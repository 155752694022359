import React, { Component } from "react";
import {
  Row,
  Col,
  CustomInput,
  Progress,
  UncontrolledCollapse,
  Button,
  CardBody,
  Card,
  Collapse,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { translations } from "../../../../translate.js";
import InfoIcon from "@material-ui/icons/Info";
import { atelierEstuaryDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { toAbsoluteUrl } from "../../../../_metronic";
import { Picky } from "react-picky";
import { confirmAlert } from "react-confirm-alert";
import "react-picky/dist/picky.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { CircularProgress } from "@material-ui/core";
import { toPairs } from "lodash";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class AddBalanceMulti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierList: [],
      distcountList: [],
      referenceList: [],
      banksList: [],
      res: [],
      formList: [],
      selectedSupplier: [],
      selectedSupplierId: "",
      cardAvalibe: false,
      cardNotAvalibe: false,
      cardNotValid: false,
      cardNotExist: false,
      openAddAction: false,
      cardAvalibeList: [],
      cardNotAvalibeList: [],
      cardNotValidList: [],
      cardNotExistList: [],
      cardAvalibeCount: 0,
      export: 0,
      startExportFile: 0,
      cardNotValidCount: 0,
      canMakeCardRequest: 0,
      cardNotAvalibeCount: 0,
      cardNotExistCount: 0,
      selectedBank: [],
      selectedBankId: "",
      selectedFormList: [],
      selectedFormId: "",
      selectedDiscountList: [],
      selectedDiscountId: "",
      selectedReferenceList: [],
      selectedReferenceId: "",
      loadData: 0,
      totalPrice: 0,
      totalPriceBefore: 0,
      submitError: "",
      errorMsg: "",
      fileName: "",
      fileSize: "",
      note: "",
      discount: "",
      cardList: [],
      selectedCardList: [],
      loading: 0,
      startExport: 0,
      loadingCards: 0,
      progressUploadHeader: false,
      startSave: 0,
      status: "",
      dataRes: [],
      doublictedCode: [],
      orderId: "",
      showResponse: 0,
      fileCounter: 0,distId:this.props.distId?this.props.distId:'',
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  handleRefersh = () => {
    this.setState({
      res: [],
      openAddAction: false,
      selectedDiscountList: [],
      selectedDiscountId: "",
      selectedReferenceList: [],
      selectedReferenceId: "",
      selectedSupplier: [],
      selectedSupplierId: "",
      cardAvalibe: false,
      cardNotAvalibe: false,
      cardNotValid: false,
      cardNotExist: false,
      cardAvalibeList: [],
      cardNotAvalibeList: [],
      cardNotValidList: [],
      cardNotExistList: [],
      cardAvalibeCount: 0,
      canMakeCardRequest: 0,
      export: 0,
      startExportFile: 0,
      cardNotValidCount: 0,
      cardNotAvalibeCount: 0,
      cardNotExistCount: 0,
      selectedBank: [],
      selectedBankId: "",
      selectedFormList: [],
      selectedFormId: "",
      loadData: 0,
      totalPrice: 0,
      totalPriceBefore: 0,
      submitError: "",
      errorMsg: "",
      fileName: "",
      fileSize: "",
      note: "",
      discount: "",
      cardList: [],
      selectedCardList: [],
      loading: 0,
      startExport: 0,
      loadingCards: 0,
      progressUploadHeader: false,
      startSave: 0,
      status: "",
      dataRes: [],
      doublictedCode: [],
      orderId: "",
      showResponse: 0,
    });
  };
  componentDidMount() {
    this.getSuppliers();
    this.getBanks();
    this.getDiscount();
    this.getReference();
    this.getForms();
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  //################################### api section ###############################
  getSuppliers() {
    this.setState({
      loading: 1,
    });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getAllSupplier",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        supplierType: "-1",
        distId:this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              supplierList: data["supplier"],
            });
          }
        }
      });
  }
  getBanks() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistBanks",
        allBank: "1",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId:this.state.distId,

      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              banksList: data["data"],
            });
          }
        }
      });
  }
  getForms() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getForms",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId:this.state.distId,

      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              formList: data["data"],
              loading: 0,
            });
          }
        }
      });
  }
  getCards() {
    this.setState({
      loadingCards: 1,
    });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCardsbyFormIdAndSuppId",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        formId: this.state.selectedFormId,
        supplierId: this.state.selectedSupplierId,
        distId:this.state.distId,

      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] != 0) {
            this.setState({
              errorMsg: data["errorMsg"],
            });
          } else {
            this.setState({
              cardList: data["data"],
              loadingCards: 0,
            });
          }
        }
      });
  }
  getDiscount() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDiscountsList",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId:this.state.distId,

      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              distcountList: data["data"],
            });
          }
        }
      });
  }
  getReference() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getRefIdListForCardRequest",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId:this.state.distId,

      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              referenceList: data,
            });
          }
        }
      });
  }
  handleAddPurchaseOrderClick() {
    this.setState({ loadingCards: 1, startSave: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "add_multi_cards_uploaded",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        res: JSON.stringify(this.state.res),
        fileName: this.state.fileName,
        distId:this.state.distId,

      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] != 0) {
            var duplicatedCodes =
              data["duplicatedCodes"] != undefined &&
              data["duplicatedCodes"].length > 0
                ? data["duplicatedCodes"]
                : [];
            var showResponse =
              data["duplicatedCodes"] != undefined &&
              data["duplicatedCodes"].length > 0
                ? 1
                : 0;
            this.setState({
              errorMsg: data["errorMsg"],
              startSave: 0,
              doublictedCode: duplicatedCodes,
              showResponse: showResponse,
              canMakeCardRequest: 0,
              loadingCards: 0,
              loadData: 0,
            });
          } else {
            this.setState({
              canMakeCardRequest: 0,
              startSave: 0,
              loadingCards: 0,
              loadData: 0,
              dataRes: data["res"]["data"],
              doublictedCode: data["duplicatedCodes"],
              orderId: data["cardOrderId"],
              status: data["errorMsg"],
              showResponse: 1,
            });
          }
        }
      });
  }
  exportErrorFileErrorCode = (event, type) => {
    if (this.state.startExportFile == 1) {
      this.exportFileAelrt(event);
    } else {
      this.setState({ startExportFile: 1 });
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "export_error_code_by_type",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          res: JSON.stringify(this.state.res),
          duplicatedCodes: JSON.stringify(this.state.doublictedCode),
          fileName: this.state.fileName,
          exportType: type,
        distId:this.state.distId,

        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else if (data["error"] != 0) {
              this.setState({ startExportFile: 0 }, function() {
                this.alertErrorSubmite(data["errorMsg"]);
              });
            } else {
              this.setState({ startExportFile: 0 }, function() {
                if (
                  data["filePath"] != undefined &&
                  data["filePath"] != "" &&
                  data["filePath"] != 0 &&
                  data["filePath"] != 0
                ) {
                  window.location = data["filePath"];
                }
              });
            }
          }
        });
    }
  };
  handleValidSubmit(event) {
    event.preventDefault();
    if (this.state.export == 1) {
      this.setState({ startExport: 1 });
    } else {
      this.setState({ startSave: 1, loadingCards: 1, loadData: 0 });
    }
    // var data = new FormData();
    // var cardCodeFile = document.querySelector("input[name=cardCodeFile]")
    //   .files[0];
    // data.append("data", cardCodeFile);
    var data = new FormData();
    var cardCodeFiles = document.querySelector("input[name=cardCodeFile]")
      .files;

    for (let i = 0; i < cardCodeFiles.length; i++) {
      data.append(`data[]`, cardCodeFiles[i]);
    }

    data.append("type", "show_multi_cards_uploaded");
    data.append("userId", localStorage.getItem("USER_ID"));
    data.append("token", localStorage.getItem("USER_TOKEN"));
    data.append("lang", localStorage.getItem("LANG"));
    data.append("export", this.state.export == 1 ? 1 : "");
    data.append(
      "res",
      this.state.export == 1 ? JSON.stringify(this.state.res) : ""
    );
    data.append("supplierId", this.state.selectedSupplierId);
    data.append("bankId", this.state.selectedBankId);
    data.append("cardId", this.getSelected("card"));
    data.append("formId", this.state.selectedFormId);
    data.append("discount", this.state.selectedDiscountId);
    data.append("refId", this.state.selectedReferenceId);
    data.append("note", this.state.note);
    data.append("distId", this.state.distId);


    fetch(window.API_PERM_USER, {
      // mode: 'no-cors',
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        if (!response.ok) {
        }
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({
              startSave: 0,
              loadingCards: 0,
              errorMsg: data["errorMsg"],
            });
          } else if (this.state.export == 1) {
            this.setState(
              { startExport: 0, export: 0, errorMsg: "" },
              function() {
                if (
                  data["filePath"] != undefined &&
                  data["filePath"] != "" &&
                  data["filePath"] != 0 &&
                  data["filePath"] != 0
                ) {
                  window.location = data["filePath"];
                }
              }
            );
          } else {
            var res = data["res"];
            var cardAvalibeCount =
              res["existsTotalCount"] != undefined &&
              res["existsTotalCount"] != 0 &&
              res["existsTotalCount"] != "" &&
              res["existsTotalCount"] != null &&
              res["existsTotalCount"] != "nan"
                ? res["existsTotalCount"]
                : 0;
            var cardNotAvalibeCount =
              res["cardClassNotHaveSupplierCount"] != undefined &&
              res["cardClassNotHaveSupplierCount"] != 0 &&
              res["cardClassNotHaveSupplierCount"] != "" &&
              res["cardClassNotHaveSupplierCount"] != null &&
              res["cardClassNotHaveSupplierCount"] != "nan"
                ? res["cardClassNotHaveSupplierCount"]
                : 0;
            var cardNotExistCount =
              res["notExistsCardClassCount"] != undefined &&
              res["notExistsCardClassCount"] != 0 &&
              res["notExistsCardClassCount"] != "" &&
              res["notExistsCardClassCount"] != null &&
              res["notExistsCardClassCount"] != "nan"
                ? res["notExistsCardClassCount"]
                : 0;
            var cardNotValidCount =
              res["cardClassNotValidCount"] != undefined &&
              res["cardClassNotValidCount"] != 0 &&
              res["cardClassNotValidCount"] != "" &&
              res["cardClassNotValidCount"] != null &&
              res["cardClassNotValidCount"] != "nan"
                ? res["cardClassNotValidCount"]
                : 0;
            var canMakeCardRequest = cardAvalibeCount > 0 ? 1 : 0;
            this.setState({
              canMakeCardRequest: canMakeCardRequest,
              startSave: 0,
              loadingCards: 0,
              errorMsg: "",
              loadData: 1,
              cardAvalibe: true,
              res: res,
              totalCodesInFile: res["totalCodesInFile"],
              totalPrice: res["totalPrice"],
              totalPriceBefore: res["totalPriceBefore"],
              cardAvalibeCount: cardAvalibeCount,
              cardNotAvalibeCount: cardNotAvalibeCount,
              cardNotExistCount: cardNotExistCount,
              cardNotValidCount: cardNotValidCount,
              cardAvalibeList: res["existsCardClass"],
              cardNotAvalibeList: res["cardClassNotHaveSupplier"],
              cardNotValidList: res["cardClassNotValid"],
              cardNotExistList: res["notExistsCardClass"],
            });
          }
        }
      });
  }
  //################################### react section ###############################
  handleChangeSelect = (type, option) => {
    if (type == "selectedSupplier") {
      this.setState(
        (state) => {
          return {
            errorMsg: "",
            cardList: [],
            selectedCardList: [],
            submitError: "",
            status: "",
            [type]: option,
            selectedSupplierId: option.id,
          };
        },
        function() {
          if (this.state.selectedFormId != "") {
            this.getCards();
          }
        }
      );
    } else if (type == "selectedFormList") {
      this.setState(
        (state) => {
          return {
            [type]: option,
            cardList: [],
            selectedCardList: [],
            selectedFormId: option.formId,
            errorMsg: "",
            submitError: "",
            status: "",
          };
        },
        function() {
          if (this.state.selectedSupplierId != "") {
            this.getCards();
          }
        }
      );
    } else if (type == "selectedBank") {
      this.setState((state) => {
        return {
          [type]: option,
          selectedBankId: option.id,
          errorMsg: "",
          submitError: "",
          status: "",
        };
      });
    } else if (type == "selectedDiscountList") {
      this.setState((state) => {
        return {
          [type]: option,
          selectedDiscountId: option.id,
        };
      });
    } else if (type == "selectedReferenceList") {
      this.setState((state) => {
        return {
          [type]: option,
          selectedReferenceId: option.refId,
        };
      });
    } else {
      this.setState((state) => {
        return {
          [type]: option,
        };
      });
    }
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      submitError: "",
    });
  };
  //   handleImgChange = (event) => {
  //     var fileReader = new FileReader();
  //     const scope = this;
  //     this.setState({
  //       fileName: event.target.files[0]["name"],
  //       fileSize: Math.ceil(event.target.files[0].size / 1024) + "KB",
  //     });
  //     // this.setState({ displayErrorHeaderMsg: false });
  //     // this.setState({ progressUploadHeader: 10 });
  //     // console.log('event.target.files[0].type');
  //     // console.log(event.target.files[0].type);
  //     // console.log(event.target.files[0]['name']);

  //     //   if Excel version ==2003 ->event.target.files[0].type.includes("application/vnd.ms-excel") //.xls
  //     //   but
  //     //   if Excel version >=2007 ->event.target.files[0].type.includes
  //     // ("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")// /.xlsx

  //     this.setState({
  //       fileName: "",
  //       fileSize: "",
  //     });
  //     if (
  //       event.target.files[0].type.includes(
  //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //       ) ||
  //       event.target.files[0].type.includes("application/vnd.ms-excel") ||
  //       event.target.files[0].type.includes("text/csv") ||
  //       event.target.files[0].type.includes("text/plain")
  //     ) {
  //       this.setState({
  //         fileName: event.target.files[0]["name"],
  //         fileSize: Math.ceil(event.target.files[0].size / 1024) + " KB",
  //       });
  //       fileReader.readAsBinaryString(event.target.files[0]);
  //       fileReader.onprogress = function(data) {
  //         if (data.lengthComputable) {
  //           var progress = parseInt((data.loaded / data.total) * 100, 10);
  //           scope.setState({
  //             progressUploadHeader: progress,
  //           });
  //         }
  //       };
  //     } else {
  //       this.setState({ displayErrorHeaderMsg: true });
  //     }
  //   };

  handleImgChange = (event) => {
    const files = event.target.files;
    const scope = this;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check file type and process each file
      if (
        file.type.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) ||
        file.type.includes("application/vnd.ms-excel") ||
        file.type.includes("text/csv") ||
        file.type.includes("text/plain")
      ) {
        // Set state for the current file
        scope.setState({
          //   fileName: file.name,
          //   fileSize: Math.ceil(file.size / 1024) + " KB",
          fileName: Strings["Upload Complete"],
          fileSize: "100%",
        });

        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file);

        fileReader.onprogress = function(data) {
          if (data.lengthComputable) {
            const progress = parseInt((data.loaded / data.total) * 100, 10);
            scope.setState({
              progressUploadHeader: progress,
            });
          }
        };
      } else {
        // Handle invalid file type
        scope.setState({ displayErrorHeaderMsg: true });
      }
      console.log(file, files);
    }
  };
  remove = (event) => {
    event.target.value = null;
  };
  getSelected = (type) => {
    if (type == "card") {
      return this.state.selectedCardList.map((data) => data.cardId);
    }
  };
  handleExportChange = (event) => {
    this.setState(
      {
        export: 1,
        submitError: "",
      },
      function() {
        this.handleValidSubmit(event);
      }
    );
  };
  openAddActionMenu = () => {
    this.setState({
      openAddAction: !this.state.openAddAction,
    });
  };
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        openAddAction: false,
      });
    }
  };
  alertErrorSubmite = (errorMsg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view ">
            <div className="mb-4">
              <HighlightOffIcon className="error-alert-icon" size={70} />
            </div>
            <p className="mb-3 ">{errorMsg}</p>
            <button
              type="button"
              className="btn  alert-btn-margin ml-2 mr-2 filter-empty-email label-error"
              onClick={onClose}
            >
              {Strings.Close}
            </button>
          </div>
        );
      },
    });
  };
  handleAddPurchaseOrder = (event) => {
    this.setState(
      {
        status: "",
        submitError: "",
      },
      function() {
        this.addPurchaseOrder(event);
      }
    );
  };
  exportFileAelrt = (event) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <p>
              {Strings["You cannot export more than one card file at a time"]}
            </p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Close}
            </button>
          </div>
        );
      },
    });
  };
  addPurchaseOrder = (event) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to create this purchase order ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleAddPurchaseOrderClick();
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };
  openCollapse = (event, status, type) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({
      [type]: status,
    });
  };
  displayResponse = () => {
    return (
      <>
        {this.state.orderId != "" &&
          this.state.orderId != 0 &&
          this.state.orderId != null && (
            <Row className="  mt-2 ">
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="  count-card-title pt-4"
              >
                <div>
                  {Strings["Purchase order number"]} : ({this.state.orderId})
                </div>
              </Col>
            </Row>
          )}
        <div className="padding-5">
          {this.state.dataRes.length > 0 && (
            <>
              <Row className="table-header-row mr-card-1px mr-1 ml-1">
                <Col
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["ID"]}
                >
                  {" "}
                  {Strings["ID"]}
                </Col>
                <Col
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Card Name"]}
                >
                  {" "}
                  {Strings["Card Name"]}
                </Col>
                <Col
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Status"]}
                >
                  {" "}
                  {Strings["Status"]}
                </Col>
                <Col
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Purchase Request # (Class)"]}
                >
                  {" "}
                  {Strings["Purchase Request # (Class)"]}
                </Col>
              </Row>
              <div className="overflow-y-status ">{this.displayStatus()}</div>
            </>
          )}
          {this.state.dataRes.length <= 0 && (
            <div>
              <div className="text-center" style={{ marginTop: "15%" }}>
                <div>
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(
                      "/media/ezpay/EZPAY_LOGO_GRAY" +
                        window.MAIN_IMAGE +
                        ".png"
                    )}
                    className="img-fluid no-data-img"
                  />
                  <div className="no-data-text">
                    {Strings["There is no data to display"]}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };
  displayCards = () => {
    return (
      <>
        {((this.state.totalCodesInFile != "" &&
          this.state.totalCodesInFile != 0 &&
          this.state.totalCodesInFile != null) ||
          this.state.cardNotValidCount > 0 ||
          this.state.cardNotExistCount > 0 ||
          this.state.cardNotAvalibeCount > 0) && (
          <Row className=" mb-2 mt-2">
            <Col lg={7} md={7} sm={7} xs={12} className="count-card-title pt-4">
              {Strings["The number of cards in the file : "]}(
              {this.state.totalCodesInFile})
            </Col>
            <Col lg={5} md={5} sm={5} className="text-right ">
              <Col lg={12} md={12}>
                {this.state.startExport == 0 &&
                  (this.state.cardNotValidCount > 0 ||
                    this.state.cardNotExistCount > 0 ||
                    this.state.cardNotAvalibeCount > 0) && (
                    <button
                      type="button"
                      className="btn page-action-btn"
                      onClick={this.handleExportChange}
                    >
                      {Strings["Export the wrong cards"]}
                    </button>
                  )}
                {this.state.startExport != 0 &&
                  (this.state.cardNotValidCount > 0 ||
                    this.state.cardNotExistCount > 0 ||
                    this.state.cardNotAvalibeCount > 0) && (
                    <button type="button" className="btn page-action-btn ">
                      <CircularProgress className="SaveCircularProgressColor" />
                      {Strings["Export the wrong cards"]}
                    </button>
                  )}
              </Col>
            </Col>
          </Row>
        )}
        <Row
          className="collaps-view"
          id="cardAvalibe"
          onClick={(event) =>
            this.openCollapse(event, !this.state.cardAvalibe, "cardAvalibe")
          }
        >
          <Col lg={11} md={11} sm={11} xs={11}>
            {Strings["Cards available to the supplier"]}
          </Col>
          <Col className={"text-right"} lg={1} md={1} sm={1} xs={1}>
            {this.state.cardAvalibe == true ? (
              <i
                className={
                  localStorage.getItem("LANG") == 1
                    ? "fas fa-angle-up mr-1"
                    : "fas fa-angle-up ml-1"
                }
              ></i>
            ) : (
              <i
                className={
                  localStorage.getItem("LANG") == 1
                    ? "fas fa-angle-down mr-1"
                    : "fas fa-angle-down ml-1"
                }
              ></i>
            )}{" "}
          </Col>
        </Row>
        <Collapse isOpen={this.state.cardAvalibe}>
          <Card className="without-border ml-2 mr-2">
            <CardBody>
              {this.state.cardAvalibeCount > 0 &&
                this.state.selectedDiscountId == "" &&
                this.state.cardAvalibeList.length > 0 && (
                  <Row className="table-header-row mr-card-1px">
                    <Col
                      lg={1}
                      md={1}
                      sm={1}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["ID"]}
                    >
                      {" "}
                      {Strings["ID"]}
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Card Name"]}
                    >
                      {" "}
                      {Strings["Card Name"]}
                    </Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Card Name In File"]}
                    >
                      {" "}
                      {Strings["Card Name In File"]}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Price"]}
                    >
                      {Strings["Price"]}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Qty"]}
                    >
                      {" "}
                      {Strings["Qty"]}
                    </Col>
                  </Row>
                )}
              {this.state.cardAvalibeCount > 0 &&
                this.state.selectedDiscountId != "" &&
                this.state.cardAvalibeList.length > 0 && (
                  <Row className="table-header-row mr-card-1px">
                    <Col
                      lg={1}
                      md={1}
                      sm={1}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["ID"]}
                    >
                      {" "}
                      {Strings["ID"]}
                    </Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Card Name"]}
                    >
                      {" "}
                      {Strings["Card Name"]}
                    </Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Card Name In File"]}
                    >
                      {" "}
                      {Strings["Card Name In File"]}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Price"]}
                    >
                      {Strings["Price"]}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Price After Discount"]}
                    >
                      {Strings["Price After Discount"]}
                    </Col>
                    <Col
                      lg={1}
                      md={1}
                      sm={1}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Qty"]}
                    >
                      {" "}
                      {Strings["Qty"]}
                    </Col>
                  </Row>
                )}
              <div className="overflow-y-cards ">
                {this.displayData("cardAvalibe")}
              </div>
              {this.state.cardAvalibeCount != "" &&
                this.state.selectedDiscountId == "" &&
                this.state.cardAvalibeCount != 0 &&
                this.state.cardAvalibeCount != null && (
                  <div className="m-l-r-10px">
                    <Row className="table-body-row without-border-bottom font-w-600 p-l-r-0">
                      <Col
                        lg={8}
                        md={8}
                        sm={8}
                        xs={12}
                        className="main-color-1   overflow-text-length"
                        title={Strings["Totals"]}
                      >
                        {Strings["Totals"]}
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                        className="overflow-text-length"
                        title={Strings["Total Price"]}
                      >
                        {" "}
                        {this.state.totalPrice == null ||
                        this.state.totalPrice == ""
                          ? 0
                          : this.state.totalPrice}
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                        className="overflow-text-length"
                        title={Strings["Total Qty"]}
                      >
                        {" "}
                        {this.state.cardAvalibeCount}
                      </Col>
                    </Row>
                  </div>
                )}
              {this.state.cardAvalibeCount != "" &&
                this.state.selectedDiscountId != "" &&
                this.state.cardAvalibeCount != 0 &&
                this.state.cardAvalibeCount != null && (
                  <div className="m-l-r-10px">
                    <Row className="table-body-row without-border-bottom font-w-600 p-l-r-0">
                      <Col
                        lg={7}
                        md={7}
                        sm={7}
                        xs={12}
                        className="main-color-1   overflow-text-length"
                        title={Strings["Totals"]}
                      >
                        {Strings["Totals"]}
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                        className="overflow-text-length"
                        title={Strings["Total Price"]}
                      >
                        {" "}
                        {this.state.totalPriceBefore == null ||
                        this.state.totalPriceBefore == ""
                          ? 0
                          : this.state.totalPriceBefore}
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                        className="overflow-text-length"
                        title={Strings["Total Price After Dicount"]}
                      >
                        {" "}
                        {this.state.totalPrice == null ||
                        this.state.totalPrice == ""
                          ? 0
                          : this.state.totalPrice}
                      </Col>
                      <Col
                        lg={1}
                        md={1}
                        sm={1}
                        xs={12}
                        className="overflow-text-length"
                        title={Strings["Total Qty"]}
                      >
                        {" "}
                        {this.state.cardAvalibeCount}
                      </Col>
                    </Row>
                  </div>
                )}
            </CardBody>
          </Card>
        </Collapse>
        <Row
          className="collaps-view"
          id="cardNotAvalibe"
          onClick={(event) =>
            this.openCollapse(
              event,
              !this.state.cardNotAvalibe,
              "cardNotAvalibe"
            )
          }
        >
          <Col lg={11} md={11} sm={11} xs={11}>
            {Strings["Cards are not available to the supplier"]}
          </Col>
          <Col className={"text-right"} lg={1} md={1} sm={1} xs={1}>
            {this.state.cardNotAvalibe == true ? (
              <i
                className={
                  localStorage.getItem("LANG") == 1
                    ? "fas fa-angle-up mr-1"
                    : "fas fa-angle-up ml-1"
                }
              ></i>
            ) : (
              <i
                className={
                  localStorage.getItem("LANG") == 1
                    ? "fas fa-angle-down mr-1"
                    : "fas fa-angle-down ml-1"
                }
              ></i>
            )}{" "}
          </Col>
        </Row>
        <Collapse isOpen={this.state.cardNotAvalibe}>
          <Card className="without-border ml-2 mr-2">
            <CardBody>
              {this.state.cardNotAvalibeCount > 0 &&
                this.state.cardNotAvalibeList.length > 0 && (
                  <Row className="table-header-row mr-card-1px">
                    <Col
                      lg={1}
                      md={1}
                      sm={1}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["ID"]}
                    >
                      {" "}
                      {Strings["ID"]}
                    </Col>
                    <Col
                      lg={5}
                      md={5}
                      sm={5}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Card Name"]}
                    >
                      {" "}
                      {Strings["Card Name"]}
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Card Name In File"]}
                    >
                      {Strings["Card Name In File"]}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Qty"]}
                    >
                      {" "}
                      {Strings["Qty"]}
                    </Col>
                  </Row>
                )}
              <div className="overflow-y-cards ">
                {this.displayData("cardNotAvalibe")}
              </div>
              {this.state.cardNotAvalibeCount != "" &&
                this.state.cardNotAvalibeCount != 0 &&
                this.state.cardNotAvalibeCount != null && (
                  <div className="m-l-r-10px">
                    <Row className="table-body-row without-border-bottom font-w-600 p-l-r-0">
                      <Col
                        lg={10}
                        md={10}
                        sm={10}
                        xs={12}
                        className="main-color-1   overflow-text-length"
                        title={Strings["Totals"]}
                      >
                        {Strings["Totals"]}
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                        className="overflow-text-length"
                        title={Strings["Total Qty"]}
                      >
                        {" "}
                        {this.state.cardNotAvalibeCount}
                      </Col>
                    </Row>
                  </div>
                )}
            </CardBody>
          </Card>
        </Collapse>
        <Row
          className="collaps-view"
          id="cardNotValid"
          onClick={(event) =>
            this.openCollapse(event, !this.state.cardNotValid, "cardNotValid")
          }
        >
          <Col lg={11} md={11} sm={11} xs={11}>
            {Strings["Invalid codes"]}
          </Col>
          <Col className={"text-right"} lg={1} md={1} sm={1} xs={1}>
            {this.state.cardNotValid == true ? (
              <i
                className={
                  localStorage.getItem("LANG") == 1
                    ? "fas fa-angle-up mr-1"
                    : "fas fa-angle-up ml-1"
                }
              ></i>
            ) : (
              <i
                className={
                  localStorage.getItem("LANG") == 1
                    ? "fas fa-angle-down mr-1"
                    : "fas fa-angle-down ml-1"
                }
              ></i>
            )}{" "}
          </Col>
        </Row>
        <Collapse isOpen={this.state.cardNotValid}>
          <Card className="without-border ml-2 mr-2">
            <CardBody>
              {this.state.cardNotValidCount > 0 &&
                this.state.cardNotValidList.length > 0 && (
                  <Row className="table-header-row mr-card-1px">
                    <Col
                      lg={1}
                      md={1}
                      sm={1}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["ID"]}
                    >
                      {" "}
                      {Strings["ID"]}
                    </Col>
                    <Col
                      lg={5}
                      md={5}
                      sm={5}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Card Name"]}
                    >
                      {" "}
                      {Strings["Card Name"]}
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Card Name In File"]}
                    >
                      {" "}
                      {Strings["Card Name In File"]}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Qty"]}
                    >
                      {" "}
                      {Strings["Qty"]}
                    </Col>
                  </Row>
                )}
              <div className="overflow-y-cards ">
                {this.displayData("cardNotValid")}
              </div>
              {this.state.cardNotValidCount != "" &&
                this.state.cardNotValidCount != 0 &&
                this.state.cardNotValidCount != null && (
                  <div className="m-l-r-10px">
                    <Row className="table-body-row without-border-bottom font-w-600 p-l-r-0">
                      <Col
                        lg={10}
                        md={10}
                        sm={10}
                        xs={12}
                        className="main-color-1   overflow-text-length"
                        title={Strings["Totals"]}
                      >
                        {Strings["Totals"]}
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                        className="overflow-text-length"
                        title={Strings["Total Qty"]}
                      >
                        {" "}
                        {this.state.cardNotValidCount}
                      </Col>
                    </Row>
                  </div>
                )}
            </CardBody>
          </Card>
        </Collapse>
        <Row
          className="collaps-view "
          id="cardNotExist"
          onClick={(event) =>
            this.openCollapse(event, !this.state.cardNotExist, "cardNotExist")
          }
        >
          <Col lg={11} md={11} sm={11} xs={11}>
            {Strings["Cards not found in the database"]}
          </Col>
          <Col className={"text-right"} lg={1} md={1} sm={1} xs={1}>
            {this.state.cardNotExist == true ? (
              <i
                className={
                  localStorage.getItem("LANG") == 1
                    ? "fas fa-angle-up mr-1"
                    : "fas fa-angle-up ml-1"
                }
              ></i>
            ) : (
              <i
                className={
                  localStorage.getItem("LANG") == 1
                    ? "fas fa-angle-down mr-1"
                    : "fas fa-angle-down ml-1"
                }
              ></i>
            )}{" "}
          </Col>
        </Row>
        <Collapse isOpen={this.state.cardNotExist}>
          <Card className="without-border ml-2 mr-2">
            <CardBody>
              {this.state.cardNotExistCount > 0 &&
                this.state.cardNotExistList.length > 0 && (
                  <Row className="table-header-row mr-card-1px">
                    <Col
                      lg={1}
                      md={1}
                      sm={1}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["ID"]}
                    >
                      {" "}
                      {Strings["ID"]}
                    </Col>
                    <Col
                      lg={9}
                      md={9}
                      sm={9}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Card Name In File"]}
                    >
                      {" "}
                      {Strings["Card Name In File"]}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Qty"]}
                    >
                      {" "}
                      {Strings["Qty"]}
                    </Col>
                  </Row>
                )}
              <div className="overflow-y-cards ">
                {this.displayData("cardNotExist")}
              </div>
              {this.state.cardNotExistCount != "" &&
                this.state.cardNotExistCount != 0 &&
                this.state.cardNotExistCount != null && (
                  <div className="m-l-r-10px">
                    <Row className="table-body-row without-border-bottom font-w-600 p-l-r-0">
                      <Col
                        lg={10}
                        md={10}
                        sm={10}
                        xs={12}
                        className="main-color-1   overflow-text-length"
                        title={Strings["Totals"]}
                      >
                        {Strings["Totals"]}
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                        className="overflow-text-length"
                        title={Strings["Total Qty"]}
                      >
                        {" "}
                        {this.state.cardNotExistCount}
                      </Col>
                    </Row>
                  </div>
                )}
            </CardBody>
          </Card>
        </Collapse>
      </>
    );
  };
  displayFilter() {
    var info = [];
    info.push(
      <>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className=" mb-2">
            <label className="font-w-400">{Strings["Supplier"]}</label>
            <Picky
              value={this.state.selectedSupplier}
              options={this.state.supplierList}
              onChange={this.handleChangeSelect.bind(this, "selectedSupplier")}
              open={false}
              valueKey={"id"}
              labelKey={"name"}
              selectAllText={Strings["Select All"]}
              allSelectedPlaceholder={Strings["%s selected"]}
              manySelectedPlaceholder={Strings["%s selected"]}
              multiple={false}
              clearFilterOnClose={true}
              includeSelectAll={false}
              includeFilter={true}
              dropdownHeight={200}
              placeholder={Strings["Select Supplier"]}
              filterPlaceholder={Strings["Supplier"]}
            />
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className="mb-2 mt-3">
            <label className="font-w-400">
              {Strings["Upload File Template"]}
            </label>
            <Picky
              value={this.state.selectedFormList}
              options={this.state.formList}
              onChange={this.handleChangeSelect.bind(this, "selectedFormList")}
              open={false}
              valueKey={"formId"}
              labelKey={
                localStorage.getItem("LANG") == 1 ? "formName" : "formNameEn"
              }
              selectAllText={Strings["Select All"]}
              allSelectedPlaceholder={Strings["%s selected"]}
              manySelectedPlaceholder={Strings["%s selected"]}
              multiple={false}
              clearFilterOnClose={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={200}
              placeholder={Strings["Select Upload File Template"]}
              filterPlaceholder={Strings["Select Upload File Template"]}
            />
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className="mb-2 mt-3">
            <label className="font-w-400">{Strings["Cards"]}</label>
            <Picky
              value={this.state.selectedCardList}
              options={this.state.cardList}
              onChange={this.handleChangeSelect.bind(this, "selectedCardList")}
              open={false}
              valueKey={"cardId"}
              labelKey={localStorage.getItem("LANG") == 1 ? "name" : "name_en"}
              selectAllText={Strings["Select All"]}
              allSelectedPlaceholder={Strings["%s selected"]}
              manySelectedPlaceholder={Strings["%s selected"]}
              multiple={true}
              clearFilterOnClose={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={200}
              placeholder={Strings["Select Card"]}
              filterPlaceholder={Strings["Select Card"]}
            />
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className="mb-2 mt-3">
            <label className="font-w-400">{Strings["From Bank"]}</label>
            <Picky
              value={this.state.selectedBank}
              options={this.state.banksList}
              onChange={this.handleChangeSelect.bind(this, "selectedBank")}
              open={false}
              valueKey="id"
              labelKey="bankName"
              multiple={false}
              includeSelectAll={true}
              includeFilter={true}
              clearFilterOnClose={true}
              dropdownHeight={200}
              placeholder={Strings["Bank Name"]}
              filterPlaceholder={Strings["Select Bank Name"]}
            />
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className="mb-2 mt-3">
            <label className="font-w-400">{Strings["Discount"]}</label>
            <Picky
              value={this.state.selectedDiscountList}
              options={this.state.distcountList}
              onChange={this.handleChangeSelect.bind(
                this,
                "selectedDiscountList"
              )}
              open={false}
              valueKey="id"
              labelKey={localStorage.getItem("LANG") == 1 ? "name" : "nameEn"}
              multiple={false}
              includeSelectAll={true}
              includeFilter={true}
              clearFilterOnClose={true}
              dropdownHeight={200}
              placeholder={Strings["Discount"]}
              filterPlaceholder={Strings["Select Discount"]}
            />
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className="mb-2 mt-3">
            <label className="font-w-400">{Strings["Reference #"]}</label>
            <Picky
              value={this.state.selectedReferenceList}
              options={this.state.referenceList}
              onChange={this.handleChangeSelect.bind(
                this,
                "selectedReferenceList"
              )}
              open={false}
              valueKey="refId"
              labelKey={"refId"}
              multiple={false}
              includeSelectAll={true}
              includeFilter={true}
              clearFilterOnClose={true}
              dropdownHeight={200}
              placeholder={Strings["Reference #"]}
              filterPlaceholder={Strings["Select Reference #"]}
            />
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
          <label>{Strings["Codes File"]}</label>
          <span className={"fileInput-span col-md-12"}>
            <PublishIcon />{" "}
            <span className="pointer-cursor pt-3">{Strings["Upload"]}</span>
            <input
              multiple
              onClick={(event) => this.remove(event)}
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/plain"
              className="fileInput-width fileInput-opacity"
              onChange={(e) => this.handleImgChange(e)}
              name="cardCodeFile"
            ></input>
          </span>
          {this.state.progressUploadHeader != undefined &&
            this.state.progressUploadHeader > 0 && (
              <Progress
                striped
                value={this.state.progressUploadHeader}
                className="mb-1 mt-1 progress-bg-color"
              >
                {this.state.progressUploadHeader} %
              </Progress>
            )}
          {this.state.displayErrorHeaderMsg == true && (
            <div className="invalid-feedback d-block mb-1">
              {Strings["Upload Excel File only"]}
            </div>
          )}
        </Col>
        {this.state.fileName != "" && this.state.fileSize != "" && (
          <Col lg={12} md={12} sm={12} xs={12} className="mt-1">
            <div className="error-text-black file-icon-view">
              <i className="flaticon-file-2 success-icon mr-1 mb-3 mt-4 " />{" "}
              {this.state.fileName} {" (" + this.state.fileSize + ")"}
            </div>
          </Col>
        )}
        <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
          <AvField
            name="note"
            type="textarea"
            label={Strings["Note"]}
            placeholder={Strings["Enter Notes"]}
            value={this.state.note}
            onChange={this.handleInputChange}
          />
        </Col>
      </>
    );
    info.push(
      <Col lg={12} md={12} sm={12} xs={12}>
        <div className="text-right mt-4">
          {this.state.showResponse == 1 &&
            (this.state.cardNotValidCount > 0 ||
              this.state.cardNotExistCount > 0 ||
              this.state.cardNotAvalibeCount > 0 ||
              (this.state.doublictedCode != undefined &&
                this.state.doublictedCode.length > 0)) && (
              <span
                className={
                  (this.state.openAddAction == true ? "show " : "") +
                  "dropdown-inv "
                }
                ref={this.setWrapperRef}
              >
                <button
                  type="button"
                  className="btn page-action-btn ml-2 mr-2"
                  data-toggle="dropdown"
                  aria-expanded="true"
                  onClick={(event) => this.openAddActionMenu()}
                >
                  {Strings["Export Cards Files"]}{" "}
                  {this.state.openAddAction == true ? (
                    <i
                      className={
                        localStorage.getItem("LANG") == 1
                          ? "fas fa-angle-up mr-1"
                          : "fas fa-angle-up ml-1"
                      }
                    ></i>
                  ) : (
                    <i
                      className={
                        localStorage.getItem("LANG") == 1
                          ? "fas fa-angle-down mr-1"
                          : "fas fa-angle-down ml-1"
                      }
                    ></i>
                  )}
                </button>

                <div
                  className={
                    (this.state.openAddAction == true ? "show " : "") +
                    "dropdown-menu-inv add-card"
                  }
                  x-placement="bottom-start"
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  <label className="pl-1 pr-1 pt-1 ">{Strings.Files}</label>
                  {this.state.cardNotValidCount > 0 && (
                    <a
                      className="dropdown-item-inv "
                      href="#/"
                      onClick={(event) =>
                        this.exportErrorFileErrorCode(event, "1")
                      }
                    >
                      {Strings["Invalid codes"]}
                    </a>
                  )}
                  {this.state.doublictedCode != undefined &&
                    this.state.doublictedCode.length > 0 && (
                      <a
                        className="dropdown-item-inv "
                        href="#/"
                        onClick={(event) =>
                          this.exportErrorFileErrorCode(event, "2")
                        }
                      >
                        {Strings["Duplicate codes"]}
                      </a>
                    )}
                  {this.state.cardNotAvalibeCount > 0 && (
                    <a
                      className="dropdown-item-inv "
                      href="#/"
                      onClick={(event) =>
                        this.exportErrorFileErrorCode(event, "3")
                      }
                    >
                      {Strings["Not available to the supplier"]}
                    </a>
                  )}
                  {this.state.cardNotExistCount > 0 && (
                    <a
                      className="dropdown-item-inv "
                      href="#/"
                      onClick={(event) =>
                        this.exportErrorFileErrorCode(event, "4")
                      }
                    >
                      {Strings["Not registered in the warehouse"]}
                    </a>
                  )}
                </div>
              </span>
            )}
          {this.state.showResponse == 0 &&
            this.state.canMakeCardRequest == 0 &&
            this.state.startSave == 0 &&
            this.state.fileName != "" &&
            this.state.selectedBankId != "" &&
            this.state.errorMsg == "" &&
            this.state.selectedCardList.length > 0 &&
            this.state.selectedSupplierId != "" &&
            this.state.selectedFormId != "" && (
              <button type="submit" className="btn form-save-btn mr-1 ml-1">
                {" "}
                {Strings["Uploaded File"]}{" "}
              </button>
            )}
          {this.state.showResponse == 0 &&
            this.state.canMakeCardRequest == 0 &&
            this.state.startSave != 0 &&
            this.state.fileName != "" &&
            this.state.selectedBankId != "" &&
            this.state.errorMsg == "" &&
            this.state.selectedCardList.length > 0 &&
            this.state.selectedSupplierId != "" &&
            this.state.selectedFormId != "" && (
              <button type="button" className="btn form-save-btn">
                {" "}
                <CircularProgress className="SaveCircularProgressColor" />{" "}
                {Strings["Uploaded File"]}{" "}
              </button>
            )}
          {this.state.showResponse == 0 &&
            this.state.canMakeCardRequest == 1 &&
            this.state.startSave == 0 && (
              <button
                type="button"
                className="btn form-save-btn mr-1 ml-1"
                onClick={(event) => this.handleAddPurchaseOrder(event)}
              >
                {" "}
                {Strings["Create a purchase order"]}{" "}
              </button>
            )}
          {this.state.showResponse == 0 &&
            this.state.canMakeCardRequest == 1 &&
            this.state.startSave != 0 && (
              <button type="button" className="btn form-save-btn">
                {" "}
                <CircularProgress className="SaveCircularProgressColor" />{" "}
                {Strings["Create a purchase order"]}{" "}
              </button>
            )}

          <button
            type="button"
            className="btn form-cancel-btn m-l-10"
            onClick={this.handleRefersh}
          >
            {Strings["Cancel"]}
          </button>
        </div>

        {this.state.status && (
          <div className="error-text-black">
            <i className={"flaticon2-check-mark success-icon mr-1 mb-3 mt-4"} />{" "}
            {this.state.status}
          </div>
        )}
        {this.state.submitError && (
          <div className="error-text-black">
            <i className="flaticon2-exclamation error-icon mr-1 mb-3 mt-4" />{" "}
            {this.state.submitError}
          </div>
        )}
      </Col>
    );

    return info;
  }
  displayData = (type) => {
    let list = "";
    if (
      type == "cardAvalibe" &&
      this.state.selectedDiscountId == "" &&
      this.state.cardAvalibeCount > 0 &&
      this.state.cardAvalibeList.length > 0
    ) {
      list = this.state.cardAvalibeList.map((rowData, index) => {
        return (
          <Row key={index} className="table-body-row ">
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length"
              title={index + 1}
            >
              {" "}
              {index + 1}
            </Col>
            <Col
              lg={4}
              md={4}
              sm={4}
              xs={12}
              className="overflow-text-length"
              title={rowData["oraginalName"]}
            >
              {" "}
              {rowData["oraginalName"]}
            </Col>
            <Col
              lg={3}
              md={3}
              sm={3}
              xs={12}
              className="overflow-text-length"
              title={rowData["name"]}
            >
              {" "}
              {rowData["name"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["data"]["price"]}
            >
              {rowData["data"]["price"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["count"]}
            >
              {" "}
              {rowData["count"]}
            </Col>
          </Row>
        );
      });
    } else if (
      type == "cardAvalibe" &&
      this.state.selectedDiscountId != "" &&
      this.state.cardAvalibeCount > 0 &&
      this.state.cardAvalibeList.length > 0
    ) {
      list = this.state.cardAvalibeList.map((rowData, index) => {
        return (
          <Row key={index} className="table-body-row ">
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length"
              title={index + 1}
            >
              {" "}
              {index + 1}
            </Col>
            <Col
              lg={3}
              md={3}
              sm={3}
              xs={12}
              className="overflow-text-length"
              title={rowData["oraginalName"]}
            >
              {" "}
              {rowData["oraginalName"]}
            </Col>
            <Col
              lg={3}
              md={3}
              sm={3}
              xs={12}
              className="overflow-text-length"
              title={rowData["name"]}
            >
              {" "}
              {rowData["name"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["data"]["priceBefore"]}
            >
              {" "}
              {rowData["data"]["priceBefore"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["data"]["price"]}
            >
              {rowData["data"]["price"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length"
              title={rowData["count"]}
            >
              {" "}
              {rowData["count"]}
            </Col>
          </Row>
        );
      });
    } else if (
      type == "cardNotAvalibe" &&
      this.state.cardNotAvalibeCount > 0 &&
      this.state.cardNotAvalibeList.length > 0
    ) {
      list = this.state.cardNotAvalibeList.map((rowData, index) => {
        return (
          <Row className="table-body-row ">
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length"
              title={index + 1}
            >
              {" "}
              {index + 1}
            </Col>
            <Col
              lg={5}
              md={5}
              sm={5}
              xs={12}
              className="overflow-text-length"
              title={rowData["oraginalName"]}
            >
              {" "}
              {rowData["oraginalName"]}
            </Col>
            <Col
              lg={4}
              md={4}
              sm={4}
              xs={12}
              className="overflow-text-length"
              title={rowData["name"]}
            >
              {" "}
              {rowData["name"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["count"]}
            >
              {" "}
              {rowData["count"]}
            </Col>
          </Row>
        );
      });
    } else if (
      type == "cardNotValid" &&
      this.state.cardNotValidCount > 0 &&
      this.state.cardNotValidList.length > 0
    ) {
      list = this.state.cardNotValidList.map((rowData, index) => {
        return (
          <Row className="table-body-row ">
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length"
              title={index + 1}
            >
              {" "}
              {index + 1}
            </Col>
            <Col
              lg={5}
              md={5}
              sm={5}
              xs={12}
              className="overflow-text-length"
              title={rowData["oraginalName"]}
            >
              {" "}
              {rowData["oraginalName"]}
            </Col>
            <Col
              lg={4}
              md={4}
              sm={4}
              xs={12}
              className="overflow-text-length"
              title={rowData["name"]}
            >
              {" "}
              {rowData["name"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["count"]}
            >
              {" "}
              {rowData["count"]}
            </Col>
          </Row>
        );
      });
    } else if (
      type == "cardNotExist" &&
      this.state.cardNotExistCount > 0 &&
      this.state.cardNotExistList.length > 0
    ) {
      list = this.state.cardNotExistList.map((rowData, index) => {
        return (
          <Row className="table-body-row ">
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length"
              title={index + 1}
            >
              {" "}
              {index + 1}
            </Col>
            <Col
              lg={9}
              md={9}
              sm={9}
              xs={12}
              className="overflow-text-length"
              title={rowData["name"]}
            >
              {" "}
              {rowData["name"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["count"]}
            >
              {" "}
              {rowData["count"]}
            </Col>
          </Row>
        );
      });
    } else {
      list = (
        <div>
          <div className="text-center" style={{ marginTop: "1%" }}>
            <div>
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">
                {Strings["There is no data to display"]}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return list;
  };
  displayStatus = () => {
    let list = [];
    list = this.state.dataRes.map((rowData, index) => {
      if (
        rowData["countMsg"] != undefined &&
        rowData["cardClassId"] != undefined
      )
        return (
          <Row className="table-body-row">
            <Col
              lg={3}
              md={3}
              sm={3}
              xs={12}
              className="overflow-text-length"
              title={rowData["cardClassId"]}
            >
              {rowData["cardClassId"]}
            </Col>
            <Col
              lg={3}
              md={3}
              sm={3}
              xs={12}
              className="overflow-text-length"
              title={rowData["cardClassName"]}
            >
              {rowData["cardClassName"]}
            </Col>
            <Col
              lg={3}
              md={3}
              sm={3}
              xs={12}
              className="overflow-text-length"
              title={rowData["countMsg"]}
            >
              {rowData["countMsg"]}
            </Col>
            <Col
              lg={3}
              md={3}
              sm={3}
              xs={12}
              className="overflow-text-length"
              title={
                rowData["requestCardId"] == undefined ||
                rowData["requestCardId"] == null ||
                rowData["requestCardId"] == "" ||
                rowData["requestCardId"] == 0
                  ? "-"
                  : rowData["requestCardId"]
              }
            >
              {rowData["requestCardId"] == undefined ||
              rowData["requestCardId"] == null ||
              rowData["requestCardId"] == "" ||
              rowData["requestCardId"] == 0
                ? "-"
                : rowData["requestCardId"]}
            </Col>
          </Row>
        );
    });
    return list;
  };
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  render() {
    this.setWrapperRef = this.setWrapperRef.bind(this);
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {Strings["Add Balance"]}
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>
        {this.state.loading == 1 && (
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}
        {this.state.loading == 0 && (
          <div className="sidebar-body-view">
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
            >
              <Row>
                <Col
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="add-purchase-order-form-border"
                >
                  <Row>{this.displayFilter()}</Row>
                </Col>
                <Col lg={8} md={8} sm={12} xs={12} className="mt-2">
                  {this.state.showResponse == 0 &&
                    this.state.loadingCards == 0 &&
                    this.state.startSave == 0 &&
                    this.state.loadData == 1 &&
                    this.state.errorMsg == "" &&
                    this.displayCards()}
                  {this.state.showResponse == 1 &&
                    this.state.loadingCards == 0 &&
                    this.state.startSave == 0 &&
                    this.state.loadData == 0 &&
                    this.state.errorMsg == "" &&
                    this.displayResponse()}
                  {(this.state.selectedSupplierId == "" ||
                    this.state.selectedFormId == "") &&
                    this.state.errorMsg == "" && (
                      <div>
                        <div
                          className="text-center"
                          style={{ marginTop: "15%" }}
                        >
                          <div>
                            <div className="no-data-text">
                              {
                                Strings[
                                  "Select file template and supplier to display cards"
                                ]
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.selectedBankId == "" &&
                    this.state.errorMsg == "" &&
                    this.state.selectedCardList.length > 0 &&
                    this.state.cardList.length > 0 &&
                    this.state.selectedSupplierId != "" &&
                    this.state.selectedFormId != "" && (
                      <div>
                        <div
                          className="text-center"
                          style={{ marginTop: "15%" }}
                        >
                          <div>
                            <div className="no-data-text">
                              {Strings["Select the bank to upload the file"]}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.errorMsg == "" &&
                    this.state.selectedCardList.length <= 0 &&
                    this.state.cardList.length > 0 &&
                    this.state.selectedSupplierId != "" &&
                    this.state.selectedFormId != "" && (
                      <div>
                        <div
                          className="text-center"
                          style={{ marginTop: "15%" }}
                        >
                          <div>
                            <div className="no-data-text">
                              {Strings["Select the cards to upload the file"]}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.errorMsg == "" &&
                    this.state.selectedCardList.length > 0 &&
                    this.state.cardList.length > 0 &&
                    this.state.loadData == 0 &&
                    this.state.showResponse == 0 &&
                    this.state.selectedBankId != "" &&
                    this.state.fileName == "" &&
                    this.state.selectedSupplierId != "" &&
                    this.state.selectedFormId != "" && (
                      <div>
                        <div
                          className="text-center"
                          style={{ marginTop: "15%" }}
                        >
                          <div>
                            <div className="no-data-text">
                              {Strings["Upload the file Now"]}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.loadingCards == 0 &&
                    this.state.errorMsg == "" &&
                    this.state.cardList.length <= 0 &&
                    this.state.selectedSupplierId != "" &&
                    this.state.selectedFormId != "" && (
                      <div>
                        <div
                          className="text-center"
                          style={{ marginTop: "15%" }}
                        >
                          <div>
                            <div className="no-data-text">
                              {
                                Strings[
                                  "There are no cards in the inventory associated with this supplier and file template"
                                ]
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.errorMsg != "" && (
                    <div>
                      <Row className="text-center" style={{ marginTop: "15%" }}>
                        <div>
                          <img
                            alt="Logo"
                            src={toAbsoluteUrl(
                              "/media/ezpay/EZPAY_LOGO_GRAY" +
                                window.MAIN_IMAGE +
                                ".png"
                            )}
                            className="img-fluid no-data-img"
                          />
                          <div className="no-data-text">
                            {this.state.errorMsg}
                          </div>
                        </div>
                      </Row>
                    </div>
                  )}
                  {this.state.loadingCards == 1 && (
                    <Row>
                      <Col
                        md={12}
                        style={{ marginTop: "15%" }}
                        className="text-center"
                      >
                        <div className="card-body">
                          <div
                            className="spinner-grow text-dark m-2 main-color-1"
                            role="status"
                          ></div>
                          <div
                            className="spinner-grow text-dark m-2 main-color-2"
                            role="status"
                          ></div>
                          <div
                            className="spinner-grow text-dark m-2 main-color-1"
                            role="status"
                          ></div>
                          <div
                            className="spinner-grow text-dark m-2 main-color-2"
                            role="status"
                          ></div>
                          <div
                            className="spinner-grow text-dark m-2 main-color-1"
                            role="status"
                          ></div>
                          <div
                            className="spinner-grow text-dark m-2 main-color-2"
                            role="status"
                          ></div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </AvForm>
          </div>
        )}
      </div>
    );
  }
}
export default AddBalanceMulti;

import React, { Component } from "react";
import { translations } from "../../../../translate";
import { Col, Row, Progress } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import { CircularProgress } from "@material-ui/core";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
var today = new Date();
var currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);

export default class RequestBalanceRecharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      errMessage: "",
      password: "",
      confirmPassword: "",
      formError: "",
      amount: "",
      progressUploadHeader: "",
      displayErrorHeaderMsg: "",
      fileName: "",
      fileSize: "",
    };
  }
  submitHandler = async () => {
    if(this.state.amount <1){
      return
    }
    this.setState({ startSave: 1 });
    var data = new FormData();
    var cardCodeFile = document.querySelector("input[name=imageFile]")
      ?.files?.[0];
    data.append("type", "sendEmailChargePosBal");
    data.append("userId", localStorage.getItem("USER_ID"));
    data.append("token", localStorage.getItem("USER_TOKEN"));
    data.append("lang", localStorage.getItem("LANG"));
    data.append(`rechargeRequsetImage`, cardCodeFile);
    data.append(`amount`, this.state.amount);
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        if (!response.ok) {
          console.log(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ submitError: data["errorMsg"] });
          } else {
            this.props.handleCloseSideBar();
          }
        }
      });
    this.setState({ startSave: 0 });
  };
  remove = (event) => {
    event.target.value = null;
  };
  handleImgChange = (event) => {
    const files = event.target.files;
    const scope = this;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check file type and process each file
      if (
        file.type.includes("image/jpeg") ||
        file.type.includes("image/png") ||
        file.type.includes("image/gif") ||
        file.type.includes("image/bmp") ||
        file.type.includes("image/webp")
      ) {
        // Set state for the current file
        scope.setState({
          fileName: file.name,
          fileSize: Math.ceil(file.size / 1024) + " KB",
          progressUploadHeader: 0,
        });

        const fileReader = new FileReader();
        fileReader.readAsDataURL(file); // Use readAsDataURL for images

        fileReader.onprogress = function(data) {
          if (data.lengthComputable) {
            const progress = parseInt((data.loaded / data.total) * 100, 10);
            scope.setState({
              progressUploadHeader: progress,
            });
          }
        };

        fileReader.onloadend = function() {
          // Optional: handle image preview or further processing
          scope.setState({
            filePreview: fileReader.result, // This can be used to show a preview of the image
          });
        };
      } else {
        // Handle invalid file type
        scope.setState({ displayErrorHeaderMsg: true });
      }
    }
  };

  componentDidMount() {}
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (this.state.selectedTransfer?.id == 1) {
      //     distCurrencyAmount8
      // rate
      // amount

      this.setState(
        {
          [name]: value,
        },
        function() {}
      );
    } else {
      this.setState(
        {
          [name]: value,
        },
        function() {}
      );
    }
  };

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {`${Strings["Request Balance Charge"]}`}
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>
        <div className="sidebar-body-view">
          {this.state.loading == 1 && (
            <Row>
              <Col md={12} style={{ marginTop: "15%" }} className="text-center">
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}
          {this.state.loading == 0 && (
            <>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <AvForm onSubmit={this.submitHandler}>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <AvField
                          name="amount"
                          type="number"
                          label={Strings["Amount"]}
                          placeholder={Strings["Amount"]}
                          value={this.state.amount}
                          onChange={this.handleInputChange}
                          errorMessage={Strings["This field is required"]}
                          required
                          min="1" 
                        />
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                        <label>{Strings["Images File"]}</label>
                        <span className={"fileInput-span col-md-12"}>
                          <PublishIcon />{" "}
                          <span className="pointer-cursor pt-3">
                            {Strings["Upload"]}
                          </span>
                          <input
                            multiple
                            onClick={(event) => this.remove(event)}
                            type="file"
                            accept="image/jpeg, image/png, image/gif, image/bmp, image/webp"
                            className="fileInput-width fileInput-opacity"
                            onChange={(e) => this.handleImgChange(e)}
                            name="imageFile"
                          ></input>
                        </span>
                        {this.state.progressUploadHeader != undefined &&
                          this.state.progressUploadHeader > 0 && (
                            <Progress
                              striped
                              value={this.state.progressUploadHeader}
                              className="mb-1 mt-1 progress-bg-color"
                            >
                              {this.state.progressUploadHeader} %
                            </Progress>
                          )}
                        {this.state.displayErrorHeaderMsg == true && (
                          <div className="invalid-feedback d-block mb-1">
                            {Strings["Upload Image only"]}
                          </div>
                        )}
                      </Col>
                      {this.state.fileName != "" && this.state.fileSize != "" && (
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-1">
                          <div className="error-text-black file-icon-view">
                            <i className="flaticon-file-2 success-icon mr-1 mb-3 mt-4 " />{" "}
                            {this.state.fileName}{" "}
                            {" (" + this.state.fileSize + ")"}
                          </div>
                        </Col>
                      )}
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {this.state.startSave == 1 ? (
                          <button
                            type="button"
                            className="btn form-save-btn mt-2"
                          >
                            {" "}
                            <CircularProgress className="SaveCircularProgressColor" />
                            {Strings["Save"]}{" "}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn form-save-btn mr-1 ml-1 mt-2"
                          >
                            {" "}
                            {Strings["Save"]}{" "}
                          </button>
                        )}
                      </Col>
                      {this.state.formError && (
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <p className="invalid-feedback d-block mb-1">
                            {this.state.formError}
                          </p>
                        </Col>
                      )}
                    </Row>
                  </AvForm>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    );
  }
}

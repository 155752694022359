import React from "react";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";
import Brand from "../brand/Brand";
import Menu from "./Menu";
import { Drawer } from "@material-ui/core";
import KTOffcanvas from "../../_assets/js/offcanvas";
import { translations } from "../../../translate.js";
import VeiwSupplierApiBalance from "./forms/veiwSupplierApiBalance";
import UserBalance from "./forms/userBalance";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AddOrGetAmount from "../../../app/pages/pos/forms/addOrGetAmount.js";
import BalanceDynamic from "./BalanceDynamic.jsx";
import SupervisorDynamicBalance from "./SupervisorDynamicBalance.jsx";
// import * as bal from "../../../app/store/ducks/balance.duck";
// import { getUserBalance } from "../../../app/crud/balance.crud";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
class AsideLeft extends React.Component {
  asideOffCanvasRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      userBalance: "",
      totalLoanFormat: "",
      openleftModal: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line
    const ktoffConvas = new KTOffcanvas(
      this.asideOffCanvasRef.current,
      this.props.menuCanvasOptions
    );

    if (
      localStorage.getItem("PERM_TYPE") == 2 ||
      localStorage.getItem("PERM_TYPE") == 3
    ) {
      this.getUserBalance();
      this.getDistTotalLoans();
    }
    if (localStorage.getItem("PERM_TYPE") == 5) {
      this.getDistTotalLoans();
    }
    // if (localStorage.getItem('POS_MGR_EMP') == 1) {
    //   this.getUserBalance();
    // }
  }
  openSidebarModal = (event) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({
      openleftModal: true,
    });
  };
  handleCloseSideBar = () => {
    this.setState({
      openleftModal: false,
    });
  };
  handleCloseSideBar2 = () => {
    this.setState({
      openModal: false,
    });
  };
  getUserBalance() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getUserBalance",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ userBalance: data["balanceCurr"] });
          }
        }
      });
  }
  openSidebarModal2 = (event, type, data, viewMode) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({
      propsData: data,
      actionType: type,
      viewMode: viewMode,
      openModal: true,
    });
  };
  getDistTotalLoans() {
    // https://dev45.hoja-crm.com/API/permUser.php?type=getDistTotalLoans&userId=399&qsyTest=-500
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistTotalLoans",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ totalLoanFormat: data["totalLoanFormat"] });
          }
        }
      });
  }
  handleFormAction = (type) => {
    this.setState(
      {
        openModal: false,
      },
      function() {
        if (type == "edit") {
          // this.getAccountData()
        }
      }
    );
  };
  render() {
    return (
      <>
        <button className="kt-aside-close" id="kt_aside_close_btn">
          <i className="la la-close" />
        </button>
        <div
          id="kt-aside"
          ref={this.asideOffCanvasRef}
          className={`kt-aside ${this.props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop`}
        >
          <Brand />
          <div
            id="kt_aside_menu_wrapper"
            className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
          >
            <Menu />
          </div>
          {(localStorage.getItem("PERM_TYPE") == 5 ||
            localStorage.getItem("PERM_TYPE") == 1) && (
            <div
              type="button"
              onClick={(event) =>
                this.openSidebarModal2(event, "addBalance", [])
              }
              className="text-center mb-3 left-menu-balance-view pb-0-m"
            >
              <div className="main-color-1 font-w-500">
                {Strings["Add Balance"]}
              </div>
              <AccountBalanceWalletIcon
                className="pos-main-profile-section-box-icon"
                style={{ width: 50 }}
              />
            </div>
          )}
          {localStorage.getItem("USER_LEVEL") == 3 &&
            localStorage.getItem("ACCOUNT_TYPE") == 7 && <BalanceDynamic />}
          {(localStorage.getItem("PERM_TYPE") == 2 ||
            localStorage.getItem("PERM_TYPE") == 3) && (
            <div className="text-center mb-3 left-menu-balance-view">
              <div className="main-color-1 font-w-500">
                {Strings["Balance"]}
              </div>
              <div>{this.state.userBalance}</div>
            </div>
          )}
          {(localStorage.getItem("PERM_TYPE") == 5 ||
            localStorage.getItem("POS_MGR_EMP") == 1) && <UserBalance />}
          {localStorage.getItem("POS_MGR_EMP_NEW") == 1 && (
              <SupervisorDynamicBalance />
            )}
          {(localStorage.getItem("PERM_TYPE") == 2 ||
            localStorage.getItem("PERM_TYPE") == 3 ||
            localStorage.getItem("PERM_TYPE") == 5) && (
            <div className="text-center mb-3 left-menu-balance-view">
              <div className="main-color-1 font-w-500">
                {Strings["loans Total"]}
              </div>
              <div>{this.state.totalLoanFormat}</div>
            </div>
          )}
          {(localStorage.getItem("ACCOUNT_TYPE") == "" ||
            localStorage.getItem("ACCOUNT_TYPE") == undefined ||
            localStorage.getItem("ACCOUNT_TYPE") == null) &&
            localStorage.getItem("USER_LEVEL") == 1 && (
              <div className="text-center mb-3 left-menu-balance-view">
                <div
                  className="main-color-1 font-w-500 pointer-cursor hover"
                  onClick={(event) => this.openSidebarModal(event)}
                >
                  {Strings["API Suppliers Balances"]}
                </div>
              </div>
            )}
          {(localStorage.getItem("ACCOUNT_TYPE") == "" ||
            localStorage.getItem("ACCOUNT_TYPE") == undefined ||
            localStorage.getItem("ACCOUNT_TYPE") == null) &&
            localStorage.getItem("USER_LEVEL") == 1 && (
              <Drawer
                anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
                open={this.state.openleftModal}
                className="sidebar-modal-view "
              >
                <VeiwSupplierApiBalance
                  handleCloseSideBar={this.handleCloseSideBar}
                />
              </Drawer>
            )}
          <Drawer
            anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
            open={this.state.openModal}
            className={"sidebar-modal-view"}
          >
            {this.state.actionType == "addBalance" && (
              <AddOrGetAmount
                handleCloseSideBar={this.handleCloseSideBar2}
                handleFormAction={this.handleFormAction}
                mainAccount="1"
              />
            )}
          </Drawer>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  disableAsideSelfDisplay:
    builder.selectors.getConfig(store, "aside.self.display") === false,
  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: "aside",
    toString: true,
  }),
  menuCanvasOptions: {
    baseClass: "kt-aside",
    overlay: true,
    closeBy: "kt_aside_close_btn",
    toggleBy: {
      target: "kt_aside_mobile_toggler",
      state: "kt-header-mobile__toolbar-toggler--active",
    },
  },
});

export default connect(mapStateToProps)(AsideLeft);

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { translations } from "../../../../translate.js";
import InfoIcon from "@material-ui/icons/Info";
import { atelierEstuaryDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { toAbsoluteUrl } from "../../../../_metronic";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { CircularProgress } from "@material-ui/core";
import { toPairs } from "lodash";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class SelectBufferAndLinkInv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitError: "",
      cardClassList: [],
      selectedCardClass: [],
      cardClassFields: [],
      loading: 0,
      startSave: 0,
      modeVeiw: this.props.modeVeiw,
      keyActive: this.props.modeVeiw == "buffer" ? "active" : "linkType",
      status: "",
      countCardClass: 0,
      distId: this.props.distId ? this.props.distId : "",
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    this.getSuppliersWithBufferByCardId();
  }

  getSuppliersWithBufferByCardId() {
    this.setState({ loading: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getSuppliersWithBufferByCardId",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        cardId: this.props.cardsData.cardId,
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        this.setState({ loading: 1 });
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              cardClassList: data["classData"],
              cardClassFields: data["fildes"],
              countCardClass: data["countCardClass"],
              loading: 0,
            });
          }
        }
      });
  }
  handleValidSubmit(event) {
    event.preventDefault();
    var value = [];
    var allClasses = [];
    var keyActive = this.state.keyActive;
    if (this.state.selectedCardClass.length > 0) {
      this.setState({ startSave: 1 });
      for (let item in this.state.cardClassFields) {
        if (this.state.cardClassFields[item]["show"] == 1) {
          value = [];
          for (let subItem in this.state.cardClassFields[item]["suppliers"]) {
            value.push({
              supplierKey: this.state.cardClassFields[item]["suppliers"][
                subItem
              ]["supplyerKey"],
              active: this.state.cardClassFields[item]["suppliers"][subItem][
                keyActive
              ],
            });
          }
          allClasses.push({
            classId: this.state.cardClassFields[item]["classId"],
            suppliers: value,
          });
        }
      }
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "updateBufferStatusAndApiLink",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          cardId: this.props.cardsData.cardId,
          typeCheck: this.state.modeVeiw == "buffer" ? 1 : 2,
          allClasses: JSON.stringify(allClasses),
          distId: this.state.distId,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else if (data["error"] == 1) {
              this.setState({
                submitError: data["errorMsg"],
                status: "",
                startSave: 0,
              });
            } else {
              this.setState({ status: data["errorMsg"], startSave: 0 });
            }
          }
        });
    }
  }

  handleChangeSelect = (type, option) => {
    this.setState(
      (state) => {
        return {
          [type]: option,
        };
      },
      function() {
        this.setState(
          {
            errorMsg: "",
            submitError: "",
            status: "",
          },
          function() {
            this.handleClasses();
          }
        );
      }
    );
  };
  handleClasses = () => {
    var show = 0;
    var allCardClass = this.state.cardClassFields;
    var selectedCardClass = this.state.selectedCardClass.map(
      (data) => data.cardClassId
    );
    Object.keys(allCardClass).map((classId, index) => {
      show = selectedCardClass.includes(classId) ? 1 : 0;
      allCardClass[classId]["show"] = show;
    });
    this.setState({ cardClassFields: allCardClass, loading: 0 });
  };

  handleChangeInput = (event, classId = 0, SupplierKey = 0) => {
    const target = event.target;
    const name = target.name;
    const value =
      target.type === "checkbox"
        ? target.checked == true
          ? 1
          : 0
        : target.value;
    var allCardClass = this.state.cardClassFields;
    if (this.state.modeVeiw == "buffer") {
      allCardClass[classId]["suppliers"][SupplierKey]["active"] = value;
    } else {
      allCardClass[classId]["linkInvSupplier"] = value;
      Object.keys(allCardClass[classId]["suppliers"]).map((suppKey, index) => {
        allCardClass[classId]["suppliers"][suppKey]["linkType"] =
          suppKey == value ? 1 : 0;
      });
    }
    this.setState({ cardClassFields: allCardClass });
  };
  displaySupplierData_buffer() {
    var dataMain = [];
    var rowData = [];
    var suppliers = [];
    var keyActive = this.state.keyActive;
    if (this.state.countCardClass > 0) {
      Object.keys(this.state.cardClassFields).map((tableData, index) => {
        if (this.state.cardClassFields[tableData]["show"] == 1) {
          suppliers = [];
          Object.keys(this.state.cardClassFields[tableData]["suppliers"]).map(
            (rowKey, index3) => {
              rowData = this.state.cardClassFields[tableData]["suppliers"][
                rowKey
              ];
              suppliers.push(
                <tag>
                  <AvField
                    label={rowData["name"]}
                    type="checkbox"
                    name={
                      "supplier_" +
                      rowData.supplyerKey +
                      "_" +
                      this.state.cardClassFields[tableData].classId
                    }
                    ref="default"
                    value={rowData["active"] == 1 ? true : false}
                    checked={rowData["active"] == 1 ? true : false}
                    onChange={(event) =>
                      this.handleChangeInput(
                        event,
                        this.state.cardClassFields[tableData].classId,
                        this.state.cardClassFields[tableData]["suppliers"][
                          rowKey
                        ].supplyerKey
                      )
                    }
                    tag={CustomInput}
                    className="custom-form-mb default mb-3 mr-19px pointer-cursor"
                  />
                </tag>
              );
            }
          );

          dataMain.push(
            <div key={index} className="supplier-api-section-box main-color-1 ">
              <div className="  font-title-supplier-api ">
                {this.state.cardClassFields[tableData]["name"]}
              </div>
              <div>{suppliers}</div>
            </div>
          );
        }
      });
    }
    return dataMain;
  }
  displaySupplierData_linkInv() {
    var dataMain = [];
    var rowData = [];
    var suppliers = [];
    if (this.state.countCardClass > 0) {
      Object.keys(this.state.cardClassFields).map((tableData, index) => {
        if (this.state.cardClassFields[tableData]["show"] == 1) {
          suppliers = [];
          Object.keys(this.state.cardClassFields[tableData]["suppliers"]).map(
            (rowKey, index3) => {
              rowData = this.state.cardClassFields[tableData]["suppliers"][
                rowKey
              ];
              suppliers.push(
                <tag>
                  <AvRadio
                    label={rowData["name"]}
                    value={
                      this.state.cardClassFields[tableData]["suppliers"][rowKey]
                        .supplyerKey
                    }
                    onChange={(event) =>
                      this.handleChangeInput(
                        event,
                        this.state.cardClassFields[tableData].classId
                      )
                    }
                    className=" pointer-cursor mb-3"
                  />
                </tag>
              );
            }
          );
          suppliers.push(
            <tag>
              <AvRadio
                label={Strings["Without Supplier"]}
                value={""}
                onChange={(event) =>
                  this.handleChangeInput(
                    event,
                    this.state.cardClassFields[tableData].classId
                  )
                }
                className=" pointer-cursor mb-3"
              />
            </tag>
          );

          dataMain.push(
            <div key={index} className="supplier-api-section-box main-color-1 ">
              <div className="  font-title-supplier-api ">
                {this.state.cardClassFields[tableData]["name"]}
              </div>
              <div>
                <AvRadioGroup
                  className="radio-inv-buffer"
                  name={
                    "radioExample_" +
                    this.state.cardClassFields[tableData].classId
                  }
                  value={this.state.cardClassFields[tableData].linkInvSupplier}
                >
                  {suppliers}
                </AvRadioGroup>
              </div>
            </div>
          );
        }
      });
    }
    return dataMain;
  }

  displaySupplier() {
    var info = [];
    if (this.state.cardClassList.length > 0) {
      info.push(
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className="member-searchable-list mb-2">
            <Picky
              value={this.state.selectedCardClass}
              options={this.state.cardClassList}
              onChange={this.handleChangeSelect.bind(this, "selectedCardClass")}
              open={false}
              valueKey={"cardClassId"}
              labelKey={"cardClassName"}
              selectAllText={Strings["Select All"]}
              allSelectedPlaceholder={Strings["%s selected"]}
              manySelectedPlaceholder={Strings["%s selected"]}
              multiple={true}
              clearFilterOnClose={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={200}
              placeholder={Strings["Select Class"]}
              filterPlaceholder={Strings["Select Class"]}
            />
          </div>
        </Col>
      );
      info.push(
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className="text-right mt-4">
            {this.state.startSave == 0 ? (
              <button type="submit" className="btn form-save-btn mr-1 ml-1">
                {" "}
                {Strings["Save"]}{" "}
              </button>
            ) : (
              <button type="button" className="btn form-save-btn">
                {" "}
                <CircularProgress className="SaveCircularProgressColor" />{" "}
                {Strings["Save"]}{" "}
              </button>
            )}

            <button
              type="button"
              className="btn form-cancel-btn m-l-10"
              onClick={this.props.handleCloseSideBar}
            >
              {Strings["Cancel"]}
            </button>
          </div>

          {this.state.status && (
            <div className="error-text-black">
              <i
                className={"flaticon2-check-mark success-icon mr-1 mb-3 mt-4"}
              />{" "}
              {this.state.status}
            </div>
          )}
          {this.state.submitError && (
            <div className="error-text-black">
              <i className="flaticon2-exclamation error-icon mr-1 mb-3 mt-4" />{" "}
              {this.state.submitError}
            </div>
          )}
        </Col>
      );
    }
    return info;
  }

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {this.state.modeVeiw == "buffer"
                  ? Strings["Edit the Classes Buffer"]
                  : Strings["Edit Inventory Supplier for Classes"]}
                (
                {localStorage.getItem("LANG") == 1
                  ? this.props.cardsData.name
                  : this.props.cardsData.name_en}
                )
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>
        {this.state.loading == 1 && (
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}
        {this.state.loading == 0 && this.state.countCardClass <= 0 && (
          <div>
            <Row>
              <Col md={12} className="text-center" style={{ marginTop: "15%" }}>
                <div>
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(
                      "/media/ezpay/EZPAY_LOGO_GRAY" +
                        window.MAIN_IMAGE +
                        ".png"
                    )}
                    className="img-fluid no-data-img"
                  />
                  <div className="no-data-text">
                    {
                      Strings[
                        "All card classes following this card do not have an API supplier"
                      ]
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
        {this.state.loading == 0 && this.state.countCardClass > 0 && (
          <div className="sidebar-body-view">
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
            >
              <Row>
                <Col
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="add-purchase-order-form-border"
                >
                  <Row>{this.displaySupplier()}</Row>
                </Col>
                <Col lg={9} md={9} sm={12} xs={12}>
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                  >
                    <Masonry>
                      {this.state.modeVeiw == "buffer"
                        ? this.displaySupplierData_buffer()
                        : this.displaySupplierData_linkInv()}
                    </Masonry>
                  </ResponsiveMasonry>

                  {this.state.selectedCardClass.length == 0 && (
                    <div>
                      <Row className="text-center" style={{ marginTop: "15%" }}>
                        <div>
                          <img
                            alt="Logo"
                            src={toAbsoluteUrl(
                              "/media/ezpay/EZPAY_LOGO_GRAY" +
                                window.MAIN_IMAGE +
                                ".png"
                            )}
                            className="img-fluid no-data-img"
                          />
                          <div className="no-data-text">
                            {
                              Strings[
                                "Select card class to display its API suppliers"
                              ]
                            }
                          </div>
                        </div>
                      </Row>
                    </div>
                  )}
                </Col>
              </Row>
            </AvForm>
          </div>
        )}
      </div>
    );
  }
}
export default SelectBufferAndLinkInv;

import React, { Component } from "react";
import { translations } from "../../../../translate";
import { Col, Row, Progress } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import { CircularProgress } from "@material-ui/core";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
var today = new Date();
var currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      errMessage: "",
      password: "",
      confirmPassword: "",
      formError: "",
    };
  }
  submitHandler = async () => {
    this.setState({ startSave: 1 });

    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "changePassword",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        newPassword: this.state.password,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          }
          this.setState({ startSave: 0 });
          if (data.error == 1) {
            this.setState({ formError: data.errorMsg });
          } else {
            this.props.handleCloseSideBar();
          }
        }
      });
  };

  componentDidMount() {}
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (this.state.selectedTransfer?.id == 1) {
      //     distCurrencyAmount8
      // rate
      // amount

      this.setState(
        {
          [name]: value,
        },
        function() {}
      );
    } else {
      this.setState(
        {
          [name]: value,
        },
        function() {}
      );
    }
  };

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {`${Strings["Change Password"]}`}
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>
        <div className="sidebar-body-view">
          {this.state.loading == 1 && (
            <Row>
              <Col md={12} style={{ marginTop: "15%" }} className="text-center">
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}
          {this.state.loading == 0 && (
            <>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <AvForm onSubmit={this.submitHandler}>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <AvField
                          name="password"
                          type="password"
                          label={Strings["New Password"]}
                          value={this.state.password}
                          onChange={this.handleInputChange}
                          errorMessage={Strings["This field is required"]}
                          required
                        />
                      </Col>

                      <Col lg={12} md={12} sm={12} xs={12}>
                        {this.state.startSave == 1 ? (
                          <button type="button" className="btn form-save-btn ">
                            {" "}
                            <CircularProgress className="SaveCircularProgressColor" />
                            {Strings["Save"]}{" "}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn form-save-btn mr-1 ml-1"
                          >
                            {" "}
                            {Strings["Save"]}{" "}
                          </button>
                        )}
                      </Col>
                      {this.state.formError && (
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <p className="invalid-feedback d-block mb-1">
                            {this.state.formError}
                          </p>
                        </Col>
                      )}
                    </Row>
                  </AvForm>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import "react-picky/dist/picky.css";
import { translations } from "../../../../translate.js";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class DaftraForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currencyList: [],
      posUsers: [],
      selectedPointOfSales: [],
      selectedPointOfSalesId: "",
      submitResult: [],
      formView: 1,
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    this.getCurrencies();
  }

  getCurrencies() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCurrencies",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          console.log(data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ currencyList: data });
          }
        }
      });
  }

  setUpSelectCurrencyOptions = (data, type) => {
    return (
      <option value={data.id} key={data.id}>
        {data.currency}
      </option>
    );
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      submitError: "",
    });
  };

  handleValidSubmit(event) {
    event.preventDefault();
    this.props.handleCloseSideBar();
  }

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col md={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Add / Get Amount"]}{" "}
              </div>
            </Col>
            <Col md={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          {this.state.formView == 1 && (
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
            >
              <AvField
                name="posBalanceDate"
                type="date"
                label={Strings["Date"] + " *"}
                value={this.state.posBalanceDate}
                onChange={this.handleInputChange}
                errorMessage={Strings["This field is required"]}
              />
              <AvField
                type="select"
                label={Strings["Type Of Operation"] + " *"}
                name="typeBalance"
                value={this.state.typeBalance}
                onChange={this.handleInputChange}
                className="p-1 pl-3"
                errorMessage={Strings["This field is required"]}
              >
                <option
                  style={{ display: "none" }}
                  value=""
                  selected="selected"
                >
                  {Strings["Select Type Of Operation"]}
                </option>
                <option value="1">{Strings["Add"]}</option>
                <option value="2">{Strings["Get"]}</option>
              </AvField>
              <AvField
                type="select"
                name="currencyId"
                label={Strings["Currency"] + " *"}
                value={this.state.currencyId}
                onChange={this.handleInputChange}
                className="p-1 pl-3"
                errorMessage={Strings["This field is required"]}
              >
                <option
                  style={{
                    display: "none",
                  }}
                  value=""
                  selected="selected"
                >
                  {Strings["Select currency"]}
                </option>
                {this.state.currencyList.map((data) =>
                  this.setUpSelectCurrencyOptions(data, "currency")
                )}
              </AvField>
              <AvField
                name="posAmount"
                min={0.1}
                type="number"
                label={Strings["Amount"] + " *"}
                placeholder={Strings["Enter Amount"]}
                value={this.state.posAmount}
                onChange={this.handleInputChange}
                errorMessage={Strings["This field is required"]}
              />
              <AvField
                name="note1"
                type="textarea"
                label={Strings["Note"] + "(1)"}
                value={this.state.note1}
                onChange={this.handleInputChange}
              />
              <AvField
                name="note2"
                type="textarea"
                label={Strings["Note"] + "(2)"}
                value={this.state.note2}
                onChange={this.handleInputChange}
              />

              <div className="text-right mt-4">
                <button type="submit" className="btn form-save-btn mr-1 ml-1">
                  {" "}
                  {Strings["Save"]}{" "}
                </button>
                <button
                  type="button"
                  className="btn form-cancel-btn m-l-10"
                  onClick={this.props.handleCloseSideBar}
                >
                  {Strings["Cancel"]}
                </button>
              </div>

              {this.state.submitError && (
                <div className="error-text-black mt-3">
                  <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                  {this.state.submitError}
                </div>
              )}
            </AvForm>
          )}
        </div>
      </div>
    );
  }
}
export default DaftraForm;

import React, { Component } from "react";
import {
  Row,
  Col,
  CustomInput,
  Progress,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import Form from "react-bootstrap/Form";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { toAbsoluteUrl } from "../../../../_metronic";
import Pagination from "react-js-pagination";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, Drawer } from "@material-ui/core";
import { translations } from "../../../../translate.js";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import EmailIcon from "@material-ui/icons/Email";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
var today = new Date();
var currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);
class PosFinancialReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      limit: 25,
      activePage: 1,
      dateFrom: currentDate,
      dateTo: currentDate,
      total: 0,
      additionTotal: 0,
      withdrawalTotal: 0,
      orderTotal: 0,
      emptyEmail: 0,
      loadingSave: 0,
      dataCount: 0,
      posId: this.props.posData["userId"],
      posName: this.props.posData["fname"] + " " + this.props.posData["lname"],
      data: [],
      errorMsg: "",
      note1: "",
      totalFilter: 0,
      additionPOSTotal: 0,
      withdrawalPOSTotal: 0,
      note2: "",
      pos_mgr_emp: this.props.posData["accountType"] == 8 ? 1 : 0,
      colNote2: this.props.posData["accountType"] == 8 ? 4 : 6,
      colDate: this.props.posData["accountType"] == 8 ? 1 : 2,
      types_operation_list: [],
      selected_types_operation_list: [],
      note1Title: Strings["Note"] + " 1",
      note2Title: Strings["Note"] + " 2",
      posManagerName: "",
      loadingTips: 0,
      transId: "",
      typesOps: 0,
      paymentTypeKey: "",
      paymentTypes: [],
    };
  }
  handleRefresh = () => {
    // clear all filter data
    this.setState(
      {
        loading: 0,
        activePage: 1,
        dateFrom: currentDate,
        dateTo: currentDate,
        total: 0,
        additionTotal: 0,
        withdrawalTotal: 0,
        orderTotal: 0,
        dataCount: 0,
        note1: "",
        note2: "",
        visibleid: "",
        data: [],
        typesOps: 0,
        selected_types_operation_list: [],
        errorMsg: "",
      },
      function() {
        this.getFinancialReport();
      }
    );
  };

  componentDidMount() {
    console.log(this.state.pageView, "pageView");

    document.addEventListener("mousedown", this.handleClickOutside);
    this.getFinancialReport();
    this.get_types_operation_list();
    this.getFilterPaymentsTypes();
  }
  getFinancialReport(withEmail) {
    this.setState({ sendEmailResult: 0 });
    var sendByMail = "";
    var email = "";
    if (withEmail == 1) {
      sendByMail = 1;
      email = this.state.email;
      this.setState({ loadingSave: 1 });
    } else {
      this.setState({ loading: 1 });
    }
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "pos_financial_operations",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        start: this.state.activePage,
        limit: this.state.limit,
        inPosId: this.state.posId,
        orderFilterDateFrom: this.state.dateFrom,
        orderFilterDateTo: this.state.dateTo,
        posTransFilterNote1: this.state.note1,
        posTransFilterNote2: this.state.note2,
        typeOps: this.state.typesOps,
        sendByMail: sendByMail,
        emails: email,
        distId: this.props.distId ? this.props.distId : "",
        paymentTypeKey: this.state.paymentTypeKey,
      }),

      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ loading: 0, errorMsg: data["errorMsg"] });
          } else {
            if (withEmail == 1) {
              this.setState({ sendEmailResult: 1, loadingSave: 0 });
            } else {
              this.setState({
                data: data["data"],
                dataCount: data["count"],
                loading: 0,
                total: data["total"],
                additionTotal: data["additionTotal"],
                withdrawalTotal: data["withdrawalTotal"],
                additionPOSTotal: data["additionPOSTotal"],
                withdrawalPOSTotal: data["withdrawalPOSTotal"],
                totalFilter: data["totalFilter"],
                orderTotal: data["orderTotal"],
              });
            }
          }
        }
      });
  }
  get_types_operation_list() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "get_types_operation_list",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.props.distId ? this.props.distId : "",
        posMGR: this.state.pos_mgr_emp == 0 ? 0 : 1, //localStorage.getItem('POS_MGR_EMP') == 0 ||
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              types_operation_list: data["data"],
            });
          }
        }
      });
  }
  getFilterPaymentsTypes = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getFilterPaymentsTypes",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        posId: this.state.posId ? this.state.posId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              paymentTypes: data,
            });
          }
        }
      });
  };
  exportHandler() {
    if (this.state.posId != 0) {
      // console.log(this.state);
      window.location =
        window.API_PERM_USER +
        "/permUser.php?type=" +
        "pos_financial_operations" +
        "&lang=" +
        localStorage.getItem("LANG") +
        "&userId=" +
        localStorage.getItem("USER_ID") +
        "&token=" +
        localStorage.getItem("USER_TOKEN") +
        "&orderFilterDateFrom=" +
        this.state.dateFrom +
        "&orderFilterDateTo=" +
        this.state.dateTo +
        "&typeOps=" +
        this.state.typesOps +
        "&inPosId=" +
        this.state.posId +
        "&posTransFilterNote1=" +
        this.state.note1 +
        "&posTransFilterNote2=" +
        this.state.note2 +
        "&distId=" +
        (this.props.distId ? this.props.distId : "") +
        "&export=1&paymentTypeKey=" +
        this.state.paymentTypeKey;
    }
  }
  getTransInfo = (posId, typeId) => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "get_pos_trans_info",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        posId: posId,
        transId: typeId,
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              posManagerName: data["fulName"],
              transId: data["transId"],
              loadingTips: 0,
            });
          }
        }
      });
  };

  handleSendEmail = () => {
    if (
      this.state.email != undefined &&
      this.state.email != null &&
      this.state.email != ""
    ) {
      this.getFinancialReport(1);
      this.setState({ emptyEmail: 0 });
    } else {
      this.setState({ emptyEmail: 1 });
    }
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (name == "email") {
      this.setState({
        [name]: value,
        emptyEmail: 0,
      });
    } else {
      this.setState(
        {
          [name]: value,
          activePage: 1,
        },
        function() {
          this.getFinancialReport();
        }
      );
    }
  };
  handleChangeSelect = (type, option) => {
    this.setState(
      (state) => {
        return {
          [type]: option,
          activePage: 1,
        };
      },
      function() {
        if (type == "selected_types_operation_list") {
          this.setState(
            {
              typesOps: option.typeId,
              paymentTypeKey: option.typeId == 3 ? 1 : "",
            },
            function() {
              this.getFinancialReport();
            }
          );
        }
      }
    );
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getFinancialReport();
    });
  };

  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
          visibleid: "",
        });
      }
    }
  };
  onVisibleChange = (visible, id, type, posId, typeId) => {
    this.setState(
      {
        [type]: id,
        posManagerName: "",
        transId: "",
        loadingTips: 1,
      },
      function() {
        this.getTransInfo(posId, typeId);
      }
    );
  };
  render() {
    let dataInfo = [];
    var typeNmae = "";
    var correctName = "";
    var col = this.state.typesOps == 3 ? 2 : 1;
    var type = "";
    var note1 = "";
    var note2 = "";
    var balBefore = "";
    var balAfter = "";
    var classStyle = {
      "text-1": "succ-color",
      "text-2": "warning-color",
      "text-3": "reject-color",
      "text-5": "addCredit-color",
      "text-6": "wthdrawal-color",
    };
    var classColStatus = "";
    if (this.state.loading == 0 && this.state.data.length > 0) {
      this.state.data.map((rowData, index) => {
        typeNmae =
          localStorage.getItem("LANG") == 1
            ? rowData["typeAR"]
            : rowData["typeEN"];
        correctName =
          localStorage.getItem("LANG") == 1
            ? rowData["correctAR"]
            : rowData["correctEN"];
        note1 = rowData["note1"] == "" ? "-" : rowData["note1"];
        note2 = rowData["note2"] == "" ? "-" : rowData["note2"];
        balBefore =
          rowData["balBefore"] == undefined || rowData["balBefore"] == ""
            ? "-"
            : rowData["balBefore"];
        balAfter =
          rowData["balAfter"] == undefined || rowData["balAfter"] == ""
            ? "-"
            : rowData["balAfter"];
        type = rowData["type"];
        if (type !== "") {
          classColStatus = classStyle["text-" + type];
        }
        classColStatus = "  bold-font " + classColStatus + " "; //overflow-text-length
        dataInfo.push(
          <Row key={rowData.id} className="table-body-row">
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length"
              title={rowData["typeId"]}
            >
              {rowData["typeId"]}
            </Col>
            <Col
              lg={this.state.colDate}
              md={this.state.colDate}
              sm={this.state.colDate}
              xs={12}
              className="overflow-text-length"
              title={rowData["dateTimeZone"]}
            >
              {rowData["dateTimeZone"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length"
              title={rowData["actionDate"]}
            >
              {rowData["actionDate"]}
            </Col>
            {this.state.typesOps != 3 && (
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={12}
                className="overflow-text-length"
                title={note1}
              >
                {note1}
              </Col>
            )}
            {this.state.typesOps != 3 && (
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={12}
                className="overflow-text-length"
                title={note2}
              >
                {note2}
              </Col>
            )}
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className={classColStatus}
              title={typeNmae}
            >
              <span style={{ position: "absolute", zIndex: 9 }}>
                {typeNmae}

                {rowData["posManagerTypeId"] != "" &&
                  rowData["posManagerTypeId"] != 0 &&
                  this.props.posData["accountType"] == 7 &&
                  (rowData["type"] == 1 || rowData["type"] == 2) &&
                  (localStorage.getItem("PERM_TYPE") == 5 ||
                    this.props.permType == 5 ||
                    localStorage.getItem("POS_MGR_EMP") == 1 ||
                    localStorage.getItem("OPERA_EMP") == 1 ||
                    localStorage.getItem("ACC_EMP") == 1) && (
                    <Tooltip
                      placement={
                        localStorage.getItem("LANG") == 1 ? "left" : "right"
                      }
                      title={Strings["POS Manager"]}
                      visible={
                        this.state.visibleid == rowData["id"] &&
                        this.state.loadingTips == 0
                          ? true
                          : false
                      }
                      animation="zoom"
                      onVisibleChange={(event) =>
                        this.onVisibleChange(
                          event,
                          rowData["id"],
                          "visibleid",
                          rowData["posId"],
                          rowData["typeId"]
                        )
                      }
                      trigger="click"
                      getTooltipContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      overlay={
                        <span className="report-tooltip-span">
                          <p className="mb-0 mr-1 tooltipRequestStyle">
                            {Strings["POS Manager"]} :{" "}
                            {this.state.posManagerName}
                          </p>
                          <p className="mb-0 mr-1 tooltipRequestStyle">
                            {Strings["Trans Id"]} : {this.state.transId}
                          </p>
                        </span>
                      }
                    >
                      {this.state.loadingTips == 1 &&
                      this.state.visibleid == rowData["id"] ? (
                        <CircularProgress className="CircularProgressColor-dark-gray mt-1 ml-2  mb-0 hover-gray " />
                      ) : (
                        <InfoOutlinedIcon
                          title={Strings["POS Manager"]}
                          className=" mt-1 ml-2 mb-1  hover-gray"
                        />
                      )}
                    </Tooltip>
                  )}
              </span>
            </Col>
            <Col
              lg={this.state.paymentTypeKey == 9 ? 1 : col}
              md={this.state.paymentTypeKey == 9 ? 1 : col}
              sm={this.state.paymentTypeKey == 9 ? 1 : col}
              xs={12}
              className="overflow-text-length"
              title={rowData["amountFormat"]}
            >
              {rowData["amountFormat"]}
            </Col>
            {this.state.pos_mgr_emp == 1 && (
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={12}
                className="overflow-text-length"
                title={rowData["posTypeId"]}
              >
                {rowData["posTypeId"]}
              </Col>
            )}
            <Col
              lg={this.state.paymentTypeKey == 9 ? col : 1}
              md={this.state.paymentTypeKey == 9 ? col : 1}
              sm={this.state.paymentTypeKey == 9 ? col : 1}
              xs={12}
              className="overflow-text-length"
              title={
                this.state.paymentTypeKey == 9 ? rowData.referenceId : balBefore
              }
            >
              {this.state.paymentTypeKey == 9 ? rowData.referenceId : balBefore}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length"
              title={
                this.state.paymentTypeKey == 9 ? rowData.paymentUrl : balAfter
              }
            >
              {this.state.paymentTypeKey == 9 ? (
                <a href={rowData.paymentUrl}>{Strings.clickHere}</a>
              ) : (
                balAfter
              )}
            </Col>
            <Col
              lg={col}
              md={col}
              sm={col}
              xs={12}
              className="overflow-text-length"
              title={correctName}
            >
              {correctName}
            </Col>
          </Row>
        );
      });
    }
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col xs={10} lg={10} md={10} sm={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Financial Report"]} ({this.state.posName})
              </div>
            </Col>
            <Col md={2} xs={2} lg={2} sm={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view sidebar-body-view-supplier-transaction">
          <Row className="page-filter-view">
            <Col md={6} xs={12} lg={6} sm={12}>
              <AvForm
                onValidSubmit={this.handleValidSubmit}
                ref={(c) => (this.form = c)}
              >
                <Row className="filter-form">
                  <Col
                    xs={12}
                    lg={this.props.pageView != 1 ? 5 : 10}
                    md={this.props.pageView != 1 ? 5 : 10}
                    sm={12}
                  >
                    <div className="member-searchable-list mb-2">
                      <Picky
                        value={this.state.selected_types_operation_list}
                        options={this.state.types_operation_list}
                        onChange={this.handleChangeSelect.bind(
                          this,
                          "selected_types_operation_list"
                        )}
                        open={false}
                        valueKey="typeId"
                        labelKey={
                          localStorage.getItem("LANG") == 1
                            ? "typeAR"
                            : "typeEN"
                        }
                        multiple={false}
                        includeSelectAll={true}
                        includeFilter={true}
                        dropdownHeight={200}
                        placeholder={Strings["Operation Type"]}
                        filterPlaceholder={Strings["Select Operation Type"]}
                        selectAllText={Strings["Select All"]}
                        allSelectedPlaceholder={Strings["%s selected"]}
                        manySelectedPlaceholder={Strings["%s selected"]}
                        clearFilterOnClose={true}
                      />
                    </div>
                  </Col>

                  {this.props.pageView != 1 && (
                    <Col lg={5} md={5} sm={5} xs={10}>
                      <AvField
                        type="select"
                        name="paymentTypeKey"
                        value={this.state.paymentTypeKey}
                        onChange={this.handleInputChange}
                        className="p-1 pl-3"
                        errorMessage={Strings["This field is required"]}
                        disabled={
                          this.state.selected_types_operation_list?.typeId != 3
                        }
                      >
                        <option
                          style={{ display: "none" }}
                          value=""
                          selected="selected"
                        >
                          {Strings["Select"] + " " + Strings["Payment type"]}
                        </option>
                        {this.state.paymentTypes.map((data) => (
                          <option value={data.id} key={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </AvField>
                    </Col>
                  )}
                  <Col md={2} xs={12} lg={2} sm={12} className="mb-1">
                    <Button
                      variant="contained"
                      className="refresh-action-btn   mb-md-0"
                      onClick={this.handleRefresh}
                      title={Strings["Refresh"]}
                    >
                      <i className="flaticon-refresh reset-filter-btn"></i>
                    </Button>
                  </Col>

                  {this.state.typesOps != 3 && (
                    <Col md={6} xs={12} lg={6} sm={12}>
                      <AvField
                        name="note1"
                        type="text"
                        placeholder={Strings["Note"] + " 1"}
                        value={this.state.note1}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  )}
                  {this.state.typesOps != 3 && (
                    <Col md={6} xs={12} lg={6} sm={12}>
                      <AvField
                        name="note2"
                        type="text"
                        placeholder={Strings["Note"] + " 2"}
                        value={this.state.note2}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  )}
                  <Col md={6} xs={12} lg={6} sm={12} className="mb-1">
                    <AvField
                      name="dateFrom"
                      type="date"
                      label={Strings["From Date"]}
                      value={this.state.dateFrom}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col md={6} xs={12} lg={6} sm={12} className="mb-1">
                    <AvField
                      name="dateTo"
                      type="date"
                      label={Strings["To Date"]}
                      value={this.state.dateTo}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col md={12}>
                    <hr className="filter-hr" />
                  </Col>
                  <Col md={6} lg={6} sm={6}>
                    <AvField
                      name="email"
                      type="email"
                      label={Strings["Emails"]}
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      className={
                        this.state.emptyEmail == 1 && "filter-empty-email"
                      }
                    />
                    {this.state.sendEmailResult == 1 && (
                      <div className="main-color-1">
                        {Strings["We will send you this report by email"]}
                      </div>
                    )}
                  </Col>
                  <Col md={6} lg={6} sm={6}>
                    {this.state.loadingSave == 1 ? (
                      <Button
                        variant="contained"
                        disabled
                        className="shadow-sm refresh-action-btn refresh-btn-margin filter-send-email-btn task-action-btn-loading "
                      >
                        <EmailIcon className="reset-filter-btn mr-2 ml-2" />{" "}
                        <CircularProgress className="SaveCircularProgressColor" />{" "}
                        {Strings["Send By Email"]}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="refresh-action-btn refresh-btn-margin filter-send-email-btn"
                        onClick={this.handleSendEmail}
                      >
                        <EmailIcon className="reset-filter-btn mr-2 ml-2" />{" "}
                        {Strings["Send By Email"]}
                      </Button>
                    )}
                  </Col>
                </Row>
              </AvForm>
            </Col>
            <Col
              md={6}
              xs={12}
              lg={6}
              sm={12}
              className={
                (localStorage.getItem("PERM_TYPE") == 5 ||
                  this.props.permType == 5 ||
                  localStorage.getItem("POS_MGR_EMP") == 1 ||
                  localStorage.getItem("ACC_EMP") == 1 ||
                  localStorage.getItem("OPERA_EMP") == 1) &&
                this.state.typesOps != 0
                  ? "text-right position-parent-square"
                  : "text-right"
              }
            >
              <button
                className="btn page-action-btn ml-1"
                onClick={(event) => this.exportHandler(event)}
              >
                {Strings["Export"]}
              </button>
              {(localStorage.getItem("PERM_TYPE") == 5 ||
                this.props.permType == 5 ||
                localStorage.getItem("POS_MGR_EMP") == 1 ||
                localStorage.getItem("ACC_EMP") == 1 ||
                localStorage.getItem("OPERA_EMP") == 1) &&
                this.state.loading == 0 &&
                this.state.data.length > 0 && (
                  <Row
                    className={
                      this.state.typesOps != 0
                        ? "position-child-square mr-1 ml-1"
                        : "mr-1 ml-1"
                    }
                  >
                    {/* md={4} lg={6} sm={12} */}
                    <Col
                      xs={12}
                      lg={6}
                      md={12}
                      sm={12}
                      className="d-flex align-items-end js-c"
                    >
                      <Row>
                        {this.state.pos_mgr_emp == 0 ? (
                          <div className="finDistReport-square-view-pos mr-pos  ">
                            {(this.state.typesOps == 0 ||
                              this.state.typesOps == 1) && (
                              <div
                                className={
                                  this.state.typesOps == 0
                                    ? "task-square-div"
                                    : "task-square-div mb-0"
                                }
                              >
                                {" "}
                                <div
                                  className={
                                    this.state.typesOps == 0
                                      ? "task-square-3"
                                      : "task-square-3 mb-0"
                                  }
                                ></div>{" "}
                                <p className="mb-0 mr-1">
                                  {" "}
                                  {Strings["Add Credit To POS"]}{" "}
                                </p>{" "}
                              </div>
                            )}
                            {(this.state.typesOps == 0 ||
                              this.state.typesOps == 2) && (
                              <div
                                className={
                                  this.state.typesOps == 0
                                    ? "task-square-div"
                                    : "task-square-div mb-0"
                                }
                              >
                                {" "}
                                <div className="task-square-2"></div>{" "}
                                <p className="mb-0 mr-1">
                                  {" "}
                                  {Strings["Balance withdrawal From POS"]}{" "}
                                </p>{" "}
                              </div>
                            )}
                            {(this.state.typesOps == 0 ||
                              this.state.typesOps == 3) && (
                              <div className="task-square-div mb-0">
                                {" "}
                                <div className="task-square-4  mb-0"></div>{" "}
                                <p className="mb-0 mr-1">
                                  {" "}
                                  {Strings["Request"]}{" "}
                                </p>{" "}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="finDistReport-square-view-pos mr-pos  ">
                            {(this.state.typesOps == 0 ||
                              this.state.typesOps == 1) && (
                              <div
                                className={
                                  this.state.typesOps == 0
                                    ? "task-square-div "
                                    : "task-square-div  mb-0"
                                }
                              >
                                {" "}
                                <div className="task-square-3"></div>{" "}
                                <p className="mb-0 mr-1">
                                  {" "}
                                  {Strings["Add Credits"]}{" "}
                                </p>{" "}
                              </div>
                            )}
                            {(this.state.typesOps == 0 ||
                              this.state.typesOps == 2) && (
                              <div
                                className={
                                  this.state.typesOps == 0
                                    ? "task-square-div "
                                    : "task-square-div  mb-0"
                                }
                              >
                                {" "}
                                <div className="task-square-2"></div>{" "}
                                <p className="mb-0 mr-1">
                                  {" "}
                                  {Strings["Balance withdrawal"]}
                                </p>{" "}
                              </div>
                            )}
                            {(this.state.typesOps == 0 ||
                              this.state.typesOps == 5) && (
                              <div
                                className={
                                  this.state.typesOps == 0
                                    ? "task-square-div "
                                    : "task-square-div  mb-0"
                                }
                              >
                                {" "}
                                <div className="finDistReport-square-1"></div>{" "}
                                <p className="mb-0 mr-1">
                                  {" "}
                                  {Strings["Add Credit To POS"]}
                                </p>{" "}
                              </div>
                            )}
                            {(this.state.typesOps == 0 ||
                              this.state.typesOps == 6) && (
                              <div className={"task-square-div mb-0"}>
                                {" "}
                                <div className="finDistReport-square-2"></div>{" "}
                                <p className="mb-0 mr-1">
                                  {" "}
                                  {Strings["Balance withdrawal From POS"]}
                                </p>{" "}
                              </div>
                            )}
                          </div>
                        )}
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      lg={6}
                      md={12}
                      sm={12}
                      className="mt-3 algin-total-box financail-operation-report-summary-box-view"
                    >
                      {this.state.typesOps == 0 ? (
                        <div>
                          <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                            <Col
                              md={8}
                              xs={12}
                              lg={8}
                              sm={12}
                              className="main-color-1 font-w-400 textRight"
                            >
                              {Strings["Number Of Operations"]}
                            </Col>
                            <Col
                              md={4}
                              xs={12}
                              lg={4}
                              sm={12}
                              className="text-center"
                            >
                              {this.state.dataCount}
                            </Col>
                          </Row>
                          <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                            <Col
                              md={8}
                              xs={12}
                              lg={8}
                              sm={12}
                              className="main-color-1 font-w-400"
                            >
                              {Strings["Total amounts that added"]}
                            </Col>
                            <Col
                              md={4}
                              xs={12}
                              lg={4}
                              sm={12}
                              className="text-center"
                            >
                              {this.state.additionTotal}
                            </Col>
                          </Row>
                          <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                            <Col
                              md={8}
                              xs={12}
                              lg={8}
                              sm={12}
                              className="main-color-1 font-w-400"
                            >
                              {Strings["Total amount that pulled"]}
                            </Col>
                            <Col
                              md={4}
                              xs={12}
                              lg={4}
                              sm={12}
                              className="text-center"
                            >
                              {this.state.withdrawalTotal}
                            </Col>
                          </Row>
                          {this.state.pos_mgr_emp == 0 && (
                            <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                              <Col
                                md={8}
                                xs={12}
                                lg={8}
                                sm={12}
                                className="main-color-1 font-w-400"
                              >
                                {Strings["Orders total amount"]}
                              </Col>
                              <Col
                                md={4}
                                xs={12}
                                lg={4}
                                sm={12}
                                className="text-center"
                              >
                                {this.state.orderTotal}
                              </Col>
                            </Row>
                          )}
                          {this.state.pos_mgr_emp == 1 && (
                            <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                              <Col
                                md={8}
                                xs={12}
                                lg={8}
                                sm={12}
                                className="main-color-1 font-w-400"
                              >
                                {Strings["Total additions to POS"]}
                              </Col>
                              <Col
                                md={4}
                                xs={12}
                                lg={4}
                                sm={12}
                                className="text-center"
                              >
                                {this.state.additionPOSTotal}
                              </Col>
                            </Row>
                          )}
                          {this.state.pos_mgr_emp == 1 && (
                            <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                              <Col
                                md={8}
                                xs={12}
                                lg={8}
                                sm={12}
                                className="main-color-1 font-w-400"
                              >
                                {Strings["Total corrections to POS"]}
                              </Col>
                              <Col
                                md={4}
                                xs={12}
                                lg={4}
                                sm={12}
                                className="text-center"
                              >
                                {this.state.withdrawalPOSTotal}
                              </Col>
                            </Row>
                          )}
                          <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                            <Col
                              md={8}
                              xs={12}
                              lg={8}
                              sm={12}
                              className="main-color-1 font-w-400"
                            >
                              {Strings["Total balance"]}
                            </Col>
                            <Col
                              md={4}
                              xs={12}
                              lg={4}
                              sm={12}
                              className="text-center"
                            >
                              {this.state.total}
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div>
                          <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                            <Col
                              md={8}
                              xs={12}
                              lg={8}
                              sm={12}
                              className="main-color-1 font-w-400"
                            >
                              {Strings["Total balance"]}
                            </Col>
                            <Col
                              md={4}
                              xs={12}
                              lg={4}
                              sm={12}
                              className="text-center"
                            >
                              {this.state.totalFilter}
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                  </Row>
                )}
            </Col>
          </Row>

          {this.state.loading == 1 && (
            <Row>
              <Col
                md={12}
                xs={12}
                lg={12}
                sm={12}
                style={{ marginTop: "15%" }}
                className="text-center"
              >
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}

          {this.state.loading == 0 && this.state.errorMsg != "" && (
            <div className="text-center" style={{ marginTop: "15%" }}>
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">{this.state.errorMsg}</div>
            </div>
          )}

          {this.state.loading == 0 && this.state.data.length <= 0 && (
            <div className="text-center" style={{ marginTop: "15%" }}>
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">
                {Strings["There is no data to display"]}
              </div>
            </div>
          )}

          {this.state.loading == 0 && this.state.data.length > 0 && (
            <Row>
              <div className="inv-cards-view width-100">
                <Row className="table-header-row">
                  <Col
                    lg={1}
                    md={1}
                    sm={1}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Operation Number"]}
                  >
                    {Strings["Operation Number"]}
                  </Col>
                  <Col
                    lg={this.state.colDate}
                    md={this.state.colDate}
                    sm={this.state.colDate}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Entry Date"]}
                  >
                    {Strings["Entry Date"]}
                  </Col>
                  <Col
                    lg={1}
                    md={1}
                    sm={1}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Operation Date"]}
                  >
                    {Strings["Operation Date"]}
                  </Col>
                  {this.state.typesOps != 3 && (
                    <Col
                      lg={1}
                      md={1}
                      sm={1}
                      xs={12}
                      className="overflow-text-length"
                      title={this.state.note1Title}
                    >
                      {this.state.note1Title}
                    </Col>
                  )}
                  {this.state.typesOps != 3 && (
                    <Col
                      lg={1}
                      md={1}
                      sm={1}
                      xs={12}
                      className="overflow-text-length"
                      title={this.state.note2Title}
                    >
                      {this.state.note2Title}
                    </Col>
                  )}
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Operation Type"]}
                  >
                    {Strings["Operation Type"]}
                  </Col>
                  <Col
                    lg={this.state.typesOps != 3 ? 1 : 2}
                    md={this.state.typesOps != 3 ? 1 : 2}
                    sm={this.state.typesOps != 3 ? 1 : 2}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Amount"]}
                  >
                    {Strings["Amount"]}
                  </Col>
                  {this.state.pos_mgr_emp == 1 && (
                    <Col
                      lg={1}
                      md={1}
                      sm={1}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Pos Trans #"]}
                    >
                      {Strings["Pos Trans #"]}
                    </Col>
                  )}
                  <Col
                    lg={1}
                    md={1}
                    sm={1}
                    xs={12}
                    className="overflow-text-length"
                    title={
                      this.state.paymentTypeKey == 9
                        ? Strings.referenceId
                        : Strings["Balance Before Transaction"]
                    }
                  >
                    {this.state.paymentTypeKey == 9
                      ? Strings.referenceId
                      : Strings["Balance Before Transaction"]}
                  </Col>
                  <Col
                    lg={1}
                    md={1}
                    sm={1}
                    xs={12}
                    className="overflow-text-length"
                    title={
                      this.state.paymentTypeKey == 9
                        ? Strings.paymentUrl
                        : Strings["Balance After Transaction"]
                    }
                  >
                    {this.state.paymentTypeKey == 9
                      ? Strings.paymentUrl
                      : Strings["Balance After Transaction"]}
                  </Col>
                  <Col
                    lg={this.state.typesOps != 3 ? 1 : 2}
                    md={this.state.typesOps != 3 ? 1 : 2}
                    sm={this.state.typesOps != 3 ? 1 : 2}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Correction Type"]}
                  >
                    {this.state.paymentTypeKey != 9
                      ? Strings["Correction Type"]
                      : Strings.paymentStatus}
                  </Col>
                </Row>
                {dataInfo}
                {this.state.loading == 0 && this.state.data.length > 0 && (
                  <Col md={12} xs={12} lg={12} sm={12}>
                    <Row className="pagenation-view mt-3 mb-4">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.limit}
                        totalItemsCount={this.state.dataCount}
                        pageRangeDisplayed={4}
                        onChange={this.handlePageChange}
                        prevPageText={
                          <i
                            className={
                              localStorage.getItem("LANG") == 1
                                ? "flaticon2-next"
                                : "flaticon2-back"
                            }
                          ></i>
                        }
                        nextPageText={
                          <i
                            className={
                              localStorage.getItem("LANG") == 1
                                ? "flaticon2-back"
                                : "flaticon2-next"
                            }
                          ></i>
                        }
                        hideFirstLastPages
                        innerClass={
                          "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                        }
                        itemClass={"page-item"}
                        linkClass={"page-link"}
                      />
                    </Row>
                  </Col>
                )}
              </div>
            </Row>
          )}
        </div>
      </div>
    );
  }
}
export default PosFinancialReport;

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from "../../../../translate.js";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import DeleteIcon from "@material-ui/icons/Delete";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

const SortableItem = SortableElement(({ value }) => (
  <div
    style={{
      position: "relative",
      width: "100%",
      display: "block",
      padding: 10,
      backgroundColor: "#FFF",
      borderBottom: "1px solid #EFEFEF",
      boxSizing: "border-box",
      WebkitUserSelect: "none",
      cursor: "all-scroll",
      opacity: 10,
      visibility: "visible",
    }}
  >
    {value}
  </div>
));

const SortableList = SortableContainer(({ items }) => (
  <div
    className="sort-box-width-mobile"
    style={{
      width: "100%",
      margin: "0 auto",
      overflow: "auto",
      backgroundColor: "#f3f3f3",
      border: "1px solid #EFEFEF",
      borderRadius: 6,
      opacity: 10,
      visibility: "visible",
    }}
  >
    {items.map((value, index) => (
      <SortableItem
        key={`item-${index}`}
        hideSortableGhost={false}
        index={index}
        value={value.title}
      />
    ))}
  </div>
));

class CardExplanations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteImage: 0,
      title: "",
      desc: "",
      link: "",
      data: [],
      parentCardInfo: this.props.parentCardInfo,
      loading: 1,
      viewMode: 1, // 1 : table , 2 : sort
      items: [],
      distId: this.props.distId ? this.props.distId : "",
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    this.getCardExplanations();
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });
  };

  getCardExplanations() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCardExplanations",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        cardId: this.state.parentCardInfo.cardId,
        status: 1,
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              data: data["data"],
              items: data["data"],
              loading: 0,
            });
          }
        }
      });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      submitError: "",
    });
  };

  handleImgChange = (event) => {
    var fileReader = new FileReader();
    const scope = this;

    if (event.target.files[0].type.includes("image")) {
      this.setState({ displayErrorHeaderMsg: false });
      this.setState({ progressUploadHeader: 10 });
      fileReader.readAsBinaryString(event.target.files[0]);
      fileReader.onprogress = function(data) {
        if (data.lengthComputable) {
          var progress = parseInt((data.loaded / data.total) * 100, 10);
          scope.setState({ progressUploadHeader: progress });
        }
      };
    } else {
      this.setState({ displayErrorHeaderMsg: true });
    }

    this.setState({
      image: event.target.files[0]["name"],
    });
  };

  displayData() {
    var list = "";
    if (this.state.data.length > 0) {
      list = this.state.data.map((rowData, index) => {
        return (
          <Row key={index} className="table-body-row">
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="main-color-1 font-w-400"
            >
              {rowData["id"]}
            </Col>
            <Col lg={9} md={9} sm={9} xs={9}>
              {" "}
              {rowData["title"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={1}
              className="text-right d-inline inv-category-box-action-btn"
            >
              <i
                className="flaticon2-writing mr-3"
                title={Strings["Edit"]}
                onClick={(event) => this.editCardExplanations(event, rowData)}
              />
              <i
                className="flaticon2-cancel-music"
                title={Strings["Delete"]}
                onClick={(event) =>
                  this.deleteCardExplanations(event, rowData.id)
                }
              />
            </Col>
          </Row>
        );
      });
    }
    return list;
  }

  editCardExplanations = (event, data) => {
    event.preventDefault();
    this.setState({
      title: data["title"],
      desc: data["description"],
      imageUrl: data["img"],
      link: data["link"],
      expId: data["id"],
    });
  };

  handleCancelAddForm = (event) => {
    event.preventDefault();
    this.setState(
      {
        title: "",
        desc: "",
        link: "",
        imageUrl: "",
        deleteImage: 0,
        expId: "",
      },
      function() {
        document.querySelector("input[name=image]").value = "";
      }
    );
  };

  deleteCardExplanations = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to delete this description ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };

  handleClickDelete(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "deleteCardExplanations",
        expId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.getCardExplanations();
          }
        }
      });
  }

  handleValidSubmit(event) {
    event.preventDefault();
    var data = new FormData();
    this.setState({ imageUrl: "" });
    var image = document.querySelector("input[name=image]").files[0];
    var deletedImages = 0;
    if (this.state.deleteImage == 1) {
      deletedImages = "1";
    }
    data.append("data", image);
    data.append("type", "addCardExplanations");
    data.append("userId", localStorage.getItem("USER_ID"));
    data.append("token", localStorage.getItem("USER_TOKEN"));
    data.append("lang", localStorage.getItem("LANG"));
    data.append("cardId", this.state.parentCardInfo.cardId);
    data.append("status", 1);
    data.append("title", this.state.title);
    data.append("desc", this.state.desc);
    data.append("expId", this.state.expId);
    data.append("deletedImages", deletedImages);
    data.append("link", this.state.link);
    data.append("distId", this.state.distId);
    fetch(window.API_PERM_USER, {
      // mode: 'no-cors',
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        if (!response.ok) {
          // console.log(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('===============================');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ submitError: data["errorMsg"] });
          } else {
            this.form && this.form.reset();
            this.setState(
              {
                title: "",
                desc: "",
                image: "",
                link: "",
                deleteImage: 0,
                progressUploadHeader: 0,
                expId: "",
              },
              function() {
                document.querySelector("input[name=image]").value = "";
                this.getCardExplanations();
              }
            );
          }
        }
      });
  }

  viewSortSection = (event, type) => {
    event.preventDefault();
    this.setState({ viewMode: type });
  };

  handleSortAction = (event) => {
    event.preventDefault();

    if (this.state.data.length > 0) {
      var sortedIds = "";
      this.state.items.map((rowData, index) => {
        if (sortedIds != "" && sortedIds != null && sortedIds != undefined) {
          sortedIds = sortedIds + "," + rowData.id;
        } else {
          sortedIds = rowData.id;
        }
      });

      // console.log('---------');
      // console.log(sortedIds);

      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "sortCardExplanations",
          distId: this.state.distId,

          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          cardId: this.state.parentCardInfo.cardId,
          expIds: sortedIds,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState({ viewMode: 1 }, function() {
                this.getCardExplanations();
              });
            }
          }
        });
    }
  };
  deleteUploadImage = () => {
    this.setState({ deleteImage: 1 });
  };
  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Card Description"]}(
                {localStorage.getItem("LANG") == 1
                  ? this.props.parentCardInfo.name
                  : this.props.parentCardInfo.name_en}
                )
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <Row>
            <Col lg={5} md={5} sm={5} xs={12} className="card-desc-form-view">
              <div>
                <AvForm
                  onValidSubmit={this.handleValidSubmit}
                  ref={(c) => (this.form = c)}
                >
                  <AvField
                    name="title"
                    label={Strings["Title"] + " *"}
                    placeholder={Strings["Enter Title"]}
                    value={this.state.title}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <AvField
                    name="link"
                    label={Strings["Link"] + " *"}
                    placeholder={Strings["Enter Link"]}
                    value={this.state.link}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <AvField
                    name="desc"
                    type="textarea"
                    label={Strings["Description"] + " *"}
                    required
                    placeholder={Strings["Enter Description"]}
                    value={this.state.desc}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />

                  <div className="mt-2 mb-2">
                    <label>{Strings["Image"]}</label>
                    <span className={"fileInput-span col-md-12"}>
                      <PublishIcon />{" "}
                      <span className="pointer-cursor pt-3">
                        {Strings["Upload"]}
                      </span>
                      <input
                        type="file"
                        accept="image/*"
                        className="fileInput-width fileInput-opacity"
                        onChange={(e) => this.handleImgChange(e)}
                        name="image"
                      ></input>
                    </span>

                    {this.state.progressUploadHeader != undefined &&
                      this.state.progressUploadHeader > 0 && (
                        <Progress
                          striped
                          value={this.state.progressUploadHeader}
                          className="mb-1 mt-1 progress-bg-color"
                        >
                          {this.state.progressUploadHeader} %
                        </Progress>
                      )}
                    {this.state.displayErrorHeaderMsg == true && (
                      <div className="invalid-feedback d-block mb-1">
                        {Strings["Upload Image only"]}
                      </div>
                    )}
                    {this.state.imageUrl != null &&
                      this.state.imageUrl != undefined &&
                      this.state.imageUrl != "" &&
                      this.state.deleteImage == 0 && (
                        <div className="text-center mt-4">
                          <img
                            src={this.state.imageUrl}
                            className="img-fluid inv-category-img"
                          />
                          <div
                            className="task-action-file-details-delete-icon"
                            onClick={() => this.deleteUploadImage("1")}
                          >
                            <span>
                              <DeleteIcon /> {Strings["Delete"]}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="text-right mt-4">
                    <button
                      type="submit"
                      className="btn form-save-btn mr-1 ml-1"
                    >
                      {" "}
                      {Strings["Save"]}{" "}
                    </button>
                    <button
                      type="button"
                      className="btn form-cancel-btn m-l-10"
                      onClick={(e) => this.handleCancelAddForm(e)}
                    >
                      {Strings["Cancel"]}
                    </button>
                  </div>
                </AvForm>
              </div>
            </Col>
            <Col lg={7} md={7} sm={7} xs={12}>
              {this.state.loading == 1 && (
                <Row>
                  <Col
                    md={12}
                    style={{ marginTop: "15%" }}
                    className="text-center"
                  >
                    <div className="card-body">
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                    </div>
                  </Col>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length <= 0 && (
                <Row className="text-center" style={{ marginTop: "15%" }}>
                  <div>
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl(
                        "/media/ezpay/EZPAY_LOGO_GRAY" +
                          window.MAIN_IMAGE +
                          ".png"
                      )}
                      className="img-fluid no-data-img"
                    />
                    <div className="no-data-text">
                      {Strings["There is no data to display"]}
                    </div>
                  </div>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <div className="inv-cards-view">
                  <Row>
                    <Col
                      md={6}
                      className="mb-4 pr-0 pl-0 main-color-1 font-w-400 mt-2 f-15"
                    >
                      {this.state.viewMode == 1
                        ? Strings["Card Description"]
                        : Strings["Sort Card Description"]}
                    </Col>
                    <Col md={6} className="text-right mb-4 pr-0 pl-0">
                      {this.state.viewMode == 1 ? (
                        <button
                          className="btn page-action-btn ml-1"
                          onClick={(event) => this.viewSortSection(event, 2)}
                        >
                          {Strings["Sort"]}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn form-cancel-btn m-l-10"
                          onClick={(event) => this.viewSortSection(event, 1)}
                        >
                          {Strings["Cancel"]}
                        </button>
                      )}
                    </Col>
                  </Row>
                  {this.state.viewMode == 1 && (
                    <div>
                      <Row className="table-header-row">
                        <Col lg={2} md={2} sm={2} xs={2}>
                          {Strings["Id"]}
                        </Col>
                        <Col lg={9} md={9} sm={9} xs={9}>
                          {Strings["Title"]}
                        </Col>
                        <Col
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          className="text-center"
                        >
                          {Strings["Action"]}
                        </Col>
                      </Row>
                      {this.displayData()}
                    </div>
                  )}

                  {this.state.viewMode == 2 && (
                    <Row>
                      <SortableList
                        items={this.state.items}
                        onSortEnd={this.onSortEnd}
                      />
                      <button
                        className="btn form-save-btn mr-1 ml-1 mt-3"
                        onClick={(event) => this.handleSortAction(event)}
                      >
                        {" "}
                        {Strings["Save"]}{" "}
                      </button>
                    </Row>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default CardExplanations;

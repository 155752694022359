import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from '../../../../translate.js';

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class ViewClassesPos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cradsInfo: this.props.orderRowData.cradsInfo,
            orderId: this.props.orderRowData.id,
            loading: 1,
            totalDrown: 0,
            totalReq: 0,
            errorMsg: '',
            data: []
        }
    }
    componentDidMount() {
        this.openClassList()
    }
    openClassList = (id) => {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getClassInfoToOrderPos',
                'userId': localStorage.getItem('USER_ID'),
                'lang': localStorage.getItem('LANG'),
                'token': localStorage.getItem('USER_TOKEN'),
                'orderId': this.state.orderId,

            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {

            if (typeof data !== 'undefined') {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 1) {
                    this.setState({
                        errorMsg: data['errorMsg']
                    })
                } else {
                    this.setState({
                        data: data['cardClassName'],
                        total: data['total'],
                        loading: 0
                    })
                }
            }
        });
    }
    displayCardsInfo() {
        var list = ''
        if (this.state.cradsInfo.length > 0) {
            list = this.state.cradsInfo.map((rowData, index) => {
                return (
                    <Row key={index} className='table-body-row'>
                        <Col lg={6} md={6} sm={6} xs={12} className='main-color-1 font-w-400'>{rowData['cardClassName']}</Col>
                        <Col lg={2} md={2} sm={2} xs={12}>{rowData['value']}</Col>
                        <Col lg={2} md={2} sm={2} xs={12}>{rowData['cardsCount']}</Col>
                        <Col lg={2} md={2} sm={2} xs={12}>{rowData['finalValue']}</Col>
                    </Row>
                )
            })
        }
        return list
    }
    displayCardsOrderInfo() {
        var list = ''
        if (this.state.data.length > 0) {
            list = this.state.data.map((rowData, index) => {
                return (
                    <Row key={index} className='table-body-row'>
                        <Col lg={6} md={6} sm={6} xs={12} className='main-color-1 font-w-400'>{rowData['cardClassName']}</Col>
                        <Col lg={2} md={2} sm={2} xs={12}>{rowData['cardPriceFormate']}</Col>
                        <Col lg={2} md={2} sm={2} xs={12}>{rowData['cardsCount']}</Col>
                        <Col lg={2} md={2} sm={2} xs={12}>{rowData['finalValue']}</Col>
                    </Row>
                )
            })
        }
        return list
    }

    render() {
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col lg={10} md={10} sm={10} xs={12} >
                            <div className='p-0 main-color-1  sidebar-header-title'> {Strings['Classes']} ({Strings['Order']} - {this.state.orderId})</div>
                        </Col>
                        <Col lg={2} md={2} sm={2} xs={12} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>
                <div className='sidebar-body-view'>
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12} className="dev-card-class-order-right">
                            {this.state.loading == 0 && this.state.data.length > 0 &&
                                <Row className='table-header-row table-header-row-fixed-colo '>
                                    <Col lg={12} md={12} sm={12} xs={12}>{Strings['Total cards drawn']} : <span className="font-w-400">({this.state.total})</span></Col>
                                </Row>
                            }
                            {this.state.data.length > 0 &&
                                <Row className='table-header-row'>
                                    <Col lg={6} md={6} sm={6} xs={12}>{Strings['Denomination']}</Col>
                                    <Col lg={2} md={2} sm={2} xs={12}>{Strings['Price']}</Col>
                                    <Col lg={2} md={2} sm={2} xs={12}>{Strings['Count']}</Col>
                                    <Col lg={2} md={2} sm={2} xs={12}>{Strings['Total']}</Col>
                                </Row>
                            }
                            {this.displayCardsOrderInfo()}
                            {this.state.loading == 1 &&
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12} style={{ marginTop: "15%" }} className='text-center'>
                                        <div className='card-body'>
                                            <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                            <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                            <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                            <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                            <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                            <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {this.state.loading == 0 && this.state.errorMsg != '' &&
                                <Row>
                                    <div className='inv-cards-view pb-5 width-100'>
                                        <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
                                            <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                                            <div className='no-data-text'>{this.state.errorMsg}</div>
                                        </div>
                                    </div>
                                </Row>
                            }
                            {this.state.loading == 0 && this.state.data.length <= 0 &&
                                <Row>
                                    <div className='inv-cards-view pb-5 width-100'>
                                        <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
                                            <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                                            <div className='no-data-text'>{Strings["There are no cards available"]}</div>
                                        </div>
                                    </div>
                                </Row>
                            }
                        </Col>

                        <Col lg={6} md={6} sm={12} xs={12} className="dev-card-class-order-left">
                            <Row className='table-header-row table-header-row-fixed-colo '>
                                <Col lg={12} md={12} sm={12} xs={12}>{Strings['Total cards required']} : <span className="font-w-400">({this.props.orderRowData.amountRequest})</span></Col>
                            </Row>
                            <Row className='table-header-row'>
                                <Col lg={6} md={6} sm={6} xs={12}>{Strings['Denomination']}</Col>
                                <Col lg={2} md={2} sm={2} xs={12}>{Strings['Value']}</Col>
                                <Col lg={2} md={2} sm={2} xs={12}>{Strings['Count']}</Col>
                                <Col lg={2} md={2} sm={2} xs={12}>{Strings['Total']}</Col>
                            </Row>
                            {this.displayCardsInfo()}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
export default ViewClassesPos;

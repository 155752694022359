import React from "react";
// import SearchDropdown from "../../../app/partials/layout/SearchDropdown";
// import UserNotifications from "../../../app/partials/layout/UserNotifications";
// import MyCart from "../../../app/partials/layout/MyCart";
// import QuickActionsPanel from "../../../app/partials/layout/QuickActionsPanel";
// import QuickPanelToggler from "./QuickPanelToggle";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import { Drawer } from "@material-ui/core";
import UserProfile from "../../../app/partials/layout/UserProfile";
import MyAccount from "../../../app/pages/auth/MyAccount";
import { toAbsoluteUrl } from "../../utils/utils";
import MainAccountForm from "../../../app/pages/settings/accountInfo/forms/mainAccountForm";
import AddOrGetAmount from "../../../app/pages/pos/forms/addOrGetAmount";
import AddNotifications from "../../../app/pages/pos/forms/addNotifications";
// import rtlStyles from '../../../RtlCssBundleService'

export default class Topbar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openleftModal: false,
      actionType: '',
      // openleftModalNotification: false,
      // openleftModalAddBalnce: false,
      // openleftModalMain: false
    }
  }

  // componentWillMount() {
  // console.log('***********');
  // if (localStorage.getItem('LANG') == 1) {
  //   rtlStyles.insert()
  // } else {
  //   rtlStyles.detach()
  // }
  // }

  openSidebarModal = (action, actionType) => {
    this.setState({ openleftModal: action, actionType: actionType })
  }
  // openSidebarModalMain = (action) => {
  //   this.setState({ openleftModalMain: action })
  // }
  // handleCloseSideBarNotification = () => {
  //   this.setState({ openleftModalMain: false })
  // }
  // handleCloseSideBarAddBalnce = () => {
  //   this.setState({ openleftModalMain: false })
  // }
  // handleCloseSideBarMain = () => {
  //   this.setState({ openleftModalMain: false })
  // }

  render() {
    return (
      <div className="kt-header__topbar">
        {/* <SearchDropdown icon="flaticon2-search-1" /> */}

        {/* <UserNotifications
          skin="light"
          iconType=""
          icon="flaticon2-bell-alarm-symbol"
          type="primary"
          dot="false"
        /> */}

        {/* <QuickActionsPanel
          bgImage={toAbsoluteUrl("/media/misc/bg-2.jpg")}
          skin="light"
          iconType=""
          gridNavSkin="light"
          icon="flaticon2-gear"
        /> */}

        {/* <MyCart
          iconType=""
          skin="light"
          icon="flaticon2-shopping-cart-1"
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
        /> */}

        {/* <QuickPanelToggler /> */}

        <LanguageSelector iconType="" />

        <UserProfile showBadge={true} openSidebarModal={this.openSidebarModal} />
        {/* <UserProfile showBadge={true} openSidebarModal={(localStorage.getItem("INV_MGR") == 1 || localStorage.getItem('POS_MGR_EMP') == 1 || localStorage.getItem('ACC_EMP') == 1 || localStorage.getItem('OPERA_EMP') == 1) ? this.openSidebarModalMain : this.openSidebarModal} /> */}

        <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.openleftModal} className={this.state.actionType == "notification" ? 'sidebar-modal-large-view' : 'sidebar-modal-view'}>
          {this.state.actionType == "account" &&
            <MyAccount openSidebarModal={this.openSidebarModal} />
          }
          {this.state.actionType == "main_account" &&
            <MainAccountForm handleCloseSideBar={this.openSidebarModal} />
          }
          {this.state.actionType == "add_balance" &&
            <AddOrGetAmount
              handleCloseSideBar={this.openSidebarModal}
              mainAccount='1'
            />
          }
          {this.state.actionType == "notification" &&
            <AddNotifications handleCloseSideBar={this.openSidebarModal} />
          }
        </Drawer>
        {/* <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.openleftModalMain} className='sidebar-modal-view'>
          <MainAccountForm handleCloseSideBar={this.handleCloseSideBarMain} />
        </Drawer>
        <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.openleftModalMain} className='sidebar-modal-view'>
          <AddOrGetAmount
            // handleCloseSideBar={this.handleCloseSideBar}
            // handleFormAction={this.handleFormAction}
            mainAccount='1' />
        </Drawer>
        <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.openleftModalMain} className='sidebar-modal-large-view'>
          <AddNotifications
          // handleCloseSideBar={this.handleCloseSideBar}
          // handleFormAction={this.handleFormAction} 
          />
        </Drawer> */}
      </div>
    );
  }
}

import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { toAbsoluteUrl } from "../../../_metronic";
import DaftraCategory from "./daftraCategory";
import DaftraProduct from "./daftraProduct";
import { translations } from '../../../translate.js';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;
class DaftraInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewCardMode: 1,
            loading: 1,
            dataCount: 0
        }
    }

    componentDidMount() {
        this.refs.categorydaftra.getCategoryList();
    }

    loadPageAction = (dataCount, catId, catData) => {
        this.setState({
            loading: 0,
            dataCount: dataCount

        }, function () {
            if (dataCount > 0) {
                this.refs.product.getProductList(catId, catData['categoryName']);
            }
        })
    }

    //When Change Category call api product  be catId
    changeCategoryCards = (catId, catData) => {
        this.refs.product.getProductList(catId, catData['categoryName']);
    }

    render() {
        return (
            <div>
                {this.state.loading == 1 &&
                    <Row>
                        <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                            <div className='card-body'>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                            </div>
                        </Col>
                    </Row>
                }
                {this.state.loading == 0 && this.state.dataCount <= 0 &&
                    <Row className='text-center' style={{ marginTop: "15%" }}>
                        <div className="col-md-12">
                            <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                            <div className='no-data-text'>{Strings["There is no data to display"]}</div>
                        </div>
                    </Row>
                }

                <Row>
                    <Col md={3}>
                        <DaftraCategory
                            ref='categorydaftra'
                            loadPageAction={this.loadPageAction}
                            changeCategoryCards={this.changeCategoryCards}
                        />
                    </Col>
                    {this.state.loading == 0 && this.state.dataCount > 0 &&
                        <Col md={9}>
                            <DaftraProduct ref='product' />
                        </Col>
                    }
                </Row>
            </div>
        )
    }
}
export default DaftraInventory;

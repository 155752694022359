import React, { Component } from "react";
import { Row, Col, CustomInput, Progress, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Drawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Pagination from "react-js-pagination";
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';

import ViewOrder from './forms/viewOrder'
import ViewClasses from './forms/viewClasses'

import { translations } from '../../../translate.js';

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class DaftraOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOrderAction: false,
      orderRowData: [],
      daftraList: [],
      selectedDaftra: [],
      selectedDaftraId: '',
      orderFilterDateFrom: '',
      orderFilterDateTo: '',
      loading: 1,
      data: [],
      dataCount: 0,
      limit: 10,
      activePage: 1,
      errorMsg: '',
      actionType: '',
      categoryList: [],
      selectedCategoryList: [],
      selectedCategoryId: '',
      productList: [],
      selectedProductList: [],
      selectedProductId: '',
      orderFilterOrderId:'',
      orderFilterRefrenceId:'',
      orderFilterInvoiceId:'',



    }
  }

  componentDidMount() {
    this.getDaftraOrder()
    this.getDaftraClient()
    this.getDaftraCategory()
    document.addEventListener('mousedown', this.handleClickOutside);
  }


  // https://cp8.easypay-card.com/API/daftra_Api.php?type=getClients&
  // page=1&limit=25&clientName=pos%20Client&
  // userId=7421&token=413e972f492640456d37ec5dfc3637ad
  getDaftraClient() {
    fetch(window.API_DAFTRA_API, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'getClients',
        'lang': localStorage.getItem('LANG'),
        'userId': localStorage.getItem('USER_ID'),
        'token': localStorage.getItem('USER_TOKEN'),
        'page': 0
      }),
      headers: {
        'Accept': 'application/json',
      },
    }).then(function (response) {
      return response.json();
    }).then(data => {
      if (data !== "Nothing found") {

        if (data['logout'] == 1) {
          localStorage.setItem('USER_ID', '');
          localStorage.setItem('USER_TOKEN', '');
          window.location.href = "/logout";
        } else {
          var daftraList = []
          if (data['data'].length > 0) {
            data['data'].map((rowData, index) => {
              var daftraListItem = {}
              daftraListItem['clientId'] = rowData['clientId']
              daftraListItem['clientName'] = rowData['clientName']
              daftraList.push(daftraListItem)
            })
          }

          this.setState({
            daftraList: daftraList
          })
        }
      }
    })
  }



  getDaftraCategory() {
    fetch(window.API_DAFTRA_API, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'getDaftraCategory',
        'lang': localStorage.getItem('LANG'),
        'userId': localStorage.getItem('USER_ID'),
        'token': localStorage.getItem('USER_TOKEN'),
        'page': 0
      }),
      headers: {
        'Accept': 'application/json',
      },
    }).then(function (response) {
      return response.json();
    }).then(data => {
      if (typeof data !== 'undefined') {
        // console.log('===============================');
        // console.log(data);

        if (data['logout'] == 1) {
          localStorage.setItem('USER_ID', '');
          localStorage.setItem('USER_TOKEN', '');
          window.location.href = "/logout";
        } else {
          this.setState({
            categoryList: data['data'],
          })
        }
      }
    });
  }







  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains('actionDropdownList')) {
        this.setState({
          openHeader: ''
        })
      }
    }
  }


  // https://cp8.easypay-card.com/API/daftra_Api.php?type=getInvoiceOrders&page=1&limit=25&
  // userId=7421&token=413e972f492640456d37ec5dfc3637ad


  getDaftraOrder() {
    this.setState({ loading: 1 })
    console.log('orderFilterDateFrom',this.state.orderFilterDateFrom)
    console.log('orderFilterDateto',this.state.orderFilterDateTo)
    console.log("posid",this.state.selectedDaftraId)
    console.log("selectedProductId",this.state.selectedProductId)
    console.log("orderFilterOrderId",this.state.orderFilterOrderId)
    console.log("orderFilterRefrenceId",this.state.orderFilterRefrenceId)
        console.log('invoiceId',this.state.orderFilterInvoiceId)

    fetch(window.API_DAFTRA_API, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'getInvoiceOrders',
        'lang': localStorage.getItem('LANG'),
        'userId': localStorage.getItem('USER_ID'),
        'token': localStorage.getItem('USER_TOKEN'),
        'page': this.state.activePage,
        'limit': this.state.limit,
        'clientId': this.state.selectedDaftraId,
        'productId': this.state.selectedProductId,
        'createdAtStart': this.state.orderFilterDateFrom,
        'createdAtEnd': this.state.orderFilterDateTo,
        'orderId': this.state.orderFilterOrderId,
        'refrenceId':this.state.orderFilterRefrenceId,
        'invoiceId':this.state.orderFilterInvoiceId
      }),
      headers: {
        'Accept': 'application/json',
      },
    }).then(function (response) {
      return response.json();
    }).then(data => {
      if (data !== "Nothing found") {

        console.log('====================================== order data ');
        console.log(data);

        if (data['logout'] == 1) {
          localStorage.setItem('USER_ID', '');
          localStorage.setItem('USER_TOKEN', '');
          window.location.href = "/logout";
        } else if (data['error'] == 1) {
          this.setState({ errorMsg: data['errorMsg'] })
        } else {
          this.setState({
            data: data['data'],
            dataCount: data['count'],
            loading: 0,
          })
        }
      }
    })
  }




  handleRefresh = () => {
    // clear all filter data
    this.setState({
      selectedDaftra: [],
      selectedDaftraId: '',
      orderFilterDateFrom: '',
      orderFilterDateTo: '',
      selectedCategoryList: [],
      selectedCategoryId: '',
      selectedProductList: [],
      selectedProductId: '',
      orderFilterOrderId: '',
      orderFilterRefrenceId:'',productList:[],
      orderFilterInvoiceId:''

    }, function () {
      this.getDaftraOrder()
    })
  }



  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value,
      activePage: 1
    }, function () {
      this.getDaftraOrder()
    })
  }

  addRowHover = (index, event) => {
    var stateVal = 'activeIndex'
    this.setState({ [stateVal]: index })
  }
  removeRowHover = (index, event) => {
    var stateVal = 'activeIndex'
    this.setState({ [stateVal]: -1 })
  }

  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (event.target.classList[1] != undefined && event.target.classList[1] == "menu-dots-vertical" && id != undefined) {
      var val = ''
      if (this.state.openHeader == id) {
        val = ''
      } else if (id != undefined) {
        val = id
      }
      this.setState({
        openHeader: val
      })
    } else if (id == undefined && event.target.classList[1] != "menu-dots-vertical") {
      this.setState({
        openHeader: ''
      })
    }
  }






  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function () {
      this.getDaftraOrder();
    });
  }


  displayData() {
    var orderInfo = ''
    if (this.state.loading == 0 && this.state.data.length > 0) {
      orderInfo = this.state.data.map((rowData, index) => {
        return (
          <Row key={index} className='table-body-row'>
            <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData.orderId}>{rowData.orderId}</Col>
            <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData.referenceId}>{rowData.referenceId}</Col>
            <Col lg={3} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData.name}>{rowData.name}</Col>
            <Col lg={3} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData.createdAt}>{rowData.createdAt}</Col>
            <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData.productName}>{rowData.productName}</Col>


          </Row>
        )
      })
    }
    return orderInfo;
  }

  getDaftraProduct() {
    fetch(window.API_DAFTRA_API, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'getDaftraProduct',
        'lang': localStorage.getItem('LANG'),
        'userId': localStorage.getItem('USER_ID'),
        'token': localStorage.getItem('USER_TOKEN'),
        'categoryId': this.state.selectedCategoryId,

      }),
      headers: {
        'Accept': 'application/json',
      },
    }).then(function (response) {
      return response.json();
    }).then(data => {

      if (typeof data !== 'undefined') {
        console.log('=============DDDDDD==================');
        console.log(data);
        if (data['logout'] == 1) {
          localStorage.setItem('USER_ID', '');
          localStorage.setItem('USER_TOKEN', '');
          window.location.href = "/logout";
        } else {
          this.setState({
            productList: data['data'],
            loading: 0,


          })
        }
      }
    });
  }

  handleChangeSelect = (type, option) => {
    if (type == 'daftraName') {
      this.setState(state => {
        return {
          selectedDaftra: option,
          selectedDaftraId: option['clientId'],
          activePage: 1
        };
      }, function () {
        this.getDaftraOrder();
      });
    } else if (type == 'category') {
      this.setState(state => {
        return {
          selectedCategoryList: option,
          selectedCategoryId: option['categoryId'],
          selectedProductList: [],
          selectedProductId: '',
          activePage: 1
        };
      }, function () {
        this.getDaftraOrder();
        this.getDaftraProduct()
      });
    }
    else if (type == 'product') {
      this.setState(state => {
        return {
          selectedProductList: option,
          selectedProductId: option['productId'],
          activePage: 1
        };
      }, function () {
        this.getDaftraOrder();
      });
    }
  }


  exportHandler() {
    var orderFilterDateFrom = this.state.orderFilterDateFrom
    if (orderFilterDateFrom == '' || orderFilterDateFrom == null || orderFilterDateFrom == undefined) {
        orderFilterDateFrom = ''
    }

    var orderFilterDateTo = this.state.orderFilterDateTo
    if (orderFilterDateTo == '' || orderFilterDateTo == null || orderFilterDateTo == undefined) {
        orderFilterDateTo = ''
    }

    var selectedCategoryId = this.state.selectedCategoryId
    if (selectedCategoryId == '' || selectedCategoryId == null || selectedCategoryId == undefined) {
        selectedCategoryId = ''
    }

    var selectedProductId = this.state.selectedProductId
    if (selectedProductId== '' || selectedProductId == null || selectedProductId == undefined) {
      selectedProductId = ''
    }

    var orderFilterOrderId = this.state.orderFilterOrderId
    if (orderFilterOrderId == '' || orderFilterOrderId == null || orderFilterOrderId == undefined) {
        orderFilterOrderId = ''
    }

    var selectedDaftraId = this.state.selectedDaftraId
    if (selectedDaftraId == '' || selectedDaftraId == null || selectedDaftraId == undefined) {
      selectedDaftraId = ''
    }
    var orderFilterRefrenceId = this.state.orderFilterRefrenceId
    if (orderFilterRefrenceId == '' || orderFilterRefrenceId == null || orderFilterRefrenceId == undefined) {
      orderFilterRefrenceId = ''
    }

    var orderFilterInvoiceId = this.state.orderFilterInvoiceId
    if (orderFilterInvoiceId == '' || orderFilterInvoiceId == null || orderFilterInvoiceId == undefined) {
      orderFilterInvoiceId = ''
    }
  

    window.location = window.API_DAFTRA_API + "?type=getInvoiceOrders&lang=" + 
    localStorage.getItem('LANG') + "&userId=" + localStorage.getItem('USER_ID') +
        "&token=" + localStorage.getItem('USER_TOKEN') + "&export=1" + 
        "&createdAtStart=" + orderFilterDateFrom + "&createdAtEnd=" + orderFilterDateTo
        + "&productId=" + selectedProductId + "&page=" + this.state.activePage + "&limit=" +
         this.state.limit + "&orderId=" + orderFilterOrderId
        + "&clientId=" + selectedDaftraId  + "&refrenceId=" + orderFilterRefrenceId+ "&invoiceId=" + orderFilterInvoiceId
}
  render() {
    return (
      <div>
        <Row className='page-filter-view'>
          <Col lg={5} md={7}>
            <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
              <Row className='filter-form'>

                <Col md={10} lg={10} sm={10} xs={10}>
                  <div className='member-searchable-list mb-2'>
                    <Picky
                      value={this.state.selectedDaftra}
                      options={this.state.daftraList}
                      onChange={this.handleChangeSelect.bind(this, 'daftraName')}
                      open={false}
                      valueKey="clientId"
                      labelKey="clientName"
                      multiple={false}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings['POS']}
                      filterPlaceholder={Strings['Select POS']}
                    />
                  </div>
                </Col>

                <Col md={2} lg={2} sm={2} xs={2} className='mb-2'>
                  <Button variant="contained" className='refresh-action-btn' onClick={this.handleRefresh} title={Strings['Refresh']}>
                    <i className='flaticon-refresh reset-filter-btn'></i>
                  </Button>
                </Col>

                <Col md={6} lg={6} sm={6} xs={12} className='mb-2'>
                  <AvField name="orderFilterDateFrom" type='date' label={Strings['From Date']} value={this.state.orderFilterDateFrom} onChange={this.handleInputChange} />
                </Col>
                <Col md={6} lg={6} sm={6} xs={12} className='mb-2'>
                  <AvField name="orderFilterDateTo" type='date' label={Strings['To Date']} value={this.state.orderFilterDateTo} onChange={this.handleInputChange} />
                </Col>

                <Col md={6} lg={6} sm={6} xs={12}>
                  <div className='member-searchable-list mb-2'>
                    <Picky
                      value={this.state.selectedCategoryList}
                      options={this.state.categoryList}
                      onChange={this.handleChangeSelect.bind(this, 'category')}
                      open={false}
                      valueKey="categoryId"
                      labelKey="categoryName"
                      multiple={false}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings['CategoryMain']}
                      filterPlaceholder={Strings['Select Category']}
                    />
                  </div>
                </Col>

                <Col md={6} lg={6} sm={6} xs={12}>
                  <div className='member-searchable-list mb-2'>
                    <Picky
                      value={this.state.selectedProductList}
                      options={this.state.productList}
                      onChange={this.handleChangeSelect.bind(this, 'product')}
                      open={false}
                      valueKey="productId"
                      labelKey="productName"
                      multiple={false}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings['Product']}
                      filterPlaceholder={Strings['Select Product']}
                    />
                  </div>
                </Col>


                <Col md={6} lg={6} sm={6} xs={12} className="mb-2">
                  <AvField name="orderFilterOrderId" type='number' placeholder={Strings['Order Number']} value={this.state.orderFilterOrderId} onChange={this.handleInputChange} />
                </Col>


                <Col md={6} lg={6} sm={6} xs={12}>
                  <AvField name="orderFilterRefrenceId" type='text' placeholder={Strings['Reference #']} value={this.state.orderFilterRefrenceId} onChange={this.handleInputChange} />
                </Col>

                <Col md={6} lg={6} sm={6} xs={12}>
                  <AvField name="orderFilterInvoiceId" type='number' placeholder={Strings['Invoice Id']} value={this.state.orderFilterInvoiceId} onChange={this.handleInputChange} />
                </Col>

              </Row>
            </AvForm>
          </Col>
          <Col lg={7} md={5} className='text-right Export-textAlignleft'>
            <button className="btn page-action-btn ml-1" onClick={(event) => this.exportHandler(event)}>
              {Strings['Export']}
            </button>
          </Col>
        </Row>

        {this.state.loading == 0 && this.state.errorMsg != '' &&
          <div className='inv-cards-view pb-5'>
            <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
              <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
              <div className='no-data-text'>{this.state.errorMsg}</div>
            </div>
          </div>
        }

        {this.state.loading == 0 && this.state.data.length <= 0 &&
          <div className='inv-cards-view pb-5'>
            <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
              <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
              <div className='no-data-text'>{Strings["There is no data to display"]}</div>
            </div>
          </div>
        }

        {this.state.loading == 1 &&
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
              <div className='card-body'>
                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
              </div>
            </Col>
          </Row>
        }


        {/* //table--start// */}
        {this.state.loading == 0 && this.state.data.length > 0 &&
          <Row>
            <div className='inv-cards-view width-100'>
              <Row className='table-header-row'>
                <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Order #']}>{Strings['Order #']}</Col>
                <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Reference #']}>{Strings['Reference #']}</Col>
                <Col lg={3} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['POS Name']}>{Strings['POS Name']}</Col>
                <Col lg={3} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Date']}>{Strings['Date']}</Col>
                <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Product Name']}>{Strings['Product Name']}</Col>



              </Row>
              {this.displayData()}
              {this.state.loading == 0 && this.state.data.length > 0 &&
                <Col md={12}>
                  <Row className='pagenation-view mt-3 mb-4'>
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-next' : 'flaticon2-back'}></i>}
                      nextPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-back' : 'flaticon2-next'}></i>}
                      hideFirstLastPages
                      innerClass={'pagination  pagination-rounded justify-content-end pagenation-view my-2'}
                      itemClass={'page-item'}
                      linkClass={'page-link'}
                    />
                  </Row>
                </Col>
              }

            </div>
          </Row>
        }



      </div>
    )
  }
}
export default DaftraOrder;


import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";

export default function CommingSoon() {
  return (
    <div className='dashboard-main-view' style={{marginTop:'5%'}}>
      <img className='img-fluid dashboard-logo' alt="logo" src={toAbsoluteUrl("/media/ezpay/source.gif")} />
    </div>
  );
}

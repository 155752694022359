import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from "../../../../translate.js";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class POSviewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 1,
      errorMsg: "",
      viewPage: 1,
      orderData: this.props.orderRowData,
      showExportOrder: 0,
      orderDate: undefined,
    };
  }
  componentDidMount() {
    this.loadingDataApi();
  }

  loadingDataApi() {
    this.setState({ loading: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getOrderInfo",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        orderId: this.props.orderRowData?.orderId,
        print: 1,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        console.log(data, "DATAAA");
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ errorMsg: data["errorMsg"], loading: 0 });
          } else {
            this.setState({
              errorMsg: "",
              orderData: data,
              orderId: data?.orderId,
              posName: "",
              clientName: "",
              distName: "",
              orderDate: data.orderDate,
              amountFromate: "",
              actualAmount: "",
              catName: "",
              invoicePassword: "",
              paymentType: "",
              note: "",
              imgUrl: "",
              bankName: "",
              loading: 0,
            });
          }
        }
      });
  }

  loadingBody() {
    if (this.state.viewPage == 1) {
      return (
        <tag>
          {this.state.loading == 1 && (
            <Row>
              <Col md={12} style={{ marginTop: "15%" }} className="text-center">
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}
          {this.state.loading == 0 && this.state.errorMsg != "" && (
            <div className="text-center" style={{ marginTop: "15%" }}>
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">{this.state.errorMsg}</div>
            </div>
          )}

          {this.state.loading == 0 &&
            Object.keys(this.state.orderData).length <= 0 && (
              <div className="text-center" style={{ marginTop: "15%" }}>
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                  )}
                  className="img-fluid no-data-img"
                />
                <div className="no-data-text">
                  {Strings["There is no data to display"]}
                </div>
              </div>
            )}
        </tag>
      );
    }
  }
  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {Strings["View Order"]}
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>
        {this.loadingBody()}
        {(this.state.viewPage != 1 ||
          (this.state.loading == 0 &&
            Object.keys(this.state.orderData).length > 0)) && (
          <div className="sidebar-body-view">
            <div className="view-purchase-main-fields">
              {localStorage.getItem("SHOW_ORDER_ID_POS") == 1 && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Order Number"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state?.orderId}
                  </Col>
                </div>
              )}

              {this.state?.orderDate != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Date"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state?.orderDate}{" "}
                  </Col>
                </div>
              )}
              {this.props?.orderMoreInfo?.paymentType == 9 ? (
                <>
                  {this.props?.orderMoreInfo?.transReferenceId != "" && (
                    <div className="mb-4 view-purchase-main-fields-row">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="main-font-1 font-w-500"
                      >
                        {Strings["transReferenceId"]}
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {this.props?.orderMoreInfo?.transReferenceId}{" "}
                      </Col>
                    </div>
                  )}
                  {this.props?.orderMoreInfo?.transAmount != "" &&
                    localStorage.getItem("USER_PRICE_SHOW") == 1 && (
                      <div className="mb-4 view-purchase-main-fields-row">
                        <Col
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="main-font-1 font-w-500"
                        >
                          {Strings["transAmount"]}
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          {this.props?.orderMoreInfo?.transAmount}{" "}
                        </Col>
                      </div>
                    )}
                  {this.props?.orderMoreInfo?.transEntryDate != "" && (
                    <div className="mb-4 view-purchase-main-fields-row">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="main-font-1 font-w-500"
                      >
                        {Strings["transEntryDate"]}
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {this.props?.orderMoreInfo?.transEntryDate}{" "}
                      </Col>
                    </div>
                  )}
                  {this.props?.orderMoreInfo?.transErrorDesc != "" && (
                    <div className="mb-4 view-purchase-main-fields-row">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="main-font-1 font-w-500"
                      >
                        {Strings["transErrorDesc"]}
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {this.props?.orderMoreInfo?.transErrorDesc}{" "}
                      </Col>
                    </div>
                  )}
                  {this.props?.orderMoreInfo?.transTitle && (
                    <div className="mb-4 view-purchase-main-fields-row">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="main-font-1 font-w-500"
                      >
                        {Strings["transTitle"]}
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {this.props?.orderMoreInfo?.transTitle}{" "}
                      </Col>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {this.props?.orderMoreInfo?.value != "" &&
                    localStorage.getItem("USER_PRICE_SHOW") == 1 && (
                      <div className="mb-4 view-purchase-main-fields-row">
                        <Col
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="main-font-1 font-w-500"
                        >
                          {Strings["transAmount"]}
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          {this.props?.orderMoreInfo?.amountFormated}{" "}
                        </Col>
                      </div>
                    )}
                </>
              )}
              {this.props?.orderMoreInfo?.categoryName != "" &&
                this.props?.orderMoreInfo?.categoryName != undefined && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["Category"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.props?.orderMoreInfo?.categoryName}{" "}
                    </Col>
                  </div>
                )}
            </div>
            {this.state?.orderData?.cards?.length > 0 &&
              this.state?.orderData?.cards.map((card) => (
                <div className="view-purchase-main-fields mt-2 mb-2">
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["Card Name"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {card.name}{" "}
                    </Col>
                  </div>
                  {localStorage.getItem("USER_PRICE_SHOW") == 1 && (
                    <div className="mb-4 view-purchase-main-fields-row">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="main-font-1 font-w-500"
                      >
                        {Strings["Value"]}
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {card?.value}{" "}
                      </Col>
                    </div>
                  )}
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["Code"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {card?.code}{" "}
                    </Col>
                  </div>
                  {localStorage.getItem("SHOW_ORDER_ID_POS") == 1 && (
                    <div className="mb-4 view-purchase-main-fields-row">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="main-font-1 font-w-500"
                      >
                        {Strings["billingNumber"]}
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {card?.billNumber}{" "}
                      </Col>
                    </div>
                  )}

                  {localStorage.getItem("SHOW_ORDER_ID_POS") == 1 && (
                    <div className="mb-4 view-purchase-main-fields-row">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="main-font-1 font-w-500"
                      >
                        {Strings["Id"]}
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {card?.id}{" "}
                      </Col>
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
}
export default POSviewOrder;

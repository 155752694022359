import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { toAbsoluteUrl } from "../../../../_metronic";
import { confirmAlert } from "react-confirm-alert";
import { translations } from "../../../../translate.js";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class PosMainAccountForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: this.props.propsData.fname,
      userEmail: this.props.propsData.userEmail,
      mobile: this.props.propsData.mobile,
      //------------------ POS DISTRIBUTOR ----------------
      address: this.props.propsData.address,
      city: this.props.propsData.city,
      district: this.props.propsData.district,
      zipcode: this.props.propsData.zipcode,
      commercial: this.props.propsData.commercial,
      posDistEmail: this.props.propsData.posDistEmail,
      accountDistNoti: this.props.propsData.accountDistNoti,
      emailAppPos: this.props.propsData.emailAppPos,
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      submitError: "",
    });
  };

  handleValidSubmit(event) {
    event.preventDefault();
    var body = "";
    if (this.props?.distId && this.props?.permType == 5) {
      body = JSON.stringify({
        type: "editMainDistInfo",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        address: this.state.address,
        city: this.state.city,
        district: this.state.district,
        zipcode: this.state.zipcode,
        commercial: this.state.commercial,
        posDistEmail: this.state.posDistEmail,
        accountDistNoti: this.state.accountDistNoti,
        emailAppPos: this.state.emailAppPos,
        userPassword: this.state.password,
        distId: this.props?.distId ? this.props.distId : "",
      });
    } else if (this.props?.distId && this.props?.permType != 5) {
      body = JSON.stringify({
        type: "editMainDistInfo",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        fname: this.state.fname,
        userEmail: this.state.userEmail,
        mobile: this.state.mobile,
        userPassword: this.state.password,
        distId: this.props?.distId ? this.props.distId : "",
      });
    } else if (localStorage.getItem("PERM_TYPE") == 5) {
      body = JSON.stringify({
        type: "editMainDistInfo",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        address: this.state.address,
        city: this.state.city,
        district: this.state.district,
        zipcode: this.state.zipcode,
        commercial: this.state.commercial,
        posDistEmail: this.state.posDistEmail,
        accountDistNoti: this.state.accountDistNoti,
        emailAppPos: this.state.emailAppPos,
        userPassword: this.state.password,
      });
    } else {
      body = JSON.stringify({
        type: "editMainDistInfo",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        fname: this.state.fname,
        userEmail: this.state.userEmail,
        mobile: this.state.mobile,
        userPassword: this.state.password,
      });
    }
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: body,
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          console.log(data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
            this.props.handleFormAction("edit");
            if (localStorage.getItem("PERM_TYPE") == 5||(this.props?.distId && this.props?.permType == 5)) {
              this.alertSuccessSubmite(data["errorMsg"]);
            }
          } else {
            this.setState({ submitError: data["errorMsg"] });
          }
        }
      });
  }
  alertSuccessSubmite = (errorMsg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view ">
            <div className="mb-4">
              <CheckCircleOutlineIcon className="success-alert-icon" />
            </div>
            <p className="mb-3 ">{errorMsg}</p>
            <button
              type="button"
              className="btn  alert-btn-margin ml-2 mr-2 success-btn text-success"
              onClick={onClose}
            >
              {Strings.Close}
            </button>
          </div>
        );
      },
    });
  };
  buildFromJSC = () => {
    var info = [];
    info.push(
      <AvField
        name="fname"
        label={Strings["Name"] + " *"}
        placeholder={Strings["Enter Name"]}
        value={this.state.fname}
        required
        onChange={this.handleInputChange}
        errorMessage={Strings["This field is required"]}
      />
    );
    info.push(
      <AvField
        name="userEmail"
        type="email"
        label={Strings["Email"] + " *"}
        placeholder={Strings["Enter Email"]}
        value={this.state.userEmail}
        required
        onChange={this.handleInputChange}
        errorMessage={Strings["This field is required"]}
      />
    );
    info.push(
      <AvField
        name="mobile"
        type="number"
        label={Strings["Phone"] + " *"}
        placeholder={Strings["Enter Phone"]}
        value={this.state.mobile}
        required
        onChange={this.handleInputChange}
        errorMessage={Strings["This field is required"]}
      />
    );
    info.push(
      <AvField
        name="password"
        type="password"
        label={Strings["Password"]}
        placeholder={Strings["Enter Password"]}
        value={this.state.password}
        onChange={this.handleInputChange}
      />
    );
    return info;
  };
  buildFromPOS = () => {
    var info = [];
    info.push(
      <AvField
        name="address"
        label={Strings["Address"] + " *"}
        placeholder={Strings["Enter Address"]}
        value={this.state.address}
        required
        onChange={this.handleInputChange}
        errorMessage={Strings["This field is required"]}
      />
    );
    info.push(
      <AvField
        name="city"
        label={Strings["City"] + " *"}
        placeholder={Strings["Enter City"]}
        value={this.state.city}
        required
        onChange={this.handleInputChange}
        errorMessage={Strings["This field is required"]}
      />
    );
    info.push(
      <AvField
        name="district"
        label={Strings["District"] + " *"}
        placeholder={Strings["Enter District"]}
        value={this.state.district}
        required
        onChange={this.handleInputChange}
        errorMessage={Strings["This field is required"]}
      />
    );
    info.push(
      <AvField
        name="zipcode"
        label={Strings["Zip Code"] + " *"}
        placeholder={Strings["Enter Zip Code"]}
        value={this.state.zipcode}
        required
        onChange={this.handleInputChange}
        errorMessage={Strings["This field is required"]}
      />
    );
    info.push(
      <AvField
        name="commercial"
        label={Strings["Commercial Registration No."] + " *"}
        placeholder={Strings["Enter Commercial Registration No."]}
        value={this.state.commercial}
        required
        onChange={this.handleInputChange}
        errorMessage={Strings["This field is required"]}
      />
    );
    info.push(
      <AvField
        name="posDistEmail"
        type="email"
        label={Strings["Email [Recharge POS]"] + " *"}
        placeholder={Strings["Enter Email [Recharge POS]"]}
        value={this.state.posDistEmail}
        required
        onChange={this.handleInputChange}
        errorMessage={Strings["This field is required"]}
      />
    );
    info.push(
      <AvField
        name="accountDistNoti"
        type="email"
        label={Strings["Notification Email [ add classes ]"] + " *"}
        placeholder={Strings["Enter Notification Email [ add classes ]"]}
        value={this.state.accountDistNoti}
        required
        onChange={this.handleInputChange}
        errorMessage={Strings["This field is required"]}
      />
    );
    info.push(
      <AvField
        name="emailAppPos"
        type="email"
        label={Strings["POS Application Email"] + " *"}
        placeholder={Strings["Enter POS Application Email"]}
        value={this.state.emailAppPos}
        required
        onChange={this.handleInputChange}
        errorMessage={Strings["This field is required"]}
      />
    );
    info.push(
      <AvField
        name="password"
        type="password"
        label={Strings["Password"]}
        placeholder={Strings["Enter Password"]}
        value={this.state.password}
        onChange={this.handleInputChange}
      />
    );
    return info;
  };
  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col md={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Edit Account Information"]}{" "}
              </div>
            </Col>
            <Col md={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <AvForm
            onValidSubmit={this.handleValidSubmit}
            ref={(c) => (this.form = c)}
          >
            {localStorage.getItem("PERM_TYPE") == 5 || (this.props?.distId && this.props?.permType == 5)
              ? this.buildFromPOS()
              : this.buildFromJSC()}

            <div className="text-right mt-4">
              <button type="submit" className="btn form-save-btn mr-1 ml-1">
                {" "}
                {Strings["Save"]}{" "}
              </button>
              <button
                type="button"
                className="btn form-cancel-btn m-l-10"
                onClick={this.props.handleCloseSideBar}
              >
                {Strings["Cancel"]}
              </button>
            </div>

            {this.state.submitError && (
              <div className="error-text-black">
                <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                {this.state.submitError}
              </div>
            )}
          </AvForm>
        </div>
      </div>
    );
  }
}
export default PosMainAccountForm;

import React, { Component } from "react";
import { Row, Col, } from 'reactstrap';
import { AvForm, AvField, AvFeedback, } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CircularProgress from "@material-ui/core/CircularProgress";
import { translations } from '../../../../translate.js';
import { Picky } from "react-picky";

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;


class WholesaleOrdersForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingSave: 0,
            loading: 0,
            msgError:0,
            name: this.props.status == 'edit' ? this.props.data.cartName : '',
            selectedDistributor: [],
            distId: this.props.status == 'edit' ? this.props.data.userId : '',
            cartId: this.props.status == 'edit' ? this.props.data.id : ''
        }
    }
    componentDidMount() {
        (this.props.distributorList.length > 0 || (this.props.data.cartName != undefined && this.props.data.cartName != null && this.props.data.cartName != '')) && this.setState({ loading: 1, })
    }

    handleValidSubmit = (event) => {
        var canSave = 0;
        if (this.props.status == "add") {
            if (this.state.selectedDistributor != undefined && this.state.selectedDistributor != null && this.state.selectedDistributor != '') {
                canSave = 1
            }
        } else {
            canSave = 1;
        }
        event.preventDefault();
        if (canSave == 1) {
            this.setState({ loadingSave: 1 })
            fetch(window.API_PERM_USER, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'addBulkCart',
                    'lang': localStorage.getItem('LANG'),
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                    'cartName': this.state.name,
                    'distId': this.state.distId,
                    'cartId': this.state.cartId,
                }),
                headers: {
                    'Accept': 'application/json',
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (data !== "Nothing found") {
                    // console.log('========================== result');
                    // console.log(data);
                    if (data['logout'] == 1) {
                        localStorage.setItem('USER_ID', '');
                        localStorage.setItem('USER_TOKEN', '');
                        window.location.href = "/logout";
                    } else if (data['error'] == 1) {

                    } else {
                        this.setState({ loadingSave: 0 })
                        this.props.getBulkOrderCarts();
                        this.props.handleCloseSideBar();
                    }
                }
            })
        } else {
            this.setState({msgError : 1})
        }

    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value
        })
    }
    handleChangeSelect = (option) => {
        this.setState(state => {
            return {
                selectedDistributor: option,
                distId: option.userId,
                msgError : 0
            }
        }, function () { })

    }
    render() {
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col lg={10} md={10} sm={10} xs={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'>  {this.props.status == 'add' ? Strings['Add'] : Strings['Edit']} {Strings['Cart']}
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={2} xs={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>


                <div className='sidebar-body-view'>
                    {this.state.loading == 0 &&
                        <Row>
                            <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                                <div className='card-body'>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                </div>
                            </Col>
                        </Row>
                    }
                    {this.state.loading != 0 &&
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            <AvField name="name" type='text' label={Strings['Cart Name'] + ' *'} placeholder={Strings['Enter Cart Name']} value={this.state.name} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />

                            {this.props.status == "add" &&
                                <div className="mt-4">
                                    <label className={this.state.msgError == 1 ? 'label-error' : ''}>{Strings['Distributors']} *</label>
                                    <Picky
                                        value={this.state.selectedDistributor}
                                        options={this.props.distributorList}
                                        onChange={this.handleChangeSelect}
                                        open={false}
                                        valueKey="userId"
                                        labelKey='fname'
                                        multiple={false}
                                        includeSelectAll={false}
                                        includeFilter={true}
                                        dropdownHeight={200}
                                        placeholder={Strings['Distributor Name']}
                                        filterPlaceholder={Strings['Select Distributor Name']}
                                        className={this.state.msgError == 1 ? 'border border-danger' : ''}
                                        clearFilterOnClose={true}
                                    />
                                    {this.state.msgError == 1 &&
                                        <div className='msg-error'>{Strings['This field is required']}</div>
                                    }
                                </div>
                            }
                            <div className='text-right mt-4'>
                                {this.state.loadingSave == 1 ?
                                    <button type="button" className="btn form-save-btn mr-1 ml-1 task-action-btn-loading"><CircularProgress className="SaveCircularProgressColor" /> {Strings['Save']}</button>
                                    :
                                    <button type="submit" className="btn form-save-btn mr-1 ml-1"> {Strings['Save']} </button>
                                }
                                <button type="button" className="btn form-cancel-btn m-l-10" onClick={this.props.handleCloseSideBar}>{Strings['Cancel']}</button>
                            </div>


                        </AvForm>
                    }

                </div>

            </div>
        )
    }
}

export default WholesaleOrdersForm;
import React, { Component } from "react";
import {
  Row,
  Col,
  CustomInput,
  Progress,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { toAbsoluteUrl } from "../../../_metronic";
import { Picky } from "react-picky";
import { Button, Drawer } from "@material-ui/core";
import "react-picky/dist/picky.css";
import { translations } from "../../../translate.js";
import Pagination from "react-js-pagination";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CircularProgress from "@material-ui/core/CircularProgress";
import ViewOrderAdmin from "./forms/viewOrderAdmin";
import Tooltip from "rc-tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import SearchIcon from "@material-ui/icons/Search";
import EmailIcon from "@material-ui/icons/Email";
import { confirmAlert } from "react-confirm-alert";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import AddProblem from "./forms/addProblem.js";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
class OrdersAdminList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posList: [],
      selectedPos: [],
      loading: 0,
      loadingTips: 0,
      selectedDistributor: [],
      distributorList: [],
      selectedClient: [],
      clientList: [],
      categoryList: [],
      selectedCategoryList: [],
      cardTypeList: [],
      selectedCardTypeList: [],
      statusList: [],
      selectedStatus: [],
      openOrderAction: false,
      orderType: 0,
      emptyFromDate: 0,
      emptyToDate: 0,
      orderTypeList: [],
      orderFilterOrderId:
        this.props.match.params.orderId == undefined
          ? ""
          : this.props.match.params.orderId,
      orderFilterDateFrom: "",
      orderFilterDateTo: "",
      code: "",
      total: "",
      openHeader: "",
      errorMsg: "",
      refernceNumber: "",
      refernceNumberDist: "",
      openOrderAmount: "",
      classesList: [],
      referncesList: [],
      pageView: this.props.match.params.pageType, // 1 --> doubliacted card , 2--> client orders
      activePage: 1,
      showExportOrder: 0,
      emptyEmail: 0,
      loadingSave: 0,
      searchCode: 0,
      limit: 25,
      loading: 0,
      dataCount: 0,
      data: [],   printFilters: [],
      orderPrint: null,
    };
  }
  handleSecondSearch = () => {};
  handleRefresh = () => {
    // clear all filter data
    this.setState(
      {
        loading: 0,
        orderType: 0,
        showExportOrder: 0,
        emptyEmail: 0,
        loadingSave: 0,
        searchCode: 0,
        code: "",
        refernceNumber: "",
        refernceNumberDist: "",
        emptyFromDate: 0,
        emptyToDate: 0,
        classesList: [],
        selectedStatus: [],
        errorMsg: "",
        total: "",
        selectedDistributor: [],
        selectedClient: [],
        selectedPos: [],
        selectedCategoryList: [],
        selectedCardTypeList: [],
        posList: [],
        clientList: [],
        cardTypeList: [],
        referncesList: [],
        orderFilterOrderId: "",
        orderFilterDateFrom: "",
        orderFilterDateTo: "",
        status: "",
        activePage: 1,
        limit: 25,
        loading: 0,
        dataCount: 0,
        data: [],
        requestId: "",
        rechargeId: "",   
        orderPrint: null,
     
      },
      function() {
        if (this.state.pageView == 2) {
          this.getData();
        }
      }
    );
  };
  componentDidMount() {
    if (this.state.pageView == 1) {
      this.getDublicatedCardOrderType();
    } else {
      this.getPrintStatusFilters();
      this.getOrderStatus();
      this.getCategoryList();
      this.getAllDist();
      this.getData();
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.pageType !== prevProps.match.params.pageType) {
      this.setState(
        {
          pageView: this.props.match.params.pageType,
        },
        function() {
          this.constructor(this.props);
          this.componentDidMount();
        }
      );
    }
  }

  //###################################### api section
  getPrintStatusFilters = async () => {
    try {
      let response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "printFilter",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
        headers: {
          Accept: "application/json",
        },
      });
      if (response.status == 200) {
        let res = await response.json();
        console.log("FILTERS_RESPONSE", res);
        if (res.logout == 1) {
          localStorage.setItem("USER_ID", "");
          localStorage.setItem("USER_TOKEN", "");
          window.location.href = "/logout";
        } else {
          this.setState({ printFilters: res });
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  getDublicatedCardOrderType = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDublicatedCardOrderType",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ orderTypeList: data["data"] });
          }
        }
      });
  };
  getData(withEmail = 0, seacrhCode = 0) {
    console.log("GETDATA");
    if (this.state.pageView == 1) {
      this.setState({ loading: 1 });
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "doublictedCard",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          start: this.state.activePage,
          limit: this.state.limit,
          orderType: this.state.orderType,
          code: seacrhCode,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (typeof data !== "undefined") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else if (data["error"] == 1) {
              this.setState({ errorMsg: data["errorMsg"], loading: 0 });
            } else {
              this.setState({
                data: data["data"],
                dataCount: data["count"],
                loading: 0,
                errorMsg: "",
                showExportOrder: data["showExportOrder"],
              });
            }
          }
        });
    } else {
      this.setState({ sendEmailResult: 0 });
      var sendByMail = "";
      var email = "";
      if (withEmail == 1) {
        sendByMail = 1;
        email = this.state.email;
        this.setState({ loadingSave: 1 });
      } else {
        this.setState({ loading: 1 });
      }
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getCustomerOrder",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          page: this.state.activePage,
          limit: this.state.limit,
          orderFilterDateFrom: this.state.orderFilterDateFrom,
          orderFilterDateTo: this.state.orderFilterDateTo,
          refernceNumber: this.state.refernceNumber,
          referenceNo: this.state.refernceNumberDist,
          orderFilterStatus: this.getSelected("status"),
          orderFilterCardCatg: this.getSelected("category"),
          orderFilterCardId: this.getSelected("card"),
          orderFilterOrderId: this.state.orderFilterOrderId,
          orderFilterPosId: this.getSelected("pos"),
          CLIENT_ID: this.getSelected("client"),
          orderFilterUserId: this.getSelected("dist"),
          orderPrint: this.state.orderPrint?.id,
          mode: 0,
          sendByMail: sendByMail,
          orderFilterCardCode: seacrhCode == 1 ? this.state.code : "",
          code: seacrhCode,
          emails: email,
          orderFilterSubId: this.state.rechargeId,
          orderFilterPhone: this.state.requestId,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else if (data["error"] == 1) {
              this.setState({ errorMsg: data["errorMsg"] });
            } else {
              if (withEmail == 1) {
                this.setState({ sendEmailResult: 1, loadingSave: 0 });
              } else {
                this.setState({
                  data: data["orderData"],
                  dataCount: data["orderDataCounter"],
                  showExportOrder: data["showExportOrder"],
                  loading: 0,
                  // currencyTitle: data['currencyTitle']
                });
              }
            }
          }
        });
    }
  }
  exportHandler() {
    var orderFilterDateFrom = this.state.orderFilterDateFrom;
    if (
      orderFilterDateFrom == "" ||
      orderFilterDateFrom == null ||
      orderFilterDateFrom == undefined
    ) {
      orderFilterDateFrom = "";
    }

    var orderFilterDateTo = this.state.orderFilterDateTo;
    if (
      orderFilterDateTo == "" ||
      orderFilterDateTo == null ||
      orderFilterDateTo == undefined
    ) {
      orderFilterDateTo = "";
    }

    var orderFilterOrderId = this.state.orderFilterOrderId;
    if (
      orderFilterOrderId == "" ||
      orderFilterOrderId == null ||
      orderFilterOrderId == undefined
    ) {
      orderFilterOrderId = "";
    }
    var code = this.state.code;
    if (
      code == "" ||
      code == null ||
      code == undefined ||
      this.state.searchCode == 0
    ) {
      code = "";
    }
    var refernceNumber = this.state.refernceNumber;
    if (
      refernceNumber == "" ||
      refernceNumber == null ||
      refernceNumber == undefined
    ) {
      refernceNumber = "";
    }
    var refernceNumberDist = this.state.refernceNumberDist;
    if (
      refernceNumberDist == "" ||
      refernceNumberDist == null ||
      refernceNumberDist == undefined
    ) {
      refernceNumberDist = "";
    }

    if (orderFilterDateFrom != "" && orderFilterDateTo != "") {
      window.location =
        window.API_PERM_USER +
        "?type=getCustomerOrder&lang=" +
        localStorage.getItem("LANG") +
        "&userId=" +
        localStorage.getItem("USER_ID") +
        "&token=" +
        localStorage.getItem("USER_TOKEN") +
        "&export=1" +
        "&orderFilterDateFrom=" +
        orderFilterDateFrom +
        "&orderFilterDateTo=" +
        orderFilterDateTo +
        "&orderFilterStatus=" +
        this.getSelected("status") +
        "&orderFilterCardCatg=" +
        this.getSelected("category") +
        "&orderFilterCardId=" +
        this.getSelected("card") +
        "&orderFilterPosId=" +
        this.getSelected("pos") +
        "&CLIENT_ID=" +
        this.getSelected("client") +
        "&orderFilterUserId=" +
        this.getSelected("dist") +
        "&orderFilterOrderId=" +
        orderFilterOrderId +
        "&refernceNumber=" +
        refernceNumber +
        "&referenceNo=" +
        refernceNumberDist +
        "&mode=0" +
        "&code=" +
        this.state.searchCode +
        "&orderFilterCardCode=" +
        code +
        "&orderPrint=" +
        (this.state.orderPrint?.id ? this.state.orderPrint?.id : "");
    } else {
      var emptyFromDate = orderFilterDateFrom != "" ? 0 : 1;
      var emptyToDate = orderFilterDateTo != "" ? 0 : 1;
      this.setState({
        emptyFromDate: emptyFromDate,
        emptyToDate: emptyToDate,
        errorDate: 1,
      });
    }
  }
  getAllDist = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getAllDist",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        getType: "all",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ distributorList: data["data"] });
          }
        }
      });
  };
  getPos() {
    if (this.getSelected("dist").length > 0)
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getDistPos",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          distId: this.getSelected("dist"),
          allPos: "2",
          getType: "all",
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState({
                posList: data["data"],
              });
            }
          }
        });
  }
  get_dist_client() {
    if (this.getSelected("dist").length > 0)
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "get_dist_client",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          distId: this.getSelected("dist"),
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState({
                clientList: data["client"],
              });
            }
          }
        });
  }
  getCategoryList() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCardCategory",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        mainDist: this.state.mainDist,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              categoryList: data["data"],
            });
          }
        }
      });
  }
  getOrderStatus() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getOrderStatus",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              statusList: data["data"],
            });
          }
        }
      });
  }
  getCardsList() {
    if (this.getSelected("category").length > 0) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getCardsAdmin",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          catId: this.getSelected("category"),
          mainDist: this.state.mainDist,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (typeof data !== "undefined") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState({
                cardTypeList: data["data"],
              });
            }
          }
        });
    }
  }
  handelShowCardClass = (orderId) => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getClassInfoToOrderPos",
        userId: localStorage.getItem("USER_ID"),
        lang: localStorage.getItem("LANG"),
        token: localStorage.getItem("USER_TOKEN"),
        orderId: orderId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState(
              {
                classData: data["cardClassName"],
                total: data["total"],
                loadingTips: 0,
              },
              function() {
                this.handelFormateClasses();
              }
            );
          }
        }
      });
  };
  printPdf_CSV(id, typeExport) {
    /*
            typeExport >> 1 : pdf
                          2 : csv
        */

    window.open(
      window.API_PERM_USER +
        "/permUser.php?type=" +
        "printPDFOrCSVInvoiceToCustomer" +
        "&lang=" +
        localStorage.getItem("LANG") +
        "&userId=" +
        localStorage.getItem("USER_ID") +
        "&token=" +
        localStorage.getItem("USER_TOKEN") +
        "&orderId=" +
        id +
        "&typeExport=" +
        typeExport,
      "_blank"
    );
  }
  handleClickSendSms(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "sendSMSToCustomer",
        orderId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            var errorIcon = "";
            var errorIconClass = "";
            if (data["error"] == 0) {
              errorIconClass = "success-alert-icon";
              errorIcon = "!";
            } else {
              errorIcon = "!";
              errorIconClass = "alert-delete-icon";
            }
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    {data["error"] == 0 ? (
                      <div className="mb-4">
                        <CheckCircleOutlineIcon className="success-alert-icon" />
                      </div>
                    ) : (
                      <div className="mb-3">
                        <span className={"alert-delete-icon"}>!</span>
                      </div>
                    )}
                    <p>{data["errorMsg"]}</p>
                    {data["error"] == 0 ? (
                      <button
                        type="button"
                        className="btn  alert-btn-margin ml-2 mr-2 success-btn text-success"
                        onClick={onClose}
                      >
                        {Strings.Close}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                        onClick={onClose}
                      >
                        {Strings.Close}
                      </button>
                    )}
                  </div>
                );
              },
            });
          }
        }
      });
  }
  //####################################### react section ################################################
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    if (name == "email") {
      this.setState({
        emptyFromDate: 0,
        emptyToDate: 0,
        [name]: value,
        errorDate: 0,
        emptyEmail: 0,
      });
    } else if (name == "code" && this.state.pageView == 2) {
      this.setState({
        [name]: value,
        emptyFromDate: 0,
        emptyToDate: 0,
        errorDate: 0,
        emptyCode: 0,
      });
    } else {
      this.setState(
        {
          [name]: value,
          emptyFromDate: 0,
          errorDate: 0,
          emptyToDate: 0,
        },
        function() {
          if (this.state.pageView == 1) this.getData();
        }
      );
    }
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getData();
    });
  };
  displayOrderType = (data) => {
    var list = [];
    if (Object.keys(this.state.orderTypeList).length > 0) {
      var typeName = "";
      Object.keys(this.state.orderTypeList).map((rowData, index) => {
        typeName = this.state.orderTypeList[rowData];
        list.push(<option value={rowData}>{typeName}</option>);
      });
    }
    return list;
  };
  openOrderAmount = (id) => {
    this.setState({ openOrderAmount: id });
  };
  handleCloseSideBar = () => {
    this.setState({ openOrderAction: false, orderRowData: [] });
  };
  openOrderAction = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (type == "export") {
      this.printPdf_CSV(data["orderId"], 1);
    } else if (type == "csv") {
      this.printPdf_CSV(data["orderId"], 2);
    } else if (type == "sms") {
      this.sendSms(data["orderId"]);
    } else {
      this.setState({
        orderRowData: data,
        openOrderAction: true,
        actionType: type,
      });
    }
  };
  sendSms(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to send sms ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickSendSms(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  }
  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };
  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
          visibleid: "",
          refernces: "",
        });
      }
    }
  };
  onVisibleChange = (visible, id, type, data = []) => {
    this.setState(
      {
        [type]: id,
        loadingTips: 1,
        total: "",
        referncesList: [],
        classData: [],
      },
      function() {
        if (type == "refernces") {
          this.handelFormateCPRefernces(data);
        } else {
          this.handelShowCardClass(id);
        }
      }
    );
  };
  handleChangeSelect = (type, option) => {
    this.setState(
      {
        [type]: option,
        activePage: 1,
      },
      function() {
        if (type == "selectedDistributor") {
          this.setState(
            {
              selectedPos: [],
              selectedClient: [],
              posList: [],
              clientList: [],
            },
            function() {
              this.getPos();
              this.get_dist_client();
              // this.getData()
            }
          );
        } else if (type == "selectedCategoryList") {
          this.setState(
            (state) => {
              return {
                selectedCardTypeList: [],
                cardTypeList: [],
              };
            },
            function() {
              this.getCardsList();
              // this.getData();
            }
          );
        }
        // else {
        //     // this.getData();
        // }
      }
    );
  };
  getSelected = (type) => {
    if (type == "pos") {
      return this.state.selectedPos.map((data) => data.userId);
    } else if (type == "dist") {
      return this.state.selectedDistributor.map((data) => data.userId);
    } else if (type == "category") {
      return this.state.selectedCategoryList.map((data) => data.id);
    } else if (type == "card") {
      return this.state.selectedCardTypeList.map((data) => data.cardId);
    } else if (type == "client") {
      return this.state.selectedClient.map((data) => data.id);
    } else if (type == "status") {
      return this.state.selectedStatus.map((data) => data.id);
    }
  };
  handleSendEmail = () => {
    if (
      this.state.email != undefined &&
      this.state.email != null &&
      this.state.email != "" &&
      this.state.orderFilterDateFrom != "" &&
      this.state.orderFilterDateTo != ""
    ) {
      this.getData(1);
      this.setState({ emptyEmail: 0 });
    } else {
      var emptyEmail =
        this.state.email != undefined &&
        this.state.email != null &&
        this.state.email != ""
          ? 0
          : 1;
      var emptyFromDate = this.state.orderFilterDateFrom != "" ? 0 : 1;
      var emptyToDate = this.state.orderFilterDateTo != "" ? 0 : 1;
      var errorDate = emptyFromDate == 1 || emptyToDate == 1 ? 2 : 0;
      this.setState({
        emptyEmail: emptyEmail,
        emptyFromDate: emptyFromDate,
        emptyToDate: emptyToDate,
        errorDate: errorDate,
      });
    }
  };
  handleSearchByCode = () => {
    if (
      this.state.code != undefined &&
      this.state.code != null &&
      this.state.code != ""
    ) {
      this.setState({ emptyCode: 0, searchCode: 1 }, function() {
        this.getData(0, 1);
      });
    } else {
      this.setState({ emptyCode: 1 });
    }
  };
  handleSearch = () => {
    this.setState(
      {
        activePage: 1,
        searchCode: 0,
      },
      function() {
        this.getData(0, 0);
      }
    );
  };

  handelFormateClasses = () => {
    let classesList = [];
    if (this.state.classData.length > 0) {
      classesList.push(
        <Row className="buttom-border-tool-tip ">
          <Col
            lg={4}
            md={4}
            sm={4}
            xs={12}
            title={Strings["Denomination"]}
            className="overflow-text-length"
          >
            {Strings["Denomination"]}
          </Col>
          <Col
            lg={3}
            md={3}
            sm={3}
            xs={12}
            title={Strings["Value"]}
            className="overflow-text-length"
          >
            {Strings["Value"]}
          </Col>
          <Col
            lg={2}
            md={2}
            sm={2}
            xs={12}
            title={Strings["Count"]}
            className="overflow-text-length"
          >
            {Strings["Count"]}
          </Col>
          <Col
            lg={3}
            md={3}
            sm={3}
            xs={12}
            title={Strings["Total"]}
            className="overflow-text-length"
          >
            {Strings["Total"]}
          </Col>
        </Row>
      );
      this.state.classData.map((rowData, index) => {
        classesList.push(
          <Row key={index} className="table-body-row">
            <Col
              lg={4}
              md={4}
              sm={4}
              xs={12}
              title={rowData["cardClassName"]}
              className="overflow-text-length main-color-1 font-w-400"
            >
              {rowData["cardClassName"]}
            </Col>
            <Col
              lg={3}
              md={3}
              sm={3}
              xs={12}
              title={rowData["cardPriceFormate"]}
              className="overflow-text-length"
            >
              {rowData["cardPriceFormate"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              title={rowData["cardsCount"]}
              className="overflow-text-length"
            >
              {rowData["cardsCount"]}
            </Col>
            <Col
              lg={3}
              md={3}
              sm={3}
              xs={12}
              title={rowData["finalValue"]}
              className="overflow-text-length"
            >
              {rowData["finalValue"]}
            </Col>
          </Row>
        );
      });
      classesList.push(
        <Row className=" mt-1">
          <Col lg={12} md={12} sm={12} xs={12}>
            {Strings["Total Summation"]} : <span>({this.state.total})</span>
          </Col>
        </Row>
      );
    } else {
      classesList.push(
        <Row className="table-body-row text-center">
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="font-w-500 gray-color"
          >
            {Strings["There are no cards to display"]}
          </Col>
        </Row>
      );
    }
    this.setState({
      classesList: classesList,
    });
  };
  handelFormateCPRefernces = (data) => {
    let referncesList = [];
    referncesList.push(
      <Row className="buttom-border-tool-tip ">
        <Col
          lg={6}
          md={6}
          sm={6}
          xs={12}
          title={Strings["Denomination"]}
          className="overflow-text-length"
        >
          {Strings["Denomination"]}
        </Col>
        <Col
          lg={6}
          md={6}
          sm={6}
          xs={12}
          title={Strings["Reference #"]}
          className="overflow-text-length"
        >
          {Strings["Reference #"]}
        </Col>
      </Row>
    );
    data.map((rowData, index) => {
      referncesList.push(
        <Row key={index} className="table-body-row">
          <Col
            lg={6}
            md={6}
            sm={6}
            xs={12}
            title={rowData["name"]}
            className="overflow-text-length main-color-1 font-w-400"
          >
            {rowData["name"]}
          </Col>
          <Col
            lg={6}
            md={6}
            sm={6}
            xs={12}
            title={rowData["refId"]}
            className="overflow-text-length"
          >
            {rowData["refId"]}
          </Col>
        </Row>
      );
    });
    this.setState({
      referncesList: referncesList,
      loadingTips: 0,
    });
  };
  display_filter = () => {
    return (
      <>
        {this.state.pageView == 1 ? (
          <>
            <Col xs={12} lg={5} md={5} sm={5}>
              <div className="member-searchable-list mb-2">
                <AvField
                  type="select"
                  name="orderType"
                  value={this.state.orderType}
                  onChange={(event) => this.handleInputChange(event)}
                  className={
                    this.state.errorMsg != "" && this.state.orderType == 0
                      ? "filter-empty-email"
                      : ""
                  }
                >
                  <option value="0" defaultValue>
                    {Strings["Select Order Type"]}
                  </option>
                  {this.displayOrderType()}
                </AvField>
              </div>
            </Col>
            <Col xs={12} lg={5} md={5} sm={5}>
              <AvField
                name="code"
                type="text"
                className={
                  this.state.errorMsg != "" && this.state.code == ""
                    ? "filter-empty-email"
                    : ""
                }
                placeholder={Strings["Code"]}
                value={this.state.code}
                errorMessage={
                  this.state.errorMsg != ""
                    ? Strings["This field is required"]
                    : ""
                }
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} lg={2} md={2} sm={2}>
              <Button
                variant="contained"
                type="button"
                className="refresh-action-btn mb-2 mb-md-0"
                onClick={this.handleRefresh}
                title={Strings["Refresh"]}
              >
                <i className="flaticon-refresh reset-filter-btn"></i>
              </Button>
            </Col>
          </>
        ) : (
          <>
            <Col lg={10} md={10} sm={10} xs={12}>
              <div className="member-searchable-list mb-2">
                <Picky
                  value={this.state.selectedDistributor}
                  options={this.state.distributorList}
                  onChange={this.handleChangeSelect.bind(
                    this,
                    "selectedDistributor"
                  )}
                  open={false}
                  valueKey="userId"
                  labelKey="fname"
                  multiple={true}
                  includeSelectAll={true}
                  includeFilter={true}
                  dropdownHeight={200}
                  placeholder={Strings["Distributor Name"]}
                  filterPlaceholder={Strings["Select Distributor Name"]}
                  selectAllText={Strings["Select All"]}
                  allSelectedPlaceholder={Strings["%s selected"]}
                  manySelectedPlaceholder={Strings["%s selected"]}
                  clearFilterOnClose={true}
                />
              </div>
            </Col>
            <Col md={2} lg={2} sm={2} xs={12} className="mb-2">
              <Button
                variant="contained"
                className="refresh-action-btn"
                onClick={this.handleRefresh}
                title={Strings["Refresh"]}
              >
                <i className="flaticon-refresh reset-filter-btn"></i>
              </Button>
            </Col>
            <Col lg={6} md={6} sm={6} xs={12}>
              <div className="member-searchable-list mb-2">
                <Picky
                  value={this.state.selectedPos}
                  options={this.state.posList}
                  onChange={this.handleChangeSelect.bind(this, "selectedPos")}
                  open={false}
                  valueKey="userId"
                  labelKey="name"
                  multiple={true}
                  includeSelectAll={true}
                  includeFilter={true}
                  dropdownHeight={200}
                  placeholder={Strings["POS"]}
                  filterPlaceholder={Strings["Select POS"]}
                  selectAllText={Strings["Select All"]}
                  allSelectedPlaceholder={Strings["%s selected"]}
                  manySelectedPlaceholder={Strings["%s selected"]}
                  clearFilterOnClose={true}
                />
              </div>
            </Col>
            <Col md={6} lg={6} sm={6} xs={12}>
              <div className="member-searchable-list mb-2">
                <Picky
                  value={this.state.selectedClient}
                  options={this.state.clientList}
                  onChange={this.handleChangeSelect.bind(
                    this,
                    "selectedClient"
                  )}
                  open={false}
                  valueKey="id"
                  labelKey={
                    localStorage.getItem("LANG") == 1 ? "arName" : "enName"
                  }
                  multiple={true}
                  includeSelectAll={true}
                  includeFilter={true}
                  dropdownHeight={200}
                  placeholder={Strings["Clients"]}
                  filterPlaceholder={Strings["Select Clients"]}
                  selectAllText={Strings["Select All"]}
                  allSelectedPlaceholder={Strings["%s selected"]}
                  manySelectedPlaceholder={Strings["%s selected"]}
                  clearFilterOnClose={true}
                />
              </div>
            </Col>
            <Col md={6} lg={6} sm={6} xs={12}>
              <div className="member-searchable-list mb-2">
                <Picky
                  value={this.state.selectedCategoryList}
                  options={this.state.categoryList}
                  onChange={this.handleChangeSelect.bind(
                    this,
                    "selectedCategoryList"
                  )}
                  open={false}
                  valueKey="id"
                  labelKey="name"
                  multiple={true}
                  includeSelectAll={true}
                  includeFilter={true}
                  dropdownHeight={200}
                  placeholder={Strings["CategoryMain"]}
                  filterPlaceholder={Strings["Select Category"]}
                  selectAllText={Strings["Select All"]}
                  allSelectedPlaceholder={Strings["%s selected"]}
                  manySelectedPlaceholder={Strings["%s selected"]}
                  clearFilterOnClose={true}
                />
              </div>
            </Col>

            <Col md={6} lg={6} sm={6} xs={12}>
              <div className="member-searchable-list mb-2">
                <Picky
                  value={this.state.selectedCardTypeList}
                  options={this.state.cardTypeList}
                  onChange={this.handleChangeSelect.bind(
                    this,
                    "selectedCardTypeList"
                  )}
                  open={false}
                  valueKey="cardId"
                  labelKey={"name"}
                  multiple={true}
                  includeSelectAll={true}
                  includeFilter={true}
                  dropdownHeight={200}
                  placeholder={Strings["Card Type"]}
                  filterPlaceholder={Strings["Select Card Type"]}
                  selectAllText={Strings["Select All"]}
                  allSelectedPlaceholder={Strings["%s selected"]}
                  manySelectedPlaceholder={Strings["%s selected"]}
                  clearFilterOnClose={true}
                />
              </div>
            </Col>
            <Col md={12} lg={12} sm={12} xs={12}>
              <div className="member-searchable-list mb-2">
                <Picky
                  value={this.state.orderPrint}
                  options={this.state.printFilters}
                  onChange={this.handleChangeSelect.bind(this, "orderPrint")}
                  open={false}
                  valueKey="id"
                  labelKey="name"
                  multiple={false}
                  includeSelectAll={true}
                  includeFilter={true}
                  dropdownHeight={200}
                  placeholder={`${Strings["Select"]} ${Strings["Print Status"]}`}
                  filterPlaceholder={`${Strings["Select"]} ${Strings["Print Status"]}`}
                  selectAllText={Strings["Select All"]}
                  allSelectedPlaceholder={Strings["%s selected"]}
                  manySelectedPlaceholder={Strings["%s selected"]}
                  clearFilterOnClose={true}
                />
              </div>
            </Col>
            <Col md={6} lg={6} sm={6} xs={12}>
              <div className="member-searchable-list mb-2">
                <Picky
                  value={this.state.selectedStatus}
                  options={this.state.statusList}
                  onChange={this.handleChangeSelect.bind(
                    this,
                    "selectedStatus"
                  )}
                  open={false}
                  valueKey="id"
                  labelKey="name"
                  multiple={true}
                  includeSelectAll={true}
                  includeFilter={true}
                  dropdownHeight={200}
                  placeholder={Strings["Status"]}
                  filterPlaceholder={Strings["Select Status"]}
                  selectAllText={Strings["Select All"]}
                  allSelectedPlaceholder={Strings["%s selected"]}
                  manySelectedPlaceholder={Strings["%s selected"]}
                  clearFilterOnClose={true}
                />
              </div>
            </Col>
            <Col md={6} lg={6} sm={6} xs={12}>
              <AvField
                name="orderFilterOrderId"
                type="number"
                placeholder={Strings["Order Number"]}
                value={this.state.orderFilterOrderId}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col className="mb-1" md={6} lg={6} sm={6} xs={12}>
              <AvField
                name="refernceNumber"
                type="text"
                placeholder={Strings["CP Reference #"]}
                value={this.state.refernceNumber}
                onChange={this.handleInputChange}
              />
            </Col>

            <Col className="mb-1" md={6} lg={6} sm={6} xs={12}>
              <AvField
                name="refernceNumberDist"
                type="text"
                placeholder={
                  Strings["Reference # of (Wholesale  or API Order)"]
                }
                value={this.state.refernceNumberDist}
                onChange={this.handleInputChange}
              />
            </Col>
            {/* baraaHide */}
            <Col md={6} lg={6} sm={6} xs={12}>
              <AvField
                name="requestId"
                type="text"
                placeholder={Strings["Request Id"]}
                value={this.state.requestId}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col md={6} lg={6} sm={6} xs={12}>
              <AvField
                name="rechargeId"
                type="text"
                placeholder={Strings["Recharge Id"]}
                value={this.state.rechargeId}
                onChange={this.handleInputChange}
              />
            </Col>

            <Col md={6} lg={6} sm={6} xs={12} className="mb-2">
              <AvField
                name="orderFilterDateFrom"
                type="date"
                label={Strings["From Date"]}
                value={this.state.orderFilterDateFrom}
                onChange={this.handleInputChange}
                className={
                  this.state.emptyFromDate == 1 && "filter-empty-email"
                }
              />
              {this.state.errorDate == 1 && (
                <div className="msg-error">
                  {
                    Strings[
                      "Enter a from and date for the orders to be exported"
                    ]
                  }
                </div>
              )}
            </Col>
            <Col md={6} lg={6} sm={6} xs={12} className="mb-2">
              <AvField
                name="orderFilterDateTo"
                type="date"
                label={Strings["To Date"]}
                value={this.state.orderFilterDateTo}
                onChange={this.handleInputChange}
                className={this.state.emptyToDate == 1 && "filter-empty-email"}
              />
            </Col>
            <Col md={6} lg={6} sm={6} xs={12}>
              <AvField
                name="code"
                type="text"
                placeholder={Strings["Code"]}
                value={this.state.code}
                className={this.state.emptyCode == 1 && "filter-empty-email"}
                onChange={this.handleInputChange}
              />
            </Col>

            <Col md={6} lg={6} sm={6} xs={12}>
              <Button
                variant="contained"
                className="refresh-action-btn  filter-send-email-btn"
                onClick={this.handleSearchByCode}
              >
                <SearchIcon className="reset-filter-btn mr-2 ml-2" />{" "}
                {Strings["SEARCH BY CODE"]}
              </Button>
            </Col>

            <Col md={12}>
              <hr className="filter-hr" />
            </Col>

            <Col md={6} lg={6} sm={6} xs={12}>
              <AvField
                name="email"
                type="email"
                label={Strings["Emails"]}
                value={this.state.email}
                onChange={this.handleInputChange}
                className={this.state.emptyEmail == 1 && "filter-empty-email"}
              />
              {this.state.sendEmailResult == 1 && (
                <div className="main-color-1">
                  {Strings["We will send you this report by email"]}
                </div>
              )}
              {this.state.errorDate == 2 && (
                <div className="msg-error">
                  {
                    Strings[
                      "Enter the From date and the To date for orders to be sent via email"
                    ]
                  }
                </div>
              )}
            </Col>

            <Col md={6} lg={6} sm={6} xs={12}>
              {this.state.loadingSave == 1 ? (
                <Button
                  variant="contained"
                  disabled
                  className="shadow-sm refresh-action-btn refresh-btn-margin filter-send-email-btn task-action-btn-loading "
                >
                  <EmailIcon className="reset-filter-btn mr-2 ml-2" />{" "}
                  <CircularProgress className="SaveCircularProgressColor" />{" "}
                  {Strings["Send By Email"]}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="refresh-action-btn refresh-btn-margin filter-send-email-btn"
                  onClick={this.handleSendEmail}
                >
                  <EmailIcon className="reset-filter-btn mr-2 ml-2" />{" "}
                  {Strings["Send By Email"]}
                </Button>
              )}
            </Col>
          </>
        )}
      </>
    );
  };
  displayHeader() {
    return (
      <Row className="table-header-row">
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={12}
          className="overflow-text-length"
          title={Strings["Order #"]}
        >
          {" "}
          {Strings["Order #"]}
        </Col>
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={12}
          className="overflow-text-length"
          title={Strings["Category"]}
        >
          {Strings["Category"]}
        </Col>
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={12}
          className="overflow-text-length"
          title={Strings["Card"]}
        >
          {" "}
          {Strings["Card"]}
        </Col>
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={12}
          className="overflow-text-length"
          title={Strings["Classes"]}
        >
          {" "}
          {Strings["Classes"]}
        </Col>
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={12}
          className="overflow-text-length"
          title={Strings["Order Amount"]}
        >
          {" "}
          {Strings["Order Amount"]}
        </Col>
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={12}
          className="overflow-text-length"
          title={Strings["Distributor Name"]}
        >
          {Strings["Distributor Name"]}
        </Col>
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={12}
          className="overflow-text-length"
          title={Strings["Order Creator"]}
        >
          {Strings["Order Creator"]}
        </Col>
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={12}
          className="overflow-text-length"
          title={Strings["Account Type of Creator"]}
        >
          {Strings["Account Type of Creator"]}
        </Col>
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={12}
          className="overflow-text-length"
          title={Strings["Type of Order"]}
        >
          {Strings["Type of Order"]}
        </Col>
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={12}
          className="overflow-text-length"
          title={Strings["Order Date/Time"]}
        >
          {Strings["Order Date/Time"]}
        </Col>
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={12}
          className="overflow-text-length"
          title={Strings["Status"]}
        >
          {Strings["Status"]}
        </Col>
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={12}
          className="overflow-text-length text-md-right"
          title={Strings["Action"]}
        >
          {" "}
          {Strings["Action"]}
        </Col>
      </Row>
    );
  }
  displayData() {
    var orderInfo = "";
    var classColStatus = "";
    var classStyle = {
      "text-1": "gray-color",
      "text-2": "warning-color",
      "text-3": "succ-color",
      "text-4": "reject-color",
      "text-5": "addCredit-color",
      "text-6": "wthdrawal-color",
      "text-7": "profit-color",
      "text-8": "status-8-color",
      "text-9": "status-9-color",
      "text-10": "pos-recharge-mng-color",
      "text-11": "pos-correct-mng-color",
      "text-15": "status-10-color",
    };
    var orderAmount = "";
    if (this.state.loading == 0 && this.state.data.length > 0) {
      orderInfo = this.state.data.map((rowData, index) => {
        if (rowData["status"] !== "") {
          classColStatus = classStyle["text-" + rowData["status"]];
        }
        classColStatus = "  bold-font " + classColStatus + " ";
        orderAmount = "";
        if (this.state.openOrderAmount == rowData["orderId"]) {
          if (rowData["sourceOrder"] == 1 || rowData["sourceOrder"] == 3) {
            orderAmount = (
              <Row>
                <Col
                  md={12}
                  title={Strings["Transaction Amount"]}
                  className={"orderClassWidth overflow-text-length"}
                >
                  {Strings["Transaction Amount"]} :{" "}
                  {rowData["transferAmountFromate"]}
                </Col>
                <Col md={12} title={Strings["Net Transfer"]}>
                  {" "}
                  {Strings["Net Transfer"]} : {rowData["totalTransferFromate"]}
                </Col>
                <Col md={12} title={Strings["Cards Value"]}>
                  {Strings["Cards Value"]} : {rowData["cardsAmount"]}
                </Col>
              </Row>
            );
          } else if (rowData["sourceOrder"] == 2) {
            orderAmount = (
              <Row>
                <Col md={12} title={Strings["Cards Value for Distributor"]}>
                  {Strings["Cards Value for Distributor"]} :{" "}
                  {rowData["distAmountOrderForamate"]}{" "}
                </Col>
                <Col md={12} title={Strings["Cards Value for POS"]}>
                  {Strings["Cards Value for POS"]} :{" "}
                  {rowData["amountOrderCurr"]}
                </Col>
              </Row>
            );
          } else {
            orderAmount = (
              <Row>
                <Col md={12} title={Strings["Cards Value for Distributor"]}>
                  {" "}
                  {Strings["Cards Value for Distributor"]} :{" "}
                  {rowData["amountOrderCurr"]}{" "}
                </Col>
              </Row>
            );
          }
        }
        return (
          <Row key={index} className="table-body-row">
            <Col lg={1} md={1} sm={1} xs={12} className="overflow-text-length">
              {rowData["orderId"]}{" "}
              {(window.DOMAIN_TYPE != 1 || window.TEST_MODE == "1") &&
              rowData["identifierDomainArray"] != undefined &&
              rowData["identifierDomainArray"].length > 0 ? (
                <Tooltip
                  placement={
                    localStorage.getItem("LANG") == 1 ? "left" : "right"
                  }
                  title={Strings["CP Reference #"]}
                  visible={
                    this.state.refernces == rowData["orderId"] &&
                    this.state.loadingTips == 0
                      ? true
                      : false
                  }
                  animation="zoom"
                  onVisibleChange={(event) =>
                    this.onVisibleChange(
                      event,
                      rowData["orderId"],
                      "refernces",
                      rowData["identifierDomainArray"]
                    )
                  }
                  trigger="click"
                  overlay={
                    <span className=" tooltipRequestStyle1 report-tooltip-span">
                      {this.state.referncesList}
                    </span>
                  }
                >
                  {this.state.loadingTips == 1 &&
                  this.state.refernces == rowData["orderId"] ? (
                    <CircularProgress className="CircularProgressColor-dark-gray mt-1 ml-2  mb-0 hover-gray " />
                  ) : (
                    <InfoOutlinedIcon
                      title={Strings["CP Reference #"]}
                      className=" mt-1 ml-2 mb-1  hover-gray"
                    />
                  )}
                </Tooltip>
              ) : (
                <span>&nbsp;&nbsp;#</span>
              )}
              {/* <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; #</span> */}
            </Col>
            <Col lg={1} md={1} sm={1} xs={12} title={rowData["catName"]}>
              {rowData["catName"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              title={
                localStorage.getItem("LANG") == 1
                  ? rowData["cardName_ar"]
                  : rowData["cardName_en"]
              }
            >
              {localStorage.getItem("LANG") == 1
                ? rowData["cardName_ar"]
                : rowData["cardName_en"]}
            </Col>
            <Col lg={1} md={1} sm={1} xs={12} title={Strings["Card Class"]}>
              {rowData["haveClass"] == 1 && rowData["status"] == 3 ? ( //
                <Tooltip
                  placement={
                    localStorage.getItem("LANG") == 1 ? "left" : "right"
                  }
                  title={Strings["Card Class"]}
                  visible={
                    this.state.visibleid == rowData["orderId"] &&
                    this.state.loadingTips == 0
                      ? true
                      : false
                  }
                  animation="zoom"
                  onVisibleChange={(event) =>
                    this.onVisibleChange(event, rowData["orderId"], "visibleid")
                  }
                  trigger="click"
                  overlayClassName="rc-tooltip-inner-card-class"
                  overlay={
                    <span className=" tooltipRequestStyle1 report-tooltip-span">
                      {this.state.classesList}
                    </span>
                  }
                >
                  {this.state.loadingTips == 1 &&
                  this.state.visibleid == rowData["orderId"] ? (
                    <CircularProgress className="CircularProgressColor-dark-gray mt-1 ml-2  mb-0 hover-gray " />
                  ) : (
                    <InfoOutlinedIcon
                      title={Strings["Card Class"]}
                      className=" mt-1 ml-2 mb-1  hover-gray"
                    />
                  )}
                </Tooltip>
              ) : (
                "-"
              )}
            </Col>
            <Col lg={1} md={1} sm={1} xs={12} title={rowData["Order Amount"]}>
              <div>
                {this.state.openOrderAmount == rowData["orderId"] ? (
                  this.state.loadingClass == 1 &&
                  this.state.openOrderAmount == rowData["orderId"] ? (
                    <CircularProgress className="CircularProgressColor hover" />
                  ) : (
                    <ExpandLessIcon
                      className="pointer-cursor hover"
                      onClick={() => this.openOrderAmount("")}
                    />
                  )
                ) : (
                  <ExpandMoreIcon
                    className="pointer-cursor hover"
                    onClick={() => this.openOrderAmount(rowData["orderId"])}
                  />
                )}
              </div>
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="  direction-account-name "
              title={rowData["distNameFull"]}
            >
              {rowData["distNameFull"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className=" direction-account-name "
              title={rowData["makerName"]}
            >
              {rowData["makerName"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className=""
              title={
                localStorage.getItem("LANG") == 1
                  ? rowData["accountTypeTitle_ar"]
                  : rowData["accountTypeTitle_en"]
              }
            >
              {localStorage.getItem("LANG") == 1
                ? rowData["accountTypeTitle_ar"]
                : rowData["accountTypeTitle_en"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className=""
              title={
                localStorage.getItem("LANG") == 1
                  ? rowData["sourceOrder_ar"]
                  : rowData["sourceOrder_en"]
              }
            >
              {localStorage.getItem("LANG") == 1
                ? rowData["sourceOrder_ar"]
                : rowData["sourceOrder_en"]}
              {" - "}
              {localStorage.getItem("LANG") == 1
                ? rowData["transPaymentType_ar"]
                : rowData["transPaymentType_en"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className=""
              title={rowData["orderDate"] + "\n" + rowData["orderTime"]}
            >
              {rowData["orderDate"]} <br />
              {rowData["orderTime"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className={classColStatus}
              title={
                localStorage.getItem("LANG") == 1
                  ? rowData["statusTitle_ar"]
                  : rowData["statusTitle_en"]
              }
            >
              {localStorage.getItem("LANG") == 1
                ? rowData["statusTitle_ar"]
                : rowData["statusTitle_en"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className={
                "action-icon-view actionDropdownList actionDropdownListPadding-0 text-md-right"
              }
            >
              <Dropdown
                isOpen={
                  this.state.openHeader == rowData["orderId"] ? true : false
                }
                className="font-size-13 actionDropdownList actionDropdownListPadding-0 orderlistActionlist"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link pt-0 actionDropdownList  actionDropdownListPadding-0"
                  caret
                >
                  <MoreVertIcon
                    className="menu-dots-vertical actionDropdownList"
                    onClick={(event) =>
                      this.openHeaderMenu(event, rowData["orderId"])
                    }
                  />
                </DropdownToggle>
                <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList ">
                  <div
                    className="actionDropdownList"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    <DropdownItem
                      className="actionDropdownList"
                      onClick={(event) => {
                        this.openOrderAction(event, "viewOrder", rowData);
                        // console.log('HELLO WORLD');
                      }}
                    >
                      {Strings["View Order"]}
                    </DropdownItem>
                    {rowData["status"] == 3 &&
                      localStorage.getItem("USER_LEVEL") == 1 && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openOrderAction(event, "export", rowData)
                          }
                        >
                          {Strings["Export"]}
                        </DropdownItem>
                      )}
                    <DropdownItem
                      className="actionDropdownList"
                      onClick={(event) =>
                        this.openOrderAction(event, "add_problem", rowData)
                      }
                    >
                      {Strings["Add Problem"]}
                    </DropdownItem>
                    {this.state.showExportOrder == 1 && rowData["status"] == 3 && (
                      <DropdownItem
                        className="actionDropdownList"
                        onClick={(event) =>
                          this.openOrderAction(event, "csv", rowData)
                        }
                      >
                        CSV
                      </DropdownItem>
                    )}
                    {rowData["status"] == 3 && rowData["sourceOrder"] == 1 && (
                      <DropdownItem
                        className="actionDropdownList"
                        onClick={(event) =>
                          this.openOrderAction(event, "sms", rowData)
                        }
                      >
                        SMS
                      </DropdownItem>
                    )}
                    <DropdownItem
                      className="actionDropdownList"
                      tag="a"
                      href={
                        "/settings/orderProblem/orderProblem/" +
                        rowData["orderId"]
                      }
                    >
                      {Strings["View Order Problems"]}
                    </DropdownItem>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </Col>
            {this.state.openOrderAmount == rowData["orderId"] && (
              <Col md={12} className="pt-3">
                <div>{Strings["Order Amount"]} :</div>
                {orderAmount}
              </Col>
            )}
          </Row>
        );
      });
    }
    return orderInfo;
  }

  render() {
    return (
      <div>
        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="main-color-1 inv-class-view-title mb-4"
          >
            {this.props.match.params.orderId == undefined ? (
              <div>
                {this.state.pageView == 1
                  ? Strings["Duplicate Cards"]
                  : Strings["Client Orders"]}
              </div>
            ) : (
              <Paper elevation={0}>
                <Breadcrumbs
                  separator={
                    localStorage.getItem("LANG") == 1 ? (
                      <NavigateBeforeIcon fontSize="small" />
                    ) : (
                      <NavigateNextIcon fontSize="small" />
                    )
                  }
                  aria-label="Breadcrumb"
                >
                  <Link color="inherit" href={"/wholesaleOrders"}>
                    {Strings["Wholesale Orders"]}
                  </Link>
                  <Typography color="textPrimary">
                    {Strings["Client Orders"]}
                  </Typography>
                </Breadcrumbs>
              </Paper>
            )}

            <hr className="inv-hr-view-2" />
          </Col>
        </Row>
        <Row className="page-filter-view">
          <Col
            xl={{ size: 5, order: 1 }}
            lg={{ size: 7, order: 1 }}
            md={{ size: 7, order: 1 }}
            sm={{ size: 12, order: 1 }}
            xs={{ size: 12, order: 12 }}
          >
            <AvForm ref={(c) => (this.form = c)}>
              <Row className="filter-form">{this.display_filter()}</Row>
            </AvForm>
          </Col>
          <Col
            xl={{ size: 7, order: 1 }}
            lg={{ size: 5, order: 1 }}
            md={{ size: 5, order: 1 }}
            sm={{ size: 10, order: 1 }}
            xs={{ size: 12, order: 12 }}
            className="text-right"
          >
            {this.state.pageView == 2 && (
              <Col lg={12} md={12}>
                <button
                  variant="contained"
                  className="btn form-save-btn ml-1 mr-1 "
                  onClick={this.handleSearch}
                >
                  {Strings["Search"]}{" "}
                  <SearchIcon className="font-size-15  main-color-2" />
                </button>
                {localStorage.getItem("USER_LEVEL") == 1 && (
                  <>
                    <button
                      className="btn page-action-btn ml-1"
                      onClick={(event) => this.exportHandler()}
                    >
                      {Strings["Export"]}
                    </button>
                  </>
                )}
              </Col>
            )}

            <div className="status-square-view algin-btn-account m-9-respsive mt-2">
              {localStorage.getItem("LANG") == 1 ? (
                <>
                  <Row>
                    <Col lg={7} md={7} sm={7} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-1 "></div>{" "}
                        <p className="mb-0 "> {Strings["Not Received"]} </p>
                      </div>
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-2 "></div>{" "}
                        <p className="mb-0 "> {Strings["In Proccess"]} </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} sm={7} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-3 "></div>{" "}
                        <p className="mb-0 "> {Strings["Complete"]} </p>
                      </div>
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-4 "></div>{" "}
                        <p className="mb-0 "> {Strings["Rejected"]} </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} sm={7} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-6  "></div>{" "}
                        <p className="mb-0 "> {Strings["Some Card Used"]} </p>
                      </div>
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-5 "></div>{" "}
                        <p className="mb-0 "> {Strings["Canceled"]} </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} sm={7} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-8 "></div>{" "}
                        <p className="mb-0 ">
                          {" "}
                          {Strings["Some Cards Have Been Recovered"]}{" "}
                        </p>
                      </div>
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-7  "></div>{" "}
                        <p className="mb-0 "> {Strings["Not Canceled"]} </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} sm={7} xs={12}>
                      <div className="task-square-div">
                        <div className="finDistReport-square-10  "></div>{" "}
                        <p className="mb-0 "> {Strings["Canceled Order"]} </p>
                      </div>
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={12}>
                      <div className="task-square-div ">
                        <div className="finDistReport-square-11 "></div>{" "}
                        <p className="mb-0 ">
                          {" "}
                          {Strings["Not Completed Order"]}{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={7} md={7} sm={7} xs={12}>
                      <div className="task-square-div mb-0">
                        <div className="status-square-9"></div>{" "}
                        <p className="mb-0">
                          {" "}
                          {Strings["EcoVoucher Cards Have Been Restored"]}{" "}
                        </p>
                      </div>
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-10  "></div>{" "}
                        <p className="mb-0 ">
                          {" "}
                          {Strings["Visa payment failed"]}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col lg={7} md={7} sm={7} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-1 "></div>{" "}
                        <p className="mb-0 "> {Strings["Not Received"]} </p>
                      </div>
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-2 "></div>{" "}
                        <p className="mb-0 "> {Strings["In Proccess"]} </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} sm={7} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-3 "></div>{" "}
                        <p className="mb-0 "> {Strings["Complete"]} </p>
                      </div>
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-4 "></div>{" "}
                        <p className="mb-0 "> {Strings["Rejected"]} </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} sm={7} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-6  "></div>{" "}
                        <p className="mb-0 "> {Strings["Some Card Used"]} </p>
                      </div>
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-5 "></div>{" "}
                        <p className="mb-0 "> {Strings["Canceled"]} </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} sm={7} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-9"></div>{" "}
                        <p className="mb-0">
                          {" "}
                          {Strings["EcoVoucher Cards Have Been Restored"]}{" "}
                        </p>
                      </div>
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-7  "></div>{" "}
                        <p className="mb-0 "> {Strings["Not Canceled"]} </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} sm={7} xs={12}>
                      <div className="task-square-div">
                        <div className="finDistReport-square-11 "></div>{" "}
                        <p className="mb-0 ">
                          {" "}
                          {Strings["Not Completed Order"]}{" "}
                        </p>
                      </div>
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={12}>
                      <div className="task-square-div ">
                        <div className="finDistReport-square-10  "></div>{" "}
                        <p className="mb-0 "> {Strings["Canceled Order"]} </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <div className="task-square-div mb-0">
                        <div className="status-square-8 "></div>{" "}
                        <p className="mb-0 ">
                          {" "}
                          {Strings["Some Cards Have Been Recovered"]}{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Col>
        </Row>
        {this.state.loading == 0 &&
          this.state.data.length <= 0 &&
          this.state.errorMsg == "" &&
          ((this.state.code != "" &&
            this.state.orderType != 0 &&
            this.state.pageView == 1) ||
            this.state.pageView == 2) && (
            <Row>
              <div className="inv-cards-view pb-5 width-100">
                <div
                  className="text-center"
                  style={{ marginTop: "15%", marginBottom: "15%" }}
                >
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(
                      "/media/ezpay/EZPAY_LOGO_GRAY" +
                        window.MAIN_IMAGE +
                        ".png"
                    )}
                    className="img-fluid no-data-img"
                  />
                  <div className="no-data-text">
                    {Strings["There is no data to display"]}
                  </div>
                </div>
              </div>
            </Row>
          )}
        {this.state.loading == 0 && this.state.errorMsg != "" && (
          <Row>
            <div className="inv-cards-view pb-5 width-100">
              <div
                className="text-center"
                style={{ marginTop: "15%", marginBottom: "15%" }}
              >
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                  )}
                  className="img-fluid no-data-img"
                />
                <div className="no-data-text">{this.state.errorMsg}</div>
              </div>
            </div>
          </Row>
        )}
        {this.state.loading == 0 &&
          this.state.errorMsg == "" &&
          this.state.code == "" &&
          this.state.orderType == 0 &&
          this.state.pageView == 1 && (
            <Row>
              <div className="inv-cards-view pb-5 width-100">
                <div
                  className="text-center"
                  style={{ marginTop: "15%", marginBottom: "15%" }}
                >
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(
                      "/media/ezpay/EZPAY_LOGO_GRAY" +
                        window.MAIN_IMAGE +
                        ".png"
                    )}
                    className="img-fluid no-data-img"
                  />
                  <div className="no-data-text">
                    {
                      Strings[
                        "Select order type and Enter code to display data"
                      ]
                    }
                  </div>
                </div>
              </div>
            </Row>
          )}

        {this.state.loading == 1 && (
          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginTop: "15%" }}
              className="text-center"
            >
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}
        {this.state.loading == 0 && this.state.data.length > 0 && (
          <Row>
            <div className="inv-cards-view width-100">
              {this.displayHeader()}
              {this.displayData()}
              <Col lg={12} md={12} sm={12} xs={12}>
                <Row className="pagenation-view mt-3 mb-4">
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.limit}
                    totalItemsCount={this.state.dataCount}
                    pageRangeDisplayed={4}
                    onChange={this.handlePageChange}
                    prevPageText={
                      <i
                        className={
                          localStorage.getItem("LANG") == 1
                            ? "flaticon2-next"
                            : "flaticon2-back"
                        }
                      ></i>
                    }
                    nextPageText={
                      <i
                        className={
                          localStorage.getItem("LANG") == 1
                            ? "flaticon2-back"
                            : "flaticon2-next"
                        }
                      ></i>
                    }
                    hideFirstLastPages
                    innerClass={
                      "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                    }
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                  />
                </Row>
              </Col>
            </div>
          </Row>
        )}
        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openOrderAction}
          className={"sidebar-modal-view"}
        >
          {this.state.actionType == "viewOrder" && (
            <ViewOrderAdmin
              orderRowData={this.state.orderRowData}
              handleCloseSideBar={this.handleCloseSideBar}
              showExportOrder={this.state.showExportOrder}
            />
          )}
          {this.state.actionType == "add_problem" && (
            <AddProblem
              orderRowData={this.state.orderRowData}
              handleCloseSideBar={this.handleCloseSideBar}
            />
          )}
        </Drawer>
      </div>
    );
  }
}
export default OrdersAdminList;

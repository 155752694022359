import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from '../../../../translate.js';

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class ViewClasses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cradsInfo: this.props.orderRowData.cradsInfo,
        }
    }

    displayCardsInfo() {
        // console.log(this.state.cradsInfo);
        var list = ''
        if (this.state.cradsInfo.length > 0) {
            list = this.state.cradsInfo.map((rowData, index) => {
                return (
                    <Row key={index} className='table-body-row'>
                        <Col md={6} className='main-color-1 font-w-400'>{rowData['cardClassName']}</Col>
                        <Col md={2}>{rowData['value']}</Col>
                        <Col md={2}>{rowData['cardsCount']}</Col>
                        <Col md={2}>{rowData['finalValue']}</Col>
                    </Row>
                )
            })
        }
        return list
    }

    render() {
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col md={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'> {Strings['Classes']}</div>
                        </Col>
                        <Col md={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>
                <div className='sidebar-body-view'>
                    {this.state.cradsInfo.length > 0 &&
                        <Row className='table-header-row'>
                            <Col md={6}>{Strings['Card Type']}</Col>
                            <Col md={2}>{Strings['Value']}</Col>
                            <Col md={2}>{Strings['Count']}</Col>
                            <Col md={2}>{Strings['Total']}</Col>
                        </Row>
                    }
                    {this.displayCardsInfo()}
                </div>
            </div>
        )
    }
}
export default ViewClasses;

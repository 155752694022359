import React, { useEffect } from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import ChangePasswordComponent from "./ChangePasswordComponent";
import { confirmAlert } from "react-confirm-alert";
import { translations } from "../../../translate.js";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

export default function ChangePasswordWrapper() {
  const messageAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              {/* <span className="alert-delete-icon">!</span> */}
            </div>
            <h5>{Strings["Update Your Password"]}</h5>
            <p>
              {Strings["You need to update your password to be able to login"]}
            </p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.ok}
            </button>
          </div>
        );
      },
    });
  };
  useEffect(() => {
    messageAlert();
  }, []);
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside login-logo-main-view"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/ezpay/bg-3.jpg"
                )})`,
              }}
            >
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo login-logo-view">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(
                      "/media/ezpay/EZPAY_LOGO" + window.MAIN_IMAGE + ".png"
                    )}
                  />
                </Link>
                <h3 className="login-main-left-title">
                  CRM & Inventory System
                </h3>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <ChangePasswordComponent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { translations } from '../../../../translate.js';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import { CircularProgress } from "@material-ui/core";
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;


class AddForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: 0,
            startSave: 0,
            errorSelect: 0,
            submitError: '',
            errorCode: '',
            reservedCode: '',
            selectedFormName: '',
            formTypeList: this.props.formTypeList,
            formFieldsList: this.props.formFieldsList,
            name: (this.props.status == 'add') ? '' : this.props.formData['formName'],
            selectedFormName: (this.props.status == 'add') ? '' : this.props.formFieldsList["type"][this.props.formData['formType']]["typeName"],
            name_en: (this.props.status == 'add') ? '' : this.props.formData['formNameEn'],
            formType: (this.props.status == 'add') ? 0 : this.props.formData['formType'],
            formFiledsSelected: (this.props.status == 'add') ? [] : this.props.formData['formData'],
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }
    //############################################# start Api ###################################################
    handleValidSubmit(event) {
        event.preventDefault();
        if (this.state.formType == 0 || this.state.formType == '') {
            this.setState({ errorSelect: 1 })
        } else {
            this.setState({ startSave: 1 })
            fetch(window.API_PERM_USER, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'addForms',
                    'lang': localStorage.getItem('LANG'),
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                    'formId': (this.props.status == 'add') ? '' : this.props.formData['formId'],
                    'formType': this.state.formType,
                    'name': this.state.name,
                    'name-en': this.state.name_en,
                    'dataList': JSON.stringify(this.state.formFiledsSelected),
                }),
                headers: {
                    'Accept': 'application/json',
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (data !== "Nothing found") {
                    if (data['logout'] == 1) {
                        localStorage.setItem('USER_ID', '');
                        localStorage.setItem('USER_TOKEN', '');
                        window.location.href = "/logout";
                    } else if (data['error'] == 1) {
                        this.setState({ submitError: data['errorMsg'], startSave: 0 })
                    } else {
                        this.props.handleFormAction()
                    }
                }
            })

        }
    }
    //############################################# end Api ###################################################
    //############################################# start React ###################################################
    setSelectOption = (option) => {
        return (
            <option value={option.id} key={option.id} data-name={option.name}>{option.name}</option>
        )
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            submitError: '',
            errorSelect: 0
        })
    }
    buildFields = () => {
        let feilds = ''
        let nameLabel = "";
        let nameValue = "";
        if (this.state.formFiledsSelected.length > 0) {
            feilds = this.state.formFiledsSelected.map((rowData, indexData) => {
                nameLabel = "label" + indexData;
                nameValue = "value" + indexData;
                return (
                    <Row key={indexData}>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <AvField name={nameLabel} label={Strings["Column"]} value={rowData['label']} placeholder={Strings['Enter']} disabled />
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12} >
                            <AvField type='number' name={nameValue} label={Strings["Its location in the file"]} value={rowData['val']} placeholder={Strings['Enter']}
                                required errorMessage={Strings['This field is required']}
                                className={this.state.error == 2 ? (this.state.errorCode == rowData["colInExcel"] ? 'filter-empty-email' : (this.state.reservedCode == rowData["colInExcel"] && 'alert-border-input')) : ''}
                                onChange={(event) => this.handleInputChangeDynamic(event, 'val', indexData)}
                            />
                            {(this.state.error == 2 && this.state.errorCode == rowData["colInExcel"]) &&
                                <div className='error-text-red2 mb-3 mt-1'>{Strings['This location is reserved for another column']}</div>
                            }
                            {(this.state.error == 2 && this.state.reservedCode == rowData["colInExcel"]) &&
                                <div className='alert-text-orange mb-3 mt-1'>{Strings['Location is reserved for this column']}</div>
                            }
                        </Col>
                    </Row>
                )

            })
            return feilds
        }
    }
    handleChangeSelect = (event, id) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        var formFields = this.state.formFieldsList;
        var fieldsSelected = value != '' ? formFields["fildes"][value]["feildes"] : []
        var selectedFormName = ''
        if (value != '') {
            var index = event.target.selectedIndex;
            var optionElement = event.target.childNodes[index]
            selectedFormName = optionElement.getAttribute('data-name');
        }
        this.setState(state => {
            return {
                errorCode: '',
                reservedCode: '',
                formType: value,
                formFiledsSelected: fieldsSelected,
                selectedFormName: selectedFormName,
                error: 0
            }
        })
    }
    handleInputChangeDynamic = (event, key, index) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        var allFields = this.state.formFiledsSelected

        const found = allFields.find(obj => {
            return obj.val === value;
        });
        if (found != undefined && value != '' && allFields[index]['colInExcel'] != found.colInExcel) {
            this.setState({
                error: 2,
                errorCode: allFields[index]['colInExcel'],
                reservedCode: found.colInExcel
            })
        } else {
            allFields[index][key] = value
            this.setState({
                formFiledsSelected: allFields,
                errorCode: '',
                reservedCode: '',
                error: 0
            })
        }

    }
    //############################################# end React ###################################################

    render() {
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col lg={10} md={10} sm={10} xs={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'>  {this.props.status == 'add' ? Strings['Add'] : Strings['Edit']} {Strings['Form']}
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={2} xs={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>

                <div className='sidebar-body-view'>
                    <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                        <AvField name="name" label={Strings['Form Name'] + ' *'} placeholder={Strings['Enter Form Name']} value={this.state.name} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                        <AvField name="name_en" label={Strings['Form Name In English'] + ' *'} placeholder={Strings['Enter Form Name In English']} value={this.state.name_en} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                        <div className=' mb-2'>
                            <AvField label={Strings['Form Type'] + ' *'} type="select" name="formType" value={this.state.formType} onChange={(event) => this.handleChangeSelect(event)} required errorMessage={Strings['This field is required']}   >
                                <option style={{
                                    'display': 'block'
                                }} value="" defaultValue>{Strings['Select Form Type']}</option>
                                {this.state.formTypeList.map((option) => this.setSelectOption(option))}
                            </AvField>
                        </div>
                        {(this.state.formFiledsSelected.length > 0) &&
                            <div className='mb-3 mt-4 box-form-fileds'>
                                <Row className="m-b-7">
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className='f-15'> {Strings['Arrangement the columns in the form'] + " (" + this.state.selectedFormName + ") "}
                                        </div>
                                        <hr className="hr-title" />
                                    </Col>
                                </Row>
                                {this.buildFields()}
                            </div>
                        }
                        <div className='text-right mt-4'>
                            {this.state.error == 0 &&
                                <>
                                    {this.state.startSave == 0 ?
                                        <button type="submit" className="btn form-save-btn mr-1 ml-1"> {Strings['Save']} </button>
                                        :
                                        <button type="button" className="btn form-save-btn mr-1 ml-1"><CircularProgress className="SaveCircularProgressColor" /> {Strings['Save']}</button>
                                    }
                                </>
                            }
                            <button type="button" className="btn form-cancel-btn m-l-10" onClick={this.props.handleCloseSideBar}>{Strings['Cancel']}</button>
                        </div>

                        {this.state.submitError &&
                            <div className='error-text-black'><i className='flaticon2-exclamation error-icon mr-1' /> {this.state.submitError}</div>
                        }
                    </AvForm>
                </div>
            </div>
        )
    }
}
export default AddForm;
import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import { translations } from "../../../translate";
import { Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
export default function FailedOrder() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const getMsg = async () => {
    try {
      let response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "checkVisaError",
          orderId: id,
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
        headers: {
          Accept: "application/json",
        },
      });
      let data = await response.json();
      if (data !== "Nothing found") {
        if (data["logout"] == 1) {
          localStorage.setItem("USER_ID", "");
          localStorage.setItem("USER_TOKEN", "");
          window.location.href = "/logout";
        } else if (data["error"] == 1) {
          setErrorMsg(data["errorMsg"]);
        } else {
          setErrorMsg(data["errorMsg"]);
        }
      }
    } catch (error) {
      setErrorMsg(Strings["An error occurred while processing your request."]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMsg();
  }, []);

  return (
    <div className="inv-cards-view pb-5 width-100">
      {loading && (
        <Row>
          <Col md={12} style={{ marginTop: "15%" }} className="text-center">
            <div className="card-body">
              <div
                className="spinner-grow text-dark m-2 main-color-1"
                role="status"
              ></div>
              <div
                className="spinner-grow text-dark m-2 main-color-2"
                role="status"
              ></div>
              <div
                className="spinner-grow text-dark m-2 main-color-1"
                role="status"
              ></div>
              <div
                className="spinner-grow text-dark m-2 main-color-2"
                role="status"
              ></div>
              <div
                className="spinner-grow text-dark m-2 main-color-1"
                role="status"
              ></div>
              <div
                className="spinner-grow text-dark m-2 main-color-2"
                role="status"
              ></div>
            </div>
          </Col>
        </Row>
      )}
      {!loading && errorMsg == "" ? (
        <div
          className="text-center"
          style={{ marginTop: "15%", marginBottom: "15%" }}
        >
          <img
            alt="Logo"
            src={toAbsoluteUrl(
              "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
            )}
            className="img-fluid no-data-img"
          />
          <div className="no-data-text">
            {Strings["An error occurred while processing your request."]}
          </div>
        </div>
      ) : (
        <div
          className="text-center"
          style={{ marginTop: "15%", marginBottom: "15%" }}
        >
          <img
            alt="Logo"
            src={toAbsoluteUrl(
              "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
            )}
            className="img-fluid no-data-img"
          />
          <div className="no-data-text">{errorMsg}</div>
        </div>
      )}
    </div>
  );
}

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { translations } from '../../../../translate.js';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

const SortableItem = SortableElement(({ value }) =>
    <div style={{
        position: 'relative',
        width: '100%',
        display: 'block',
        padding: 10,
        backgroundColor: '#FFF',
        borderBottom: '1px solid #EFEFEF',
        boxSizing: 'border-box',
        WebkitUserSelect: 'none',
        cursor: 'all-scroll',
        opacity: 10,
        visibility: 'visible',
    }}>
        {value}
    </div>
);

const SortableList = SortableContainer(({ items }) => (
    <div className='sort-box-width-mobile' style={{
        width: '100%',
        margin: '0 auto',
        overflow: 'auto',
        backgroundColor: '#f3f3f3',
        border: '1px solid #EFEFEF',
        borderRadius: 6,
        opacity: 10,
        visibility: 'visible',
    }}>
        {items.map((value, index) => <SortableItem key={`item-${index}`} hideSortableGhost={false} index={index} value={value.title} />)}
    </div>
));


class SortCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: 1
        }
    }

    componentDidMount() {
        this.getCategories()
    }

    getCategories() {
        this.setState({
            items: [
                {
                    'id': 1,
                    'title': 'cat 1'
                }, {
                    'id': 2,
                    'title': 'cat 2'
                }, {
                    'id': 3,
                    'title': 'cat 3'
                }
            ],
            loading:0
        })
    }


    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState({
            items: arrayMove(this.state.items, oldIndex, newIndex),
        });
    };


    handleSortAction = (event) => {
        event.preventDefault();
    }

    render() {
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col md={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'> {Strings['Sort Categories']} </div>
                        </Col>
                        <Col md={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>

                <div className='sidebar-body-view'>

                    {this.state.loading == 1 &&
                        <Row>
                            <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                                <div className='card-body'>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                </div>
                            </Col>
                        </Row>
                    }

                    <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />
                    <div className='mt-4 text-right'>
                        <button className="btn form-save-btn mr-1 ml-1" onClick={(event) => this.handleSortAction(event)}> {Strings['Save']} </button>
                        <button type="button" className="btn form-cancel-btn m-l-10" onClick={this.props.handleCloseSideBar}>{Strings['Cancel']}</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default SortCategories;
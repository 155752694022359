/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import Inventory from "../pages/inventory/inventory";
import Pos from "../pages/pos/pos";
import PosEmp from "../pages/pos/posEmp";
import CommingSoon from "../pages/errors/commingSoon";
import Orders from "../pages/orders/orders";
import unCompleteOrder from "../pages/orders/unCompleteOrder";
import BuyCards from "../pages/orders/buyCards";
import OrdersList from "../pages/orders/ordersList";

import Tools from "../pages/tools/tools";
import RequestOrder from "../pages/RequestOrder/RequestOrder";
import DaftraOrder from "../pages/orders/daftraOrder";
import DaftraInventory from "../pages/inventory/daftraInventory";

import DaftraEmp from "../pages/pos/daftraEmp";
import Receipt from "../pages/pos/receipt";
import DistAccountInfo from "../pages/settings/Distributors/pages/DistAccountInfo";
import MyOrdersPOS from "../pages/orders/MyOrdersPOS";
import POSBuyCards from "../pages/orders/POSbuyCards";
import ChangePasswordWrapper from "../pages/auth/ChangePasswordWrapper";

export const Routes = withRouter(({ history }) => {
    const lastLocation = useLastLocation();
    routerHelpers.saveLastLocation(lastLocation);
    const { isAuthorized, menuConfig, userLastLocation } = useSelector(
        ({ auth, urls, builder: { menuConfig } }) => ({
            menuConfig,
            isAuthorized: (localStorage.getItem('USER_ID') != '' && localStorage.getItem('USER_ID') != undefined && localStorage.getItem('USER_ID') != null) ? true : false,
            userLastLocation: routerHelpers.getLastLocation()
        }),
        shallowEqual
    );



    return (
        /* Create `LayoutContext` from current `history` and `menuConfig`. */
        <LayoutContextProvider history={history} menuConfig={menuConfig}>
            <Switch>
                {!isAuthorized ? (
                    /* Render auth page when user at `/auth` and not authorized. */
                    <AuthPage />
                ) : (
                    /* Otherwise redirect to root page (`/`) */
                    <Redirect from="/auth" to={userLastLocation} />
                )}

                <Route path="/error" component={ErrorsPage} />
                <Route path="/logout" component={LogoutPage} />
                <Route path="/password/changePassword" component={ChangePasswordWrapper} />
                <Route path="/pages/inventory/:mainDist?/:posId?" component={Inventory} />
                <Route path="/pages/pos" component={Pos} />
                <Route path="/pages/distributors" component={Pos} />
                {/* <Route path="/pages/DistAccountInfo/:id?" component={DistAccountInfo} /> */}

                <Route path="/pages/posEmp/:pageType?" component={PosEmp} />
                <Route path="/pages/commingSoon" component={CommingSoon} />
                <Route path="/pages/orders" component={Orders} />
                <Route path="/pages/ordersList" component={OrdersList} />
                <Route path="/pages/buyCards" component={BuyCards} />
                <Route path="/pages/unCompleteOrder" component={unCompleteOrder} />
                <Route path="/pages/tools" component={Tools} />
                <Route path="/pages/RequestOrder" component={RequestOrder} />


                {/* DaftraPage Add in 20-01-2021----Start */}
                <Route path="/pages/daftraOrder" component={DaftraOrder} />
                <Route path="/pages/daftraInventory" component={DaftraInventory} />
                <Route path="/pages/daftraEmp" component={DaftraEmp} />
                <Route path="/pages/receipt/:posId?" component={Receipt} />


                {!isAuthorized ? (
                    /* Redirect to `/auth` when user is not authorized */
                    <Redirect to="/auth/login" />
                ) : (
                    <Layout>
                        <HomePage userLastLocation={userLastLocation} />
                    </Layout>
                )}
            </Switch>
        </LayoutContextProvider>
    );
});

import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import { translations } from '../../../translate.js';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;


class DaftraCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: [],
            selectedCategoryId: '',
            data: []
        }
    }

    // https://cp8.easypay-card.com/API/daftra_Api.php?type=getDaftraCategory&page=0&limit=3&
    // categoryName=m&userId=7421&token=413e972f492640456d37ec5dfc3637ad
    getCategoryList() {
        fetch(window.API_DAFTRA_API, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDaftraCategory',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'page': 0

            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            this.setState({ loading: 1 });
            if (typeof data !== 'undefined') {
                // console.log('===============================');
                // console.log(data);

                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({
                        data: data['data'],
                    }, function () {
                        var selectedCategory = []
                        var selectedCategoryId = ''
                        if (this.state.data.length > 0) {
                            selectedCategory = this.state.data[0]
                            selectedCategoryId = this.state.data[0]['categoryId']
                            this.setState({ selectedCategory: selectedCategory, selectedCategoryId: selectedCategoryId })
                        }
                        this.props.loadPageAction(this.state.data.length, selectedCategoryId, selectedCategory)

                    })
                }
            }
        });
    }

    getCategoryCards(selectedCategory) {
        this.setState({
            selectedCategory: selectedCategory,
            selectedCategoryId: selectedCategory.categoryId
        }, function () {
            this.props.changeCategoryCards(selectedCategory.categoryId, selectedCategory)
        })
    }


    displayData() {
        var list = ''
        if (this.state.data.length > 0) {
            list = this.state.data.map((rowData, index) => {
                return (
                    <div className={'inv-category-box ' + (this.state.selectedCategoryId == rowData.categoryId && 'inv-selected-cat-box')} key={index}>
                        <Row>
                            <Col md={9}>
                                <div className='inv-category-box-title main-color-1' title={rowData.categoryName} onClick={() => this.getCategoryCards(rowData)}>
                                    {rowData.categoryName}
                                </div>
                            </Col>

                        </Row>
                    </div>
                )
            })
        }
        return list
    }



    render() {

        return (
            <div>
                {this.state.data.length > 0 &&
                    <div className='inv-category-view'>
                        <div className='inv-category-btn-view ml-1 mb-3 main-color-1 pb-3 inv-cat-main-title'>
                            {Strings['Categories']}
                        </div>
                        <div className='inv-cat-list-height'>
                            {this.displayData()}
                        </div>


                    </div>
                }
            </div>

        )
    }
}
export default DaftraCategory;
import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toAbsoluteUrl } from "../../../../_metronic";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { translations } from '../../../../translate.js';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;


class PurchaseOrderInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 0,
           
        }
    }

    componentDidMount() {
        // console.log(this.props.data);
    }

   

    rowData = (data) => {
        return data != null && data != undefined && data != '' ? data : '-'
    }

    render() {

        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col md={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'>
                                {Strings['Purchase Order Information']}
                                ({(this.props.data.id)})
                            </div>
                        </Col>
                        <Col md={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>


                <div className='sidebar-body-view purchase-info'>

                    <div class="inv-cat-list-height">
                        <div className="inv-cat-box-padding">
                            <div class="inv-category-box inv-selected-cat-box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inv-category-box-title main-color-1">
                                            {Strings['Purchase order date']} : {this.rowData(this.props.data.orderDate)} - {this.rowData(this.props.data.orderTime)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="inv-cat-box-padding">
                            <div class="inv-category-box inv-selected-cat-box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inv-category-box-title main-color-1">
                                            {Strings['Purchase order value']} : {Strings['Actual Value']} : {this.rowData(this.props.data.amount)}  
                                            {/* <span className="mx-4">{Strings['Transaction Value']} : </span> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <div className="inv-cat-box-padding">
                            <div class="inv-category-box inv-selected-cat-box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inv-category-box-title main-color-1">
                                            {Strings['Card Rating']} : {this.rowData(this.props.data.cardCatData.name)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> */}
                        <div className="inv-cat-box-padding">
                            <div class="inv-category-box inv-selected-cat-box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inv-category-box-title main-color-1 ">
                                            {/* {Strings['Card Name']} : { localStorage.getItem('LANG') == 1 ? this.rowData(this.props.data.cardData.name) : this.rowData(this.props.data.cardData.name_en)} */}
                                            {Strings['Card Name']} : {this.rowData(this.props.data.cardData.name)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="inv-cat-box-padding">
                            <div class="inv-category-box inv-selected-cat-box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inv-category-box-title main-color-1 d-flex">
                                            {Strings['Card Category']} : <ul className="w-50">{this.props.data.classesData.map((data) => { return (<li><div className="row"><div className="col-6 mb-2">{this.rowData(data.name)}</div> <div className="col-6 mb-2">{Strings['Count']} : {this.rowData(data.count)}</div></div> </li>) })}</ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="inv-cat-box-padding">
                            <div class="inv-category-box inv-selected-cat-box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inv-category-box-title main-color-1">
                                            {Strings['Distributor Name']} : {this.rowData(this.props.data.distInfo.fname)} {this.props.data.distInfo.lname}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="inv-cat-box-padding">
                            <div class="inv-category-box inv-selected-cat-box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inv-category-box-title main-color-1">
                                            {Strings['Invoice password']} : {this.rowData(this.props.data.invoicePassword)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <div className="inv-cat-box-padding">
                            <div class="inv-category-box inv-selected-cat-box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inv-category-box-title main-color-1">
                                            {Strings['Payment type']} :
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> */}
                        <div className="inv-cat-box-padding">
                            <div class="inv-category-box inv-selected-cat-box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inv-category-box-title main-color-1">
                                            {Strings['Bank account']} : {this.rowData(this.props.data.bankInfo.bankName)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="inv-cat-box-padding">
                            <div class="inv-category-box inv-selected-cat-box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inv-category-box-title main-color-1">
                                            {Strings['Notes']} : {this.rowData(this.props.data.note)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="inv-cat-box-padding">
                            <div class="inv-category-box inv-selected-cat-box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inv-category-box-title main-color-1">
                                            {Strings['Total discount value']} : {this.rowData(this.props.discountValue)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="inv-cat-box-padding">
                            <div class="inv-category-box inv-selected-cat-box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inv-category-box-title main-color-1">
                                            {Strings['Invoice date']} : {Strings['From Date']}  {this.rowData(this.props.data.inoivceDateFrom)} - {Strings['To Date']} {this.rowData(this.props.data.inoivceDateTo)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="inv-cat-box-padding">

                            <div class="inv-category-box inv-selected-cat-box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inv-category-box-title main-color-1">
                                            {Strings['Company Name']} : {this.rowData(this.props.data.companyInfo.name)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>


                    {this.state.loading == 1 && this.state.errorMsg != '' &&
                        <Row>
                            <div className='inv-cards-view pb-5 width-100'>
                                <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
                                    <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                                    <div className='no-data-text'>{this.state.errorMsg}</div>
                                </div>
                            </div>
                        </Row>
                    }

                    {this.state.loading == 1 && this.state.cartData.length <= 0 &&
                        <Row>
                            <div className='inv-cards-view pb-5 width-100'>
                                <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
                                    <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                                    <div className='no-data-text'>{Strings["There is no data to display"]}</div>
                                </div>
                            </div>
                        </Row>
                    }

                    <Row className='inv-cards-view'>
                        <Col>
                            {this.state.loading == 1 &&
                                <Row>
                                    <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                                        <div className='card-body'>
                                            <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                            <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                            <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                            <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                            <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                            <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                        </div>
                                    </Col>
                                </Row>
                            }

                        </Col>
                    </Row>
                </div>
            </div>

        )
    }
}
export default PurchaseOrderInfo;
import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CircularProgress from "@material-ui/core/CircularProgress";

import { translations } from "../../../../../translate.js";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
// let fname = localStorage.getItem('USER_FULL_NAME').split()
class MainAccountForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: "",
      fname: localStorage.getItem("USER_FIRST_NAME"),
      lname: localStorage.getItem("USER_LAST_NAME"),
      userEmail: localStorage.getItem("USER_EMAIL"),
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    // console.log(this.props);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      submitError: "",
    });
  };

  handleValidSubmit(event) {
    event.preventDefault();
    // check Password
    var canSave = 1;
    if (this.state.password != "") {
      if (this.state.password == this.state.rePassword) {
        canSave = 1;
      } else {
        canSave = 0;
      }
    } else {
      canSave = 1;
    }

    if (canSave == 1) {
      this.setState({ loadingSave: 1 });
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "addOrEditAdmin",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          adminId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          fname: this.state.fname,
          lname: this.state.lname,
          userEmail: this.state.userEmail,
          userPassword: this.state.password,
          confirmPassword: this.state.rePassword,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            console.log(data);
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else if (data["error"] == 0) {
              if (
                !(
                  localStorage.getItem("INV_MGR") == 1 ||
                  localStorage.getItem("ACC_EMP") == 1 ||
                  localStorage.getItem("POS_MGR_EMP") == 1 ||
                  localStorage.getItem("OPERA_EMP") == 1
                )
              ) {
                localStorage.setItem(
                  "USER_FULL_NAME",
                  this.state.fname + " " + this.state.lname
                );
                localStorage.setItem("USER_EMAIL", this.state.userEmail);
                localStorage.setItem("USER_FIRST_NAME", this.state.fname);
                localStorage.setItem("USER_LAST_NAME", this.state.lname);
              }
              this.setState({ loadingSave: 0 });
              if (
                !(
                  localStorage.getItem("INV_MGR") == 1 ||
                  localStorage.getItem("POS_MGR_EMP") == 1 ||
                  localStorage.getItem("ACC_EMP") == 1 ||
                  localStorage.getItem("OPERA_EMP") == 1
                )
              ) {
                window.location = "/settings/accountInfo/accountInfo";
                this.props.handleFormAction();
              } else {
                this.setState({ success: data["errorMsg"] });
              }
            } else {
              this.setState({ submitError: data["errorMsg"] });
            }
          }
        });
    } else {
      this.setState({
        submitError: Strings["Password does not match"],
      });
    }
  }

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col md={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Edit Account Information"]}{" "}
              </div>
            </Col>
            <Col md={2} className="text-right">
              <HighlightOffIcon
                onClick={
                  localStorage.getItem("INV_MGR") == 1 ||
                  localStorage.getItem("POS_MGR_EMP") == 1 ||
                  localStorage.getItem("ACC_EMP") == 1 ||
                  localStorage.getItem("OPERA_EMP") == 1
                    ? (event) =>
                        this.props.handleCloseSideBar(false, "main_account")
                    : this.props.handleCloseSideBar
                }
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <AvForm
            onValidSubmit={this.handleValidSubmit}
            ref={(c) => (this.form = c)}
          >
            <AvField
              name="fname"
              disabled={
                localStorage.getItem("INV_MGR") == 1 ||
                localStorage.getItem("ACC_EMP") == 1 ||
                localStorage.getItem("POS_MGR_EMP") == 1 ||
                localStorage.getItem("OPERA_EMP") == 1 || localStorage.getItem("USER_LEVEL") == 14 
                  ? true
                  : false
              }
              label={Strings["First Name"] + " *"}
              placeholder={Strings["Enter First Name"]}
              value={this.state.fname}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              name="lname"
              disabled={
                localStorage.getItem("INV_MGR") == 1 ||
                localStorage.getItem("ACC_EMP") == 1 ||
                localStorage.getItem("POS_MGR_EMP") == 1 ||
                localStorage.getItem("OPERA_EMP") == 1|| localStorage.getItem("USER_LEVEL") == 14 
                  ? true
                  : false
              }
              type="text"
              label={Strings["Last Name"] + " *"}
              placeholder={Strings["Enter Last Name"]}
              value={this.state.lname}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              name="userEmail"
              disabled={
                localStorage.getItem("INV_MGR") == 1 ||
                localStorage.getItem("ACC_EMP") == 1 ||
                localStorage.getItem("POS_MGR_EMP") == 1 ||
                localStorage.getItem("OPERA_EMP") == 1|| localStorage.getItem("USER_LEVEL") == 14 
                  ? true
                  : false
              }
              autoComplete="off"
              for
              type="email"
              label={Strings["Email"] + " *"}
              placeholder={Strings["Enter Email"]}
              value={this.state.userEmail}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              name="password"
              autoComplete="new-password"
              type="password"
              label={Strings["Password"]}
              placeholder={Strings["Enter Password"]}
              value={this.state.password}
              onChange={this.handleInputChange}
            />
            <AvField
              name="rePassword"
              type="password"
              label={Strings["Confirm"] + " " + Strings["Password"] + " *"}
              placeholder={Strings["Enter Password"]}
              value={this.state.rePassword}
              onChange={this.handleInputChange}
            />

            <div className="text-right mt-4">
              {this.state.loadingSave == 1 ? (
                <button
                  type="button"
                  className="btn form-save-btn mr-1 ml-1 task-action-btn-loading"
                >
                  <CircularProgress className="SaveCircularProgressColor" />{" "}
                  {Strings["Save"]}
                </button>
              ) : (
                <button type="submit" className="btn form-save-btn mr-1 ml-1">
                  {" "}
                  {Strings["Save"]}{" "}
                </button>
              )}
              <button
                type="button"
                className="btn form-cancel-btn m-l-10"
                onClick={
                  localStorage.getItem("INV_MGR") == 1 ||
                  localStorage.getItem("POS_MGR_EMP") == 1 ||
                  localStorage.getItem("ACC_EMP") == 1 ||
                  localStorage.getItem("OPERA_EMP") == 1
                    ? (event) =>
                        this.props.handleCloseSideBar(false, "main_account")
                    : this.props.handleCloseSideBar
                }
              >
                {Strings["Cancel"]}
              </button>
            </div>

            {this.state.submitError && (
              <div className="error-text-black">
                <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                {this.state.submitError}
              </div>
            )}
            {this.state.success && (
              <div className="error-text-black">
                <i
                  className={"flaticon2-check-mark success-icon mr-1 mb-3 mt-4"}
                />{" "}
                {this.state.success}
              </div>
            )}
          </AvForm>
        </div>
      </div>
    );
  }
}
export default MainAccountForm;

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import BallotIcon from '@material-ui/icons/Ballot';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { Drawer } from "@material-ui/core";

import AddNotifications from '../pos/forms/addNotifications'
import AddDenomination from '../pos/forms/addDenomination'
import AddOrGetAmount from '../pos/forms/addOrGetAmount'
import SortCategories from './forms/sortCategories'

import { translations } from '../../../translate.js';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class Tools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openLeftModal: false,
            actionType: ''
        }
    }

    componentDidMount() {

    }

    openSidebarModal = (event, type, viewMode) => {
        event.preventDefault();
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({
            actionType: type,
            viewMode: viewMode,
            openleftModal: true
        })
    }

    handleCloseSideBar = () => {
        this.setState({ openleftModal: false, actionType: '', viewMode: '' })
    }


    render() {
        return (
            <div>

                <Row className='mt-4'>
 
                    <Col xs={12} sm={6} lg={{ size: 4, offset: 2 }} md={{ size: 4, offset: 2 }} className='tools-margin-right'>
                        <div className='pos-main-profile-section-box main-color-1 tools-box' onClick={(event) => this.openSidebarModal(event, 'addBalance', 'small')}>
                            <div>
                                <AccountBalanceWalletIcon className='pos-main-profile-section-box-icon f-100' />
                            </div>
                            <div className='f-16'>{Strings['Add Balance']}</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={4}>
                        <div className='pos-main-profile-section-box main-color-1 tools-box' onClick={(event) => this.openSidebarModal(event, 'addNotifications', 'large')}>
                            <div>
                                <NotificationsActiveIcon className='pos-main-profile-section-box-icon f-100' />
                            </div>
                            <div className='f-16'>{Strings['Notifications']}</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={{ size: 4, offset: 2 }} lg={{ size: 4, offset: 2 }} className='tools-margin-right'>
                        <div className='pos-main-profile-section-box main-color-1 tools-box' onClick={(event) => this.openSidebarModal(event, 'classes', 'large')}>
                            <div>
                                <BallotIcon className='pos-main-profile-section-box-icon f-100' />
                            </div>
                            <div className='f-16'>{Strings['Classes']}</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={4}>
                        <div className='pos-main-profile-section-box main-color-1 tools-box' onClick={(event) => this.openSidebarModal(event, 'sortCategories', 'small')}>
                            <div>
                                <ImportExportIcon className='pos-main-profile-section-box-icon f-100' />
                            </div>
                            <div className='f-16'>{Strings['Sort Categories']}</div>
                        </div>
                    </Col>
                </Row>


                <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.openleftModal} className={this.state.viewMode == 'large' ? 'sidebar-modal-large-view' : 'sidebar-modal-view'}>

                    {/* need edit ... for tool page case */}
                    {this.state.actionType == 'addBalance' &&
                        <AddOrGetAmount
                            page="tools"
                            handleCloseSideBar={this.handleCloseSideBar}
                            recallGetData={this.handleCloseSideBar} />
                    }

                    {this.state.actionType == 'addNotifications' &&
                        <AddNotifications
                            handleCloseSideBar={this.handleCloseSideBar}
                            handleFormAction={this.handleCloseSideBar} />
                    }

                    {this.state.actionType == 'classes' &&
                        <AddDenomination
                            handleCloseSideBar={this.handleCloseSideBar}
                            handleFormAction={this.handleCloseSideBar} />
                    }

                    {this.state.actionType == 'sortCategories' &&
                        <SortCategories
                        handleCloseSideBar={this.handleCloseSideBar}  />
                    }

                </Drawer>
            </div>
        )
    }
}
export default Tools;
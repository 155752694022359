import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { translations } from '../../../../translate.js';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import { CircularProgress } from "@material-ui/core";
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;


class AddDiscount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: 0,
            startSave: 0,
            errorSelect: 0,
            submitError: '',
            errorCode: '',
            discountTypeList: this.props.discountTypeList,
            name: (this.props.status == 'add') ? '' : this.props.discountData['name'],
            name_en: (this.props.status == 'add') ? '' : this.props.discountData['nameEn'],
            discountType: (this.props.status == 'add') ? 0 : this.props.discountData['type'],
            discountValue: (this.props.status == 'add') ? 0 : this.props.discountData['discountValue'],
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }
    //############################################# start Api ###################################################
    handleValidSubmit(event) {
        event.preventDefault();
        if (this.state.discountType == 0 || this.state.discountType == '') {
            this.setState({ errorSelect: 1 })
        } else {
            this.setState({ startSave: 1 })
            fetch(window.API_PERM_USER, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'addDiscount',
                    'lang': localStorage.getItem('LANG'),
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                    'id': (this.props.status == 'add') ? '' : this.props.discountData['id'],
                    'discountType': this.state.discountType,
                    'discountValue': this.state.discountValue,
                    'name': this.state.name,
                    'nameEn': this.state.name_en
                }),
                headers: {
                    'Accept': 'application/json',
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (data !== "Nothing found") {
                    if (data['logout'] == 1) {
                        localStorage.setItem('USER_ID', '');
                        localStorage.setItem('USER_TOKEN', '');
                        window.location.href = "/logout";
                    } else if (data['error'] == 1) {
                        this.setState({ submitError: data['errorMsg'], startSave: 0 })
                    } else {
                        this.props.handleFormAction()
                    }
                }
            })

        }
    }
    //############################################# end Api ###################################################
    //############################################# start React ###################################################
    setSelectOption = (option) => {
        return (
            <option value={option.id} key={option.id} data-name={option.name}>{option.name}</option>
        )
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            submitError: '',
            errorSelect: 0
        })
    }
    handleChangeSelect = (event, id) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;     
        this.setState(state => {
            return {
                [name]: value,
                error: 0
            }
        })
    }
    //############################################# end React ###################################################

    render() {
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col lg={10} md={10} sm={10} xs={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'>  {this.props.status == 'add' ? Strings['Add'] : Strings['Edit']} {Strings['Discount']}
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={2} xs={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>

                <div className='sidebar-body-view'>
                    <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                        <AvField name="name" label={Strings['Discount Name'] + ' *'} placeholder={Strings['Enter Discount Name']} value={this.state.name} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                        <AvField name="name_en" label={Strings['Discount Name In English'] + ' *'} placeholder={Strings['Enter Discount Name In English']} value={this.state.name_en} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                        <AvField name="discountValue" label={Strings['Discount Value'] + ' *'} placeholder={Strings['Discount Value']} value={this.state.discountValue} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />

                        <div className=' mb-2'>
                            <AvField label={Strings['Discount Type'] + ' *'} type="select" name="discountType" value={this.state.discountType} onChange={(event) => this.handleInputChange(event)} required errorMessage={Strings['This field is required']}   >
                                <option style={{
                                    'display': 'block'
                                }} value="" defaultValue>{Strings['Select Discount Type']}</option>
                                {this.state.discountTypeList.map((option) => this.setSelectOption(option))}
                            </AvField>
                        </div>
                     
                        <div className='text-right mt-4'>
                            {this.state.error == 0 &&
                                <>
                                    {this.state.startSave == 0 ?
                                        <button type="submit" className="btn form-save-btn mr-1 ml-1"> {Strings['Save']} </button>
                                        :
                                        <button type="button" className="btn form-save-btn mr-1 ml-1"><CircularProgress className="SaveCircularProgressColor" /> {Strings['Save']}</button>
                                    }
                                </>
                            }
                            <button type="button" className="btn form-cancel-btn m-l-10" onClick={this.props.handleCloseSideBar}>{Strings['Cancel']}</button>
                        </div>

                        {this.state.submitError &&
                            <div className='error-text-black'><i className='flaticon2-exclamation error-icon mr-1' /> {this.state.submitError}</div>
                        }
                    </AvForm>
                </div>
            </div>
        )
    }
}
export default AddDiscount;
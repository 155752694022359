import React, { Component } from "react";
import { translations } from "../../../../translate";
import { Col, Row, Progress } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import { CircularProgress } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
var today = new Date();
var currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);

export default class POSAddCardOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      errMessage: "",
      password: "",
      confirmPassword: "",
      formError: "",
      qty: 1,
      selectedPaymentMethod: "",
      playerId: "",
      countries: [],
      selectedCountry: "",
      invoiceAmount: 0,
      paymentMethods: [],
    };
  }
  getFilterPaymentsTypes = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getFilterPaymentsTypes",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        isDirectCharge: this.props.isDirectCharge,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              paymentMethods: data,
            });
          }
        }
      });
  };
  chargeMsg = (msg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-Succsess-icon">✓</span>
            </div>
            <h5>{Strings["Done Successfully"]}</h5>
            <p>{msg.replaceAll('-','')}</p>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                window.location.reload();
                onClose();
              }}
            >
              {Strings.Done}
            </button>
          </div>
        );
      },
    });
  };
  alertPlayerCheck = (msg, callback) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{msg}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                onClose();
                callback();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };
  setUpSelectOptions = (data, type) => {
    console.log({ data, type });
    if (type == "country") {
      return (
        <option value={data.codeNumber} key={data.codeNumber}>
          {data.name}
        </option>
      );
    } else {
      return (
        <option value={data.id} key={data.id}>
          {data.title}
        </option>
      );
    }
  };
  getCountries = async () => {
    const body = {
      type: "getAllActiveCountries",
      lang: localStorage.getItem("LANG"),
      userId: localStorage.getItem("USER_ID"),
      token: localStorage.getItem("USER_TOKEN"),
      catId: this.props.catId,
      cardId: this.props.cardId,
    };
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ errorMsg: data["errorMsg"], loading: 0 });
          } else {
            console.log({ countries: data });
            this.setState({ countries: data });
          }
        }
      });
  };
  submitHandler = async () => {
    console.log(
      this.props.isDirectCharge,
      "this.props.isDirectCharge",
      Number(this.state.qty),
      Number(this.state.qty) > 10
    );

    if (
      this.props.isDirectCharge == 0 &&
      (Number(this.state.qty) < 1 || Number(this.state.qty) > 10)
    ) {
      return;
    }
    const body =
      this.props.isDirectCharge == 0
        ? {
            type: "posOrder",
            lang: localStorage.getItem("LANG"),
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            classId: this.props.id,
            qty: this.state.qty,
          }
        : this.props.isDirectCharge == 2 ||
          this.props.isDirectCharge == 5 ||
          this.props.isDirectCharge == 8
        ? {
            type: "sendDirectChargeRequest",
            lang: localStorage.getItem("LANG"),
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            classId: this.props.id,
            invoiceAmount: this.state.invoiceAmount
              ? this.state.invoiceAmount
              : "",
            phone: this.state.selectedCountry + this.state.phone,
            noteTwo: this.state.note,
            id: this.state.playerId,
            name: "",
            noteThree: "",
          }
        : {
            type: "sendDirectChargeRequestApi",
            lang: localStorage.getItem("LANG"),
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            classId: this.props.id,
            phone: this.state.selectedCountry + this.state.phone,
            noteTwo: this.state.note,
          };
    let validCardBuy =
      this.props.isDirectCharge == 0 &&
      this.state.qty > 0 &&
      this.state.selectedPaymentMethod != "";
    let validChargeBuy =
      (this.props.isDirectCharge == 2 ||
        this.props.isDirectCharge == 5 ||
        this.props.isDirectCharge == 8) &&
      this.state.playerId != "" &&
      this.state.selectedCountry != "" &&
      this.state.phone != "" &&
      this.state.selectedPaymentMethod != "";
    let validApiChargeBuy =
      (this.props.isDirectCharge == 3 ||
        this.props.isDirectCharge == 4 ||
        this.props.isDirectCharge == 6 ||
        this.props.isDirectCharge == 7 ||
        this.props.isDirectCharge == 9) &&
      this.state.selectedCountry != "" &&
      this.state.phone != "" &&
      this.state.selectedPaymentMethod != "";
    if (validCardBuy || validChargeBuy || validApiChargeBuy) {
      if (
        (this.props.isDirectCharge == 5 || this.props.isDirectCharge == 8) &&
        this.state.invoiceAmount == ""
      ) {
        return;
      }
      if (this.props.isDirectCharge == 2 && this.props.inquiryType) {
        fetch(window.API_PERM_USER, {
          method: "POST",
          body: JSON.stringify({
            type: "inquiryPlayerByPlayerId",
            lang: localStorage.getItem("LANG"),
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            playerId: this.state.playerId,
            cardId: this.props.cardId,
          }),
          headers: {
            Accept: "application/json",
          },
        })
          .then(function(response) {
            return response.json();
          })
          .then((data) => {
            if (data !== "Nothing found") {
              if (data["logout"] == 1) {
                localStorage.setItem("USER_ID", "");
                localStorage.setItem("USER_TOKEN", "");
                window.location.href = "/logout";
              } else if (data["error"] == 1) {
                this.setState({ formError: data["errMsg"], startSave: 0 });
              } else if (data["error"] == 0) {
                this.alertPlayerCheck(
                  data.username
                    ? `هل انت من متاكد من انك تريد الشحن ل:
الحساب:${data.username}
صاحب المعرف:${data.playerId}
اللعبة:${data.game}`
                    : `هل انت من متاكد من انك تريد الشحن ل:
صاحب المعرف:${data.playerId}`,
                  async () => {
                    if (this.state.selectedPaymentMethod == 1) {
                      this.setState({ startSave: 1 });
                      fetch(window.API_PERM_USER, {
                        method: "POST",
                        body: JSON.stringify(body),
                        headers: {
                          Accept: "application/json",
                        },
                      })
                        .then(function(response) {
                          return response.json();
                        })
                        .then((data) => {
                          if (data !== "Nothing found") {
                            if (data["logout"] == 1) {
                              localStorage.setItem("USER_ID", "");
                              localStorage.setItem("USER_TOKEN", "");
                              window.location.href = "/logout";
                            } else if (data["error"] == 1) {
                              this.setState({
                                formError: data["errorMsg"],
                                startSave: 0,
                              });
                            } else {
                              this.setState({ startSave: 0 });
                              if (this.props.isDirectCharge == 0) {
                                this.props.history.push(
                                  `/myOrder/${data.orderId}`
                                );
                              } else {
                                this.chargeMsg(data["errorMsg"]);
                              }
                            }
                          }
                        });
                    } else if (this.state.selectedPaymentMethod == 9) {
                    }
                  }
                );
                // this.setState({ startSave: 0 });
                // if (this.props.isDirectCharge == 0) {
                //   this.props.history.push(`/myOrder/${data.orderId}`);
                // }else {
                //   this.chargeMsg(data["errorMsg"])
                // }
              }
            }
          });
      } else {
        if (this.state.selectedPaymentMethod == 1) {
          this.setState({ startSave: 1 });
          fetch(window.API_PERM_USER, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              Accept: "application/json",
            },
          })
            .then(function(response) {
              return response.json();
            })
            .then((data) => {
              if (data !== "Nothing found") {
                if (data["logout"] == 1) {
                  localStorage.setItem("USER_ID", "");
                  localStorage.setItem("USER_TOKEN", "");
                  window.location.href = "/logout";
                } else if (data["error"] == 1) {
                  this.setState({ formError: data["errorMsg"], startSave: 0 });
                } else {
                  this.setState({ startSave: 0 });
                  if (this.props.isDirectCharge == 0) {
                    this.props.history.push(`/myOrder/${data.orderId}`);
                  } else {
                    this.chargeMsg(data["errorMsg"]);
                  }
                }
              }
            });
        } else if (this.state.selectedPaymentMethod == 9) {
          window.location = `${
            window.API_PERM_USER
          }?type=payByVisa&userId=${localStorage.getItem(
            "USER_ID"
          )}&token=${localStorage.getItem(
            "USER_TOKEN"
          )}&lang=${localStorage.getItem("LANG")}&classId=${
            this.props.id
          }&qty=${this.state.qty}`;
        }
      }
    }
  };

  componentDidMount() {
    this.getFilterPaymentsTypes();
    this.getCountries();
    console.log("isDirectCharge:", this.props.isDirectCharge);
  }
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (this.state.selectedTransfer?.id == 1) {
      //     distCurrencyAmount8
      // rate
      // amount

      this.setState(
        {
          [name]: value,
        },
        function() {}
      );
    } else {
      this.setState(
        {
          [name]: value,
        },
        function() {}
      );
    }
  };

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {`${Strings["buy"]} ${this.props.name}`}
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>
        <div className="sidebar-body-view">
          {this.state.loading == 1 && (
            <Row>
              <Col md={12} style={{ marginTop: "15%" }} className="text-center">
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}
          {this.state.loading == 0 && (
            <>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <AvForm onSubmit={this.submitHandler}>
                    <Row>
                      {(this.props.isDirectCharge == 0 ||
                        !this.props.isDirectCharge) && (
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <AvField
                            name="qty"
                            type="number"
                            label={Strings["Quantity"]}
                            min={1}
                            max={10}
                            value={this.state.qty}
                            onChange={this.handleInputChange}
                            errorMessage={Strings["This field is required"]}
                            required
                          />
                        </Col>
                      )}
                      {(this.props.isDirectCharge == 8 ||
                        this.props.isDirectCharge == 5 ||
                        this.props.isDirectCharge == 2) && (
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <AvField
                            name="playerId"
                            type="text"
                            label={
                              this.props.isDirectCharge == 5
                                ? Strings.billingNumber
                                : this.props.isDirectCharge == 8
                                ? Strings.referenceNumber
                                : Strings.playerId
                            }
                            placeholder={
                              this.props.isDirectCharge == 5
                                ? Strings.billingNumber
                                : this.props.isDirectCharge == 8
                                ? Strings.referenceNumber
                                : Strings.playerId
                            }
                            value={this.state.playerId}
                            onChange={this.handleInputChange}
                            errorMessage={Strings["This field is required"]}
                            required
                          />
                          {(this.props.isDirectCharge == 5 ||
                            this.props.isDirectCharge == 8) && (
                            <p>
                              {
                                Strings[
                                  "The billing number is the billing number,subscriber number, phone number, or university number"
                                ]
                              }
                            </p>
                          )}
                        </Col>
                      )}
                      {this.props.isDirectCharge != 0 && (
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <AvField
                            type="select"
                            label={Strings["Countries"] + " *"}
                            name="selectedCountry"
                            value={this.state.selectedCountry}
                            onChange={this.handleInputChange}
                            className="p-1 pl-3"
                            errorMessage={Strings["This field is required"]}
                            required
                          >
                            <option
                              style={{ display: "none" }}
                              value=""
                              selected="selected"
                            >
                              {Strings["Select Country Name"]}
                            </option>
                            {this.state.countries?.length > 0 &&
                              this.state.countries.map((data) =>
                                this.setUpSelectOptions(data, "country")
                              )}
                          </AvField>
                        </Col>
                      )}
                      {this.props.isDirectCharge != 0 &&
                        this.state.selectedCountry != "" && (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <AvField
                              name="phone"
                              type="text"
                              label={Strings["Phone Number"]}
                              placeholder={Strings["Enter Phone Number"]}
                              value={this.state.phone}
                              onChange={this.handleInputChange}
                              errorMessage={Strings["This field is required"]}
                              required
                            />
                          </Col>
                        )}
                      {(this.props.isDirectCharge == 5 ||
                        this.props.isDirectCharge == 8) && (
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <AvField
                            name="invoiceAmount"
                            type="number"
                            label={Strings["invoiceAmount"]}
                            placeholder={Strings["invoiceAmount"]}
                            value={this.state.invoiceAmount}
                            onChange={this.handleInputChange}
                            errorMessage={Strings["This field is required"]}
                            required
                          />
                        </Col>
                      )}
                      {this.props.isDirectCharge != 0 && (
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <AvField
                            name="note"
                            type="textarea"
                            rows="4"
                            label={Strings["Add Note"] + " *"}
                            placeholder={Strings["Add Note"]}
                            value={this.state.note}
                            onChange={this.handleInputChange}
                          />
                        </Col>
                      )}
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <AvField
                          type="select"
                          required
                          label={Strings["Payment Method"] + " *"}
                          name="selectedPaymentMethod"
                          value={this.state.selectedPaymentMethod}
                          onChange={this.handleInputChange}
                          className="p-1 pl-3"
                          errorMessage={Strings["This field is required"]}
                        >
                          <option
                            style={{ display: "none" }}
                            value=""
                            selected="selected"
                          >
                            {Strings["Select"] +
                              " " +
                              Strings["Payment Method"]}
                          </option>
                          {this.state.paymentMethods?.length > 0 &&
                            this.state.paymentMethods.map((data) => (
                              <option value={data.id} key={data.id}>
                                {data.name}
                              </option>
                            ))}
                          {/* <option value="1">{Strings["Balance"]}</option> */}
                          {/* {this.props.isDirectCharge == 0 &&
                            localStorage.getItem("VISA_PAY") == 1 && (
                              <option value="9">
                                {Strings["Credit card"]}
                              </option>
                            )} */}
                        </AvField>
                      </Col>

                      <Col lg={12} md={12} sm={12} xs={12}>
                        {this.state.startSave == 1 ? (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                            type="button"
                            className="btn form-save-btn "
                          >
                            {" "}
                            <CircularProgress className="SaveCircularProgressColor" />
                            {Strings["buy"]}{" "}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn form-save-btn mr-1 ml-1"
                          >
                            {" "}
                            {Strings["buy"]}{" "}
                          </button>
                        )}
                      </Col>
                      {this.state.formError && (
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <p className="invalid-feedback d-block mb-1">
                            {this.state.formError}
                          </p>
                        </Col>
                      )}
                    </Row>
                  </AvForm>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from "../../../../translate.js";
import { CircularProgress } from "@material-ui/core";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class ActiveCardOrCardClassToOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFrom: "",
      dateTo: "",
      timeFrom: "",
      timeTo: "",
      deactivateType: "",
      startSave: 0,
      startDelete: 0,
      loading: 1,
      daysList: [],
      data: [],
      emptyFromDate: "",
      emptyDeactivateType: "",
      emptyToDate: "",
      emptyFromTime: "",
      emptyToTime: "",
      emptyDay: "",
      id: "",
      classId: this.props.isCard == 1 ? "" : this.props.cardsCalssData.id,
      cardId: this.props.cardsCalssData.cardId,
      isCard: this.props.isCard,
      status: "",
      distId: this.props.distId ? this.props.distId : "",
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  componentDidMount() {
    this.getListDays();
    this.getTimeOfActiveCardOrCardClassToOrder();
  }

  handleValidSubmit(event) {
    if (this.validation()) {
      this.setState({ startSave: 1 });
      event.preventDefault();
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "activeCardOrCardClassToOrder",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          id: this.state.id,
          isCard: this.state.isCard,
          classId: this.state.classId,
          cardId: this.state.cardId,
          dateName: this.state.deactivateType == 2 ? this.state.day : "",
          withDate: this.state.deactivateType == 1 ? 1 : "",
          dateOfStart:
            this.state.deactivateType == 1 ? this.state.dateFrom : "",
          dateOfEnd: this.state.deactivateType == 1 ? this.state.dateTo : "",
          withTime: this.state.deactivateType == 2 ? 1 : "",
          timeOfStart:
            this.state.deactivateType == 2 ? this.state.timeFrom : "",
          timeOfEnd: this.state.deactivateType == 2 ? this.state.timeTo : "",
          distId: this.state.distId,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else if (data["error"] == 1) {
              this.setState({ submitError: data["errorMsg"], startSave: 0 });
            } else {
              this.setState(
                {
                  status: data["errorMsg"],
                  submitError: "",
                  id: "",
                  dateFrom: "",
                  dateTo: "",
                  timeFrom: "",
                  timeTo: "",
                  day: "",
                  startSave: 0,
                },
                function() {
                  this.getTimeOfActiveCardOrCardClassToOrder();
                }
              );
            }
          }
        });
    }
  }
  getListDays() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "list_days",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              daysList: data,
            });
          }
        }
      });
  }
  getTimeOfActiveCardOrCardClassToOrder() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getTimeOfActiveCardOrCardClassToOrder",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        isCard: this.state.isCard,
        classId: this.state.classId,
        cardId: this.state.cardId,
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              data: data,
              loading: 0,
            });
          }
        }
      });
  }
  handleClickDelete(id, all = "") {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "deleteTimeOfActiveCardOrCardClassToOrder",
        id: all == 0 ? id : 0,
        isCard: this.state.isCard,
        classId: all == 1 && this.state.isCard != 1 ? this.state.classId : 0,
        cardId: all == 1 && this.state.isCard == 1 ? this.state.cardId : 0,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.getTimeOfActiveCardOrCardClassToOrder();
          }
        }
      });
  }
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (name == "deactivateType") {
      this.setState({
        [name]: value,
        dateFrom: "",
        dateTo: "",
        timeFrom: "",
        timeTo: "",
        day: "",
        emptyDay: "",
        emptyDeactivateType: "",
        emptyFromDate: "",
        emptyToDate: "",
        emptyFromTime: "",
        emptyToTime: "",
        submitError: "",
        error: "",
        status: "",
      });
    } else if (name == "day") {
      this.setState({
        [name]: value,
        timeFrom: "",
        timeTo: "",
        emptyDay: "",
        emptyDeactivateType: "",
        emptyFromDate: "",
        emptyToDate: "",
        emptyFromTime: "",
        emptyToTime: "",
        submitError: "",
        error: "",
        status: "",
      });
    } else {
      this.setState({
        [name]: value,
        emptyDay: "",
        emptyDeactivateType: "",
        emptyFromDate: "",
        emptyToDate: "",
        emptyFromTime: "",
        emptyToTime: "",
        submitError: "",
        error: "",
        status: "",
      });
    }
  };
  validation() {
    var valid = true;
    if (this.state.deactivateType == "") {
      this.setState({ emptyDeactivateType: 1 });
      valid = false;
    } else if (
      this.state.dateFrom == "" &&
      this.state.dateTo == "" &&
      this.state.deactivateType == 1
    ) {
      this.setState({ emptyFromDate: 1, emptyToDate: 1 });
      valid = false;
    } else if (this.state.dateFrom == "" && this.state.deactivateType == 1) {
      this.setState({ emptyFromDate: 1 });
      valid = false;
    } else if (this.state.dateTo == "" && this.state.deactivateType == 1) {
      this.setState({ emptyToDate: 1 });
      valid = false;
    } else if (
      this.state.dateTo < this.state.dateFrom &&
      this.state.deactivateType == 1
    ) {
      this.setState({
        error: Strings["Please enter a valid period !"],
        emptyFromDate: 1,
        emptyToDate: 1,
      });
      valid = false;
    } else if (
      this.state.timeFrom == "" &&
      this.state.timeTo == "" &&
      this.state.deactivateType == 2
    ) {
      this.setState({ emptyFromTime: 1, emptyToTime: 1 });
      valid = false;
    } else if (this.state.day == "" && this.state.deactivateType == 2) {
      this.setState({ emptyDay: 1 });
      valid = false;
    } else if (this.state.timeFrom == "" && this.state.deactivateType == 2) {
      this.setState({ emptyFromTime: 1 });
      valid = false;
    } else if (this.state.timeTo == "" && this.state.deactivateType == 2) {
      this.setState({ emptyToTime: 1 });
      valid = false;
    } else if (
      this.state.timeTo < this.state.timeFrom &&
      this.state.deactivateType == 2
    ) {
      this.setState({
        error: Strings["Please enter a valid period !"],
        emptyFromTime: 1,
        emptyToTime: 1,
      });
      valid = false;
    }
    return valid;
  }
  setUpSelectOptions = (data) => {
    return (
      <option value={data.key} key={data.key}>
        {data.name}
      </option>
    );
  };

  deleteTime = (event, id, all = 0) => {
    event.preventDefault();
    var msg = "";
    if (this.state.isCard == 1)
      msg =
        all == 1
          ? Strings[
              "You want to delete all days from this card and every classes that  under its ?"
            ]
          : Strings[
              "You want to delete this day from this card and every classes that  under its ?"
            ];
    else
      msg =
        all == 1
          ? Strings["You want to delete all days ?"]
          : Strings["You want to delete this day ?"];
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{msg}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickDelete(id, all);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };
  editDeacativeItem = (event, data) => {
    event.preventDefault();
    this.setState({
      deactivateType: data["type"],
      id: data["id"],
      dateFrom: data["dateStartToDeactivationCard"],
      dateTo: data["dateEndToDeactivationCard"],
      day: data["dateName"],
      timeFrom: data["timeStartToDeactivationCard"],
      timeTo: data["timeEndToDeactivationCard"],
    });
  };
  cancelEditDeacativeItem = (event) => {
    event.preventDefault();
    this.setState({
      deactivateType: "",
      id: "",
      dateFrom: "",
      dateTo: "",
      day: "",
      timeFrom: "",
      timeTo: "",
    });
  };
  displayData() {
    var list = "";
    if (this.state.data.length > 0) {
      list = this.state.data.map((rowData, index) => {
        rowData["type"] = rowData["withTime"] == 1 ? 2 : 1;
        rowData["dateStartToDeactivationCardFromate"] =
          rowData["dateStartToDeactivationCard"] != "" &&
          rowData["dateStartToDeactivationCard"] != null
            ? rowData["dateStartToDeactivationCard"]
            : "-";
        rowData["dateEndToDeactivationCardFromate"] =
          rowData["dateEndToDeactivationCard"] != "" &&
          rowData["dateEndToDeactivationCard"] != null
            ? rowData["dateEndToDeactivationCard"]
            : "-";
        rowData["timeStartToDeactivationCardFromate"] =
          rowData["timeStartToDeactivationCard"] != "" &&
          rowData["timeStartToDeactivationCard"] != null
            ? rowData["timeStartToDeactivationCard"]
            : "-";
        rowData["timeEndToDeactivationCardFromate"] =
          rowData["timeEndToDeactivationCard"] != "" &&
          rowData["timeEndToDeactivationCard"] != null
            ? rowData["timeEndToDeactivationCard"]
            : "-";
        rowData["dayNameLang"] =
          rowData["dayNameLang"] != "" &&
          rowData["dayNameLang"] != null &&
          rowData["type"] == 2
            ? rowData["dayNameLang"]
            : "-";
        return (
          <Row key={index} className="table-body-row">
            <Col
              lg={4}
              md={4}
              sm={4}
              xs={12}
              className="main-color-1 font-w-400"
            >
              {" "}
              {rowData["type"] == 1
                ? Strings["Disable it from orders using history"]
                : Strings["Disable it from orders using days"]}
            </Col>
            <Col lg={2} md={2} sm={2} xs={12}>
              {" "}
              {rowData["type"] == 1
                ? rowData["dateStartToDeactivationCardFromate"]
                : rowData["timeStartToDeactivationCardFromate"]}
            </Col>
            <Col lg={2} md={2} sm={2} xs={12}>
              {" "}
              {rowData["type"] == 1
                ? rowData["dateEndToDeactivationCardFromate"]
                : rowData["timeEndToDeactivationCardFromate"]}
            </Col>
            <Col lg={2} md={2} sm={2} xs={12}>
              {" "}
              {rowData["type"] == 1 ? "-" : rowData["dayNameLang"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="text-right d-inline inv-category-box-action-btn"
            >
              <i
                className="flaticon2-writing mr-3"
                title={Strings["Edit"]}
                onClick={(event) => this.editDeacativeItem(event, rowData)}
              />
              <i
                className="flaticon2-cancel-music"
                title={Strings["Delete"]}
                onClick={(event) => this.deleteTime(event, rowData.id)}
              />
            </Col>
          </Row>
        );
      });
    }
    return list;
  }

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {Strings["Deactivation Days"]}(
                {localStorage.getItem("LANG") == 1
                  ? this.props.cardsCalssData.name
                  : this.props.cardsCalssData.name_en}
                )
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <Row>
            <Col lg={4} md={4} sm={4} xs={12} className="card-desc-form-view">
              <div>
                <AvForm
                  onValidSubmit={this.handleValidSubmit}
                  ref={(c) => (this.form = c)}
                >
                  <AvField
                    type="select"
                    name="deactivateType"
                    label={Strings["Deactivate Type"] + " *"}
                    value={this.state.deactivateType}
                    onChange={this.handleInputChange}
                    className={
                      this.state.emptyDeactivateType == 1
                        ? "filter-empty-email p-1 pl-3"
                        : "p-1 pl-3"
                    }
                    errorMessage={Strings["This field is required"]}
                  >
                    <option
                      style={{
                        display: "block",
                      }}
                      value=""
                      selected="selected"
                    >
                      {Strings["Select type disable"]}
                    </option>
                    <option value="1">
                      {Strings["Disable it from orders using history"]}
                    </option>
                    <option value="2">
                      {Strings["Disable it from orders using days"]}
                    </option>
                  </AvField>
                  {this.state.deactivateType == 1 && (
                    <AvField
                      name="dateFrom"
                      type="date"
                      label={Strings["From Date"] + " *"}
                      value={this.state.dateFrom}
                      onChange={this.handleInputChange}
                      className={
                        this.state.emptyFromDate == 1 && "filter-empty-email"
                      }
                    />
                  )}
                  {this.state.deactivateType == 1 && (
                    <AvField
                      name="dateTo"
                      type="date"
                      label={Strings["To Date"] + " *"}
                      value={this.state.dateTo}
                      onChange={this.handleInputChange}
                      className={
                        this.state.emptyToDate == 1 && "filter-empty-email"
                      }
                    />
                  )}
                  {this.state.deactivateType == 2 && (
                    <AvField
                      type="select"
                      name="day"
                      label={Strings["Day"] + " *"}
                      value={this.state.day}
                      onChange={this.handleInputChange}
                      className={
                        this.state.emptyDay == 1
                          ? "filter-empty-email p-1 pl-3"
                          : "p-1 pl-3"
                      }
                      errorMessage={Strings["This field is required"]}
                    >
                      <option
                        style={{
                          display: "none",
                        }}
                        value=""
                        selected="selected"
                      >
                        {Strings["Select Day"]}
                      </option>
                      {this.state.daysList.map((data) =>
                        this.setUpSelectOptions(data)
                      )}
                    </AvField>
                  )}
                  {this.state.deactivateType == 2 && (
                    <AvField
                      name="timeFrom"
                      type="time"
                      label={Strings["Start Time"] + " *"}
                      value={this.state.timeFrom}
                      onChange={this.handleInputChange}
                      className={
                        this.state.emptyFromTime == 1 && "filter-empty-email"
                      }
                    />
                  )}
                  {this.state.deactivateType == 2 && (
                    <AvField
                      name="timeTo"
                      type="time"
                      label={Strings["End Time"] + " *"}
                      value={this.state.timeTo}
                      onChange={this.handleInputChange}
                      className={
                        this.state.emptyToTime == 1 && "filter-empty-email"
                      }
                    />
                  )}
                  <div className="text-right mt-4">
                    {this.state.startSave == 1 ? (
                      <button type="button" className="btn form-save-btn ">
                        {" "}
                        <CircularProgress className="SaveCircularProgressColor" />
                        {Strings["Save"]}{" "}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn form-save-btn mr-1 ml-1"
                      >
                        {" "}
                        {Strings["Save"]}{" "}
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn form-cancel-btn m-l-10"
                      onClick={(event) => this.cancelEditDeacativeItem(event)}
                    >
                      {Strings["Cancel"]}
                    </button>
                  </div>

                  {this.state.submitError && (
                    <div className="error-text-black">
                      <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                      {this.state.submitError}
                    </div>
                  )}

                  {this.state.status && (
                    <div className="error-text-black">
                      <i className={"flaticon2-check-mark success-icon mr-1"} />{" "}
                      {this.state.status}
                    </div>
                  )}
                  {this.state.error && (
                    <div className="error-text-black">
                      <i className={"flaticon2-exclamation error-icon mr-1"} />{" "}
                      {this.state.error}
                    </div>
                  )}
                </AvForm>
              </div>
            </Col>
            <Col lg={8} md={8} sm={8} xs={12}>
              {this.state.loading == 1 && (
                <Row>
                  <Col
                    md={12}
                    style={{ marginTop: "15%" }}
                    className="text-center"
                  >
                    <div className="card-body">
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                    </div>
                  </Col>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length <= 0 && (
                <Row className="text-center" style={{ marginTop: "15%" }}>
                  <div>
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl(
                        "/media/ezpay/EZPAY_LOGO_GRAY" +
                          window.MAIN_IMAGE +
                          ".png"
                      )}
                      className="img-fluid no-data-img"
                    />
                    <div className="no-data-text">
                      {Strings["There is no data to display"]}
                    </div>
                  </div>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <div className="inv-cards-view">
                  <Row>
                    <Col
                      md={7}
                      className="mb-4 pr-0 pl-0 main-color-1 font-w-400 mt-2 f-15"
                    >
                      {Strings["Deactivation Days"]}
                    </Col>
                    <Col md={5} className="text-right mb-4 pr-0 pl-0">
                      {this.state.startDelete == 1 ? (
                        <button className="btn page-action-btn ml-1">
                          <CircularProgress className="SaveCircularProgressColor" />
                          {Strings["Delete All Days"]}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn  page-action-btn m-l-10"
                          onClick={(event) => this.deleteTime(event, 0, 1)}
                        >
                          {Strings["Delete All Days"]}
                        </button>
                      )}
                    </Col>
                  </Row>
                  <div>
                    <Row className="table-header-row">
                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={12}
                        className="main-color-1 font-w-400"
                      >
                        {Strings["Deactivate Type"]}
                      </Col>
                      <Col lg={2} md={2} sm={2} xs={12}>
                        {Strings["From"]}
                      </Col>
                      <Col lg={2} md={2} sm={2} xs={12}>
                        {Strings["To"]}
                      </Col>
                      <Col lg={2} md={2} sm={2} xs={12}>
                        {Strings["Day"]}
                      </Col>
                      <Col lg={2} md={2} sm={2} xs={12} className="text-right">
                        {Strings["Action"]}
                      </Col>
                    </Row>
                    {this.displayData()}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default ActiveCardOrCardClassToOrder;

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toAbsoluteUrl } from "../../../_metronic";
import { translations } from '../../../translate.js';

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;



class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 1
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    componentDidMount() {
        this.getUserInfo()
    }

    getUserInfo() {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getUserInfo',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            this.setState({ loading: 1 });
            if (typeof data !== 'undefined') {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({
                        fname: data['fname'],
                        joinDate: data['joinDate'],
                        currencyTitle: data['currencyTitle'],
                        countryTitle: data['countryTitle'],
                        formType: data['formType'],
                        address: data['address'],
                        city: data['city'],
                        district: data['district'],
                        zipcode: data['zipcode'],
                        commercial: data['commercial'],
                        mobile: data['mobile'],
                        mobilePhone: data['mobilePhone'],
                        userEmail: data['userEmail'],
                        timeZone: data['timeZone'],
                        distLogoUrl: data['distLogo'],
                        accountDistNoti: data['accountDistNoti'],
                        loading: 0,
                    })
                }
            }
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value,
            submitError: ''
        })
    }

    handleValidSubmit(event) {
        event.preventDefault();
        this.setState({ submitError: '', status: '' })
        if (this.state.userPassword != '' && (this.state.userPassword != this.state.confirmPassword)) {
            this.setState({ submitError: Strings['Password Not Match'] })
        } else {
            fetch(window.API_PERM_USER, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'editUserInfo',
                    'lang': localStorage.getItem('LANG'),
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                    'address': this.state.address,
                    'city': this.state.city,
                    'district': this.state.district,
                    'zipcode': this.state.zipcode,
                    'commercial': this.state.commercial,
                    'password': this.state.userPassword,
                    'accountDistNoti': this.state.accountDistNoti
                }),
                headers: {
                    'Accept': 'application/json',
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (data !== "Nothing found") {
                    if (data['logout'] == 1) {
                        localStorage.setItem('USER_ID', '');
                        localStorage.setItem('USER_TOKEN', '');
                        window.location.href = "/logout";
                    } else if (data['error'] == 1) {
                        this.setState({ submitError: data['errorMsg'] })
                    } else {
                        this.setState({ status: data['errorMsg'] })
                    }
                }
            })
        }
    }

    loadingHtml() {
        return (
            <Row>
                <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                    <div className='card-body'>
                        <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                        <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                        <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                        <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                        <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                        <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                    </div>
                </Col>
            </Row>
        )
    }

    render() {
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col md={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'> {Strings['Account Information']}</div>
                        </Col>
                        <Col md={2} className='text-right'>
                            <HighlightOffIcon onClick={() => this.props.openSidebarModal(false, "account")} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>

                <div className='sidebar-body-view'>

                    {this.state.loading == 1 && this.loadingHtml()}

                    {this.state.loading == 0 &&
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            <AvField name="fname" label={Strings['Company Name']} value={this.state.fname} readOnly />
                            <AvField name="joinDate" label={Strings['Subscription Date']} value={this.state.joinDate} readOnly />
                            <AvField name="currencyTitle" label={Strings['Sale Currency']} value={this.state.currencyTitle} readOnly />
                            <AvField name="countryTitle" label={Strings['Country']} value={this.state.countryTitle} readOnly />
                            <AvField type="select" label={Strings['Invoice Type']} name="formType" value={this.state.formType} className="p-1 pl-3" readOnly disabled>
                                <option value='1'>{Strings['Type Num']} 1</option>
                                <option value='2'>{Strings['Type Num']} 2</option>
                                <option value='3'>{Strings['Type Num']} 3</option>
                                <option value='4'>{Strings['Type Num']} 4</option>
                                <option value='5'>{Strings['Type Num']} 5</option>
                                <option value='6'>{Strings['Type Num']} 6</option>
                                <option value='7'>{Strings['Type Num']} 7</option>
                            </AvField>
                            <AvField name="mobile" label={Strings['Phone']} value={this.state.mobile} readOnly />
                            <AvField name="mobilePhone" label={Strings['Mobile']} value={this.state.mobilePhone} readOnly />
                            <AvField name="userEmail" label={Strings['Email']} value={this.state.userEmail} readOnly />
                            <AvField name="timeZone" label={Strings['Time Zone']} value={this.state.timeZone} readOnly />
                            {localStorage.getItem('PERM_TYPE') == 3 &&
                                <AvField name="accountDistNoti" type='email' label={Strings['Notification Email [ add classes ]']} placeholder={Strings['Enter Notification Email [ add classes ]']} value={this.state.accountDistNoti} onChange={this.handleInputChange} />
                            }
                            <AvField name="address" label={Strings['Address']} placeholder={Strings['Enter Address']} value={this.state.address} onChange={this.handleInputChange} />
                            <AvField name="city" label={Strings['City']} placeholder={Strings['Enter City']} value={this.state.city} onChange={this.handleInputChange} />
                            <AvField name="district" label={Strings['District']} placeholder={Strings['Enter District']} value={this.state.district} onChange={this.handleInputChange} />
                            <AvField name="zipcode" label={Strings['Zip Code']} placeholder={Strings['Enter Zip Code']} value={this.state.zipcode} onChange={this.handleInputChange} />
                            <AvField name="commercial" label={Strings['Commercial Registration No.']} placeholder={Strings['Enter Commercial Registration No.']} value={this.state.commercial} onChange={this.handleInputChange} />

                            <AvField name="userPassword" type='password' label={Strings['Password']} placeholder={Strings['Enter Password']} value={this.state.userPassword} onChange={this.handleInputChange} />
                            <AvField name="confirmPassword" type='password' label={Strings['Confirm Password']} placeholder={Strings['Enter Password']} value={this.state.confirmPassword} onChange={this.handleInputChange} />
                            {this.state.distLogoUrl != null && this.state.distLogoUrl != undefined && this.state.distLogoUrl != '' &&
                                <div className='mt-2 mb-2'>
                                    <label className='font-w-400'>{Strings['Logo']}</label>
                                    <div className='text-center mt-4'>
                                        <a href={this.state.distLogoUrl} download target='_blank'>
                                            <img src={this.state.distLogoUrl} className='img-fluid inv-category-img' />
                                        </a>
                                    </div>
                                </div>
                            }

                            <div className='text-right mt-4'>
                                <button type="submit" className="btn form-save-btn mr-1 ml-1"> {Strings['Save']} </button>
                                <button type="button" className="btn form-cancel-btn m-l-10" onClick={() => this.props.openSidebarModal(false, "account")}>{Strings['Cancel']}</button>
                            </div>

                            {this.state.submitError &&
                                <div className='error-text-black'><i className='flaticon2-exclamation error-icon mr-1' /> {this.state.submitError}</div>
                            }

                            {this.state.status &&
                                <div className='error-text-black'><i className='flaticon2-check-mark success-icon mr-1' /> {this.state.status}</div>
                            }
                        </AvForm>
                    }
                </div>
            </div>
        )
    }
}
export default MyAccount;
import { TYPE } from "../action/balanceActions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
const bal = localStorage.getItem('USER_BALANCE')
const initState = {
    userBalance: bal
};
export const balanceReducer = persistReducer(
    { storage, key: "Balance", whitelist: ["userBalance"] },
    (State = initState, action) => {
        switch (action.type) {
            case TYPE.UPDATEBALANCE: {
                return {
                    ...State,
                    userBalance: action?.payload,
                };
            }
            default:
                return State;
        }
    }
);
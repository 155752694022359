import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import { confirmAlert } from 'react-confirm-alert';
import { CircularProgress } from "@material-ui/core";

import 'react-confirm-alert/src/react-confirm-alert.css';
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from '../../../../translate.js';

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;



class PullCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startSave: 0,
            pullReason: ''
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }


    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            submitError: ''
        })
    }

    handleImgChange = (event) => {
        var fileReader = new FileReader();
        const scope = this

        // this.setState({ displayErrorHeaderMsg: false });
        // this.setState({ progressUploadHeader: 10 });
        console.log('event.target.files[0].type');
        console.log(event.target.files[0].type);
        console.log(event.target.files[0]['name']);

        //   if Excel version ==2003 ->event.target.files[0].type.includes("application/vnd.ms-excel") //.xls
        //   but 
        //   if Excel version >=2007 ->event.target.files[0].type.includes
        // ("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")// /.xlsx


        // if (event.target.files[0].type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        //     || event.target.files[0].type.includes("application/vnd.ms-excel")) {
        if (event.target.files[0].type.includes("text/csv")) {
            // this.setState({ displayErrorHeaderMsg: false });
            fileReader.readAsBinaryString(event.target.files[0]);
            fileReader.onprogress = function (data) {

                if (data.lengthComputable) {
                    var progress = parseInt(((data.loaded / data.total) * 100), 10);
                    scope.setState({ progressUploadHeader: progress });
                }
            }
        } else {
            this.setState({ displayErrorHeaderMsg: true });
        }


        this.setState({
            image: event.target.files[0]['name']
        });
    }

    remove = (event) => {
        event.target.value = null;
    }
    handelGetSampleExcel() {
        window.location = window.API_PERM_USER + "?type=" + "getPullFileExample" + "&lang=" + localStorage.getItem('LANG') + "&userId=" + localStorage.getItem('USER_ID')
            + "&token=" + localStorage.getItem('USER_TOKEN');

    }
    //currenly will not use 
    removeFile(fileName) {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'remove_file',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'fileName': fileName
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                }
            }
        })
    }

    handleValidSubmit(event) {
        this.setState({ startSave: 1 })

        event.preventDefault();

        var data = new FormData();
        var pullExcelfile = document.querySelector('input[name=pullExcelfile]').files[0];
        // console.log(pullExcelfile);
        data.append("pullExcelfile", pullExcelfile);
        data.append("type", 'pullExcelCards');
        data.append("test1", '1');
        data.append("userId", localStorage.getItem('USER_ID'));
        data.append("token", localStorage.getItem('USER_TOKEN'));
        data.append("lang", localStorage.getItem('LANG'));
        data.append("classId", this.props.cardsCalssData.id);
        data.append("pullReason", this.state.pullReason);
        fetch(window.API_PERM_USER, {
            // mode: 'no-cors',
            method: "POST",
            body: data,
            headers: {
                Accept: 'application/json',
            },
        }).then(function (response) {
            // console.log('===============================');

            // console.log(response);
            if (!response.ok) {

            }
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 0) {
                    this.setState({
                        startSave: 0
                    }, function () {
                        window.location = data['fileUrl'];
                        this.props.recallGetData(1)
                        this.props.handleCloseSideBar(1)
                        // setTimeout(() => {
                        //     // this.removeFile(data['fileName'])

                        // }, 10000);

                    })

                } else {
                    this.setState({
                        submitError: data['errorMsg'],
                        startSave: 0
                    })

                }

            }
        });

    }


    render() {
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col lg={10} md={10} sm={6} xs={12}>
                            <div className='p-0 main-color-1  sidebar-header-title'> 
                            {Strings['Pull Cards']} 
                            ({localStorage.getItem('LANG') == 1 ? this.props.cardsCalssData.name : this.props.cardsCalssData.name_en})
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={4} xs={12} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>

                <div className='sidebar-body-view'>

                    <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>

                        <AvField name="drowName" label={Strings['Drawer']} value={localStorage.getItem('USER_FULL_NAME')} disabled />
                        <AvField name="pullReason" type='textarea' label={Strings['Reason for withdrawing the cards'] + ' *'} placeholder={Strings['Enter reason for withdrawing the cards']} onChange={this.handleInputChange} required errorMessage={Strings['This field is required']} />

                        <div className='mt-2 mb-2'>
                            <label>{Strings['Upload CSV File']}</label>
                            <span className={'fileInput-span col-md-12'}>
                                <PublishIcon /> <span className='pointer-cursor pt-3'>{Strings['Upload']}</span>
                                <input
                                    onClick={(event) => this.remove(event)}
                                    // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel 
                                    type="file" accept=".csv" className='fileInput-width fileInput-opacity' onChange={(e) => this.handleImgChange(e)} name='pullExcelfile'></input>
                            </span>
                            {(this.state.progressUploadHeader != undefined && this.state.progressUploadHeader > 0) &&
                                <Progress striped value={this.state.progressUploadHeader} className='mb-1 mt-1 progress-bg-color'>{this.state.progressUploadHeader} %</Progress>
                            }
                            {(this.state.displayErrorHeaderMsg == true) &&
                                <div className='invalid-feedback d-block mb-1'>{Strings['Upload CSV File only']}</div>
                            }
                        </div>

                        <Row>
                            <Col lg={2} md={2} sm={2} xs={12}>{Strings['Example']} : </Col>
                            <Col lg={10} md={10} sm={10} xs={12}><div className='link-color-sample' onClick={(event) => this.handelGetSampleExcel(event)}>{Strings['Download Example']}</div></Col>
                        </Row>

                        <div className='text-right mt-4'>
                            {this.state.startSave == 0 ?
                                <button type="submit" className="btn form-save-btn mr-1 ml-1"> {Strings['Save']} </button>
                                :
                                <button type="button" className="btn form-save-btn"><CircularProgress className="SaveCircularProgressColor" /> {Strings['Save']}</button>
                            }

                            <button type="button" className="btn form-cancel-btn m-l-10" onClick={this.props.handleCloseSideBar}>{Strings['Cancel']}</button>
                        </div>

                        {this.state.submitError &&
                            <div className='error-text-black'><i className='flaticon2-exclamation error-icon mr-1' /> {this.state.submitError}</div>
                        }
                    </AvForm>


                </div>
            </div>
        )
    }
}
export default PullCard;
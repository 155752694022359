import React, { useContext, useEffect } from "react";
import { translations } from "../../../translate.js";
import { MyContext } from "../../../app/Context/MyContext.jsx";
import OrderDetailsPOS from "./OrderDetailsPOS.jsx";
import { useParams } from "react-router-dom";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
export default function POSOrderInfoWrapper() {
  const { id } = useParams();
  const { getBalance } = useContext(MyContext);
  useEffect(() => {
    getBalance();
  }, []);
  return <OrderDetailsPOS id={id} />;
}

import React, { Component } from "react";
import {
  UncontrolledDropdown,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { toAbsoluteUrl } from "../../../_metronic";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Switch from "@material-ui/core/Switch";
import { Drawer } from "@material-ui/core";
import CardForm from "./forms/card";
import CardExplanations from "./forms/card_explanations";
import CardSuppliers from "./forms/cardSuppliers";
import AddBalance from "./forms/addBalance";
import AddNumnyAndEgifterBalance from "./forms/addNumnyAndEgifterBalance";
import AddSellingPrice from "./forms/addSellingPrice";
import EditDistCardPrice from "./forms/editDistCardPrice";
import EditPosPrice from "./forms/editPosPrice";
import AccountStatement from "./forms/accountStatement";
import ViewCards from "./forms/viewCards";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import AddCoupons from "./forms/addCoupon";
import AddSupplierApi from "./forms/addSupplierApi";
import SortPriority from "./forms/sortPriority";
import ActiveCardOrCardClassToOrder from "./forms/activeCardOrCardClassToOrder";

import { translations } from "../../../translate.js";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openHeader: "",
      mainCatName: "",
      parentCardInfo: [],
      loading: 1,
      data: [],
      cardsCalssData: [],
      openCardExplanations: false,
      openCardAction: false,
      actionType: "",
      mainType: "",
      nameCard: localStorage.getItem("LANG") == 1 ? "name" : "name_en",
      taxCondition: "",
      openCardStatement: false,
      currencyTitle: "",
      nameAddBalanceApi: "",
      link: window.location.origin + "/posEmp",
      distClassList: [],
      catData: [],
      openCouponsleftModal: false,
      distId: this.props.distId ? this.props.distId : "",
      permType: this.props.permType ? this.props.permType : "",
      distData: this.props.distData ? this.props.distData : "",
    };
  }

  componentDidMount() {
    if (
      this.props.mainDist == 1 ||
      (this.props.mainDist == 2 &&
        localStorage.getItem("ACC_EMP") == 0 &&
        localStorage.getItem("OPERA_EMP") == 0)
    ) {
      this.getDistClasses();
    }

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  getDistClasses() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistClasses",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId,
        getType: "all",
        list: localStorage.getItem("USER_LEVEL") == 1 ? 1 : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              distClassList: data["data"],
            });
          }
        }
      });
  }
  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
        });
      }
    }
  };
  getCardList(
    cardData,
    mainCatName,
    mainType,
    catData,
    seach = 0,
    searchData = []
  ) {
    if (
      localStorage.getItem("PERM_TYPE") == 3 ||
      this.state.permType == 3 ||
      this.state.permType == 2 ||
      localStorage.getItem("PERM_TYPE") == 2 ||
      this.props.mainDist == 1 ||
      this.props.mainDist == 2
    ) {
      this.getCardList_type_2(cardData, mainCatName);
    } else {
      this.getCardList_type_1(
        cardData,
        mainCatName,
        catData,
        seach,
        searchData
      );
    }
  }

  getCardList_type_2(cardData, mainCatName) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistCardsPrice",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        cardId: cardData["cardId"],
        mainDist:
          this.props.mainDist != 1 && this.props.mainDist != 2
            ? ""
            : this.props.mainDist,
        posId: this.props.mainDist == 2 ? this.props.posId : "",
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        this.setState({ loading: 1 });
        if (typeof data !== "undefined") {
          // console.log('===============================');
          // console.log(data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              mainCatName: mainCatName,
              parentCardInfo: cardData,
              data: data?.["cards"],
              taxCondition:
                localStorage.getItem("PERM_TYPE") == 5 ||
                this.state.permType == 5 ||
                localStorage.getItem("ACC_EMP") == 1 ||
                localStorage.getItem("OPERA_EMP") == 1
                  ? this.props.mainDist == 2 &&
                    this.props.isDistTax == 1 &&
                    localStorage.getItem("IS_TAXDIST") == 1 &&
                    cardData["cardIsTax"] == 1
                  : 0,
              currencyTitle: data?.["currencyTitle"],
              loading: 0,
            });
          }
        }
      });
  }

  getCardList_type_1(
    cardData,
    mainCatName,
    catData,
    seach = 0,
    searchData = []
  ) {
    if (seach == 1) {
      // console.log("type 1 ");
      // console.log(searchData["cardClass"]);
      this.setState({
        mainCatName: mainCatName,
        parentCardInfo: cardData,
        catData: catData,
        data: searchData["cardClass"],
        loading: 0,
      });
    } else
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getCardClass",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          cardId: cardData["cardId"],
          distId: this.state.distId,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          this.setState({ loading: 1 });
          if (typeof data !== "undefined") {
            // console.log('===============================');
            // console.log(data);
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState({
                mainCatName: mainCatName,
                parentCardInfo: cardData,
                catData: catData,
                data: data,
                loading: 0,
              });
            }
          }
        });
  }

  displayCardsMain() {
    this.props.displayCardsMain(
      this.state.parentCardInfo["cardCategory"],
      this.state.mainCatName
    );
  }
  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };

  handleChange_2 = (event, id, cardId) => {
    var name = "hide-" + id;
    this.setState({ [name]: event.target.checked });

    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "updateStatusDistCard",
        cardId: cardId,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        status: event.target.checked == true ? 0 : 1,
        cardClassId: id,
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('=========================data');
          // console.log(data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.getCardList(
              this.state.parentCardInfo,
              this.state.mainCatName,
              "",
              this.state.catData
            );
          }
        }
      });
  };

  handleChange = (event, id) => {
    var name = "activeStatus-" + id;
    this.setState({ [name]: event.target.checked });

    var status =
      localStorage.getItem("USER_LEVEL") == 1 ||
      localStorage.getItem("INV_MGR") == 1
        ? event.target.checked == true
          ? 1
          : 2
        : event.target.checked == true
        ? 1
        : 0;
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "updateCardClassStatus",
        cardClassId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        status: status,
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ openHeader: "" }, function() {
              this.getCardList(
                this.state.parentCardInfo,
                this.state.mainCatName,
                "",
                this.state.catData
              );
            });
          }
        }
      });
  };

  displayData_2() {
    var list = "";
    if (this.state.data?.length > 0) {
      list = this.state.data.map((rowData, index) => {
        return (
          <Row key={index} className="table-body-row">
            <Col
              lg={
                localStorage.getItem("PERM_TYPE") == 3 ||
                this.state.permType == 3
                  ? 5
                  : 3
              }
              md={
                localStorage.getItem("PERM_TYPE") == 3 ||
                this.state.permType == 3
                  ? 5
                  : 3
              }
              sm={
                localStorage.getItem("PERM_TYPE") == 3 ||
                this.state.permType == 3
                  ? 5
                  : 3
              }
              xs={12}
              className="main-color-1 font-w-400 "
            >
              {rowData["cardClassName"]}
            </Col>
            <Col lg={2} md={2} sm={2} xs={12} className="">
              {localStorage.getItem("PERM_TYPE") == 3 ||
              this.state.permType == 3
                ? rowData["priceFormat"]
                : rowData["cardClassValue"]}
            </Col>
            {(localStorage.getItem("PERM_TYPE") != 3 ||
              (this.state.permType != 3&&this.state.permType !='')) && (
              <Col lg={2} md={2} sm={2} xs={12} className="">
                {rowData["buyPriceFormat"] != "" &&
                rowData["buyPriceFormat"] != null &&
                rowData["buyPriceFormat"] != undefined
                  ? rowData["buyPriceFormat"]
                  : "-"}{" "}
              </Col>
            )}
            {(localStorage.getItem("PERM_TYPE") != 3 ||
             (this.state.permType != 3&&this.state.permType !='')) && (
              <Col lg={2} md={2} sm={2} xs={12} className="">
                {rowData["priceFormat"] != "" &&
                rowData["priceFormat"] != null &&
                rowData["priceFormat"] != undefined
                  ? rowData["priceFormat"]
                  : "-"}{" "}
              </Col>
            )}
            {(localStorage.getItem("PERM_TYPE") != 3 ||
             (this.state.permType != 3&&this.state.permType !='')) && (
              <Col lg={2} md={2} sm={2} xs={12}>
                <Switch
                  checked={rowData["hide"] == 0 ? true : false}
                  onChange={(event) =>
                    this.handleChange_2(
                      event,
                      rowData["classId"],
                      rowData["cardId"]
                    )
                  }
                  name={"hide-" + rowData["id"]}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </Col>
            )}
            {(localStorage.getItem("PERM_TYPE") != 3 ||
              (this.state.permType != 3&&this.state.permType !='')) && (
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={12}
                className="text-center action-icon-view actionDropdownList"
              >
                <Dropdown
                  isOpen={this.state.openHeader == rowData["id"] ? true : false}
                  className="font-size-13 actionDropdownList"
                >
                  <DropdownToggle
                    tag="a"
                    className="nav-link pt-0 actionDropdownList"
                    caret
                  >
                    <MoreVertIcon
                      className="menu-dots-vertical actionDropdownList"
                      onClick={(event) =>
                        this.openHeaderMenu(event, rowData["id"])
                      }
                    />
                  </DropdownToggle>
                  <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList">
                    <div
                      className="actionDropdownList"
                      style={{ maxHeight: "250px", overflowY: "scroll" }}
                    >
                      <DropdownItem
                        className="actionDropdownList"
                        onClick={(event) =>
                          this.openCardAction(
                            event,
                            "editSellingPrice",
                            rowData
                          )
                        }
                      >
                        {Strings["Edit Selling Price"]}
                      </DropdownItem>
                      <DropdownItem
                        className="actionDropdownList"
                        onClick={(event) =>
                          this.openCouponsCardsSidebarModal(
                            event,
                            rowData,
                            "addCoupons"
                          )
                        }
                      >
                        {Strings["Coupons"]}
                      </DropdownItem>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            )}
          </Row>
        );
      });
    }
    return list;
  }

  displayData() {
    var list = "";
    var adminApiBalanceCondition = "";
    var nameAddBalanceApi = "";
    var addSupplierApiCondition = "";
    if (this.state.data.length > 0) {
      list = this.state.data.map((rowData, index) => {
        // console.log(this.state.catData);
        adminApiBalanceCondition =
          this.state.parentCardInfo["getCardType"] != 5 &&
          rowData["typeInvReact"] != undefined &&
          rowData["activeStatus"] != undefined &&
          rowData["typeInvReact"] != 0 &&
          rowData["typeInvReact"] != "" && // check card linked with supplier api in order process
          rowData["supplierNameInvReact"] != undefined &&
          rowData["supplierNameInvReact"] != "" &&
          rowData["supplierNameInvReact"] != null && //check supplier api linked with class in orders and linked with inventory
          this.state.catData != undefined &&
          this.state.catData.isDirectCharge != undefined &&
          this.state.catData.isApi != undefined &&
          (this.state.catData.isDirectCharge == 0 ||
            this.state.catData.isDirectCharge == "" ||
            this.state.catData.isApi == 1) &&
          (localStorage.getItem("USER_LEVEL") == 1 ||
            localStorage.getItem("INV_MGR") == 1) &&
          rowData["activeStatus"] == 1; // check if category type in not direct charage beacuse does not have this feature except if add feature api
        nameAddBalanceApi = "";
        if (adminApiBalanceCondition) {
          nameAddBalanceApi =
            localStorage.getItem("LANG") == 1
              ? Strings["Add"] +
                " " +
                Strings["Balance Title"] +
                " " +
                rowData["supplierNameInvReact"]
              : Strings["Add"] +
                " " +
                rowData["supplierNameInvReact"] +
                " " +
                Strings["Balance"];
        }
        addSupplierApiCondition =
          this.state.parentCardInfo["getCardType"] != 5 &&
          this.state.catData != undefined &&
          this.state.catData.isDirectCharge != undefined &&
          this.state.catData.isApi != undefined &&
          this.state.catData.isDing != undefined &&
          this.state.catData.isFreePriceForDist != undefined &&
          (this.state.catData.isDing == 0 || this.state.catData.isDing == "") &&
          (this.state.catData.isFreePriceForDist == 0 ||
            this.state.catData.isFreePriceForDist == "") &&
          (this.state.catData.isDirectCharge == "" ||
            this.state.catData.isDirectCharge == 0 ||
            //   for test baraa 4-12
            this.state.catData.isDirectCharge == 2 ||
            this.state.catData.isDirectCharge == 3 ||
            this.state.catData.isDirectCharge == 4 ||
            this.state.catData.isDirectCharge == 7 ||
            this.state.catData.isDirectCharge == 9 ||
            this.state.catData.isDirectCharge == 6 ||
            this.state.catData.isApi == 1) &&
          (localStorage.getItem("USER_LEVEL") == 1 ||
            localStorage.getItem("INV_MGR") == 1);
        return (
          <Row key={index} className="table-body-row">
            <Col
              lg={
                this.state.parentCardInfo["getCardType"] == 3 ||
                this.state.parentCardInfo["getCardType"] == 4 ||
                localStorage.getItem("PERM_TYPE") == 3
                  ? 5
                  : localStorage.getItem("USER_LEVEL") == 1 ||
                    localStorage.getItem("INV_MGR") == 1
                  ? 2
                  : 3
              }
              md={
                this.state.parentCardInfo["getCardType"] == 3 ||
                this.state.parentCardInfo["getCardType"] == 4 ||
                localStorage.getItem("PERM_TYPE") == 3
                  ? 5
                  : localStorage.getItem("USER_LEVEL") == 1 ||
                    localStorage.getItem("INV_MGR") == 1
                  ? 2
                  : 3
              }
              sm={
                this.state.parentCardInfo["getCardType"] == 3 ||
                this.state.parentCardInfo["getCardType"] == 4 ||
                localStorage.getItem("PERM_TYPE") == 3
                  ? 5
                  : localStorage.getItem("USER_LEVEL") == 1 ||
                    localStorage.getItem("INV_MGR") == 1
                  ? 2
                  : 3
              }
              xs={12}
              className="main-color-1 font-w-400"
            >
              {localStorage.getItem("LANG") == 1
                ? rowData["name"]
                : rowData["name_en"]}
            </Col>
            <Col
              lg={
                this.state.parentCardInfo["getCardType"] == 3 ||
                this.state.parentCardInfo["getCardType"] == 4
                  ? 5
                  : 2
              }
              md={
                this.state.parentCardInfo["getCardType"] == 3 ||
                this.state.parentCardInfo["getCardType"] == 4
                  ? 5
                  : 2
              }
              sm={
                this.state.parentCardInfo["getCardType"] == 3 ||
                this.state.parentCardInfo["getCardType"] == 4
                  ? 5
                  : 2
              }
              xs={12}
            >
              {rowData["value"]}
            </Col>
            {this.state.parentCardInfo["getCardType"] != 3 &&
              this.state.parentCardInfo["getCardType"] != 4 &&
              (localStorage.getItem("PERM_TYPE") != 3 ||
              (this.state.permType != 3&&this.state.permType !='')) && (
                <Col lg={2} md={2} sm={2} xs={12}>
                  {rowData["unUsedCard"]}
                </Col>
              )}
            {this.state.parentCardInfo["getCardType"] != 3 &&
              this.state.parentCardInfo["getCardType"] != 4 &&
              (localStorage.getItem("PERM_TYPE") != 3 ||
              (this.state.permType != 3&&this.state.permType !='')) && (
                <Col lg={2} md={2} sm={2} xs={12}>
                  {rowData["cardLimit"]}
                </Col>
              )}
            {this.state.parentCardInfo["getCardType"] != 3 &&
              this.state.parentCardInfo["getCardType"] != 4 &&
              (localStorage.getItem("PERM_TYPE") != 3 ||
              (this.state.permType != 3&&this.state.permType !='')) &&
              (localStorage.getItem("USER_LEVEL") == 1 ||
                localStorage.getItem("INV_MGR") == 1) && (
                <Col lg={2} md={2} sm={2} xs={12}>
                  {rowData["supplierName"] != undefined &&
                  rowData["supplierName"] != "" &&
                  rowData["supplierName"] != null
                    ? rowData["supplierName"]
                    : "-"}
                </Col>
              )}
            {this.state.parentCardInfo["getCardType"] != 3 &&
              this.state.parentCardInfo["getCardType"] != 4 &&
              (localStorage.getItem("PERM_TYPE") != 3 ||
              (this.state.permType != 3&&this.state.permType !='')) && (
                <Col
                  lg={
                    localStorage.getItem("USER_LEVEL") == 1 ||
                    localStorage.getItem("INV_MGR") == 1
                      ? 1
                      : 2
                  }
                  md={
                    localStorage.getItem("USER_LEVEL") == 1 ||
                    localStorage.getItem("INV_MGR") == 1
                      ? 1
                      : 2
                  }
                  sm={
                    localStorage.getItem("USER_LEVEL") == 1 ||
                    localStorage.getItem("INV_MGR") == 1
                      ? 1
                      : 2
                  }
                  xs={12}
                >
                  <Switch
                    checked={rowData["activeStatus"] == 1 ? true : false}
                    onChange={(event) =>
                      this.handleChange(event, rowData["id"])
                    }
                    name={"activeStatus-" + rowData["id"]}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Col>
              )}
            {(localStorage.getItem("PERM_TYPE") != 3 ||
              (this.state.permType != 3&&this.state.permType !='')) && (
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={12}
                className="text-center action-icon-view actionDropdownList"
              >
                <Dropdown
                  isOpen={this.state.openHeader == rowData["id"] ? true : false}
                  className="font-size-13 actionDropdownList"
                >
                  <DropdownToggle
                    tag="a"
                    className="nav-link pt-0 actionDropdownList"
                    caret
                  >
                    <MoreVertIcon
                      className="menu-dots-vertical actionDropdownList"
                      onClick={(event) =>
                        this.openHeaderMenu(event, rowData["id"])
                      }
                    />
                  </DropdownToggle>
                  {this.state.parentCardInfo["getCardType"] != 3 &&
                  this.state.parentCardInfo["getCardType"] != 4 ? (
                    <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList menu-cardClass">
                      <div
                        className="actionDropdownList"
                        style={{ maxHeight: "250px", overflowY: "scroll" }}
                      >
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openCardAction(event, "addSupplier", rowData)
                          }
                        >
                          {Strings["Add Supplier"]}
                        </DropdownItem>
                        {this.state.parentCardInfo["getCardType"] != 5 &&
                          this.state.parentCardInfo["getCardType"] != 5 && (
                            <DropdownItem
                              className="actionDropdownList"
                              onClick={(event) =>
                                this.openCardAction(
                                  event,
                                  "addBalance",
                                  rowData
                                )
                              }
                            >
                              {Strings["Add Cards"]}
                            </DropdownItem>
                          )}
                        {(localStorage.getItem("USER_LEVEL") == 1 ||
                          localStorage.getItem("INV_MGR") == 1) && (
                          <DropdownItem
                            className="actionDropdownList"
                            onClick={(event) =>
                              this.openCardsStatement(
                                event,
                                "activeCardClassToOrder",
                                rowData
                              )
                            }
                          >
                            {Strings["Deactivation Days"]}
                          </DropdownItem>
                        )}
                        {addSupplierApiCondition && (
                          <DropdownItem
                            className="actionDropdownList"
                            onClick={(event) =>
                              this.openCouponsCardsSidebarModal(
                                event,
                                rowData,
                                "addSupplierApi"
                              )
                            }
                          >
                            {Strings["Selection of API Suppliers"]}
                          </DropdownItem>
                        )}
                        {addSupplierApiCondition &&
                          rowData["type"] != undefined &&
                          rowData["type"] != 0 &&
                          rowData["type"] != "" && (
                            <DropdownItem
                              className="actionDropdownList"
                              onClick={(event) =>
                                this.openCardAction(
                                  event,
                                  "sortPriority",
                                  rowData
                                )
                              }
                            >
                              {Strings["Prioritizing Suppliers"]}
                            </DropdownItem>
                          )}
                        {/* {this.state.parentCardInfo['getCardType'] != 5 && rowData['type'] == 6 && (localStorage.getItem('USER_LEVEL') != 1 &&  localStorage.getItem('INV_MGR') != 1)&& <DropdownItem className='actionDropdownList' onClick={(event) => this.openCardAction(event, 'addNumnyBalance', rowData)}>{Strings['Add Numny Balance']}</DropdownItem>}
                                                {this.state.parentCardInfo['getCardType'] != 5 && rowData['type'] == 3 && (localStorage.getItem('USER_LEVEL') != 1 && localStorage.getItem('INV_MGR') != 1)&& <DropdownItem className='actionDropdownList' onClick={(event) => this.openCardAction(event, 'addEgifterBalance', rowData)}>{Strings['Add Egifter Balance']}</DropdownItem>} */}
                        {adminApiBalanceCondition && (
                          <DropdownItem
                            className="actionDropdownList"
                            onClick={(event) =>
                              this.openCardAction(
                                event,
                                "addBalanceFromApi",
                                rowData,
                                1
                              )
                            }
                          >
                            {nameAddBalanceApi}
                          </DropdownItem>
                        )}
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openCardsStatement(
                              event,
                              "accountStatement",
                              rowData
                            )
                          }
                        >
                          {Strings["Account Statement"]}
                        </DropdownItem>
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openCardsStatement(event, "viewCards", rowData)
                          }
                        >
                          {Strings["View Cards"]}
                        </DropdownItem>
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openSidebarModal(event, "edit", rowData)
                          }
                        >
                          {Strings["Edit"]}
                        </DropdownItem>
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.deleteCardClass(event, rowData.id)
                          }
                        >
                          {Strings["Delete"]}
                        </DropdownItem>
                      </div>
                    </DropdownMenu>
                  ) : (
                    <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList">
                      <div
                        className="actionDropdownList"
                        style={{ maxHeight: "250px", overflowY: "scroll" }}
                      >
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openSidebarModal(event, "edit", rowData)
                          }
                        >
                          {Strings["Edit"]}
                        </DropdownItem>
                      </div>
                    </DropdownMenu>
                  )}
                </Dropdown>
              </Col>
            )}
          </Row>
        );
      });
    }
    return list;
  }

  deleteCardClass = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to delete this class ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };

  handleClickDelete(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "deleteCardClass",
        cardClassId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ openHeader: "" }, function() {
              this.getCardList(
                this.state.parentCardInfo,
                this.state.mainCatName,
                "",
                this.state.catData
              );
            });
          }
        }
      });
  }

  openSidebarModal = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        cardsCalssData: data,
        status: type,
        openleftModal: true,
      },
      function() {
        // document.removeEventListener('click', this.openHeaderMenu, false);
      }
    );
  };

  openCardAction = (event, type, data, addBalanceApi = "") => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    var nameAddBalanceApi = "";
    if (addBalanceApi) {
      nameAddBalanceApi =
        localStorage.getItem("LANG") == 1
          ? Strings["Add"] +
            " " +
            Strings["Balance Title"] +
            " " +
            data["supplierNameInvReact"]
          : Strings["Add"] +
            " " +
            data["supplierNameInvReact"] +
            " " +
            Strings["Balance"];
    }

    this.setState(
      {
        cardsCalssData: data,
        actionType: type,
        nameAddBalanceApi: nameAddBalanceApi,
        openCardAction: true,
      },
      function() {}
    );
  };

  openCouponsCardsSidebarModal = (event, data, type) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        cardsCalssData: data,
        actionType: type,
        openCouponsleftModal: true,
      },
      function() {}
    );
  };

  openCardsStatement = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        cardsCalssData: data,
        actionType: type,
        openCardStatement: true,
      },
      function() {}
    );
  };

  openCardExplanations = (event) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({
      openCardExplanations: true,
    });
  };
  handleCloseSideBar = () => {
    this.setState({ openleftModal: false, cardsCalssData: [], status: "" });
  };
  handleCloseDescSideBar = () => {
    this.setState({ openCardExplanations: false });
  };
  handleCloseCardActionSideBar = () => {
    this.setState({ openCardAction: false, actionType: "" });
  };

  handleCloseCardStatement = (loading = 0) => {
    this.setState({ openCardStatement: false, actionType: "" }, function() {
      if (loading == 1) {
        this.getCardList(
          this.state.parentCardInfo,
          this.state.mainCatName,
          "",
          this.state.catData
        );
      }
    });
  };

  handleCloseCouponsSideBar = () => {
    this.setState({ openCouponsleftModal: false, actionType: "" });
  };

  recallGetData = () => {
    this.getCardList(
      this.state.parentCardInfo,
      this.state.mainCatName,
      "",
      this.state.catData
    );
  };
  handleFormAction = () => {
    this.setState(
      {
        cardsCalssData: [],
        status: "",
        openleftModal: false,
      },
      function() {
        this.getCardList(
          this.state.parentCardInfo,
          this.state.mainCatName,
          "",
          this.state.catData
        );
      }
    );
  };

  addApiAction = (event) => {
    event.preventDefault();
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "addAPI",
        cardId: this.state.parentCardInfo["cardId"],
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.getCardList(
              this.state.parentCardInfo,
              this.state.mainCatName,
              "",
              this.state.catData
            );
          }
        }
      });
  };

  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };
  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };

  displayCardClassesHeader() {
    let classHeader = [];
    if (this.state.distClassList.length > 0) {
      this.state.distClassList.map((rowData, index) => {
        classHeader.push(
          <div className="col-4 col-sm-4 col-md-4 col-lg-4 table-header-row table-header-row-fixed-color">
            <div className="overflow-text-length">{rowData["name"]}</div>
          </div>
        );
      });
    }
    return classHeader;
  }

  displayCardClassesBody(id, rowDataClass) {
    let classBody = [];
    var price = "";
    if (
      this.state.distClassList.length > 0 &&
      rowDataClass != undefined &&
      Object.keys(rowDataClass).length > 0
    ) {
      this.state.distClassList.map((rowData, index) => {
        price =
          rowDataClass[rowData["id"]]["priceCurr"] != "" &&
          rowDataClass[rowData["id"]]["priceCurr"] != undefined
            ? rowDataClass[rowData["id"]]["priceCurr"]
            : rowDataClass[rowData["id"]]["price"];
        classBody.push(
          <div
            className={
              "col-4 col-sm-4 col-md-4 col-lg-4 table-body-row " +
              (this.state["activeIndex"] == id && " hover-scroll-table-row  ")
            }
            onMouseOver={this.addRowHover.bind(this, id)}
            onMouseOut={this.removeRowHover.bind(this, id)}
          >
            <div className="overflow-text-length">{price}</div>
          </div>
        );
      });
    }
    return classBody;
  }

  render() {
    console.log(localStorage.getItem('PERM_TYPE'),'PERM_TYPE');
    let classMain = [];
    let classInfo = [];
    let classAction = [];
    // var taxCondition = localStorage.getItem('PERM_TYPE') == 5 && this.props.mainDist == 2 && this.props.isDistTax == 1 && localStorage.getItem('IS_TAXDIST') == 1 && this.state.parentCardInfo['cardIsTax'] == 1;
    var colPricesMainDiv = "";
    var colActionMainDiv = "";
    var colBuyPrices =
      this.state.taxCondition == 1
        ? "col-4 col-sm-4 col-md-4 col-lg-4"
        : "col-12 col-sm-12 col-md-12 col-lg-12";
    if (
      localStorage.getItem("ACC_EMP") == 1 ||
      localStorage.getItem("OPERA_EMP") == 1
    ) {
      colPricesMainDiv = "col-8 col-sm-8 col-md-8 col-lg-8";
    } else {
      colPricesMainDiv =
        this.state.taxCondition == 1
          ? "col-7 col-sm-7 col-md-7 col-lg-7"
          : "col-5 col-sm-5 col-md-5 col-lg-5";
      colActionMainDiv =
        this.state.taxCondition == 1
          ? "col-1 col-sm-1 col-md-1 col-lg-1"
          : "col-3 col-sm-3 col-md-3 col-lg-3";
    }
    if (
      this.state.loading == 0 &&
      this.state.data?.length > 0 &&
      (localStorage.getItem("PERM_TYPE") == 1 ||
        this.state.permType == 1 ||
        ((localStorage.getItem("PERM_TYPE") == 5 ||
          this.state.permType == 5 ||
          localStorage.getItem("ACC_EMP") == 1 ||
          localStorage.getItem("OPERA_EMP") == 1) &&
          this.props.mainDist == 2))
    ) {
      this.state.data.map((rowData, index) => {
        classMain.push(
          <div
            className={rowData.id + "class" + " row flex-row flex-nowrap"}
            style={{ height: "50px" }}
            key={rowData.id}
          >
            <div
              className={
                this.state["activeIndex"] == rowData.id
                  ? "col-12 col-sm-12 col-md-12 col-lg-12 table-body-row  main-color-1 hover-scroll-table-row  "
                  : "col-12 col-sm-12 col-md-12 col-lg-12 table-body-row main-color-1"
              }
              onMouseOver={this.addRowHover.bind(this, rowData.id)}
              onMouseOut={this.removeRowHover.bind(this, rowData.id)}
            >
              <div className="overflow-text-length font-w-400">
                {rowData["cardClassName"]}
              </div>
            </div>
          </div>
        );

        classInfo.push(
          <div
            className={rowData.id + "class" + " row flex-row flex-nowrap"}
            style={{ height: "50px" }}
            key={rowData.id}
          >
            {this.props.mainDist == 1 && (
              <div
                className={
                  "col-4 col-sm-4 col-md-4 col-lg-4 table-body-row " +
                  (this.state["activeIndex"] == rowData.id &&
                    " hover-scroll-table-row  ")
                }
                onMouseOver={this.addRowHover.bind(this, rowData.id)}
                onMouseOut={this.removeRowHover.bind(this, rowData.id)}
              >
                <div className="overflow-text-length">
                  {rowData["classQty"]}
                </div>
              </div>
            )}
            <div
              className={
                (this.props.mainDist == 1
                  ? "col-4 col-sm-4 col-md-4 col-lg-4"
                  : colBuyPrices) +
                " table-body-row " +
                (this.state["activeIndex"] == rowData.id &&
                  " hover-scroll-table-row  ")
              }
              onMouseOver={this.addRowHover.bind(this, rowData.id)}
              onMouseOut={this.removeRowHover.bind(this, rowData.id)}
            >
              {/* <div className={(this.props.mainDist == 1 ? 'col-4 col-sm-4 col-md-4 col-lg-4' : 'col-12 col-sm-12 col-md-12 col-lg-12') + ' table-body-row ' + (this.state['activeIndex'] == rowData.id && ' hover-scroll-table-row  ')} onMouseOver={this.addRowHover.bind(this, rowData.id)} onMouseOut={this.removeRowHover.bind(this, rowData.id)}> */}
              <div className="overflow-text-length">
                {/* {rowData['price']} */}
                {this.props.mainDist == 1
                  ? rowData["priceFormat"]
                  : rowData["priceDistCurr"]}
              </div>
            </div>
            {this.props.mainDist == 1 && (
              <div
                className={
                  "col-4 col-sm-4 col-md-4 col-lg-4 table-body-row " +
                  (this.state["activeIndex"] == rowData.id &&
                    " hover-scroll-table-row  ")
                }
                onMouseOver={this.addRowHover.bind(this, rowData.id)}
                onMouseOut={this.removeRowHover.bind(this, rowData.id)}
              >
                <div className="overflow-text-length">
                  {/* {rowData['buyPrice']} */}
                  {rowData["buyPriceCurr"]}
                </div>
              </div>
            )}
            {this.state.taxCondition == 1 && (
              <div
                className={
                  "col-4 col-sm-4 col-md-4 col-lg-4 table-body-row " +
                  (this.state["activeIndex"] == rowData.id &&
                    " hover-scroll-table-row  ")
                }
                onMouseOver={this.addRowHover.bind(this, rowData.id)}
                onMouseOut={this.removeRowHover.bind(this, rowData.id)}
              >
                <div className="overflow-text-length">
                  {/* {rowData['buyPrice']} */}
                  {rowData["priceWithTax"]}
                </div>
              </div>
            )}
            {this.state.taxCondition == 1 && (
              <div
                className={
                  "col-4 col-sm-4 col-md-4 col-lg-4 table-body-row " +
                  (this.state["activeIndex"] == rowData.id &&
                    " hover-scroll-table-row  ")
                }
                onMouseOver={this.addRowHover.bind(this, rowData.id)}
                onMouseOut={this.removeRowHover.bind(this, rowData.id)}
              >
                <div className="overflow-text-length">
                  {/* {rowData['buyPrice']} */}
                  {rowData["percentTaxFromDist"]}
                </div>
              </div>
            )}
            {this.props.mainDist == 1 &&
              this.displayCardClassesBody(rowData.id, rowData["classes"])}
          </div>
        );

        classAction.push(
          <div
            className={rowData.id + "class" + " row flex-row flex-nowrap"}
            style={{ height: "50px" }}
            key={rowData.id}
          >
            <div
              className={
                "col-12 col-sm-12 col-md-12 col-lg-12 table-body-row text-right action-icon-view actionDropdownList" +
                (this.state["activeIndex"] == rowData.id &&
                  " hover-scroll-table-row  ")
              }
              onMouseOver={this.addRowHover.bind(this, rowData.id)}
              onMouseOut={this.removeRowHover.bind(this, rowData.id)}
            >
              <Dropdown
                isOpen={this.state.openHeader == rowData["id"] ? true : false}
                className="font-size-13 actionDropdownList"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link pt-0 actionDropdownList"
                  caret
                >
                  <MoreVertIcon
                    className="menu-dots-vertical actionDropdownList"
                    onClick={(event) =>
                      this.openHeaderMenu(event, rowData["id"])
                    }
                  />
                </DropdownToggle>
                <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList">
                  <div
                    className="actionDropdownList"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    {this.props.mainDist == 1 && (
                      <DropdownItem
                        className="actionDropdownList"
                        onClick={(event) =>
                          this.openCardAction(
                            event,
                            "editDistCardPrice",
                            rowData
                          )
                        }
                      >
                        {Strings["Edit Classes Price"]}
                      </DropdownItem>
                    )}
                    {this.props.mainDist == 2 && (
                      <DropdownItem
                        className="actionDropdownList"
                        onClick={(event) =>
                          this.openCardAction(event, "editPosPrice", rowData)
                        }
                      >
                        {Strings["Edit Price"]}
                      </DropdownItem>
                    )}
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        );
      });
    }

    return (
      <div>
        {this.state.loading == 1 && (
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}

        {this.state.loading == 0 && (
          <Row>
            <Col
              lg={
                this.props.mainDist == 2 &&
                (localStorage.getItem("PERM_TYPE") == 5 ||
                  this.state.permType == 5 ||
                  localStorage.getItem("ACC_EMP") == 1 ||
                  localStorage.getItem("OPERA_EMP") == 1)
                  ? 12
                  : 7
              }
              md={
                this.props.mainDist == 2 &&
                (localStorage.getItem("PERM_TYPE") == 5 ||
                  this.state.permType == 5 ||
                  localStorage.getItem("ACC_EMP") == 1 ||
                  localStorage.getItem("OPERA_EMP") == 1)
                  ? 12
                  : 7
              }
              sm={
                this.props.mainDist == 2 &&
                (localStorage.getItem("PERM_TYPE") == 5 ||
                  this.state.permType == 5 ||
                  localStorage.getItem("ACC_EMP") == 1 ||
                  localStorage.getItem("OPERA_EMP") == 1)
                  ? 12
                  : 7
              }
              xs={12}
              className="main-color-1 inv-class-view-title"
            >
              <Paper elevation={0}>
                <Breadcrumbs
                  separator={
                    localStorage.getItem("LANG") == 1 ? (
                      <NavigateBeforeIcon fontSize="small" />
                    ) : (
                      <NavigateNextIcon fontSize="small" />
                    )
                  }
                  aria-label="Breadcrumb"
                >
                  {this.props.mainDist == 2 &&
                    (localStorage.getItem("PERM_TYPE") == 5 ||
                      this.state.permType == 5 ||
                      localStorage.getItem("ACC_EMP") == 1 ||
                      localStorage.getItem("OPERA_EMP") == 1) &&!this.state.distId&& (
                      <Link color="inherit" href={this.state.link}>
                        {this.props.posName}
                      </Link>
                    )}
                  <Link
                    color="inherit"
                    href="#"
                    onClick={() => this.displayCardsMain()}
                  >
                    {" "}
                    {this.state.mainCatName}{" "}
                  </Link>
                  <Typography color="textPrimary">
                    {this.state.parentCardInfo[this.state.nameCard]}
                  </Typography>
                </Breadcrumbs>
              </Paper>
              <hr className="inv-hr-view-2" />
            </Col>
            {this.state.parentCardInfo["getCardType"] != 3 &&
            this.state.parentCardInfo["getCardType"] != 4 ? (
              <Col lg={5} md={5} sm={5} xs={12} className="text-right">
                {(localStorage.getItem("PERM_TYPE") != 2 ||
                  (this.state.permType != 2&&this.state.permType !='')) &&
                  this.props.mainDist != 1 &&
                  this.props.mainDist != 2 &&
                  (localStorage.getItem("PERM_TYPE") != 3 ||
                  (this.state.permType != 3&&this.state.permType !='')) && (
                    <button
                      className="btn page-action-btn ml-1"
                      onClick={(event) => this.openCardExplanations(event)}
                    >
                      {Strings["Card Description"]}
                    </button>
                  )}
                {(localStorage.getItem("PERM_TYPE") != 2 ||
                  (this.state.permType != 2&&this.state.permType !='')) &&
                  this.props.mainDist != 1 &&
                  this.props.mainDist != 2 &&
                  (localStorage.getItem("PERM_TYPE") != 3 ||
                  (this.state.permType != 3&&this.state.permType !='')) && (
                    <button
                      className="btn gradient-button-view gradient-button gradient-button-font-12 ml-1"
                      onClick={(event) =>
                        this.openSidebarModal(event, "add", "")
                      }
                    >
                      {Strings["Add Class"]}
                    </button>
                  )}
              </Col>
            ) : (
              <Col lg={5} md={5} sm={5} xs={12} className="text-right">
                {(localStorage.getItem("PERM_TYPE") != 3 ||
                  (this.state.permType != 3&&this.state.permType !='')) &&
                  (localStorage.getItem("PERM_TYPE") != 5 ||
                  (this.state.permType != 5&&this.state.permType !='')) &&
                  localStorage.getItem("OPERA_EMP") != 5 &&
                  localStorage.getItem("ACC_EMP") != 5 && (
                    <button
                      className="btn page-action-btn ml-1"
                      onClick={(event) => this.addApiAction(event)}
                    >
                      {Strings["Add API"]}
                    </button>
                  )}
              </Col>
            )}
          </Row>
        )}

        {this.state.loading == 0 && this.state.data?.length <= 0 && (
          <Row className="text-center" style={{ marginTop: "15%" }}>
            <div>
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">
                {Strings["There is no data to display"]}
              </div>
            </div>
          </Row>
        )}

        {this.state.loading == 0 && this.state.data?.length > 0 && (
          <div className="inv-cards-view">
            {(localStorage.getItem("PERM_TYPE") == 2 ||
              this.state.permType == 2 ||
              localStorage.getItem("PERM_TYPE") == 3 ||
              this.state.permType == 3) && (
              <Row className="table-header-row">
                <Col
                  lg={
                    (localStorage.getItem("PERM_TYPE") == 3 ||
                    this.state.permType == 3)
                      ? 5
                      : 3
                  }
                  md={
                    (localStorage.getItem("PERM_TYPE") == 3 ||
                    this.state.permType == 3)
                      ? 5
                      : 3
                  }
                  sm={
                   ( localStorage.getItem("PERM_TYPE") == 3 ||
                    this.state.permType == 3)
                      ? 5
                      : 3
                  }
                  xs={12}
                >
                  {Strings["Denomination"]} 
                </Col>
                <Col md={2}>
                  {localStorage.getItem("PERM_TYPE") == 3 ||
                  this.state.permType == 3
                    ? Strings["Buy Price"]
                    : Strings["Value"]}
                </Col>
                {(localStorage.getItem("PERM_TYPE") != 3 ||
                (this.state.permType != 3&&this.state.permType !='')) && (
                  <Col lg={2} md={2} sm={2} xs={12}>
                    {Strings["Selling Price"]}
                  </Col>
                )}
                {(localStorage.getItem("PERM_TYPE") != 3 ||
                  (this.state.permType != 3&&this.state.permType !='')) && (
                  <Col lg={2} md={2} sm={2} xs={12}>
                    {Strings["Cost Price"]}
                  </Col>
                )}
                {(localStorage.getItem("PERM_TYPE") != 3 ||
                  (this.state.permType != 3&&this.state.permType !='')) && (
                  <Col lg={2} md={2} sm={2} xs={12}>
                    {Strings["Status"]}
                  </Col>
                )}
                {(localStorage.getItem("PERM_TYPE") != 3 ||
                  (this.state.permType != 3&&this.state.permType !='')) && (
                  <Col lg={1} md={1} sm={1} xs={12} className="text-center">
                    {Strings["Action"]}
                  </Col>
                )}
              </Row>
            )}

            {(localStorage.getItem("PERM_TYPE") != 3 ||
              (this.state.permType != 3&&this.state.permType !='')) &&
              (localStorage.getItem("PERM_TYPE") != 2 ||
                (this.state.permType != 2&&this.state.permType !='')) &&
              this.props.mainDist != 1 &&
              this.props.mainDist != 2 && (
                <Row className="table-header-row">
                  <Col
                    className="overflow-text-length"
                    lg={
                      this.state.parentCardInfo["getCardType"] == 3 ||
                      this.state.parentCardInfo["getCardType"] == 4 ||
                      localStorage.getItem("PERM_TYPE") == 3
                        ? 5
                        : localStorage.getItem("USER_LEVEL") == 1 ||
                          localStorage.getItem("INV_MGR") == 1
                        ? 2
                        : 3
                    }
                    md={
                      this.state.parentCardInfo["getCardType"] == 3 ||
                      this.state.parentCardInfo["getCardType"] == 4 ||
                      localStorage.getItem("PERM_TYPE") == 3
                        ? 5
                        : localStorage.getItem("USER_LEVEL") == 1 ||
                          localStorage.getItem("INV_MGR") == 1
                        ? 2
                        : 3
                    }
                    sm={
                      this.state.parentCardInfo["getCardType"] == 3 ||
                      this.state.parentCardInfo["getCardType"] == 4 ||
                      localStorage.getItem("PERM_TYPE") == 3
                        ? 5
                        : localStorage.getItem("USER_LEVEL") == 1 ||
                          localStorage.getItem("INV_MGR") == 1
                        ? 2
                        : 3
                    }
                    xs={12}
                  >
                    {Strings["Denomination"]}
                  </Col>
                  <Col
                    className="overflow-text-length"
                    lg={
                      this.state.parentCardInfo["getCardType"] == 3 ||
                      this.state.parentCardInfo["getCardType"] == 4
                        ? 5
                        : 2
                    }
                    md={
                      this.state.parentCardInfo["getCardType"] == 3 ||
                      this.state.parentCardInfo["getCardType"] == 4
                        ? 5
                        : 2
                    }
                    sm={
                      this.state.parentCardInfo["getCardType"] == 3 ||
                      this.state.parentCardInfo["getCardType"] == 4
                        ? 5
                        : 2
                    }
                    xs={12}
                  >
                    {localStorage.getItem("PERM_TYPE") == 3 ||
                    this.state.permType == 3
                      ? Strings["Buy Price"]
                      : Strings["Value"]}
                  </Col>
                  {this.state.parentCardInfo["getCardType"] != 3 &&
                    this.state.parentCardInfo["getCardType"] != 4 &&
                    (localStorage.getItem("PERM_TYPE") != 3 ||
                    (this.state.permType != 3&&this.state.permType !='')) && (
                      <Col
                        className="overflow-text-length"
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                      >
                        {Strings["Number of cards"]}
                      </Col>
                    )}
                  {this.state.parentCardInfo["getCardType"] != 3 &&
                    this.state.parentCardInfo["getCardType"] != 4 &&
                    (localStorage.getItem("PERM_TYPE") != 3 ||
                    (this.state.permType != 3&&this.state.permType !='')) && (
                      <Col
                        className="overflow-text-length"
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                      >
                        {Strings["Minimum number of cards"]}
                      </Col>
                    )}
                  {this.state.parentCardInfo["getCardType"] != 3 &&
                    this.state.parentCardInfo["getCardType"] != 4 &&
                    (localStorage.getItem("PERM_TYPE") != 3 ||
                    (this.state.permType != 3&&this.state.permType !='')) &&
                    (localStorage.getItem("USER_LEVEL") == 1 ||
                      localStorage.getItem("INV_MGR") == 1) && (
                      <Col
                        className="overflow-text-length"
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                      >
                        {Strings["Main Supplier"]}
                      </Col>
                    )}
                  {this.state.parentCardInfo["getCardType"] != 3 &&
                    this.state.parentCardInfo["getCardType"] != 4 &&
                    (localStorage.getItem("PERM_TYPE") != 3 ||
                    (this.state.permType != 3&&this.state.permType !='')) && (
                      <Col
                        className="overflow-text-length"
                        md={
                          localStorage.getItem("USER_LEVEL") == 1 ||
                          localStorage.getItem("INV_MGR") == 1
                            ? 1
                            : 2
                        }
                        lg={
                          localStorage.getItem("USER_LEVEL") == 1 ||
                          localStorage.getItem("INV_MGR") == 1
                            ? 1
                            : 2
                        }
                        sm={
                          localStorage.getItem("USER_LEVEL") == 1 ||
                          localStorage.getItem("INV_MGR") == 1
                            ? 1
                            : 2
                        }
                        xs={12}
                      >
                        {Strings["Status"]}
                      </Col>
                    )}
                  {(localStorage.getItem("PERM_TYPE") != 3 ||
                   (this.state.permType != 3&&this.state.permType !='')) && (
                    <Col lg={1} md={1} sm={1} xs={12} className="text-center">
                      {Strings["Action"]}
                    </Col>
                  )}
                </Row>
              )}

            {(localStorage.getItem("PERM_TYPE") == 2 ||
              this.state.permType == 2 ||
              localStorage.getItem("PERM_TYPE") == 3 ||
              this.state.permType == 3) &&
              this.displayData_2()}

            {(localStorage.getItem("PERM_TYPE") != 3 ||
             (this.state.permType != 3&&this.state.permType !='')) &&
              (localStorage.getItem("PERM_TYPE") != 2 ||
              (this.state.permType != 3&&this.state.permType !='')) &&
              this.props.mainDist != 1 &&
              this.props.mainDist != 2 &&
              this.displayData()}

            {(this.props.mainDist == 1 || this.props.mainDist == 2) && (
              <Row>
                <div className="col-md-4">
                  <div className="row flex-row flex-nowrap">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 table-header-row ">
                      <div className="overflow-text-length">
                        {Strings["Class Name"]}
                      </div>
                    </div>
                  </div>
                  {classMain}
                </div>

                {/* <div className='col-md-5 pr-0' style={{ 'overflowX': 'auto', 'overflowY': 'hidden', 'width': '100%' }}> */}
                <div
                  className={colPricesMainDiv + " pr-0"}
                  style={{
                    overflowX: "auto",
                    overflowY: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="row flex-row flex-nowrap">
                    {this.props.mainDist == 1 && (
                      <div className="col-4 col-sm-4 col-md-4 col-lg-4 table-header-row table-header-row-fixed-color">
                        <div className="overflow-text-length">
                          {Strings["Qty"]}
                        </div>
                      </div>
                    )}
                    {/* <div className={(this.props.mainDist == 1 ? 'col-4 col-sm-4 col-md-4 col-lg-4' : 'col-12 col-sm-12 col-md-12 col-lg-12') + " table-header-row table-header-row-fixed-color"}> */}
                    <div
                      className={
                        (this.props.mainDist == 1
                          ? "col-4 col-sm-4 col-md-4 col-lg-4"
                          : colBuyPrices) +
                        " table-header-row table-header-row-fixed-color"
                      }
                    >
                      <div className="overflow-text-length">
                        {Strings["Buy Price"]}
                      </div>
                    </div>
                    {this.props.mainDist == 1 && (
                      <div className="col-4 col-sm-4 col-md-4 col-lg-4 table-header-row table-header-row-fixed-color">
                        <div className="overflow-text-length">
                          {Strings["Sell Price"]}
                        </div>
                      </div>
                    )}
                    {this.state.taxCondition == 1 && (
                      <div className="col-4 col-sm-4 col-md-4 col-lg-4 table-header-row table-header-row-fixed-color">
                        <div className="overflow-text-length">
                          {Strings["Price With Tax"]}
                        </div>
                      </div>
                    )}
                    {this.state.taxCondition == 1 && (
                      <div className="col-4 col-sm-4 col-md-4 col-lg-4 table-header-row table-header-row-fixed-color">
                        <div className="overflow-text-length">
                          {Strings["Tax"]}
                        </div>
                      </div>
                    )}
                    {this.props.mainDist == 1 &&
                      this.displayCardClassesHeader()}
                  </div>
                  {classInfo}
                </div>
                {/* <div className='col-md-3 pl-0'> */}
                {localStorage.getItem("ACC_EMP") == 0 &&
                  localStorage.getItem("OPERA_EMP") == 0 && (
                    <div className={colActionMainDiv + "  pl-0"}>
                      <div className="row flex-row flex-nowrap">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 table-header-row table-header-row-fixed-color">
                          <div className="overflow-text-length text-right">
                            {Strings["Action"]}
                          </div>
                        </div>
                      </div>
                      {classAction}
                    </div>
                  )}
              </Row>
            )}
          </div>
        )}

        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openleftModal}
          className="sidebar-modal-view"
        >
          <CardForm
            handleCloseSideBar={this.handleCloseSideBar}
            catData={this.state.catData}
            handleFormAction={this.handleFormAction}
            status={this.state.status}
            cardsCalssData={this.state.cardsCalssData}
            parentCardInfo={this.state.parentCardInfo}
            distId={this.state.distId}
          />
        </Drawer>

        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openCardExplanations}
          className="sidebar-modal-large-view"
        >
          <CardExplanations
            handleCloseSideBar={this.handleCloseDescSideBar}
            parentCardInfo={this.state.parentCardInfo}
            distId={this.state.distId}
          />
        </Drawer>

        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openCardAction}
          className="sidebar-modal-view"
        >
          {this.state.actionType == "addSupplier" && (
            <CardSuppliers
              distId={this.state.distId}
              handleCloseSideBar={this.handleCloseCardActionSideBar}
              parentCardInfo={this.state.parentCardInfo}
              cardsCalssData={this.state.cardsCalssData}
            />
          )}

          {this.state.actionType == "addBalance" && (
            <AddBalance
              distId={this.state.distId}
              handleCloseSideBar={this.handleCloseCardActionSideBar}
              parentCardInfo={this.state.parentCardInfo}
              cardsCalssData={this.state.cardsCalssData}
              recallGetData={this.recallGetData}
            />
          )}

          {(this.state.actionType == "addNumnyBalance" ||
            this.state.actionType == "addEgifterBalance" ||
            this.state.actionType == "addBalanceFromApi") && (
            <AddNumnyAndEgifterBalance
              handleCloseSideBar={this.handleCloseCardActionSideBar}
              parentCardInfo={this.state.parentCardInfo}
              cardsCalssData={this.state.cardsCalssData}
              distId={this.state.distId}
              recallGetData={this.recallGetData}
              formName={
                this.state.actionType == "addNumnyBalance"
                  ? Strings["Add Numny Balance"]
                  : this.state.actionType == "addBalanceFromApi"
                  ? this.state.nameAddBalanceApi
                  : Strings["Add Egifter Balance"]
              }
            />
          )}

          {this.state.actionType == "editSellingPrice" && (
            <AddSellingPrice
              distId={this.state.distId}
              handleCloseSideBar={this.handleCloseCardActionSideBar}
              parentCardInfo={this.state.parentCardInfo}
              cardsCalssData={this.state.cardsCalssData}
              recallGetData={this.recallGetData}
            />
          )}

          {this.state.actionType == "editDistCardPrice" && (
            <EditDistCardPrice
              distId={this.state.distId}
              handleCloseSideBar={this.handleCloseCardActionSideBar}
              parentCardInfo={this.state.parentCardInfo}
              cardsCalssData={this.state.cardsCalssData}
              recallGetData={this.recallGetData}
              distClassList={this.state.distClassList}
            />
          )}

          {this.state.actionType == "editPosPrice" && (
            <EditPosPrice
              distId={this.state.distId}
              handleCloseSideBar={this.handleCloseCardActionSideBar}
              parentCardInfo={this.state.parentCardInfo}
              taxCondition={this.state.taxCondition}
              cardsCalssData={this.state.cardsCalssData}
              recallGetData={this.recallGetData}
              posId={this.props.posId}
            />
          )}
          {this.state.actionType == "sortPriority" && (
            <SortPriority
              distId={this.state.distId}
              handleCloseSideBar={this.handleCloseCardActionSideBar}
              parentCardInfo={this.state.parentCardInfo}
              cardsCalssData={this.state.cardsCalssData}
              recallGetData={this.recallGetData}
              isCard={"0"}
            />
          )}
        </Drawer>
        <Drawer
          style={{ position: "absolute", zIndex: 2 }}
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openCardStatement}
          className={
            this.state.actionType == "viewCards" ||
            this.state.actionType == "accountStatement"
              ? "sidebar-modal-large-view view-supplier-transaction-modal"
              : "sidebar-modal-large-view"
          }
        >
          {this.state.actionType == "accountStatement" && (
            <AccountStatement
              distId={this.state.distId}
              handleCloseSideBar={this.handleCloseCardStatement}
              parentCardInfo={this.state.parentCardInfo}
              cardsCalssData={this.state.cardsCalssData}
            />
          )}
          {this.state.actionType == "viewCards" && (
            <ViewCards
              distId={this.state.distId}
              handleCloseSideBar={this.handleCloseCardStatement}
              parentCardInfo={this.state.parentCardInfo}
              cardsCalssData={this.state.cardsCalssData}
            />
          )}
          {this.state.actionType == "activeCardClassToOrder" && (
            <ActiveCardOrCardClassToOrder
              distId={this.state.distId}
              handleCloseSideBar={this.handleCloseCardStatement}
              parentCardInfo={this.state.parentCardInfo}
              cardsCalssData={this.state.cardsCalssData}
              isCard={"0"}
            />
          )}
        </Drawer>

        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openCouponsleftModal}
          className="sidebar-modal-x-large-view"
        >
          {this.state.actionType == "addCoupons" && (
            <AddCoupons
              distId={this.state.distId}
              handleCloseSideBar={this.handleCloseCouponsSideBar}
              cardType="2"
              cardId={this.state.cardsCalssData["classId"]}
              cardsData={this.state.cardsCalssData}
            />
          )}
          {this.state.actionType == "addSupplierApi" && (
            <AddSupplierApi
              distId={this.state.distId}
              handleCloseSideBar={this.handleCloseCouponsSideBar}
              parentCardInfo={this.state.parentCardInfo}
              cardsCalssData={this.state.cardsCalssData}
              recallGetData={this.recallGetData}
            />
          )}
        </Drawer>
      </div>
    );
  }
}
export default Cards;

/**
* Actions Part
*/

export const TYPE = {
    UPDATEBALANCE: "UPDATEBALANCE"
};

export const getBalance = (parsedData) => {
    return {
        type: TYPE.UPDATEBALANCE,
        payload: parsedData,
    };
};

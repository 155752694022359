import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { toAbsoluteUrl } from "../../../_metronic";
import Category from "./category";
import CardClass from "./cardClass";
import Cards from "./cards";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { translations } from "../../../translate.js";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewCardMode: 1,
      loading: 1,
      posName: "",
      isDistTax: "",
      catData: [],
      dataCount: 0,
      distId: this.props?.location?.state?.distId,
      distData: this.props?.location?.state?.distData,
      permType: this.props?.location?.state?.distData?.permType,
      isTaxDist: this.props?.location?.state?.distData?.isTaxDist,
      pathFlag: this.props?.location?.state?.flag,
    };
  }
  getPosInfo = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getEmpInfo",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        posId: this.props.match.params.posId,
        distId:this.state.distId?this.state.distId:''
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              posName: data["fulName"],
              isDistTax: data["isDistTax"],
            });
          }
        }
      });
  };
  componentDidMount() {
    this.refs.category.getCategoryList();
    if (
      this.props.match.params.mainDist == 2 &&
      (localStorage.getItem("PERM_TYPE") == 5 || this.state.permType==5||
        localStorage.getItem("ACC_EMP") == 1 ||
        localStorage.getItem("OPERA_EMP") == 1)
    ) {
      this.getPosInfo();
    }
  }

  displayCardsList = (
    cardData,
    mainCatName,
    mainCatType,
    catData,
    seach = 0,
    searchData = []
  ) => {
    this.setState({ viewCardMode: 2 }, function() {
      console.log("inv cardclass");
      console.log(seach);
      this.refs.cards.getCardList(
        cardData,
        mainCatName,
        mainCatType,
        catData,
        seach,
        searchData
      );
    });
  };

  displayCardsMain = (catId, mainCatName, mainCatType) => {
    this.setState({ viewCardMode: 1 }, function() {
      this.refs.cardClass.getCardsList(
        catId,
        mainCatName,
        mainCatType,
        0,
        this.state.catData
      );
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
    });
  };

  loadPageAction = (dataCount, catId, catData) => {
    this.setState(
      {
        catData: catData,
        loading: 0,
        dataCount: dataCount,
      },
      function() {
        if (dataCount > 0) {
          // get cards
          if (this.refs.cardClass != undefined)
            this.refs.cardClass.getCardsList(
              catId,
              localStorage.getItem("LANG") == 1
                ? catData["name"]
                : catData["name_en"],
              catData["type"],
              0,
              catData
            );
        }
      }
    );
  };

  changeCategoryCards = (catId, catData, seach = 0, searchData = []) => {
    // get cards
    // console.log("inv ");
    // console.log(seach);
    this.setState({ viewCardMode: 1, catData: catData }, function() {
      console.log(catData,'catData');
      if (this.refs.cardClass != undefined)
        this.refs.cardClass.getCardsList(
          catId,
          localStorage.getItem("LANG") == 1
            ? catData["name"]
            : catData["name_en"],
          catData["type"],
          0,
          catData,
          seach,
          searchData
        );
    });
  };

  render() {
    // console.log('this.props.match.params.mainDist');
    // console.log(this.props.match.params.mainDist);

    return (
      <div>
          { this.state.distId&&(<Col className="mb-5 pb-4" md={12}><Paper elevation={0}>
                <Breadcrumbs
                  separator={
                    localStorage.getItem("LANG") == 1 ? (
                      <NavigateBeforeIcon fontSize="large" />
                    ) : (
                      <NavigateNextIcon fontSize="large" />
                    )
                  }
                  aria-label="Breadcrumb"
                >
                      <Link
                        className="color-bridcrim"
                        to={{
                          pathname:
                            "/settings/admin/Distributors" ,
                        }}
                        href="#"
                      >
                        {" "}
                        {Strings["Distributors"]}
                      </Link>
                      <Link
                        className="color-bridcrim"
                        to={{
                          pathname:
                            "/settings/DistAccountInfo/" + this.state.distId,
                          state: {
                            distId: this.state.distId,
                            distData: this.state.distData,
                          },
                        }}
                        href="#"
                      >
                        {" "}
                        {this.state.distData?.fname}
                      </Link>
                     {this.state.pathFlag&& <Link
                        className="color-bridcrim"
                  
                        to={{
                          pathname: "/posEmp",
                          state: {
                            distId: this.state.distId,
                            distData: this.state.distData,
                          },
                        }}
                        href="#"
                      >
                        {" "}
                        {Strings['Points Of Sales']}
                      </Link>}
                  <Typography color="textPrimary">
                    {Strings["Cards"]}
                  </Typography>
                </Breadcrumbs>
          
              </Paper>  <hr className="inv-hr-view-2" /></Col>)}
        {this.state.loading == 1 && (
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}
        {this.state.loading == 0 && this.state.dataCount <= 0 && (
          <Row className="text-center" style={{ marginTop: "15%" }}>
            <div>
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">
                {Strings["There is no data to display"]}
              </div>
            </div>
          </Row>
        )}

        <Row>
       
          <Col md={3}>
            <Category
              mainDist={this.props.match.params.mainDist}
              posId={this.props.match.params.posId}
              distId={this.state.distId}
              permType={this.state.permType}
              distData={this.state.distData}
              ref="category"
              loadPageAction={this.loadPageAction}
              changeCategoryCards={this.changeCategoryCards}
            />
          </Col>
          {this.state.loading == 0 && this.state.dataCount > 0 && (
            <Col md={9}>
              {this.state.viewCardMode == 1 && (
                <CardClass
                  mainDist={this.props.match.params.mainDist}
                  posId={this.props.match.params.posId}
                  posName={this.state.posName}
                  distId={this.state.distId}
                  permType={this.state.permType}
                  distData={this.state.distData}
                  isDistTax={this.state.isTaxDist?this.state.isTaxDist:this.state.isDistTax}
                  ref="cardClass"
                  createQr={this.state?.catData?.createQr}
                  displayCardsList={this.displayCardsList}
                />
              )}
              {this.state.viewCardMode == 2 && (
                <Cards
                createQr={this.state?.catData?.createQr}
                  mainDist={this.props.match.params.mainDist}
                  posId={this.props.match.params.posId}
                  posName={this.state.posName}
                  isDistTax={this.state.isTaxDist?this.state.isTaxDist:this.state.isDistTax}
                  distId={this.state.distId}
                  permType={this.state.permType}
                  distData={this.state.distData}
                  ref="cards"
                  displayCardsMain={this.displayCardsMain}
                />
              )}
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
export default Inventory;

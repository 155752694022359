import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import CardForm from './forms/cardClass';
import FixedPrice from './forms/fixedPrice'
import CardLangForm from './forms/cardLangForm'
import { Button, Drawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Switch from '@material-ui/core/Switch';
import { translations } from '../../../translate.js';
import AddCoupons from './forms/addCoupon'
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class DaftraProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openleftModal: false,
            cardsData: [],
            data: [],
            loading: 1,
            catName: '',
            cardsDataFixesPrice: [],
            openFixedPriceLeftModal: false,
            openEditCardleftModal: false,
            openCouponsleftModal: false
        }
    }
    displayData() {
        var list = ''
        if (this.state.data.length > 0) {
            list = this.state.data.map((rowData, index) => {
                return (
                    <Row key={index} className='table-body-row'>
                        <Col md={4} className='main-color-1 font-w-400'>{rowData['productName']}</Col>
                        <Col md={4} className='main-color-1 font-w-400'>{rowData['productPrice']}</Col>
                        <Col md={4} className='main-color-1 font-w-400'>{rowData['productQuantity']}</Col>
                    </Row>
                )
            })
        }
        return list
    }


    // https://cp8.easypay-card.com/API/daftra_Api.php?type=getDaftraProduct&
    // page=0&limit=3&userId=7421&token=413e972f492640456d37ec5dfc3637ad
    getProductList(catId, catName, withoutLoading) {
        if (withoutLoading != 1) {
            this.setState({ loading: 1 })
        }

        fetch(window.API_DAFTRA_API, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDaftraProduct',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'categoryId': catId,
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (withoutLoading != 1) {
                this.setState({ loading: 1 });
            }
            if (typeof data !== 'undefined') {
                // console.log('=============DDDDDD==================');
                // console.log(data);
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({
                        data: data['data'],
                        loading: 0,
                        catName: catName,
                        catId: catId,

                    })
                }
            }
        });
    }


    render() {
        return (
            <div>

                {this.state.loading == 1 &&
                    <Row>
                        <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                            <div className='card-body'>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                            </div>
                        </Col>
                    </Row>
                }

                {this.state.loading == 0 &&
                    <div>
                        <Row>
                            <Col md={6} className='main-color-1 inv-class-view-title'>
                                {/* <div>{this.state.catName}{this.state.catId}</div> */}
                                <div>{this.state.catName}</div>
                                <hr className='inv-hr-view-2' />
                            </Col>

                        </Row>

                        {this.state.loading == 0 && this.state.data.length <= 0 &&
                            <Row className='text-center' style={{ marginTop: "15%" }}>
                                <div className="col-md-12">
                                    <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                                    <div className='no-data-text'>{Strings["There is no data to display"]}</div>
                                </div>
                            </Row>
                        }
                        {this.state.loading == 0 && this.state.data.length > 0 &&
                            <Row>
                                <div className='inv-cards-view width-100'>
                                    <Row className='table-header-row'>
                                        <Col lg={4} md={4} sm={4} xs={12} className="overflow-text-length" title={Strings['Product Name']}>{Strings['Product Name']}</Col>
                                        <Col lg={4} md={4} sm={4} xs={12} className="overflow-text-length" title={Strings['Product Price']}> {Strings['Product Price']}</Col>
                                        <Col lg={4} md={4} sm={4} xs={12} className="overflow-text-length" title={Strings['Product Quantity']}> {Strings['Product Quantity']}</Col>

                                    </Row>
                                    {this.displayData()}
                                </div>
                            </Row>
                        }
                    </div>
                }



            </div >
        )
    }
}
export default DaftraProduct;
import React, { Component } from "react";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Drawer } from "@material-ui/core";
import CategoryForm from './forms/category';
import CategoryLangForm from './forms/categoryLangForm'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { translations } from '../../../translate.js';
import AddCoupons from "./forms/addCoupon";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import { func } from "prop-types";
import { set } from "lodash";
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;


class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMssage: "",
            selectedCategory: [],
            selectedCategoryId: '',
            searchKey: localStorage.getItem('LANG') == 1 ? "name" : "name_en",
            col: ((localStorage.getItem('USER_LEVEL') == 1 || localStorage.getItem('INV_MGR') == 1) || ((localStorage.getItem('PERM_TYPE') == 2 && localStorage.getItem('SHOW_COUPONS') == 1)||(this.props.permType==2&&this.props?.distData?.appearCoupons==1)))
                ? { 'lg': 8, 'md': 6, 'sm': 6, 'xs': 6 } : (localStorage.getItem('PERM_TYPE') == 1 || localStorage.getItem('PERM_TYPE') == 2||this.props.permType==2||this.props.permType==1) ? { 'lg': 12, 'md': 12, 'sm': 12, 'xs': 12 } : { 'lg': 11, 'md': 11, 'sm': 11, 'xs': 11 },
            openleftModal: false,
            openEditCaegoryDataModal: false,
            categoryData: [], // used when open modal
            data: [],
            cardsData: [],
            cardType: '',
            search: '',
            searchVal: '',
            showFilter: '',
            levelId: '',
            searchListLevel: [{ "level": 1, "name": Strings["Categories"] }, { "level": 2, "name": Strings["Cards"] }, { "level": 3, "name": Strings["Denominations"] }],
            selectedSearchLevel: [],
            openHeader: 0
        }
    }


    getCategoryList() {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCategories',
                'inventory': '1',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'mainDist': (this.props.mainDist != 1 && this.props.mainDist != 2) ? '' : this.props.mainDist,
                'posId': (this.props.mainDist == 2) ? this.props.posId : '',
                distId:this.props.distId?this.props.distId:'',
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            this.setState({ loading: 1 });
            if (typeof data !== 'undefined') {
                // console.log('===============================');
                // console.log(data);

                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({
                        data: data,
                        search: ''
                    }, function () {
                        var selectedCategory = []
                        var selectedCategoryId = ''
                        if (this.state.data.length > 0) {
                            selectedCategory = this.state.data[0]
                            selectedCategoryId = this.state.data[0]['id']

                            this.setState({ selectedCategory: selectedCategory, selectedCategoryId: selectedCategoryId })
                        }
                        this.props.loadPageAction(this.state.data.length, selectedCategoryId, selectedCategory)
                    })
                }
            }
        });
    }

    getCategoryCards(selectedCategory, search = 0, searchData = []) {
        this.setState({
            selectedCategory: selectedCategory,
            selectedCategoryId: selectedCategory.id
        }, function () {
            this.props.changeCategoryCards(selectedCategory.id, selectedCategory, search, searchData)
        })
    }

    openCouponsCardsSidebarModal = (event, data, type) => {
        // console.log(data);
        event.preventDefault();

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({
            cardsData: data,
            cardType: type,
            openCouponsleftModal: true
        }, function () {

        })
    }
    handleCloseCouponsSideBar = () => {
        this.setState({ openCouponsleftModal: false })
    }
    displayData() {
        var list = ''
        var search = ((localStorage.getItem("USER_LEVEL") == 1 || localStorage.getItem("INV_MGR") == 1)&&!this.props.distId) ? ((this.state.levelId == 1) ? this.state.search : '') : this.state.search;
        if (this.state.data.length > 0) {
            var list = this.state.data.filter(items => { return items[this.state.searchKey].toLowerCase().includes(search.toLowerCase().trim()) }).map((rowData, index) => {
                // list = this.state.data.map((rowData, index) => {
                return (
                    <div className={'inv-category-box ' + (this.state.selectedCategoryId == rowData.id && 'inv-selected-cat-box')} key={index}>
                        <Row>
                            <Col md={9} xs={9} lg={9} sm={9}>
                                <div className='inv-category-box-title main-color-1' title={(localStorage.getItem('LANG') == 1 ? rowData.name : rowData.name_en)} onClick={() => this.getCategoryCards(rowData)}>
                                    {(localStorage.getItem('LANG') == 1 ? rowData.name : rowData.name_en)}
                                </div>
                            </Col>
                            <Col md={3} xs={3} lg={3} sm={3} className='text-right d-inline inv-category-box-action-btn'>
                                {(((localStorage.getItem('PERM_TYPE') != 2||(this.props.permType!=2&&this.props.permType!=undefined)) && this.props.mainDist != 1 && this.props.mainDist != 2 && ((localStorage.getItem('PERM_TYPE') != 3)||(this.props.permType!=3&&this.props.permType!=undefined)))) && <i className='flaticon2-writing mr-3' title={Strings['Edit']} onClick={(event) => this.openSidebarModal(event, 'edit', rowData)} />}
                                {(((localStorage.getItem('PERM_TYPE') != 2||(this.props.permType!=2&&this.props.permType!=undefined)) && this.props.mainDist != 1 && this.props.mainDist != 2 && ((localStorage.getItem('PERM_TYPE') != 3)||(this.props.permType!=3&&this.props.permType!=undefined)))) && <i className='flaticon2-cancel-music' title={Strings['Delete']} onClick={(event) => this.deleteCardCategory(event, rowData.id)} />}
                                {(localStorage.getItem('PERM_TYPE') == 2||this.props.permType==2) && <i className='flaticon2-writing' title={Strings['Edit']} onClick={(event) => this.openEditCaegoryDataModal(event, 'edit', rowData)} />}
                                {((localStorage.getItem('PERM_TYPE') == 2 && localStorage.getItem('SHOW_COUPONS') == 1)||(this.props.permType==2&&this.props?.distData?.appearCoupons==1)) &&
                                    < i className='flaticon-price-tag ml-2' title={Strings['Coupons']} onClick={(event) => this.openCouponsCardsSidebarModal(event, rowData, "3")} />
                                }
                            </Col>
                        </Row>
                    </div>
                )
            })
        }
        return list
    }

    deleteCardCategory = (event, id) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <div className="mb-3">
                            <span className="alert-delete-icon">!</span>
                        </div>
                        <h5>{Strings['Are you sure ?']}</h5>
                        <p>{Strings['You want to delete this category ?']}</p>
                        <button type="button" className="btn form-cancel-btn alert-btn-margin ml-2 mr-2" onClick={onClose}>{Strings.Cancel}</button>
                        <button type="button" className="btn form-save-btn"
                            onClick={() => {
                                this.handleClickDelete(id);
                                onClose();
                            }}
                        >
                            {Strings.Yes}
                        </button>
                    </div>
                );
            }
        });
    }

    handleClickDelete(id) {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'deleteCardCat',
                'catId': id,
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                distId:this.props.distId?this.props.distId:'',

            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.getCategoryList()
                }
            }
        })
    }

    openSidebarModal = (event, type, data) => {
        event.preventDefault();

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({
            categoryData: data,
            status: type,
            openleftModal: true
        }, function () {

        })
    }

    openEditCaegoryDataModal = (event, type, data) => {
        event.preventDefault();

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({
            categoryData: data,
            status: type,
            openEditCaegoryDataModal: true
        }, function () {

        })
    }

    handleCloseSideBar = () => {
        this.setState({ openleftModal: false })
    }

    handleCloseEditCategorySideBar = () => {
        this.setState({ openEditCaegoryDataModal: false })
    }

    handleFormAction = () => {
        this.setState({
            categoryData: [],
            status: '',
            openleftModal: false
        }, function () {
            this.getCategoryList()
        })
    }

    handleLangFormAction = () => {
        this.setState({
            categoryData: [],
            status: '',
            openEditCaegoryDataModal: false
        }, function () {
            this.getCategoryList()
        })
    }
    handleSearch = (event, inputName = "search") => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value,
            errorMssage: ""
        }, function () {
            console.log("value");
            console.log(value);
            console.log(this.state.levelId);
            if (value != '')
                if ((inputName == "searchVal" && this.state.levelId != '')
                    || (((localStorage.getItem("USER_LEVEL") == 1 || localStorage.getItem("INV_MGR") == 1)&&!this.props.distId) && inputName == "search" && this.state.levelId != 1 && this.state.levelId != '')) {
                    this.getSearchData()
                } else if (inputName == "searchVal" || (((localStorage.getItem("USER_LEVEL") == 1 || localStorage.getItem("INV_MGR") == 1)&&!this.props.distId) && inputName == "search" && this.state.levelId == '')) {
                    this.setState({ errorMsq: 1 })
                }
        });
    }
    

    getSearchData = () => {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCardClassByClassId',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'catId': this.state.levelId == 1 ? this.state.searchVal : "",
                'cardId': this.state.levelId == 2 ? this.state.searchVal : "",
                'classId': this.state.levelId == 3 ? this.state.searchVal : "",
                'name': this.state.search,
                'level': this.state.levelId,
                distId:this.props.distId?this.props.distId:'',

            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'undefined') {
                // console.log(data);
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 1) {
                    this.setState({ errorMssage: data["errorMsg"] })
                } else {
                    var res = data["data"]
                    var catList = res["cardCategory"]['list']
                    var cardList = res["cardCategory"]["cards"]['list']
                    var cardClass = res["cardCategory"]["cards"]["cardClass"]

                    var searchData = []
                    searchData["cat"] = []
                    searchData["card"] = []
                    searchData["cardClass"] = []

                    if (catList != undefined && catList.length > 0)
                        searchData["cat"] = catList
                    if (cardList != undefined && cardList.length > 0)
                        searchData["card"] = cardList
                    if (cardClass != undefined && cardClass.length > 0)
                        searchData["cardClass"] = cardClass
                    this.setState({ errorMssage: "" })
                    if (searchData["cat"].length > 0) {
                        this.setState({ data: searchData["cat"] }, function () {
                            if (searchData["card"].length > 0) {
                                this.getCategoryCards(searchData["cat"][0], 1, searchData)
                            }
                        })
                    }
                }
            }
        });
    }
    handleFilter = (status) => {
        this.setState({ showFilter: status })
    }
    handleRefersh = () => {
        this.setState({
            search: '',
            levelId: '',
            searchVal: '',
            errorMsq: '',
            errorMssage: "",
            selectedSearchLevel: []
        }, function () {
            this.getCategoryList()
        })
    }
    displayFilter = () => {
        if (this.state.showFilter == 1) {
            return (
                <Col xs={6} lg={7} md={6} sm={12}>
                    <div onClick={(event) => this.handleFilter(0)}>
                        {Strings["Hide Filter"]} <SearchRoundedIcon className=" font-size-15" ></SearchRoundedIcon>
                    </div>
                </Col>

            )
        } else {
            return (
                <Col xs={6} lg={7} md={6} sm={12}>
                    <div onClick={(event) => this.handleFilter(1)}>
                        {Strings["Show Filter"]} <SearchRoundedIcon className="main-color-2 font-size-15" ></SearchRoundedIcon>
                    </div>
                </Col>

            )
        }
    }
    handleChangeSelect = (type, option) => {
        this.setState(state => {
            return {
                [type]: option,
                levelId: option.level,
                errorMsq: '',
                searchVal: "",
                errorMssage: ""
            }
        })
    }
    displayFilterData = () => {
        if (this.state.showFilter == 1)
            return (
                <Col xs={12} lg={12} md={12} sm={12} className="mt-2" >
                    <AvForm ref={c => (this.form = c)} >
                        <div className=' member-searchable-list filter-inv  mb-2'>
                            <Picky
                                value={this.state.selectedSearchLevel}
                                options={this.state.searchListLevel}
                                onChange={this.handleChangeSelect.bind(this, 'selectedSearchLevel')}
                                open={false}
                                valueKey={"level"}
                                labelKey={"name"}
                                multiple={false}
                                includeSelectAll={false}
                                includeFilter={false}
                                dropdownHeight={200}
                                placeholder={Strings['Search In']}
                                filterPlaceholder={Strings['Select Search In']}
                                selectAllText={Strings["Select All"]}
                                className={this.state.errorMsq == 1 ? 'border border-danger rounded' : ''}
                                allSelectedPlaceholder={Strings["%s selected"]}
                                manySelectedPlaceholder={Strings["%s selected"]}
                                clearFilterOnClose={true}
                            />
                        </div>
                        <AvField name="searchVal" type='text' placeholder={Strings['Search for Number']} value={this.state.searchVal} className=' search-input-cat ' onChange={(event) => this.handleSearch(event, "searchVal")} />
                        <AvField name="search" type='text' placeholder={Strings['Search For Name']} value={this.state.search} className=' search-input-cat ' onChange={(event) => this.handleSearch(event)} />
                    </AvForm>
                    {this.state.errorMssage != '' &&
                        <div className='msg-error'><i className='flaticon2-exclamation error-icon-small mr-1' />{this.state.errorMssage}</div>
                    }
                </Col>
            )

    }
    render() {
        // console.log('this.props');

        // console.log(this.props);ml-1
        return (
            <div>
                {this.state.data.length > 0 &&
                    <div className='inv-category-view'>
                        {/* {localStorage.getItem('USER_LEVEL') == 1 &&
                            < div className='inv-category-btn-view'>
                                <button className="btn gradient-button-view gradient-button gradient-button-font-12 ml-1 mb-3" onClick={(event) => this.openSidebarModal(event, 'add', '')}>
                                    {Strings['Add Category']}
                                </button>
                            </div>
                        } */}
                        <Row>
                            <Col md={12}>
                                <Row className=" inv-category-btn-view">
                                    <Col xs={this.state.col.xs} lg={this.state.col.lg} md={this.state.col.md} sm={this.state.col.sm} >
                                        <div className={' mb-3 main-color-1 pb-3 inv-cat-main-title mt-2   ' + ((localStorage.getItem('PERM_TYPE') != 1||(this.props.permType!=undefined&&this.props.permType!=1)) ? " margin-15px algin-float" : "")}>
                                            {Strings['Categories']}
                                        </div>
                                    </Col>
                                    {((localStorage.getItem("USER_LEVEL") == 1 || localStorage.getItem("INV_MGR") == 1)&&!this.props.distId) &&
                                        <Col xs={6} lg={4} md={6} sm={6} >
                                            <button className="btn gradient-button-view gradient-button gradient-button-font-12  mb-3 min-width mr-8px" onClick={(event) => this.openSidebarModal(event, 'add', '')}>
                                                {Strings['Add']}
                                            </button>
                                            {/* <AddCircleIcon/> */}
                                            {/* < i className='flaticon2-add' title={Strings['Coupons']} /> */}
                                        </Col>
                                    }
                                    {((localStorage.getItem('PERM_TYPE') == 2 && localStorage.getItem('SHOW_COUPONS') == 1)||(this.props.permType==2&&this.props?.distData?.appearCoupons==1)) &&
                                        <Col xs={6} lg={4} md={6} sm={6} className='action-icon-view actionDropdownList mt-2'>
                                            <button className="btn gradient-button-view gradient-button gradient-button-font-12  mb-3 min-width mr-8px" onClick={(event) => this.openCouponsCardsSidebarModal(event, this.state.data, "4")}>
                                                {Strings['Coupons']}
                                            </button>
                                            {/* < i className='flaticon-price-tag ml-2 ' title={Strings['Coupons']} onClick={(event) => this.openCouponsCardsSidebarModal(event, this.state.data, "4")} /> */}
                                        </Col>
                                    }
                                </Row>
                            </Col>
                            <Col md={12} className={((localStorage.getItem("USER_LEVEL") == 1 || localStorage.getItem("INV_MGR") == 1)&&!this.props.distId)? "filter-box-inv-style " : ""}>
                                {((localStorage.getItem("USER_LEVEL") == 1 || localStorage.getItem("INV_MGR") == 1)&&!this.props.distId) ?
                                    <Row className=" cat-search-div-filter  main-color-1 mb-2 filter-box-style-inv ">
                                        <Col xs={12} lg={12} md={12} sm={12} >
                                            <Row>
                                                {this.displayFilter()}
                                                <Col xs={6} lg={5} md={6} sm={12} className="filter-icon-inv" >
                                                    <div onClick={(event) => this.handleRefersh()}>
                                                        {Strings["Clear Filter"]} <RefreshRoundedIcon className=" font-size-15" ></RefreshRoundedIcon>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {this.displayFilterData()}
                                    </Row>
                                    :
                                    <Row className=" cat-search-div search-cat">
                                        <Col xs={12} lg={12} md={12} sm={12} >
                                            <AvForm ref={c => (this.form = c)} >
                                                <AvField name="search" type='text' placeholder={Strings['Search For Name']} value={this.state.search} className=' search-input-cat ' onChange={(event) => this.handleSearch(event)} />
                                            </AvForm>
                                        </Col>
                                    </Row>
                                }

                            </Col>
                        </Row>

                        <div className='inv-cat-list-height'>
                            {this.displayData()}
                        </div>

                        <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.openleftModal} className='sidebar-modal-view'>
                            <CategoryForm handleCloseSideBar={this.handleCloseSideBar} handleFormAction={this.handleFormAction} status={this.state.status} categoryData={this.state.categoryData} />
                        </Drawer>

                        <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.openEditCaegoryDataModal} className='sidebar-modal-view'>
                            <CategoryLangForm handleCloseSideBar={this.handleCloseEditCategorySideBar} handleFormAction={this.handleLangFormAction} status={this.state.status} categoryData={this.state.categoryData} />
                        </Drawer>

                        <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.openCouponsleftModal} className='sidebar-modal-x-large-view'>
                            <AddCoupons handleCloseSideBar={this.handleCloseCouponsSideBar} cardType={this.state.cardType == "3" ? "3" : "4"} cardId={this.state.cardsData['id']} cardsData={this.state.cardsData} />
                        </Drawer>
                    </div >
                }
            </div>

        )
    }
}
export default Category;
import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { translations } from "../../../../translate.js";
import { atelierEstuaryDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import DeleteIcon from "@material-ui/icons/Delete";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class CategoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      alertMsg: "",
      deleteImage: 0,
      submitError: "",
      internationalCallsTypeList: [],
      catTypeList: [],
      directChargeTypeList: [],
      directChargeTypeSelected: [],
      directChargeCountriesList: [],
      directChargeCountriesSelected: [],
      name: this.props.status == "add" ? "" : this.props.categoryData["name"],
      name_en:
        this.props.status == "add" ? "" : this.props.categoryData["name_en"],
      type: this.props.status == "add" ? "" : this.props.categoryData["type"],
      isMaster:
        this.props.status == "add" ? 0 : this.props.categoryData["isMaster"],
      isLearn:
        this.props.status == "add" ? 0 : this.props.categoryData["isLearn"],
      imageUrl:
        this.props.status == "add" ? "" : this.props.categoryData["imageUrl"],
      image: this.props.status == "add" ? "" : this.props.categoryData["image"],
      catId: this.props.status == "add" ? 0 : this.props.categoryData["id"],
      isFreePriceForDist:
        this.props.status == "add"
          ? 0
          : this.props.categoryData["isFreePriceForDist"],
      isDing:
        this.props.status == "add" ? 0 : this.props.categoryData["isDing"],
      isDirectCharge:
        this.props.status == "add"
          ? 0
          : this.props.categoryData["isDirectCharge"],
      isApi: this.props.status == "add" ? 0 : this.props.categoryData["isApi"],
      createQr:
        this.props.status == "add" ? 0 : this.props.categoryData["createQr"],
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  componentDidMount() {
    console.log(this.props.categoryData, "this.props.categoryData");
    this.getCatType();
    if (
      localStorage.getItem("USER_LEVEL") == 1 ||
      localStorage.getItem("INV_MGR") == 1
    ) {
      this.getDirectChargeType();
      this.getDirectChargeCountries();
      this.getInternationalCallsType();
      if (
        this.props.status == "edit" &&
        this.state.isDirectCharge != "" &&
        this.state.isDirectCharge != 0
      ) {
        this.getCountryDirectChargeApi();
      }
    }
  }
  //############################################# start Api ###################################################
  handleValidSubmit(event) {
    event.preventDefault();
    var data = new FormData();
    var cardCatImage = document.querySelector("input[name=cardCatImage]")
      .files[0];
    var deletedImages = 0;
    if (this.state.deleteImage == 1) {
      deletedImages = "1";
    }
    // console.log('cardCatImage');
    // console.log(cardCatImage);

    data.append("data", cardCatImage);
    data.append("type", "addCardCat");
    data.append("userId", localStorage.getItem("USER_ID"));
    data.append("token", localStorage.getItem("USER_TOKEN"));
    data.append("lang", localStorage.getItem("LANG"));
    data.append("name", this.state.name);
    if (this.state.isDirectCharge == 0) {
      data.append("createQr", this.state.createQr ? 1 : 0);
    }
    data.append("name_en", this.state.name_en);
    data.append("catType", this.state.type);
    data.append("isLearn", this.state.isLearn == true ? 1 : 0);
    data.append("isMaster", this.state.isMaster == true ? 1 : 0);
    data.append("catId", this.state.catId);
    data.append("deletedImages", deletedImages);
    if (
      localStorage.getItem("USER_LEVEL") == 1 ||
      localStorage.getItem("INV_MGR") == 1
    ) {
      data.append(
        "isFreePriceForDist",
        this.state.isFreePriceForDist == true ? 1 : 0
      );
      data.append("isApi", this.state.isApi == true ? 1 : 0);
      data.append("isDirectCharge", this.state.isDirectCharge);
      data.append(
        "countryDirectCharge",
        JSON.stringify(
          this.state.directChargeCountriesSelected.map((data) => data.code)
        )
      );
      data.append("isDing", this.state.isDing);
    }
    fetch(window.API_PERM_USER, {
      // mode: 'no-cors',
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        if (!response.ok) {
          // console.log(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('===============================');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ submitError: data["errorMsg"] });
          } else {
            this.props.handleFormAction();
          }
        }
      });
  }
  getDirectChargeType = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "typesOfDirectCharge",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ directChargeTypeList: data });
          }
        }
      });
  };
  getCatType = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "typesOfCardCat",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ catTypeList: data });
          }
        }
      });
  };
  getInternationalCallsType = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "typeOfInternationalCalls",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ internationalCallsTypeList: data });
          }
        }
      });
  };
  getDirectChargeCountries = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "Countries",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ directChargeCountriesList: data });
          }
        }
      });
  };
  getCountryDirectChargeApi = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCountryDirectChargeApi",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        catId: this.state.catId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ directChargeCountriesSelected: data });
          }
        }
      });
  };
  //############################################# end Api ###################################################
  //############################################# start React ###################################################

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        alertMsg: "",
        submitError: "",
      },
      function() {
        if (name == "isDirectCharge") {
          this.setState({
            directChargeCountriesSelected: [],
            isApi: "",
            isDing: "",
          });
        } else if (name == "isDing") {
          this.setState({
            alertMsg: 1,
            directChargeCountriesSelected: [],
            isDirectCharge: "",
            isApi: "",
          });
        }
      }
    );
  };
  handleChangeSelect = (type, option) => {
    this.setState((state) => {
      return {
        [type]: option,
      };
    });
  };
  handleImgChange = (event) => {
    var fileReader = new FileReader();
    const scope = this;

    if (event.target.files[0].type.includes("image")) {
      this.setState({ displayErrorHeaderMsg: false });
      this.setState({ progressUploadHeader: 10 });
      fileReader.readAsBinaryString(event.target.files[0]);
      fileReader.onprogress = function(data) {
        if (data.lengthComputable) {
          var progress = parseInt((data.loaded / data.total) * 100, 10);
          scope.setState({ progressUploadHeader: progress });
        }
      };
    } else {
      this.setState({ displayErrorHeaderMsg: true });
    }

    this.setState({
      image: event.target.files[0]["name"],
    });
  };
  setUpSelectOptions = (type) => {
    var list = "";
    if (type == "isDirectCharge") {
      if (
        this.state.directChargeTypeList != null &&
        this.state.directChargeTypeList != undefined &&
        this.state.directChargeTypeList.length > 0
      ) {
        list = this.state.directChargeTypeList.map((rowData, index) => {
          return (
            <option value={rowData.id} key={index}>
              {rowData.name}
            </option>
          );
        });
      }
    } else if (type == "type") {
      if (
        this.state.catTypeList != null &&
        this.state.catTypeList != undefined &&
        this.state.catTypeList.length > 0
      ) {
        list = this.state.catTypeList.map((rowData, index) => {
          return (
            <option value={rowData.id} key={index}>
              {rowData.name}
            </option>
          );
        });
      }
    } else {
      if (
        this.state.internationalCallsTypeList != null &&
        this.state.internationalCallsTypeList != undefined &&
        this.state.internationalCallsTypeList.length > 0
      ) {
        list = this.state.internationalCallsTypeList.map((rowData, index) => {
          return (
            <option value={rowData.id} key={index}>
              {rowData.name}
            </option>
          );
        });
      }
    }
    return list;
  };

  deleteUploadImage = () => {
    this.setState({ deleteImage: 1 });
  };
  //############################################# end React ###################################################

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {this.props.status == "add" ? Strings["Add"] : Strings["Edit"]}
                {" "}
                {Strings["Category"]}
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <AvForm
            onValidSubmit={this.handleValidSubmit}
            ref={(c) => (this.form = c)}
          >
            <AvField
              name="name"
              label={Strings["Category Name"] + " *"}
              placeholder={Strings["Enter Category Name"]}
              value={this.state.name}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              name="name_en"
              label={Strings["Category Name In English"] + " *"}
              placeholder={Strings["Enter Category Name In English"]}
              value={this.state.name_en}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              type="select"
              name="type"
              label={Strings["Category Type"] + " *"}
              value={this.state.type}
              required
              onChange={this.handleInputChange}
              className="p-1 pl-3"
              errorMessage={Strings["This field is required"]}
            >
              {this.setUpSelectOptions("type")}
            </AvField>

            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) && (
              <AvField
                type="select"
                label={Strings["Direct Charging Type"]}
                name="isDirectCharge"
                value={this.state.isDirectCharge}
                onChange={(event) => this.handleInputChange(event)}
              >
                <option
                  style={{
                    display: "block",
                  }}
                  value=""
                  defaultValue
                >
                  {Strings["Select Direct Charging Type"]}
                </option>
                {this.setUpSelectOptions("isDirectCharge")}
              </AvField>
            )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) &&
              this.state.isDirectCharge != "" &&
              this.state.isDirectCharge != 0 && (
                <div className="member-searchable-list mb-4">
                  <label className="input-label">
                    {" "}
                    {Strings["Direct Charge Countries"]}{" "}
                  </label>
                  <Picky
                    value={this.state.directChargeCountriesSelected}
                    options={this.state.directChargeCountriesList}
                    onChange={this.handleChangeSelect.bind(
                      this,
                      "directChargeCountriesSelected"
                    )}
                    open={false}
                    valueKey="code"
                    labelKey="name"
                    multiple={true}
                    includeSelectAll={true}
                    includeFilter={true}
                    selectAllText={Strings["Select All"]}
                    allSelectedPlaceholder={Strings["%s selected"]}
                    manySelectedPlaceholder={Strings["%s selected"]}
                    clearFilterOnClose={true}
                    dropdownHeight={200}
                    placeholder={Strings["Direct Charge Countries"]}
                    filterPlaceholder={
                      Strings["Select Direct Charge Countries"]
                    }
                  />
                </div>
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) && (
              <div className="inter-Calls">
                <AvField
                  type="select"
                  label={Strings["Type of international calls"]}
                  name="isDing"
                  value={this.state.isDing}
                  onChange={(event) => this.handleInputChange(event)}
                >
                  <option
                    style={{
                      display: "block",
                    }}
                    value=""
                    defaultValue
                  >
                    {Strings["Select type of international calls"]}
                  </option>
                  {this.setUpSelectOptions("isDing")}
                </AvField>
              </div>
            )}
            {this.state.alertMsg != "" && this.state.isDing == 1 && (
              <div className="alert-msg mb-3">
                {
                  Strings[
                    "This category will be assigned to the Ding property and removed from all other categories"
                  ]
                }
              </div>
            )}
            {this.state.alertMsg != "" && this.state.isDing == 2 && (
              <div className="alert-msg mb-3">
                {
                  Strings[
                    "This category will be assigned to the Dtone property and removed from all other categories"
                  ]
                }
              </div>
            )}

            <AvField
              label={Strings["Educational Cards"]}
              type="checkbox"
              name="isLearn"
              ref="default"
              value={
                this.state.isLearn == 1 || this.state.isLearn == true
                  ? true
                  : false
              }
              onChange={this.handleInputChange}
              checked={
                this.state.isLearn == 1 || this.state.isLearn == true
                  ? true
                  : false
              }
              tag={CustomInput}
              className={
                localStorage.getItem("USER_LEVEL") == 1 ||
                localStorage.getItem("INV_MGR") == 1
                  ? "custom-form-mb default mb-3 mr-19px mt-3 "
                  : "custom-form-mb default mb-3 mr-19px"
              }
            />
            {this.props.status == "add" && (
              <AvField
                label={Strings.isMaster}
                type="checkbox"
                name="isMaster"
                ref="default"
                value={
                  this.state.isMaster == 1 || this.state.isMaster == true
                    ? true
                    : false
                }
                onChange={this.handleInputChange}
                checked={
                  this.state.isMaster == 1 || this.state.isMaster == true
                    ? true
                    : false
                }
                tag={CustomInput}
                className="custom-form-mb default mb-3 mr-19px"
              />
            )}
            {localStorage.getItem("USER_LEVEL") == 1 &&
              this.state.isDirectCharge == 0 && (
                <AvField
                  label={Strings["Print QR Code"]}
                  type="checkbox"
                  name="createQr"
                  ref="default"
                  value={
                    this.state.createQr == 1 || this.state.createQr == true
                      ? true
                      : false
                  }
                  onChange={this.handleInputChange}
                  checked={
                    this.state.createQr == 1 || this.state.createQr == true
                      ? true
                      : false
                  }
                  tag={CustomInput}
                  className="custom-form-mb default mb-3 mr-19px"
                />
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) && (
              <AvField
                label={Strings["Free Cards"]}
                type="checkbox"
                name="isFreePriceForDist"
                ref="default"
                value={
                  this.state.isFreePriceForDist == 1 ||
                  this.state.isFreePriceForDist == true
                    ? true
                    : false
                }
                onChange={this.handleInputChange}
                checked={
                  this.state.isFreePriceForDist == 1 ||
                  this.state.isFreePriceForDist == true
                    ? true
                    : false
                }
                tag={CustomInput}
                className="custom-form-mb default mb-3 mr-19px"
              />
            )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) &&
              this.state.isDirectCharge != "" &&
              this.state.isDirectCharge != 0 && (
                <AvField
                  label={Strings["Direct Charge Cards API"]}
                  type="checkbox"
                  name="isApi"
                  ref="default"
                  value={
                    this.state.isApi == 1 || this.state.isApi == true
                      ? true
                      : false
                  }
                  onChange={this.handleInputChange}
                  checked={
                    this.state.isApi == 1 || this.state.isApi == true
                      ? true
                      : false
                  }
                  tag={CustomInput}
                  className="custom-form-mb default mb-3 mr-19px"
                />
              )}

            <div className="mt-2 mb-2">
              <label>{Strings["Category Icon"]}</label>
              <span className={"fileInput-span col-md-12"}>
                <PublishIcon />{" "}
                <span className="pointer-cursor pt-3">{Strings["Upload"]}</span>
                <input
                  type="file"
                  accept="image/*"
                  className="fileInput-width fileInput-opacity"
                  onChange={(e) => this.handleImgChange(e)}
                  name="cardCatImage"
                ></input>
              </span>

              {this.state.cardCatImage != "" &&
                this.state.cardCatImage != undefined &&
                this.state.cardCatImage != null && (
                  <Row className="mt-2">
                    <Col md={12}>
                      <div className="">
                        <a
                          href={this.state.profileImgPath}
                          className="black-color"
                        >
                          <i className="far fa-file-image"></i>{" "}
                          {this.state.displayName}
                        </a>
                      </div>
                    </Col>
                  </Row>
                )}
              {this.state.progressUploadHeader != undefined &&
                this.state.progressUploadHeader > 0 && (
                  <Progress
                    striped
                    value={this.state.progressUploadHeader}
                    className="mb-1 mt-1 progress-bg-color"
                  >
                    {this.state.progressUploadHeader} %
                  </Progress>
                )}
              {this.state.displayErrorHeaderMsg == true && (
                <div className="invalid-feedback d-block mb-1">
                  {Strings["Upload Image only"]}
                </div>
              )}

              {this.state.imageUrl != null &&
                this.state.imageUrl != undefined &&
                this.state.imageUrl != "" &&
                this.state.image != null &&
                this.state.image != undefined &&
                this.state.image != "" &&
                this.state.deleteImage == 0 && (
                  <div className="text-center mt-4">
                    <img
                      src={this.state.imageUrl}
                      className="img-fluid inv-category-img"
                    />
                    <a
                      href={this.state.imageUrl}
                      download
                      className="inv-category-img-title overflow-text-length"
                      target="_blank"
                    >
                      {this.state.image}
                    </a>
                    <div
                      className="task-action-file-details-delete-icon"
                      onClick={() => this.deleteUploadImage("3")}
                    >
                      <span>
                        <DeleteIcon /> {Strings["Delete"]}
                      </span>
                    </div>
                  </div>
                )}
            </div>

            <div className="text-right mt-4">
              <button type="submit" className="btn form-save-btn mr-1 ml-1">
                {" "}
                {Strings["Save"]}{" "}
              </button>
              <button
                type="button"
                className="btn form-cancel-btn m-l-10"
                onClick={this.props.handleCloseSideBar}
              >
                {Strings["Cancel"]}
              </button>
            </div>

            {this.state.submitError && (
              <div className="error-text-black">
                <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                {this.state.submitError}
              </div>
            )}
          </AvForm>
        </div>
      </div>
    );
  }
}
export default CategoryForm;

import React, { Component } from "react";
import { UncontrolledDropdown, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import { Link } from 'react-router-dom'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Switch from '@material-ui/core/Switch';
import { Drawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CachedIcon from '@material-ui/icons/Cached';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import BallotIcon from '@material-ui/icons/Ballot';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PosMainAccountForm from './forms/posMainAccountForm'
import AddOrGetAmount from './forms/addOrGetAmount'
import AddDenomination from './forms/addDenomination'
import AddNotifications from './forms/addNotifications'
import SortCategories from './forms/sortCategories'
import { translations } from '../../../translate.js';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class Pos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openLeftModal: false,
            propsData: {},
            userData: {},
            column: localStorage.getItem('PERM_TYPE') == 5 ? 4 : 6,
            linkTo: localStorage.getItem('PERM_TYPE') == 5 ? "/posEmp/1" : "/posEmp/0"
        }
    }

    componentDidMount() {
        this.getAccountData()
    }

    getAccountData() {

        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getMainDistInfo',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN')
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({
                        userData: data['data']
                    })
                }
            }
        })
    }



    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }

    openSidebarModal = (event, type, data, viewMode) => {
        event.preventDefault();
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({
            propsData: data,
            actionType: type,
            viewMode: viewMode,
            openleftModal: true
        })
    }

    handleCloseSideBar = (res = '', error = 0) => {
        this.setState({ openleftModal: false, propsData: [], actionType: '', viewMode: '' })
    }


    handleFormAction = (type) => {
        this.setState({
            propsData: [],
            status: '',
            openleftModal: false,
            viewMode: ''
        }, function () {
            if (type == 'edit') {
                this.getAccountData()
            }
        })
    }

    AuthenticationReset = (event) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <div className="mb-3">
                            <span className="alert-delete-icon">!</span>
                        </div>
                        <h5>{Strings['Are you sure ?']}</h5>
                        <p>{Strings['You want to  Reset Authentication ?']}</p>
                        <button type="button" className="btn form-cancel-btn alert-btn-margin ml-2 mr-2" onClick={onClose}>{Strings.Cancel}</button>
                        <button type="button" className="btn form-save-btn"
                            onClick={() => {
                                this.handleClickAuthenticationReset();
                                onClose();
                            }}
                        >
                            {Strings.Yes}
                        </button>
                    </div>
                );
            }
        });
    }

    handleClickAuthenticationReset() {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'resetAuth',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'posId': 0
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                }
            }
        })
    }


    render() {

        return (
            <div>
                <Row>
                    <Col lg={4} md={6} className="mb-2">
                        <div className='pos-main-profile'>
                            <div className='text-center'>
                                <img alt="Profile" src={toAbsoluteUrl("/media/ezpay/user.png")} className='img-fluid' />
                            </div>

                            <Row className='mb-3 mt-3'>
                                <Col md={12} className='mb-1 main-color-1 font-w-400'><PermIdentityIcon /> {Strings['Name']}</Col>
                                <Col md={12} className='black-font ml-4 pl-3'>{this.state.userData.fname}</Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={12} className='mb-1 main-color-1 font-w-400'><MailOutlineIcon /> {Strings['Email']}</Col>
                                <Col md={12} className='black-font ml-4 pl-3'>{this.state.userData.userEmail}</Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={12} className='mb-1 main-color-1 font-w-400'><PhoneAndroidIcon /> {Strings['Phone']}</Col>
                                <Col md={12} className='black-font ml-4 pl-3'>{this.state.userData.mobile}</Col>
                            </Row>

                            <Row className='text-center mt-4 mb-3 pos-main-profile-main-btn-view'>
                                <Col md={4} lg={4} sm={4} xs={4}>
                                    <div className='pos-main-profile-main-btn pos-main-profile-main-btn-gradient' onClick={(event) => this.openSidebarModal(event, 'edit', this.state.userData)}>
                                        {Strings['Edit']}
                                    </div>
                                </Col>
                                <Col md={8} lg={8} sm={8} xs={8}>
                                    <div className='pos-main-profile-main-btn pos-main-profile-main-btn-gradient' onClick={(event) => this.AuthenticationReset(event)}>
                                        {Strings['Authentication Reset']}
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </Col>
                    <Col lg={8} md={6} className="mb-2">
                        <Row>
                            {localStorage.getItem('PERM_TYPE') == 5 &&
                                <Col md={12} lg={6} sm={12} xs={12}>
                                    <div className='pos-main-profile-section-box main-color-1' onClick={(event) => this.openSidebarModal(event, 'addBalance', [])}>
                                        <div>
                                            <AccountBalanceWalletIcon className='pos-main-profile-section-box-icon' />
                                        </div>
                                        <div>{Strings['Add Balance']}</div>
                                    </div>
                                </Col>
                            }
                            {localStorage.getItem('PERM_TYPE') == 5 &&
                                <Col md={6} lg={6} sm={12} xs={12}>
                                    <div className='pos-main-profile-section-box main-color-1' onClick={(event) => this.openSidebarModal(event, 'sortCategories', [], "large")}>
                                        <div>
                                            <ImportExportIcon className='pos-main-profile-section-box-icon' />
                                        </div>
                                        <div>{Strings['Sort']}</div>
                                    </div>
                                </Col>
                            }

                          {localStorage.getItem('PERM_TYPE') == 5 && <Col md={6} lg={this.state.column} sm={12} xs={12}>
                                <Link to={this.state.linkTo}>
                                    <div className='pos-main-profile-section-box main-color-1'>
                                        <div>
                                            <GroupAddIcon className='pos-main-profile-section-box-icon' />
                                        </div>
                                        <div>{Strings['Employees']}</div>
                                    </div>
                                </Link>
                            </Col>}
                            {localStorage.getItem('PERM_TYPE') != 5 &&
                                <Col md={6} lg={this.state.column} sm={12} xs={12}>
                                    <Link to="/inventory/1">
                                        <div className='pos-main-profile-section-box main-color-1'>
                                            <div>
                                                <CalendarViewDayIcon className='pos-main-profile-section-box-icon' />
                                            </div>
                                            <div>{Strings['Cards']}</div>
                                        </div>
                                    </Link>
                                </Col>
                            }
                            <Col md={6} lg={this.state.column} sm={12} xs={12}>
                                <div className='pos-main-profile-section-box main-color-1'>
                                    <div>
                                        <NotificationsActiveIcon className='pos-main-profile-section-box-icon' onClick={(event) => this.openSidebarModal(event, 'addNotifications', [], 'large')} />
                                    </div>
                                    <div>{Strings['Notifications']}</div>
                                </div>
                            </Col>

                            <Col md={6} lg={this.state.column} sm={12} xs={12}>
                                <div className='pos-main-profile-section-box main-color-1' onClick={(event) => this.openSidebarModal(event, 'addDenomination', [], 'large')}>
                                    <div>
                                        <BallotIcon className='pos-main-profile-section-box-icon' />
                                    </div>
                                    <div>{Strings['Classes']}</div>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>

                <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.openleftModal} className={this.state.viewMode == 'large' ? 'sidebar-modal-large-view' : 'sidebar-modal-view'}>
                    {this.state.actionType == 'edit' &&
                        <PosMainAccountForm
                            propsData={this.state.propsData}
                            handleCloseSideBar={this.handleCloseSideBar}
                            handleFormAction={this.handleFormAction} />
                    }

                    {this.state.actionType == 'addBalance' &&
                        <AddOrGetAmount
                            handleCloseSideBar={this.handleCloseSideBar}
                            handleFormAction={this.handleFormAction}
                            mainAccount='1' />
                    }

                    {this.state.actionType == 'sortCategories' &&
                        <SortCategories
                            handleCloseSideBar={this.handleCloseSideBar}
                            handleFormAction={this.handleFormAction} />
                    }

                    {this.state.actionType == 'addDenomination' &&
                        <AddDenomination
                            handleCloseSideBar={this.handleCloseSideBar}
                            handleFormAction={this.handleFormAction} />
                    }

                    {this.state.actionType == 'addNotifications' &&
                        <AddNotifications
                            handleCloseSideBar={this.handleCloseSideBar}
                            handleFormAction={this.handleFormAction} />
                    }
                </Drawer>
            </div>
        )
    }
}
export default Pos;

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from '../../../../translate.js';
import { CircularProgress } from "@material-ui/core";
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;



class AddNumnyAndEgifterBalance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transValue: '',
            startSave: 0,
            transName: '',
            status: '',
            apiDataTrans: [],
            numberCode: 0,
            numberEmptyCode: 0,
            alreadyExsitsCode: 0,
            distId:this.props.distId?this.props.distId:''
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value,
            submitError: ''
        })
    }


    handleValidSubmit(event) {
        this.setState({
            startSave: 1,
            status: '',
            numberCode: 0,
            numberEmptyCode: 0,
            alreadyExsitsCode: 0,
            transValue: '',
            transName: '',
            submitError: '',
        })
        event.preventDefault();
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'addCardInventoryAPI',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'apiType': this.props.cardsCalssData.typeInvReact,
                'cardClassId': this.props.cardsCalssData.id,
                'qty': this.state.qty,
                distId:this.state.distId,
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 1) {
                    this.setState({ submitError: data['errorMsg'], startSave: 0 })
                } else {
                    this.setState({
                        status: data['status'],
                        alreadyExsitsCode: data['alreadyExsitsCode'],
                        numberEmptyCode: data['numberEmptyCode'],
                        numberCode: data['numberCode'],
                        transValue: '',
                        transName: '',
                        submitError: '',
                        startSave: 0
                    })
                    if ((this.state.status && this.state.numberCode > 0)) {
                        this.props.recallGetData()
                    }
                    if (data['transactionApiData'] != undefined && Object.keys(data['transactionApiData']).length > 0) {
                        this.setState({
                            transValue: (data['transactionApiData']["value"] != undefined && data['transactionApiData']["value"] != null && data['transactionApiData']["value"] != '') ? data['transactionApiData']["value"] : "",
                            transName: (data['transactionApiData']["name"] != undefined && data['transactionApiData']["name"] != null && data['transactionApiData']["name"] != '') ? data['transactionApiData']["name"] : "",
                        })
                    }
                }
            }
        })
    }


    render() {
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col lg={10} md={10} sm={10} xs={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'>
                                {this.props.formName}
                                ({localStorage.getItem('LANG') == 1 ? this.props.cardsCalssData.name : this.props.cardsCalssData.name_en})
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={2} xs={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>

                <div className='sidebar-body-view'>
                    <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                        {/* <AvField name="className" label={Strings['Class Name']} disabled value={this.props.cardsCalssData.name} /> */}
                        <AvField name="qty" label={Strings['Quantity'] + ' *'} placeholder={Strings['Enter Quantity']} value={this.state.qty} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                        {this.state.transValue != '' &&
                            <AvField name="transValue" label={this.state.transName} disabled value={this.state.transValue} />
                        }
                        <div className='text-right mt-4'>
                            {this.state.startSave == 1 ?
                                <button type="button" className="btn form-save-btn "> <CircularProgress className="SaveCircularProgressColor" />{Strings['Save']} </button>
                                :
                                <button type="submit" className="btn form-save-btn mr-1 ml-1"> {Strings['Save']} </button>
                            }
                            <button type="button" className="btn form-cancel-btn m-l-10" onClick={this.props.handleCloseSideBar}>{Strings['Cancel']}</button>
                        </div>

                        {this.state.submitError &&
                            <div className='error-text-black'><i className='flaticon2-exclamation error-icon mr-1' /> {this.state.submitError}</div>
                        }

                        {(this.state.status && this.state.numberCode > 0) &&
                            <div className='error-text-black'><i className={'flaticon2-check-mark success-icon mr-1'} /> {this.state.status}</div>
                        }
                        {(this.state.status && this.state.numberCode == 0) &&
                            <div className='error-text-black'><i className={'flaticon2-exclamation error-icon warning-color mr-1'} /> {this.state.status}</div>
                        }
                        {this.state.alreadyExsitsCode != 0 &&
                            <div className='error-text-black mt-2'><i className='flaticon2-exclamation error-icon mr-1' />{Strings['Number of codes already exist:']}  ({this.state.alreadyExsitsCode})</div>
                        }
                        {this.state.numberEmptyCode != 0 &&
                            <div className='error-text-black mt-2'><i className='flaticon2-exclamation error-icon mr-1' />{Strings['Number of codes that do not contain a PIN or that there was a problem when requesting it:']}  ({this.state.numberEmptyCode})</div>
                        }

                    </AvForm>
                </div>
            </div>
        )
    }
}
export default AddNumnyAndEgifterBalance;
import React, { Component } from "react";
import { Row, Col, Label, CustomInput, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AvForm, AvField, } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { translations } from '../../../../translate.js';
import { Picky } from "react-picky";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { toAbsoluteUrl } from "../../../../_metronic";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CircularProgress from "@material-ui/core/CircularProgress";
import { isThisWeek } from "date-fns";

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;


class AddOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitError: '',
            cardLength: 0,
            countOrder: 0,
            cardClassLength: 0,
            passwordView: 1,
            loading: 0,
            selectedCardRating: [],
            cardRatingList: [],
            orderTotalAmount: '',
            orderTotalAfterDisc: '',
            discountTypeList: [],
            selectedDiscountType: '',
            discountValue: 0,
            haveDiscount: false,
            countryList: [],
            selectedCountry: '',
            countryList: [],
            selectedCompanyName: '',
            beneficiaryList: [],
            selectedCurrency: '',
            currencyList: [],
            selectedBank: '',
            bankList: [],
            orderDate: '',
            orderTime: '',
            inoivceDateFrom: '',
            inoivceDateTo: '',
            selectedCompany: '',
            companyList: [],
            note: '',
            showPass: false,
            passInvoce: '',
            cardsList: [],
            catId: '',
            cardId: '',
            distId: '',
            classList: [],
            typeOfCard: '',
            classQty: [],
            passwordView: 1, // 1 : hide , 2 show [ in step 3 ]
            errorInSelectedClasses: 0,
            classdistPrice: [],
            orderList: [],
            orderId: 0,
            showHaveDiscount: false,
            countRequest: '',
            loadingSave: 0,
            cardClass: [],
            className: [],
            deleted: 0,

        }
    }

    componentDidMount() {
        this.getDistCardCategory()
        this.getCountriesBanks()
        this.getDiscountType()
        this.getCompanies()
        document.addEventListener('mousedown', this.handleClickOutside);

        // console.log(this.props.data);
    }
    handleClickOutside = (event) => {
        if (event.target.className != undefined) {
            if (!event.target.classList.contains('actionDropdownList')) {
                this.setState({
                    openHeader: ''
                })
            }
        }
    }

    getCompanies = () => {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCompanies',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    // console.log(data);
                    this.setState({ companyList: data['data'], loading: 1, }, function () {


                    })
                }
            }
        })
    }
    getDistCardCategory = () => {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDistCardCategory',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'distId': this.props.data.userId,
                'cartId': this.props.data.id,
                'cardCategoryType': '2',

            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    // console.log(data);
                    this.setState({ cardRatingList: data['data'], loading: 1, }, function () {


                    })
                }
            }
        })
    }

    getDistCards = () => {
        this.setState({ loading: 2 })
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDistCards',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'distId': this.state.distId,
                'catId': this.state.catId,
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {

                    this.setState({ cardsList: data['data'], loading: 1 }, function () {
                        var length = this.state.cardsList
                        this.setState({ cardLength: length })
                    })
                }
            }
        })
    }

    getDistCardClass = () => {
        this.setState({ loading: 3 })
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDistCardClass',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'distId': this.props.data.userId,
                'catId': this.state.catId,
                'cardId': this.state.cardId,
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {

                    this.setState({
                        classList: data['data']['typeOfCard'] == 1 ? [] : data['data']['cardClass'],
                        distCurrency: data['data']['distCurrency'],
                        typeOfCard: data['data']['typeOfCard'],
                        loading: 1
                    }, function () {
                        var length = this.state.classList
                        this.setState({ cardClassLength: length })
                    })
                }
            }
        })
    }
    getCountriesBanks = () => {
        this.setState({ loading: 0 })
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCountriesBanks',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({ countryList: data['data'], loading: 1, }, function () {


                    })
                }
            }
        })
    }

    getBanksByCountry = () => {

        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getBanksByCountry',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'selectedCountryCode': this.state.selectedCountry,
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({ beneficiaryList: data['data'], loading: 1, }, function () {

                    })
                }
            }
        })
    }

    getCompanyCurrencies = () => {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCompanyCurrencies',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'selectedCompany': this.state.selectedCompanyName,
                'selectedCountry': this.state.selectedCountry,
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({ currencyList: data['data'], loading: 1, }, function () {


                    })
                }
            }
        })
    }

    getCompanyBank = () => {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCompanyBank',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'selectedCompany': this.state.selectedCompanyName,
                'selectedCountry': this.state.selectedCountry,
                'selectedCurrency': this.state.selectedCurrency,
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({ bankList: data['data'], loading: 1, }, function () {


                    })
                }
            }
        })
    }

    getDiscountType = () => {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDiscountType',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),

            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    // console.log(data['data']);
                    this.setState({ discountTypeList: data['data'], loading: 1, }, function () {

                    })
                }
            }
        })
    }

    handleValidSubmit = (event) => {
        event.preventDefault();
        this.setState({ loadingSave: 1 })
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'addBulkOrderFromApi',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'cartOrder': this.state.orderList,
                'countRequest': this.state.countRequest,
                'distId': this.props.data.userId,
                'cartID': this.props.data.id,
                'haveDiscount': this.state.haveDiscount == true ? '1' : '0',
                'discountType': this.state.selectedDiscountType,
                'discountValue': this.state.discountValue,
                'orderDate': this.state.orderDate,
                'orderTime': this.state.orderTime,
                'rBank_country': this.state.selectedCountry,
                'rBank_compName': this.state.selectedCompanyName,
                'rBank_currency': this.state.selectedCurrency,
                'bankId': this.state.selectedBank,
                'inoivceDateFrom': this.state.inoivceDateFrom,
                'companyId': this.state.selectedCompany,
                'note': this.state.note,
                'showPass': this.state.showPass == true ? '1' : '0',
                'invoicePassword': this.state.passInvoce,
                'inoivceDateTo': this.state.inoivceDateTo,


            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {

                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 1) {
                    var error = data['data'].map((rowData, index) => {
                        return (
                            <div className='error-text-black'><i className='flaticon2-exclamation error-icon mr-1' /> {rowData}</div>
                        )
                    })
                    this.setState({ submitError: error, loadingSave: 0 })

                } else {
                    this.setState({ loadingSave: 0 })
                    this.props.getBulkOrderCarts()
                    this.props.handleCloseSideBar();
                }
            }
        })


    }

    handleAddOrder = (event) => {
        const arr = {};
        arr['orderId'] = this.state.orderId + 1;
        arr['cardCategory'] = { 'name': (localStorage.getItem('LANG') == 1 ? this.state.selectedCardRating.name : this.state.selectedCardRating.name_en), 'catId': this.state.selectedCardRating.id };
        arr['cardData'] = { 'name': this.state.cardName, 'cardId': this.state.cardId };
        arr['actualValue'] = this.state.cardsAmounCurr;
        arr['cardClass'] = this.state.cardClass;
        arr['deleted'] = '0';
        var orderData = this.state.orderList;
        var orderCounts = this.state.countOrder
        orderCounts = orderCounts + 1
        orderData.push(arr);
        // console.log(orderData);
        this.setState({ orderList: orderData, selectedCardRating: [], classQty: [], className: [], cardsAmounCurr: '', showHaveDiscount: true, deleted: 0, countOrder: orderCounts }, function () {
            this.handleOrderTotal()
        })
    }

    deleteOrder = (event, index) => {
        var order = this.state.orderList
        order[index]['deleted'] = '1';
        var orderCounts = this.state.countOrder
        orderCounts = orderCounts - 1
        this.setState({ orderList: order, countOrder: orderCounts })
    }
    handleDiscountValue = () => {
        var totalDiscount = 0;
        if (this.state.selectedDiscountType == '1') {
            totalDiscount = this.state.orderTotalAmount - parseFloat(this.state.discountValue)
        } else {
            totalDiscount = parseFloat(this.state.orderTotalAmount) - (parseFloat(this.state.orderTotalAmount) - parseFloat(this.state.discountValue))
        }
        this.setState({ orderTotalAfterDisc: totalDiscount })
    }

    handleOrderTotal = (event) => {
        var orderTotal = 0;
        var count = 0;
        this.state.orderList.map((rowData) => {
            orderTotal = parseFloat(orderTotal) + parseFloat(rowData['actualValue'])
            count++;
        })
        this.setState({ orderTotalAmount: orderTotal, countRequest: count }, function () {
            // this.handleDiscountValue()
        })
    }

    handleChangeSelect = (type, option) => {
        this.setState(state => {
            return {
                [type]: option,
                msgError: 0
            }
        }, function () {
            if (type == 'selectedCardRating') {
                this.setState({
                    distId: option.distId,
                    catId: option.id,
                    cardsList: [],
                    cardId: '',
                    classList: [],
                }, function () {
                    this.getDistCards()
                })
            }
        })

    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value,
            submitError: ''
        }, function () {
            if (name == 'selectedCountry') {
                this.setState({ selectedCompanyName: [], selectedCurrency: [], selectedBank: [] }, function () {
                    this.getBanksByCountry()
                })
            } else if (name == 'selectedCompanyName') {
                this.setState({ selectedCurrency: [], selectedBank: [] }, function () {
                    this.getCompanyCurrencies()
                })
            } else if (name == 'selectedCurrency') {
                this.setState({ selectedBank: [] }, function () {
                    this.getCompanyBank()
                })
            } else if (name == 'selectedDiscountType') {
                this.setState({}, function () {
                    this.getDiscountType()
                    this.setState({ orderTotalAfterDisc: '', discountValue: '' })
                })
            }

            if (name == 'showPass') {
                this.setState({
                    passInvoce: Math.floor(Math.random() * 10000)
                })
            } else if (name == 'discountValue') {
                this.handleDiscountValue()

            }
        })



    }

    setUpSelectOptions = (type) => {
        var list = ''
        if (type == 'selectedCompanyName') {
            list = Object.keys(this.state.beneficiaryList).map((rowData, index) => {
                var beneficiaryList = this.state.beneficiaryList[rowData];
                return (
                    <option value={beneficiaryList} key={index}>
                        {beneficiaryList}
                    </option>
                )
            })
        } else if (type == 'selectedDiscountType') {

            list = this.state.discountTypeList.map((rowData, index) => {
                return (
                    <option value={rowData.key} key={index}>
                        {rowData.value}
                    </option>
                )
            })

        } else if (type == 'selectedCompany') {
            list = this.state.companyList.map((rowData, index) => {
                return (
                    <option value={rowData.id} key={index}>
                        {rowData['name']}
                    </option>
                )
            })
        } else if (type == 'selectedCountry') {
            list = this.state.countryList.map((rowData, index) => {
                return (
                    <option value={rowData.country} key={index}>
                        {rowData.countryTitle}
                    </option>
                )
            })
        } else if (type == 'selectedCurrency') {
            list = this.state.currencyList.map((rowData, index) => {
                return (
                    <option value={rowData.id} key={index}>
                        {rowData.currencyTitle}
                    </option>
                )
            })
        } else if (type == 'selectedBank') {
            list = this.state.bankList.map((rowData, index) => {
                return (
                    <option value={rowData.id} key={index}>
                        {rowData.bankName}
                    </option>
                )
            })
        }
        return list
    }
    changePasswordView = (passwordView) => {
        this.setState({ passwordView: passwordView })
    }
    selectCard = (type, data) => {
        // data.name_en
        this.setState({ cardName: data.name, catId: data.cardCategory, cardId: data.cardId }, function () {
            this.getDistCardClass()

        })

    }

    displayCards = () => {
        let cardsView = ''
        if (this.state.cardsList.length > 0) {
            cardsView = this.state.cardsList.map((rowData, index) => {
                return (
                    // <Col md={2} className='text-center p-0' key={index}>
                    <div className='text-center p-0 buyCard-Category-CardWidth addOrder' key={index}>

                        <div className={(this.state.cardId == rowData['cardId'] && 'add-order-cat-selected-view') + (' add-order-cat-view')} onClick={() => this.selectCard('', rowData)}>
                            <div className='add-order-cat-img-view'>
                                <img className='img-fluid add-order-cat-img' src={(rowData.image != '' && rowData.image != null && rowData.image != undefined) ? rowData.image : toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} />
                            </div>
                            <div className='mt-3 d-inline-flex main-color-1'>
                                {this.state.cardId == rowData['cardId'] ? <RadioButtonCheckedIcon className='ml-1 mr-1' /> : <RadioButtonUncheckedIcon className='ml-1 mr-1' />}
                                <div className='add-order-cat-name font-w-500'>{rowData.name}</div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return cardsView
    }


    handleClassQtyChange = (event, classId, distPrice, name) => {

        //classId==>id
        //distPrice==>distPriceCurr
        const target = event.target;
        const value = target.value;

        var classQty = this.state.classQty
        classQty[classId] = value

        var className = this.state.className
        className[classId] = name

        var classdistPrice = this.state.classdistPrice
        classdistPrice[classId] = distPrice


        this.setState({
            classQty: classQty,
            classdistPrice: classdistPrice,
            className: className


        }, function () {
            this.calculateCardsAmount()
        })
    }

    calculateCardsAmount = () => {
        var scope = this

        // loop in classQty 
        var cardsAmounCurr = 0;
        var cardClassData = [];
        var cardClass = this.state.cardClass;
        Object.keys(this.state.classQty).map(function (rowData, index) {
            cardsAmounCurr = parseFloat(cardsAmounCurr) + (scope.state.classQty[rowData] * parseFloat(scope.state.classdistPrice[rowData]))

            cardClass = {
                'id': rowData,
                'name': scope.state.className[rowData],
                'count': scope.state.classQty[rowData],
            }

            cardClassData.push(cardClass)

        })
        this.setState({ cardsAmounCurr: cardsAmounCurr.toFixed(5), cardClass: cardClassData })
    }

    displayClasses = () => {
        let classView = ''
        if (this.state.classList.length > 0) {
            classView = this.state.classList.map((rowData, index) => {
                // console.log(rowData);
                return (
                    <Col md={3} className='mb-3' key={rowData.id}>
                        <div className='add-order-class-box-form-view'>
                            <div className='main-color-1 f-15 font-w-500 add-order-class-box-form-title overflow-text-length'>
                                {rowData['name']}
                            </div>
                            <div>
                                <Row className='mt-3'>
                                    <Col md={2}>
                                        {Strings['Price']}
                                    </Col>
                                    <Col md={10}>
                                        <div>{rowData['priceDistCurr']} {this.state.distCurrency}</div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row className='mt-3'>
                                    <Col md={2} className='mt-2'>
                                        {Strings['Qty']}
                                    </Col>
                                    <Col md={10}>
                                        <AvField
                                            disabled={(rowData['distBuyPrice'] == 0 || rowData['distBuyPrice'] == '')
                                                ? true : false} name={"class-" + rowData['id']} type='number'
                                            min={0} max={100}
                                            placeholder={Strings['Enter Qty']}
                                            value={this.state.classQty[rowData['id']]}
                                            onChange={(event) => this.handleClassQtyChange(event, rowData['id'], rowData['priceDistCurr'], rowData['name'])}
                                            errorMessage={Strings['Sorry, the required quantity is not available']}

                                        />
                                    </Col>
                                </Row>
                            </div>


                        </div>
                    </Col>
                )
            })
        }
        return classView
    }

    openHeaderMenu = (event, id) => {
        event.preventDefault();
        if (event.target.classList[1] != undefined && event.target.classList[1] == "menu-dots-vertical" && id != undefined) {
            var val = ''
            if (this.state.openHeader == id) {
                val = ''
            } else if (id != undefined) {
                val = id
            }
            this.setState({
                openHeader: val
            })
        } else if (id == undefined && event.target.classList[1] != "menu-dots-vertical") {
            this.setState({
                openHeader: ''
            })
        }
    }
    displayData = () => {
        var cartInfo = ''
        if (this.state.loading == 1 && this.state.orderList.length > 0) {
            cartInfo = this.state.orderList.map((rowData, index) => {
                // console.log(this.state.openHeader);
                if (rowData.deleted != '1') {
                    return (
                        <Row key={index} className='table-body-row'>
                            <Col lg={2} md={3} sm={4} xs={6} className="overflow-text-length" >#{rowData['orderId']}</Col>
                            <Col lg={3} md={3} sm={4} xs={6} className="overflow-text-length" title={rowData['cardCategory']['name']}>{rowData['cardCategory']['name']}</Col>
                            <Col lg={3} md={3} sm={4} xs={6} className="overflow-text-length">{rowData['cardData']['name']}</Col>
                            <Col lg={3} md={3} sm={4} xs={6} className="overflow-text-length">{rowData['actualValue']}</Col>
                            <Col lg={1} md={3} sm={4} xs={6} className={'action-icon-view actionDropdownList actionDropdownListPadding-0 text-md-right'}  >
                                <Dropdown isOpen={(this.state.openHeader == rowData['orderId'] + 1 ? true : false)} className="font-size-13 actionDropdownList actionDropdownListPadding-0 orderlistActionlist" >
                                    <DropdownToggle tag="a" className="nav-link pt-0 actionDropdownList  actionDropdownListPadding-0" caret  >
                                        <MoreVertIcon className='menu-dots-vertical' onClick={(event) => this.openHeaderMenu(event, rowData['orderId'] + 1)} />
                                    </DropdownToggle>

                                    <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList" >
                                        <div className="actionDropdownList" style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                                            <DropdownItem className='actionDropdownList' onClick={(event) => this.deleteOrder(event, index)}>{Strings['Delete']}</DropdownItem>
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>
                    )
                }
            })

        }
        return cartInfo;
    }
    render() {

        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col lg={10} md={10} sm={10} xs={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'>  {this.props.data.cartName}
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={2} xs={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>

                <div className='sidebar-body-view mb-form'>
                    <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                        <Row>
                            <Col md={5}>
                                <div>
                                    <label className={this.state.msgError == 1 ? 'label-error mt-2' : 'mt-2'}>{Strings['Card Rating']} *</label>
                                    <Picky
                                        value={this.state.selectedCardRating}
                                        options={this.state.cardRatingList}
                                        onChange={this.handleChangeSelect.bind(this, 'selectedCardRating')}
                                        open={false}
                                        valueKey="id"
                                        labelKey={(localStorage.getItem('LANG') == 1 ? 'name' : 'name_en')}
                                        multiple={false}
                                        includeSelectAll={false}
                                        includeFilter={true}
                                        dropdownHeight={200}
                                        placeholder={Strings['Select Cards Category']}
                                        filterPlaceholder={Strings['Select Cards Category']}
                                        className={this.state.msgError == 1 ? 'border border-danger' : ''}
                                        clearFilterOnClose={true}
                                    />
                                    {this.state.msgError == 1 &&
                                        <div className='msg-error'>{Strings['This field is required']}</div>
                                    }
                                </div>
                            </Col>
                            {this.state.selectedCardRating != null && this.state.selectedCardRating != undefined && this.state.selectedCardRating.length != '' &&
                                <Col md={12}>
                                    <Row>
                                        <Col md={12}>
                                            {
                                                this.state.loading == 2 &&
                                                <Row>
                                                    <Col lg={12} md={12} sm={12} xs={12} style={{ marginTop: "15%" }} className='text-center'>
                                                        <div className='card-body'>
                                                            <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                                            <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                                            <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                                            <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                                            <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                                            <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                            {this.state.loading != 2 &&
                                                <div className='mb-3 pt-3'>
                                                    <div className='main-color-1 f-16 font-w-500 mb-4 mt-3 pl-4 pr-4'>
                                                        {Strings['Select Card']}
                                                        <hr className='inv-hr-view-2' />
                                                    </div>
                                                    <Row className='pr-4 pl-4'>
                                                        {this.displayCards()}
                                                    </Row>
                                                </div>
                                            }
                                            {this.state.loading != 2 && this.state.cardLength == 0 && this.state.catId != '' &&
                                                <Col md={12}>
                                                    <Row>
                                                        <div className='width-100'>
                                                            <div className='text-center' style={{ marginTop: "3%", marginBottom: "3%" }}>
                                                                <div className='no-data-text'>{Strings["Selected category does not have any card"]}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            }
                                            {
                                                this.state.loading == 3 &&
                                                <Col md={12}>
                                                    <Row>
                                                        <Col lg={12} md={12} sm={12} xs={12} style={{ marginTop: "15%" }} className='text-center'>
                                                            <div className='card-body'>
                                                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }

                                            {this.state.loading != 3 && this.state.classList.length > 0 &&
                                                <Col md={12}>

                                                    <div className='mb-5'>
                                                        <div className='main-color-1 f-16 font-w-500 mb-4 mt-3 pl-4 pr-4'>
                                                            {Strings['Determine the value of the cards']}
                                                            <hr className='inv-hr-view-2' />
                                                        </div>
                                                        <Row className='pr-4 pl-4'>
                                                            {this.displayClasses()}
                                                        </Row>
                                                        <Row className="pr-4 pl-4 align-items-end">
                                                            <Col md={3}>
                                                                <AvField className='not-allowed-cursor' name="cardsAmounCurr" type='number' label={Strings['Actual Value']} value={this.state.cardsAmounCurr} disabled />
                                                            </Col>
                                                            <Col md={9} className="text-right">
                                                                <button className="btn gradient-button-view gradient-button gradient-button-font-12 ml-1 mt-2" onClick={(event) => this.handleAddOrder(event)}>
                                                                    {Strings['Add Order']}
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            }
                                            {this.state.loading != 3 && this.state.cardClassLength == 0 && this.state.cardId != '' &&
                                                <Col md={12}>
                                                    <Row>
                                                        <div className='width-100'>
                                                            <div className='text-center' style={{ marginTop: "4%", marginBottom: "4%" }}>
                                                                <div className='no-data-text'>{Strings["Selected card do not have any card class"]}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            {this.state.countOrder > 0 && this.state.orderList.length > 0 &&
                                < Col md={12} className="my-5">
                                    <Row className='table-header-row'>
                                        <Col lg={2} md={3} sm={4} xs={6} className="overflow-text-length" title={Strings['Order #']}>{Strings['Order #']}</Col>
                                        <Col lg={3} md={3} sm={4} xs={6} className="overflow-text-length" title={Strings['Card Category']}>{Strings['Card Category']}</Col>
                                        <Col lg={3} md={3} sm={4} xs={6} className="overflow-text-length" title={Strings['Card']}> {Strings['Card']}</Col>
                                        <Col lg={3} md={3} sm={4} xs={6} className="overflow-text-length" title={Strings['Actual Value']}>{Strings['Actual Value']}</Col>
                                        <Col lg={1} md={1} sm={4} xs={6} className="overflow-text-length text-md-right" title={Strings['Action']}> {Strings['Action']}</Col>
                                    </Row>
                                    {this.displayData()}
                                </Col>
                            }
                        </Row>
                        <Row className="mt-3">
                            <Col md={5}>
                                <AvField name="orderTotalAmount" label={Strings['Orders total amount']} type='number' min={0.1} placeholder="0.0" value={this.state.orderTotalAmount} disabled />
                            </Col>
                            {(this.state.haveDiscount == 1 || this.state.haveDiscount == true) &&
                                <Col md={5}>
                                    <AvField className='not-allowed-cursor' name="orderTotalAfterDisc" type='number' placeholder="0.0" label={Strings['Orders Total After Discount']} value={this.state.orderTotalAfterDisc} disabled />
                                </Col>
                            }
                        </Row>
                        {this.state.showHaveDiscount == true &&
                            <Row>
                                <Col md={5} className={'mt-2'}>
                                    <AvField
                                        label={Strings['Have Discount']}
                                        type="checkbox"
                                        name="haveDiscount"
                                        ref="default"
                                        value={this.state.haveDiscount == 1 || this.state.haveDiscount == true ? true : false}
                                        onChange={this.handleInputChange}
                                        checked={this.state.haveDiscount == 1 || this.state.haveDiscount == true ? true : false}
                                        tag={CustomInput}
                                        className='custom-form-mb default mb-3 mr-19px'
                                    />
                                </Col>
                                {(this.state.haveDiscount == 1 || this.state.haveDiscount == true) &&
                                    <Col md={12}>
                                        <Row>
                                            <Col md={5}>
                                                {/* With select and AvField */}
                                                <AvField type="select" name="selectedDiscountType" label={Strings['Discount Type'] + " *"} value={this.state.selectedDiscountType} onChange={this.handleInputChange} >
                                                    <option style={{
                                                        'display': 'none'
                                                    }} value="" defaultValue>{Strings['Select Discount Type']}</option>
                                                    {this.setUpSelectOptions('selectedDiscountType')}
                                                </AvField>

                                            </Col>
                                            <Col md={5}>
                                                <AvField name="discountValue" type="text" label={Strings['Discount Value'] + ' *'} value={this.state.discountValue} placeholder="0" onChange={this.handleInputChange}
                                                    validate={{
                                                        pattern: { value: this.state.selectedDiscountType == '1' ? '^([0-9]*)?' : '^(0[.][0-9]*)?', errorMessage: this.state.selectedDiscountType == '1' ? Strings['The number must be Integer'] : Strings['The number must be decimal'] },
                                                        min: { value: this.state.selectedDiscountType == '1' ? 0 : 0.0, errorMessage: this.state.selectedDiscountType == '1' ? Strings['The number must be between 0 and '] + this.state.orderTotalAmount : Strings['The number must be between 0 and 1'] },
                                                        max: { value: this.state.selectedDiscountType == '1' ? this.state.orderTotalAmount - 1 : 0.99, errorMessage: this.state.selectedDiscountType == '1' ? Strings['The number must be between 0 and '] + this.state.orderTotalAmount : Strings['The number must be between 0 and 1'] }
                                                    }}


                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </Row>
                        }
                        <Row className={'mt-2'}>
                            <Col md={5}>
                                <AvField type="date" name="orderDate" label={Strings['Subscription Date'] + ' *'} value={this.state.orderDate} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                            </Col>
                            <Col md={5}>
                                <AvField type="time" name="orderTime" label={Strings['Time'] + ' *'} value={this.state.orderTime} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                            </Col>
                        </Row>
                        <Label className="font-w-500 f-20 mt-2">{Strings['Bank account']} *</Label>
                        <Row>

                            <Col md={5}>
                                {/* With select and AvField */}
                                <AvField type="select" name="selectedCountry" label={Strings['Country'] + ' *'} errorMessage={Strings['This field is required']} value={this.state.selectedCountry} required onChange={this.handleInputChange}>
                                    <option style={{
                                        'display': 'none'
                                    }} value="" defaultValue>{Strings['Select Country']}</option>
                                    {this.setUpSelectOptions('selectedCountry')}
                                </AvField>


                            </Col>
                            <Col md={5}>
                                {/* With select and AvField */}
                                <AvField type="select" name="selectedCompanyName" label={Strings['Beneficiary Name'] + ' *'} value={this.state.selectedCompanyName} required onChange={this.handleInputChange} disabled={this.state.selectedCountry != null && this.state.selectedCountry != undefined && this.state.selectedCountry != '' ? false : true} >
                                    <option style={{
                                        'display': 'none'
                                    }} value="" defaultValue>{Strings['Select Beneficiary Name']}</option>
                                    {this.setUpSelectOptions('selectedCompanyName')}
                                </AvField>

                            </Col>
                            <Col md={5} className="">
                                {/* With select and AvField */}
                                <AvField type="select" name="selectedCurrency" label={Strings['Currency'] + ' *'} value={this.state.selectedCurrency} required onChange={this.handleInputChange} disabled={this.state.selectedCompanyName != null && this.state.selectedCompanyName != undefined && this.state.selectedCompanyName != '' ? false : true} >
                                    <option style={{
                                        'display': 'none'
                                    }} value="" defaultValue>{Strings['Select currency']}</option>
                                    {this.setUpSelectOptions('selectedCurrency')}
                                </AvField>

                            </Col>
                            <Col md={5} className="">
                                {/* With select and AvField */}
                                <AvField type="select" name="selectedBank" label={Strings['Bank Name'] + ' *'} value={this.state.selectedBank} required onChange={this.handleInputChange} disabled={this.state.selectedCurrency != null && this.state.selectedCurrency != undefined && this.state.selectedCurrency != '' ? false : true} >
                                    <option style={{
                                        'display': 'none'
                                    }} value="" defaultValue>{Strings['Select Bank Name']}</option>
                                    {this.setUpSelectOptions('selectedBank')}
                                </AvField>

                            </Col>
                        </Row>
                        <Label className="font-w-500 f-20 mt-2">{Strings['Invoice date']} *</Label>
                        <Row>
                            <Col md={5}>
                                <AvField type="date" name="inoivceDateFrom" label={Strings['From Date'] + ' *'} value={this.state.inoivceDateFrom} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                            </Col>
                            <Col md={5}>
                                <AvField type="date" name="inoivceDateTo" label={Strings['To Date'] + ' *'} value={this.state.inoivceDateTo} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                            </Col>
                            <Col md={5}>
                                <AvField type="select" name="selectedCompany" label={Strings['Company'] + ' *'} errorMessage={Strings['This field is required']} value={this.state.selectedCompany} required onChange={this.handleInputChange}>
                                    <option style={{
                                        'display': 'none'
                                    }} value="" defaultValue>{Strings['Select Company']}</option>
                                    {this.setUpSelectOptions('selectedCompany')}
                                </AvField>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={5} className={'mt-2'}>
                                <AvField type="textarea" name="note" rows={5} label={Strings['Note']}
                                    value={this.state.note} onChange={this.handleInputChange} />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={5} className={'mt-2'}>
                                <AvField
                                    label={Strings['Show Password']}
                                    type="checkbox"
                                    name="showPass"
                                    ref="default"
                                    value={this.state.showPass == 1 || this.state.showPass == true ? true : false}
                                    onChange={this.handleInputChange}
                                    checked={this.state.showPass == 1 || this.state.showPass == true ? true : false}
                                    tag={CustomInput}
                                    className='custom-form-mb default mb-3 mr-19px'
                                />

                                {(this.state.showPass == 1 || this.state.showPass == true) &&
                                    <div className='d-inline-flex add-order-password-input-view'>
                                        <AvField type={this.state.passwordView == 1 ? 'password' : 'text'} autoComplete="new-password" name="passInvoce" label={Strings['Invoice Password'] + ' *'} placeholder={Strings['Enter password']} value={this.state.passInvoce} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                        {this.state.passwordView == 1 && <VisibilityOffOutlinedIcon className='add-order-password-icon-view pointer-cursor' onClick={() => this.changePasswordView(2)} />}
                                        {this.state.passwordView == 2 && <VisibilityOutlinedIcon className='add-order-password-icon-view pointer-cursor' onClick={() => this.changePasswordView(1)} />}
                                    </div>
                                }
                            </Col>
                        </Row>


                        <div className='text-right mt-4'>

                            {this.state.loadingSave == 1 ?
                                <button type="button" className="btn form-save-btn mr-1 ml-1 task-action-btn-loading"><CircularProgress className="SaveCircularProgressColor" /> {Strings['Save']}</button>
                                :
                                <button type="submit" className="btn form-save-btn mr-1 ml-1"> {Strings['Save']} </button>
                            }
                            <button type="button" className="btn form-cancel-btn m-l-10" onClick={this.props.handleCloseSideBar}>{Strings['Cancel']}</button>
                        </div>

                        {this.state.submitError &&
                            <>
                                {this.state.submitError}
                            </>

                        }
                    </AvForm>



                </div>

            </div >
        )
    }
}

export default AddOrder;
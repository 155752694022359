import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from "../../../../translate.js";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import { CircularProgress } from "@material-ui/core";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

const SortableItem = SortableElement(({ value }) => (
  <div
    style={{
      position: "relative",
      width: "100%",
      display: "block",
      padding: 10,
      backgroundColor: "#FFF",
      borderBottom: "1px solid #EFEFEF",
      boxSizing: "border-box",
      WebkitUserSelect: "none",
      cursor: "all-scroll",
      opacity: 10,
      visibility: "visible",
    }}
  >
    {value}
  </div>
));

const SortableList = SortableContainer(({ typeSort, items }) => (
  <div
    className="sort-box-width-mobile"
    style={{
      width: "100%",
      margin: "0 auto",
      overflow: "auto",
      backgroundColor: "#f3f3f3",
      border: "1px solid #EFEFEF",
      borderRadius: 6,
      opacity: 10,
      visibility: "visible",
    }}
  >
    {items.map((value, index) => (
      <SortableItem
        key={`item-${index}`}
        hideSortableGhost={false}
        index={index}
        value={typeSort == 3 ? value.cardClassName : value.name}
      />
    ))}
  </div>
));

class SortCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 1,
      startSave: 0,
      typeSort: 1,
      sortList: [],
      submitError: "",
      status: "",
      catId: "",
      cardId: "",
      classId: "",
      categoryList: [],
      selectTypeSort: [],
      cardList: [],
      selectedCategory: [],
      selectedCard: [],
      items: [],
    };
  }

  componentDidMount() {
    this.getSortList();
    this.getCardCategory();
    this.getCategories();
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });
  };

  getCategories() {
    this.setState({ loading: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "sortDistCat",
        typeSort: this.state.typeSort,
        cardId: this.state.cardId,
        catId: this.state.catId,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              items: data["data"],
              loading: 0,
            });
          }
        }
      });
  }
  getCardCategory() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCategories",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              categoryList: data,
            });
          }
        }
      });
  }
  getCardsList() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCards",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        catId: this.state.catId,
        distId: this.props.distId ? this.props.distId : "",
        mainDist: 1,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              cardList: data,
            });
          }
        }
      });
  }
  getSortList() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "sort_list",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              sortList: data,
            });
          }
        }
      });
  }

  handleChangeSelect = (type, option) => {
    this.setState(
      (state) => {
        return {
          [type]: option,
          activePage: 1,
          name: "",
          submitError: "",
          status: "",
          items: [],
          msgError: 0,
        };
      },
      function() {
        if (type == "selectTypeSort") {
          this.setState(
            {
              catId: "",
              cardId: "",
              typeSort: option.id,
              selectedCard: [],
              selectedCategory: [],
              cardList: [],
            },
            function() {
              if (this.state.typeSort == 1) this.getCategories();
            }
          );
        } else if (type == "selectedCategory") {
          this.setState(
            {
              cardId: "",
              catId: option.id,
              name: option.name,
              selectedCard: [],
              cardList: [],
            },
            function() {
              this.getCardsList();
              if (this.state.typeSort == 2) this.getCategories();
            }
          );
        } else if (type == "selectedCard") {
          this.setState(
            {
              cardId: option.cardId,
              name:
                localStorage.getItem("LANG") == 1
                  ? option["name"]
                  : option["name_en"],
            },
            function() {
              if (this.state.typeSort == 3) this.getCategories();
            }
          );
        }
      }
    );
  };
  handleSortAction = (event) => {
    event.preventDefault();
    this.setState({
      startSave: 1,
      submitError: "",
      status: "",
    });
    if (this.state.items.length > 0) {
      var sortedIds = "";
      this.state.items.map((rowData, index) => {
        if (sortedIds != "" && sortedIds != null && sortedIds != undefined) {
          sortedIds =
            sortedIds +
            "," +
            (this.state.typeSort == 3
              ? rowData.classId
              : this.state.typeSort == 2
              ? rowData.cardId
              : rowData.id);
        } else {
          sortedIds =
            this.state.typeSort == 3
              ? rowData.classId
              : this.state.typeSort == 2
              ? rowData.cardId
              : rowData.id;
        }
      });

      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "sortDistCat",
          typeSort: this.state.typeSort,
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          category: sortedIds,
          distId: this.props.distId ? this.props.distId : "",
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else if (data["error"] == 1) {
              this.setState({
                submitError: data["errorMsg"],
              });
            } else {
              this.setState({
                status: data["errorMsg"],
                startSave: 0,
              });
            }
          }
        });
    }
  };

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Sort"]}{" "}
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <Row>
            <Col lg={4} md={4} sm={4} xs={12} className="card-desc-form-view">
              <AvForm ref={(c) => (this.form = c)}>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="member-searchable-list mb-2">
                    <Picky
                      value={this.state.selectTypeSort}
                      options={this.state.sortList}
                      onChange={this.handleChangeSelect.bind(
                        this,
                        "selectTypeSort"
                      )}
                      open={false}
                      valueKey="id"
                      labelKey="name"
                      multiple={false}
                      includeSelectAll={false}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["Sort Type"]}
                      filterPlaceholder={Strings["Select Category"]}
                    />
                  </div>
                </Col>
                {this.state.typeSort != 1 && (
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="member-searchable-list mb-2">
                      <Picky
                        value={this.state.selectedCategory}
                        options={this.state.categoryList}
                        onChange={this.handleChangeSelect.bind(
                          this,
                          "selectedCategory"
                        )}
                        open={false}
                        valueKey="id"
                        labelKey="name"
                        multiple={false}
                        includeSelectAll={false}
                        includeFilter={true}
                        dropdownHeight={200}
                        placeholder={Strings["CategoryMain"]}
                        filterPlaceholder={Strings["Select Category"]}
                      />
                    </div>
                  </Col>
                )}
                {this.state.typeSort == 3 && (
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="member-searchable-list mb-2">
                      <Picky
                        value={this.state.selectedCard}
                        options={this.state.cardList}
                        onChange={this.handleChangeSelect.bind(
                          this,
                          "selectedCard"
                        )}
                        open={false}
                        valueKey="cardId"
                        labelKey={
                          localStorage.getItem("LANG") == 1 ? "name" : "name_en"
                        }
                        multiple={false}
                        includeSelectAll={false}
                        includeFilter={true}
                        dropdownHeight={200}
                        placeholder={Strings["Select Card"]}
                        filterPlaceholder={Strings["Select Card"]}
                      />
                    </div>
                  </Col>
                )}
              </AvForm>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="mt-4 text-right">
                  {this.state.startSave == 1 ? (
                    <button className="btn form-save-btn ">
                      <CircularProgress className="SaveCircularProgressColor" />{" "}
                      {Strings["Save"]}{" "}
                    </button>
                  ) : (
                    <button
                      className="btn form-save-btn mr-1 ml-1"
                      onClick={(event) => this.handleSortAction(event)}
                    >
                      {" "}
                      {Strings["Save"]}{" "}
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn form-cancel-btn m-l-10"
                    onClick={this.props.handleCloseSideBar}
                  >
                    {Strings["Cancel"]}
                  </button>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                {this.state.submitError && (
                  <div className="error-text-black">
                    <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                    {this.state.submitError}
                  </div>
                )}

                {this.state.status && (
                  <div className="error-text-black">
                    <i className={"flaticon2-check-mark success-icon mr-1"} />{" "}
                    {this.state.status}
                  </div>
                )}
              </Col>
            </Col>
            <Col lg={8} md={8} sm={8} xs={12}>
              {this.state.loading == 1 && (
                <Row>
                  <Col
                    md={12}
                    xs={12}
                    lg={12}
                    sm={12}
                    style={{ marginTop: "15%" }}
                    className="text-center"
                  >
                    <div className="card-body">
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                    </div>
                  </Col>
                </Row>
              )}
              {this.state.loading == 0 && this.state.items.length > 0 && (
                <div className="inv-cards-view mt-0 pt-0">
                  <Row>
                    <Col
                      md={12}
                      xs={12}
                      lg={12}
                      sm={12}
                      className="mb-4 pr-0 pl-0 main-color-1 font-w-400 mt-2 f-15"
                    >
                      {this.state.typeSort == 1
                        ? Strings["All Categories Sort"]
                        : this.state.name}
                    </Col>
                    <SortableList
                      typeSort={this.state.typeSort}
                      items={this.state.items}
                      onSortEnd={this.onSortEnd}
                    />
                  </Row>
                </div>
              )}
              {this.state.loading == 0 && this.state.items.length <= 0 && (
                <Row className="text-center" style={{ marginTop: "15%" }}>
                  <div>
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl(
                        "/media/ezpay/EZPAY_LOGO_GRAY" +
                          window.MAIN_IMAGE +
                          ".png"
                      )}
                      className="img-fluid no-data-img"
                    />
                    <div className="no-data-text">
                      {Strings["There is no data to display"]}
                    </div>
                  </div>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default SortCategories;

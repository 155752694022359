import React, { createContext, useEffect, useState } from "react";
const MyContext = createContext();

const MyContextProvider = ({ children }) => {
  const [balance, setBalance] = useState(0);
  const [supervisorBalance, setSupervisorBalance] = useState({});
  const getBalance = async () => {
    try {
      if (
        localStorage.getItem("USER_ID") &&
        localStorage.getItem("USER_LEVEL") == 3 &&
        localStorage.getItem("ACCOUNT_TYPE") == 7
      ) {
        const body = JSON.stringify({
          type: "getUserInfoPOS",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        });
        const res = await fetch(window.API_PERM_USER, {
          method: "POST",
          body,
          headers: {
            Accept: "application/json",
          },
        });
        const response = await res.json();
        if (response.logout == 1) {
          let lang = localStorage.getItem("LANG");
          localStorage.clear();
          localStorage.setItem("LANG", lang);
          window.location.href = "/logout";
        } else {
          let balanceRes = response.distBalanceAmount + " " + response.currency;
          setBalance(balanceRes);
        }
      }
    } catch (error) {
      console.log("BALANCE ERROR", error.message);
    } finally {
    }
  };
  const getSupervisorBalance = async () => {
    if (
      localStorage.getItem("POS_MGR_EMP_NEW") == 1 
    ) {
      try {
        const body = JSON.stringify({
          type: "getUserBalance",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        });
        const res = await fetch(window.API_PERM_USER, {
          method: "POST",
          body,
          headers: {
            Accept: "application/json",
          },
        });
        const response = await res.json();
        if (response.logout == 1) {
          // let lang = localStorage.getItem("LANG");
          // localStorage.clear();
          // localStorage.setItem("LANG", lang);
          // window.location.href = "/logout";
        } else {
          setSupervisorBalance(response);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    getBalance();
    getSupervisorBalance();
  }, []);
  return (
    <MyContext.Provider
      value={{
        balance,
        getBalance,
        supervisorBalance,
        getSupervisorBalance,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export { MyContext, MyContextProvider };

import React, { Component } from "react";
import {
  UncontrolledDropdown,
  Row,
  Col,
  CustomInput,
  Progress,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from "../../../../translate.js";
import Pagination from "react-js-pagination";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Switch from "@material-ui/core/Switch";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class AddCoupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openHeader: "",
      qty: "",
      discountRate: "",
      once: "",
      data: [],
      cardsData: this.props.cardsData,
      cardType: this.props.cardType,
      cardId: this.props.cardId,
      loading: 1,
      activePage: 1,
      limit: 10,
      dataCount: 0,
      distId: this.props.distId ? this.props.distId : "",
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.cardsData);
    this.getCoupons();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getCoupons();
    });
  };

  downloadCodes(patchCouponId) {
    window.location =
      window.API_PERM_USER +
      "?type=downloadCodes&lang=" +
      localStorage.getItem("LANG") +
      "&userId=" +
      localStorage.getItem("USER_ID") +
      "&cardType=" +
      this.state.cardType +
      "&cardId=" +
      this.state.cardId +
      "&patchCouponId=" +
      patchCouponId +
      "&distId=" +
      this.state.distId +
      "&token=" +
      localStorage.getItem("USER_TOKEN");
  }
  getCoupons() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCoupons",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        cardType: this.state.cardType,
        cardId: this.state.cardId,
        page: this.state.activePage,
        limit: this.state.limit,
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              data: data["data"],
              dataCount: data["dataCount"],
              loading: 0,
            });
          }
        }
      });
  }

  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
        });
      }
    }
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      submitError: "",
    });
  };
  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };

  handleChangeStatus = (event, id) => {
    // https://dev45.hoja-crm.com/API/permUser.php?type=updateCouponStatus&cardType=2&cardId=490&userId=640&patchCouponId=49&qsyTest=-500
    var name = "active-" + id;
    this.setState({ [name]: event.target.checked });

    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "updateCouponStatus",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        active: event.target.checked == true ? 1 : 0,
        cardType: this.state.cardType,
        cardId: this.state.cardId,
        patchCouponId: id,
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('=========================data');
          // console.log(data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.getCoupons();
          }
        }
      });
  };

  displayData() {
    var list = "";
    if (this.state.data.length > 0) {
      list = this.state.data.map((rowData, index) => {
        return (
          <Row key={index} className="table-body-row">
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="main-color-1 font-w-400"
            >
              {rowData["qty"]}
            </Col>
            <Col lg={1} md={1} sm={1} xs={12}>
              {rowData["qtyRemaining"]}
            </Col>
            <Col lg={2} md={2} sm={2} xs={12}>
              {rowData["couponType"] == 1
                ? Strings["One time use"]
                : Strings["Use several times"]}
            </Col>
            <Col lg={2} md={2} sm={2} xs={12}>
              {rowData["discountRate"]}
            </Col>
            <Col lg={1} md={1} sm={1} xs={12}>
              {rowData["fromDate"] != "" &&
              rowData["fromDate"] != null &&
              rowData["fromDate"] != undefined
                ? rowData["fromDate"]
                : "-"}
            </Col>
            <Col lg={1} md={1} sm={1} xs={12}>
              {rowData["toDate"] != "" &&
              rowData["toDate"] != null &&
              rowData["toDate"] != undefined
                ? rowData["toDate"]
                : "-"}
            </Col>
            <Col lg={1} md={1} sm={1} xs={12}>
              <Switch
                checked={rowData["active"] == 0 ? false : true}
                onChange={(event) =>
                  this.handleChangeStatus(event, rowData["id"])
                }
                name={"active-" + rowData["id"]}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </Col>
            <Col lg={2} md={2} sm={2} xs={12}>
              {rowData["entryDate"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="text-center action-icon-view actionDropdownList"
            >
              <Dropdown
                isOpen={this.state.openHeader == rowData["id"] ? true : false}
                className="font-size-13 actionDropdownList"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link pt-0 actionDropdownList"
                  caret
                >
                  <MoreVertIcon
                    className="menu-dots-vertical actionDropdownList"
                    onClick={(event) =>
                      this.openHeaderMenu(event, rowData["id"])
                    }
                  />
                </DropdownToggle>
                <DropdownMenu className="font-size-13 actionDropdownList">
                  <div
                    className="actionDropdownList"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    <DropdownItem
                      className="actionDropdownList"
                      onClick={(event) => this.downloadCodes(rowData["id"])}
                    >
                      {Strings["Download codes"]}
                    </DropdownItem>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        );
      });
    }
    return list;
  }

  handleCancelAddForm = (event) => {
    event.preventDefault();
    this.setState(
      {
        qty: "",
        discountRate: "",
        once: 0,
        fromDate: "",
        toDate: "",
      },
      function() {}
    );
  };

  handleValidSubmit(event) {
    event.preventDefault();
    var data = new FormData();
    data.append("type", "addCoupon");
    data.append("userId", localStorage.getItem("USER_ID"));
    data.append("token", localStorage.getItem("USER_TOKEN"));
    data.append("lang", localStorage.getItem("LANG"));
    data.append("cardId", this.state.cardId);
    data.append("cardType", this.state.cardType);
    data.append("qty", this.state.qty);
    data.append("once", this.state.once == true ? 1 : 2);
    data.append("discountRate", this.state.discountRate);
    data.append("fromDate", this.state.fromDate);
    data.append("toDate", this.state.toDate);
    fetch(window.API_PERM_USER, {
      // mode: 'no-cors',
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        if (!response.ok) {
          // console.log(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('===============================');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ submitError: data["errorMsg"] });
          } else {
            this.form && this.form.reset();
            this.setState(
              {
                qty: "",
                once: 0,
                discountRate: "",
                fromDate: "",
                toDate: "",
              },
              function() {
                this.getCoupons();
              }
            );
          }
        }
      });
  }
  /**cardType 1-> card , 2-> card class , 3-> only one category , 4-> all categories (all inventory) */
  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Coupons"]}(
                {this.state.cardType == 1 || this.state.cardType == 3
                  ? localStorage.getItem("LANG") == 1
                    ? this.props.cardsData.name
                    : this.props.cardsData.name_en
                  : this.state.cardType == 4
                  ? Strings["All Categories Sort"]
                  : this.state.cardsData["cardClassName"]}
                ){" "}
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <Row>
            <Col lg={3} md={3} sm={3} xs={12} className="card-desc-form-view">
              <div>
                <AvForm
                  onValidSubmit={this.handleValidSubmit}
                  ref={(c) => (this.form = c)}
                >
                  <AvField
                    name="qty"
                    type="number"
                    label={Strings["Number of coupons"] + " *"}
                    placeholder={Strings["Enter Number of coupons"]}
                    value={this.state.qty}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <AvField
                    name="discountRate"
                    type="text"
                    label={Strings["Discount rate"] + " *"}
                    placeholder={Strings["Enter discount rate"]}
                    value={this.state.discountRate}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <AvField
                    name="fromDate"
                    type="date"
                    label={Strings["From Date"] + " *"}
                    value={this.state.fromDate}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <AvField
                    name="toDate"
                    type="date"
                    label={Strings["To Date"] + " *"}
                    value={this.state.toDate}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <AvField
                    label={Strings["One time use"]}
                    type="checkbox"
                    name="once"
                    ref="default"
                    value={this.state.once}
                    onChange={this.handleInputChange}
                    checked={
                      this.state.once == 1 || this.state.once == true
                        ? true
                        : false
                    }
                    tag={CustomInput}
                    className="custom-form-mb default mb-3 mr-19px"
                  />
                  <div className="text-right mt-4">
                    <button
                      type="submit"
                      className="btn form-save-btn mr-1 ml-1"
                    >
                      {" "}
                      {Strings["Save"]}{" "}
                    </button>
                    <button
                      type="button"
                      className="btn form-cancel-btn m-l-10"
                      onClick={(e) => this.handleCancelAddForm(e)}
                    >
                      {Strings["Cancel"]}
                    </button>
                  </div>

                  {this.state.submitError && (
                    <div className="error-text-black mt-4">
                      <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                      {this.state.submitError}
                    </div>
                  )}
                </AvForm>
              </div>
            </Col>
            <Col lg={9} md={9} sm={9} xs={12}>
              {this.state.loading == 1 && (
                <Row>
                  <Col
                    md={12}
                    style={{ marginTop: "15%" }}
                    className="text-center"
                  >
                    <div className="card-body">
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                    </div>
                  </Col>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length <= 0 && (
                <Row className="text-center" style={{ marginTop: "15%" }}>
                  <div>
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl(
                        "/media/ezpay/EZPAY_LOGO_GRAY" +
                          window.MAIN_IMAGE +
                          ".png"
                      )}
                      className="img-fluid no-data-img"
                    />
                    <div className="no-data-text">
                      {Strings["There is no data to display"]}
                    </div>
                  </div>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <div className="inv-cards-view">
                  <Row>
                    <Col
                      md={12}
                      className="mb-4 pr-0 pl-0 main-color-1 font-w-400 mt-2 f-15"
                    >
                      {Strings["Coupons"]}
                    </Col>
                  </Row>
                  <div>
                    <Row className="table-header-row">
                      <Col
                        lg={1}
                        md={1}
                        sm={1}
                        xs={12}
                        title={Strings["Qty"]}
                        className="main-color-1 font-w-400"
                      >
                        {Strings["Qty"]}
                      </Col>
                      <Col
                        lg={1}
                        md={1}
                        sm={1}
                        xs={12}
                        title={Strings["Qty remaining"]}
                        className="overflow-text-length"
                      >
                        {Strings["Qty remaining"]}
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                        title={Strings["Coupon type"]}
                        className="overflow-text-length"
                      >
                        {Strings["Coupon type"]}
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                        title={Strings["Discount rate"]}
                        className="overflow-text-length"
                      >
                        {Strings["Discount rate"]}
                      </Col>
                      <Col
                        lg={1}
                        md={1}
                        sm={1}
                        xs={12}
                        title={Strings["From Date"]}
                        className="overflow-text-length"
                      >
                        {Strings["From Date"]}
                      </Col>
                      <Col
                        lg={1}
                        md={1}
                        sm={1}
                        xs={12}
                        title={Strings["To Date"]}
                        className="overflow-text-length"
                      >
                        {Strings["To Date"]}
                      </Col>
                      <Col
                        lg={1}
                        md={1}
                        sm={1}
                        xs={12}
                        title={Strings["Status"]}
                        className="overflow-text-length"
                      >
                        {Strings["Status"]}
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                        title={Strings["Entry Date"]}
                        className="overflow-text-length"
                      >
                        {Strings["Entry Date"]}
                      </Col>
                      <Col
                        lg={1}
                        md={1}
                        sm={1}
                        xs={12}
                        title={Strings["Action"]}
                        className="text-center"
                      >
                        {Strings["Action"]}
                      </Col>
                    </Row>
                    {this.displayData()}
                  </div>
                </div>
              )}

              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Col md={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default AddCoupons;

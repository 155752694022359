import React, { Component } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { confirmAlert } from "react-confirm-alert";
import Alert from "@material-ui/lab/Alert";

import "react-confirm-alert/src/react-confirm-alert.css";
import { Button } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";
import Pagination from "react-js-pagination";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import PhoneInput from "react-phone-number-input";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ErrorIcon from "@material-ui/icons/Error";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CommentIcon from "@material-ui/icons/Comment";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import EditIcon from "@material-ui/icons/Edit";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import "../../../../node_modules/react-phone-number-input/style.css";
import { translations } from "../../../translate.js";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

var today = new Date();
var currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);

class RequestOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      loading: 1,
      data: [],
      statusList: [],
      limit: 10,
      activePage: 1,
      dataCount: 0,
      errorMsg: "",
      startSave: 0,
      posList: [],
      distList: [],
      requestId: "",
      dateFrom: currentDate,
      dateTo: currentDate,
      subscribeId: "",
      name: "",
      phone: "",
      orderId: "",
      editPhone: 0,
      requestTypeList: [],
      openClassList: "",
      cardCategoryId: "",
      cardCategoryList: [],
      selectedCardCategory: [],
      cardList: [],
      selectedCard: [],
      cardClassList: [],
      selectedCardClass: [],
      selectedRequestType: [],
      selectedStatus: [],
      dist: [],
      pos: [],
      statusDivList: [],
      sortId: 0,
    };
  }
  handleRefresh = () => {
    // clear all filter data
    this.setState(
      {
        data: [],
        currencyTitle: "",
        limit: 10,
        activePage: 1,
        dataCount: 0,
        errorMsg: "",
        startSave: 0,
        dist: [],
        pos: [],
        posList: [],
        requestId: "",
        dateFrom: currentDate,
        dateTo: currentDate,
        subscribeId: "",
        name: "",
        phone: "",
        orderId: "",
        cardList: [],
        cardClassList: [],
        cardClassId: "",
        sortId: "",
        selectedCardCategory: [],
        selectedCard: [],
        selectedCardClass: [],
        selectedRequestType: [],
        selectedStatus: [],
      },
      function() {
        this.getOrderRequset();
      }
    );
  };
  componentDidMount() {
    this.getOrderRequset();
    this.getAllStatus();
    this.getAllrequestType();
    this.getDistHavePos();
    this.getCardCategoryDirectCharge();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
          visibleid: "",
          visibleidRequest: "",
        });
      }
    }
  };

  getCardCategoryDirectCharge() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCardCategoryDirectCharge",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        this.setState({
          cardCategoryList: data["data"],
        });
      });
  }
  getCardsDirectCharge() {
    if (this.state.selectedCardCategory.length > 0) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getCardsDirectCharge",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          cardCat: this.getSelected("selectedCardCategory"),
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          this.setState({
            cardList: data["data"],
          });
        });
    }
  }
  getCardClassDirectCharge() {
    if (this.state.selectedCard.length > 0) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getCardClassDirectCharge",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          cardId: this.getSelected("selectedCard"),
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          this.setState({
            cardClassList: data["data"],
          });
        });
    }
  }
  getOrderRequset() {
    this.setState({ loading: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDirectChargeRequest",
        lang: localStorage.getItem("LANG"),
        posId: this.getSelected("pos"),
        orderId: this.state.orderId,
        distId: this.getSelected("dist"),
        dateFrom: this.state.dateFrom,
        dateTo: this.state.dateTo,
        status: this.getSelected("selectedStatus"),
        subscribeId: this.state.subscribeId,
        id: this.state.requestId,
        // 'name': this.state.name,
        phone: this.state.phone,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        page: this.state.activePage,
        limit: this.state.limit,
        requestType: this.getSelected("selectedRequestType"),
        cardId: this.getSelected("selectedCard"),
        classId: this.getSelected("selectedCardClass"),
        cardCat: this.getSelected("selectedCardCategory"),
        orderBy: this.state.sortId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ errorMsg: data["errorMsg"] });
          } else {
            this.setState({
              data: data["requsetData"],
              dataCount: data["requsetCount"][0]["count"],
              loading: 0,
            });
          }
        }
      });
  }
  getAllStatus() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getRequestStatus",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        this.setState({
          statusList: data["data"],
        });
        let statusDev = [];
        var item = [];
        for (let index in this.state.statusList) {
          item = this.state.statusList[index];
          statusDev[item.status_id] =
            localStorage.getItem("LANG") == 1
              ? item["status_name_ar"]
              : item["status_name_en"];
        }
        this.setState({
          statusDivList: statusDev,
        });
      });
  }
  getAllrequestType() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getRequestTypes",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        this.setState({
          requestTypeList: data["data"],
        });
      });
  }
  getDistHavePos() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistHavePos",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        this.setState({
          distList: data["data"],
        });
      });
  }
  getDistPos() {
    if (this.state.dist.length > 0) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getDistPos",
          lang: localStorage.getItem("LANG"),
          allPos: 2,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          getType: "all",
          distId: this.getSelected("dist"),
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          this.setState(
            {
              posList: data["data"],
            },
            () => {
              this.getOrderRequset();
            }
          );
        });
    }
  }

  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };
  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };
  exportHandler() {
    var orderId = this.state.orderId;
    if (orderId == "" || orderId == null || orderId == undefined) {
      orderId = "";
    }
    var dateFrom = this.state.dateFrom;
    if (dateFrom == "" || dateFrom == null || dateFrom == undefined) {
      dateFrom = "";
    }

    var dateTo = this.state.dateTo;
    if (dateTo == "" || dateTo == null || dateTo == undefined) {
      dateTo = "";
    }
    var subscribeId = this.state.subscribeId;
    if (subscribeId == "" || subscribeId == null || subscribeId == undefined) {
      subscribeId = "";
    }
    var name = this.state.name;
    if (name == "" || name == null || name == undefined) {
      name = "";
    }
    var phone = this.state.phoneNumber;
    if (phone == "" || phone == null || phone == undefined) {
      phone = "";
    }
    var requestId = this.state.requestId;
    if (requestId == "" || requestId == null || requestId == undefined) {
      requestId = "";
    }

    var orderBy = this.state.sortId;
    if (orderBy == "" || orderBy == null || orderBy == undefined) {
      orderBy = "";
    }
    window.location =
      window.API_PERM_USER +
      "/permUser.php?type=" +
      "getDirectChargeRequest" +
      "&lang=" +
      localStorage.getItem("LANG") +
      "&userId=" +
      localStorage.getItem("USER_ID") +
      "&token=" +
      localStorage.getItem("USER_TOKEN") +
      "&posId=" +
      this.getSelected("pos") +
      "&distId=" +
      this.getSelected("dist") +
      "&orderId=" +
      orderId +
      "&dateFrom=" +
      dateFrom +
      "&dateTo=" +
      dateTo +
      "&status=" +
      this.getSelected("selectedStatus") +
      "&subscribeId=" +
      subscribeId +
      "&name=" +
      name +
      "&phone=" +
      phone +
      "&id=" +
      requestId +
      "&orderBy=" +
      orderBy +
      "&requestType=" +
      this.getSelected("selectedRequestType") +
      "&cardId=" +
      this.getSelected("selectedCard") +
      "&classId=" +
      this.getSelected("selectedCardClass") +
      "&cardCat=" +
      this.getSelected("selectedCardCategory") +
      "&export=1";
  }

  getSelected = (type) => {
    if (type == "dist") {
      return this.state.dist.map((data) => data.userId);
    }
    if (type == "pos") {
      return this.state.pos.map((data) => data.userId);
    }
    if (type == "selectedRequestType") {
      return this.state.selectedRequestType.map((data) => data.requestType_id);
    }
    if (type == "selectedStatus") {
      return this.state.selectedStatus.map((data) => data.status_id);
    }
    if (type == "selectedCardCategory") {
      return this.state.selectedCardCategory.map((data) => data.id);
    }
    if (type == "selectedCard") {
      return this.state.selectedCard.map((data) => data.cardId);
    }
    if (type == "selectedCardClass") {
      return this.state.selectedCardClass.map((data) => data.id);
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getOrderRequset();
    });
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        activePage: 1,
      },
      function() {
        if (name == "statusId" || name == "note" || name == "phoneNumber") {
          // console.log(name);
        } else {
          this.getOrderRequset();
        }
      }
    );
  };

  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };

  openOrderAction = (event, type, data) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (type == "updatestatus") {
      this.setState(
        {
          openHeader: "",
          selectedId: data["status"]["status_num"],
          note: data["note"],
        },
        () => {
          this.UpdateStatus(data);
        }
      );
    } else if (type == "SMS") {
      this.sendSms(data["id"], type);
    } else if (type == "resendSMS") {
      this.sendSms(data["id"], type);
    } else if (type == "requestData") {
      this.setState({ modal: true, responsData: data });
    }
  };

  editPhoneHndler = (event, number) => {
    this.setState({ editPhone: 1, phoneNumber: number }, () => {});
  };

  // /*************SendSmsHandler/Start*********************//

  sendSms(id, type) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to send sms ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickSendSms(id, type);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  }

  handleClickSendSms(id, type) {
    console.log("resend", type == "SMS" ? "" : 1);
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "sendSmsToCustomer",
        id: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        resend: type == "SMS" ? "" : 1,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          console.log(data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
            this.getOrderRequset();
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <div className="mb-3">
                      <span className="alert-delete-icon">!</span>
                    </div>
                    <p>{data["errorMsg"]}</p>
                    <button
                      type="button"
                      className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                      onClick={() => {
                        this.getOrderRequset();
                        onClose();
                      }}
                    >
                      {Strings.Cancel}
                    </button>
                  </div>
                );
              },
            });
          }
        }
      });
  }
  // /*************SendSmsHandler/End*********************//

  setUpSelectedOptions = (data, type) => {
    return (
      data.status_id > this.state.selectedId && (
        <option value={data.status_id} key={data.status_id}>
          {localStorage.getItem("LANG") == 1
            ? data["status_name_ar"]
            : data["status_name_en"]}
        </option>
      )
    );
  };

  UpdateStatus(rowData) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            {rowData["status"]["status_num"] != 6 && (
              <p className={"requestStatusStyle"}>
                {Strings["please select the status of request"]}
              </p>
            )}
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
              className="mt-3 updateStatusForm"
            >
              {rowData["status"]["status_num"] != 6 && (
                <AvField
                  errorMessage={Strings["This field is required"]}
                  type="select"
                  name="statusId"
                  value={this.state.statusId}
                  onChange={this.handleInputChange}
                >
                  <option style={{ display: "none" }} value="" defaultValue>
                    {Strings["Select Status"]}
                  </option>
                  {this.state.statusList.map((data) =>
                    this.setUpSelectedOptions(data, "status")
                  )}
                </AvField>
              )}
              <AvField
                type="textarea"
                name="note"
                rows={5}
                label={Strings["Note"]}
                className={"mt-2"}
                value={this.state.note}
                onChange={this.handleInputChange}
              />
            </AvForm>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={() => {
                this.setState({ statusId: "" });
                onClose();
              }}
            >
              {Strings.Cancel}
            </button>
            <button
              type="submit"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickUpdateStatus(rowData["id"]);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  }
  handleClickUpdateStatus(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "updateStatusDirectChargeRequest",
        status: this.state.statusId,
        id: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        note: this.state.note,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        console.log("data", data);
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
            this.getOrderRequset();
            this.setState({ statusId: "" });
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <div className="mb-3">
                      <span className="alert-delete-icon">!</span>
                    </div>
                    <p>{data["errorMsg"]}</p>
                    <button
                      type="button"
                      className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                      onClick={() => {
                        this.getOrderRequset();
                        this.setState({ statusId: "" });
                        onClose();
                      }}
                    >
                      {Strings.Cancel}
                    </button>
                  </div>
                );
              },
            });
          }
        }
      });
  }

  onVisibleChange = (visible, id, type) => {
    this.setState({
      [type]: id,
    });
  };

  openClassList = (id) => {
    this.setState({ openClassList: id });
  };

  handleChangePos = (type, value, event) => {
    this.setState(
      (state) => {
        return {
          [type]: value,
          activePage: 1,
        };
      },
      function() {
        if (type == "dist") {
          this.setState(
            {
              pos: [],
            },
            () => {
              this.getDistPos();
              this.getOrderRequset();
            }
          );
        } else if (type == "selectedCardCategory") {
          this.setState(
            {
              cardList: [],
              cardClassList: [],
            },
            () => {
              this.getCardsDirectCharge();
              this.getOrderRequset();
            }
          );
        } else if (type == "selectedCard") {
          this.setState(
            {
              cardClassList: [],
            },
            () => {
              this.getCardClassDirectCharge();
              this.getOrderRequset();
            }
          );
        } else {
          this.getOrderRequset();
        }
      }
    );
  };
  displayData() {
    var orderInfo = "";
    if (this.state.loading == 0 && this.state.data.length > 0) {
      var classStyle = {
        "text-6": "gray-color",
        "text-3": "warning-color",
        "text-4": "succ-color",
        "text-5": "reject-color",
        "text-1": "addCredit-color",
        "text-2": "wthdrawal-color",
        "text-7": "status-9-color",
      };
      var classColStatus = "";
      orderInfo = this.state.data.map((rowData, index) => {
        if (rowData["status"]["status_num"] !== "") {
          console.log(rowData["status"]["status_num"], rowData);
          classColStatus =
            classStyle["text-" + rowData["status"]["status_num"]];
        }
        classColStatus =
          " overflow-text-length  bold-font " + classColStatus + " ";
        return (
          <Row key={index} className="table-body-row">
            <Col
              xl={1}
              lg={1}
              md={3}
              sm={4}
              xs={12}
              className="overflow-text-length"
              title={rowData["id"]}
            >
              {rowData["id"]}
            </Col>
            <Col
              xl={1}
              lg={1}
              md={3}
              sm={4}
              xs={12}
              className="overflow-text-length"
              title={rowData["orderId"]}
            >
              {rowData["orderId"]}
            </Col>
            <Col
              xl={1}
              lg={1}
              md={3}
              sm={4}
              xs={12}
              className="overflow-text-length d-flex"
            >
              <div title={Strings["responseData"]}>
                <Tooltip
                  title={Strings["responseData"]}
                  visible={this.state.visibleid == rowData["id"] ? true : false}
                  animation="zoom"
                  onVisibleChange={(event) =>
                    this.onVisibleChange(event, rowData["id"], "visibleid")
                  }
                  trigger="click"
                  overlay={
                    <span className="report-tooltip-span">
                      {rowData["responseData"]["orderId"] != undefined &&
                        rowData["responseData"]["orderId"] != "" &&
                        rowData["responseData"]["orderId"] != null && (
                          <p className="mb-0 mr-1 tooltipRequestStyle">
                            {"#" + "" + rowData["responseData"]["orderId"]}
                          </p>
                        )}
                      <p className="mb-0 mr-1 tooltipRequestStyle">
                        {Strings["message"] +
                          " : " +
                          (localStorage.getItem("LANG") == 1
                            ? rowData["responseData"]["msq_Ar"]
                            : rowData["responseData"]["msq_En"])}
                      </p>
                      <p className="mb-0 mr-1 tooltipRequestStyle">
                        {Strings["Status"] +
                          " : " +
                          (localStorage.getItem("LANG") == 1
                            ? rowData["responseData"]["status_AR"]
                            : rowData["responseData"]["status_En"])}{" "}
                      </p>
                    </span>
                  }
                >
                  <InfoOutlinedIcon
                    title={Strings["responseData"]}
                    className="reset-filter-btn mt-1 mb-1 hover-green"
                  />
                </Tooltip>
              </div>
              <div title={Strings["Class Name"]}>
                {this.state.openClassList == rowData["id"] ? (
                  <ExpandLessIcon
                    className="pointer-cursor reset-filter-btn hover-green"
                    onClick={() => this.openClassList(rowData[""])}
                  />
                ) : (
                  <ExpandMoreIcon
                    className="pointer-cursor reset-filter-btn hover-green"
                    onClick={() => this.openClassList(rowData["id"])}
                  />
                )}
              </div>
              <div title={Strings["Note"]} className="mr-1 ml-1">
                {((rowData["note"] != undefined &&
                  rowData["note"] != "" &&
                  rowData["note"] != null) ||
                  (rowData["noteTwo"] != undefined &&
                    rowData["noteTwo"] != "" &&
                    rowData["noteTwo"] != null) ||
                  (rowData["noteThree"] != undefined &&
                    rowData["noteThree"] != "" &&
                    rowData["noteThree"] != null)) && (
                  <Tooltip
                    title={Strings["Request Data"]}
                    visible={
                      this.state.visibleidRequest == rowData["id"]
                        ? true
                        : false
                    }
                    animation="zoom"
                    onVisibleChange={(event) =>
                      this.onVisibleChange(
                        event,
                        rowData["id"],
                        "visibleidRequest"
                      )
                    }
                    trigger="click"
                    overlay={
                      <span className="report-tooltip-span">
                        {rowData["note"] != undefined &&
                          rowData["note"] != "" &&
                          rowData["note"] != null && (
                            <li className="mb-0 mr-1 tooltipRequestStyle">
                              {rowData["note"]}
                            </li>
                          )}
                        {rowData["noteTwo"] != undefined &&
                          rowData["noteTwo"] != "" &&
                          rowData["noteTwo"] != null && (
                            <li className="mb-0 mr-1 tooltipRequestStyle">
                              {rowData["noteTwo"]}
                            </li>
                          )}

                        {rowData["noteThree"] != undefined &&
                          rowData["noteThree"] != "" &&
                          rowData["noteThree"] != null && (
                            <li className="mb-0 mr-1 tooltipRequestStyle">
                              {rowData["noteThree"]}
                            </li>
                          )}
                      </span>
                    }
                  >
                    <CommentIcon
                      title={Strings["Notes"]}
                      className="reset-filter-btn mt-1 mb-1"
                    />
                  </Tooltip>
                )}
              </div>
            </Col>

            <Col
              xl={1}
              lg={1}
              md={3}
              sm={4}
              xs={12}
              className="overflow-text-length"
              title={
                localStorage.getItem("LANG") == 1
                  ? rowData["requestType"]["requestType_AR"]
                  : rowData["requestType"]["requestType_En"]
              }
            >
              {localStorage.getItem("LANG") == 1
                ? rowData["requestType"]["requestType_AR"]
                : rowData["requestType"]["requestType_En"]}
            </Col>

            <Col
              xl={2}
              lg={2}
              md={3}
              sm={4}
              xs={12}
              className="overflow-text-length"
              title={rowData["posName"]}
            >
              {rowData["posName"]}
            </Col>
            <Col
              xl={2}
              lg={2}
              md={3}
              sm={4}
              xs={12}
              className="overflow-text-length"
              title={rowData["distName"]}
            >
              {" "}
              {rowData["distName"]}
            </Col>
            <Col
              xl={2}
              lg={2}
              md={3}
              sm={4}
              xs={12}
              className={classColStatus}
              title={
                localStorage.getItem("LANG") == 1
                  ? rowData["status"]["status_AR"]
                  : rowData["status"]["status_En"]
              }
            >
              {localStorage.getItem("LANG") == 1
                ? rowData["status"]["status_AR"]
                : rowData["status"]["status_En"]}
            </Col>

            <Col
              xl={1}
              lg={1}
              md={3}
              sm={4}
              xs={12}
              className="overflow-text-length  bt-text-align-left "
              title={rowData["time"] + " / " + rowData["date"]}
            >
              {rowData["time"]}
              <br></br>
              {rowData["date"]}
            </Col>
            <Col
              xl={1}
              lg={1}
              md={3}
              sm={4}
              xs={12}
              className={
                "action-icon-view actionDropdownList actionDropdownListPadding-0"
              }
            >
              <Dropdown
                isOpen={this.state.openHeader == rowData["id"] ? true : false}
                className="font-size-13 actionDropdownList"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link pt-0 actionDropdownList actionDropdownListPadding-0"
                  caret
                  onClick={(event) => this.openHeaderMenu(event, rowData["id"])}
                >
                  <MoreVertIcon
                    className="menu-dots-vertical actionDropdownList"
                    onClick={(event) =>
                      this.openHeaderMenu(event, rowData["id"])
                    }
                  />
                </DropdownToggle>
                <DropdownMenu className="font-size-13  actionDropdownList">
                  <div
                    className="actionDropdownList"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    {/* /////update Status /////*/}

                    <DropdownItem
                      className="actionDropdownList textRight"
                      onClick={(event) =>
                        this.openOrderAction(event, "updatestatus", rowData)
                      }
                    >
                      {Strings["update status"]}
                    </DropdownItem>

                    {/* /////send SMS /////*/}
                    {rowData["status"]["status_num"] == 4 &&
                      rowData["dateSendMsq"] == "" && (
                        <DropdownItem
                          onClick={(event) =>
                            this.openOrderAction(event, "SMS", rowData)
                          }
                          className="actionDropdownList textRight"
                        >
                          SMS
                        </DropdownItem>
                      )}
                    {rowData["status"]["status_num"] == 4 &&
                      rowData["dateSendMsq"] != "" && (
                        <DropdownItem
                          className="actionDropdownList textRight"
                          onClick={(event) =>
                            this.openOrderAction(event, "resendSMS", rowData)
                          }
                        >
                          {Strings["Resend SMS"]}
                        </DropdownItem>
                      )}
                    {/* //Request Data */}

                    <DropdownItem
                      onClick={(event) =>
                        this.openOrderAction(event, "requestData", rowData)
                      }
                      className="actionDropdownList textRight"
                    >
                      {Strings["Request Data"]}
                    </DropdownItem>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </Col>
            {this.state.openClassList == rowData["id"] && (
              <Col md={12} className="pt-3">
                <div>{Strings["Class Name"]} :</div>
                {rowData["className"]}
              </Col>
            )}
          </Row>
        );
      });
    }
    return orderInfo;
  }

  toggle(value) {
    this.setState({ modal: value, editPhone: 0 });
  }

  updatePhone(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "updatePhoneDirectCharge",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        id: id,
        phone: this.state.phoneNumber,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data["code"] == 200) {
          this.setState({ editPhone: 0, modal: false }, () => {
            this.getOrderRequset();
          });
        }
      });
  }

  copyText(type) {
    navigator.clipboard.writeText(this.state.responsData[type]);
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 1000);
  }
  render() {
    return (
      <div>
        {/* ///FiliterView--Start///// */}

        <Row className="page-filter-view">
          <Col lg={5} md={7} sm={12} xs={12}>
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
            >
              <Row className="filter-form requestfilter-form">
                <Col md={5} lg={6} sm={5} xs={12}>
                  <AvField
                    name="dateFrom"
                    type="date"
                    label={Strings["From Date"]}
                    value={this.state.dateFrom}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col
                  md={5}
                  lg={4}
                  sm={5}
                  xs={12}
                  className={
                    localStorage.getItem("LANG") == 2 ? "pr-0" : "pl-0"
                  }
                >
                  <AvField
                    name="dateTo"
                    type="date"
                    label={Strings["To Date"]}
                    value={this.state.dateTo}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col
                  md={2}
                  lg={2}
                  sm={2}
                  xs={4}
                  className={
                    localStorage.getItem("LANG") == 2
                      ? "pl-1 mb-2"
                      : "pr-1 mb-2"
                  }
                >
                  <Button
                    variant="contained"
                    className="refresh-action-btn refresh-btn-margin"
                    onClick={this.handleRefresh}
                    title={Strings["Refresh"]}
                  >
                    <i className="flaticon-refresh reset-filter-btn"></i>
                  </Button>
                </Col>

                <Col md={5} lg={6} sm={5} xs={12}>
                  <AvField
                    name="orderId"
                    type="number"
                    placeholder={Strings["number of order"]}
                    value={this.state.orderId}
                    onChange={this.handleInputChange}
                  />
                </Col>

                <Col md={5} lg={6} sm={5} xs={12}>
                  <AvField
                    name="requestId"
                    type="number"
                    placeholder={Strings["number of request"]}
                    value={this.state.requestId}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={5} lg={6} sm={5} xs={12}>
                  <AvField
                    name="subscribeId"
                    type="number"
                    placeholder={Strings["subscribe #"]}
                    value={this.state.subscribeId}
                    onChange={this.handleInputChange}
                  />
                </Col>

                {/* <Col md={5} lg={6} sm={5} xs={12}>

                                    <AvField name="name" placeholder={Strings['Name']} value={this.state.name} onChange={this.handleInputChange} />
                                </Col> */}
                <Col md={5} lg={6} sm={5} xs={12}>
                  <div className="member-searchable-list mb-2 mt-1 pickyList">
                    <Picky
                      value={this.state.dist}
                      options={this.state.distList}
                      onChange={this.handleChangePos.bind(this, "dist")}
                      open={false}
                      valueKey="userId"
                      labelKey="name"
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["Distributor Name"]}
                      filterPlaceholder={Strings["select distributor"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>
                </Col>
                <Col md={5} lg={6} sm={5} xs={12}>
                  <div className="member-searchable-list posPicky mb-2 mt-1 pickyList">
                    <Picky
                      disabled={this.state.posList.length <= 0}
                      value={this.state.pos}
                      options={this.state.posList}
                      onChange={this.handleChangePos.bind(this, "pos")}
                      open={false}
                      valueKey="userId"
                      labelKey="name"
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["POS Name"]}
                      filterPlaceholder={Strings["select POS"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>
                </Col>
                <Col md={5} lg={6} sm={5} xs={12}>
                  <div className="member-searchable-list posPicky mb-2 mt-1 pickyList">
                    <Picky
                      disabled={this.state.requestTypeList.length <= 0}
                      value={this.state.selectedRequestType}
                      options={this.state.requestTypeList}
                      onChange={this.handleChangePos.bind(
                        this,
                        "selectedRequestType"
                      )}
                      open={false}
                      valueKey="requestType_id"
                      labelKey={
                        localStorage.getItem("LANG") == 1
                          ? "requestType_name_ar"
                          : "requestType_name_en"
                      }
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["requestType"]}
                      filterPlaceholder={Strings["Select requestType"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>
                </Col>

                <Col md={5} lg={6} sm={5} xs={12}>
                  <div className="member-searchable-list posPicky mb-2 mt-1 pickyList">
                    <Picky
                      disabled={this.state.statusList.length <= 0}
                      value={this.state.selectedStatus}
                      options={this.state.statusList}
                      onChange={this.handleChangePos.bind(
                        this,
                        "selectedStatus"
                      )}
                      open={false}
                      valueKey="status_id"
                      labelKey={
                        localStorage.getItem("LANG") == 1
                          ? "status_name_ar"
                          : "status_name_en"
                      }
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["Status"]}
                      filterPlaceholder={Strings["Select Status"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>
                </Col>

                <Col md={5} lg={6} sm={5} xs={12}>
                  <div className="member-searchable-list posPicky mb-2 mt-1 pickyList">
                    <Picky
                      disabled={this.state.cardCategoryList.length <= 0}
                      value={this.state.selectedCardCategory}
                      options={this.state.cardCategoryList}
                      onChange={this.handleChangePos.bind(
                        this,
                        "selectedCardCategory"
                      )}
                      open={false}
                      valueKey="id"
                      labelKey={
                        localStorage.getItem("LANG") == 1 ? "name" : "name_en"
                      }
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["Card Category"]}
                      filterPlaceholder={Strings["Select Card Category"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>
                </Col>

                <Col md={5} lg={6} sm={5} xs={12}>
                  <div className="member-searchable-list posPicky mb-2 mt-1 pickyList">
                    <Picky
                      disabled={this.state.cardList.length <= 0}
                      value={this.state.selectedCard}
                      options={this.state.cardList}
                      onChange={this.handleChangePos.bind(this, "selectedCard")}
                      open={false}
                      valueKey="cardId"
                      labelKey={
                        localStorage.getItem("LANG") == 1 ? "name" : "name_en"
                      }
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["Card"]}
                      filterPlaceholder={Strings["Select Card"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>
                </Col>
                <Col md={5} lg={6} sm={5} xs={12}>
                  <div className="member-searchable-list posPicky mb-2 mt-1 pickyList">
                    <Picky
                      disabled={this.state.cardClassList.length <= 0}
                      value={this.state.selectedCardClass}
                      options={this.state.cardClassList}
                      onChange={this.handleChangePos.bind(
                        this,
                        "selectedCardClass"
                      )}
                      open={false}
                      valueKey="id"
                      labelKey={
                        localStorage.getItem("LANG") == 1 ? "name" : "name_en"
                      }
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["Card Class"]}
                      filterPlaceholder={Strings["Select Card Class"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>
                </Col>

                <Col md={5} lg={6} sm={5} xs={12}>
                  <AvField
                    errorMessage={Strings["This field is required"]}
                    type="select"
                    name="sortId"
                    value={this.state.sortId}
                    onChange={this.handleInputChange}
                  >
                    <option style={{ display: "none" }} value="" defaultValue>
                      {Strings["sort by"]}
                    </option>

                    <option value={1}>{Strings["oldest"]}</option>

                    <option value={0}>{Strings["Newest"]}</option>
                  </AvField>
                </Col>

                <Col md={5} lg={6} sm={5} xs={12}>
                  <AvField
                    name="phone"
                    type="number"
                    placeholder={Strings["phone with country code"]}
                    value={this.state.phone}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
            </AvForm>
          </Col>
          <Col md={7} className="text-right">
            <Col md={12} lg={12} sm={12}>
              <button
                className="btn page-action-btn ml-1"
                onClick={(event) => this.exportHandler(event)}
              >
                {Strings["Export"]}
              </button>
            </Col>
            {this.state.statusDivList.length > 0 && (
              <Col md={12} lg={12} sm={12} className={"mt-2"}>
                <div className="balRechargeDist-square-view mt-130">
                  <div className="task-square-div">
                    {" "}
                    <div className="status-square-5"></div>{" "}
                    <p className="mb-0 mr-1"> {this.state.statusDivList[1]} </p>{" "}
                  </div>
                  <div className="task-square-div">
                    {" "}
                    <div className="status-square-6 "></div>{" "}
                    <p className="mb-0 mr-1"> {this.state.statusDivList[2]} </p>{" "}
                  </div>
                  <div className="task-square-div">
                    {" "}
                    <div className="status-square-2"></div>{" "}
                    <p className="mb-0 mr-1"> {this.state.statusDivList[3]}</p>{" "}
                  </div>
                  <div className="task-square-div">
                    {" "}
                    <div className="status-square-3"></div>{" "}
                    <p className="mb-0 mr-1"> {this.state.statusDivList[4]} </p>{" "}
                  </div>
                  <div className="task-square-div">
                    {" "}
                    <div className="status-square-4 "></div>{" "}
                    <p className="mb-0 mr-1"> {this.state.statusDivList[5]} </p>{" "}
                  </div>
                  <div className="task-square-div">
                    {" "}
                    <div className="status-square-9 "></div>{" "}
                    <p className="mb-0 mr-1"> {this.state.statusDivList[7]} </p>{" "}
                  </div>
                  <div className="task-square-div mb-0">
                    {" "}
                    <div className="status-square-1"></div>{" "}
                    <p className="mb-0 mr-1"> {this.state.statusDivList[6]} </p>{" "}
                  </div>
                </div>
              </Col>
            )}
          </Col>
        </Row>
        {/* ///FiliterView--End///// */}

        {/* //ModalView---Start ////*/}
        {this.state.responsData != null &&
          this.state.responsData != undefined &&
          this.state.responsData != "" && (
            <div className={"requestdataModal"}>
              <Modal
                isOpen={this.state.modal}
                centered={true}
                className={"requestdataModal"}
              >
                <div
                  className="close-modal-btn "
                  onClick={() => this.toggle(false)}
                >
                  X
                </div>
                <ModalBody>
                  <p
                    className={
                      "requestStatusStyle black-font mb-4 text-center requestDataTitleStyle"
                    }
                  >
                    {this.state.editPhone == 0
                      ? Strings["Request Information"]
                      : Strings["Edit phone"]}
                  </p>
                  {/* {this.state.editPhone == 0 &&
                                    <Row className={'mb-3 mt-4'}>
                                        <Col md={9} className={'d-flex'}>
                                            <p className='mb-0 mr-1 tooltipRequestStyle1 black-font font-w-500 text-left'>
                                                {Strings['Name'] + ":"}
                                            </p>
                                            <p className='mb-0 mr-1 tooltipRequestStyle black-font text-left'>
                                                {this.state.responsData['name']}
                                            </p>
                                        </Col>
                                        <Col md={3} className={'text-right'} >
                                            <FileCopyIcon className={'reset-filter-btn'}
                                                onClick={() => {
                                                    this.copyText('name')
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                } */}
                  {this.state.editPhone == 0 && (
                    <Row className={"mb-3"}>
                      <Col md={9} className={"d-flex"}>
                        <p className="mb-0 mr-1 tooltipRequestStyle1 black-font font-w-500  text-left">
                          {Strings["subscribe #"] + ":"}
                        </p>
                        <p className="mb-0 mr-1 tooltipRequestStyle black-font text-left">
                          {this.state.responsData["subscribeId"]}
                        </p>
                      </Col>
                      <Col md={3} className={"text-right"}>
                        <FileCopyIcon
                          className={"reset-filter-btn hover-green"}
                          onClick={() => {
                            this.copyText("subscribeId");
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                  {this.state.editPhone == 0 && (
                    <Row className={"mb-3"}>
                      <Col md={9} className={"d-flex"}>
                        <p className="mb-0 mr-1 tooltipRequestStyle1 black-font font-w-500  text-left">
                          {Strings["invoiceAmount"] + ":"}
                        </p>
                        <p className="mb-0 mr-1 tooltipRequestStyle black-font text-left">
                          {this.state.responsData["invoiceAmount"]}
                        </p>
                      </Col>
                      <Col md={3} className={"text-right"}>
                        <FileCopyIcon
                          className={"reset-filter-btn hover-green"}
                          onClick={() => {
                            this.copyText("invoiceAmount");
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row className={"mb-1"}>
                    <Col md={9} className={"d-flex"}>
                      <p className="mb-0 mr-1 tooltipRequestStyle1 black-font font-w-500 text-left">
                        {Strings["Phone"] + ":"}
                      </p>
                      {this.state.editPhone == 0 && (
                        <p
                          className={
                            "mb-0 mr-1 tooltipRequestStyle black-font text-left"
                          }
                        >
                          {this.state.responsData["phone"]}
                        </p>
                      )}
                    </Col>
                    {this.state.editPhone == 1 && (
                      <Col md={12} lg={12} sm={12} xs={12} className=" mt-2">
                        <AvForm
                          onValidSubmit={this.handleValidSubmit}
                          ref={(c) => (this.form = c)}
                          className="editPhoneForm"
                        >
                          <AvField
                            name="phoneNumber"
                            type="number"
                            placeholder={Strings["phone with country code"]}
                            value={this.state.phoneNumber}
                            onChange={this.handleInputChange}
                          />
                          <span className="editPhoneLabelStyle">
                            {Strings["phone with country code"]}
                          </span>
                        </AvForm>
                        <div className="mb-2 text-center mt-2">
                          <button
                            type="button"
                            className="btn form-save-btn"
                            onClick={() =>
                              this.updatePhone(this.state.responsData["id"])
                            }
                          >
                            {Strings.Yes}
                          </button>
                          <button
                            type="button"
                            className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                            onClick={() => {
                              this.setState({ editPhone: 0 });
                            }}
                          >
                            {Strings.Cancel}
                          </button>
                        </div>
                      </Col>
                    )}

                    <Col md={3} className={"text-right"}>
                      {this.state.editPhone == 0 && (
                        <EditIcon
                          className={"reset-filter-btn hover-green"}
                          onClick={(e) =>
                            this.editPhoneHndler(
                              e,
                              this.state.responsData["phone"]
                            )
                          }
                        />
                      )}
                      {this.state.editPhone == 0 && (
                        <FileCopyIcon
                          className={"reset-filter-btn hover-green"}
                          onClick={() => {
                            this.copyText("phone");
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      {this.state.copied && (
                        <Alert
                          className={"copyTextStle pl-0 pr-0"}
                          severity="success"
                        >
                          {Strings["copied"]}
                        </Alert>
                      )}
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </div>
          )}
        {/* //ModalView----End//// */}

        {this.state.loading == 0 && this.state.errorMsg != "" && (
          <Row>
            <div className="inv-cards-view pb-5 width-100">
              <div
                className="text-center"
                style={{ marginTop: "15%", marginBottom: "15%" }}
              >
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                  )}
                  className="img-fluid no-data-img"
                />
                <div className="no-data-text">{this.state.errorMsg}</div>
              </div>
            </div>
          </Row>
        )}

        {this.state.loading == 0 && this.state.data.length <= 0 && (
          <Row>
            <div className="inv-cards-view pb-5 width-100">
              <div
                className="text-center"
                style={{ marginTop: "15%", marginBottom: "15%" }}
              >
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                  )}
                  className="img-fluid no-data-img"
                />
                <div className="no-data-text">
                  {Strings["There is no data to display"]}
                </div>
              </div>
            </div>
          </Row>
        )}

        {this.state.loading == 1 && (
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}

        {this.state.loading == 0 && this.state.data.length > 0 && (
          <Row>
            <div className="inv-cards-view width-100">
              <Row className="table-header-row">
                <Col
                  lg={1}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["number of request"]}
                >
                  {Strings["number of request"]}
                </Col>
                <Col
                  lg={1}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["number of order"]}
                >
                  {Strings["number of order"]}
                </Col>
                <Col
                  lg={1}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["info "]}
                >
                  {Strings["info "]}
                </Col>
                <Col
                  lg={1}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["requestType"]}
                >
                  {Strings["requestType"]}
                </Col>
                <Col
                  lg={2}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["POS Name"]}
                >
                  {Strings["POS Name"]}
                </Col>
                <Col
                  lg={2}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Distributor Name"]}
                >
                  {" "}
                  {Strings["Distributor Name"]}
                </Col>
                <Col
                  lg={2}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Status"]}
                >
                  {Strings["Status"]}
                </Col>
                <Col
                  lg={1}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length bt-text-align-left "
                  title={Strings["Entry Date"]}
                >
                  {" "}
                  {Strings["Entry Date"]}
                </Col>
                <Col
                  lg={1}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length  bt-text-align-left "
                  title={Strings["Action"]}
                >
                  {" "}
                  {Strings["Action"]}
                </Col>
              </Row>
              {this.displayData()}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Col md={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              )}
            </div>
          </Row>
        )}
      </div>
    );
  }
}
export default RequestOrder;

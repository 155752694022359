/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import { translations } from '../../../translate.js';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;
class UserProfile extends React.Component {
  render() {
    const { user, showHi, showAvatar, showBadge } = this.props;

    return (
      <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div className="kt-header__topbar-user">
            {showHi && (
              <span className="kt-header__topbar-welcome kt-hidden-mobile">
                Hi,
              </span>
            )}

            {showHi && (
              <span className="kt-header__topbar-username kt-hidden-mobile">
                {user.fullname}
              </span>
            )}

            {showAvatar && <img alt="Pic" src={user.pic} />}

            {showBadge && (
              <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
                {/* TODO: Should get from currentUser */}
                <div className='header-user-name-length direction-account-name' title={localStorage.getItem('USER_FULL_NAME')}>{localStorage.getItem('USER_FULL_NAME')}</div>
              </span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {/* <div
            className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
            }}
          >
            <div className="kt-user-card__avatar">
              <img alt="Pic" className="kt-hidden" src={user.pic} />
              <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                S
              </span>
            </div>
            <div className="kt-user-card__name">{user.fullname}</div>
            <div className="kt-user-card__badge">
              <span className="btn btn-success btn-sm btn-bold btn-font-md">
                23 messages
              </span>
            </div>
          </div> */}
          <div className="kt-notification">
            {/* <a className="kt-notification__item textRight">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 kt-font-success" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                   {Strings['My Profile']}
                </div>
                <div className="kt-notification__item-time">
                  {Strings['Account settings and more']}
                </div>
              </div>
            </a>   */}

            {(localStorage.getItem('PERM_TYPE') == 2 || localStorage.getItem('PERM_TYPE') == 3) &&
              <div className="kt-notification__custom hover-menu" onClick={() => this.props.openSidebarModal(true, "account")}>
                <div className="main-color-1 font-w-400 pointer-cursor" >
                  <AccountBoxOutlinedIcon /> {Strings['My Account']}
                </div>
              </div>
            }
            {(localStorage.getItem('INV_MGR') == 1 || localStorage.getItem('POS_MGR_EMP') == 1 || localStorage.getItem('ACC_EMP') == 1 || localStorage.getItem('OPERA_EMP') == 1) &&
              <div className="kt-notification__custom hover-menu" onClick={() => this.props.openSidebarModal(true, "main_account")}>
                <div className="main-color-1 font-w-400 pointer-cursor" >
                  <AccountBoxOutlinedIcon /> {Strings['My Account']}
                </div>
              </div>
            }
            {(localStorage.getItem('OPERA_EMP') == 1) &&
              <div className="kt-notification__custom hover-menu" onClick={() => this.props.openSidebarModal(true, "notification")}>
                <div className="main-color-1 font-w-400 pointer-cursor" >
                  <NotificationsActiveOutlinedIcon /> {Strings['POS Notifications']}
                </div>
              </div>
            }
            {(localStorage.getItem('OPERA_EMP') == 1) &&
              <div className="kt-notification__custom hover-menu" onClick={() => this.props.openSidebarModal(true, "add_balance")}>
                <div className="main-color-1 font-w-400 pointer-cursor" >
                  <AccountBalanceWalletOutlinedIcon /> {Strings['Add Balance to POS']}
                </div>
              </div>
            }
            <div className="kt-notification__custom hover-menu">
              <Link
                to="/logout"
                className="main-color-1 font-w-400" >
                <ExitToAppIcon /> {Strings['Sign Out']}
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(UserProfile);

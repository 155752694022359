import React, { useContext, useEffect } from "react";
import { translations } from "../../../translate.js";
import { MyContext } from "../../../app/Context/MyContext.jsx";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
export default function BalanceDynamic() {
  const { balance } = useContext(MyContext);

  return (
    <div className="text-center mb-3 left-menu-balance-view">
      <div className="main-color-1 font-w-500">{Strings["Balance"]}</div>
      <div>{balance}</div>
    </div>
  );
}

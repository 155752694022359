import React, { Component } from "react";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Drawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic";
import Pagination from "react-js-pagination";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import 'react-picky/dist/picky.css';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { translations } from '../../../../translate.js';
import PurchaseOrderInfo from "./purchaseOrderInfo";
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;



class ReviewPurchaseOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 0,
            limit: 25,
            activePage: 1,
            dataCount: 0,
            cartDataRequests: [],
            openleftModal: false,
            rowData: [],
            cartId: this.props.data.id,
            distId: this.props.data.userId,


        }
    }

    componentDidMount() {
        // console.log(this.props.data);
        this.getCartOrders();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (event.target.className != undefined) {
            if (!event.target.classList.contains('actionDropdownList')) {
                this.setState({
                    openHeader: ''
                })
            }
        }
    }

    getCartOrders = () => {

        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCartOrders',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'distId': this.props.data.userId,
                'cartId': this.props.data.id,
                "start": this.state.activePage,
                "limit": this.state.limit
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    // console.log('cartDataRequests');
                    // console.log(data['data']);
                    this.setState({
                        discountValue: data['data']['discountValue'],
                        cartDataRequests: data['data']['requests'],
                        loading: 1,
                        dataCount: parseInt(data['count'])
                    })
                }
            }
        })
    }

    deleteCart = (event, id) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <div className="mb-3">
                            <span className="alert-delete-icon">!</span>
                        </div>
                        <h5>{Strings['Are you sure ?']}</h5>
                        <p>{Strings['You want to delete this cart ?']}</p>
                        <button type="button" className="btn form-cancel-btn alert-btn-margin ml-2 mr-2" onClick={onClose}>{Strings.Cancel}</button>
                        <button type="button" className="btn form-save-btn"
                            onClick={() => {
                                this.handleClickDelete(id);
                                onClose();
                            }}
                        >
                            {Strings.Yes}
                        </button>
                    </div>
                );
            }
        });
    }

    handleClickDelete = (id) => {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'deleteCartRequestBulk',
                'requestId': id,
                'cartId': this.props.data.id,
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({ activePage: 1, loading: 0 }, function () {
                        this.getCartOrders()
                    });
                }
            }
        })
    }
    exportHandler = () => {


        var cartId = this.state.cartId
        if (cartId == '' || cartId == null || cartId == undefined) {
            cartId = ''
        }

        var distId = this.state.distId
        if (distId == '' || distId == null || distId == undefined) {
            distId = ''
        }

        window.location = window.API_PERM_USER + "?type=" + "getCartOrders" + "&lang=" + localStorage.getItem('LANG') + "&userId=" + localStorage.getItem('USER_ID')
            + "&token=" + localStorage.getItem('USER_TOKEN') +
            "&cartId=" + cartId +
            "&distId=" + distId +
            "&export=1"

    }
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            this.getCartOrders();
        });
    }


    openHeaderMenu = (event, id) => {
        event.preventDefault();
        if (event.target.classList[1] != undefined && event.target.classList[1] == "menu-dots-vertical" && id != undefined) {
            var val = ''
            if (this.state.openHeader == id) {
                val = ''
            } else if (id != undefined) {
                val = id
            }
            this.setState({
                openHeader: val
            })
        } else if (id == undefined && event.target.classList[1] != "menu-dots-vertical") {
            this.setState({
                openHeader: ''
            })
        }
    }

    reviewPurchaseOrders = (event, data) => {
        this.setState({ openleftModal: true, rowData: data })
    }

    handleCloseSideBar = () => {
        this.setState({ openleftModal: false, })
    }


    displayData = () => {
        var cartInfo = ''
        if (this.state.loading == 1 && this.state.cartDataRequests.length > 0) {
            cartInfo = this.state.cartDataRequests.map((rowData, index) => {
                // console.log(rowData);
                return (
                    <Row key={index} className='table-body-row'>
                        <Col lg={3} md={3} sm={6} xs={6} className="overflow-text-length" >#{rowData['id']}</Col>
                        <Col lg={3} md={3} sm={6} xs={6} className="overflow-text-length" title={rowData['orderId']}>{rowData['orderId'] != null && rowData['orderId'] != undefined && rowData['orderId'] != '' ? rowData['orderId'] : '-'}</Col>
                        <Col lg={4} md={4} sm={6} xs={6} className="overflow-text-length">{rowData['orderDate']} {rowData['orderTime']}</Col>
                        <Col lg={2} md={2} sm={6} xs={6} className={'action-icon-view actionDropdownList actionDropdownListPadding-0 text-md-right'}  >
                            <Dropdown isOpen={(this.state.openHeader == rowData['id'] ? true : false)} className="font-size-13 actionDropdownList actionDropdownListPadding-0 orderlistActionlist" >
                                <DropdownToggle tag="a" className="nav-link pt-0 actionDropdownList  actionDropdownListPadding-0" caret  >
                                    <MoreVertIcon className='menu-dots-vertical' onClick={(event) => this.openHeaderMenu(event, rowData['id'])} />
                                </DropdownToggle>

                                <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList" >
                                    <div className="actionDropdownList" style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                                        {/* <DropdownItem className='actionDropdownList' onClick={(event) => this.deleteCart(event, rowData['id'])}>{Strings['Review Order']}</DropdownItem> */}
                                        <DropdownItem className='actionDropdownList' onClick={(event) => this.reviewPurchaseOrders(event, rowData)}>{Strings['Review Request Data']}</DropdownItem>
                                        {(rowData['orderId'] != null && rowData['orderId'] != undefined && rowData['orderId'] != '') &&
                                            <DropdownItem className='actionDropdownList' tag="a" href={"/ordersAdminList/2/" + rowData['orderId']}>{Strings['View Order']}</DropdownItem>
                                        }
                                        {this.props.data.status == "0" &&
                                            <DropdownItem className='actionDropdownList' onClick={(event) => this.deleteCart(event, rowData['id'])}>{Strings['Delete']}</DropdownItem>
                                        }
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Row>
                )
            })
        }
        return cartInfo;
    }


    render() {

        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col md={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'>
                                {this.props.data.cartName}
                            </div>
                        </Col>
                        <Col md={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>


                <div className='sidebar-body-view'>

                    {this.state.loading == 1 && this.state.cartDataRequests.length <= 0 &&
                        <Row>
                            <div className='inv-cards-view pb-5 width-100'>
                                <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
                                    <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                                    <div className='no-data-text'>{Strings["There is no data to display"]}</div>
                                </div>
                            </div>
                        </Row>
                    }

                    {this.state.loading == 1 && this.state.cartDataRequests.length > 0 &&
                        <Row className='inv-cards-view'>
                            <Col xs={12} className='text-right mb-4'>
                                <button className="btn page-action-btn ml-1" onClick={this.exportHandler}>
                                    {Strings['Export']}
                                </button>

                            </Col>
                            <Col>
                                <Row className='table-header-row'>
                                    <Col lg={3} md={3} sm={6} xs={6} className="overflow-text-length" title={Strings['Purchase order number']}>{Strings['Purchase order number']}</Col>
                                    <Col lg={3} md={3} sm={6} xs={6} className="overflow-text-length" title={Strings['Order Number']}>{Strings['Order Number']}</Col>
                                    <Col lg={4} md={4} sm={6} xs={6} className="overflow-text-length" title={Strings['Purchase order date']}> {Strings['Purchase order date']}</Col>
                                    <Col lg={2} md={2} sm={6} xs={6} className="overflow-text-length text-md-right" title={Strings['Action']}> {Strings['Action']}</Col>
                                </Row>
                                {this.displayData()}
                            </Col>
                            <Col md={12}>
                                <Row className='pagenation-view mt-3 mb-4'>
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={this.state.dataCount}
                                        pageRangeDisplayed={4}
                                        onChange={this.handlePageChange}
                                        prevPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-next' : 'flaticon2-back'}></i>}
                                        nextPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-back' : 'flaticon2-next'}></i>}
                                        hideFirstLastPages
                                        innerClass={'pagination  pagination-rounded justify-content-end pagenation-view my-2'}
                                        itemClass={'page-item'}
                                        linkClass={'page-link'}
                                    />
                                </Row>
                            </Col>

                        </Row >
                    }
                    {
                        this.state.loading == 0 &&
                        <Row>
                            <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                                <div className='card-body'>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                </div>
                            </Col>
                        </Row>
                    }
                    <Drawer anchor={localStorage.getItem('LANG') == 1 ? 'left' : 'right'} open={this.state.openleftModal} className='sidebar-modal-view'>
                        <PurchaseOrderInfo
                            handleCloseSideBar={this.handleCloseSideBar}
                            data={this.state.rowData}
                            discountValue={this.state.discountValue} />
                    </Drawer>
                </div >
            </div>

        )
    }
}
export default ReviewPurchaseOrder;
import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toAbsoluteUrl } from "../../../../_metronic";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import Pagination from "react-js-pagination";
import { translations } from "../../../../translate.js";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class AddNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: 1,
      langList: [],
      posList: [],
      selectedPos: [],
      activePage: 1,
      limit: 10,
      dataCount: 0,
      notiId: "",
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    this.getLang();
    this.getNotifications();
    this.getPos();
  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getNotifications();
    });
  };

  getPos() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistPos",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        allPos: 0,
        getType: "all",
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            var posList = [];
            if (data["data"].length > 0) {
              data["data"].map((rowData, index) => {
                var posListItem = [];
                posListItem["userId"] = rowData["userId"];
                posListItem["fname"] = rowData["fname"];
                posListItem["lname"] = rowData["lname"];
                posListItem["name"] = rowData["fname"] + " " + rowData["lname"];

                posList.push(posListItem);
              });
            }

            this.setState({
              posList: posList,
            });
          }
        }
      });
  }

  getLang() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getLang",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('====================================== lang');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              langList: data,
            });
          }
        }
      });
  }

  getNotifications() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistNoti",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        page: this.state.activePage,
        limit: this.state.limit,
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('====================================== lang');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              data: data["data"],
              dataCount: data["dataCount"],
              loading: 0,
            });
          }
        }
      });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      submitError: "",
    });
  };

  displayData() {
    var list = "";
    if (this.state.data.length > 0) {
      list = this.state.data.map((rowData, index) => {
        return (
          <Row key={index} className="table-body-row">
            <Col md={5} className="main-color-1 font-w-400">
              {rowData["name"]}
            </Col>
            <Col md={3}>{rowData["dateStart"]}</Col>
            <Col md={3}>{rowData["dateEnd"]}</Col>
            <Col
              md={1}
              className="text-right d-inline inv-category-box-action-btn"
            >
              <i
                className="flaticon2-writing mr-3"
                title={Strings["Edit"]}
                onClick={(event) => this.editNotification(event, rowData)}
              />
              <i
                className="flaticon2-cancel-music"
                title={Strings["Delete"]}
                onClick={(event) => this.deleteNotifications(event, rowData.id)}
              />
            </Col>
          </Row>
        );
      });
    }
    return list;
  }

  editNotification = (event, data) => {
    event.preventDefault();

    // get notification info

    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistNotiInfo",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        notiId: data["id"],
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('====================================== notification info');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              notiId: data["data"]["id"],
              title: data["data"]["name"],
              dateStart: data["data"]["dateStart"],
              dateEnd: data["data"]["dateEnd"],
              selectedPos: data["data"]["pos"],
            });

            if (data["data"]["postLang"].length > 0) {
              data["data"]["postLang"].map((rowData, index) => {
                var title = "title" + rowData["langId"];
                var desc = "desc" + rowData["langId"];
                var image = "image" + rowData["langId"];

                this.setState({
                  [title]: rowData["name"],
                  [desc]: rowData["description"],
                  [image]: rowData["image"],
                });
              });
            }
          }
        }
      });
  };

  handleCancelAddForm = () => {
    this.form && this.form.reset();
    this.setState(
      {
        title: "",
        notiId: "",
        dateStart: "",
        dateEnd: "",
        selectedPos: [],
      },
      function() {
        if (this.state.langList.length > 0) {
          this.state.langList.map((rowData, index) => {
            var title = "title" + rowData["id"];
            var desc = "desc" + rowData["id"];
            var image = "image" + rowData["id"];
            var image_lang = "image_" + rowData["id"];
            var displayErrorHeaderMsg =
              "displayErrorHeaderMsg_" + rowData["id"];
            var progressUploadHeader = "progressUploadHeader_" + rowData["id"];
            document.querySelector("input[name=" + image + "]").value = "";
            this.setState({
              [title]: "",
              [desc]: "",
              [image]: "",
              [image_lang]: "",
              [displayErrorHeaderMsg]: "",
              [progressUploadHeader]: "",
            });
          });
        }
      }
    );
  };

  deleteNotifications = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to delete this notification ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };

  handleClickDelete(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "deleteNoti",
        notiId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.getNotifications();
          }
        }
      });
  }

  handleValidSubmit(event) {
    event.preventDefault();
    var selectedPosIds = [];
    var selectedPosIdsStr = "";
    if (this.state.selectedPos.length > 0) {
      this.state.selectedPos.map((rowData, index) => {
        selectedPosIds.push(rowData["userId"]);
      });

      selectedPosIdsStr = selectedPosIds.join(",");
    }

    var data = new FormData();
    var image_ar =
      document.querySelector("input[name=image1]") != null
        ? document.querySelector("input[name=image1]").files[0]
        : "";
    var image_en =
      document.querySelector("input[name=image2]") != null
        ? document.querySelector("input[name=image2]").files[0]
        : "";

    var uploadedImages = "";
    if (this.state.image_1 == 1 && this.state.image_2 == 1) {
      uploadedImages = "all";
    } else if (this.state.image_1 == 1) {
      uploadedImages = "1";
    } else if (this.state.image_2 == 1) {
      uploadedImages = "2";
    }

    data.append("data[]", image_ar);
    data.append("data[]", image_en);
    data.append("uploadedImages", uploadedImages);
    data.append("type", "addNoti");
    data.append("userId", localStorage.getItem("USER_ID"));
    data.append("lang", localStorage.getItem("LANG"));
    data.append("token", localStorage.getItem("USER_TOKEN"));
    data.append("title", this.state.title);
    data.append("dateStart", this.state.dateStart);
    data.append("dateEnd", this.state.dateEnd);
    data.append("notiId", this.state.notiId);
    data.append("pos", selectedPosIdsStr);
    data.append("distId", this.props.distId ? this.props.distId : "");

    if (this.state.langList.length > 0) {
      this.state.langList.map((rowData, index) => {
        var title = "title" + rowData["id"];
        var desc = "desc" + rowData["id"];
        data.append(title, this.state[title]);
        data.append(desc, this.state[desc]);
      });
    }

    fetch(window.API_PERM_USER, {
      // mode: 'no-cors',
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        if (!response.ok) {
          // console.log(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('===============================');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
            this.handleCancelAddForm();
            this.getNotifications();
          } else {
            this.setState({ submitError: data["errorMsg"] });
          }
        }
      });
  }

  handleChangePos = (option) => {
    this.setState((state) => {
      return {
        selectedPos: option,
      };
    });
  };

  handleImgChange = (event, langId) => {
    var fileReader = new FileReader();
    const scope = this;

    var displayErrorHeaderMsg = "displayErrorHeaderMsg_" + langId;
    var progressUploadHeader = "progressUploadHeader_" + langId;
    var image = "image_" + langId;

    if (event.target.files[0].type.includes("image")) {
      this.setState({ [displayErrorHeaderMsg]: false });
      this.setState({ [progressUploadHeader]: 10 });
      fileReader.readAsBinaryString(event.target.files[0]);
      fileReader.onprogress = function(data) {
        if (data.lengthComputable) {
          var progress = parseInt((data.loaded / data.total) * 100, 10);
          scope.setState({ [progressUploadHeader]: progress });
        }
      };
    } else {
      this.setState({ [displayErrorHeaderMsg]: true });
    }
    this.setState({
      [image]: 1,
    });
  };

  displayLangFields() {
    var list = "";
    if (this.state.langList.length > 0) {
      list = this.state.langList.map((rowData, index) => {
        return (
          <div key={index} className="mt-4 notification-multi-lang-view">
            <Row>
              <Col md={10}>
                <hr className="notification-multi-lang-hr" />
              </Col>
              <Col md={2} className="main-color-1 font-w-500">
                {rowData["langName"]}
              </Col>
            </Row>
            <AvField
              name={"title" + rowData["id"]}
              label={Strings["Title"] + " *"}
              placeholder={Strings["Enter Title"]}
              value={this.state["title" + rowData["id"]]}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />

            <div className="mt-3 mb-3">
              <label className="font-w-400">{Strings["Image"]}</label>
              <span className={"fileInput-span col-md-12"}>
                <PublishIcon />{" "}
                <span className="pointer-cursor pt-3">{Strings["Upload"]}</span>
                <input
                  type="file"
                  accept="image/*"
                  className="fileInput-width fileInput-opacity"
                  onChange={(e) => this.handleImgChange(e, rowData["id"])}
                  name={"image" + rowData["id"]}
                ></input>
              </span>
              {this.state["progressUploadHeader_" + rowData["id"]] !=
                undefined &&
                this.state["progressUploadHeader_" + rowData["id"]] > 0 && (
                  <Progress
                    striped
                    value={this.state["progressUploadHeader_" + rowData["id"]]}
                    className="mb-1 mt-1 progress-bg-color"
                  >
                    {this.state["progressUploadHeader_" + rowData["id"]]} %
                  </Progress>
                )}
              {this.state["displayErrorHeaderMsg_" + rowData["id"]] == true && (
                <div className="invalid-feedback d-block mb-1">
                  {Strings["Upload Image only"]}
                </div>
              )}

              {this.state["image" + rowData["id"]] != null &&
                this.state["image" + rowData["id"]] != undefined &&
                this.state["image" + rowData["id"]] != "" && (
                  <div className="text-center mt-4">
                    <img
                      src={this.state["image" + rowData["id"]]}
                      className="img-fluid inv-category-img"
                    />
                    {/* <a href={this.state.barCodeImgUrl} download className='inv-category-img-title overflow-text-length' target='_blank'>{this.state['image' + rowData['id']] }</a> */}
                  </div>
                )}
            </div>

            <AvField
              name={"desc" + rowData["id"]}
              type="textarea"
              label={Strings["Description"] + " *"}
              placeholder={Strings["Enter Description"]}
              value={this.state["desc" + rowData["id"]]}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
          </div>
        );
      });
    }
    return list;
  }

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col md={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {localStorage.getItem("OPERA_EMP") == 1
                  ? Strings["POS Notifications"]
                  : Strings["Notifications"]}{" "}
              </div>
            </Col>
            <Col md={2} className="text-right">
              <HighlightOffIcon
                onClick={
                  localStorage.getItem("OPERA_EMP") == 1
                    ? (event) =>
                        this.props.handleCloseSideBar(false, "notification")
                    : (event) => this.props.handleCloseSideBar(event)
                }
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <Row>
            <Col md={5} className="card-desc-form-view">
              <div>
                <AvForm
                  onValidSubmit={this.handleValidSubmit}
                  ref={(c) => (this.form = c)}
                >
                  <AvField
                    name="title"
                    label={Strings["Title"] + " *"}
                    placeholder={Strings["Enter Title"]}
                    value={this.state.title}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <AvField
                    name="dateStart"
                    type="date"
                    label={Strings["From Date"] + " *"}
                    value={this.state.dateStart}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <AvField
                    name="dateEnd"
                    type="date"
                    label={Strings["To Date"] + " *"}
                    value={this.state.dateEnd}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />

                  <div className=" mb-2">
                    <label className="font-w-400">{Strings["POS"]}</label>
                    <Picky
                      value={this.state.selectedPos}
                      options={this.state.posList}
                      onChange={this.handleChangePos}
                      open={false}
                      valueKey="userId"
                      labelKey="name"
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["POS"]}
                      filterPlaceholder={Strings["Select POS"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>

                  {this.displayLangFields()}

                  <div className="text-right mt-4">
                    <button
                      type="submit"
                      className="btn form-save-btn mr-1 ml-1"
                    >
                      {" "}
                      {Strings["Save"]}{" "}
                    </button>
                    <button
                      type="button"
                      className="btn form-cancel-btn m-l-10"
                      onClick={() => this.handleCancelAddForm()}
                    >
                      {Strings["Cancel"]}
                    </button>
                  </div>

                  {this.state.submitError && (
                    <div className="error-text-black">
                      <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                      {this.state.submitError}
                    </div>
                  )}
                </AvForm>
              </div>
            </Col>

            <Col md={7}>
              {this.state.loading == 1 && (
                <Row>
                  <Col
                    md={12}
                    style={{ marginTop: "15%" }}
                    className="text-center"
                  >
                    <div className="card-body">
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                    </div>
                  </Col>
                </Row>
              )}

              {this.state.loading == 0 && this.state.data.length <= 0 && (
                <Row className="text-center" style={{ marginTop: "15%" }}>
                  <div>
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl(
                        "/media/ezpay/EZPAY_LOGO_GRAY" +
                          window.MAIN_IMAGE +
                          ".png"
                      )}
                      className="img-fluid no-data-img"
                    />
                    <div className="no-data-text">
                      {Strings["There is no data to display"]}
                    </div>
                  </div>
                </Row>
              )}

              {this.state.loading == 0 && this.state.data.length > 0 && (
                <div className="inv-cards-view">
                  <div>
                    <Row className="table-header-row">
                      <Col md={5}>{Strings["Title"]}</Col>
                      <Col md={3}>{Strings["From Date"]}</Col>
                      <Col md={3}>{Strings["To Date"]}</Col>
                      <Col md={1} className="text-center">
                        {Strings["Action"]}
                      </Col>
                    </Row>
                    {this.displayData()}
                  </div>
                </div>
              )}

              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Col md={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default AddNotifications;

import React, { Component } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import "react-confirm-alert/src/react-confirm-alert.css";
import Pagination from "react-js-pagination";
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from "../../../../translate.js";
import { AvForm, AvField } from "availity-reactstrap-validation";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Drawer, Button } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import AddDiscount from "./addDiscount";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class DiscountAddCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      activePage: 1,
      limit: 25,
      openHeader: "",
      actionType: "",
      name: "",
      discountTypeList: [],
      selectedDiscountType: [],
      openleftModal: false,
      dataCount: 0,
      data: [],
      dataRow: [],
      errorMsg: "",
      distId: this.props.distId ? this.props.distId : "",
    };
  }

  componentDidMount() {
    this.getData();
    this.getDiscountTypeList();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleRefresh = () => {
    // clear all filter data
    this.setState(
      {
        loading: 0,
        data: [],
        dataRow: [],
        dataCount: 0,
        name: "",
        selectedDiscountType: [],
        dateFrom: "",
        dateTo: "",
        activePage: 1,
        msgError: "",
      },
      function() {
        this.getData();
      }
    );
  };
  //################################ api section #################################
  getDiscountTypeList = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDataTypeDiscountsList",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ errorMsg: data["errorMsg"], loading: 0 });
          } else {
            this.setState({
              discountTypeList: data,
            });
          }
        }
      });
  };
  getData = (loading = 1) => {
    this.setState({ loading: loading });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDiscountsList",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        name: this.state.name,
        discountType: this.getSelected("discountType"),
        name: this.state.name,
        start: this.state.activePage,
        limit: this.state.limit,
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({
              errorMsg: data["errorMsg"],
              loading: 0,
              data: [],
              dataCount: 0,
            });
          } else {
            this.setState({
              errorMsg: "",
              data: data["data"],
              dataCount: data["count"],
              loading: 0,
            });
          }
        }
      });
  };
  handleClickDelete(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "deleteDiscount",
        id: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.alertErrorSubmite(data["errorMsg"]);
          } else {
            this.getData(0);
          }
        }
      });
  }
  //################################ react section #################################
  alertErrorSubmite = (errorMsg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view ">
            <div className="mb-4">
              <HighlightOffIcon className="error-alert-icon" size={70} />
            </div>
            <p className="mb-3 ">{errorMsg}</p>
            <button
              type="button"
              className="btn  alert-btn-margin ml-2 mr-2 filter-empty-email label-error"
              onClick={onClose}
            >
              {Strings.Close}
            </button>
          </div>
        );
      },
    });
  };
  deleteFrom = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to delete this discount ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };
  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
        });
      }
    }
  };
  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };
  handleCloseSideBar = () => {
    this.setState({ openleftModal: false });
  };
  getRecallData = () => {
    this.setState(
      {
        openleftModal: false,
        dataRow: [],
        actionType: "",
      },
      function() {
        this.getData(0);
      }
    );
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getData();
    });
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState(
      {
        [name]: value,
        errorMsg: "",
        activePage: 1,
      },
      function() {
        this.getData();
      }
    );
  };
  openFromAction = (event, data, type) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({
      dataRow: data,
      actionType: type,
      openleftModal: true,
    });
  };
  handleChangeSelect = (type, option) => {
    this.setState(
      (state) => {
        return {
          [type]: option,
          activePage: 1,
          errorMsg: "",
        };
      },
      function() {
        this.getData();
      }
    );
  };
  getSelected = (type) => {
    if (type == "discountType") {
      return this.state.selectedDiscountType.map((data) => data.id);
    }
  };
  displayData() {
    var list = "";
    if (this.state.data.length > 0) {
      list = this.state.data.map((rowData, index) => {
        return (
          <Row key={index} className="table-body-row">
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length"
              title={rowData["id"]}
            >
              {rowData["id"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={
                localStorage.getItem("LANG") == 1
                  ? rowData["name"]
                  : rowData["nameEn"]
              }
            >
              {localStorage.getItem("LANG") == 1
                ? rowData["name"]
                : rowData["nameEn"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["typeTitle"]}
            >
              {rowData["typeTitle"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["discountValue"]}
            >
              {rowData["discountValue"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["entryDate"]}
            >
              {rowData["entryDate"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={
                rowData["modificationDate"] != undefined &&
                rowData["modificationDate"] != "" &&
                rowData["modificationDate"] != null
                  ? rowData["modificationDate"]
                  : "-"
              }
            >
              {rowData["modificationDate"] != undefined &&
              rowData["modificationDate"] != "" &&
              rowData["modificationDate"] != null
                ? rowData["modificationDate"]
                : "-"}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="action-icon-view actionDropdownList actionDropdownListPadding-0 text-md-right"
            >
              <Dropdown
                isOpen={this.state.openHeader == rowData["id"] ? true : false}
                className="font-size-13 actionDropdownList actionDropdownListPadding-0 orderlistActionlist"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link pt-0 actionDropdownList  actionDropdownListPadding-0"
                  caret
                >
                  <MoreVertIcon
                    className="menu-dots-vertical"
                    onClick={(event) =>
                      this.openHeaderMenu(event, rowData["id"])
                    }
                  />
                </DropdownToggle>
                <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList">
                  <div
                    className="actionDropdownList"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    <DropdownItem
                      className="actionDropdownList"
                      onClick={(event) =>
                        this.openFromAction(event, rowData, "edit")
                      }
                    >
                      {Strings["Edit"]}
                    </DropdownItem>
                    <DropdownItem
                      className="actionDropdownList"
                      onClick={(event) => this.deleteFrom(event, rowData["id"])}
                    >
                      {Strings["Delete"]}
                    </DropdownItem>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        );
      });
    }
    return list;
  }

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col xs={10} lg={10} md={10} sm={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {Strings["Discount Add Balance"]}
              </div>
            </Col>
            <Col md={2} xs={2} lg={2} sm={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>
        <div className="sidebar-body-view sidebar-body-view-supplier-transaction">
          <Row className="page-filter-view">
            <Col
              xl={{ size: 6, order: 1 }}
              lg={{ size: 6, order: 1 }}
              md={{ size: 7, order: 1 }}
              sm={{ size: 8, order: 1 }}
              xs={{ size: 12, order: 12 }}
            >
              <AvForm>
                <Row className="filter-form">
                  <Col md={6} xs={12} lg={6} sm={12}>
                    <div className="member-searchable-list mb-2">
                      <Picky
                        value={this.state.selectedDiscountType}
                        options={this.state.discountTypeList}
                        onChange={this.handleChangeSelect.bind(
                          this,
                          "selectedDiscountType"
                        )}
                        open={false}
                        valueKey={"id"}
                        labelKey={"name"}
                        multiple={true}
                        includeSelectAll={true}
                        includeFilter={true}
                        dropdownHeight={200}
                        placeholder={Strings["Discount Type"]}
                        filterPlaceholder={Strings["Select Discount Type"]}
                        selectAllText={Strings["Select All"]}
                        allSelectedPlaceholder={Strings["%s selected"]}
                        manySelectedPlaceholder={Strings["%s selected"]}
                        clearFilterOnClose={true}
                      />
                    </div>
                  </Col>
                  <Col md={4} xs={12} lg={4} sm={12} className="mb-1">
                    <AvField
                      name="name"
                      type="text"
                      placeholder={Strings["Discount Name"]}
                      value={this.state.name}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </Col>
                  <Col xs={12} lg={2} md={2} sm={12}>
                    <Button
                      variant="contained"
                      type="button"
                      className="refresh-action-btn mb-2 mb-md-0"
                      onClick={this.handleRefresh}
                      title={Strings["Refresh"]}
                    >
                      <i className="flaticon-refresh reset-filter-btn"></i>
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </Col>
            <Col
              xl={{ size: 6, order: 1 }}
              lg={{ size: 6, order: 1 }}
              md={{ size: 5, order: 1 }}
              sm={{ size: 4, order: 1 }}
              xs={{ size: 12, order: 12 }}
              className="text-right"
            >
              <button
                className="btn gradient-button-view gradient-button gradient-button-font-12 ml-1"
                onClick={(event) => this.openFromAction(event, [], "add")}
              >
                {Strings["Add Discount"]}
              </button>
            </Col>
          </Row>
          {this.state.loading == 1 && (
            <Row>
              <Col md={12} style={{ marginTop: "15%" }} className="text-center">
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}

          {this.state.loading == 0 && this.state.errorMsg != "" && (
            <div className="text-center" style={{ marginTop: "15%" }}>
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">{this.state.errorMsg}</div>
            </div>
          )}

          {this.state.loading == 0 &&
            this.state.data.length <= 0 &&
            this.state.errorMsg == "" && (
              <div className="text-center" style={{ marginTop: "15%" }}>
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                  )}
                  className="img-fluid no-data-img"
                />
                <div className="no-data-text">
                  {Strings["There is no data to display"]}
                </div>
              </div>
            )}
          {this.state.loading == 0 && this.state.data.length > 0 && (
            <Row>
              <div className="inv-cards-view width-100">
                <Row className="table-header-row">
                  <Col lg={1} md={1} sm={1} xs={12} title={Strings["Id"]}>
                    {Strings["Id"]}
                  </Col>
                  <Col lg={2} md={2} sm={2} xs={12} title={Strings["Name"]}>
                    {Strings["Name"]}
                  </Col>
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    title={Strings["Discount Type"]}
                    className="overflow-text-length"
                  >
                    {Strings["Discount Type"]}
                  </Col>
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    title={Strings["Discount Value"]}
                    className="overflow-text-length"
                  >
                    {Strings["Discount Value"]}
                  </Col>
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    title={Strings["Entry Date"]}
                    className="overflow-text-length"
                  >
                    {Strings["Entry Date"]}
                  </Col>
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    title={Strings["Modification Date"]}
                    className="overflow-text-length"
                  >
                    {Strings["Modification Date"]}
                  </Col>
                  <Col
                    lg={1}
                    md={1}
                    sm={1}
                    xs={12}
                    title={Strings["Action"]}
                    className="text-md-right"
                  >
                    {Strings["Action"]}
                  </Col>
                </Row>
                {this.displayData()}
                <Col md={12}>
                  <Row className="pagenation-view mt-3">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              </div>
            </Row>
          )}
        </div>
        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openleftModal}
          className={"sidebar-modal-view"}
        >
          <AddDiscount
            status={this.state.actionType}
            discountData={this.state.dataRow}
            discountTypeList={this.state.discountTypeList}
            handleCloseSideBar={this.handleCloseSideBar}
            handleFormAction={this.getRecallData}
          />
        </Drawer>
      </div>
    );
  }
}
export default DiscountAddCard;

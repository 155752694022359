import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from "../../../../translate.js";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class ViewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      errorMsg: "",
      viewPage:
        this.props.viewPage != undefined &&
        this.props.viewPage == "accountStatment"
          ? 1
          : this.props.viewPage == "notCompleteOrder"
          ? 2
          : 0,
      orderData: this.props.orderRowData,
      showExportOrder:
        this.props.viewPage == undefined ? this.props.showExportOrder : 1,
    };
  }
  componentDidMount() {
    console.log(this.props.orderRowData,'orderRowData');
    
    if (this.state.viewPage == 1) {
      this.loadingDataApi();
    } else if (this.state.viewPage == 2) {
      this.loadingDataNotComplete();
    } else {
      this.loadingData();
    }
  }

  loadingDataApi() {
    this.setState({ loading: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getOrderDataForInventoryStatement",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        orderId: this.props.orderRowData.orderId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ errorMsg: data["errorMsg"], loading: 0 });
          } else {
            var data = data["data"];
            this.setState({
              errorMsg: "",
              orderData: data,
              orderId: data.orderId,
              posName: data.posName,
              clientName: data.clientName,
              distName: data.distName,
              orderDate: data.orderDate,
              amountFromate: data.orderPaymentAmount,
              actualAmount: data.actualAmount,
              catName: data.catName,
              invoicePassword: data.password,
              paymentType: data.paymentType,
              note: data.Note,
              imgUrl:
                data.imgs != undefined && data.imgs.length > 0
                  ? data.imgs[0]["img"]
                  : "",
              bankName: data.bankName != null ? data.bankName : "",
              loading: 0,
            });
            // console.log(this.state.orderData);
          }
        }
      });
  }
  loadingData() {
    this.setState({
      orderId: this.state.orderData.id,
      posName: this.state.orderData.fname + " " + this.state.orderData.lname,
      distName:
        this.state.orderData.distdata.fname +
        " " +
        this.state.orderData.distdata.lname,
      orderDate:
        this.state.orderData.orderDate + " " + this.state.orderData.orderTime,
      amountFromate: this.state.orderData.amountFromate,
      actualAmount: this.state.orderData.actualAmount,
      catName: this.state.orderData.name,
      invoicePassword: this.state.orderData.invoicePassword,
    });
  }
  loadingDataNotComplete() {
    var data = this.state.orderData;
    this.setState({
      orderId: data["id"],
      taxPrecent: data["taxPrecent"],
      orderType: data["orderType"],
      paymentAmount: data["paymentAmount"],
      taxTruncate: data["taxTruncate"],
      order_type_name: data["order_type_name"],
      signatureImg: data["signatureImg"],
      amountUSD: data["amountUSD"],
      employeeName: data["employeeName"],
      orderRate: data["rate"],
      inputAmount: data["inputAmount"],
      orderCurrency: data["distInfo"]["currencyTitle"],
      accountTypeEmployee:
        localStorage.getItem("LANG") == 1
          ? data["accountTypeTitle_ar"]
          : data["accountTypeTitle_en"],
      clientName: data["clientName"],
      distName: data["distInfo"]["fname"] + " " + data["distInfo"]["lname"],
      orderDate: data.orderDate + " " + data.orderTime,
      amountFromate: data.amountFormate,
      isApiOrder: data.isApiOrder,
      actualAmount: data.actualAmount,
      catName: data.categoryName,
      cardName: data.cardName,
      paymentType:
        localStorage.getItem("LANG") == 1
          ? data["paymentType_ar"]
          : data["paymentType_en"],
      note: data.note,
      imgUrl:
        data.imgs != undefined && data.imgs != "" && data.imgs.length > 0
          ? data.imgs[0]["img"]
          : "",
      bankName: data.bankName != null ? data.bankName : "",
      loading: 0,
    });
    // console.log(this.state.orderData);
  }
  loadingBody() {
    if (this.state.viewPage == 1) {
      return (
        <tag>
          {this.state.loading == 1 && (
            <Row>
              <Col md={12} style={{ marginTop: "15%" }} className="text-center">
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}
          {this.state.loading == 0 && this.state.errorMsg != "" && (
            <div className="text-center" style={{ marginTop: "15%" }}>
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">{this.state.errorMsg}</div>
            </div>
          )}

          {this.state.loading == 0 &&
            Object.keys(this.state.orderData).length <= 0 && (
              <div className="text-center" style={{ marginTop: "15%" }}>
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                  )}
                  className="img-fluid no-data-img"
                />
                <div className="no-data-text">
                  {Strings["There is no data to display"]}
                </div>
              </div>
            )}
        </tag>
      );
    }
  }
  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {Strings["View Order"]}
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>
        {this.loadingBody()}
        {(this.state.viewPage != 1 ||
          (this.state.loading == 0 &&
            Object.keys(this.state.orderData).length > 0)) && (
          <div className="sidebar-body-view">
            <div className="view-purchase-main-fields">
              {(localStorage.getItem("PERM_TYPE") == 5 ||
                localStorage.getItem("POS_MGR_EMP") == 1 ||
                this.state.viewPage == 1 ||
                this.state.viewPage == 2) && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Order Number"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                  {console.log(this.state.orderId,this.state.orderData.orderId,'this.state.orderData.orderId')
                  }
                    {this.state.viewPage == 2
                      ? this.state.orderData.orderId
                      : this.state.orderId}
                  </Col>
                </div>
              )}
                {this.props.orderRowData.printStatus != undefined &&
              this.props.orderRowData.printStatus != "" &&
              this.props.orderRowData.printStatus != "-" &&((localStorage.getItem("PERM_TYPE") == 5 ||
              localStorage.getItem("POS_MGR_EMP") == 1 ||
              this.state.viewPage == 1 ||
              this.state.viewPage == 2))&& (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Print Status"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.props.orderRowData.printStatus}
                  </Col>
                </div>
              )}
              {((this.state.distName != "" &&
                localStorage.getItem("PERM_TYPE") != 5 &&
                localStorage.getItem("POS_MGR_EMP") == 0) ||
                this.state.viewPage == 2) && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {this.state.viewPage == 1 || this.state.viewPage == 2
                      ? Strings["Distributor Name"]
                      : Strings["Agent Name"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.distName}
                  </Col>
                </div>
              )}
              
              {this.state.order_type_name != undefined &&
                this.state.order_type_name != "" && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["Order Type"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.state.order_type_name}
                    </Col>
                  </div>
                )}
              {this.state.clientName != undefined &&
                this.state.clientName != "" &&
                this.state.clientName != "-" && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["Client Name"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.state.clientName}
                    </Col>
                  </div>
                )}
              {this.state.accountTypeEmployee != undefined &&
                this.state.accountTypeEmployee != "" &&
                this.state.accountTypeEmployee != "-" && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["Employee Account Type"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.state.accountTypeEmployee}
                    </Col>
                  </div>
                )}
              {this.state.employeeName != undefined &&
                this.state.employeeName != "" &&
                this.state.employeeName != "-" && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["Employee Name"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.state.employeeName}
                    </Col>
                  </div>
                )}
              {this.state.posName != "" && this.state.viewPage != 2 && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["POS Name"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.posName}
                  </Col>
                </div>
              )}
              {this.state.orderDate != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Date"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.orderDate}{" "}
                  </Col>
                </div>
              )}
              {this.state.amountFromate != "" && this.state.viewPage != 2 && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Transaction Value"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.amountFromate}
                  </Col>
                </div>
              )}
              {this.state.orderType != undefined && this.state.viewPage == 2 && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Order Value"]} :
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12} className="main-font-1">
                    {this.state.orderType == 1 ? (
                      <ul className="mb-2">
                        <li>
                          {Strings["Currency"]} : {this.state.currencyTitle}
                        </li>
                        <li>
                          {Strings["Transfer Price"]} : {this.state.orderRate}
                        </li>
                        <li>
                          {Strings["Value"]} : {this.state.amountFromate}
                        </li>
                        <li>
                          {Strings["Dollar"]} : {this.state.amountUSD}
                        </li>
                        {this.state.taxPrecent > 0 &&
                          this.state.taxPrecent != "" && (
                            <>
                              <li>
                                {Strings["Tax"]} :{" "}
                                {this.state.taxPrecent + " %"}
                              </li>
                              <li>
                                {Strings["Tax Amount"]} :{" "}
                                {this.state.taxTruncate}
                              </li>
                            </>
                          )}
                      </ul>
                    ) : (
                      <ul className="mb-2">
                        <li>
                          {Strings["Actual Value"]} : {this.state.amountFromate}
                        </li>
                        <li>
                          {Strings["Transaction Amount"]} :{" "}
                          {this.state.paymentAmount}
                        </li>
                        {this.state.taxPrecent > 0 &&
                          this.state.taxPrecent != "" && (
                            <>
                              <li>
                                {Strings["The Amount Paid"]} :{" "}
                                {this.state.inputAmount}
                              </li>
                              <li>
                                {Strings["Tax"]} :{" "}
                                {this.state.taxPrecent + " %"}
                              </li>
                              <li>
                                {Strings["The amount withheld for tax"]} :{" "}
                                {this.state.taxTruncate}
                              </li>
                            </>
                          )}
                      </ul>
                    )}
                  </Col>
                </div>
              )}
              {this.state.actualAmount != "" && this.state.viewPage != 2 && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Cards Value"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.actualAmount}{" "}
                  </Col>
                </div>
              )}
              {this.state.catName != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Card Type"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.catName}{" "}
                  </Col>
                </div>
              )}
              {this.state.cardName != undefined &&
                this.state.cardName != "" &&
                this.state.viewPage == 2 && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["Card"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.state.cardName}{" "}
                    </Col>
                  </div>
                )}
              {((localStorage.getItem("PERM_TYPE") != 5 &&
                localStorage.getItem("POS_MGR_EMP") == 0) ||
                ((localStorage.getItem("POS_MGR_EMP") == 1 ||
                  localStorage.getItem("PERM_TYPE") == 5) &&
                  this.state.showExportOrder == 1)) &&
                this.state.invoicePassword != "" &&
                this.state.viewPage != 2 && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["Invoice Password"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.state.invoicePassword}{" "}
                    </Col>
                  </div>
                )}
              {this.state.paymentType != undefined &&
                this.state.paymentType != "" && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["Payment type"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.state.paymentType}
                    </Col>
                  </div>
                )}
              {this.state.bankName != undefined &&
                this.state.bankName != "" &&
                ((this.state.viewPage == 2 && this.state.isApiOrder != 1) ||
                  this.state.viewPage != 2) && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["Bank account"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.state.bankName}
                    </Col>
                  </div>
                )}
              {this.state.note != undefined && this.state.note != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Note"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.note}
                  </Col>
                </div>
              )}
              {this.state.imgUrl != undefined && this.state.imgUrl != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Transfer Image"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="text-center mt-4">
                      <img
                        src={this.state.imgUrl}
                        className="img-fluid inv-category-img"
                      />
                      {/* <a href={this.state.imageUrl} download className='inv-category-img-title overflow-text-length' target='_blank'>{this.state.cardClassImg}</a> */}
                    </div>
                  </Col>
                </div>
              )}
              {this.state.signatureImg != undefined &&
                this.state.signatureImg != "" && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["Signature Image"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <div className="text-center mt-4">
                        <img
                          src={this.state.signatureImg}
                          className="img-fluid inv-category-img"
                        />
                      </div>
                    </Col>
                  </div>
                )}
              {this.state.orderData?.paymentType == 9 && (
                <>
                  {this.state.orderData?.transReferenceId != "" && (
                    <div className="mb-4 view-purchase-main-fields-row">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="main-font-1 font-w-500"
                      >
                        {Strings["transReferenceId"]}
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {this.state.orderData?.transReferenceId}{" "}
                      </Col>
                    </div>
                  )}
                  {this.state.orderData?.transAmount != "" && (
                    <div className="mb-4 view-purchase-main-fields-row">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="main-font-1 font-w-500"
                      >
                        {Strings["transAmount"]}
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {this.state.orderData?.transAmount}{" "}
                      </Col>
                    </div>
                  )}
                  {this.state.orderData?.transEntryDate != "" && (
                    <div className="mb-4 view-purchase-main-fields-row">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="main-font-1 font-w-500"
                      >
                        {Strings["transEntryDate"]}
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {this.state.orderData?.transEntryDate}{" "}
                      </Col>
                    </div>
                  )}
                  {this.state.orderData?.transErrorDesc != "" && (
                    <div className="mb-4 view-purchase-main-fields-row">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="main-font-1 font-w-500"
                      >
                        {Strings["transErrorDesc"]}
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {this.state.orderData?.transErrorDesc}{" "}
                      </Col>
                    </div>
                  )}
                  {this.state.orderData?.transTitle && (
                    <div className="mb-4 view-purchase-main-fields-row">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="main-font-1 font-w-500"
                      >
                        {Strings["transTitle"]}
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {this.state.orderData?.transTitle}{" "}
                      </Col>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default ViewOrder;

// import React, { Component } from "react";
import React, { useState, useEffect } from 'react';
import * as auth from "../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { useDispatch, useSelector } from 'react-redux';

function Logout(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    props.logout();
    logoutHandler()
  }, [])

  const logoutHandler = () => {
    if ((localStorage.getItem('PERM_TYPE') == 5 || localStorage.getItem('POS_MGR_EMP') == 1)) {
      dispatch({
        type: 'UPDATEBALANCE',
        payload: '0'
      })
    }
    localStorage.setItem('USER_ID', '');
    localStorage.setItem('USER_EMAIL', '');
    localStorage.setItem('USER_FULL_NAME', '');
    localStorage.setItem('USER_BALANCE', '');
    localStorage.setItem('POS_MGR_EMP', 0);
    localStorage.setItem('PERM_TYPE', '');
    localStorage.setItem('ACCOUNT_TYPE', '');
    localStorage.setItem('USER_LEVEL', '');
    localStorage.setItem('PERM_TYPE_PARENT', '');
    localStorage.setItem('IS_TAXDIST', '');
    localStorage.setItem('USER_BALANCE_FROM', '');
    localStorage.setItem("POS_MGR_EMP_NEW", '');

  }

  // render() {
  const { hasAuthToken } = props;

  return (hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />);
  // }
}


// class Logout extends Component {
//   componentDidMount() {
//     this.props.logout();
//     this.logoutHandler()
//   }

//   logoutHandler = () => {
//     localStorage.setItem('USER_ID', '');
//     localStorage.setItem('USER_EMAIL', '');
//     localStorage.setItem('USER_FULL_NAME', '');
//     localStorage.setItem('USER_BALANCE', '');
//     localStorage.setItem('POS_MGR_EMP', 0);
//     localStorage.setItem('PERM_TYPE', '');
//     localStorage.setItem('ACCOUNT_TYPE', '');
//     localStorage.setItem('USER_LEVEL', '');
//     localStorage.setItem('PERM_TYPE_PARENT', '');
//     localStorage.setItem('IS_TAXDIST', '');
//   }


//   render() {
//     const { hasAuthToken } = this.props;

//     return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
//   }
// }

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Drawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";
import Pagination from "react-js-pagination";
import { Picky } from 'react-picky';
import CheckIcon from '@material-ui/icons/Check';
import { CircularProgress } from "@material-ui/core";
import 'react-picky/dist/picky.css';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { translations } from '../../../translate.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

var today = new Date();
var currentDate = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);

class unCompleteOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 1,
            data: [],
            currencyTitle: '',
            limit: 25,
            activePage: 1,
            dataCount: 0,
            errorMsg: '',
            openClassList: '',
            orderFilterDateFrom: currentDate,
            orderFilterDateTo: currentDate,
            cardClassName: [],
            loadingClass: 0,
            startSave: 0

        }
    }

    componentDidMount() {
        this.getOrderRequset()

    }

    AcceptRequest(id) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <div className="mb-3">
                            <span className="alert-delete-icon">!</span>
                        </div>
                        <h5>{Strings['Are you sure ?']}</h5>
                        <p>{Strings['You want to accept request?']}</p>
                        <button type="button" className="btn form-cancel-btn alert-btn-margin ml-2 mr-2" onClick={onClose}>{Strings.Cancel}</button>

                        {this.state.startSave == 0 ?

                            <button type="button" className="btn form-save-btn"
                                onClick={() => {
                                    this.handleClickRequest(id);
                                    // onClose();
                                }}
                            >
                                {Strings.Yes}
                            </button>
                            :
                            <button type="button" className="btn form-save-btn"><CircularProgress className="SaveCircularProgressColor" /> {Strings.Yes}</button>
                        }
                    </div>
                );
            }
        });
    }
    // https://dev45.hoja-crm.com/API/permUser.php?type=addOrderFromReq&userId=636&requestId=2&qsyTest=-500
    handleClickRequest(id) {
        this.setState({ startSave: 1 })
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'addOrderFromReq',
                'requestId': id,
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 0) {
                    this.setState({ startSave: 0 })
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div className='custom-ui alert-box-view'>
                                    <div className="mb-3">
                                        <span className="alert-Succsess-icon">✓</span>
                                    </div>
                                    <p className="mt-2">{Strings['Your request is in processed']}</p>

                                    <button type="button" className="btn form-cancel-btn alert-btn-margin ml-2 mr-2" onClick={() => {
                                        this.getOrderRequset()
                                        onClose();
                                    }}>{Strings.Close}</button>
                                </div>
                            );
                        }
                    });

                } else {
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div className='custom-ui alert-box-view'>
                                    <div className="mb-3">
                                        <span className="alert-delete-icon">!</span>
                                    </div>
                                    <p>{data['errorMsg']}</p>
                                    <button type="button" className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                                        onClick={() => {
                                            onClose();
                                        }}
                                    >{Strings.Close}</button>
                                </div>
                            );
                        }
                    });
                }
            }
        })
    }

    // https://dev45.hoja-crm.com/API/permUser.php?type=getOrderRequset&userId=636&page=1&limit=10&qsyTest=-500
    getOrderRequset() {
        this.setState({ loading: 1 })
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getOrderRequset',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'page': this.state.activePage,
                'limit': this.state.limit,
                'fromDate': this.state.orderFilterDateFrom,
                'toDate': this.state.orderFilterDateTo,

            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {

                // console.log('======================================');
                // console.log(data);

                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 1) {
                    this.setState({ errorMsg: data['errorMsg'] })
                } else {
                    this.setState({
                        data: data['requsetData'],
                        dataCount: data['requsetCount'],
                        loading: 0,
                    })
                }
            }
        })
    }

    // https://dev45.hoja-crm.com/API/permUser.php?type=getClassInfoToOrderRequset&userId=636&requestId=2&qsyTest=-500
    openClassList = (id) => {

        this.setState({ loadingClass: 1, openClassList: id, })
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getClassInfoToOrderRequset',
                'userId': localStorage.getItem('USER_ID'),
                'lang': localStorage.getItem('LANG'),
                'token': localStorage.getItem('USER_TOKEN'),
                'requestId': id,

            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {

            if (typeof data !== 'undefined') {
                // console.log('===============================');
                // console.log(data);

                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({
                        cardClassName: data['cardClassName'],
                        openClassList: id,
                        loadingClass: 0
                    })
                }
            }
        });

        // this.setState({ openClassList: id })
    }
    closeOpenClassList = (id) => {
        this.setState({
            openClassList: id
        })
    }

    displayData() {
        var orderInfo = ''
        if (this.state.loading == 0 && this.state.data.length > 0) {
            orderInfo = this.state.data.map((rowData, index) => {
                var classesList = []
                if (this.state.cardClassName.length > 0 && this.state.openClassList == rowData['id']) {
                    classesList = this.state.cardClassName.map((rowData2, index2) => {
                        return (<Row key={index} >
                            <div title={rowData2['cardClassName']} className={"orderClassWidth overflow-text-length"}>- {rowData2['cardClassName']} </div>
                            <div>{rowData2['cardsCount']}</div>
                        </Row>
                        )
                    })
                }
                return (
                    <Row key={index} className='table-body-row'>
                        <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData['id']}>{rowData['id']}</Col>
                        <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData['orderDate'] + '' + rowData['orderTime']}>{rowData['orderDate']} <br /> {rowData['orderTime']}</Col>
                        <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData['categoryData']}>{rowData['categoryData']}</Col>
                        <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData['cardData']['name']}>{rowData['cardData']['name']}</Col>
                        <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Denomination']}>
                            <div>
                                {(this.state.openClassList == rowData['id'] ?
                                    ((this.state.loadingClass == 1 && this.state.openClassList == rowData['id']) ?
                                        <CircularProgress className="CircularProgressColor hover" />
                                        :
                                        <ExpandLessIcon className='pointer-cursor hover' onClick={() => this.closeOpenClassList(rowData[''])} />
                                    )
                                    :
                                    <ExpandMoreIcon className='pointer-cursor hover' onClick={() => this.openClassList(rowData['id'])} />
                                )}
                            </div>
                        </Col>
                        <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData['amountCurr']}>{rowData['amountCurr']}</Col>
                        <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData['countCard']}>{rowData['countCard']}</Col>
                        <Col lg={1} md={3} sm={12} xs={12} className="text-center bt-text-align-left bt-marginTop" title={Strings['Action']}>
                            <Button variant="contained" className={rowData['orderTime'] == '' ? 'accept-action-btn mb-2' : 'accept-action-btn '} title={Strings['Accept']} onClick={() => { this.AcceptRequest(rowData['id']) }}>
                                <CheckIcon className="reset-filter-btn mt-1 mb-1" />
                            </Button>
                        </Col>
                        {(this.state.openClassList == rowData['id'] &&
                            this.state.cardClassName.length > 0 && this.state.loadingClass != 1) &&
                            <Col md={12} className='pt-3'>
                                <div>{Strings['Classes']} :</div>
                                {classesList}
                            </Col>
                        }
                    </Row>
                )
            })
        }
        return orderInfo;
    }


    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            this.getOrderRequset()
        });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            activePage: 1
        }, function () {
            this.getOrderRequset()
        })
    }
    handleRefresh = () => {
        // clear all filter data
        this.setState({
            loading: 1,
            data: [],
            currencyTitle: '',
            limit: 25,
            activePage: 1,
            dataCount: 0,
            errorMsg: '',
            openClassList: '',
            orderFilterDateFrom: currentDate,
            orderFilterDateTo: currentDate,
            cardClassName: [],
            loadingClass: 0,
            startSave: 0

        }, function () {
            this.getOrderRequset()
        });
    }

    render() {

        return (
            <div>
                {/* ///FiliterView--Start///// */}

                <Row className='page-filter-view'>
                    <Col lg={5} md={7} sm={12} xs={12}>
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            <Row className='filter-form'>

                                <Col md={5} lg={5} sm={5} xs={12}>
                                    <AvField name="orderFilterDateFrom" type='date' label={Strings['From Date']} value={this.state.orderFilterDateFrom} onChange={this.handleInputChange} />
                                </Col>
                                <Col md={5} lg={5} sm={5} xs={12}>
                                    <AvField name="orderFilterDateTo" type='date' label={Strings['To Date']} value={this.state.orderFilterDateTo} onChange={this.handleInputChange} />
                                </Col>
                                <Col md={2} lg={2} sm={2} xs={4}className='mb-2'>
                                    <Button variant="contained" className='refresh-action-btn refresh-btn-margin' onClick={this.handleRefresh} title={Strings['Refresh']}>
                                        <i className='flaticon-refresh reset-filter-btn'></i>
                                    </Button>
                                </Col>
                            </Row>
                        </AvForm>
                    </Col>

                </Row>
                {/* ///FiliterView--End///// */}

                {this.state.loading == 0 && this.state.errorMsg != '' &&
                    <Row>
                        <div className='inv-cards-view pb-5 width-100'>
                            <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
                                <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                                <div className='no-data-text'>{this.state.errorMsg}</div>
                            </div>
                        </div>
                    </Row>
                }

                {this.state.loading == 0 && this.state.data.length <= 0 &&
                    <Row>
                        <div className='inv-cards-view pb-5 width-100'>
                            <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
                                <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                                <div className='no-data-text'>{Strings["There is no data to display"]}</div>
                            </div>
                        </div>
                    </Row>
                }

                {this.state.loading == 1 &&
                    <Row>
                        <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                            <div className='card-body'>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                            </div>
                        </Col>
                    </Row>
                }

                {this.state.loading == 0 && this.state.data.length > 0 &&
                    <Row>
                        <div className='inv-cards-view width-100'>
                            <Row className='table-header-row'>
                                <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Request #']}>{Strings['Request #']}</Col>
                                <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Date']}>{Strings['Date']}</Col>
                                <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Card Type']}>{Strings['Card Type']}</Col>
                                <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Card Name']}>{Strings['Card Name']}</Col>
                                <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Denomination']}> {Strings['Denomination']}</Col>
                                <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Value']}>{Strings['Value']}</Col>
                                <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Cards Count']}>{Strings['Cards Count']}</Col>
                                <Col lg={1} md={3} sm={12} xs={12} className="overflow-text-length text-center bt-text-align-left " title={Strings['Action']}> {Strings['Action']}</Col>

                            </Row>
                            {this.displayData()}
                            {this.state.loading == 0 && this.state.data.length > 0 &&
                                <Col md={12}>
                                    <Row className='pagenation-view mt-3 mb-4'>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.dataCount}
                                            pageRangeDisplayed={4}
                                            onChange={this.handlePageChange}
                                            prevPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-next' : 'flaticon2-back'}></i>}
                                            nextPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-back' : 'flaticon2-next'}></i>}
                                            hideFirstLastPages
                                            innerClass={'pagination  pagination-rounded justify-content-end pagenation-view my-2'}
                                            itemClass={'page-item'}
                                            linkClass={'page-link'}
                                        />
                                    </Row>
                                </Col>
                            }
                        </div>
                    </Row>
                }
            </div>
        )
    }
}
export default unCompleteOrder;
import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import 'react-picky/dist/picky.css';
import { translations } from '../../../../translate.js';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toAbsoluteUrl } from "../../../../_metronic";
import { isEmpty } from "lodash";

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;
class VeiwSupplierApiBalance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: 1,
        }
    }
    componentDidMount() {
        this.get_supplier_api_bal();
    }
    get_supplier_api_bal() {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'get_supplier_api_bal',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({ data: data['supplierApi'], loading: 0 })
                }
            }
        })
    }
    displayData() {
        var supplierApiBal = [];
        var bal = '';
        this.state.data.map((rowData, index) => {
            bal = (rowData['balance'] == null || rowData['balance'] == '') ? '-' : rowData['balance'];
            supplierApiBal.push(
                <div className='mb-4 view-purchase-main-fields-row'>
                    <Col md={12} className='main-font-1 font-w-500'>{rowData['name']} :  </Col>
                    <Col md={12}>{bal}</Col>
                </div>
            )
        })
        return supplierApiBal

    }
    render() {
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col md={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'> {Strings['View API Suppliers Balance']}</div>
                        </Col>
                        <Col md={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>
                {this.state.loading == 1 &&
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} style={{ marginTop: "15%" }} className='text-center'>
                            <div className='card-body'>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                            </div>
                        </Col>
                    </Row>
                }
                {this.state.loading == 0 && this.state.data.length > 0 &&
                    <div className='sidebar-body-view'>
                        <div className='view-purchase-main-fields'>
                            {this.displayData()}
                        </div>
                    </div>
                }
                {this.state.loading == 0 && this.state.data.length <= 0 &&
                    <Row>
                        <div className='inv-cards-view pb-5 width-100'>
                            <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
                                <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                                <div className='no-data-text'>{Strings["There is no data to display"]}</div>
                            </div>
                        </div>
                    </Row>
                }
            </div>
        )
    }
}
export default VeiwSupplierApiBalance;
import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Pagination from "react-js-pagination";
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from '../../../../translate.js';
import { Button } from "@material-ui/core";

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;



class ViewCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            limit: 25,
            dataCount: 0,
            col: (localStorage.getItem('USER_LEVEL') == 1 || localStorage.getItem('INV_MGR') == 1) ? 2 : 3,
            data: [],
            errorMsg: '',
            code: '',
            serial: '',distId:this.props.distId?this.props.distId:''
        }
    }

    componentDidMount() {
        this.getInventoryCardsCodes()
    }

    handleRefresh = () => {
        // clear all filter data
        this.setState({
            activePage: 1,
            code: '',
            serial: ''
        }, function () {
            this.getInventoryCardsCodes()
        });
    }
    getInventoryCardsCodes() {
        this.setState({
            loading: 1
        })
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getInventoryCardsCodes',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'cardClassId': this.props.cardsCalssData.id,
                'page': this.state.activePage,
                'limit': this.state.limit,
                'serial': this.state.serial,
                'code': this.state.code,
                distId:this.state.distId,
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {

                // console.log('======================================');
                // console.log(data);

                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 1) {
                    this.setState({ errorMsg: data['errorMsg'] })
                } else {
                    this.setState({
                        data: data['cardsInventory'],
                        dataCount: data['cardsInventoryCounter'],
                        loading: 0
                    })
                }
            }
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            this.getInventoryCardsCodes();
        });
    }

    handleSearchChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [name]: value }, function () {
            this.setState({ activePage: 1 }, function () {
                this.getInventoryCardsCodes();
            });
        });
    }


    displayData() {
        var list = ''
        if (this.state.data.length > 0) {
            list = this.state.data.map((rowData, index) => {
                var count = parseInt(index) + 1
                return <Row key={index} className='table-body-row'>
                    <Col md={1} xs={12} lg={1} sm={12}>{count}</Col>
                    <Col md={3} xs={12} lg={3} sm={12} className='main-color-1 font-w-400'>{rowData['billNumber'] != '' ? rowData['billNumber'] : '-'}</Col>
                    <Col md={this.state.col} xs={12} lg={this.state.col} sm={12}>{rowData['apiAmount'] != '' ? rowData['apiAmount'] : '-'}</Col>
                    <Col md={this.state.col} xs={12} lg={this.state.col} sm={12}>{rowData['entryDate']}</Col>
                    {(localStorage.getItem('USER_LEVEL') == 1 || localStorage.getItem('INV_MGR') == 1) &&
                        <Col md={2} xs={12} lg={2} sm={12}>{rowData['used_at'] != '' && rowData['used_at'] != null ? rowData['used_at'] : '-'}</Col>
                    }
                    <Col md={2} xs={12} lg={2} sm={12} className='text-center'>
                        {rowData['used'] == 1 ? Strings['Used'] : Strings['Not Used']}
                    </Col>
                </Row>
            })
        }
        return list
    }



    render() {


        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col xs={10} lg={10} md={10} sm={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'> {Strings['View Cards']}
                                ({localStorage.getItem('LANG') == 1 ? this.props.cardsCalssData.name : this.props.cardsCalssData.name_en})
                            </div>
                        </Col>
                        <Col md={2} xs={2} lg={2} sm={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>

                <div className='sidebar-body-view sidebar-body-view-supplier-transaction'>
                    {/* {this.state.loading == 0 && this.state.data.length > 0 && */}
                    <Row className="page-filter-view">
                        <Col md={6} xs={12} lg={6} sm={12}>
                            {/* className="form-inline" */}
                            <AvForm >
                                <Row className='filter-form'>
                                    {/* <AvGroup className="mb-4 mt-2">
                                        <AvInput name="code" placeholder={Strings['Search For Code']} value={this.state.code} onChange={this.handleSearchChange} />
                                    </AvGroup> */}


                                    < Col md={5} xs={12} lg={5} sm={12}>
                                        <AvField name="code" type='text' placeholder={Strings['Search For Code']} value={this.state.code} onChange={this.handleSearchChange} />
                                    </Col>

                                    {(localStorage.getItem('USER_LEVEL') == 1 || localStorage.getItem('INV_MGR') == 1) &&
                                        <Col md={5} xs={12} lg={5} sm={12}  >
                                            <AvField name="serial" type='text' placeholder={Strings['Search For Serial No.']} value={this.state.serial} onChange={this.handleSearchChange} />
                                        </Col>
                                    }

                                    <Col md={2} xs={12} lg={2} sm={12} className='mb-1'>
                                        <Button variant="contained" className='refresh-action-btn   mb-md-0' onClick={this.handleRefresh} title={Strings['Refresh']}>
                                            <i className='flaticon-refresh reset-filter-btn'></i>
                                        </Button>
                                    </Col>
                                </Row >
                            </AvForm>
                        </Col>
                    </Row>
                    {/* } */}


                    {this.state.loading == 1 &&
                        <Row>
                            <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                                <div className='card-body'>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                    <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                </div>
                            </Col>
                        </Row>
                    }

                    {this.state.loading == 0 && this.state.errorMsg != '' &&
                        <div className='text-center' style={{ marginTop: "15%" }}>
                            <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                            <div className='no-data-text'>{this.state.errorMsg}</div>
                        </div>
                    }

                    {this.state.loading == 0 && this.state.data.length <= 0 &&
                        <div className='text-center' style={{ marginTop: "15%" }}>
                            <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                            <div className='no-data-text text-center'>{Strings["There is no data to display"]}</div>
                        </div>
                    }

                    {this.state.loading == 0 && this.state.data.length > 0 &&
                        <Row>
                            <div className='inv-cards-view width-100'>
                                <Row className='table-header-row'>
                                    <Col md={1} xs={12} lg={1} sm={12}>#</Col>
                                    <Col md={3} xs={12} lg={3} sm={12}>{Strings['Voucher Number']}</Col>
                                    <Col md={this.state.col} xs={12} lg={this.state.col} sm={12}>Po Number</Col>
                                    <Col md={this.state.col} xs={12} lg={this.state.col} sm={12}>{Strings['Entry Date']}</Col>
                                    {(localStorage.getItem('USER_LEVEL') == 1 || localStorage.getItem('INV_MGR') == 1) &&
                                        <Col md={2} xs={12} lg={2} sm={12}>{Strings['Used Date']}</Col>
                                    }
                                    <Col md={2} xs={12} lg={2} sm={12} className='text-center'>{Strings['Status']}</Col>
                                </Row>
                                {this.displayData()}
                                <Col md={12}>
                                    <Row className='pagenation-view mt-3'>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.dataCount}
                                            pageRangeDisplayed={4}
                                            onChange={this.handlePageChange}
                                            prevPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-next' : 'flaticon2-back'}></i>}
                                            nextPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-back' : 'flaticon2-next'}></i>}
                                            hideFirstLastPages
                                            innerClass={'pagination  pagination-rounded justify-content-end pagenation-view my-2'}
                                            itemClass={'page-item'}
                                            linkClass={'page-link'}
                                        />
                                    </Row>
                                </Col>
                            </div>
                        </Row>
                    }



                </div>
            </div>
        )
    }
}
export default ViewCards;
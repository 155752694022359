import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from '../../../../translate.js';

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class EditDistCardPrice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            distClassList: this.props.distClassList,
            classQty: this.props.cardsCalssData.classQty,
            // price: this.props.cardsCalssData.price,
            // buyPrice: this.props.cardsCalssData.buyPrice,
            price: this.props.cardsCalssData.priceFormat,
            buyPrice: this.props.cardsCalssData.buyPriceCurr,
            classes: this.props.cardsCalssData.classes,
            distId:this.props.distId?this.props.distId:'',
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }



    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value,
            submitError: ''
        })
    }

    displayCardClassPrice = () => {
        let fields = []
        var price = ''
        let scop = this
        if (Object.keys(this.state.classes).length > 0) {
            Object.keys(this.state.classes).map(function (rowData, index) {
                price = scop.state.classes[rowData]['priceCurr'] != '' && scop.state.classes[rowData]['priceCurr'] != undefined ? scop.state.classes[rowData]['priceCurr'] : scop.state.classes[rowData]['price']
                fields.push(
                    <AvField name={scop.state.classes[rowData]['id']}
                        type='number' label={scop.state.classes[rowData]['name'] + ' *'}
                        placeholder={Strings['Enter Price']}
                        value={price} required
                        onChange={scop.handleClassInputChange.bind(scop, scop.state.classes[rowData]['id'])} errorMessage={Strings['This field is required']} />
                )
            })
        }
        return fields
    }

    handleClassInputChange = (id, event) => {
        const target = event.target;
        // const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const newArray = this.state.classes;
        var name = 'priceCurr'
        newArray[id][name] = value;
        this.setState({ classes: newArray });
    }

    handleValidSubmit(event) {

        event.preventDefault();
        let classesFields = []
        let scop = this
        var price = ''
        if (Object.keys(this.state.classes).length > 0) {
            Object.keys(this.state.classes).map(function (rowData, index) {
                price = scop.state.classes[rowData]['priceCurr'] != '' && scop.state.classes[rowData]['priceCurr'] != undefined ? scop.state.classes[rowData]['priceCurr'] : scop.state.classes[rowData]['price']
                classesFields.push({
                    'id': scop.state.classes[rowData]['id'],
                    'price': price
                });

            })
        }


        // console.log(classesFields);

        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'updateMainDistCardPrice',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'cardClassId': this.props.cardsCalssData.classId,
                'buyPrice': this.state.price,
                'sellPrice': this.state.buyPrice,
                'classQty': this.state.classQty,
                'classesPrice': JSON.stringify(classesFields),
                distId:this.state.distId,
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 0) {
                    this.props.recallGetData();
                    this.props.handleCloseSideBar()
                } else {
                    this.setState({ submitError: data['errorMsg'] })
                }
            }
        })
    }


    render() {
        // console.log('this.props.cardsCalssData');
        // console.log(this.props.cardsCalssData);
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col lg={10} md={10} sm={10} xs={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'>
                                {Strings['Edit Classes Price']}
                                ({this.props.cardsCalssData['cardClassName']})
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={2} xs={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>

                <div className='sidebar-body-view'>
                    <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                        <AvField name="classQty" type='number' label={Strings['Qty'] + ' *'} placeholder={Strings['Enter Qty']} value={this.state.classQty} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                        <AvField name="price" type='number' label={Strings['Buy Price'] + ' *'} placeholder={Strings['Enter Buy Price']} value={this.state.price} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                        <AvField name="buyPrice" validate={{ min: { value: this.state.price, errorMessage: Strings['selling price must be greater or equal to the buy price'] } }} type='number' label={Strings['Selling Price'] + ' *'} placeholder={Strings['Enter Selling Price']} value={this.state.buyPrice} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />

                        {this.displayCardClassPrice()}

                        <div className='text-right mt-4'>
                            <button type="submit" className="btn form-save-btn mr-1 ml-1"> {Strings['Save']} </button>
                            <button type="button" className="btn form-cancel-btn m-l-10" onClick={this.props.handleCloseSideBar}>{Strings['Cancel']}</button>
                        </div>

                        {this.state.submitError &&
                            <div className='error-text-black'><i className='flaticon2-exclamation error-icon mr-1' /> {this.state.submitError}</div>
                        }

                    </AvForm>
                </div>
            </div>
        )
    }
}
export default EditDistCardPrice;
import React, { Component } from "react";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";
import Pagination from "react-js-pagination";
import "react-picky/dist/picky.css";
import { translations } from "../../../translate.js";
import SearchIcon from "@material-ui/icons/Search";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
var today = new Date();
var currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);
const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const getMinDate = (POS_DAYS_NUM) => {
  const today = new Date();
  // Adjust the date by subtracting POS_DAYS_NUM days
  const pastDate = new Date(today);
  pastDate.setDate(today.getDate() - POS_DAYS_NUM);

  // Ensure proper formatting
  const pastYear = pastDate.getFullYear();
  const pastMonth = String(pastDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const pastDay = String(pastDate.getDate()).padStart(2, "0");

  return `${pastYear}-${pastMonth}-${pastDay}`;
};

class POStransactionsLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOrderAction: false,
      orderRowData: [],
      posList: [],
      posCodeError: "",
      selectedPos: [],
      loading: 1,
      posCode: "",
      modal: true,
      statusList: [],
      categoryList: [],
      selectedCategoryList: [],
      cardTypeList: [],
      orderFilterOrderId:
        this.props.match.params.orderId == undefined
          ? ""
          : this.props.match.params.orderId,
      selectedCardTypeList: [],
      selectedStatusList: [],
      data: [],
      dataCount: 0,
      showExportOrder: 0,
      limit: 25,
      activePage: 1,
      emptyEmail: 0,
      loadingSave: 0,
      searchCode: 0,
      mainDist:
        localStorage.getItem("PERM_TYPE") == 5 ||
        localStorage.getItem("POS_MGR_EMP") == 1
          ? 0
          : 1,
      errorMsg: "",
      actionType: "",
      orderFilterDateFrom: currentDate,
      orderFilterDateTo: currentDate,
      transactionsType: 1,
      transactionsTypes: [
        { id: 1, title: Strings["Cards"] },
        { id: 2, title: Strings["Transactions"] },
      ],
      info: {
        totalQty: 0,
        totalAmount: 0,
        totalAmountFormat: "",
        finalBalance: "",
        finalBalanceFormat: "",
        totalAmountVisa: "",
      },
      currency: "",
      accountTransaction: [],
      paymentTypeKey: "1",
      paymentTypes: [],
      cards: [],
    };
  }
  handleRefresh = () => {
    // clear all filter data
    this.setState(
      {
        selectedPos: [],
        selectedCategoryList: [],
        selectedCardTypeList: [],
        selectedStatusList: [],
        orderFilterOrderId: "",
        searchCode: 0,
        orderFilterDateFrom: currentDate,
        paymentTypeKey: "1",
        transactionsType: 1,
        orderFilterDateTo: currentDate,
      },
      function() {
        this.getOrdersList();
      }
    );
  };
  errAlert = (msg, callback = () => {}) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Error"]}</h5>
            <p>{msg}</p>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                onClose();
                callback();
              }}
            >
              {Strings.ok}
            </button>
          </div>
        );
      },
    });
  };
  componentDidMount() {
    this.getFilterPaymentsTypes();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
        });
      }
    }
  };
  setUpSelectOptions = (data, type) => {
    if (type == "paymentMethod") {
      return (
        <option value={data.id} key={data.id}>
          {data.name}
        </option>
      );
    } else
      return (
        <option value={data.id} key={data.id}>
          {data.title}
        </option>
      );
  };
  getOrderStatus() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getOrderStatus",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              statusList: data["data"],
            });
          }
        }
      });
  }
  getFilterPaymentsTypes = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getFilterPaymentsTypes",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              paymentTypes: data,
            });
          }
        }
      });
  };

  getOrdersList = (withEmail = 0, seacrhCode = 0) => {
    this.setState({
      loading: 1,
    });
    let timeDef =
      new Date(this.state.orderFilterDateTo) -
      new Date(this.state.orderFilterDateFrom);
    let daysCount = Math.floor(timeDef / (1000 * 60 * 60 * 24));
    if (daysCount <= 7 && daysCount >= 0) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getPosOrders",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          page: this.state.activePage,
          limit: this.state.limit,
          fromDate: this.state.orderFilterDateFrom,
          toDate: this.state.orderFilterDateTo,
          posCode: this.state.posCode,
          byFilter: 1,
          dataType: "transLog",
          paymentTypeKey: this.state.paymentTypeKey,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else if (data["error"] == 1) {
              this.setState({ errorMsg: data["errorMsg"] });
            } else {
              this.setState({
                currency: data.currency,
                info: data.info,
                accountTransaction: data.accountTransaction,
                cards: data.cards,
                loading: 0,
                showExportOrder: data.showExportOrder,
              });
            }
          }
        });
      this.setState({
        loading: 0,
      });
    } else {
      this.errAlert(Strings.daysCountError);
      this.setState({
        loading: 0,
      });
    }
  };

  getCardsList() {
    if (this.state.selectedCategoryList.length > 0) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getCards",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          catId: this.getSelected("category"),
          mainDist: this.state.mainDist,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (typeof data !== "undefined") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState({
                cardTypeList: data,
              });
            }
          }
        });
    }
  }

  exportHandler() {
    window.location =
      window.API_PERM_USER +
      "?type=getPosOrders&byFilter=1&dataType=transLog&lang=" +
      localStorage.getItem("LANG") +
      "&posCode=" +
      this.state.posCode +
      "&userId=" +
      localStorage.getItem("USER_ID") +
      "&token=" +
      localStorage.getItem("USER_TOKEN") +
      "&export=1" +
      "&fromDate=" +
      this.state.orderFilterDateFrom +
      "&toDate=" +
      this.state.orderFilterDateTo +
      "&page=" +
      this.state.activePage +
      "&limit=" +
      this.state.limit +
      "&paymentTypeKey=" +
      this.state.paymentTypeKey;
  }

  handleChangeSelect = (type, option) => {
    if (type == "pos") {
      this.setState(
        (state) => {
          return {
            selectedPos: option,
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
        }
      );
    } else if (type == "category") {
      this.setState(
        (state) => {
          return {
            selectedCategoryList: option,
            selectedCardTypeList: [],
            cardTypeList: [],
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
          // this.getCardsList();
        }
      );
    } else if (type == "card") {
      this.setState(
        (state) => {
          return {
            selectedCardTypeList: option,
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
        }
      );
    } else if (type == "status") {
      this.setState(
        (state) => {
          return {
            selectedStatusList: option,
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
        }
      );
    }
  };
  getSelected = (type) => {
    if (type == "pos") {
      return this.state.selectedPos.map((data) => data.userId);
    }
    if (type == "category") {
      return this.state.selectedCategoryList.map((data) => data.id);
    }
    if (type == "card") {
      return this.state.selectedCardTypeList.map((data) => data.cardId);
    }
    if (type == "status") {
      return this.state.selectedStatusList.map((data) => data.id);
    }
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (name == "transactionsType") {
      this.setState({ [name]: value, paymentTypeKey: value == 1 ? "1" : "" });
    } else if (name == "email") {
      this.setState({
        [name]: value,
        emptyEmail: 0,
      });
    } else if (name == "code") {
      this.setState({
        [name]: value,
        emptyCode: 0,
      });
    } else {
      this.setState(
        {
          [name]: value,
          activePage: 1,
        },
        function() {
          // this.getOrdersList();
        }
      );
    }
  };

  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };
  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };

  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };

  openOrderAction = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (type == "view") {
      this.setState({
        orderRowData: data,
        openOrderAction: true,
        actionType: "viewOrder",
      });
    } else if (type == "export") {
      this.printPdf_CSV(data["id"], 1);
    } else if (type == "csv") {
      this.printPdf_CSV(data["id"], 2);
    } else if (type == "sms") {
      this.sendSms(data["id"]);
    } else if (type == "cancel") {
      this.cancelOrder(data["id"]);
    } else if (type == "classes") {
      this.setState({
        orderRowData: data,
        openOrderAction: true,
        actionType: "viewClasses",
      });
    } else if (type == "add_problem") {
      this.setState({
        actionType: type,
        openOrderAction: true,
        orderRowData: data,
      });
    }
  };

  printPdf_CSV(id, typeExport) {
    /*
            typeExport >> 1 : pdf
                          2 : csv
        */

    window.open(
      window.API_PERM_USER +
        "/permUser.php?type=" +
        "printPDFOrCSVInvoiceToCustomer" +
        "&lang=" +
        localStorage.getItem("LANG") +
        "&userId=" +
        localStorage.getItem("USER_ID") +
        "&token=" +
        localStorage.getItem("USER_TOKEN") +
        "&orderId=" +
        id +
        "&typeExport=" +
        typeExport,
      "_blank"
    );
  }

  sendSms(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to send sms ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickSendSms(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  }

  handleClickSendSms(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "sendSMSToCustomer",
        orderId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <div className="mb-3">
                      <span className="alert-delete-icon">!</span>
                    </div>
                    <p>{data["errorMsg"]}</p>
                    <button
                      type="button"
                      className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                      onClick={onClose}
                    >
                      {Strings.Cancel}
                    </button>
                  </div>
                );
              },
            });
          }
        }
      });
  }

  cancelOrder(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view alertBoxResopnsive">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to cancel order ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickCancelOrder(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  }

  handleClickCancelOrder(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "cancelOrderById",
        orderId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
            this.getOrdersList();
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <div className="mb-3">
                      <span className="alert-delete-icon">!</span>
                    </div>
                    <p>{data["errorMsg"]}</p>
                    <button
                      type="button"
                      className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                      onClick={onClose}
                    >
                      {Strings.Cancel}
                    </button>
                  </div>
                );
              },
            });
          }
        }
      });
  }
  handleSendEmail = () => {
    if (
      this.state.email != undefined &&
      this.state.email != null &&
      this.state.email != ""
    ) {
      this.getOrdersList(1);
      this.setState({ emptyEmail: 0 });
    } else {
      this.setState({ emptyEmail: 1 });
    }
  };
  handleSearchByCode = () => {
    if (
      this.state.code != undefined &&
      this.state.code != null &&
      this.state.code != ""
    ) {
      this.setState({ emptyCode: 0, searchCode: 1 }, function() {
        this.getOrdersList(0, 1);
      });
    } else {
      this.setState({ emptyCode: 1 });
    }
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getOrdersList();
    });
  };

  handleCloseSideBar = () => {
    this.setState({ openOrderAction: false, orderRowData: [] });
  };

  recallGetData = () => {
    this.setState(
      {
        openOrderAction: false,
        orderRowData: [],
      },
      function() {
        this.getOrdersList();
      }
    );
  };
  displayData() {
    var orderInfo = "";
    var classStyle = {
      "text-1": "gray-color",
      "text-2": "warning-color",
      "text-3": "succ-color",
      "text-4": "reject-color",
      "text-5": "addCredit-color",
      "text-6": "wthdrawal-color",
      "text-7": "profit-color",
      "text-8": "status-8-color",
      "text-9": "status-9-color",
      "text-11": "pos-correct-mng-color",
    };
    var classColStatus = "";
    var cardName = "";
    var serailNumber = "";
    if (
      this.state.loading == 0 &&
      ((this.state.cards?.length > 0 && this.state.transactionsType == 1) ||
        (this.state.accountTransaction?.length > 0 &&
          this.state.transactionsType == 2))
    ) {
      let data =
        this.state.transactionsType == 1
          ? this.state.cards
          : this.state.accountTransaction;
      orderInfo = data.map((rowData, index) => {
        if (rowData["status"] !== "") {
          classColStatus = classStyle["text-" + rowData["status"]];
        }
        classColStatus =
          " overflow-text-length  bold-font " + classColStatus + " ";
        rowData["actualAmount"] =
          rowData["actualAmount"] == "" ? 0 : rowData["actualAmount"];
        cardName =
          localStorage.getItem("LANG") == 1
            ? rowData["cardName_ar"]
            : rowData["cardName_en"];
        serailNumber =
          rowData["billNumber"] != undefined && rowData["billNumber"] != ""
            ? rowData["billNumber"]
            : "-";
        return (
          <Row key={index} className="table-body-row">
            {this.state.transactionsType == 2 && (
              <Col
                lg={2}
                md={2}
                sm={2}
                xs={12}
                className="overflow-text-length"
                title={Strings["Type Of Operation"]}
              >
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["Type Of Operation"]} :{" "}
                </span>
                {rowData.type == 1 ? Strings["charge"] : Strings["Add"]}
              </Col>
            )}
            {/* {localStorage.getItem("SHOW_ORDER_ID_POS") == 1 && (
              <Col
                lg={2}
                md={2}
                sm={2}
                xs={12}
                className="overflow-text-length"
                title={Strings["Order #"]}
              >
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["Order #"]} :{" "}
                </span>
                {this.state.transactionsType == 1 ? rowData.cardId : rowData.id}
              </Col>
            )} */}
            {this.state.transactionsType == 1 && (
              <Col
                lg={2}
                md={2}
                sm={2}
                xs={12}
                className="overflow-text-length"
                title={Strings["Card"]}
              >
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["Card"]} :{" "}
                </span>
                {rowData.cardName}
              </Col>
            )}
            {/* <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={Strings["Class"]}
            >
              {rowData.cardClass}
            </Col> */}

            {this.state.transactionsType == 1 && (
              <Col
                lg={2}
                md={2}
                sm={2}
                xs={12}
                className="overflow-text-length"
                title={Strings["Quantity"]}
              >
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["Quantity"]} :{" "}
                </span>
                {rowData.qty}
              </Col>
            )}
            {this.state.transactionsType == 2 && (
              <Col
                lg={2}
                md={2}
                sm={2}
                xs={12}
                className="overflow-text-length"
                title={Strings["Date"]}
              >
                {" "}
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["Date"]} :{" "}
                </span>
                {rowData.entryDate}
              </Col>
            )}
            {/* <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={Strings["Print Status"]}
            >
              {rowData.printStatus}
            </Col> */}
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={Strings["Value"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Value"]} :{" "}
              </span>
              {this.state.transactionsType == 1
                ? localStorage.getItem("USER_PRICE_SHOW") == 1
                  ? rowData.amount + this.state.currency
                  : "--"
                : rowData.valueFormat}
            </Col>
            {this.state.transactionsType == 2 && (
              <Col
                lg={2}
                md={2}
                sm={2}
                xs={12}
                className="overflow-text-length"
                title={Strings["Note"]}
              >
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["Note"]} :{" "}
                </span>
                {rowData.note1 ? rowData.note1 : "--"}
              </Col>
            )}
            {/* <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={Strings["Balance Before"]}
            >
              {rowData.beforeSumFormat}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={Strings["Balance After"]}
            >
              {rowData.afterSumFormat}
            </Col> */}
          </Row>
        );
      });
    }
    console.log("orderInfo", orderInfo);
    return orderInfo;
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          centered={true}
          className={"requestdataModal"}
        >
          <div
            className="close-modal-btn "
            onClick={() => {
              this.setState({ posCode: "" });
              this.props.history.push({
                pathname: "/dashboard",
              });
            }}
          >
            X
          </div>
          <ModalBody>
            <AvForm>
              <div className="d-flex flex-column justify-content-between h-100">
                <h5 className="text-center">{Strings["Enter Pos Code"]}</h5>
                <div>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <AvField
                        name="posCode"
                        type="password"
                        label={" "}
                        placeholder={Strings["Enter Pos Code"]}
                        value={this.state.posCode}
                        onChange={this.handleInputChange}
                        errorMessage={Strings["This field is required"]}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <button
                  type={"button"}
                  className={
                    "btn gradient-button-view gradient-button gradient-button-font-12 ml-1 f-15 gradient-button-view-mobile "
                  }
                  onClick={(event) => {
                    event.preventDefault();
                    console.log(
                      "this.state.posCode",
                      this.state.posCode,
                      'localStorage.getItem("POS_CODE")',
                      localStorage.getItem("POS_CODE")
                    );
                    if (
                      this.state.posCode == localStorage.getItem("POS_CODE")
                    ) {
                      this.setState({ modal: false, posCodeError: "" });
                      this.getOrdersList();
                    } else {
                      this.setState({ posCodeError: Strings.errPosCode });
                    }
                  }}
                >
                  {Strings["Confirm"]}
                </button>
                {this.state.posCodeError != "" &&
                  this.state.posCodeError != undefined && (
                    <div className="error-text-red font-size-15 text-center mt-3">
                      {this.state.posCodeError}
                    </div>
                  )}
              </div>
            </AvForm>
          </ModalBody>
        </Modal>
        <Row className="page-filter-view">
          <Col lg={5} md={7}>
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
            >
              <Row className="filter-form">
                {/* <Col md={10} lg={10} sm={10} xs={10}>
                  <div className="member-searchable-list mb-2">
                    <Picky
                      value={this.state.selectedPos}
                      options={this.state.posList}
                      onChange={this.handleChangeSelect.bind(this, "pos")}
                      open={false}
                      valueKey="userId"
                      labelKey="name"
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["POS"]}
                      filterPlaceholder={Strings["Select POS"]}
                    />
                  </div>
                </Col> */}

                <Col lg={5} md={5} sm={5} xs={12}>
                  <AvField
                    type="select"
                    // label={Strings["Transactions type"] + " *"}
                    name="transactionsType"
                    value={this.state.transactionsType}
                    onChange={this.handleInputChange}
                    className="p-1 pl-3"
                    errorMessage={Strings["This field is required"]}
                  >
                    <option
                      style={{ display: "none" }}
                      value=""
                      selected="selected"
                    >
                      {Strings["Select"] + " " + Strings["Transactions type"]}
                    </option>
                    {this.state.transactionsTypes.map((data) =>
                      this.setUpSelectOptions(data, "")
                    )}
                  </AvField>
                </Col>
                <Col lg={5} md={5} sm={5} xs={10}>
                  <AvField
                    type="select"
                    // label={Strings["Transactions type"] + " *"}
                    name="paymentTypeKey"
                    value={this.state.paymentTypeKey}
                    onChange={this.handleInputChange}
                    className="p-1 pl-3"
                    errorMessage={Strings["This field is required"]}
                    disabled={this.state.transactionsType != 1}
                  >
                    <option
                      style={{ display: "none" }}
                      value=""
                      selected="selected"
                    >
                      {Strings["Select"] + " " + Strings["Payment type"]}
                    </option>
                    {this.state.paymentTypes.map((data) =>
                      this.setUpSelectOptions(data, "paymentMethod")
                    )}
                  </AvField>
                </Col>
                <Col md={2} lg={2} sm={2} xs={2} className="mb-2">
                  <Button
                    variant="contained"
                    className="refresh-action-btn"
                    onClick={this.handleRefresh}
                    title={Strings["Refresh"]}
                  >
                    <i className="flaticon-refresh reset-filter-btn"></i>
                  </Button>
                </Col>
                <Col md={6} lg={6} sm={6} xs={12} className="mb-2">
                  <AvField
                    name="orderFilterDateFrom"
                    type="date"
                    label={Strings["From Date"]}
                    value={this.state.orderFilterDateFrom}
                    onChange={this.handleInputChange}
                    max={getTodayDate()}
                    min={getMinDate(
                      Number(localStorage.getItem("POS_DAYS_NUM"))
                    )}
                  />
                </Col>
                <Col md={6} lg={6} sm={6} xs={12} className="mb-2">
                  <AvField
                    name="orderFilterDateTo"
                    type="date"
                    label={Strings["To Date"]}
                    value={this.state.orderFilterDateTo}
                    onChange={this.handleInputChange}
                    max={getTodayDate()}
                    min={getMinDate(
                      Number(localStorage.getItem("POS_DAYS_NUM"))
                    )}
                  />
                </Col>
              </Row>
            </AvForm>
          </Col>

          <Col lg={7} md={5} className="text-right Export-textAlignleft">
            <Col lg={12} md={12}>
              <div className="d-flex align-items-center gap-1 flex-row-reverse justify-content-center justify-content-md-start justify-content-end">
                {this.state.showExportOrder == 1 && (
                  <button
                    className="btn page-action-btn ml-1"
                    onClick={(event) => this.exportHandler(event)}
                  >
                    {Strings["Export"]}
                  </button>
                )}
                <button
                  variant="contained"
                  className="btn form-save-btn ml-1 mr-1 "
                  onClick={this.getOrdersList}
                >
                  {Strings["Search"]}{" "}
                  <SearchIcon className="font-size-15  main-color-2" />
                </button>
              </div>
            </Col>
            <Row className="mr-1 ml-1">
              <Col md={4} lg={6} sm={12} xs={12}></Col>
              <Col
                md={8}
                lg={6}
                sm={12}
                xs={12}
                className="mt-3 algin-total-box financail-operation-report-summary-box-view"
              >
                <div>
                  <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                    <Col
                      md={8}
                      xs={12}
                      lg={8}
                      sm={12}
                      className="main-color-1 font-w-400"
                    >
                      {Strings["Quantity"]}
                    </Col>
                    <Col
                      md={4}
                      xs={12}
                      lg={4}
                      sm={12}
                      className="text-md-right"
                    >
                      {this.state.info.totalQty}
                    </Col>
                  </Row>

                  {this.state.paymentTypeKey != 9 && (
                    <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                      <Col
                        md={8}
                        xs={12}
                        lg={8}
                        sm={12}
                        className="main-color-1 font-w-400"
                      >
                        {Strings["Amount"]}
                      </Col>
                      <Col
                        md={4}
                        xs={12}
                        lg={4}
                        sm={12}
                        className="text-md-right"
                      >
                        {this.state.info.totalAmountFormat}
                      </Col>
                    </Row>
                  )}

                  {this.state.paymentTypeKey != 9 && (
                    <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                      <Col
                        md={8}
                        xs={12}
                        lg={8}
                        sm={12}
                        className="main-color-1 font-w-400"
                      >
                        {Strings["Balance"]}
                      </Col>
                      <Col
                        md={4}
                        xs={12}
                        lg={4}
                        sm={12}
                        className="text-md-right"
                      >
                        {this.state.info.finalBalanceFormat}
                      </Col>
                    </Row>
                  )}
                  {this.state.paymentTypeKey == 9 && (
                    <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                      <Col
                        md={8}
                        xs={12}
                        lg={8}
                        sm={12}
                        className="main-color-1 font-w-400"
                      >
                        {Strings["totalAmountVisa"]}
                      </Col>
                      <Col
                        md={4}
                        xs={12}
                        lg={4}
                        sm={12}
                        className="text-md-right"
                      >
                        {this.state.info.totalAmountVisa}
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        {this.state.loading == 0 && this.state.errorMsg != "" && (
          <div className="inv-cards-view pb-5">
            <div
              className="text-center"
              style={{ marginTop: "15%", marginBottom: "15%" }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">{this.state.errorMsg}</div>
            </div>
          </div>
        )}

        {this.state.loading == 0 &&
          ((this.state.cards?.length <= 0 &&
            this.state.transactionsType == 1) ||
            (this.state.accountTransaction?.length <= 0 &&
              this.state.transactionsType == 2)) && (
            <div className="inv-cards-view pb-5">
              <div
                className="text-center"
                style={{ marginTop: "15%", marginBottom: "15%" }}
              >
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                  )}
                  className="img-fluid no-data-img"
                />
                <div className="no-data-text">
                  {Strings["There is no data to display"]}
                </div>
              </div>
            </div>
          )}

        {this.state.loading == 1 && (
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}

        {/* //table--start// */}
        {this.state.loading == 0 &&
          ((this.state.cards?.length > 0 && this.state.transactionsType == 1) ||
            (this.state.accountTransaction?.length > 0 &&
              this.state.transactionsType == 2)) && (
            <Row>
              <div className="inv-cards-view width-100">
                <Row className="table-header-row d-none d-lg-flex d-md-flex">
                  {this.state.transactionsType == 2 && (
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Type Of Operation"]}
                    >
                      {Strings["Type Of Operation"]}
                    </Col>
                  )}
                  {/* {localStorage.getItem("SHOW_ORDER_ID_POS") == 1 && (
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Order #"]}
                    >
                      {Strings["Order #"]}
                    </Col>
                  )} */}
                  {this.state.transactionsType == 1 && (
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Card"]}
                    >
                      {Strings["Card"]}
                    </Col>
                  )}

                  {this.state.transactionsType == 1 && (
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Quantity"]}
                    >
                      {Strings["Quantity"]}
                    </Col>
                  )}
                  {this.state.transactionsType == 2 && (
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Date"]}
                    >
                      {Strings["Date"]}
                    </Col>
                  )}

                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Value"]}
                  >
                    {Strings["Value"]}
                  </Col>
                  {this.state.transactionsType == 2 && (
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Note"]}
                    >
                      {Strings["Note"]}
                    </Col>
                  )}
                  {/* <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Balance Before"]}
                  >
                    {Strings["Balance Before"]}
                  </Col>
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Balance After"]}
                  >
                    {Strings["Balance After"]}
                  </Col> */}
                  {/* <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Action"]}
                  >
                    {Strings["Action"]}
                  </Col> */}
                </Row>
                {this.displayData()}
                {this.state.loading == 0 && this.state.data?.length > 0 && (
                  <Col md={12}>
                    <Row className="pagenation-view mt-3 mb-4">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.limit}
                        totalItemsCount={this.state.dataCount}
                        pageRangeDisplayed={4}
                        onChange={this.handlePageChange}
                        prevPageText={
                          <i
                            className={
                              localStorage.getItem("LANG") == 1
                                ? "flaticon2-next"
                                : "flaticon2-back"
                            }
                          ></i>
                        }
                        nextPageText={
                          <i
                            className={
                              localStorage.getItem("LANG") == 1
                                ? "flaticon2-back"
                                : "flaticon2-next"
                            }
                          ></i>
                        }
                        hideFirstLastPages
                        innerClass={
                          "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                        }
                        itemClass={"page-item"}
                        linkClass={"page-link"}
                      />
                    </Row>
                  </Col>
                )}
              </div>
            </Row>
          )}
      </div>
    );
  }
}
export default withRouter(POStransactionsLog);

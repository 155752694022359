import React, { Component } from "react";
import {
  UncontrolledDropdown,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Switch from "@material-ui/core/Switch";
import { Drawer, Button } from "@material-ui/core";
import DaftraForm from "./forms/daftraForm";
import { toAbsoluteUrl } from "../../../_metronic";
import { translations } from "../../../translate.js";
import { Link } from "react-router-dom";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class DaftraEmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 25,
      activePage: 1,
      dataCount: 0,
      openHeader: "",
      viewMode: "",
      empData: [],
      openleftModal: false,
      data: [],
      openPosAction: false,
      actionType: "",
      daftraData: [],
      errorMsg: "",
    };
  }

  componentDidMount() {
    this.getDaftraEmp();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  // https://cp8.easypay-card.com/API/daftra_Api.php?type=getClients&page=1&limit=25&clientName=pos%20Client&
  // userId=7421&token=413e972f492640456d37ec5dfc3637ad
  getDaftraEmp() {
    fetch(window.API_DAFTRA_API, {
      method: "POST",
      body: JSON.stringify({
        type: "getClients",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        page: this.state.activePage,
        limit: this.state.limit,
        clientName: this.state.searchKey,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('================================ data');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ errorMsg: data["errorMsg"] });
          } else {
            this.setState({
              data: data["data"],
              dataCount: data["count"],
              loading: 0,
            });
          }
        }
      });
  }
  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
        });
      }
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getDaftraEmp();
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
      },
      function() {
        this.getDaftraEmp();
      }
    );
  };

  handleRefresh = () => {
    this.setState(
      {
        searchKey: "",
      },
      function() {
        this.getDaftraEmp();
      }
    );
  };

  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };
  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };

  handleClosePosAction = () => {
    this.setState({ daftraData: [], actionType: "", openPosAction: false });
  };

  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };

  openPosAction = (event, type, data, viewMode) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        daftraData: data,
        viewMode: viewMode,
        actionType: type,
        openPosAction: true,
      },
      function() {}
    );
  };

  displayData() {
    var orderInfo = "";
    if (this.state.loading == 0 && this.state.data.length > 0) {
      orderInfo = this.state.data.map((rowData, index) => {
        return (
          <Row key={rowData.clientId} className="table-body-row">
            <Col
              lg={2}
              md={3}
              sm={4}
              xs={12}
              className="overflow-text-length"
              title={rowData["clientName"]}
            >
              {rowData["clientName"] != null &&
              rowData["clientName"] != undefined &&
              rowData["clientName"] != ""
                ? rowData["clientName"]
                : "-"}
            </Col>
            <Col
              lg={2}
              md={3}
              sm={4}
              xs={12}
              className="overflow-text-length"
              title={rowData["created"]}
            >
              {rowData["created"]}
            </Col>
            <Col
              lg={2}
              md={3}
              sm={4}
              xs={12}
              className="overflow-text-length"
              title={rowData["clientPhone1"]}
            >
              {rowData["clientPhone1"] != null &&
              rowData["clientPhone1"] != undefined &&
              rowData["clientPhone1"] != ""
                ? rowData["clientPhone1"]
                : "-"}

              <br />
              {rowData["clientPhone1"] != null &&
              rowData["clientPhone1"] != undefined &&
              rowData["clientPhone1"] != ""
                ? rowData["clientPhone1"]
                : "-"}
            </Col>
            <Col
              lg={2}
              md={3}
              sm={4}
              xs={12}
              className="overflow-text-length"
              title={rowData["clientEmail"]}
            >
              {rowData["clientEmail"] != null &&
              rowData["clientEmail"] != undefined &&
              rowData["clientEmail"] != ""
                ? rowData["clientEmail"]
                : "-"}
            </Col>
            <Col
              lg={2}
              md={3}
              sm={4}
              xs={12}
              className="overflow-text-length"
              title={rowData["clientAddress"]}
            >
              {rowData["clientAddress"] != null &&
              rowData["clientAddress"] != undefined &&
              rowData["clientAddress"] != ""
                ? rowData["clientAddress"]
                : "-"}
            </Col>

            <Col
              lg={1}
              md={3}
              sm={4}
              xs={12}
              className={
                "action-icon-view actionDropdownList actionDropdownListPadding-0"
              }
            >
              <Dropdown
                isOpen={
                  this.state.openHeader == rowData["clientId"] ? true : false
                }
                className="font-size-13 actionDropdownList actionDropdownListPadding-0 orderlistActionlist"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link pt-0 actionDropdownList  actionDropdownListPadding-0"
                  caret
                >
                  <MoreVertIcon
                    className="menu-dots-vertical"
                    onClick={(event) =>
                      this.openHeaderMenu(event, rowData["clientId"])
                    }
                  />
                </DropdownToggle>

                <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList">
                  <div
                    className="actionDropdownList"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    <DropdownItem
                      className="actionDropdownList"
                      onClick={(event) =>
                        this.openPosAction(event, "add_get_amount", rowData)
                      }
                    >
                      {Strings["Add / Get Amount"]}
                    </DropdownItem>
                    <DropdownItem
                      className="actionDropdownList"
                      tag="a"
                      href={"/receipt/" + rowData.clientId}
                    >
                      {Strings["Receipt"]}
                    </DropdownItem>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        );
      });
    }
    return orderInfo;
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={4} className="main-color-1 inv-class-view-title">
            <div>{Strings["Points Of Sales"]} </div>
            <hr className="inv-hr-view-2" />
          </Col>
        </Row>
        <div className="inv-cards-view">
          <Row className="page-filter-view">
            <Col md={4} lg={4}>
              <AvForm
                onValidSubmit={this.handleValidSubmit}
                ref={(c) => (this.form = c)}
              >
                <Row className="filter-form">
                  <Col sm={10} xs={10} md={10} lg={10}>
                    <AvField
                      name="searchKey"
                      type="text"
                      placeholder={Strings["Search For Name"]}
                      value={this.state.searchKey}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col sm={2} xs={2} md={2} lg={2} className="mb-2">
                    <Button
                      variant="contained"
                      className="refresh-action-btn"
                      onClick={this.handleRefresh}
                      title={Strings["Refresh"]}
                    >
                      <i className="flaticon-refresh reset-filter-btn"></i>
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </Col>
          </Row>
        </div>
        {this.state.loading == 0 && this.state.errorMsg != "" && (
          <div className="inv-cards-view pb-5">
            <div
              className="text-center"
              style={{ marginTop: "15%", marginBottom: "15%" }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">{this.state.errorMsg}</div>
            </div>
          </div>
        )}

        {this.state.loading == 0 && this.state.data.length <= 0 && (
          <div className="inv-cards-view pb-5">
            <div
              className="text-center"
              style={{ marginTop: "15%", marginBottom: "15%" }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">
                {Strings["There is no data to display"]}
              </div>
            </div>
          </div>
        )}

        {this.state.loading == 1 && (
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}

        {this.state.loading == 0 && this.state.data.length > 0 && (
          <Row>
            <div className="inv-cards-view width-100">
              <Row className="table-header-row">
                <Col
                  lg={2}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Name"]}
                >
                  {Strings["Name"]}
                </Col>
                <Col
                  lg={2}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Date"]}
                >
                  {" "}
                  {Strings["Date"]}
                </Col>
                <Col
                  lg={2}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Phone"]}
                >
                  {Strings["Phone"]}
                </Col>
                <Col
                  lg={2}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Email"]}
                >
                  {Strings["Email"]}
                </Col>
                <Col
                  lg={2}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Region"]}
                >
                  {Strings["Region"]}
                </Col>
                <Col
                  lg={1}
                  md={3}
                  sm={4}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Action"]}
                >
                  {Strings["Action"]}
                </Col>
              </Row>
              {this.displayData()}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Col md={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              )}
            </div>
          </Row>
        )}

        {/* //Drawer2Start */}
        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openPosAction}
          className={
            this.state.viewMode == "large"
              ? "sidebar-modal-large-view view-supplier-transaction-modal"
              : "sidebar-modal-view"
          }
        >
          {this.state.actionType == "add_get_amount" && (
            <DaftraForm
              daftraData={this.state.daftraData}
              handleCloseSideBar={this.handleClosePosAction}
              recallGetData={this.recallGetData}
            />
          )}
        </Drawer>
        {/* //Drawer2End */}
      </div>
    );
  }
}
export default DaftraEmp;

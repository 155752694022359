import React, { Component } from "react";
import { Row, Col, Progress } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { translations } from "../../../../translate.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import PublishIcon from "@material-ui/icons/Publish";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class AddProblem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitError: "",
      orderId:
      localStorage.getItem("PERM_TYPE") == 5 ||
        localStorage.getItem("PERM_TYPE") == 1 || (localStorage.getItem("USER_LEVEL") == 3 &&
        localStorage.getItem("ACCOUNT_TYPE") == 1)
          ? this.props.orderRowData.id
          : this.props.orderRowData.orderId,
      title: "",
      problemType: "",
      note: "",
      note2: "",
      status: "",
      submitError: "",
      deleteImage1: 0,
      loadingSave: 0,
    };
  }

  handleValidSubmit = (event) => {
    this.setState({ loadingSave: 1 });
    event.preventDefault();
    var data = new FormData();
    var img =
      document.querySelector("input[name=img]") != null
        ? document.querySelector("input[name=img]").files[0]
        : "";
    var uploadedImages = "";
    if (this.state.image1 == 1) {
      uploadedImages = "1";
    }
    data.append("data", img);
    data.append("image", uploadedImages);
    data.append("type", "addOrderProblem");
    data.append("userId", localStorage.getItem("USER_ID"));
    data.append("token", localStorage.getItem("USER_TOKEN"));
    data.append("lang", localStorage.getItem("LANG"));
    data.append("orderId", this.state.orderId);
    // data.append("title", this.state.title);
    data.append("title", this.state.problemType);
    data.append("desc1", this.state.note);
    data.append("desc2", this.state.note2);
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        console.log(data, "data");
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({
              submitError: data["errorMsg"],
              loadingSave: 0,
            });
          } else {
            var msg =
              this.state.viewMode == "add"
                ? Strings["Added Successfully"]
                : Strings["Modified Successfully"];
            this.setState({ status: msg, loadingSave: 0 });
            setTimeout(() => {
              this.handleCloseSideBar();
            }, 1000);
          }
        }
      });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      submitError: "",
    });
  };

  handleCloseSideBar = () => {
    this.props.handleCloseSideBar();
  };

  handleImgChange = (event) => {
    var fileReader = new FileReader();
    const scope = this;
    if (event.target.files[0].type.includes("image")) {
      this.setState({ displayErrorHeaderMsg: false });
      this.setState({ progressUploadHeader: 10 });
      fileReader.readAsBinaryString(event.target.files[0]);
      fileReader.onprogress = function(data) {
        if (data.lengthComputable) {
          var progress = parseInt((data.loaded / data.total) * 100, 10);
          scope.setState({ progressUploadHeader: progress });
        }
      };
    } else {
      this.setState({ displayErrorHeaderMsg: true });
    }
    this.setState({
      image1: 1,
    });
  };
  deleteUploadImage = () => {
    this.setState({ deleteImage1: 1 });
  };
  render() {
  console.log(this.props.orderRowData.id,'this.props.orderRowData.id',this.props.orderRowData.orderId,'this.props.orderRowData.orderId');

    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={12}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Add Problem"]} ({Strings["Order Id"]} -{" "}
                {this.state.orderId})
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={12} className="text-right">
              <HighlightOffIcon
                onClick={this.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <AvForm
            onValidSubmit={this.handleValidSubmit}
            ref={(c) => (this.form = c)}
          >
            {/* <AvField name="title" label={Strings['Problem Title'] + ' *'} placeholder={Strings['Enter'] + ' ' + Strings['Problem Title']} value={this.state.title} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} /> */}
            <AvField
              name="problemType"
              label={Strings["Problem Type"] + " *"}
              placeholder={Strings["Enter"] + " " + Strings["Problem Type"]}
              value={this.state.problemType}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              type="textarea"
              name="note"
              label={Strings["First Note"]}
              placeholder={Strings["Enter"] + " " + Strings["First Note"]}
              value={this.state.note}
              onChange={this.handleInputChange}
            />
            <AvField
              type="textarea"
              name="note2"
              label={Strings["Second Note"]}
              placeholder={Strings["Enter"] + " " + Strings["Second Note"]}
              value={this.state.note2}
              onChange={this.handleInputChange}
            />
            <div className="mt-1 mb-2">
              <label className="font-w-400 ">{Strings["Image"]}</label>
              <span className={"fileInput-span col-md-12"}>
                <PublishIcon />{" "}
                <span className="pointer-cursor pt-3">{Strings["Upload"]}</span>
                <input
                  type="file"
                  accept="image/*"
                  className="fileInput-width fileInput-opacity"
                  onChange={(e) => this.handleImgChange(e)}
                  name="img"
                ></input>
              </span>
              {this.state.progressUploadHeader != undefined &&
                this.state.progressUploadHeader > 0 && (
                  <Progress
                    striped
                    value={this.state.progressUploadHeader}
                    className="mb-1 mt-1 progress-bg-color"
                  >
                    {this.state.progressUploadHeader} %
                  </Progress>
                )}
              {this.state.displayErrorHeaderMsg == true && (
                <div className="invalid-feedback d-block mb-1">
                  {Strings["Upload Image only"]}
                </div>
              )}
            </div>
            <div className="text-right mt-4">
              {this.state.loadingSave == 1 ? (
                <button
                  type="button"
                  className="btn form-save-btn mr-1 ml-1 task-action-btn-loading"
                >
                  <CircularProgress className="SaveCircularProgressColor" />{" "}
                  {Strings["Save"]}
                </button>
              ) : (
                <button type="submit" className="btn form-save-btn mr-1 ml-1">
                  {" "}
                  {Strings["Save"]}{" "}
                </button>
              )}

              <button
                type="button"
                className="btn form-cancel-btn m-l-10"
                onClick={this.handleCloseSideBar}
              >
                {Strings["Cancel"]}
              </button>
            </div>
            {this.state.submitError && (
              <div className="error-text-black">
                <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                {this.state.submitError}
              </div>
            )}
            {this.state.status && (
              <div className="error-text-black">
                <i className="flaticon2-check-mark success-icon mr-1" />{" "}
                {this.state.status}
              </div>
            )}
          </AvForm>
        </div>
      </div>
    );
  }
}

export default AddProblem;

import React, { Component } from "react";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter } from "react-router-dom";
import ApartmentIcon from "@material-ui/icons/Apartment";
import { Drawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { translations } from "../../../translate";
import ChangePassword from "./forms/ChangePassword";
import ChangePOScode from "./forms/ChangePOScode";
import RequestBalanceRecharge from "./forms/RequestBalanceRecharge";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class AccountSettingsPOS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLeftModal: false,
      userData: {},
      USER_PRICE_SHOW: localStorage.getItem("USER_PRICE_SHOW"),
      modal: true,
      password: "",
      amount: 1,
      code: "",
      posCodeError: "",
      errorMsg: "",

      //   passwordEntred:'',
    };
  }
  // posEmp
  componentDidMount() {
    this.sendCode();
  }
  getAccountData() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getMainDistInfo",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              userData: data["data"],
            });
          }
        }
      });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
    });
  };

  openSidebarModal = (event, type, data, viewMode) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({
      propsData: data,
      actionType: type,
      viewMode: viewMode,
      openleftModal: true,
    });
  };

  handleCloseSideBar = (res = "", error = 0) => {
    this.setState({
      openleftModal: false,
      propsData: [],
      actionType: "",
      viewMode: "",
    });
  };

  handleFormAction = (type) => {
    this.setState(
      {
        propsData: [],
        status: "",
        openleftModal: false,
        viewMode: "",
      },
      function() {
        if (type == "edit") {
          //   this.getAccountData();
        }
      }
    );
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (this.state.selectedTransfer?.id == 1) {
      //     distCurrencyAmount8
      // rate
      // amount

      this.setState(
        {
          [name]: value,
        },
        function() {}
      );
    } else {
      this.setState(
        {
          [name]: value,
        },
        function() {}
      );
    }
  };
  AuthenticationReset = (event) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>
              {this.state.USER_PRICE_SHOW == 1
                ? Strings["You want to Hide Cards Prices?"]
                : Strings["You want to Display Cards Prices?"]}
            </p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickAuthenticationReset();
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };

  handleClickAuthenticationReset() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "updatePricesShow",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        pricesShow: this.state.USER_PRICE_SHOW == 1 ? 0 : 1,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            localStorage.setItem(
              "USER_PRICE_SHOW",
              this.state.USER_PRICE_SHOW == 1 ? 0 : 1
            );
            this.setState({
              USER_PRICE_SHOW: this.state.USER_PRICE_SHOW == 1 ? 0 : 1,
            });
          }
        }
      });
  }
  sendCode = async () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "sendCode",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
          }
        }
      });
  };
  checkCode = async () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "checkCode",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        code: this.state.password,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            if (data.success == 0) {
              this.setState({ posCodeError: data.errorMsg });
            } else {
              this.setState({ modal: false, posCodeError: "" });
            }
          }
        }
      });
  };
  render() {
    console.log(this.props, "distDatadistData");
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          centered={true}
          className={"requestdataModal"}
        >
          <div
            className="close-modal-btn "
            onClick={() => {
              this.props.history.push({
                pathname: "/dashboard",
              });
            }}
          >
            X
          </div>
          <ModalBody>
            <AvForm>
              <div className="d-flex flex-column justify-content-between h-100">
                <h5 className="text-center">{Strings.codeSent}</h5>
                <div>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <AvField
                        name="password"
                        type="password"
                        label={" "}
                        placeholder={Strings.enterCode}
                        value={this.state.password}
                        onChange={this.handleInputChange}
                        errorMessage={Strings["This field is required"]}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <button
                  type={"button"}
                  className={
                    "btn gradient-button-view gradient-button gradient-button-font-12 ml-1 f-15 gradient-button-view-mobile "
                  }
                  onClick={(event) => {
                    event.preventDefault();
                    this.checkCode();
                  }}
                >
                  {Strings["Confirm"]}
                </button>
                {this.state.posCodeError != "" &&
                  this.state.posCodeError != undefined && (
                    <div className="error-text-red font-size-15 text-center mt-3">
                      {this.state.posCodeError}
                    </div>
                  )}
              </div>
            </AvForm>
          </ModalBody>
        </Modal>
        <Row>
          {/* <Col className="mb-5 pb-4" md={12}>
            <Paper elevation={0}>
              <Breadcrumbs
                separator={
                  localStorage.getItem("LANG") == 1 ? (
                    <NavigateBeforeIcon fontSize="large" />
                  ) : (
                    <NavigateNextIcon fontSize="large" />
                  )
                }
                aria-label="Breadcrumb"
              >
                <>
                  <Link
                    className="color-bridcrim"
                    to={{
                      pathname: "/settings/admin/Distributors",
                    }}
                    href="#"
                  >
                    {" "}
                    {Strings["Distributors"]}
                  </Link>
                </>

                <Typography color="textPrimary">
                  {this.state.distData?.fname}
                </Typography>
              </Breadcrumbs>
            </Paper>{" "}
            <hr className="inv-hr-view-2" />
          </Col> */}
          <Col lg={4} md={6} className="mb-2">
            <div className="pos-main-profile">
              <div className="text-center">
                <img
                  alt="Profile"
                  src={toAbsoluteUrl("/media/ezpay/user.png")}
                  className="img-fluid"
                />
              </div>

              <Row className="mb-3 mt-3">
                <Col md={12} className="mb-1 main-color-1 font-w-400">
                  <PermIdentityIcon /> {Strings["Name"]}
                </Col>
                <Col md={12} className="black-font ml-4 pl-3">
                  {localStorage.getItem("USER_FULL_NAME")}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} className="mb-1 main-color-1 font-w-400">
                  <MailOutlineIcon /> {Strings["Email"]}
                </Col>
                <Col md={12} className="black-font ml-4 pl-3">
                  {localStorage.getItem("USER_EMAIL")}
                </Col>
              </Row>
              {/* <Row className="mb-3">
                <Col md={12} className="mb-1 main-color-1 font-w-400">
                  <PhoneAndroidIcon /> {Strings["Phone"]}
                </Col>
                <Col md={12} className="black-font ml-4 pl-3">
                  {this.state.userData.mobile}
                </Col>
              </Row> */}

              <Row className="text-center mt-4 mb-3 pos-main-profile-main-btn-view">
                {/* <Col md={4} lg={4} sm={4} xs={4}>
                                    <div className='pos-main-profile-main-btn pos-main-profile-main-btn-gradient' onClick={(event) => this.openSidebarModal(event, 'edit', this.state.userData)}>
                                        {Strings['Edit']}
                                    </div>
                                </Col> */}
                <Col md={12} lg={12} sm={12} xs={12}>
                  <div
                    className="pos-main-profile-main-btn pos-main-profile-main-btn-gradient"
                    onClick={(event) => this.AuthenticationReset(event)}
                  >
                    {this.state.USER_PRICE_SHOW == 1
                      ? Strings["Hide Cards Prices"]
                      : Strings["Display Cards Prices"]}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={8} md={6} className="mb-2">
            <Row>
              <Col md={6} lg={this.state.column} sm={12} xs={12}>
                <div
                  onClick={(event) =>
                    this.openSidebarModal(event, "password", {})
                  }
                  className="pos-main-profile-section-box main-color-1"
                >
                  <div>
                    <BorderColorIcon className="pos-main-profile-section-box-icon" />
                  </div>
                  <div>{Strings["Change Password"]}</div>
                </div>
              </Col>
              <Col md={6} lg={this.state.column} sm={12} xs={12}>
                <div
                  onClick={(event) =>
                    this.openSidebarModal(event, "posCode", {})
                  }
                  className="pos-main-profile-section-box main-color-1"
                >
                  <div>
                    <FindInPageIcon className="pos-main-profile-section-box-icon" />
                  </div>
                  <div>{Strings["Change POS Code"]}</div>
                </div>
              </Col>
              <Col md={6} lg={this.state.column} sm={12} xs={12}>
                <div
                  onClick={(event) =>
                    this.openSidebarModal(event, "balance", {})
                  }
                  className="pos-main-profile-section-box main-color-1"
                >
                  <div>
                    <ApartmentIcon className="pos-main-profile-section-box-icon mb-2" />
                  </div>
                  <div>{Strings["Request Balance Charge"]}</div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openleftModal}
          className={
            this.state.viewMode == "large"
              ? "sidebar-modal-large-view"
              : "sidebar-modal-view"
          }
        >
          {this.state.actionType == "password" && (
            <ChangePassword
              handleCloseSideBar={this.handleCloseSideBar}
              handleFormAction={this.handleFormAction}
            />
          )}
          {this.state.actionType == "posCode" && (
            <ChangePOScode
              handleCloseSideBar={this.handleCloseSideBar}
              handleFormAction={this.handleFormAction}
            />
          )}
          {this.state.actionType == "balance" && (
            <RequestBalanceRecharge
              handleCloseSideBar={this.handleCloseSideBar}
              handleFormAction={this.handleFormAction}
            />
          )}
        </Drawer>
      </div>
    );
  }
}
export default withRouter(AccountSettingsPOS);

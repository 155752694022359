import React, { useContext, useEffect } from "react";
import { translations } from "../../../translate.js";
import { MyContext } from "../../../app/Context/MyContext.jsx";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

export default function SupervisorDynamicBalance({ type }) {
  const { supervisorBalance } = useContext(MyContext);

  return (
    <div
      className={
        type
          ? "d-flex gap-2 d-md-none d-lg-none justify-content-between w-100"
          : ""
      }
    >
      <div className="text-center mb-3 left-menu-balance-view">
        <div className="main-color-1 font-w-500">{Strings["Balance"]}</div>
        <div>{supervisorBalance.balanceCurr}</div>
      </div>
      <div className="text-center mb-3 left-menu-balance-view">
        <div className="main-color-1 font-w-500">{Strings["debit"]}</div>
        <div>{supervisorBalance.debitCurr}</div>
      </div>
      <div className="text-center mb-3 left-menu-balance-view">
        <div className="main-color-1 font-w-500">{Strings["Receivables"]}</div>
        <div>{supervisorBalance.receivablesCurr}</div>
      </div>
    </div>
  );
}

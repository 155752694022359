import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from "../../../../translate.js";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
const SortableItem = SortableElement(({ value }) => (
  <div
    style={{
      position: "relative",
      width: "100%",
      display: "block",
      padding: 10,
      backgroundColor: "#FFF",
      borderBottom: "1px solid #EFEFEF",
      boxSizing: "border-box",
      WebkitUserSelect: "none",
      cursor: "all-scroll",
      opacity: 10,
      visibility: "visible",
    }}
  >
    {value}
  </div>
));

const SortableList = SortableContainer(({ items }) => (
  <div
    className="sort-box-width-mobile"
    style={{
      width: "100%",
      margin: "0 auto",
      overflow: "auto",
      backgroundColor: "#f3f3f3",
      border: "1px solid #EFEFEF",
      borderRadius: 6,
      opacity: 10,
      visibility: "visible",
    }}
  >
    {items.map((value, index) => (
      <SortableItem
        key={`item-${index}`}
        hideSortableGhost={false}
        index={index}
        value={value.name}
      />
    ))}
  </div>
));

class SortPriority extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cardClassList: [],
      selectedCardClass: [],
      classId: this.props.isCard == 1 ? "" : this.props.cardsCalssData.id,
      errorMsg: "",
      submitError: "",
      status: "",
      loading: 0,
      isCard: this.props.isCard,
      cardId: this.props.isCard == 1 ? this.props.cardsCalssData.cardId : "",
      items: [],
      distId: this.props.distId?this.props.distId:'',
    };
  }

  componentDidMount() {
    if (this.state.isCard == 1) {
      this.getCardClassDirectCharge();
    } else {
      this.sortPriorityApi();
    }
  }
  sortPriorityApi(sort = 0, sortedIds = []) {
    if (sort == 0) this.setState({ loading: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "sortPriorityApi",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        classId: this.state.classId,
        addSort: sort,
        sortedList: sort == 1 ? JSON.stringify(sortedIds) : "",
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            var varName = sort == 1 ? "submitError" : "errorMsg";
            this.setState({ [varName]: data["errorMsg"], status: "" });
          } else {
            if (sort == 1) {
              this.setState(
                { status: data["errorMsg"], submitError: "" },
                function() {
                  if (this.state.isCard == 0) this.props.recallGetData();
                }
              );
            } else {
              this.setState({
                errorMsg: "",
                data: data["data"],
                items: data["data"],
                loading: 0,
              });
            }
          }
        }
      });
  }

  getCardClassDirectCharge = () => {
    this.setState({ loading: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCardClassDirectCharge",
        cardId: this.state.cardId,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ cardClassList: data["data"], loading: 0 });
          }
        }
      });
  };

  handleChangeSelect = (type, option) => {
    this.setState(
      (state) => {
        return {
          [type]: option,
        };
      },
      function() {
        this.setState(
          {
            classId: option.id,
            errorMsg: "",
            submitError: "",
            status: "",
          },
          function() {
            this.sortPriorityApi();
          }
        );
      }
    );
  };
  handleSortAction = (event) => {
    event.preventDefault();

    if (this.state.data.length > 0) {
      var sortedIds = [];
      this.state.items.map((rowData, index) => {
        sortedIds.push(rowData.supplyerKey);
      });
      this.sortPriorityApi(1, sortedIds);
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });
  };

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {this.state.isCard == 1
                  ? Strings["Prioritizing Suppliers for Classes"]
                  : Strings["Prioritizing Suppliers"]}
                (
                {localStorage.getItem("LANG") == 1
                  ? this.props.cardsCalssData.name
                  : this.props.cardsCalssData.name_en}
                )
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <Row>
            {this.state.isCard == 1 && this.state.cardClassList.length > 0 && (
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="member-searchable-list mb-2">
                  <Picky
                    value={this.state.selectedCardClass}
                    options={this.state.cardClassList}
                    onChange={this.handleChangeSelect.bind(
                      this,
                      "selectedCardClass"
                    )}
                    open={false}
                    valueKey={"id"}
                    labelKey={
                      localStorage.getItem("LANG") == 1 ? "name" : "name_en"
                    }
                    multiple={false}
                    includeSelectAll={false}
                    includeFilter={true}
                    clearFilterOnClose={true}
                    dropdownHeight={200}
                    placeholder={Strings["Select Class"]}
                    filterPlaceholder={Strings["Select Class"]}
                  />
                </div>
              </Col>
            )}

            <Col md={12}>
              {this.state.loading == 1 && (
                <Row>
                  <Col
                    md={12}
                    style={{ marginTop: "15%" }}
                    className="text-center"
                  >
                    <div className="card-body">
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                    </div>
                  </Col>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <div className="mt-4">
                  <Row>
                    <Col md={12}>
                      <SortableList
                        items={this.state.items}
                        onSortEnd={this.onSortEnd}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Row>
                  <Col md={12}>
                    <div className="text-right mt-4">
                      <button
                        className="btn form-save-btn mr-1 ml-1 mt-3"
                        onClick={(event) => this.handleSortAction(event)}
                      >
                        {" "}
                        {Strings["Save"]}{" "}
                      </button>
                      <button
                        type="button"
                        className="btn form-cancel-btn mr-1 ml-1 mt-3"
                        onClick={this.props.handleCloseSideBar}
                      >
                        {Strings["Cancel"]}
                      </button>
                    </div>
                  </Col>
                  <Col md={12}>
                    {this.state.status && (
                      <div className="error-text-black">
                        <i
                          className={
                            "flaticon2-check-mark success-icon mr-1 mb-3 mt-4"
                          }
                        />{" "}
                        {this.state.status}
                      </div>
                    )}
                    {this.state.submitError && (
                      <div className="error-text-black">
                        <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                        {this.state.submitError}
                      </div>
                    )}
                  </Col>
                </Row>
              )}

              {this.state.loading == 0 &&
                (this.state.isCard == 0 ||
                  (this.state.isCard == 1 &&
                    this.state.classId != "" &&
                    this.state.cardClassList.length > 0)) &&
                this.state.errorMsg == "" &&
                this.state.data.length <= 0 && (
                  <div>
                    <Row className="text-center" style={{ marginTop: "15%" }}>
                      <div>
                        <img
                          alt="Logo"
                          src={toAbsoluteUrl(
                            "/media/ezpay/EZPAY_LOGO_GRAY" +
                              window.MAIN_IMAGE +
                              ".png"
                          )}
                          className="img-fluid no-data-img"
                        />
                        <div className="no-data-text">
                          {Strings["The card class has no supplier API"]}
                        </div>
                      </div>
                    </Row>
                  </div>
                )}
              {this.state.loading == 0 &&
                this.state.isCard == 1 &&
                this.state.classId == "" &&
                this.state.errorMsg == "" &&
                this.state.data.length <= 0 &&
                this.state.cardClassList.length > 0 && (
                  <div>
                    <Row className="text-center" style={{ marginTop: "15%" }}>
                      <div>
                        <img
                          alt="Logo"
                          src={toAbsoluteUrl(
                            "/media/ezpay/EZPAY_LOGO_GRAY" +
                              window.MAIN_IMAGE +
                              ".png"
                          )}
                          className="img-fluid no-data-img"
                        />
                        <div className="no-data-text">
                          {
                            Strings[
                              "Select card class to display its API suppliers"
                            ]
                          }
                        </div>
                      </div>
                    </Row>
                  </div>
                )}
              {this.state.loading == 0 &&
                this.state.isCard == 1 &&
                this.state.cardClassList.length <= 0 &&
                this.state.errorMsg == "" && (
                  <div>
                    <Row className="text-center" style={{ marginTop: "15%" }}>
                      <div>
                        <img
                          alt="Logo"
                          src={toAbsoluteUrl(
                            "/media/ezpay/EZPAY_LOGO_GRAY" +
                              window.MAIN_IMAGE +
                              ".png"
                          )}
                          className="img-fluid no-data-img"
                        />
                        <div className="no-data-text">
                          {
                            Strings[
                              "All card classes following this card do not have an API supplier"
                            ]
                          }
                        </div>
                      </div>
                    </Row>
                  </div>
                )}
              {this.state.loading == 0 &&
                this.state.errorMsg != "" &&
                this.state.data.length <= 0 && (
                  <div>
                    <Row className="text-center" style={{ marginTop: "15%" }}>
                      <div>
                        <img
                          alt="Logo"
                          src={toAbsoluteUrl(
                            "/media/ezpay/EZPAY_LOGO_GRAY" +
                              window.MAIN_IMAGE +
                              ".png"
                          )}
                          className="img-fluid no-data-img"
                        />
                        <div className="no-data-text">
                          {this.state.errorMsg}
                        </div>
                      </div>
                    </Row>
                  </div>
                )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default SortPriority;

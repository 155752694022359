import React, { Component } from "react";
import { UncontrolledDropdown, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import Pagination from "react-js-pagination";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { toAbsoluteUrl } from "../../../_metronic";
import { translations } from '../../../translate.js';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class Receipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 10,
            activePage: 1,
            dataCount: 0,
            openHeader: '',
            viewMode: '',
            empData: [],
            openleftModal: false,
            data: [],
            openPosAction: false,
            actionType: '',
            daftraData: [],
            errorMsg: '',

        }
    }

    componentDidMount() {
        console.log('this.props.match.params.posId');
        console.log(this.props.match.params.posId);
        this.getDaftraReceipt()
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (event.target.className != undefined) {
            if (!event.target.classList.contains('actionDropdownList')) {
                this.setState({

                    visibleid: '',

                })
            }
        }
    }

    // https://cp8.easypay-card.com/API/daftra_Api.php?type=getClientPayment&page=1&
    // limit=25&userId=7421&token=413e972f492640456d37ec5dfc3637ad
    getDaftraReceipt() {
        // this.setState({ loading: 1 })
        fetch(window.API_DAFTRA_API, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getClientPayment',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'page': this.state.activePage,
                'limit': this.state.limit,
                'clientId': this.props.match.params.posId

            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {

                console.log('================================ data');
                console.log(data);

                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 1) {
                    this.setState({ errorMsg: data['errorMsg'] })
                } else {
                    this.setState({
                        data: data['data'],
                        dataCount: data['count'],
                        loading: 0
                    })
                }
            }
        })


    }
    ///handle When Page Change//
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            this.getDaftraReceipt()
        });
    }



    addRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: index })
    }
    removeRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: -1 })
    }


    onVisibleChange = (visible, id, type) => {
        this.setState({
            [type]: id,
        });
    }


    displayData() {
        var orderInfo = ''
        if (this.state.loading == 0 && this.state.data.length > 0) {
            orderInfo = this.state.data.map((rowData, index) => {
                return (
                    <Row key={rowData.clientId} className='table-body-row'>
                        <Col lg={2} md={2} sm={4} xs={12} className="overflow-text-length" title={rowData['daftraId']}>{rowData['daftraId']}</Col>
                        <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData['clientName']}>{rowData['clientName']}</Col>
                        <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData['date']}> {rowData['date']}</Col>
                        <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData['payment_method']}>{rowData['payment_method']}</Col>
                        <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={rowData['amount']}>{rowData['amount']} {rowData['currency_code']}</Col>
                        <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" >
                            <div title={Strings['Note']}>
                                <Tooltip title={Strings['responseData']} visible={this.state.visibleid == rowData['daftraId'] ? true : false}
                                    animation="zoom" onVisibleChange={(event) => this.onVisibleChange(event, rowData['daftraId'], 'visibleid')}
                                    trigger="click" overlay={<span className='report-tooltip-span'>


                                        <p className='mb-0 mr-1 tooltipRequestStyle'>
                                            {rowData['receipt_notes']}
                                        </p>

                                    </span>}>
                                    <InfoOutlinedIcon title={Strings['responseData']} className="reset-filter-btn mt-1 mb-1" />
                                </Tooltip>
                            </div>

                        </Col>
                    </Row>
                )
            })
        }
        return orderInfo;
    }

    // 'type': 'getClientPayment',
    // 'lang': localStorage.getItem('LANG'),
    // 'userId': localStorage.getItem('USER_ID'),
    // 'token': localStorage.getItem('USER_TOKEN'),
    // 'page': this.state.activePage,
    // 'limit': this.state.limit,
    // 'clientId': this.props.match.params.posId
    exportHandler() {
        window.location = window.API_DAFTRA_API + "?type=getClientPayment&lang=" +
            localStorage.getItem('LANG') + "&userId=" + localStorage.getItem('USER_ID') +
            "&token=" + localStorage.getItem('USER_TOKEN') + "&export=1" +
            "&clientId=" + this.props.match.params.posId
    }
    render() {

        return (
            <div>

                <Row>
                    <Col md={4} className='main-color-1 inv-class-view-title'>
                        <div>{Strings['Receipt']}</div>
                        <hr className='inv-hr-view-2' />
                    </Col>
                </Row>
                {this.state.loading == 0 && this.state.data.length > 0 &&
                <Row className='page-filter-view mt-2'>
                    <Col lg={5} md={7}>

                    </Col>
                    <Col lg={7} md={5} className='text-right Export-textAlignleft'>
                        <button className="btn page-action-btn ml-1" onClick={(event) => this.exportHandler(event)}>
                            {Strings['Export']}
                        </button>
                    </Col>
                </Row>
    }

                {this.state.loading == 0 && this.state.errorMsg != '' &&
                    <div className='inv-cards-view pb-5'>
                        <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
                            <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                            <div className='no-data-text'>{this.state.errorMsg}</div>
                        </div>
                    </div>
                }

                {this.state.loading == 0 && this.state.data.length <= 0 &&
                    <div className='inv-cards-view pb-5'>
                        <div className='text-center' style={{ marginTop: "15%", marginBottom: "15%" }}>
                            <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                            <div className='no-data-text'>{Strings["There is no data to display"]}</div>
                        </div>
                    </div>
                }

                {this.state.loading == 1 &&
                    <Row>
                        <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                            <div className='card-body'>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                            </div>
                        </Col>
                    </Row>
                }

                {this.state.loading == 0 && this.state.data.length > 0 &&

                    <Row>
                        <div className='inv-cards-view width-100'>
                            <Row className='table-header-row'>
                                <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Id']}>{Strings['Id']}</Col>
                                <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Name']}>{Strings['Name']}</Col>
                                <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Date']}> {Strings['Date']}</Col>
                                <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['payment_method']}> {Strings['payment_method']}</Col>

                                <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Amount']}>{Strings['Amount']}</Col>
                                <Col lg={2} md={3} sm={4} xs={12} className="overflow-text-length" title={Strings['Note']}>{Strings['Note']}</Col>

                            </Row>
                            {this.displayData()}
                            {this.state.loading == 0 && this.state.data.length > 0 &&
                                <Col md={12}>
                                    <Row className='pagenation-view mt-3 mb-4'>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.dataCount}
                                            pageRangeDisplayed={4}
                                            onChange={this.handlePageChange}
                                            prevPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-next' : 'flaticon2-back'}></i>}
                                            nextPageText={<i className={localStorage.getItem('LANG') == 1 ? 'flaticon2-back' : 'flaticon2-next'}></i>}
                                            hideFirstLastPages
                                            innerClass={'pagination  pagination-rounded justify-content-end pagenation-view my-2'}
                                            itemClass={'page-item'}
                                            linkClass={'page-link'}
                                        />
                                    </Row>
                                </Col>
                            }
                        </div>
                    </Row>

                }



            </div>
        )
    }
}
export default Receipt;

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import { translations } from '../../../../translate.js';
import { atelierEstuaryDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import DeleteIcon from '@material-ui/icons/Delete';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class CategoryLangForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitError: '',
            deleteImage: 0,
            catId: this.props.categoryData['id'],
            arName: '',
            enName: '',
            image: '',
            loading: 1,
            id: ''
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    componentDidMount() {
        this.getDistCardsInfo()
    }

    getDistCardsInfo() {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDistCardsInfo',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'cardId': this.state.catId,
                'cardType': 1
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            this.setState({ loading: 1 });
            if (typeof data !== 'undefined') {

                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {

                    // console.log('data');
                    // console.log(data);

                    if (data['error'] == 0) {
                        this.setState({
                            loading: 0,
                            arName: data['data']['nameAr'],
                            enName: data['data']['nameEn'],
                            image: data['data']['image'],
                            imageUrl: data['data']['image'],
                            id: (data['data']['id'] != undefined && data['data']['id'] != '' && data['data']['id'] != null) ? data['data']['id'] : '',
                            submitError: ''
                        })
                    } else {
                        this.setState({
                            loading: 0,
                            submitError: data['errorMsg'],
                        })
                    }

                }
            }
        });
    }

    handleValidSubmit(event) {
        event.preventDefault();

        var data = new FormData();
        var cardCatImage = document.querySelector('input[name=image]').files[0];
        var deletedImages = 0;
        if (this.state.deleteImage == 1) {
            deletedImages = '1'
        }
        data.append("data", cardCatImage);
        data.append("type", 'updateDistCards');
        data.append("userId", localStorage.getItem('USER_ID'));
        data.append("token", localStorage.getItem('USER_TOKEN'));
        data.append("lang", localStorage.getItem('LANG'));
        data.append("nameAr", this.state.arName);
        data.append("nameEn", this.state.enName);
        data.append("id", this.state.id);
        data.append("cardType", 1);
        data.append("cardId", this.state.catId);
        data.append("deletedImages", deletedImages);
        fetch(window.API_PERM_USER, {
            // mode: 'no-cors',
            method: "POST",
            body: data,
            headers: {
                Accept: 'application/json',
            },
        }).then(function (response) {
            if (!response.ok) {
                // console.log(response.statusText);
            }
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                // console.log('===============================');
                // console.log(data);

                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 1) {
                    this.setState({ submitError: data['errorMsg'] })
                } else {
                    this.props.handleFormAction();
                }
            }
        });

    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            submitError: ''
        })
    }

    handleImgChange = (event) => {
        var fileReader = new FileReader();
        const scope = this

        if (event.target.files[0].type.includes("image")) {
            this.setState({ displayErrorHeaderMsg: false });
            this.setState({ progressUploadHeader: 10 });
            fileReader.readAsBinaryString(event.target.files[0]);
            fileReader.onprogress = function (data) {

                if (data.lengthComputable) {
                    var progress = parseInt(((data.loaded / data.total) * 100), 10);
                    scope.setState({ progressUploadHeader: progress });
                }
            }
        } else {
            this.setState({ displayErrorHeaderMsg: true });
        }


        this.setState({
            image: event.target.files[0]['name'],
            imageUrl: ''
        });
    }
    deleteUploadImage = () => {
        this.setState({ deleteImage: 1 })
    }
    render() {
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col lg={10} md={10} sm={10} xs={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'>  {Strings['Edit']} {Strings['Category']} ( {localStorage.getItem("LANG") == 1 ? this.props.categoryData.name : this.props.categoryData.name_en} )
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={2} xs={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>

                {this.state.loading == 1 &&
                    <Row>
                        <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                            <div className='card-body'>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                            </div>
                        </Col>
                    </Row>
                }


                <div className='sidebar-body-view'>
                    {this.state.loading == 0 && this.state.submitError == '' &&
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            <AvField name="arName" label={Strings['Category Name'] + ' (عربي)'} placeholder={Strings['Enter Category Name'] + ' (عربي)'} value={this.state.arName} onChange={this.handleInputChange} />
                            <AvField name="enName" label={Strings['Category Name'] + ' (English)'} placeholder={Strings['Enter Category Name'] + ' (English)'} value={this.state.enName} onChange={this.handleInputChange} />

                            <div className='mt-2 mb-2'>
                                <label className='input-label'>{Strings['Image']}</label>
                                <span className={'fileInput-span col-md-12'}>
                                    <PublishIcon /> <span className='pointer-cursor pt-3'>{Strings['Upload']}</span>
                                    <input type="file" accept="image/*" className='fileInput-width fileInput-opacity' onChange={(e) => this.handleImgChange(e)} name='image'></input>
                                </span>
                                {(this.state.progressUploadHeader != undefined && this.state.progressUploadHeader > 0) &&
                                    <Progress striped value={this.state.progressUploadHeader} className='mb-1 mt-1 progress-bg-color'>{this.state.progressUploadHeader} %</Progress>
                                }
                                {(this.state.displayErrorHeaderMsg == true) &&
                                    <div className='invalid-feedback d-block mb-1'>{Strings['Upload Image only']}</div>
                                }
                                {(this.state.image != null && this.state.image != undefined && this.state.image != '' && this.state.imageUrl != null && this.state.imageUrl != undefined && this.state.imageUrl != '' && this.state.deleteImage == 0 )&&
                                    <div className='text-center mt-4'>
                                        <a href={this.state.image} download className='inv-category-img-title overflow-text-length' target='_blank'>
                                            <img src={this.state.image} className='img-fluid inv-category-img' />
                                        </a>
                                        <div className='task-action-file-details-delete-icon' onClick={() => this.deleteUploadImage('1')}>
                                            <span><DeleteIcon /> {Strings['Delete']}</span>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className='text-right mt-4'>
                                <button type="submit" className="btn form-save-btn mr-1 ml-1"> {Strings['Save']} </button>
                                <button type="button" className="btn form-cancel-btn m-l-10" onClick={this.props.handleCloseSideBar}>{Strings['Cancel']}</button>
                            </div>

                            {this.state.submitError &&
                                <div className='error-text-black'><i className='flaticon2-exclamation error-icon mr-1' /> {this.state.submitError}</div>
                            }

                        </AvForm>
                    }

                    {this.state.loading == 0 && this.state.submitError != '' &&
                        <div className='error-text-black'><i className='flaticon2-exclamation error-icon mr-1' /> {this.state.submitError} </div>
                    }
                </div>
            </div>
        )
    }
}
export default CategoryLangForm;

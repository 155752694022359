import React, { Component } from "react";
import {
  Row,
  Col,
  CustomInput,
  Progress,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button, Drawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";
import Pagination from "react-js-pagination";
import { Picky } from "react-picky";
import { CircularProgress } from "@material-ui/core";
import "react-picky/dist/picky.css";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { translations } from "../../../translate.js";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AddProblem from "./forms/addProblem";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

var today = new Date();
var currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 1,
      orderFilterDateFrom: currentDate,
      orderFilterDateTo: currentDate,
      data: [],
      categoryList: [],
      selectedCategoryList: [],
      selectedCategoryId: "",
      cardTypeList: [],
      selectedCardTypeList: [],
      selectedCardTypeId: "",
      status: 0,
      currencyTitle: "",
      limit: 25,
      activePage: 1,
      dataCount: 0,
      showExportOrder: 0,
      errorMsg: "",
      openClassList: "",
      referenceNo: "",
      selectedStatusList: [],
      selectedStatusId: "",
      orderFilterOrderId:
        this.props.match.params.orderId == undefined
          ? ""
          : this.props.match.params.orderId,
      cardClassName: [],
      loadingClass: 0,
      startSave: 0,
      openOrderAction: false,
      statusList: [
        {
          id: "1",
          name: Strings["Not Received"],
        },
        {
          id: "2",
          name: Strings["In Proccess"],
        },
        {
          id: "3",
          name: Strings["Complete"],
        },
        {
          id: "4",
          name: Strings["Rejected"],
        },
        // {
        //     id: '5',
        //     name: Strings['Canceled']
        // }, {
        //     id: '6',
        //     name: Strings['Cards Restored To Inventory']
        // }, {
        //     id: '7',
        //     name: Strings['Some Card Used']
        // }, {
        //     id: '8',
        //     name: Strings['Some Cards Have Been Recovered']
        // }, {
        //     id: '9',
        //     name: Strings['EcoVoucher Cards Have Been Restored']
        // }
      ],
    };
  }
  handleRefresh = () => {
    // clear all filter data
    this.setState(
      {
        activePage: 1,
        loading: 1,
        selectedCategoryList: [],
        selectedCategoryId: "",
        selectedCardTypeList: [],
        selectedCardTypeId: [],
        orderFilterOrderId: "",
        orderFilterDateFrom: currentDate,
        orderFilterDateTo: currentDate,
        referenceNo: "",
        status: 0,
        selectedStatusId: "",
      },
      function() {
        this.getUserOrder();
      }
    );
  };

  componentDidMount() {
    this.getCategoryList();
    if (
      this.props.match.params.orderId != undefined &&
      this.props.match.params.orderId != "" &&
      this.props.match.params.orderId != null &&
      this.props.match.params.orderId != 0
    ) {
      this.setState(
        {
          orderFilterDateFrom: "",
          orderFilterDateTo: "",
        },
        function() {
          this.getUserOrder();
        }
      );
    } else {
      this.getUserOrder();
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
        });
      }
    }
  };

  getCategoryList() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCategories",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        // this.setState({ loading: 1 });
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              categoryList: data,
            });
          }
        }
      });
  }

  getCardsList() {
    if (this.state.selectedCategoryList.length > 0) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getCards",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          catId: this.getSelected("category"),
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (typeof data !== "undefined") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState({
                cardTypeList: data,
              });
            }
          }
        });
    }
  }

  getUserOrder() {
    this.setState({ loading: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getUserOrder",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        page: this.state.activePage,
        limit: this.state.limit,
        orderFilterStatus: this.getSelected("status"),
        orderFilterCardCatg: this.getSelected("category"),
        orderFilterCardId: this.getSelected("card"),
        orderFilterDateFrom: this.state.orderFilterDateFrom,
        orderFilterDateTo: this.state.orderFilterDateTo,
        orderFilterOrderId: this.state.orderFilterOrderId,
        referenceNo: this.state.referenceNo,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ errorMsg: data["errorMsg"] });
          } else {
            this.setState({
              data: data["orderData"],
              dataCount: data["orderDataCounter"],
              loading: 0,
              showExportOrder: data["showExportOrder"],
              currencyTitle: data["currencyTitle"],
            });
          }
        }
      });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        activePage: 1,
      },
      function() {
        this.getUserOrder();
      }
    );
  };

  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };
  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };

  handleChangeCategory = (option) => {
    this.setState(
      (state) => {
        return {
          selectedCategoryList: option,
          // selectedCategoryId: option['id'],
          activePage: 1,
        };
      },
      function() {
        this.getCardsList();
        this.getUserOrder();
      }
    );
  };

  handleChangeCardType = (option) => {
    this.setState(
      (state) => {
        return {
          selectedCardTypeList: option,
          // selectedCardTypeId: option['cardId'],
          activePage: 1,
        };
      },
      function() {
        this.getUserOrder();
      }
    );
  };

  handleChangeStatus = (option) => {
    this.setState(
      (state) => {
        return {
          selectedStatusList: option,
          // selectedStatusId: option['id'],
          activePage: 1,
        };
      },
      function() {
        this.getUserOrder();
      }
    );
  };

  exportHandler() {
    // var selectedStatusId = this.state.selectedStatusId
    // if (selectedStatusId == '' || selectedStatusId == null || selectedStatusId == undefined) {
    //     selectedStatusId = ''
    // }

    // var selectedCategoryId = this.state.selectedCategoryId
    // if (selectedCategoryId == '' || selectedCategoryId == null || selectedCategoryId == undefined) {
    //     selectedCategoryId = ''
    // }
    // var selectedCardTypeId = this.state.selectedCardTypeId
    // if (selectedCardTypeId == '' || selectedCardTypeId == null || selectedCardTypeId == undefined) {
    //     selectedCardTypeId = ''
    // }
    var orderFilterDateFrom = this.state.orderFilterDateFrom;
    if (
      orderFilterDateFrom == "" ||
      orderFilterDateFrom == null ||
      orderFilterDateFrom == undefined
    ) {
      orderFilterDateFrom = currentDate;
    }

    var orderFilterDateTo = this.state.orderFilterDateTo;
    if (
      orderFilterDateTo == "" ||
      orderFilterDateTo == null ||
      orderFilterDateTo == undefined
    ) {
      orderFilterDateTo = currentDate;
    }

    var orderFilterOrderId = this.state.orderFilterOrderId;
    if (
      orderFilterOrderId == "" ||
      orderFilterOrderId == null ||
      orderFilterOrderId == undefined
    ) {
      orderFilterOrderId = "";
    }

    var referenceNo = this.state.referenceNo;
    if (referenceNo == "" || referenceNo == null || referenceNo == undefined) {
      referenceNo = "";
    }

    window.location =
      window.API_PERM_USER +
      "/permUser.php?type=" +
      "getUserOrder" +
      "&lang=" +
      localStorage.getItem("LANG") +
      "&userId=" +
      localStorage.getItem("USER_ID") +
      "&token=" +
      localStorage.getItem("USER_TOKEN") +
      "&page=" +
      this.state.activePage +
      "&limit=" +
      this.state.limit +
      "&orderFilterStatus=" +
      this.getSelected("status") +
      "&orderFilterCardCatg=" +
      this.getSelected("category") +
      "&orderFilterCardId=" +
      this.getSelected("card") +
      "&orderFilterDateFrom=" +
      orderFilterDateFrom +
      "&orderFilterDateTo=" +
      orderFilterDateTo +
      "&orderFilterOrderId=" +
      orderFilterOrderId +
      "&referenceNo=" +
      referenceNo +
      "&export=1";
  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getUserOrder();
    });
  };

  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };
  getSelected = (type) => {
    if (type == "card") {
      //
      return this.state.selectedCardTypeList.map((data) => data.cardId);
    }

    if (type == "category") {
      return this.state.selectedCategoryList.map((data) => data.id);
    }

    if (type == "status") {
      return this.state.selectedStatusList.map((data) => data.id);
    }
  };
  openOrderAction = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (type == "export") {
      this.printPdf_CSV(data["orderId"], 1);
    } else if (type == "csv") {
      this.printPdf_CSV(data["orderId"], 2);
    } else if (type == "sms") {
      this.sendSms(data["orderId"]);
    } else if (type == "add_problem") {
      this.setState({
        actionType: type,
        openOrderAction: true,
        orderRowData: data,
      });
    }
  };

  printPdf_CSV(id, typeExport) {
    /*
            typeExport >> 1 : pdf
                          2 : csv
        */
    window.open(
      window.API_PERM_USER +
        "/permUser.php?type=" +
        "printPDFOrCSVInvoiceToCustomer" +
        "&lang=" +
        localStorage.getItem("LANG") +
        "&userId=" +
        localStorage.getItem("USER_ID") +
        "&token=" +
        localStorage.getItem("USER_TOKEN") +
        "&orderId=" +
        id +
        "&typeExport=" +
        typeExport,
      "_blank"
    );
  }

  sendSms(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to send sms ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickSendSms(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  }

  handleClickSendSms(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "sendSMSToCustomer",
        orderId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <div className="mb-3">
                      <span className="alert-delete-icon">!</span>
                    </div>
                    <p>{data["errorMsg"]}</p>
                    <button
                      type="button"
                      className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                      onClick={onClose}
                    >
                      {Strings.Cancel}
                    </button>
                  </div>
                );
              },
            });
          }
        }
      });
  }
  AcceptOrder(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-4">
              <span className="alert-delete-icon">!</span>
            </div>

            <h5 className="mt-2"> {Strings["Order #"] + " ( " + id + " ) "}</h5>
            {/* <h5>{id}</h5> */}
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to Re-Order?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>

            {this.state.startSave == 0 ? (
              <button
                type="button"
                className="btn form-save-btn"
                onClick={() => {
                  this.handleClickReOrder(id);
                  onClose();
                }}
              >
                {Strings.Yes}
              </button>
            ) : (
              <button type="button" className="btn form-save-btn">
                <CircularProgress className="SaveCircularProgressColor" />{" "}
                {Strings.Yes}
              </button>
            )}
          </div>
        );
      },
    });
  }

  // https://dev45.hoja-crm.com/API/permUser.php?type=reOrder&userId=636&orderId=82505&qsyTest=-500
  handleClickReOrder(id) {
    this.setState({ startSave: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "reOrder",
        orderId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
            this.setState({ startSave: 0 });
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <div className="mb-3">
                      <span className="alert-Succsess-icon">✓</span>
                    </div>
                    <p className="mt-2">
                      {Strings["Re-order is successfully"]}
                    </p>

                    <button
                      type="button"
                      className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                      onClick={() => {
                        this.getUserOrder();
                        onClose();
                      }}
                    >
                      {Strings.Close}
                    </button>
                  </div>
                );
              },
            });
          } else {
            this.setState({ startSave: 0 });
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <div className="mb-3">
                      <span className="alert-delete-icon">!</span>
                    </div>
                    <p>{data["error"]}</p>
                    <button
                      type="button"
                      className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                      onClick={() => {
                        this.getUserOrder();
                        onClose();
                      }}
                    >
                      {Strings.Close}
                    </button>
                  </div>
                );
              },
            });
          }
        }
      });
  }
  displayData() {
    var status = 0;
    var orderInfo = "";
    var statusTitle = "";
    var classStyle = {
      "text-1": "gray-color",
      "text-2": "warning-color",
      "text-3": "succ-color",
      "text-4": "reject-color",
      "text-5": "addCredit-color",
      "text-11": "pos-correct-mng-color",
    };
    var classColStatus = "";
    if (this.state.loading == 0 && this.state.data.length > 0) {
      orderInfo = this.state.data.map((rowData, index) => {
        status = rowData["status"];
        statusTitle =
          localStorage.getItem("LANG") == 1
            ? rowData["statusTitle"]
            : rowData["statusTitle_en"];
        if (status !== "") {
          status = status == 3 && rowData["cardsCountDrow"] == 0 ? 5 : status;
          classColStatus = classStyle["text-" + status];
        }
        classColStatus =
          " overflow-text-length  bold-font " + classColStatus + " ";
        var classesList = [];

        if (
          this.state.cardClassName.length > 0 &&
          this.state.openClassList == rowData["orderId"]
        ) {
          classesList = this.state.cardClassName.map((rowData2, index2) => {
            return (
              <Row key={index}>
                <div
                  title={rowData2["cardClassName"]}
                  className={"orderClassWidth overflow-text-length"}
                >
                  -{" "}
                  {localStorage.getItem("LANG") == 1
                    ? rowData2["cardClassName"]
                    : rowData2["name_en"]}{" "}
                </div>
                <div>{rowData2["cardsCount"]}</div>
                {/* cardsCount */}
              </Row>
              // <div>- {rowData2['name'] }{rowData2['countCard']}</div>
            );
          });
        }

        return (
          <Row key={index} className="table-body-row">
            <Col lg={1} md={1} sm={1} xs={12} className="overflow-text-length">
              {rowData["orderId"]}
            </Col>
            <Col
              lg={localStorage.getItem("PERM_TYPE") == 3 ? 2 : 2}
              md={3}
              sm={4}
              xs={12}
              className={"white-space_pre-wrap overflow-text-length"}
            >
              {localStorage.getItem("PERM_TYPE") == 2 ||
              localStorage.getItem("PERM_TYPE") == 3
                ? rowData["orderDateZone"] + "\n" + rowData["orderTimeZone"]
                : rowData["orderDate"] + "\n" + rowData["orderTime"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["cardName"]}
            >
              {rowData["cardName"]}
            </Col>

            <Col lg={1} md={1} sm={1} xs={12} className="overflow-text-length">
              {localStorage.getItem("PERM_TYPE") != 3 ? (
                rowData["cardClassName"]
              ) : (
                <div>
                  {(rowData["status"] == 3 &&
                    rowData["amountDrowFromate"] != 0) ||
                  (rowData["status"] != 3 && rowData["haveClass"] == 1) ? (
                    this.state.openClassList == rowData["orderId"] ? (
                      this.state.loadingClass == 1 &&
                      this.state.openClassList == rowData["orderId"] ? (
                        <CircularProgress className="CircularProgressColor hover" />
                      ) : (
                        <ExpandLessIcon
                          className="pointer-cursor hover"
                          onClick={() => this.closeOpenClassList(rowData[""])}
                        />
                      )
                    ) : (
                      <ExpandMoreIcon
                        className="pointer-cursor hover"
                        onClick={() => this.openClassList(rowData["orderId"])}
                      />
                    )
                  ) : (
                    "-"
                  )}
                </div>
              )}
            </Col>

            <Col lg={1} md={1} sm={1} xs={12} className="overflow-text-length">
              {localStorage.getItem("PERM_TYPE") == 3
                ? rowData["amountTaxFormat"] > 0
                  ? rowData["amountTaxFormat"] + "" + this.state.currencyTitle
                  : "-"
                : rowData["couponRats"] != "" &&
                  rowData["couponRats"] != null &&
                  rowData["couponRats"] != undefined
                ? rowData["couponRats"]
                : "-"}
            </Col>
            <Col lg={1} md={1} sm={1} xs={12} className="overflow-text-length">
              {rowData["amountDrowFromate"]} {this.state.currencyTitle}
            </Col>
            <Col
              lg={
                localStorage.getItem("PERM_TYPE") == 3 ||
                localStorage.getItem("PERM_TYPE") == 2
                  ? 1
                  : 2
              }
              md={
                localStorage.getItem("PERM_TYPE") == 3 ||
                localStorage.getItem("PERM_TYPE") == 2
                  ? 1
                  : 2
              }
              sm={
                localStorage.getItem("PERM_TYPE") == 3 ||
                localStorage.getItem("PERM_TYPE") == 2
                  ? 1
                  : 2
              }
              xs={12}
              className="overflow-text-length"
            >
              {rowData["cardsCountDrow"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              title={statusTitle}
              className={classColStatus}
            >
              {statusTitle}
            </Col>
            <Col lg={1} md={1} sm={1} xs={12} className="overflow-text-length">
              {rowData["referenceNo"] != "" && rowData["referenceNo"] != 0
                ? rowData["referenceNo"]
                : "-"}
            </Col>
            {(localStorage.getItem("PERM_TYPE") == 3 ||
              localStorage.getItem("PERM_TYPE") == 2) && (
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={12}
                className={
                  "action-icon-view actionDropdownList actionDropdownListPadding-0 text-center"
                }
              >
                {localStorage.getItem("PERM_TYPE") == 3 ||
                localStorage.getItem("PERM_TYPE") == 2 ? (
                  // {(localStorage.getItem('PERM_TYPE') == 3 || (this.state.showExportOrder == 1 && localStorage.getItem('PERM_TYPE') == 2)) ?
                  <Dropdown
                    isOpen={
                      this.state.openHeader == rowData["orderId"] ? true : false
                    }
                    className="font-size-13 actionDropdownList"
                  >
                    <DropdownToggle
                      tag="a"
                      className="nav-link pt-0 actionDropdownList actionDropdownListPadding-0"
                      caret
                    >
                      <MoreVertIcon
                        className="menu-dots-vertical actionDropdownList"
                        onClick={(event) =>
                          this.openHeaderMenu(event, rowData["orderId"])
                        }
                      />
                    </DropdownToggle>
                    <DropdownMenu className="font-size-13  actionDropdownList">
                      <div
                        className="actionDropdownList"
                        style={{ maxHeight: "250px", overflowY: "scroll" }}
                      >
                        {localStorage.getItem("PERM_TYPE") == 3 &&
                          rowData["status"] == 3 &&
                          this.state.showExportOrder == 1 &&
                          rowData["isMaster"] == 1 &&
                          rowData["cardsCountDrow"] != 0 && (
                            <DropdownItem
                              className="actionDropdownList"
                              onClick={(event) =>
                                this.openOrderAction(event, "export", rowData)
                              }
                            >
                              {Strings["Export"]}
                            </DropdownItem>
                          )}

                        {localStorage.getItem("PERM_TYPE") == 3 &&
                          rowData["status"] == 3 &&
                          this.state.showExportOrder == 1 &&
                          rowData["isMaster"] != 1 &&
                          rowData["cardsCountDrow"] != 0 && (
                            <DropdownItem
                              className="actionDropdownList"
                              onClick={(event) =>
                                this.openOrderAction(event, "export", rowData)
                              }
                            >
                              {Strings["Export"]}
                            </DropdownItem>
                          )}
                        {this.state.showExportOrder == 1 &&
                          rowData["status"] == 3 &&
                          rowData["isMaster"] != 1 &&
                          rowData["cardsCountDrow"] != 0 && (
                            <DropdownItem
                              className="actionDropdownList"
                              onClick={(event) =>
                                this.openOrderAction(event, "csv", rowData)
                              }
                            >
                              CSV
                            </DropdownItem>
                          )}

                        {rowData["status"] == 3 &&
                          localStorage.getItem("PERM_TYPE") == 3 &&
                          rowData["invoicePassword"] == 1 && (
                            <DropdownItem
                              className="actionDropdownList"
                              onClick={(event) =>
                                this.openOrderAction(event, "sms", rowData)
                              }
                            >
                              SMS
                            </DropdownItem>
                          )}
                        {rowData["status"] == 3 &&
                          localStorage.getItem("PERM_TYPE") == 3 && (
                            <DropdownItem
                              className="actionDropdownList"
                              onClick={() => {
                                this.AcceptOrder(rowData["orderId"]);
                              }}
                            >
                              {Strings["Re-order"]}
                            </DropdownItem>
                          )}

                        {localStorage.getItem("USER_LEVEL") == 2 && (
                          <>
                            <DropdownItem
                              className="actionDropdownList"
                              onClick={(event) =>
                                this.openOrderAction(
                                  event,
                                  "add_problem",
                                  rowData
                                )
                              }
                            >
                              {Strings["Add Problem"]}
                            </DropdownItem>
                            <DropdownItem
                              className="actionDropdownList"
                              tag="a"
                              href={
                                "/settings/orderProblem/orderProblem/" +
                                rowData["orderId"]
                              }
                            >
                              {Strings["View Order Problems"]}
                            </DropdownItem>
                          </>
                        )}
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  "-"
                )}
              </Col>
            )}
            {this.state.openClassList == rowData["orderId"] &&
              this.state.cardClassName.length > 0 &&
              this.state.loadingClass != 1 && (
                <Col md={12} className="pt-3">
                  <div>{Strings["Classes"]} :</div>
                  {classesList}
                </Col>
              )}
          </Row>
        );
      });
    }
    return orderInfo;
  }

  // https://dev45.hoja-crm.com/API/permUser.php?type=getClassInfoToOrder&userId=636&orderId=82553&qsyTest=-500
  openClassList = (id) => {
    this.setState({ loadingClass: 1, openClassList: id });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getClassInfoToOrder",
        userId: localStorage.getItem("USER_ID"),
        lang: localStorage.getItem("LANG"),
        token: localStorage.getItem("USER_TOKEN"),
        orderId: id,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          // console.log('===============================');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              cardClassName: data["cardClassName"],
              openClassList: id,
              loadingClass: 0,
            });
          }
        }
      });

    // this.setState({openClassList: id })
  };

  closeOpenClassList = (id) => {
    this.setState({
      openClassList: id,
    });
  };
  handleCloseSideBar = () => {
    this.setState({ openOrderAction: false, orderRowData: [] });
  };
  render() {
    return (
      <div>
        {/* ///FiliterView///// */}
        <Row className="page-filter-view">
          <Col lg={5} md={7}>
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
            >
              <Row className="filter-form">
                <Col md={10} lg={10} sm={10} xs={10}>
                  <div className="member-searchable-list mb-2">
                    <Picky
                      value={this.state.selectedCategoryList}
                      options={this.state.categoryList}
                      onChange={this.handleChangeCategory}
                      open={false}
                      valueKey="id"
                      labelKey="name"
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["CategoryMain"]}
                      filterPlaceholder={Strings["Select Category"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>
                </Col>
                <Col md={2} lg={2} sm={2} xs={2} className="mb-2">
                  <Button
                    variant="contained"
                    className="refresh-action-btn"
                    onClick={this.handleRefresh}
                    title={Strings["Refresh"]}
                  >
                    <i className="flaticon-refresh reset-filter-btn"></i>
                  </Button>
                </Col>
                <Col md={6} lg={6} sm={6} xs={12}>
                  <div className="member-searchable-list mb-2">
                    <Picky
                      value={this.state.selectedCardTypeList}
                      options={this.state.cardTypeList}
                      onChange={this.handleChangeCardType}
                      open={false}
                      valueKey="cardId"
                      labelKey="name"
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["Card Type"]}
                      filterPlaceholder={Strings["Select Card Type"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>
                </Col>
                <Col md={6} lg={6} sm={6} xs={12}>
                  <div className="member-searchable-list mb-2">
                    <Picky
                      value={this.state.selectedStatusList}
                      options={this.state.statusList}
                      onChange={this.handleChangeStatus}
                      open={false}
                      valueKey="id"
                      labelKey="name"
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["Status"]}
                      filterPlaceholder={Strings["Select Status"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>
                </Col>
                <Col md={6} lg={6} sm={6} xs={12}>
                  <AvField
                    className={"margin-Bottom-Responsive-8"}
                    name="orderFilterOrderId"
                    type="number"
                    placeholder={Strings["Order Number"]}
                    value={this.state.orderFilterOrderId}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={6} lg={6} sm={6} xs={12}>
                  <AvField
                    name="referenceNo"
                    type="text"
                    placeholder={Strings["Reference #"]}
                    value={this.state.referenceNo}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={6} lg={6} sm={6} xs={12}>
                  <AvField
                    name="orderFilterDateFrom"
                    type="date"
                    label={Strings["From Date"]}
                    value={this.state.orderFilterDateFrom}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={6} lg={6} sm={6} xs={12}>
                  <AvField
                    name="orderFilterDateTo"
                    type="date"
                    label={Strings["To Date"]}
                    value={this.state.orderFilterDateTo}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
            </AvForm>
          </Col>
          <Col lg={7} md={5} className="text-right">
            {(localStorage.getItem("PERM_TYPE") == 1 ||
              localStorage.getItem("PERM_TYPE") == 3 ||
              localStorage.getItem("PERM_TYPE") == 2) && (
              <div>
                <Col lg={12} md={12} className="text-right">
                  <button
                    className="btn page-action-btn ml-1"
                    onClick={(event) => this.exportHandler(event)}
                  >
                    {Strings["Export"]}
                  </button>
                </Col>
                <Col lg={12} md={12} className="text-right">
                  <div>
                    <div className="task-square-view mr-1">
                      <div className="task-square-div">
                        {" "}
                        <div className="task-square-1"></div>{" "}
                        <p className="mb-0 mr-1"> {Strings["Not Received"]} </p>{" "}
                      </div>
                      <div className="task-square-div">
                        {" "}
                        <div className="task-square-2"></div>{" "}
                        <p className="mb-0 mr-1"> {Strings["In Proccess"]} </p>{" "}
                      </div>
                      <div className="task-square-div">
                        {" "}
                        <div className="status-square-5"></div>{" "}
                        <p className="mb-0 mr-1">
                          {" "}
                          {Strings["Cards not available"]}{" "}
                        </p>{" "}
                      </div>
                      <div className="task-square-div ">
                        {" "}
                        <div className="task-square-3"></div>{" "}
                        <p className="mb-0 mr-1"> {Strings["Complete"]}</p>{" "}
                      </div>
                      <div className="task-square-div ">
                        <div className="finDistReport-square-11 "></div>{" "}
                        <p className="mb-0 ">
                          {" "}
                          {Strings["Not Completed Order"]}{" "}
                        </p>
                      </div>
                      <div className="task-square-div mb-0">
                        {" "}
                        <div className="task-square-4"></div>{" "}
                        <p className="mb-0 mr-1"> {Strings["Rejected"]}</p>{" "}
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            )}
          </Col>
        </Row>

        {this.state.loading == 0 && this.state.errorMsg != "" && (
          <Row>
            <div className="inv-cards-view pb-5 width-100">
              <div
                className="text-center"
                style={{ marginTop: "15%", marginBottom: "15%" }}
              >
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                  )}
                  className="img-fluid no-data-img"
                />
                <div className="no-data-text">{this.state.errorMsg}</div>
              </div>
            </div>
          </Row>
        )}

        {this.state.loading == 0 && this.state.data.length <= 0 && (
          <Row>
            <div className="inv-cards-view pb-5 width-100">
              <div
                className="text-center"
                style={{ marginTop: "15%", marginBottom: "15%" }}
              >
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                  )}
                  className="img-fluid no-data-img"
                />
                <div className="no-data-text">
                  {Strings["There is no data to display"]}
                </div>
              </div>
            </div>
          </Row>
        )}

        {this.state.loading == 1 && (
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}

        {this.state.loading == 0 && this.state.data.length > 0 && (
          <Row>
            <div className="inv-cards-view width-100">
              <Row className="table-header-row">
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Order #"]}
                >
                  {Strings["Order #"]}
                </Col>
                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Date"]}
                >
                  {Strings["Date"]}
                </Col>
                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Card Type"]}
                >
                  {Strings["Card Type"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Denomination"]}
                >
                  {" "}
                  {Strings["Denomination"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={
                    localStorage.getItem("PERM_TYPE") == 3
                      ? Strings["Tax Value"]
                      : Strings["Discount rate"]
                  }
                >
                  {localStorage.getItem("PERM_TYPE") == 3
                    ? Strings["Tax Value"]
                    : Strings["Discount rate"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Value"]}
                >
                  {Strings["Value"]}
                </Col>
                <Col
                  lg={
                    localStorage.getItem("PERM_TYPE") == 3 ||
                    localStorage.getItem("PERM_TYPE") == 2
                      ? 1
                      : 2
                  }
                  md={
                    localStorage.getItem("PERM_TYPE") == 3 ||
                    localStorage.getItem("PERM_TYPE") == 2
                      ? 1
                      : 2
                  }
                  sm={
                    localStorage.getItem("PERM_TYPE") == 3 ||
                    localStorage.getItem("PERM_TYPE") == 2
                      ? 1
                      : 2
                  }
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Cards Count"]}
                >
                  {Strings["Cards Count"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Status"]}
                >
                  {Strings["Status"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Reference #"]}
                >
                  {Strings["Reference #"]}
                </Col>
                {(localStorage.getItem("PERM_TYPE") == 3 ||
                  localStorage.getItem("PERM_TYPE") == 2) && (
                  <Col
                    lg={1}
                    md={1}
                    sm={1}
                    xs={12}
                    className="overflow-text-length text-center"
                    title={Strings["Action"]}
                  >
                    {" "}
                    {Strings["Action"]}
                  </Col>
                )}
              </Row>
              {this.displayData()}

              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Col md={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              )}
            </div>
          </Row>
        )}
        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openOrderAction}
          className={"sidebar-modal-view"}
        >
          {this.state.actionType == "add_problem" && (
            <AddProblem
              orderRowData={this.state.orderRowData}
              handleCloseSideBar={this.handleCloseSideBar}
            />
          )}
        </Drawer>
      </div>
    );
  }
}
export default Orders;

import React, { Component } from "react";
import {
  Row,
  Col,
  CustomInput,
  Progress,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { toAbsoluteUrl } from "../../../../_metronic";
import Pagination from "react-js-pagination";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, Drawer } from "@material-ui/core";
import { translations } from "../../../../translate.js";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";

var today = new Date();
var currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class AccountStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      limit: 25,
      activePage: 1,
      dateFrom: currentDate,
      dateTo: currentDate,
      total: 0,
      additionTotal: 0,
      withdrawalTotal: 0,
      orderTotal: 0,
      dataCount: 0,
      posId: this.props.posData["userId"],
      posName: this.props.posData["fname"] + " " + this.props.posData["lname"],
      pos_mgr_emp: this.props.posData["accountType"] == 8 ? 1 : 0,
      data: [],
      typesOps: 0,
      note1: "",
      note2: "",
      selected_status_operation_list: [],
      errorMsg: "",
    };
  }
  handleRefresh = () => {
    // clear all filter data
    this.setState(
      {
        loading: 0,
        activePage: 1,
        dateFrom: currentDate,
        dateTo: currentDate,
        total: 0,
        additionTotal: 0,
        withdrawalTotal: 0,
        orderTotal: 0,
        dataCount: 0,
        note1: "",
        note2: "",
        data: [],
        typesOps: 0,
        selected_status_operation_list: [],
        errorMsg: "",
      },
      function() {
        this.getAccountStatement();
      }
    );
  };
  componentDidMount() {
    this.getAccountStatement();
    this.get_status_operation_list();
  }

  getAccountStatement() {
    this.setState({ loading: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "pos_account_statement",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        start: this.state.activePage,
        limit: this.state.limit,
        posId: this.state.posId,
        orderFilterDateFrom: this.state.dateFrom,
        orderFilterDateTo: this.state.dateTo,
        posTransFilterNote1: this.state.note1,
        posTransFilterNote2: this.state.note2,
        status: this.state.typesOps,
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] != "") {
            this.setState({ loading: 0, errorMsg: data["errorMsg"] });
          } else {
            this.setState({
              data: data["data"],
              dataCount: data["count"],
              loading: 0,
              total: data["total"],
              additionTotal: data["additionTotal"],
              withdrawalTotal: data["withdrawalTotal"],
              orderTotal: data["orderTotal"],
            });
          }
        }
      });
  }
  exportHandler() {
    if (this.state.posId != 0) {
      window.location =
        window.API_PERM_USER +
        "/permUser.php?type=" +
        "pos_account_statement" +
        "&lang=" +
        localStorage.getItem("LANG") +
        "&userId=" +
        localStorage.getItem("USER_ID") +
        "&token=" +
        localStorage.getItem("USER_TOKEN") +
        "&orderFilterDateFrom=" +
        this.state.dateFrom +
        "&orderFilterDateTo=" +
        this.state.dateTo +
        "&status=" +
        this.state.typesOps +
        "&posId=" +
        this.state.posId +
        "&distId=" +
        (this.props.distId ? this.props.distId : "") +
        "&posTransFilterNote1=" +
        this.state.note1 +
        "&posTransFilterNote2=" +
        this.state.note2 +
        "&export=1";
    }
  }
  get_status_operation_list() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "get_status_operation_list",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              status_operation_list: data["data"],
            });
          }
        }
      });
  }
  handleChangeSelect = (type, option) => {
    this.setState(
      (state) => {
        return {
          [type]: option,
          activePage: 1,
        };
      },
      function() {
        if (type == "selected_status_operation_list") {
          this.setState({ typesOps: option.typeId }, function() {
            this.getAccountStatement();
          });
        }
      }
    );
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getAccountStatement();
    });
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        activePage: 1,
      },
      function() {
        this.getAccountStatement();
      }
    );
  };
  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };
  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };

  displayData() {
    var list = "";
    var note1 = "";
    var note2 = "";
    if (this.state.data.length > 0) {
      list = this.state.data.map((rowData, index) => {
        note1 = rowData["note1"] == "" ? "-" : rowData["note1"];
        note2 = rowData["note2"] == "" ? "-" : rowData["note2"];
        return (
          <tr key={index}>
            <td className="text-center ">{rowData["date"]}</td>
            <td className="text-center ">
              {rowData["status"] == "2" ? rowData["valueFormat"] : "-"}
            </td>
            <td className="text-center ">
              {rowData["status"] == "1" ? rowData["valueFormat"] : "-"}
            </td>
            <td className="text-center ">{note1}</td>
            <td className="text-center ">{note2}</td>
          </tr>
        );
      });
    }
    return list;
  }

  render() {
    // let dataMain = [];
    // let dataInfo = [];
    // if (this.state.loading == 0 && this.state.data.length > 0) {
    //     this.state.data.map((rowData, index) => {
    //         dataMain.push(
    //             <div className={rowData.id + "class" + " row flex-row flex-nowrap"} style={{ height: "50px" }} key={rowData.id}>
    //                 <div className={this.state['activeIndex'] == rowData.id ? 'col-12 col-sm-12 col-md-12 col-lg-12 table-body-row  main-color-1 hover-scroll-table-row  ' : 'col-12 col-sm-12 col-md-12 col-lg-12 table-body-row main-color-1'} onMouseOver={this.addRowHover.bind(this, rowData.id)} onMouseOut={this.removeRowHover.bind(this, rowData.id)}>
    //                     <div className="overflow-text-length font-w-400">{rowData['id']}</div>
    //                 </div>
    //             </div>
    //         )

    //         dataInfo.push(
    //             <div className={rowData.id + "class" + " row flex-row flex-nowrap"} style={{ height: "50px" }} key={rowData.id}>
    //                 <div className={'col-3 col-sm-3 col-md-3 col-lg-3 table-body-row ' + (this.state['activeIndex'] == rowData.id && ' hover-scroll-table-row  ')} onMouseOver={this.addRowHover.bind(this, rowData.id)} onMouseOut={this.removeRowHover.bind(this, rowData.id)}>
    //                     <div className="overflow-text-length">{rowData['date']}</div>
    //                 </div>
    //                 <div className={'col-3 col-sm-3 col-md-3 col-lg-3 table-body-row ' + (this.state['activeIndex'] == rowData.id && ' hover-scroll-table-row  ')} onMouseOver={this.addRowHover.bind(this, rowData.id)} onMouseOut={this.removeRowHover.bind(this, rowData.id)}>
    //                     <div className="overflow-text-length">{rowData['operationDate']}</div>
    //                 </div>
    //                 <div className={'col-3 col-sm-3 col-md-3 col-lg-3 table-body-row ' + (this.state['activeIndex'] == rowData.id && ' hover-scroll-table-row  ')} onMouseOver={this.addRowHover.bind(this, rowData.id)} onMouseOut={this.removeRowHover.bind(this, rowData.id)}>
    //                     <div className="overflow-text-length">{rowData['note1']}</div>
    //                 </div>
    //                 <div className={'col-3 col-sm-3 col-md-3 col-lg-3 table-body-row ' + (this.state['activeIndex'] == rowData.id && ' hover-scroll-table-row  ')} onMouseOver={this.addRowHover.bind(this, rowData.id)} onMouseOut={this.removeRowHover.bind(this, rowData.id)}>
    //                     <div className="overflow-text-length">{rowData['note2']}</div>
    //                 </div>
    //                 <div className={'col-3 col-sm-3 col-md-3 col-lg-3 table-body-row ' + (this.state['activeIndex'] == rowData.id && ' hover-scroll-table-row  ')} onMouseOver={this.addRowHover.bind(this, rowData.id)} onMouseOut={this.removeRowHover.bind(this, rowData.id)}>
    //                     <div className="overflow-text-length">{rowData['type']}</div>
    //                 </div>
    //                 <div className={'col-3 col-sm-3 col-md-3 col-lg-3 table-body-row ' + (this.state['activeIndex'] == rowData.id && ' hover-scroll-table-row  ')} onMouseOver={this.addRowHover.bind(this, rowData.id)} onMouseOut={this.removeRowHover.bind(this, rowData.id)}>
    //                     <div className="overflow-text-length">{rowData['amount']}</div>
    //                 </div>
    //             </div>
    //         )
    //     })
    // }
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col xs={10} lg={10} md={10} sm={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Account Statement"]} ({this.state.posName})
              </div>
            </Col>
            <Col md={2} xs={2} lg={2} sm={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view sidebar-body-view-supplier-transaction">
          <Row className="page-filter-view">
            <Col md={6} xs={12} lg={6} sm={12}>
              <AvForm
                onValidSubmit={this.handleValidSubmit}
                ref={(c) => (this.form = c)}
              >
                <Row className="filter-form">
                  <Col xs={12} lg={10} md={10} sm={12}>
                    <div className="member-searchable-list mb-2 ">
                      <Picky
                        value={this.state.selected_status_operation_list}
                        options={this.state.status_operation_list}
                        onChange={this.handleChangeSelect.bind(
                          this,
                          "selected_status_operation_list"
                        )}
                        open={false}
                        valueKey="typeId"
                        labelKey={
                          localStorage.getItem("LANG") == 1
                            ? "typeAR"
                            : "typeEN"
                        }
                        multiple={false}
                        includeSelectAll={true}
                        includeFilter={true}
                        dropdownHeight={200}
                        placeholder={Strings["Operation Type"]}
                        filterPlaceholder={Strings["Select Operation Type"]}
                      />
                    </div>
                  </Col>

                  <Col md={2} xs={12} lg={2} sm={12} className="  mb-1">
                    <Button
                      variant="contained"
                      className="refresh-action-btn mb-2 mb-md-0"
                      onClick={this.handleRefresh}
                      title={Strings["Refresh"]}
                    >
                      <i className="flaticon-refresh reset-filter-btn"></i>
                    </Button>
                  </Col>
                  <Col md={6} xs={12} lg={6} sm={12}>
                    <AvField
                      name="note1"
                      type="text"
                      placeholder={Strings["Note"] + " 1"}
                      value={this.state.note1}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col md={6} xs={12} lg={6} sm={12}>
                    <AvField
                      name="note2"
                      type="text"
                      placeholder={Strings["Note"] + " 2"}
                      value={this.state.note2}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col md={6} xs={12} lg={6} sm={12} className="mb-1">
                    <AvField
                      name="dateFrom"
                      type="date"
                      label={Strings["From Date"]}
                      value={this.state.dateFrom}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col md={6} xs={12} lg={6} sm={12} className="mb-1">
                    <AvField
                      name="dateTo"
                      type="date"
                      label={Strings["To Date"]}
                      value={this.state.dateTo}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </Row>
              </AvForm>
            </Col>
            <Col md={6} xs={12} lg={6} sm={12} className="text-right">
              {(localStorage.getItem("PERM_TYPE") == 5 ||
                this.props.permType == 5 ||
                localStorage.getItem("POS_MGR_EMP") == 1 ||
                localStorage.getItem("ACC_EMP") == 1) && (
                <button
                  className="btn page-action-btn ml-1"
                  onClick={(event) => this.exportHandler(event)}
                >
                  {Strings["Export"]}
                </button>
              )}
              {(localStorage.getItem("PERM_TYPE") == 5 ||
                this.props.permType == 5 ||
                localStorage.getItem("POS_MGR_EMP") == 1 ||
                localStorage.getItem("ACC_EMP") == 1) &&
                this.state.loading == 0 &&
                this.state.data.length > 0 && (
                  <Row className="mr-1 ml-1 ">
                    <Col md={4} lg={4} sm={12} xs={12}></Col>
                    <Col
                      md={8}
                      lg={8}
                      sm={12}
                      xs={12}
                      className="mt-3 algin-total-box financail-operation-report-summary-box-view "
                    >
                      <div>
                        <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                          <Col
                            md={8}
                            xs={12}
                            lg={8}
                            sm={12}
                            className="main-color-1 font-w-400 textRight"
                          >
                            {Strings["Number Of Operations"]}
                          </Col>
                          <Col
                            md={4}
                            xs={12}
                            lg={4}
                            sm={12}
                            className="text-center"
                          >
                            {this.state.dataCount}
                          </Col>
                        </Row>
                        <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                          <Col
                            md={8}
                            xs={12}
                            lg={8}
                            sm={12}
                            className="main-color-1 font-w-400"
                          >
                            {Strings["Total amounts that added"]}
                          </Col>
                          <Col
                            md={4}
                            xs={12}
                            lg={4}
                            sm={12}
                            className="text-center"
                          >
                            {this.state.additionTotal}
                          </Col>
                        </Row>
                        <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                          <Col
                            md={8}
                            xs={12}
                            lg={8}
                            sm={12}
                            className="main-color-1 font-w-400"
                          >
                            {Strings["Total amount that pulled"]}
                          </Col>
                          <Col
                            md={4}
                            xs={12}
                            lg={4}
                            sm={12}
                            className="text-center"
                          >
                            {this.state.withdrawalTotal}
                          </Col>
                        </Row>
                        {this.state.pos_mgr_emp == 0 && (
                          <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                            <Col
                              md={8}
                              xs={12}
                              lg={8}
                              sm={12}
                              className="main-color-1 font-w-400"
                            >
                              {Strings["Orders total amount"]}
                            </Col>
                            <Col
                              md={4}
                              xs={12}
                              lg={4}
                              sm={12}
                              className="text-center"
                            >
                              {this.state.orderTotal}
                            </Col>
                          </Row>
                        )}
                        <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                          <Col
                            md={8}
                            xs={12}
                            lg={8}
                            sm={12}
                            className="main-color-1 font-w-400"
                          >
                            {Strings["Total balance"]}
                          </Col>
                          <Col
                            md={4}
                            xs={12}
                            lg={4}
                            sm={12}
                            className="text-center"
                          >
                            {this.state.total}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                )}
            </Col>
          </Row>
          {this.state.loading == 1 && (
            <Row>
              <Col
                md={12}
                xs={12}
                lg={12}
                sm={12}
                style={{ marginTop: "15%" }}
                className="text-center"
              >
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}

          {this.state.loading == 0 && this.state.errorMsg != "" && (
            <div className="text-center" style={{ marginTop: "15%" }}>
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">{this.state.errorMsg}</div>
            </div>
          )}

          {this.state.loading == 0 &&
            this.state.data.length <= 0 &&
            this.state.errorMsg == "" && (
              <div className="text-center" style={{ marginTop: "15%" }}>
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                  )}
                  className="img-fluid no-data-img"
                />
                <div className="no-data-text">
                  {Strings["There is no data to display"]}
                </div>
              </div>
            )}

          {this.state.loading == 0 && this.state.data.length > 0 && (
            <Row>
              <div className="inv-cards-view width-100">
                <Row>
                  <table className="mt-4">
                    <tr>
                      <th rowSpan={2} className="main-table-header">
                        {Strings["Date"]}
                      </th>
                      <th colSpan={2}>{Strings["Balance Type"]}</th>
                      <th rowSpan={2} className="main-table-header">
                        {Strings["Note"]} 1
                      </th>
                      <th rowSpan={2} className="main-table-header">
                        {Strings["Note"]} 2
                      </th>
                    </tr>
                    <tr>
                      <th className="main-table-header">{Strings["Get"]}</th>
                      <th className="main-table-header">{Strings["Add"]}</th>
                    </tr>
                    {this.displayData()}
                  </table>
                  {this.state.loading == 0 && this.state.data.length > 0 && (
                    <Col md={12} xs={12} lg={12} sm={12}>
                      <Row className="pagenation-view mt-3 mb-4">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.limit}
                          totalItemsCount={this.state.dataCount}
                          pageRangeDisplayed={4}
                          onChange={this.handlePageChange}
                          prevPageText={
                            <i
                              className={
                                localStorage.getItem("LANG") == 1
                                  ? "flaticon2-next"
                                  : "flaticon2-back"
                              }
                            ></i>
                          }
                          nextPageText={
                            <i
                              className={
                                localStorage.getItem("LANG") == 1
                                  ? "flaticon2-back"
                                  : "flaticon2-next"
                              }
                            ></i>
                          }
                          hideFirstLastPages
                          innerClass={
                            "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                          }
                          itemClass={"page-item"}
                          linkClass={"page-link"}
                        />
                      </Row>
                    </Col>
                  )}
                </Row>
              </div>
            </Row>
          )}
        </div>
      </div>
    );
  }
}
export default AccountStatement;

import React, { useState, useEffect } from "react";
import { translations } from '../../../../translate.js';
import { useDispatch, useSelector } from 'react-redux';

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;
function UserBalance(props) {
    const balance = useSelector(state => state.balance.userBalance)
    const dispatch = useDispatch()
    useEffect(() => {
        // console.log("update submit resuilt user Balance ^^ ");
        getUserBalance()
    }, [])
    const getUserBalance = () => {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getUserBalance',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    localStorage.setItem('USER_BALANCE_FROM', 'redux');
                    // console.log("call distpatch");
                    dispatch({
                        type: 'UPDATEBALANCE',
                        payload: data?.balanceCurr ? data?.balanceCurr : '0'
                    })
                }
            }
        })
    }
    return (
        <div className='text-center mb-3 left-menu-balance-view'>
            <div className='main-color-1 font-w-500'>{Strings['Balance']}</div>
            {/* <div>{balance}</div> */}
            <div>{(balance != '' && balance != null && balance != undefined && localStorage.getItem("USER_BALANCE_FROM") == "redux") ? balance : localStorage.getItem('USER_BALANCE')}</div>
        </div>
    );
};

export default UserBalance;
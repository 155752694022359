import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import KTToggle from "../../_assets/js/toggle";
import { toAbsoluteUrl } from "../../utils/utils";
import AddOrGetAmount from "../../../app/pages/pos/forms/addOrGetAmount";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { Drawer } from "@material-ui/core";

class HeaderMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userBalance: "",
      totalLoanFormat: "",
      openleftModal: false,
      openModal: false,
    };
  }
  toggleButtonRef = React.createRef();
  headerMobileCssClasses = "";
  layoutConfig = this.props.layoutConfig;
  componentDidMount() {
    new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
  }
  handleCloseSideBar2 = () => {
    this.setState({
      openModal: false,
    });
  };
  openSidebarModal2 = (event, type, data, viewMode) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({
      propsData: data,
      actionType: type,
      viewMode: viewMode,
      openModal: true,
    });
  };
  handleFormAction = (type) => {
    this.setState(
      {
        openModal: false,
      },
      function() {
        if (type == "edit") {
          // this.getAccountData()
        }
      }
    );
  };
  render() {
    const {
      asideDisplay,
      headerMobileCssClasses,
      headerMobileAttributes,
    } = this.props;
    return (
      <div
        id="kt_header_mobile"
        className={`kt-header-mobile ${headerMobileCssClasses}`}
        {...headerMobileAttributes}
      >
        <div className="kt-header-mobile__logo">
          <Link to="/">
            <img
              alt="logo"
              src={toAbsoluteUrl(
                "/media/ezpay/EZPAY_LOGO" + window.MAIN_IMAGE + ".png"
              )}
              className="rep-header-logo"
            />
          </Link>
        </div>
        {(localStorage.getItem("PERM_TYPE") == 5 ||
          localStorage.getItem("PERM_TYPE") == 1) && (
          <div
            onClick={(event) => this.openSidebarModal2(event, "addBalance", [])}
            type="button"
            className="d-flex align-items-center"
          >
            <AccountBalanceWalletIcon style={{ width: 30, height: 30 }} />
          </div>
        )}
        <div className="kt-header-mobile__toolbar">
          {asideDisplay && (
            <button
              className="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left resp-menu-icon"
              id="kt_aside_mobile_toggler"
            >
              <span />
            </button>
          )}

          <button
            className="kt-header-mobile__toggler ml-2 mr-4 resp-menu-icon  "
            id="kt_header_mobile_toggler"
          >
            {/* <span /> */}
            <i className="flaticon2-line" />
          </button>

          <button
            ref={this.toggleButtonRef}
            className="kt-header-mobile__topbar-toggler resp-menu-icon"
            id="kt_header_mobile_topbar_toggler"
          >
            <i className="flaticon-more" />
          </button>
        </div>
        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openModal}
          className={"sidebar-modal-large-view"}
        >
          {this.state.actionType == "addBalance" && (
            <AddOrGetAmount
              handleCloseSideBar={this.handleCloseSideBar2}
              handleFormAction={this.handleFormAction}
              mainAccount="1"
            />
          )}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  headerMobileCssClasses: builder.selectors.getClasses(store, {
    path: "header_mobile",
    toString: true,
  }),
  headerMobileAttributes: builder.selectors.getAttributes(store, {
    path: "header_mobile",
  }),
  asideDisplay: objectPath.get(
    store.builder.layoutConfig,
    "aside.self.display"
  ),
  toggleOptions: {
    target: "body",
    targetState: "kt-header__topbar--mobile-on",
    togglerState: "kt-header-mobile__toolbar-topbar-toggler--active",
  },
});

export default connect(mapStateToProps)(HeaderMobile);

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { confirmAlert } from "react-confirm-alert";
import { CircularProgress } from "@material-ui/core";

import "react-confirm-alert/src/react-confirm-alert.css";
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from "../../../../translate.js";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class AddBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardClassType: [],
      purchaseOrders: [],
      sampleFile: "",
      loading: 1,
      cardExsits: 0,
      cardLength: 0,
      exsitsCardCode: [],
      lengthCardCode: [],
      startSave: 0,
      distId: this.props.distId ? this.props.distId : "",
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    this.getCardClassTypes();
    this.getSampleFile();
  }

  getSampleFile() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getSampleFile",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        cardClassId: this.props.cardsCalssData.id,
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              sampleFile: data["data"],
            });
          }
        }
      });
  }

  getCardClassTypes() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCardClassTypes",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        getType: 1,
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              cardClassType: data,
              loading: 0,
            });
          }
        }
      });
  }

  getPurchaseOrders(supplierId) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getPurchaseOrders",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        supplierId: supplierId,
        distId: this.state.distId,

        // 'supplierId': 16, // for test
        // 'cardClassId': 1621, // for test
        cardClassId: this.props.cardsCalssData.id,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              purchaseOrders: data["data"],
            });
          }
        }
      });
  }

  setUpSelectOptions = (data, type) => {
    if (type == "suppliers") {
      return (
        <option value={data.id} key={data.id}>
          {data.name}
        </option>
      );
    } else if (type == "purchaseOrders") {
      return (
        <option value={data.id} key={data.id}>
          {data.qtyLeft} / {data.creation_date} [{data.cardOrderId}]
        </option>
      );
    }
  };

  setUpLiItem = (data) => {
    if (data != "" && data != null && data != undefined) {
      return <li key={data}>{data}</li>;
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (name == "suppliers") {
      this.getPurchaseOrders(value);
    }

    this.setState({
      [name]: value,
      submitError: "",
    });
  };

  handleImgChange = (event) => {
    var fileReader = new FileReader();
    const scope = this;

    // this.setState({ displayErrorHeaderMsg: false });
    // this.setState({ progressUploadHeader: 10 });
    // console.log('event.target.files[0].type');
    // console.log(event.target.files[0].type);
    // console.log(event.target.files[0]['name']);

    //   if Excel version ==2003 ->event.target.files[0].type.includes("application/vnd.ms-excel") //.xls
    //   but
    //   if Excel version >=2007 ->event.target.files[0].type.includes
    // ("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")// /.xlsx

    if (
      event.target.files[0].type.includes(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) ||
      event.target.files[0].type.includes("application/vnd.ms-excel")
    ) {
      fileReader.readAsBinaryString(event.target.files[0]);
      fileReader.onprogress = function(data) {
        if (data.lengthComputable) {
          var progress = parseInt((data.loaded / data.total) * 100, 10);
          scope.setState({ progressUploadHeader: progress });
        }
      };
    } else {
      this.setState({ displayErrorHeaderMsg: true });
    }

    this.setState({
      image: event.target.files[0]["name"],
    });
  };

  remove = (event) => {
    event.target.value = null;
  };

  handleValidSubmit(event) {
    this.setState({ startSave: 1 });

    event.preventDefault();

    var data = new FormData();
    var cardCodeFile = document.querySelector("input[name=cardCodeFile]")
      .files[0];
    // console.log(cardCodeFile);
    data.append("data", cardCodeFile);
    data.append("type", "addCardInventory");
    data.append("userId", localStorage.getItem("USER_ID"));
    data.append("token", localStorage.getItem("USER_TOKEN"));
    data.append("lang", localStorage.getItem("LANG"));
    data.append("distId", this.state.distId);

    // data.append("cardClassId", 1621);
    data.append("cardClassId", this.props.cardsCalssData.id);
    data.append("purchaseOrder", this.state.purchaseOrder);
    fetch(window.API_PERM_USER, {
      // mode: 'no-cors',
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        // console.log('===============================');

        // console.log(response);
        if (!response.ok) {
        }
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('===============================');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
            this.setState({ startSave: 0 });
            this.props.recallGetData();
            this.props.handleCloseSideBar();
          } else {
            this.props.recallGetData();
            var lengthCardCode = [];
            var exsitsCardCode = [];
            if (
              data["cardLength"] == 1 &&
              data["lengthCardCode"] != null &&
              data["lengthCardCode"] != undefined &&
              data["lengthCardCode"] != ""
            ) {
              lengthCardCode = data["lengthCardCode"].split("-");
            }

            if (
              data["cardExsits"] == 1 &&
              data["exsitsCardCode"] != null &&
              data["exsitsCardCode"] != undefined &&
              data["exsitsCardCode"] != ""
            ) {
              exsitsCardCode = data["exsitsCardCode"].split("-");
            }

            this.setState(
              {
                submitError: data["errorMsg"],
                cardExsits: data["cardExsits"],
                cardLength: data["cardLength"],
                lengthCardCode: lengthCardCode,
                exsitsCardCode: exsitsCardCode,
                startSave: 0,
              },
              function() {
                // console.log(this.state);
              }
            );
          }
        }
      });
  }

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Add Cards"]}(
                {localStorage.getItem("LANG") == 1
                  ? this.props.cardsCalssData.name
                  : this.props.cardsCalssData.name_en}
                )
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          {this.state.loading == 1 && (
            <Row>
              <Col md={12} style={{ marginTop: "15%" }} className="text-center">
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}
          {this.state.loading == 0 && (
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
            >
              <AvField
                type="select"
                label={Strings["Suppliers"] + " *"}
                name="suppliers"
                value={this.state.suppliers}
                onChange={this.handleInputChange}
                className="p-1 pl-3"
                errorMessage={Strings["This field is required"]}
              >
                <option
                  style={{ display: "none" }}
                  value=""
                  selected="selected"
                >
                  {Strings["Select supplier"]}
                </option>
                {this.state.cardClassType.map((data) =>
                  this.setUpSelectOptions(data, "suppliers")
                )}
              </AvField>

              <AvField
                type="select"
                label={Strings["Purchase Orders"] + " *"}
                name="purchaseOrder"
                value={this.state.purchaseOrder}
                onChange={this.handleInputChange}
                className="p-1 pl-3"
                errorMessage={Strings["This field is required"]}
              >
                <option
                  style={{ display: "none" }}
                  value=""
                  selected="selected"
                >
                  {Strings["Select purchase order"]}
                </option>
                {this.state.purchaseOrders.map((data) =>
                  this.setUpSelectOptions(data, "purchaseOrders")
                )}
              </AvField>

              <div className="mt-2 mb-2">
                <label>{Strings["Upload Excel File"]}</label>
                <span className={"fileInput-span col-md-12"}>
                  <PublishIcon />{" "}
                  <span className="pointer-cursor pt-3">
                    {Strings["Upload"]}
                  </span>
                  <input
                    onClick={(event) => this.remove(event)}
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    className="fileInput-width fileInput-opacity"
                    onChange={(e) => this.handleImgChange(e)}
                    name="cardCodeFile"
                  ></input>
                </span>
                {this.state.progressUploadHeader != undefined &&
                  this.state.progressUploadHeader > 0 && (
                    <Progress
                      striped
                      value={this.state.progressUploadHeader}
                      className="mb-1 mt-1 progress-bg-color"
                    >
                      {this.state.progressUploadHeader} %
                    </Progress>
                  )}
                {this.state.displayErrorHeaderMsg == true && (
                  <div className="invalid-feedback d-block mb-1">
                    {Strings["Upload Excel File only"]}
                  </div>
                )}
              </div>

              {this.state.sampleFile != "" &&
                this.state.sampleFile != undefined &&
                this.state.sampleFile != null && (
                  <Row>
                    <Col lg={2} md={2} sm={6} xs={12}>
                      {Strings["Example"]} :{" "}
                    </Col>
                    <Col lg={10} md={10} sm={6} xs={12}>
                      <a
                        href={this.state.sampleFile}
                        download
                        className=""
                        target="_blank"
                      >
                        {Strings["Download Example"]}
                      </a>
                    </Col>
                  </Row>
                )}

              <div className="text-right mt-4">
                {this.state.startSave == 0 ? (
                  <button type="submit" className="btn form-save-btn mr-1 ml-1">
                    {" "}
                    {Strings["Save"]}{" "}
                  </button>
                ) : (
                  <button type="button" className="btn form-save-btn">
                    <CircularProgress className="SaveCircularProgressColor" />{" "}
                    {Strings["Save"]}
                  </button>
                )}

                <button
                  type="button"
                  className="btn form-cancel-btn m-l-10"
                  onClick={this.props.handleCloseSideBar}
                >
                  {Strings["Cancel"]}
                </button>
              </div>

              {this.state.submitError && (
                <div className="error-text-black">
                  <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                  {this.state.submitError}
                </div>
              )}

              {this.state.cardExsits == 1 && (
                <div className="pt-3">
                  <p className="font-w-400">
                    {Strings["These Cards Already Exist"]}
                  </p>
                  <ul>
                    {this.state.exsitsCardCode.map((data) =>
                      this.setUpLiItem(data)
                    )}
                  </ul>
                </div>
              )}
              {this.state.cardLength == 1 && (
                <div className="pt-3">
                  <p className="font-w-400">
                    {Strings["Check Number Of Codes For These Cards"]}
                  </p>
                  <ul>
                    {this.state.lengthCardCode.map((data) =>
                      this.setUpLiItem(data)
                    )}
                  </ul>
                </div>
              )}
            </AvForm>
          )}
        </div>
      </div>
    );
  }
}
export default AddBalance;

import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import "react-confirm-alert/src/react-confirm-alert.css";
import { translations } from "../../../../translate.js";
import { CircularProgress } from "@material-ui/core";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class ActivateAndDeactivateClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      res: "",
      startSave: 0,
      distId: this.props.distId,
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      res: "",
      submitError: "",
    });
  };

  handleValidSubmit(event) {
    event.preventDefault();
    this.setState({
      startSave: 1,
    });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "updateCardClassStatus",
        cardId: this.props.cardsData.cardId,
        status: this.state.status,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId ? this.state.distId : "",
        all: 1,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              res: data["errorMsg"],
              submitError: "",
              startSave: 0,
            });
            // this.props.handleCloseSideBar()
          }
        }
      });
  }

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Activate and deactivate the class"]} (
                {localStorage.getItem("LANG") == 1
                  ? this.props.cardsData.name
                  : this.props.cardsData.name_en}
                )
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <AvForm
            onValidSubmit={this.handleValidSubmit}
            ref={(c) => (this.form = c)}
          >
            <AvField
              type="select"
              name="status"
              value={this.state.status}
              onChange={this.handleInputChange}
              className="p-1 pl-3"
              errorMessage={Strings["This field is required"]}
            >
              <option style={{ display: "none" }} value="" selected="selected">
                {Strings["Select Status"]}
              </option>
              <option value="1">
                {Strings["Activate status to all classes"]}
              </option>
              <option value="2">
                {Strings["Deactivate status to all classes"]}
              </option>
            </AvField>
            <div className="text-right mt-4">
              {this.state.startSave == 1 ? (
                <button type="button" className="btn form-save-btn ">
                  {" "}
                  <CircularProgress className="SaveCircularProgressColor" />
                  {Strings["Save"]}{" "}
                </button>
              ) : (
                <button type="submit" className="btn form-save-btn mr-1 ml-1">
                  {" "}
                  {Strings["Save"]}{" "}
                </button>
              )}
              <button
                type="button"
                className="btn form-cancel-btn m-l-10"
                onClick={this.props.handleCloseSideBar}
              >
                {Strings["Cancel"]}
              </button>
            </div>
            {this.state.res && (
              <div className="error-text-black">
                <i className={"flaticon2-check-mark success-icon mr-1"} />{" "}
                {this.state.res}
              </div>
            )}
          </AvForm>
        </div>
      </div>
    );
  }
}
export default ActivateAndDeactivateClass;

import React, { Component } from "react";
import {
  Row,
  Col,
  CustomInput,
  Progress,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { toAbsoluteUrl } from "../../../../_metronic";
import Pagination from "react-js-pagination";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, Drawer } from "@material-ui/core";
import { translations } from "../../../../translate.js";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import EmailIcon from "@material-ui/icons/Email";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
var today = new Date();
var currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);
class ViewAppVersion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      limit: 25,
      activePage: 1,
      dateFrom: currentDate,
      dateTo: currentDate,
      dataCount: 0,
      dateType: "loginDate",
      posId: this.props.posData["userId"],
      posName: this.props.posData["fname"] + " " + this.props.posData["lname"],
      data: [],
      errorMsg: "",
    };
  }
  handleRefresh = () => {
    // clear all filter data
    var firstOptionDate = this.state.dateList[0];
    var dateType = firstOptionDate["keyDate"];
    this.setState(
      {
        loading: 0,
        activePage: 1,
        selectedDate: firstOptionDate,
        dateType: dateType,
        dateFrom: currentDate,
        dateTo: currentDate,
        dataCount: 0,
        data: [],
        errorMsg: "",
      },
      function() {
        this.getUserTokenReport();
      }
    );
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getUserTokenReport();
  }
  getUserTokenReport() {
    this.setState({ loading: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getVersionPosAppLogin",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        posId: this.state.posId,
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ loading: 0, errorMsg: data["errorMsg"] });
          } else {
            this.setState({
              data: [data],
              loading: 0,
            });
          }
        }
      });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        activePage: 1,
      },
      function() {
        this.getUserTokenReport();
      }
    );
  };
  handleChangeSelect = (type, option) => {
    this.setState(
      (state) => {
        return {
          [type]: option,
          activePage: 1,
        };
      },
      function() {
        if (type == "selectedDate") {
          this.setState({ dateType: option.keyDate }, function() {
            this.getUserTokenReport();
          });
        }
      }
    );
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getUserTokenReport();
    });
  };
  render() {
    let dataInfo = [];
    var loginDate = "";
    var logoutDate = "";
    var versionId = "";
    if (this.state.loading == 0 && this.state.data.length > 0) {
      this.state.data.map((rowData, index) => {
        loginDate = rowData["loginDate"] == "" ? "-" : rowData["loginDate"];
        logoutDate = rowData["logoutDate"] == "" ? "-" : rowData["logoutDate"];
        versionId = rowData["versionId"] == "" ? "-" : rowData["versionId"];
        dataInfo.push(
          <Row key={index} className="table-body-row">
            {/* <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["deviceId"]}
            >
              {rowData["deviceId"]}
            </Col> */}
            <Col
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="overflow-text-length"
              title={rowData.name}
            >
              {rowData.name}
            </Col>
            <Col
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="overflow-text-length"
              title={rowData.entryDate}
            >
              {rowData.entryDate}
            </Col>
            {/* <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={logoutDate}
            >
              {logoutDate}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["numOfLogin"]}
            >
              {rowData["numOfLogin"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["numOfLogout"]}
            >
              {rowData["numOfLogout"]}
            </Col> */}
          </Row>
        );
      });
    }
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col xs={10} lg={10} md={10} sm={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["App Version"]} ({this.state.posName})
              </div>
            </Col>
            <Col md={2} xs={2} lg={2} sm={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view sidebar-body-view-supplier-transaction">
          {/* <Row className="page-filter-view">
            <Col md={6} xs={12} lg={6} sm={12}>
              <AvForm
                onValidSubmit={this.handleValidSubmit}
                ref={(c) => (this.form = c)}
              >
                <Row className="filter-form">
                  <Col xs={12} lg={10} md={10} sm={12}>
                    <div className="member-searchable-list mb-2">
                      <Picky
                        value={this.state.selectedDate}
                        options={this.state.dateList}
                        onChange={this.handleChangeSelect.bind(
                          this,
                          "selectedDate"
                        )}
                        open={false}
                        valueKey="keyDate"
                        labelKey={"name"}
                        multiple={false}
                        includeSelectAll={true}
                        includeFilter={true}
                        dropdownHeight={200}
                        placeholder={Strings["Date Type"]}
                        filterPlaceholder={Strings["Select Date Type"]}
                      />
                    </div>
                  </Col>
                  <Col md={2} xs={12} lg={2} sm={12} className="mb-1">
                    <Button
                      variant="contained"
                      className="refresh-action-btn   mb-md-0"
                      onClick={this.handleRefresh}
                      title={Strings["Refresh"]}
                    >
                      <i className="flaticon-refresh reset-filter-btn"></i>
                    </Button>
                  </Col>
                  <Col md={6} xs={12} lg={6} sm={12} className="mb-1">
                    <AvField
                      name="dateFrom"
                      type="date"
                      label={Strings["From Date"]}
                      value={this.state.dateFrom}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col md={6} xs={12} lg={6} sm={12} className="mb-1">
                    <AvField
                      name="dateTo"
                      type="date"
                      label={Strings["To Date"]}
                      value={this.state.dateTo}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </Row>
              </AvForm>
            </Col>
            <Col md={6} xs={12} lg={6} sm={12} className={"text-right"}></Col>
          </Row> */}

          {this.state.loading == 1 && (
            <Row>
              <Col
                md={12}
                xs={12}
                lg={12}
                sm={12}
                style={{ marginTop: "15%" }}
                className="text-center"
              >
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}

          {this.state.loading == 0 && this.state.errorMsg != "" && (
            <div className="text-center" style={{ marginTop: "15%" }}>
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">{this.state.errorMsg}</div>
            </div>
          )}

          {this.state.loading == 0 && this.state.data.length <= 0 && (
            <div className="text-center" style={{ marginTop: "15%" }}>
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">
                {Strings["There is no data to display"]}
              </div>
            </div>
          )}

          {this.state.loading == 0 && this.state.data.length > 0 && (
            <Row>
              <div className="inv-cards-view width-100">
                <Row className="table-header-row">
                  {/* <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["No. Device"]}
                  >
                    {Strings["No. Device"]}
                  </Col> */}
                  <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["App Version"]}
                  >
                    {Strings["App Version"]}
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Last Login"]}
                  >
                    {Strings["Date"]}
                  </Col>
                  {/* <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Last Logout"]}
                  >
                    {Strings["Last Logout"]}
                  </Col>
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Number of logins"]}
                  >
                    {Strings["Number of logins"]}
                  </Col>
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Number of logouts"]}
                  >
                    {Strings["Number of logouts"]}
                  </Col> */}
                </Row>
                {dataInfo}
                {/* <Col md={12} xs={12} lg={12} sm={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col> */}
              </div>
            </Row>
          )}
        </div>
      </div>
    );
  }
}
export default ViewAppVersion;

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { translations } from "../../../../translate.js";
import { atelierEstuaryDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class FixedPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitError: "",
      currencyList: [],
      buyPrice: [],
      loading: 1,
      distId: this.props.distId,
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleValidSubmit(event) {
    event.preventDefault();

    let priceValue = [];
    for (let item in this.state.buyPrice) {
      priceValue.push({
        currency: item,
        price: this.state.buyPrice[item],
      });
    }

    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "addFixedCardVlaue",
        cardId: this.props.cardsDataFixesPrice.cardId,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        currencyData: JSON.stringify(priceValue),
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.props.handleCloseFixesPriceSideBar();
          }
        }
      });
  }

  handleInputChange = (event, currencyCode) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    const newArray = this.state.buyPrice;
    var name = currencyCode;
    newArray[name] = value;
    this.setState({ buyPrice: newArray });
  };

  componentDidMount() {
    this.getCurrencies();
    this.getFixedCardVlaue();
  }

  getFixedCardVlaue() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getFixedCardVlaue",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        cardId: this.props.cardsDataFixesPrice.cardId,
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            var buyPrice = [];
            data["data"].map((rowData, index) => {
              buyPrice[rowData.currency] = rowData.buyPrice;
            });
            this.setState({ buyPrice: buyPrice, loading: 0 });
          }
        }
      });
  }

  getCurrencies() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCurrencies",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ currencyList: data });
          }
        }
      });
  }

  displayForm() {
    var formList = "";
    if (this.state.currencyList.length > 0) {
      formList = this.state.currencyList.map((rowData, index) => {
        return (
          <Row key={index} className="fixed-price-form">
            <Col lg={3} md={3} sm={3} xs={12} className="mt-3 font-w-400">
              1 USD =
            </Col>
            <Col
              lg={4}
              md={4}
              sm={4}
              xs={12}
              className="mt-2 mb-2 font-w-400 col-md-4 pt-2 fixed-price-currency-field"
            >
              {rowData["currencyCode"]}
            </Col>
            <Col lg={5} md={5} sm={5} xs={12}>
              <AvField
                name={"buyPrice-" + rowData.id}
                type="number"
                placeholder={Strings["Selling Price"]}
                value={this.state.buyPrice[rowData["id"]]}
                onChange={(event) =>
                  this.handleInputChange(event, rowData["id"])
                }
                errorMessage={Strings["This field is required"]}
              />
            </Col>
          </Row>
        );
      });
    }

    return formList;
  }

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Buy & Sell Price"]} (
                {localStorage.getItem("LANG") == 1
                  ? this.props.cardsDataFixesPrice.name
                  : this.props.cardsDataFixesPrice.name_en}
                )
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseFixesPriceSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          {this.state.loading == 1 && (
            <Row>
              <Col md={12} style={{ marginTop: "15%" }} className="text-center">
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}
          {this.state.loading == 0 && (
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
              className="pr-4 pl-4 form-without-error-msg"
            >
              {this.displayForm()}

              <div className="text-right mt-4">
                <button type="submit" className="btn form-save-btn mr-1 ml-1">
                  {" "}
                  {Strings["Save"]}{" "}
                </button>
                <button
                  type="button"
                  className="btn form-cancel-btn m-l-10"
                  onClick={this.props.handleCloseFixesPriceSideBar}
                >
                  {Strings["Cancel"]}
                </button>
              </div>

              {this.state.submitError && (
                <div className="error-text-black">
                  <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                  {this.state.submitError}
                </div>
              )}
            </AvForm>
          )}
        </div>
      </div>
    );
  }
}
export default FixedPrice;

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress, Label } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { translations } from "../../../../translate.js";
import { CircularProgress } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class CardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startSave: 0,
      deleteImage: 0,
      submitError: "",
      currencyList: [],
      cardsType: [],
      operatorsType: [],
      regions: [],
      pricesType: [],
      invoicesType: [],
      formList: [],
      selectedFormList: [],
      distId: this.props.distId,
      permType: this.props.permType,
      distData: this.props.distData,
      catData: this.props.catData == undefined ? [] : this.props.catData,
      catId:
        this.props.status == "add"
          ? this.props.catId
          : this.props.cardsData["cardCategory"],
      cardId: this.props.status == "add" ? 0 : this.props.cardsData["cardId"],
      name: this.props.status == "add" ? "" : this.props.cardsData["name"],
      name_en:
        this.props.status == "add" ? "" : this.props.cardsData["name_en"],
      getCardType:
        this.props.status == "add" ? "" : this.props.cardsData["getCardType"],
      sellingPrice:
        this.props.status == "add" ? "" : this.props.cardsData["sellingPrice"],
      percentage_price:
        this.props.status == "add"
          ? ""
          : this.props.cardsData["percentage_price"],
      invoiceType:
        this.props.status == "add" ? "" : this.props.cardsData["invoiceType"],
      currency:
        this.props.status == "add" ? "" : this.props.cardsData["currency"],
      getNotifications:
        this.props.status == "add"
          ? ""
          : this.props.cardsData["getNotifications"],
      bestSeller:
        this.props.status == "add" ? "" : this.props.cardsData["bestSeller"],
      note: this.props.status == "add" ? "" : this.props.cardsData["note"],
      ar_desc:
        this.props.status == "add" ? "" : this.props.cardsData["ar_desc"],
      en_desc:
        this.props.status == "add" ? "" : this.props.cardsData["en_desc"],
      imageUrl: this.props.status == "add" ? "" : this.props.cardsData["image"],
      operator:
        this.props.status == "add" ? "" : this.props.cardsData["operator"],
      whatsappPhone:
        this.props.status == "add" ? "" : this.props.cardsData["whatsappPhone"],
      whatsAppNoti:
        this.props.status == "add" ? "" : this.props.cardsData["whatsAppNoti"],
      whatsAppChannelId:
        this.props.status == "add"
          ? ""
          : this.props.cardsData["whatsAppChannelId"],
      whatsAppClientId:
        this.props.status == "add"
          ? ""
          : this.props.cardsData["whatsAppClientId"],
      paymentTax:
        this.props.status == "add" ? "" : this.props.cardsData["paymentTax"],
      region: this.props.status == "add" ? "" : this.props.cardsData["region"],
      catIsDirectCharge:
        this.props.catData == undefined ||
        this.props.catData.isDirectCharge == undefined
          ? ""
          : this.props.catData.isDirectCharge,
      catIsFree:
        this.props.catData == undefined ||
        this.props.catData.isFreePriceForDist == undefined
          ? ""
          : this.props.catData.isFreePriceForDist,
      selectedDirectChargeItem: this.props.cardsData.inquiryType,
      DirectChargeItems: [],
      qrCodeTypes: [],
      qrCodeType: this.props.cardsData.qrType
        ? this.props.cardsData.qrType
        : "",
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    console.log(this.props.cardsData, "this.props.cardsData");
    if (
      this.state.catIsDirectCharge == "0" &&
      localStorage.getItem("USER_LEVEL") == 1
    ) {
      this.getQrCodeType();
    }
    if (
      this.state.catIsDirectCharge == 2 &&
      localStorage.getItem("USER_LEVEL") == 1
    ) {
      this.getDirectChargeCheckSelectData();
    }
    this.getCurrencies();
    this.getCardTypes();
    this.getPriceTypes();
    this.getInvoiceTypes();
    if (
      localStorage.getItem("USER_LEVEL") == 1 ||
      localStorage.getItem("INV_MGR") == 1
    ) {
      this.getOperatorsTypes();
      this.getRegions();
      this.getUploadFileForm();
      if (this.props.status == "edit") this.setSelectedUploadFileForm();
    }
  }
  //############################################# start Api ###################################################
  getQrCodeType() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "qrCodeType",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ qrCodeTypes: data });
          }
        }
      });
  }
  getDirectChargeCheckSelectData = async () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "shippingInquiryType",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ DirectChargeItems: data });
          }
        }
      });
  };
  getCardTypes() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCardTypes",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ cardsType: data });
          }
        }
      });
  }
  getCurrencies() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCurrencies",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ currencyList: data });
          }
        }
      });
  }
  getOperatorsTypes() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "typesOfOperators",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ operatorsType: data });
          }
        }
      });
  }
  getPriceTypes() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getPurchasingPriceTypes",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ pricesType: data });
          }
        }
      });
  }
  getInvoiceTypes() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getInvoiceTypes",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ invoicesType: data });
          }
        }
      });
  }
  getRegions() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getRegions",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            // console.log(data["data"]);
            this.setState({ regions: data["data"] });
          }
        }
      });
  }
  getUploadFileForm() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getForms",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              formList: data["data"],
            });
          }
        }
      });
  }
  setSelectedUploadFileForm() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCardForms",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        cardId: this.state.cardId,
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ submitError: data["errorMsg"] });
          } else {
            this.setState({
              selectedFormList: data,
            });
          }
        }
      });
  }
  handleValidSubmit(event) {
    event.preventDefault();
    var data = new FormData();
    var cardImage = document.querySelector("input[name=image]").files[0];
    var deletedImages = 0;
    if (this.state.deleteImage == 1) {
      deletedImages = "1";
    }
    // console.log('------------------- cardImage');
    // console.log(cardImage);
    this.setState({ startSave: 1 });
    data.append("data", cardImage);
    if (this.state.catIsDirectCharge == 0) {
      data.append("qrType", this.state.qrCodeType);
    }
    data.append("type", "addCards");
    data.append("userId", localStorage.getItem("USER_ID"));
    data.append("token", localStorage.getItem("USER_TOKEN"));
    data.append("lang", localStorage.getItem("LANG"));
    data.append("catId", this.state.catId);
    data.append("cardId", this.state.cardId);
    data.append("inquiryType", this.state.selectedDirectChargeItem);
    data.append("name", this.state.name);
    data.append("name_en", this.state.name_en);
    data.append("getCardType", this.state.getCardType);
    data.append("sellingPrice", this.state.sellingPrice);
    data.append("percentage_price", this.state.percentage_price);
    data.append("invoiceType", this.state.invoiceType);
    data.append("currency", this.state.currency);
    data.append(
      "getNotifications",
      this.state.getNotifications == true ? 1 : 0
    );
    data.append("bestSeller", this.state.bestSeller == true ? 1 : 0);
    data.append("note", this.state.note);
    data.append("ar_desc", this.state.ar_desc);
    data.append("en_desc", this.state.en_desc);
    data.append("deletedImages", deletedImages);
    if (
      localStorage.getItem("USER_LEVEL") == 1 ||
      localStorage.getItem("INV_MGR") == 1
    ) {
      data.append("operator", this.state.operator);
      data.append("formId", JSON.stringify(this.getSelected("formType")));
      data.append("region", this.state.region);
      data.append("paymentTax", this.state.paymentTax);
      data.append("whatsappPhone", this.state.whatsappPhone);
      data.append("whatsAppChannelId", this.state.whatsAppChannelId);
      data.append("whatsAppClientId", this.state.whatsAppClientId);
      data.append("whatsAppNoti", this.state.whatsAppNoti == true ? 1 : 0);
      data.append("distId", this.state.distId ? this.state.distId : "");
    }
    fetch(window.API_PERM_USER, {
      // mode: 'no-cors',
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        if (!response.ok) {
          // console.log(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('===============================');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ submitError: data["errorMsg"] });
          } else {
            this.setState({ startSave: 0 });
            this.props.handleFormAction();
          }
        }
      });
  }
  //############################################# end Api ###################################################
  //############################################# start react ###################################################
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      submitError: "",
    });
  };
  handleChangeSelect = (type, option) => {
    this.setState((state) => {
      return {
        [type]: option,
      };
    });
  };
  handleImgChange = (event) => {
    var fileReader = new FileReader();
    const scope = this;

    if (event.target.files[0].type.includes("image")) {
      this.setState({ displayErrorHeaderMsg: false });
      this.setState({ progressUploadHeader: 10 });
      fileReader.readAsBinaryString(event.target.files[0]);
      fileReader.onprogress = function(data) {
        if (data.lengthComputable) {
          var progress = parseInt((data.loaded / data.total) * 100, 10);
          scope.setState({ progressUploadHeader: progress });
        }
      };
    } else {
      this.setState({ displayErrorHeaderMsg: true });
    }

    this.setState({
      headerImg: event.target.files[0]["name"],
    });
  };
  getSelected = (type) => {
    if (type == "formType") {
      return this.state.selectedFormList.map((data) => data.formId);
    }
  };
  setUpSelectCurrencyOptions = (data, type) => {
    if (type == "currency") {
      return (
        <option value={data.currencyCode} key={data.id}>
          {data.currency}
        </option>
      );
    } else if (type == "region") {
      return (
        <option value={data.id} key={data.id}>
          {localStorage.getItem("LANG") == 1 ? data.name : data.nameEn}
        </option>
      );
    } else if (
      type == "operator" ||
      type == "sellingPrice" ||
      type == "invoiceType" ||
      type == "cardsType"
    ) {
      return (
        <option value={data.id} key={data.id}>
          {data.name}
        </option>
      );
    }
  };
  deleteUploadImage = () => {
    this.setState({ deleteImage: 1 });
  };
  //############################################# end react ###################################################
  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {this.props.status == "add"
                  ? Strings["Add"]
                  : Strings["Edit"]}{" "}
                {Strings["Card"]}
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <AvForm
            onValidSubmit={this.handleValidSubmit}
            ref={(c) => (this.form = c)}
          >
            <AvField
              name="name"
              label={Strings["Card Name"] + " *"}
              placeholder={Strings["Enter Card Name"]}
              value={this.state.name}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              name="name_en"
              label={Strings["Card Name In English"] + " *"}
              placeholder={Strings["Enter Card Name In English"]}
              value={this.state.name_en}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <div className="mt-2 mb-4">
              <label>{Strings["Card Icon"]}</label>
              <span className={"fileInput-span col-md-12"}>
                <PublishIcon />{" "}
                <span className="pointer-cursor pt-3">{Strings["Upload"]}</span>
                <input
                  type="file"
                  accept="image/*"
                  className="fileInput-width fileInput-opacity"
                  onChange={(e) => this.handleImgChange(e)}
                  name="image"
                ></input>
              </span>

              {this.state.image != "" &&
                this.state.image != undefined &&
                this.state.image != null && (
                  <Row className="mt-2">
                    <Col md={12}>
                      <div className="">
                        <a
                          href={this.state.profileImgPath}
                          className="black-color"
                        >
                          <i className="far fa-file-image"></i>{" "}
                          {this.state.displayName}
                        </a>
                      </div>
                    </Col>
                  </Row>
                )}
              {this.state.progressUploadHeader != undefined &&
                this.state.progressUploadHeader > 0 && (
                  <Progress
                    striped
                    value={this.state.progressUploadHeader}
                    className="mb-1 mt-1 progress-bg-color"
                  >
                    {this.state.progressUploadHeader} %
                  </Progress>
                )}
              {this.state.displayErrorHeaderMsg == true && (
                <div className="invalid-feedback d-block mb-1">
                  {Strings["Upload Image only"]}
                </div>
              )}

              {this.state.imageUrl != null &&
                this.state.imageUrl != undefined &&
                this.state.imageUrl != "" &&
                this.state.deleteImage == 0 && (
                  <div className="text-center mt-4">
                    <img
                      src={this.state.imageUrl}
                      className="img-fluid inv-category-img"
                    />
                    <div
                      className="task-action-file-details-delete-icon"
                      onClick={() => this.deleteUploadImage("1")}
                    >
                      <span>
                        <DeleteIcon /> {Strings["Delete"]}
                      </span>
                    </div>
                  </div>
                )}
            </div>
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) && (
              <AvField
                type="select"
                name="operator"
                label={Strings["Network Operator"]}
                value={this.state.operator}
                onChange={this.handleInputChange}
                className="p-1 pl-3"
              >
                <option
                  style={{
                    display: "block",
                  }}
                  value=""
                  selected="selected"
                >
                  {Strings["Selected"]} {Strings["Network Operator"]}
                </option>
                {this.state.operatorsType.map((data) =>
                  this.setUpSelectCurrencyOptions(data, "operator")
                )}
              </AvField>
            )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) && (
              <>
                <Label>{Strings["Upload File Templates"]}</Label>
                <div className="member-searchable-list mb-4">
                  <Picky
                    value={this.state.selectedFormList}
                    options={this.state.formList}
                    onChange={this.handleChangeSelect.bind(
                      this,
                      "selectedFormList"
                    )}
                    open={false}
                    valueKey={"formId"}
                    labelKey={
                      localStorage.getItem("LANG") == 1
                        ? "formName"
                        : "formNameEn"
                    }
                    multiple={true}
                    includeSelectAll={true}
                    includeFilter={true}
                    dropdownHeight={200}
                    placeholder={Strings["Upload File Templates"]}
                    filterPlaceholder={Strings["Select Upload File Templates"]}
                    selectAllText={Strings["Select All"]}
                    allSelectedPlaceholder={Strings["%s selected"]}
                    manySelectedPlaceholder={Strings["%s selected"]}
                    clearFilterOnClose={true}
                  />
                </div>
              </>
            )}
            <AvField
              type="select"
              name="getCardType"
              label={Strings["Card Binding Type"] + " *"}
              value={this.state.getCardType}
              required
              onChange={this.handleInputChange}
              className="p-1 pl-3"
              errorMessage={Strings["This field is required"]}
            >
              <option
                style={{
                  display: "none",
                }}
                value=""
                selected="selected"
              >
                {Strings["Select card binding type"]}
              </option>
              {this.state.cardsType.map((data) =>
                this.setUpSelectCurrencyOptions(data, "cardsType")
              )}
            </AvField>
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) && (
              <AvField
                type="select"
                name="region"
                label={Strings["Region"]}
                value={this.state.region}
                onChange={this.handleInputChange}
                className="p-1 pl-3"
              >
                <option
                  style={{
                    display: "block",
                  }}
                  value=""
                  selected="selected"
                >
                  {Strings["Selected"]} {Strings["Region"]}
                </option>
                {this.state.regions.map((data) =>
                  this.setUpSelectCurrencyOptions(data, "region")
                )}
              </AvField>
            )}
            {this.state.getCardType == 4 && (
              <AvField
                type="select"
                name="getCardType_mintRout"
                label={Strings["MintRout Cards"] + " *"}
                value={this.state.getCardType_mintRout}
                required
                onChange={this.handleInputChange}
                className="p-1 pl-3"
                errorMessage={Strings["This field is required"]}
              >
                <option
                  style={{
                    display: "none",
                  }}
                  value=""
                  selected="selected"
                >
                  {Strings["Select buy price"]}
                </option>
              </AvField>
            )}

            <AvField
              type="select"
              name="sellingPrice"
              label={Strings["Buy Price"] + " *"}
              value={this.state.sellingPrice}
              required
              onChange={this.handleInputChange}
              className="p-1 pl-3"
              errorMessage={Strings["This field is required"]}
            >
              <option
                style={{
                  display: "none",
                }}
                value=""
                selected="selected"
              >
                {Strings["Select buy price"]}
              </option>
              {this.state.pricesType.map((data) =>
                this.setUpSelectCurrencyOptions(data, "sellingPrice")
              )}
            </AvField>

            {this.state.sellingPrice == 2 && (
              <AvField
                name="percentage_price"
                label={Strings["Percentage"] + " *"}
                placeholder={Strings["Enter Percentage"]}
                value={this.state.percentage_price}
                required
                onChange={this.handleInputChange}
                errorMessage={Strings["This field is required"]}
              />
            )}

            <AvField
              type="select"
              name="invoiceType"
              label={Strings["Invoice Type"] + " *"}
              value={this.state.invoiceType}
              required
              onChange={this.handleInputChange}
              className="p-1 pl-3"
              errorMessage={Strings["This field is required"]}
            >
              <option
                style={{
                  display: "none",
                }}
                value=""
                selected="selected"
              >
                {Strings["Select invoice Type"]}
              </option>
              {this.state.invoicesType.map((data) =>
                this.setUpSelectCurrencyOptions(data, "invoiceType")
              )}
            </AvField>

            <AvField
              type="select"
              name="currency"
              label={Strings["Currency"] + " *"}
              value={this.state.currency}
              required
              onChange={this.handleInputChange}
              className="p-1 pl-3"
              errorMessage={Strings["This field is required"]}
            >
              <option
                style={{
                  display: "none",
                }}
                value=""
                selected="selected"
              >
                {Strings["Select currency"]}
              </option>
              {this.state.currencyList.map((data) =>
                this.setUpSelectCurrencyOptions(data, "currency")
              )}
            </AvField>
            {this.props.createQr == 1 &&
              this.state.catIsDirectCharge == "0" &&
              localStorage.getItem("USER_LEVEL") == 1 && (
                <AvField
                  type="select"
                  name="qrCodeType"
                  label={Strings["Qr Code Type"] + " *"}
                  value={this.state.qrCodeType}
                  required
                  onChange={this.handleInputChange}
                  className="p-1 pl-3"
                  errorMessage={Strings["This field is required"]}
                >
                  <option
                    style={{
                      display: "none",
                    }}
                    value=""
                    selected="selected"
                  >
                    {Strings["Select invoice Type"]}
                  </option>
                  {this.state.qrCodeTypes.map((data) =>
                    this.setUpSelectCurrencyOptions(data, "invoiceType")
                  )}
                </AvField>
              )}
            {this.state.catIsDirectCharge == 2 &&
              localStorage.getItem("USER_LEVEL") == 1 && (
                <AvField
                  type="select"
                  name="selectedDirectChargeItem"
                  label={Strings["Charge inquiry type"] + " *"}
                  value={this.state.selectedDirectChargeItem}
                  required
                  onChange={this.handleInputChange}
                  className="p-1 pl-3"
                  errorMessage={Strings["This field is required"]}
                >
                  <option
                    style={{
                      display: "none",
                    }}
                    value=""
                    selected="selected"
                  >
                    {Strings["Select charge inquiry type"]}
                  </option>
                  {this.state.DirectChargeItems.map((data) =>
                    this.setUpSelectCurrencyOptions(data, "cardsType")
                  )}
                </AvField>
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) &&
              (this.state.catIsDirectCharge == 2 ||
                this.state.catIsDirectCharge == 5) && (
                <AvField
                  name="whatsappPhone"
                  type="text"
                  label={Strings["WhatsApp Number"]}
                  placeholder={Strings["WhatsApp Number"]}
                  value={this.state.whatsappPhone}
                  onChange={this.handleInputChange}
                />
              )}

            <AvField
              label={Strings["Activate Notifications"]}
              type="checkbox"
              name="getNotifications"
              ref="default"
              value={
                this.state.getNotifications == 1 ||
                this.state.getNotifications == true
                  ? true
                  : false
              }
              onChange={this.handleInputChange}
              checked={
                this.state.getNotifications == 1 ||
                this.state.getNotifications == true
                  ? true
                  : false
              }
              tag={CustomInput}
              className="custom-form-mb default mb-3 mr-19px"
            />

            <AvField
              label={Strings["Best Seller"]}
              type="checkbox"
              name="bestSeller"
              ref="default"
              value={
                this.state.bestSeller == 1 || this.state.bestSeller == true
                  ? true
                  : false
              }
              onChange={this.handleInputChange}
              checked={
                this.state.bestSeller == 1 || this.state.bestSeller == true
                  ? true
                  : false
              }
              tag={CustomInput}
              className="custom-form-mb default mb-3 mr-19px"
            />
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) &&
              (this.state.catIsDirectCharge > 0 ||
                this.state.catIsFree == 1) && (
                <AvField
                  label={Strings["WhatsApp Notifications"]}
                  type="checkbox"
                  name="whatsAppNoti"
                  ref="default"
                  value={
                    this.state.whatsAppNoti == 1 ||
                    this.state.whatsAppNoti == true
                      ? true
                      : false
                  }
                  onChange={this.handleInputChange}
                  checked={
                    this.state.whatsAppNoti == 1 ||
                    this.state.whatsAppNoti == true
                      ? true
                      : false
                  }
                  tag={CustomInput}
                  className="custom-form-mb default mb-3 mr-19px"
                />
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) &&
              this.state.whatsAppNoti == 1 && (
                <AvField
                  name="whatsAppClientId"
                  type="text"
                  label={Strings["WhatsApp Client Number"]}
                  placeholder={Strings["WhatsApp Client Number"]}
                  value={this.state.whatsAppClientId}
                  onChange={this.handleInputChange}
                />
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) &&
              this.state.whatsAppNoti == 1 && (
                <AvField
                  name="whatsAppChannelId"
                  type="text"
                  label={Strings["WhatsApp Channel Number"]}
                  placeholder={Strings["WhatsApp Channel Number"]}
                  value={this.state.whatsAppChannelId}
                  onChange={this.handleInputChange}
                />
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) &&
              this.state.whatsAppNoti == 1 && (
                <AvField
                  name="paymentTax"
                  type="text"
                  label={Strings["Payment Fees"]}
                  placeholder={Strings["Payment Fees"]}
                  value={this.state.paymentTax}
                  onChange={this.handleInputChange}
                />
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) &&
              this.state.catIsDirectCharge == 8 && (
                <AvField
                  name="whatsAppClientId"
                  type="text"
                  label={Strings["WhatsApp Client Number"]}
                  placeholder={Strings["WhatsApp Client Number"]}
                  value={this.state.whatsAppClientId}
                  onChange={this.handleInputChange}
                />
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) &&
              this.state.catIsDirectCharge == 8 && (
                <AvField
                  name="whatsAppChannelId"
                  type="text"
                  label={Strings["WhatsApp Channel Number"]}
                  placeholder={Strings["WhatsApp Channel Number"]}
                  value={this.state.whatsAppChannelId}
                  onChange={this.handleInputChange}
                />
              )}
            <AvField
              name="note"
              type="textarea"
              label={Strings["Notes"]}
              placeholder={Strings["Enter Notes"]}
              value={this.state.note}
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              name="ar_desc"
              type="textarea"
              label={Strings["Arabic Desription"]}
              placeholder={Strings["Enter arabic desription"]}
              value={this.state.ar_desc}
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              name="en_desc"
              type="textarea"
              label={Strings["English Desription"]}
              placeholder={Strings["Enter english desription"]}
              value={this.state.en_desc}
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />

            <div className="text-right mt-4">
              {this.state.startSave == 0 ? (
                <button type="submit" className="btn form-save-btn mr-1 ml-1">
                  {" "}
                  {Strings["Save"]}{" "}
                </button>
              ) : (
                <button type="button" className="btn form-save-btn mr-1 ml-1">
                  <CircularProgress className="SaveCircularProgressColor" />{" "}
                  {Strings["Save"]}
                </button>
              )}
              <button
                type="button"
                className="btn form-cancel-btn m-l-10"
                onClick={this.props.handleCloseSideBar}
              >
                {Strings["Cancel"]}
              </button>
            </div>

            {this.state.submitError && (
              <div className="error-text-black">
                <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                {this.state.submitError}
              </div>
            )}
          </AvForm>
        </div>
      </div>
    );
  }
}
export default CardForm;

import React, { Component } from "react";
import {
  Row,
  Col,
  CustomInput,
  Progress,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button, Drawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Pagination from "react-js-pagination";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ViewOrder from "./forms/viewOrder";
import ViewClasses from "./forms/viewClasses";
import ViewClassesPos from "./forms/viewClassesPos";
import { CircularProgress } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import { translations } from "../../../translate.js";
import SearchIcon from "@material-ui/icons/Search";
import AddProblem from "./forms/addProblem";
import { func } from "prop-types";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
var today = new Date();
var currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);

class OrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOrderAction: false,
      orderRowData: [],
      posList: [],
      selectedPos: [],
      loading: 1,
      statusList: [],
      categoryList: [],
      selectedCategoryList: [],
      cardTypeList: [],
      orderFilterOrderId:
        this.props.match.params.orderId == undefined
          ? ""
          : this.props.match.params.orderId,
      selectedCardTypeList: [],
      selectedStatusList: [],
      data: [],
      dataCount: 0,
      showExportOrder: 0,
      limit: 25,
      activePage: 1,
      emptyEmail: 0,
      loadingSave: 0,
      searchCode: 0,
      mainDist:
        localStorage.getItem("PERM_TYPE") == 5 ||
        localStorage.getItem("POS_MGR_EMP") == 1
          ? 0
          : 1,
      errorMsg: "",
      actionType: "",
      orderFilterDateFrom: currentDate,
      orderFilterDateTo: currentDate,
      orderPrint: null,
    };
  }
  handleRefresh = () => {
    // clear all filter data
    this.setState(
      {
        selectedPos: [],
        selectedCategoryList: [],
        selectedCardTypeList: [],
        selectedStatusList: [],
        orderFilterOrderId: "",
        searchCode: 0,
        orderFilterDateFrom: currentDate,
        orderFilterDateTo: currentDate,
      },
      function() {
        this.getOrdersList();
      }
    );
  };
  componentDidMount() {
    this.getOrdersList();
    this.getOrderStatus();
    this.getPos();
    this.getCategoryList();
    if (localStorage.getItem("PERM_TYPE") == 5) {
      this.getPrintStatusFilters();
    }
    if (
      this.props.match.params.orderId != undefined &&
      this.props.match.params.orderId != "" &&
      this.props.match.params.orderId != null &&
      this.props.match.params.orderId != 0
    ) {
      this.setState(
        {
          orderFilterDateFrom: "",
          orderFilterDateTo: "",
        },
        function() {
          this.getOrdersList();
        }
      );
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
        });
      }
    }
  };
  getOrderStatus() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getOrderStatus",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              statusList: data["data"],
            });
          }
        }
      });
  }
  getCategoryList() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCategories",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        mainDist: this.state.mainDist,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              categoryList: data,
            });
          }
        }
      });
  }

  getOrdersList(withEmail = 0, seacrhCode = 0) {
    console.log("GOOOOOOOOOOOO!");
    this.setState({ sendEmailResult: 0 });
    var sendByMail = "";
    var email = "";
    if (withEmail == 1) {
      sendByMail = 1;
      email = this.state.email;
      this.setState({ loadingSave: 1 });
    } else {
      this.setState({ loading: 1 });
    }
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCustomerOrder",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        page: this.state.activePage,
        limit: this.state.limit,
        orderFilterDateFrom: this.state.orderFilterDateFrom,
        orderFilterDateTo: this.state.orderFilterDateTo,
        orderFilterStatus: this.getSelected("status"),
        orderFilterCardCatg: this.getSelected("category"),
        orderFilterCardId: this.getSelected("card"),
        orderFilterOrderId: this.state.orderFilterOrderId,
        orderFilterPosId: this.getSelected("pos"),
        mode: window.MODE,
        // 'orderFilterUserId': localStorage.getItem('PERM_DIST'),
        orderFilterUserId: "",
        sendByMail: sendByMail,
        code: seacrhCode == 1 ? this.state.code : "",
        emails: email,
        orderPrint: this.state.orderPrint?.id,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ errorMsg: data["errorMsg"] });
          } else {
            if (withEmail == 1) {
              this.setState({ sendEmailResult: 1, loadingSave: 0 });
            } else {
              this.setState({
                data: data["orderData"],
                dataCount: data["orderDataCounter"],
                showExportOrder: data["showExportOrder"],
                loading: 0,
                // currencyTitle: data['currencyTitle']
              });
            }
          }
        }
      });
  }

  getCardsList() {
    if (this.state.selectedCategoryList.length > 0) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getCards",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          catId: this.getSelected("category"),
          mainDist: this.state.mainDist,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (typeof data !== "undefined") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState({
                cardTypeList: data,
              });
            }
          }
        });
    }
  }

  getPos() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistPos",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        allPos: 0,
        getType: "all",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            var posList = [];
            if (data["data"].length > 0) {
              data["data"].map((rowData, index) => {
                var posListItem = {};
                posListItem["userId"] = rowData["userId"];
                posListItem["fname"] = rowData["fname"];
                posListItem["lname"] = rowData["lname"];
                posListItem["name"] = rowData["fname"] + " " + rowData["lname"];
                posList.push(posListItem);
              });
            }

            this.setState({
              posList: posList,
            });
          }
        }
      });
  }

  exportHandler() {
    var orderFilterDateFrom = this.state.orderFilterDateFrom;
    if (
      orderFilterDateFrom == "" ||
      orderFilterDateFrom == null ||
      orderFilterDateFrom == undefined
    ) {
      orderFilterDateFrom = currentDate;
    }

    var orderFilterDateTo = this.state.orderFilterDateTo;
    if (
      orderFilterDateTo == "" ||
      orderFilterDateTo == null ||
      orderFilterDateTo == undefined
    ) {
      orderFilterDateTo = currentDate;
    }

    var orderFilterOrderId = this.state.orderFilterOrderId;
    if (
      orderFilterOrderId == "" ||
      orderFilterOrderId == null ||
      orderFilterOrderId == undefined
    ) {
      orderFilterOrderId = "";
    }
    var code = this.state.code;
    if (
      code == "" ||
      code == null ||
      code == undefined ||
      this.state.searchCode == 0
    ) {
      code = "";
    }

    window.location =
      window.API_PERM_USER +
      "?type=getCustomerOrder&lang=" +
      localStorage.getItem("LANG") +
      "&userId=" +
      localStorage.getItem("USER_ID") +
      "&token=" +
      localStorage.getItem("USER_TOKEN") +
      "&export=1" +
      "&orderFilterDateFrom=" +
      orderFilterDateFrom +
      "&orderFilterDateTo=" +
      orderFilterDateTo +
      "&orderFilterStatus=" +
      this.getSelected("status") +
      "&orderFilterCardCatg=" +
      this.getSelected("category") +
      "&orderFilterCardId=" +
      this.getSelected("card") +
      "&orderFilterOrderId=" +
      orderFilterOrderId +
      "&orderFilterPosId=" +
      this.getSelected("pos") +
      "&orderFilterUserId=" +
      localStorage.getItem("PERM_DIST") +
      "&mode=" +
      window.MODE +
      "&code=" +
      code +
      "&orderPrint=" +
      this.state.orderPrint?.id;
  }

  handleChangeSelect = (type, option) => {
    if (type == "pos") {
      this.setState(
        (state) => {
          return {
            selectedPos: option,
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
        }
      );
    } else if (type == "category") {
      this.setState(
        (state) => {
          return {
            selectedCategoryList: option,
            selectedCardTypeList: [],
            cardTypeList: [],
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
          this.getCardsList();
        }
      );
    } else if (type == "card") {
      this.setState(
        (state) => {
          return {
            selectedCardTypeList: option,
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
        }
      );
    } else if (type == "status") {
      this.setState(
        (state) => {
          return {
            selectedStatusList: option,
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
        }
      );
    } else {
      this.setState(
        {
          orderPrint: option,
          activePage: 1,
        },
        function() {
          // this.getOrdersList();
          this.getCardsList();
        }
      );
    }
  };
  getSelected = (type) => {
    if (type == "pos") {
      return this.state.selectedPos.map((data) => data.userId);
    }
    if (type == "category") {
      return this.state.selectedCategoryList.map((data) => data.id);
    }
    if (type == "card") {
      return this.state.selectedCardTypeList.map((data) => data.cardId);
    }
    if (type == "status") {
      return this.state.selectedStatusList.map((data) => data.id);
    }
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (name == "email") {
      this.setState({
        [name]: value,
        emptyEmail: 0,
      });
    } else if (name == "code") {
      this.setState({
        [name]: value,
        emptyCode: 0,
      });
    } else {
      this.setState(
        {
          [name]: value,
          activePage: 1,
        },
        function() {
          // this.getOrdersList();
        }
      );
    }
  };

  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };
  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };

  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };

  openOrderAction = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (type == "view") {
      this.setState({
        orderRowData: data,
        openOrderAction: true,
        actionType: "viewOrder",
      });
    } else if (type == "export") {
      this.printPdf_CSV(data["id"], 1);
    } else if (type == "csv") {
      this.printPdf_CSV(data["id"], 2);
    } else if (type == "sms") {
      this.sendSms(data["id"]);
    } else if (type == "cancel") {
      this.cancelOrder(data["id"]);
    } else if (type == "classes") {
      this.setState({
        orderRowData: data,
        openOrderAction: true,
        actionType: "viewClasses",
      });
    } else if (type == "add_problem") {
      this.setState({
        actionType: type,
        openOrderAction: true,
        orderRowData: data,
      });
    }
  };

  printPdf_CSV(id, typeExport) {
    /*
            typeExport >> 1 : pdf
                          2 : csv
        */

    window.open(
      window.API_PERM_USER +
        "/permUser.php?type=" +
        "printPDFOrCSVInvoiceToCustomer" +
        "&lang=" +
        localStorage.getItem("LANG") +
        "&userId=" +
        localStorage.getItem("USER_ID") +
        "&token=" +
        localStorage.getItem("USER_TOKEN") +
        "&orderId=" +
        id +
        "&typeExport=" +
        typeExport,
      "_blank"
    );
  }

  sendSms(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to send sms ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickSendSms(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  }

  handleClickSendSms(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "sendSMSToCustomer",
        orderId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <div className="mb-3">
                      <span className="alert-delete-icon">!</span>
                    </div>
                    <p>{data["errorMsg"]}</p>
                    <button
                      type="button"
                      className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                      onClick={onClose}
                    >
                      {Strings.Cancel}
                    </button>
                  </div>
                );
              },
            });
          }
        }
      });
  }
  getPrintStatusFilters = async () => {
    try {
      let response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "printFilter",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
        headers: {
          Accept: "application/json",
        },
      });
      if (response.status == 200) {
        let res = await response.json();
        if (res.logout == 1) {
          localStorage.setItem("USER_ID", "");
          localStorage.setItem("USER_TOKEN", "");
          window.location.href = "/logout";
        } else {
          this.setState({ printFilters: res });
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  cancelOrder(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view alertBoxResopnsive">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to cancel order ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickCancelOrder(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  }

  handleClickCancelOrder(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "cancelOrderById",
        orderId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
            this.getOrdersList();
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <div className="mb-3">
                      <span className="alert-delete-icon">!</span>
                    </div>
                    <p>{data["errorMsg"]}</p>
                    <button
                      type="button"
                      className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                      onClick={onClose}
                    >
                      {Strings.Cancel}
                    </button>
                  </div>
                );
              },
            });
          }
        }
      });
  }
  handleSendEmail = () => {
    if (
      this.state.email != undefined &&
      this.state.email != null &&
      this.state.email != ""
    ) {
      this.getOrdersList(1);
      this.setState({ emptyEmail: 0 });
    } else {
      this.setState({ emptyEmail: 1 });
    }
  };
  handleSearchByCode = () => {
    if (
      this.state.code != undefined &&
      this.state.code != null &&
      this.state.code != ""
    ) {
      this.setState({ emptyCode: 0, searchCode: 1 }, function() {
        this.getOrdersList(0, 1);
      });
    } else {
      this.setState({ emptyCode: 1 });
    }
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getOrdersList();
    });
  };

  handleCloseSideBar = () => {
    this.setState({ openOrderAction: false, orderRowData: [] });
  };

  recallGetData = () => {
    this.setState(
      {
        openOrderAction: false,
        orderRowData: [],
      },
      function() {
        this.getOrdersList();
      }
    );
  };
  displayData() {
    var orderInfo = "";
    var classStyle = {
      "text-1": "gray-color",
      "text-2": "warning-color",
      "text-3": "succ-color",
      "text-4": "reject-color",
      "text-5": "addCredit-color",
      "text-6": "wthdrawal-color",
      "text-7": "profit-color",
      "text-8": "status-8-color",
      "text-9": "status-9-color",
      "text-11": "pos-correct-mng-color",
      "text-15": "status-10-color",
    };
    var classColStatus = "";
    var cardName = "";
    var serailNumber = "";
    if (this.state.loading == 0 && this.state.data.length > 0) {
      orderInfo = this.state.data.map((rowData, index) => {
        if (rowData["status"] !== "") {
          classColStatus = classStyle["text-" + rowData["status"]];
        }
        classColStatus =
          " overflow-text-length  bold-font " + classColStatus + " ";
        rowData["actualAmount"] =
          rowData["actualAmount"] == "" ? 0 : rowData["actualAmount"];
        cardName =
          localStorage.getItem("LANG") == 1
            ? rowData["cardName_ar"]
            : rowData["cardName_en"];
        serailNumber =
          rowData["billNumber"] != undefined && rowData["billNumber"] != ""
            ? rowData["billNumber"]
            : "-";
        return (
          <Row key={index} className="table-body-row">
            {(localStorage.getItem("PERM_TYPE") == 5 ||
              localStorage.getItem("POS_MGR_EMP") == 1 ||
              (localStorage.getItem("USER_LEVEL") == 3 &&
                localStorage.getItem("ACCOUNT_TYPE") == 1)) && (
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={12}
                className="overflow-text-length"
              >
                {rowData["id"]}
              </Col>
            )}
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length"
              title={rowData["fname"] + " " + rowData["lname"]}
            >
              {rowData["fname"]} {rowData["lname"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length"
              title={Strings["Payment Method"]}
            >
              {localStorage.getItem("LANG") == 1
                ? rowData["transPaymentType_ar"]
                : rowData["transPaymentType_en"]}
            </Col>
            {localStorage.getItem("PERM_TYPE") == 5 ||
            localStorage.getItem("POS_MGR_EMP") == 1 ||
            (localStorage.getItem("USER_LEVEL") == 3 &&
              localStorage.getItem("ACCOUNT_TYPE") == 1) ? (
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={12}
                className="overflow-text-length"
              >
                {rowData["orderDateZone"]} <br /> {rowData["orderTimeZone"]}
              </Col>
            ) : (
              <Col
                lg={2}
                md={2}
                sm={2}
                xs={12}
                className="overflow-text-length"
              >
                {rowData["orderDateZone"]} <br /> {rowData["orderTimeZone"]}
              </Col>
            )}
            {localStorage.getItem("PERM_TYPE") != 5 &&
              localStorage.getItem("POS_MGR_EMP") == 0 && (
                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={12}
                  className="overflow-text-length"
                  title={
                    rowData.distdata["fname"] + " " + rowData.distdata["lname"]
                  }
                >
                  {rowData.distdata["fname"]} {rowData.distdata["lname"]}
                </Col>
              )}
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={rowData["name"]}
            >
              {rowData["name"]}
            </Col>
            {(localStorage.getItem("PERM_TYPE") == 5 ||
              localStorage.getItem("POS_MGR_EMP") == 1) &&
              this.state.searchCode != 1 && (
                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={12}
                  className="overflow-text-length"
                  title={cardName}
                >
                  {cardName}
                </Col>
              )}
            {(localStorage.getItem("PERM_TYPE") == 5 ||
              localStorage.getItem("POS_MGR_EMP") == 1) &&
              this.state.searchCode == 1 && (
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={cardName}
                >
                  {cardName}
                </Col>
              )}
            {(localStorage.getItem("PERM_TYPE") == 5 ||
              localStorage.getItem("POS_MGR_EMP") == 1) &&
              this.state.searchCode == 1 && (
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={serailNumber}
                >
                  {serailNumber}
                </Col>
              )}
            <Col lg={1} md={1} sm={1} xs={12} className="overflow-text-length">
              {rowData["amountFromate"]}
            </Col>
            <Col lg={1} md={1} sm={1} xs={12} className="overflow-text-length">
              {rowData["actualAmount"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className={classColStatus}
              title={
                localStorage.getItem("LANG") == 1
                  ? rowData["statusTitle"]
                  : rowData["statusTitle_en"]
              }
            >
              {localStorage.getItem("LANG") == 1
                ? rowData["statusTitle"]
                : rowData["statusTitle_en"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className={
                "action-icon-view actionDropdownList actionDropdownListPadding-0"
              }
            >
              <Dropdown
                isOpen={this.state.openHeader == rowData["id"] ? true : false}
                className="font-size-13 actionDropdownList actionDropdownListPadding-0 orderlistActionlist"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link pt-0 actionDropdownList  actionDropdownListPadding-0"
                  caret
                >
                  <MoreVertIcon
                    className="menu-dots-vertical actionDropdownList"
                    onClick={(event) =>
                      this.openHeaderMenu(event, rowData["id"])
                    }
                  />
                </DropdownToggle>
                <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList ">
                  <div
                    className="actionDropdownList"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    <DropdownItem
                      className="actionDropdownList"
                      onClick={(event) =>
                        this.openOrderAction(event, "view", rowData)
                      }
                    >
                      {Strings["View"]}
                    </DropdownItem>
                    {rowData["cradsInfo"].length > 0 && (
                      <DropdownItem
                        className="actionDropdownList"
                        onClick={(event) =>
                          this.openOrderAction(event, "classes", rowData)
                        }
                      >
                        {Strings["Classes"]}
                      </DropdownItem>
                    )}
                    {this.state.showExportOrder == 1 &&
                      rowData["status"] == 3 &&
                      localStorage.getItem("POS_MGR_EMP") == 0 &&
                      !(
                        localStorage.getItem("USER_LEVEL") == 3 &&
                        localStorage.getItem("ACCOUNT_TYPE") == 1
                      ) && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openOrderAction(event, "export", rowData)
                          }
                        >
                          {Strings["Export"]}
                        </DropdownItem>
                      )}
                    {this.state.showExportOrder == 1 &&
                      rowData["status"] == 3 &&
                      localStorage.getItem("POS_MGR_EMP") == 0 &&
                      !(
                        localStorage.getItem("USER_LEVEL") == 3 &&
                        localStorage.getItem("ACCOUNT_TYPE") == 1
                      ) && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openOrderAction(event, "csv", rowData)
                          }
                        >
                          CSV
                        </DropdownItem>
                      )}
                    {localStorage.getItem("PERM_TYPE") != 5 &&
                      localStorage.getItem("POS_MGR_EMP") == 0 &&
                      !(
                        localStorage.getItem("USER_LEVEL") == 3 &&
                        localStorage.getItem("ACCOUNT_TYPE") == 1
                      ) && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openOrderAction(event, "sms", rowData)
                          }
                        >
                          SMS
                        </DropdownItem>
                      )}
                    {localStorage.getItem("PERM_TYPE") != 5 &&
                      localStorage.getItem("POS_MGR_EMP") == 0 &&
                      !(
                        localStorage.getItem("USER_LEVEL") == 3 &&
                        localStorage.getItem("ACCOUNT_TYPE") == 1
                      ) && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openOrderAction(event, "cancel", rowData)
                          }
                        >
                          {Strings["Cancel"]}
                        </DropdownItem>
                      )}
                    {(localStorage.getItem("USER_LEVEL") == 2 ||
                      (localStorage.getItem("USER_LEVEL") == 3 &&
                        localStorage.getItem("ACCOUNT_TYPE") == 1)) && (
                      <DropdownItem
                        className="actionDropdownList"
                        onClick={(event) =>
                          this.openOrderAction(event, "add_problem", rowData)
                        }
                      >
                        {Strings["Add Problem"]}
                      </DropdownItem>
                    )}
                    {(localStorage.getItem("USER_LEVEL") == 2 ||
                      (localStorage.getItem("USER_LEVEL") == 3 &&
                        localStorage.getItem("ACCOUNT_TYPE") == 1)) && (
                      <DropdownItem
                        className="actionDropdownList"
                        tag="a"
                        href={
                          "/settings/orderProblem/orderProblem/" + rowData["id"]
                        }
                      >
                        {Strings["View Order Problems"]}
                      </DropdownItem>
                    )}
                  </div>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        );
      });
    }
    return orderInfo;
  }

  render() {
    return (
      <div>
        <Row className="page-filter-view">
          <Col lg={5} md={7}>
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
            >
              <Row className="filter-form">
                <Col md={10} lg={10} sm={10} xs={10}>
                  <div className="member-searchable-list mb-2">
                    <Picky
                      value={this.state.selectedPos}
                      options={this.state.posList}
                      onChange={this.handleChangeSelect.bind(this, "pos")}
                      open={false}
                      valueKey="userId"
                      labelKey="name"
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["POS"]}
                      filterPlaceholder={Strings["Select POS"]}
                    />
                  </div>
                </Col>

                <Col md={2} lg={2} sm={2} xs={2} className="mb-2">
                  <Button
                    variant="contained"
                    className="refresh-action-btn"
                    onClick={this.handleRefresh}
                    title={Strings["Refresh"]}
                  >
                    <i className="flaticon-refresh reset-filter-btn"></i>
                  </Button>
                </Col>
                {localStorage.getItem("PERM_TYPE") == 5 && (
                  <Col md={12} lg={12} sm={12} xs={12}>
                    <div className="member-searchable-list mb-2">
                      <Picky
                        value={this.state.orderPrint}
                        options={this.state.printFilters}
                        onChange={this.handleChangeSelect.bind(
                          this,
                          "orderPrint"
                        )}
                        open={false}
                        valueKey="id"
                        labelKey="name"
                        multiple={false}
                        includeSelectAll={true}
                        includeFilter={true}
                        dropdownHeight={200}
                        placeholder={`${Strings["Select"]} ${Strings["Print Status"]}`}
                        filterPlaceholder={`${Strings["Select"]} ${Strings["Print Status"]}`}
                        selectAllText={Strings["Select All"]}
                        allSelectedPlaceholder={Strings["%s selected"]}
                        manySelectedPlaceholder={Strings["%s selected"]}
                        clearFilterOnClose={true}
                      />
                    </div>
                  </Col>
                )}
                <Col md={6} lg={6} sm={6} xs={12} className="mb-2">
                  <AvField
                    name="orderFilterDateFrom"
                    type="date"
                    label={Strings["From Date"]}
                    value={this.state.orderFilterDateFrom}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={6} lg={6} sm={6} xs={12} className="mb-2">
                  <AvField
                    name="orderFilterDateTo"
                    type="date"
                    label={Strings["To Date"]}
                    value={this.state.orderFilterDateTo}
                    onChange={this.handleInputChange}
                  />
                </Col>

                <Col md={6} lg={6} sm={6} xs={12}>
                  <div className="member-searchable-list mb-2">
                    <Picky
                      value={this.state.selectedCategoryList}
                      options={this.state.categoryList}
                      onChange={this.handleChangeSelect.bind(this, "category")}
                      open={false}
                      valueKey="id"
                      labelKey={
                        localStorage.getItem("LANG") == 1 ? "name" : "name_en"
                      }
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["CategoryMain"]}
                      filterPlaceholder={Strings["Select Category"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>
                </Col>

                <Col md={6} lg={6} sm={6} xs={12}>
                  <div className="member-searchable-list mb-2">
                    <Picky
                      value={this.state.selectedCardTypeList}
                      options={this.state.cardTypeList}
                      onChange={this.handleChangeSelect.bind(this, "card")}
                      open={false}
                      valueKey="cardId"
                      labelKey={
                        localStorage.getItem("LANG") == 1 ? "name" : "name_en"
                      }
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["Card Type"]}
                      filterPlaceholder={Strings["Select Card Type"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>
                </Col>

                <Col md={6} lg={6} sm={6} xs={12}>
                  <div className="member-searchable-list mb-2">
                    <Picky
                      value={this.state.selectedStatusList}
                      options={this.state.statusList}
                      onChange={this.handleChangeSelect.bind(this, "status")}
                      open={false}
                      valueKey="id"
                      labelKey="name"
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      dropdownHeight={200}
                      placeholder={Strings["Status"]}
                      filterPlaceholder={Strings["Select Status"]}
                      selectAllText={Strings["Select All"]}
                      allSelectedPlaceholder={Strings["%s selected"]}
                      manySelectedPlaceholder={Strings["%s selected"]}
                      clearFilterOnClose={true}
                    />
                  </div>
                </Col>
                {(localStorage.getItem("PERM_TYPE") == 5 ||
                  localStorage.getItem("POS_MGR_EMP") == 1) && (
                  <Col md={6} lg={6} sm={6} xs={12}>
                    <AvField
                      name="orderFilterOrderId"
                      type="number"
                      placeholder={Strings["Order Number"]}
                      value={this.state.orderFilterOrderId}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                )}
                {localStorage.getItem("PERM_TYPE") == 5 && (
                  <Col md={6} lg={6} sm={6} xs={12}>
                    <AvField
                      name="code"
                      type="text"
                      placeholder={Strings["Code"]}
                      value={this.state.code}
                      className={
                        this.state.emptyCode == 1 && "filter-empty-email"
                      }
                      onChange={this.handleInputChange}
                    />
                  </Col>
                )}
                {localStorage.getItem("PERM_TYPE") == 5 && (
                  <Col md={6} lg={6} sm={6} xs={12}>
                    <Button
                      variant="contained"
                      className="refresh-action-btn  filter-send-email-btn"
                      onClick={this.handleSearchByCode}
                    >
                      <SearchIcon className="reset-filter-btn mr-2 ml-2" />{" "}
                      {Strings["SEARCH BY CODE"]}
                    </Button>
                  </Col>
                )}
                {(localStorage.getItem("PERM_TYPE") == 5 ||
                  localStorage.getItem("POS_MGR_EMP") == 1) && (
                  <Col md={12}>
                    <hr className="filter-hr" />
                  </Col>
                )}
                {(localStorage.getItem("PERM_TYPE") == 5 ||
                  localStorage.getItem("POS_MGR_EMP") == 1) && (
                  <Col md={6} lg={6} sm={6} xs={12}>
                    <AvField
                      name="email"
                      type="email"
                      label={Strings["Emails"]}
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      className={
                        this.state.emptyEmail == 1 && "filter-empty-email"
                      }
                    />
                    {this.state.sendEmailResult == 1 && (
                      <div className="main-color-1">
                        {Strings["We will send you this report by email"]}
                      </div>
                    )}
                  </Col>
                )}
                {(localStorage.getItem("PERM_TYPE") == 5 ||
                  localStorage.getItem("POS_MGR_EMP") == 1) && (
                  <Col md={6} lg={6} sm={6} xs={12}>
                    {this.state.loadingSave == 1 ? (
                      <Button
                        variant="contained"
                        disabled
                        className="shadow-sm refresh-action-btn refresh-btn-margin filter-send-email-btn task-action-btn-loading "
                      >
                        <EmailIcon className="reset-filter-btn mr-2 ml-2" />{" "}
                        <CircularProgress className="SaveCircularProgressColor" />{" "}
                        {Strings["Send By Email"]}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="refresh-action-btn refresh-btn-margin filter-send-email-btn"
                        onClick={this.handleSendEmail}
                      >
                        <EmailIcon className="reset-filter-btn mr-2 ml-2" />{" "}
                        {Strings["Send By Email"]}
                      </Button>
                    )}
                  </Col>
                )}
              </Row>
            </AvForm>
          </Col>
          <Col lg={7} md={5} className="text-right Export-textAlignleft">
            <Col lg={12} md={12}>
              <div className="d-flex align-items-center gap-1 flex-row-reverse">
                <button
                  className="btn page-action-btn ml-1"
                  onClick={(event) => this.exportHandler(event)}
                >
                  {Strings["Export"]}
                </button>
                <button
                  variant="contained"
                  className="btn form-save-btn ml-1 mr-1 "
                  onClick={() => this.getOrdersList()}
                >
                  {Strings["Search"]}{" "}
                  <SearchIcon className="font-size-15  main-color-2" />
                </button>
              </div>
            </Col>

            {/* <Col lg={12} md={12} sm={12} xs={12}  > */}
            {localStorage.getItem("PERM_TYPE") == 5 ||
            localStorage.getItem("POS_MGR_EMP") == 1 ||
            (localStorage.getItem("USER_LEVEL") == 3 &&
              localStorage.getItem("ACCOUNT_TYPE") == 1) ? (
              <div className="status-square-view algin-btn-account with-fit m-9-respsive">
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="task-square-div">
                      <div className="status-square-1 "></div>{" "}
                      <p className="mb-0 "> {Strings["Not Received"]} </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="task-square-div">
                      <div className="status-square-2 "></div>{" "}
                      <p className="mb-0 "> {Strings["In Proccess"]} </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="task-square-div">
                      <div className="status-square-3 "></div>{" "}
                      <p className="mb-0 "> {Strings["Complete"]} </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="task-square-div ">
                      <div className="finDistReport-square-11 "></div>{" "}
                      <p className="mb-0 ">
                        {" "}
                        {Strings["Not Completed Order"]}{" "}
                      </p>
                    </div>
                  </Col>
                </Row>
                {localStorage.getItem("VISA_PAY") == 1 && (
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <div className="task-square-div">
                        <div className="status-square-10  "></div>{" "}
                        <p className="mb-0 ">
                          {" "}
                          {Strings["Visa payment failed"]}
                        </p>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            ) : (
              <div className="status-square-view algin-btn-account m-9-respsive">
                <Row>
                  <Col lg={7} md={7} sm={7} xs={12}>
                    <div className="task-square-div">
                      <div className="status-square-1 "></div>{" "}
                      <p className="mb-0 "> {Strings["Not Received"]} </p>
                    </div>
                  </Col>
                  <Col lg={5} md={5} sm={5} xs={12}>
                    <div className="task-square-div">
                      <div className="status-square-2 "></div>{" "}
                      <p className="mb-0 "> {Strings["In Proccess"]} </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={7} md={7} sm={7} xs={12}>
                    <div className="task-square-div">
                      <div className="status-square-3 "></div>{" "}
                      <p className="mb-0 "> {Strings["Complete"]} </p>
                    </div>
                  </Col>
                  <Col lg={5} md={5} sm={5} xs={12}>
                    <div className="task-square-div">
                      <div className="status-square-4 "></div>{" "}
                      <p className="mb-0 "> {Strings["Rejected"]} </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={7} md={7} sm={7} xs={12}>
                    <div className="task-square-div">
                      <div className="status-square-6  "></div>{" "}
                      <p className="mb-0 "> {Strings["Some Card Used"]} </p>
                    </div>
                  </Col>
                  <Col lg={5} md={5} sm={5} xs={12}>
                    <div className="task-square-div">
                      <div className="status-square-5 "></div>{" "}
                      <p className="mb-0 "> {Strings["Canceled"]} </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={11} md={11} sm={11} xs={12}>
                    <div className="task-square-div ">
                      <div className="finDistReport-square-11 "></div>{" "}
                      <p className="mb-0 ">
                        {" "}
                        {Strings["Not Completed Order"]}{" "}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={11} md={11} sm={11} xs={12}>
                    <div className="task-square-div mb-0">
                      <div className="status-square-9"></div>{" "}
                      <p className="mb-0">
                        {" "}
                        {Strings["Some Cards Have Been Recovered"]}{" "}
                      </p>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                                <Col lg={12} md={12} sm={12} xs={12} >
                                    <div className='task-square-div'>
                                        <div className="status-square-8 "></div> <p className='mb-0 '> {Strings['EcoVoucher Cards Have Been Restored']} </p>
                                    </div>
                                </Col>
                            </Row> */}
              </div>
            )}
            {/* </Col> */}
          </Col>
        </Row>

        {this.state.loading == 0 && this.state.errorMsg != "" && (
          <div className="inv-cards-view pb-5">
            <div
              className="text-center"
              style={{ marginTop: "15%", marginBottom: "15%" }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">{this.state.errorMsg}</div>
            </div>
          </div>
        )}

        {this.state.loading == 0 && this.state.data.length <= 0 && (
          <div className="inv-cards-view pb-5">
            <div
              className="text-center"
              style={{ marginTop: "15%", marginBottom: "15%" }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">
                {Strings["There is no data to display"]}
              </div>
            </div>
          </div>
        )}

        {this.state.loading == 1 && (
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}

        {/* //table--start// */}
        {this.state.loading == 0 && this.state.data.length > 0 && (
          <Row>
            <div className="inv-cards-view width-100">
              <Row className="table-header-row">
                {(localStorage.getItem("PERM_TYPE") == 5 ||
                  localStorage.getItem("POS_MGR_EMP") == 1 ||
                  (localStorage.getItem("USER_LEVEL") == 3 &&
                    localStorage.getItem("ACCOUNT_TYPE") == 1)) && (
                  <Col
                    lg={1}
                    md={1}
                    sm={1}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Order #"]}
                  >
                    {" "}
                    {Strings["Order #"]}
                  </Col>
                )}
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["POS Name"]}
                >
                  {Strings["POS Name"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Payment Method"]}
                >
                  {Strings["Payment Method"]}
                </Col>
                {localStorage.getItem("PERM_TYPE") == 5 ||
                localStorage.getItem("POS_MGR_EMP") == 1 ||
                (localStorage.getItem("USER_LEVEL") == 3 &&
                  localStorage.getItem("ACCOUNT_TYPE") == 1) ? (
                  <Col
                    lg={1}
                    md={1}
                    sm={1}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Date"]}
                  >
                    {Strings["Date"]}
                  </Col>
                ) : (
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Date"]}
                  >
                    {Strings["Date"]}
                  </Col>
                )}
                {localStorage.getItem("PERM_TYPE") != 5 &&
                  localStorage.getItem("POS_MGR_EMP") == 0 && (
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Agent Name"]}
                    >
                      {Strings["Agent Name"]}
                    </Col>
                  )}
                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Card Type"]}
                >
                  {Strings["Card Type"]}
                </Col>
                {(localStorage.getItem("PERM_TYPE") == 5 ||
                  localStorage.getItem("POS_MGR_EMP") == 1) &&
                  this.state.searchCode != 1 && (
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Card"]}
                    >
                      {" "}
                      {Strings["Card"]}
                    </Col>
                  )}
                {(localStorage.getItem("PERM_TYPE") == 5 ||
                  localStorage.getItem("POS_MGR_EMP") == 1) &&
                  this.state.searchCode == 1 && (
                    <Col
                      lg={1}
                      md={1}
                      sm={1}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Card"]}
                    >
                      {" "}
                      {Strings["Card"]}
                    </Col>
                  )}
                {(localStorage.getItem("PERM_TYPE") == 5 ||
                  localStorage.getItem("POS_MGR_EMP") == 1) &&
                  this.state.searchCode == 1 && (
                    <Col
                      lg={1}
                      md={1}
                      sm={1}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Serial Number"]}
                    >
                      {" "}
                      {Strings["Serial Number"]}
                    </Col>
                  )}
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Transaction Value"]}
                >
                  {Strings["Transaction Value"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Cards Value"]}
                >
                  {Strings["Cards Value"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Status"]}
                >
                  {Strings["Status"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Action"]}
                >
                  {" "}
                  {Strings["Action"]}
                </Col>
              </Row>
              {this.displayData()}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Col md={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              )}
            </div>
          </Row>
        )}

        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openOrderAction}
          className={
            this.state.actionType == "viewClasses" &&
            (localStorage.getItem("PERM_TYPE") == 5 ||
              localStorage.getItem("POS_MGR_EMP") == 1)
              ? "sidebar-modal-x-large-view"
              : "sidebar-modal-view"
          }
        >
          {this.state.actionType == "viewClasses" &&
            localStorage.getItem("PERM_TYPE") != 5 &&
            localStorage.getItem("POS_MGR_EMP") == 0 && (
              <ViewClasses
                orderRowData={this.state.orderRowData}
                handleCloseSideBar={this.handleCloseSideBar}
                recallGetData={this.recallGetData}
              />
            )}
          {this.state.actionType == "viewClasses" &&
            (localStorage.getItem("PERM_TYPE") == 5 ||
              localStorage.getItem("POS_MGR_EMP") == 1) && (
              <ViewClassesPos
                orderRowData={this.state.orderRowData}
                handleCloseSideBar={this.handleCloseSideBar}
                recallGetData={this.recallGetData}
              />
            )}
          {this.state.actionType == "viewOrder" && (
            <ViewOrder
              orderRowData={this.state.orderRowData}
              handleCloseSideBar={this.handleCloseSideBar}
              showExportOrder={this.state.showExportOrder}
              recallGetData={this.recallGetData}
            />
          )}
          {this.state.actionType == "add_problem" && (
            <AddProblem
              orderRowData={this.state.orderRowData}
              handleCloseSideBar={this.handleCloseSideBar}
            />
          )}
        </Drawer>
      </div>
    );
  }
}
export default OrdersList;

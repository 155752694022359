import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from '../../../../translate.js';

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;



class AddSellingPrice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: '',
            price: this.props.cardsCalssData.buyPriceCurr, // buy price
            // costPrice: this.props.cardsCalssData.price, // cost price
            costPrice: this.props.cardsCalssData.priceFormat, // cost price
            distId:this.props.distId?this.props.distId:''
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        // console.log(this.props.cardsCalssData);
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value,
            submitError: ''
        })
    }

    handleValidSubmit(event) {
        // console.log(this.props.cardsCalssData);
        event.preventDefault();
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'updateDistCardPrice',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'cardClassId': this.props.cardsCalssData.classId,
                'price': this.state.price,
                distId:this.state.distId,
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 1) {
                    this.setState({ submitError: data['errorMsg'] })
                } else {
                    this.props.recallGetData();
                    this.props.handleCloseSideBar()
                }
            }
        })
    }


    render() {
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col lg={10} md={10} sm={10} xs={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'>
                                {Strings['Edit Selling Price']}
                                ({this.props.cardsCalssData['cardClassName']})
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={2} xs={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>

                <div className='sidebar-body-view'>
                    <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                        <AvField name="price" type='number' label={Strings['Price'] + ' *'} placeholder={Strings['Enter Price']} value={this.state.price} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                        {/* {parseFloat(this.state.price) > parseFloat(this.state.costPrice) &&
                            <div className='error-text-black'><i className='flaticon-information  mr-1 info-icon' /> {Strings['Insert Price Less Than Or Equal Cost Price']}</div>
                        } */}
                        {parseFloat(this.state.price) < parseFloat(this.state.costPrice) &&
                            <div className='error-text-black'><i className='flaticon-information  mr-1 info-icon' /> {Strings['Insert Price Greater Than Or Equal Cost Price']}</div>
                        }
                        <div className='text-right mt-4'>
                            <button type="submit" className="btn form-save-btn mr-1 ml-1"> {Strings['Save']} </button>
                            <button type="button" className="btn form-cancel-btn m-l-10" onClick={this.props.handleCloseSideBar}>{Strings['Cancel']}</button>
                        </div>

                        {this.state.submitError &&
                            <div className='error-text-black'><i className='flaticon2-exclamation error-icon mr-1' /> {this.state.submitError}</div>
                        }

                        {this.state.status &&
                            <div className='error-text-black'><i className='flaticon2-check-mark success-icon mr-1' /> {this.state.status}</div>
                        }

                    </AvForm>
                </div>
            </div>
        )
    }
}
export default AddSellingPrice;
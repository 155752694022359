import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import { useDispatch, useSelector } from "react-redux";
import { translations } from "../../../../translate.js";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
let today = new Date();
let currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);

function AddOrGetAmount(props) {
  const dispatch = useDispatch();
  const [currencyList, setCurrencyList] = useState([]);
  const [posUsers, setPosUsers] = useState([]);
  const [selectedPointOfSales, setSelectedPointOfSales] = useState([]);
  const [selectedPointOfSalesId, setSelectedPointOfSalesId] = useState("");
  const [submitResult, setSubmitResult] = useState([]);
  var currencyIdInitial =
    localStorage.getItem("PERM_TYPE") == 5 ||
    props.permType == 5 ||
    localStorage.getItem("OPERA_EMP") == 1 ||
    localStorage.getItem("POS_MGR_EMP") == 1
      ? localStorage.getItem("CURRENCY_ID")
      : 0;
  const [formView, setFormView] = useState(1);
  const [msgError, setMsgError] = useState(0);
  var empNameInitial =
    props.mainAccount != 1
      ? " (" + props.posData["fname"] + " " + props.posData["lname"] + ") "
      : "";
  const [empName, setEmpName] = useState(empNameInitial);
  const [startSave, setStartSave] = useState(0);
  const [submitError, setSubmitError] = useState("");
  const [state, setState] = useState({
    posBalanceDate: currentDate,
    typeBalance: "",
    posAmount: "",
    currencyId: currencyIdInitial,
    note1: "",
    note2: "",
  });

  useEffect(() => {
    // console.log("update submit resuilt ^^ ");
    localStorage.getItem("USER_LEVEL") != 1 && getUserBalance();
  }, [submitResult]);

  useEffect(() => {
    getCurrencies();
    if (props.mainAccount == 1) {
      getPosUsers();
    }
  }, []);
  //###################### start api section ####################
  const getPosUsers = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistPos",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        getType: "all",
        withoutCount: 1,
        distId: props.distId ? props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            setPosUsers(data["data"]);
          }
        }
      });
  };
  const getCurrencies = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCurrencies",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        // distId: props.distId ? props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            setCurrencyList(data);
          }
        }
      });
  };
  const handleValidSubmit = (event) => {
    if (checkValidation) {
      var posId =
        props.mainAccount == 1
          ? selectedPointOfSalesId
          : props.posData["userId"];
      setStartSave(1);
      event.preventDefault();
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "addPosBalance",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          typeBalance: state.typeBalance,
          currencyId: state.currencyId,
          note1: state.note1,
          note2: state.note2,
          posAmount: state.posAmount,
          posBalanceDate: state.posBalanceDate, //
          empId: props.pageView != 1 ? 0 : posId,
          posId: props.pageView != 1 ? posId : 0,
          distId: props.distId ? props.distId : "",
          // 'posUSer': this.state.selectedPointOfSalesId,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else if (data["error"] == 0) {
              // console.log("error 0 ");
              setStartSave(0);
              setSubmitResult(data["data"]);
              setFormView(0);
              props.recallGetData();
            } else {
              setStartSave(0);
              setSubmitError(data["errorMsg"]);
            }
          }
        });
    }
  };
  const getUserBalance = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getUserBalance",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: props.distId ? props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            localStorage.setItem("USER_BALANCE_FROM", "redux");
            // console.log("call distpatch");
            dispatch({
              type: "UPDATEBALANCE",
              payload: data?.balanceCurr ? data?.balanceCurr : "0",
            });
          }
        }
      });
  };
  //###################### end api section ####################
  //###################### start react section ####################
  const checkValidation = () => {
    if (state.posAmount == "" || state.posAmount <= 0) {
      setSubmitError(Strings["Please enter a valid amount"]);
      return 0;
    } else if (props.mainAccount == 1 && selectedPointOfSalesId == "") {
      setMsgError(1);
      return 0;
    } else if (state.typeBalance == 0) {
      setSubmitError(Strings["Please select a type of operation"]);
      return 0;
    }
    return 1;
  };
  const setUpSelectCurrencyOptions = (data, type) => {
    if (type == "currency") {
      return (
        <option value={data.id} key={data.id}>
          {data.currency}
        </option>
      );
    }
  };
  function handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setSubmitError("");
    setState({
      ...state,
      [name]: value,
    });
  }
  const handleChangePos = (option) => {
    setSelectedPointOfSales(option);
    setSelectedPointOfSalesId(option["userId"]);
    setMsgError(0);
  };

  const handelClose = () => {
    if (localStorage.getItem("OPERA_EMP") == 1) {
      props.handleCloseSideBar(false, "add_balance");
    } else {
      props.handleCloseSideBar();
    }
  };
  const form = React.createRef();
  return (
    <div className="view-modal-style">
      <div className="sidebar-header-view">
        <Row>
          <Col md={10} xs={10} lg={10} sm={10}>
            <div className="p-0 main-color-1  sidebar-header-title">
              {" "}
              {localStorage.getItem("POS_MGR_EMP") == 1 ||
              (localStorage.getItem("OPERA_EMP") == 1 && props.mainAccount != 1)
                ? Strings["Add Amount"]
                : localStorage.getItem("OPERA_EMP") == 1 &&
                  props.mainAccount == 1
                ? Strings["Add Balance to POS"]
                : Strings["Add / Get Amount"]}
              {empName}
            </div>
          </Col>
          <Col md={2} xs={2} lg={2} sm={2} className="text-right">
            <HighlightOffIcon
              onClick={handelClose}
              className="modal-close-icon"
            />
          </Col>
        </Row>
      </div>

      <div className="sidebar-body-view">
        {formView == 1 && (
          <AvForm onValidSubmit={handleValidSubmit} ref={form}>
            {props.mainAccount == 1 && (
              <div className=" mb-3">
                <label className="font-w-400">
                  {Strings["Points Of Sales"]}
                </label>
                <Picky
                  value={selectedPointOfSales}
                  options={posUsers}
                  onChange={handleChangePos}
                  open={false}
                  valueKey="userId"
                  labelKey={
                    localStorage.getItem("PERM_TYPE") == 5
                      ? "fullNameWithCurr"
                      : "fullName"
                  }
                  multiple={false}
                  includeSelectAll={true}
                  includeFilter={true}
                  dropdownHeight={200}
                  placeholder={Strings["Points Of Sales"]}
                  filterPlaceholder={Strings["Select Point Of Sales"]}
                  selectAllText={Strings["Select All"]}
                  allSelectedPlaceholder={Strings["%s selected"]}
                  manySelectedPlaceholder={Strings["%s selected"]}
                  clearFilterOnClose={true}
                />
                {msgError == 1 && (
                  <div className="msg-error">
                    {Strings["This field is required"]}
                  </div>
                )}
              </div>
            )}
            <AvField
              name="posBalanceDate"
              type="date"
              label={Strings["Date"] + " *"}
              value={state.posBalanceDate}
              required
              onChange={handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              type="select"
              label={Strings["Type Of Operation"] + " *"}
              name="typeBalance"
              value={state.typeBalance}
              onChange={handleInputChange}
              className="p-1 pl-3"
              errorMessage={Strings["This field is required"]}
            >
              <option style={{ display: "none" }} value="" selected="selected">
                {Strings["Select Type Of Operation"]}
              </option>
              <option value="1">{Strings["Add"]}</option>
              {localStorage.getItem("POS_MGR_EMP") == 0 &&
                localStorage.getItem("OPERA_EMP") == 0 && (
                  <option value="2">{Strings["Get"]}</option>
                )}
            </AvField>
            <AvField
              disabled={
                localStorage.getItem("PERM_TYPE") == 5 ||
                localStorage.getItem("POS_MGR_EMP") == 1 ||
                localStorage.getItem("OPERA_EMP") == 1
                  ? true
                  : false
              }
              type="select"
              name="currencyId"
              label={Strings["Currency"] + " *"}
              value={state.currencyId}
              required
              onChange={handleInputChange}
              className="p-1 pl-3"
              errorMessage={Strings["This field is required"]}
            >
              <option
                style={{
                  display: "none",
                }}
                value=""
                selected="selected"
              >
                {Strings["Select currency"]}
              </option>
              {currencyList.map((data) =>
                setUpSelectCurrencyOptions(data, "currency")
              )}
            </AvField>
            <AvField
              name="posAmount"
              type="number"
              label={Strings["Amount"] + " *"}
              placeholder={Strings["Enter Amount"]}
              value={state.posAmount}
              onChange={handleInputChange}
              errorMessage={Strings["This field is required"]}
              validate={{
                required: {
                  value: true,
                  errorMessage: Strings["This field is required"],
                },
                min: { value: 0.1, errorMessage: Strings.invalidNumber },
              }}
            />
            <AvField
              name="note1"
              type="textarea"
              label={Strings["Note"] + "(1)"}
              value={state.note1}
              onChange={handleInputChange}
            />
            <AvField
              name="note2"
              type="textarea"
              label={Strings["Note"] + "(2)"}
              value={state.note2}
              onChange={handleInputChange}
            />
            {submitError && (
              <div className="error-text-black mt-3">
                <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                {submitError}
              </div>
            )}
            <div className="text-right mt-4">
              {startSave == 0 ? (
                <button type="submit" className="btn form-save-btn mr-1 ml-1">
                  {" "}
                  {Strings["Save"]}{" "}
                </button>
              ) : (
                <button type="button" className="btn form-save-btn">
                  {Strings["Save"]}
                </button>
              )}
              <button
                type="button"
                className="btn form-cancel-btn m-l-10"
                onClick={handelClose}
              >
                {Strings["Cancel"]}
              </button>
            </div>
          </AvForm>
        )}

        {formView == 0 && (
          <div className="view-purchase-main-fields">
            <div className="mb-4 view-purchase-main-fields-row-color-2">
              {state.typeBalance == 1 ? (
                <Col
                  md={12}
                  xs={12}
                  lg={12}
                  sm={12}
                  className="main-font-1 font-w-500 f-15"
                >
                  {Strings["Addition completed successfully"]}
                </Col>
              ) : (
                <Col
                  md={12}
                  xs={12}
                  lg={12}
                  sm={12}
                  className="main-font-1 font-w-500 f-15"
                >
                  {Strings["Correction completed successfully"]}
                </Col>
              )}
            </div>
            <div className="mb-4 view-purchase-main-fields-row">
              <Col
                md={12}
                xs={12}
                lg={12}
                sm={12}
                className="main-font-1 font-w-500"
              >
                {props.pageView != 1
                  ? Strings["POS Name"]
                  : Strings["POS Manager Name"]}
              </Col>
              <Col md={12} xs={12} lg={12} sm={12}>
                {submitResult.employeeName}
              </Col>
            </div>
            <div className="mb-4 view-purchase-main-fields-row">
              <Col
                md={12}
                xs={12}
                lg={12}
                sm={12}
                className="main-font-1 font-w-500"
              >
                {Strings["Amount"]}
              </Col>
              <Col md={12} xs={12} lg={12} sm={12}>
                {submitResult.amount} {submitResult.symbol}
              </Col>
            </div>
            {state.typeBalance == 1 && (
              <div className="mb-4 view-purchase-main-fields-row">
                <Col
                  md={12}
                  xs={12}
                  lg={12}
                  sm={12}
                  className="main-font-1 font-w-500"
                >
                  {Strings["Operation Number"]}
                </Col>
                <Col md={12} xs={12} lg={12} sm={12}>
                  {submitResult.serialNumber}
                </Col>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AddOrGetAmount;

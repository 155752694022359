import React, { Component } from "react";
import {
  Row,
  Col,
  CustomInput,
  Progress,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Link, withRouter } from "react-router-dom";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button, Drawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Pagination from "react-js-pagination";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ViewOrder from "./forms/viewOrder";
import ViewClasses from "./forms/viewClasses";
import ViewClassesPos from "./forms/viewClassesPos";
import { CircularProgress } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import { translations } from "../../../translate.js";
import SearchIcon from "@material-ui/icons/Search";
import AddProblem from "./forms/addProblem";
import { func } from "prop-types";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
var today = new Date();
var currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);
const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const getMinDate = (POS_DAYS_NUM) => {
  const today = new Date();
  // Adjust the date by subtracting POS_DAYS_NUM days
  const pastDate = new Date(today);
  pastDate.setDate(today.getDate() - POS_DAYS_NUM);

  // Ensure proper formatting
  const pastYear = pastDate.getFullYear();
  const pastMonth = String(pastDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const pastDay = String(pastDate.getDate()).padStart(2, "0");

  return `${pastYear}-${pastMonth}-${pastDay}`;
};

class OrderDetailsPOS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOrderAction: false,
      orderRowData: [],
      posList: [],
      posCodeError: "",
      selectedPos: [],
      loading: 1,
      posCode: "",
      modal: false,
      statusList: [],
      categoryList: [],
      selectedCategoryList: [],
      cardTypeList: [],
      orderFilterOrderId:
        this.props.match.params.orderId == undefined
          ? ""
          : this.props.match.params.orderId,
      selectedCardTypeList: [],
      selectedStatusList: [],
      data: [],
      dataCount: 0,
      showExportOrder: localStorage.getItem("SHOW_ORDER_EXPORT") == 1,
      limit: 25,
      activePage: 1,
      emptyEmail: 0,
      loadingSave: 0,
      searchCode: 0,
      mainDist:
        localStorage.getItem("PERM_TYPE") == 5 ||
        localStorage.getItem("POS_MGR_EMP") == 1
          ? 0
          : 1,
      errorMsg: "",
      actionType: "",
      orderFilterDateFrom: currentDate,
      orderFilterDateTo: currentDate,
      transactionsType: 1,
      transactionsTypes: [
        { id: 1, title: Strings["Cards"] },
        { id: 2, title: Strings["Transactions"] },
      ],
      info: {
        totalQty: 0,
        totalAmount: 0,
        totalAmountFormat: "",
        finalBalance: "",
        finalBalanceFormat: "",
      },
      currency: "",
      accountTransaction: [],
      cards: [],
    };
  }
  handleRefresh = () => {
    // clear all filter data
    this.setState(
      {
        selectedPos: [],
        selectedCategoryList: [],
        selectedCardTypeList: [],
        selectedStatusList: [],
        orderFilterOrderId: "",
        searchCode: 0,
        orderFilterDateFrom: currentDate,
        orderFilterDateTo: currentDate,
      },
      function() {
        this.getOrdersList();
      }
    );
  };
  componentDidMount() {
    this.getOrdersList();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
        });
      }
    }
  };
  setUpSelectOptions = (data, type) => {
    return (
      <option value={data.id} key={data.id}>
        {data.title}
      </option>
    );
  };
  getOrderStatus() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getOrderStatus",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              statusList: data["data"],
            });
          }
        }
      });
  }
  getCategoryList() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCategories",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        mainDist: this.state.mainDist,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              categoryList: data,
            });
          }
        }
      });
  }

  getOrdersList(withEmail = 0, seacrhCode = 0) {
    console.log("GOOOOOOOOOOOO!");
    let timeDef =
      new Date(this.state.orderFilterDateTo) -
      new Date(this.state.orderFilterDateFrom);
    let daysCount = Math.floor(timeDef / (1000 * 60 * 60 * 24));
    console.log("daysCount:", daysCount);
    if (daysCount <= 7 && daysCount >= 0) {
      this.setState({ sendEmailResult: 0 });
      var sendByMail = "";
      var email = "";
      if (withEmail == 1) {
        sendByMail = 1;
        email = this.state.email;
        this.setState({ loadingSave: 1 });
      } else {
        this.setState({ loading: 1 });
      }
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getOrderInfo",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          orderId: this.props.id,
          print: 1,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else if (data["error"] == 1) {
              this.setState({ errorMsg: data["errorMsg"], showExportOrder: 0 });
            } else {
              console.log(data, "data");
              this.setState({
                loading: 0,
                resData: data,
                invoicePassword: data.invoicePassword,
              });
            }
          }
        });
      this.setState({
        loading: 0,
      });
    } else {
      this.setState({
        loading: 0,
      });
    }
  }

  exportHandler() {
    this.printPdf_CSV(this.props.id, 1);
  }

  handleChangeSelect = (type, option) => {
    if (type == "pos") {
      this.setState(
        (state) => {
          return {
            selectedPos: option,
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
        }
      );
    } else if (type == "category") {
      this.setState(
        (state) => {
          return {
            selectedCategoryList: option,
            selectedCardTypeList: [],
            cardTypeList: [],
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
          // this.getCardsList();
        }
      );
    } else if (type == "card") {
      this.setState(
        (state) => {
          return {
            selectedCardTypeList: option,
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
        }
      );
    } else if (type == "status") {
      this.setState(
        (state) => {
          return {
            selectedStatusList: option,
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
        }
      );
    }
  };
  getSelected = (type) => {
    if (type == "pos") {
      return this.state.selectedPos.map((data) => data.userId);
    }
    if (type == "category") {
      return this.state.selectedCategoryList.map((data) => data.id);
    }
    if (type == "card") {
      return this.state.selectedCardTypeList.map((data) => data.cardId);
    }
    if (type == "status") {
      return this.state.selectedStatusList.map((data) => data.id);
    }
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (name == "email") {
      this.setState({
        [name]: value,
        emptyEmail: 0,
      });
    } else if (name == "code") {
      this.setState({
        [name]: value,
        emptyCode: 0,
      });
    } else {
      this.setState(
        {
          [name]: value,
          activePage: 1,
        },
        function() {
          // this.getOrdersList();
        }
      );
    }
  };

  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };
  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };

  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };

  openOrderAction = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (type == "view") {
      this.setState({
        orderRowData: data,
        openOrderAction: true,
        actionType: "viewOrder",
      });
    } else if (type == "export") {
      this.printPdf_CSV(data["id"], 1);
    } else if (type == "csv") {
      this.printPdf_CSV(data["id"], 2);
    } else if (type == "sms") {
      this.sendSms(data["id"]);
    } else if (type == "cancel") {
      this.cancelOrder(data["id"]);
    } else if (type == "classes") {
      this.setState({
        orderRowData: data,
        openOrderAction: true,
        actionType: "viewClasses",
      });
    } else if (type == "add_problem") {
      this.setState({
        actionType: type,
        openOrderAction: true,
        orderRowData: data,
      });
    }
  };

  printPdf_CSV(id, typeExport) {
    /*
            typeExport >> 1 : pdf
                          2 : csv
        */

    window.open(
      window.API_PERM_USER +
        "/permUser.php?type=" +
        "printPDFOrCSVInvoiceToCustomer" +
        "&lang=" +
        localStorage.getItem("LANG") +
        "&userId=" +
        localStorage.getItem("USER_ID") +
        "&token=" +
        localStorage.getItem("USER_TOKEN") +
        "&orderId=" +
        id +
        "&typeExport=" +
        typeExport,
      "_blank"
    );
  }

  sendSms(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to send sms ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickSendSms(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  }

  handleClickSendSms(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "sendSMSToCustomer",
        orderId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <div className="mb-3">
                      <span className="alert-delete-icon">!</span>
                    </div>
                    <p>{data["errorMsg"]}</p>
                    <button
                      type="button"
                      className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                      onClick={onClose}
                    >
                      {Strings.Cancel}
                    </button>
                  </div>
                );
              },
            });
          }
        }
      });
  }

  cancelOrder(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view alertBoxResopnsive">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to cancel order ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickCancelOrder(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  }

  handleClickCancelOrder(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "cancelOrderById",
        orderId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
            this.getOrdersList();
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <div className="mb-3">
                      <span className="alert-delete-icon">!</span>
                    </div>
                    <p>{data["errorMsg"]}</p>
                    <button
                      type="button"
                      className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                      onClick={onClose}
                    >
                      {Strings.Cancel}
                    </button>
                  </div>
                );
              },
            });
          }
        }
      });
  }
  handleSendEmail = () => {
    if (
      this.state.email != undefined &&
      this.state.email != null &&
      this.state.email != ""
    ) {
      this.getOrdersList(1);
      this.setState({ emptyEmail: 0 });
    } else {
      this.setState({ emptyEmail: 1 });
    }
  };
  handleSearchByCode = () => {
    if (
      this.state.code != undefined &&
      this.state.code != null &&
      this.state.code != ""
    ) {
      this.setState({ emptyCode: 0, searchCode: 1 }, function() {
        this.getOrdersList(0, 1);
      });
    } else {
      this.setState({ emptyCode: 1 });
    }
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getOrdersList();
    });
  };

  handleCloseSideBar = () => {
    this.setState({ openOrderAction: false, orderRowData: [] });
  };

  recallGetData = () => {
    this.setState(
      {
        openOrderAction: false,
        orderRowData: [],
      },
      function() {
        this.getOrdersList();
      }
    );
  };
  displayData() {
    var orderInfo = "";
    var classStyle = {
      "text-1": "gray-color",
      "text-2": "warning-color",
      "text-3": "succ-color",
      "text-4": "reject-color",
      "text-5": "addCredit-color",
      "text-6": "wthdrawal-color",
      "text-7": "profit-color",
      "text-8": "status-8-color",
      "text-9": "status-9-color",
      "text-11": "pos-correct-mng-color",
    };
    var classColStatus = "";
    var cardName = "";
    var serailNumber = "";
    if (this.state.loading == 0 && this.state.resData?.cards?.length > 0) {
      orderInfo = this.state.resData?.cards.map((rowData, index) => {
        if (rowData["status"] !== "") {
          classColStatus = classStyle["text-" + rowData["status"]];
        }
        classColStatus =
          " overflow-text-length  bold-font " + classColStatus + " ";
        rowData["actualAmount"] =
          rowData["actualAmount"] == "" ? 0 : rowData["actualAmount"];
        cardName =
          localStorage.getItem("LANG") == 1
            ? rowData["cardName_ar"]
            : rowData["cardName_en"];
        serailNumber =
          rowData["billNumber"] != undefined && rowData["billNumber"] != ""
            ? rowData["billNumber"]
            : "-";
        return (
          <Row key={index} className="table-body-row">
            {/* {this.state.transactionsType == 2 && (
              <Col
                lg={2}
                md={2}
                sm={2}
                xs={12}
                className="overflow-text-length"
                title={Strings["Type Of Operation"]}
              >
                {rowData.type == 1 ? Strings["charge"] : Strings["Withdraw"]}
              </Col>
            )} */}
            {localStorage.getItem("SHOW_ORDER_ID_POS") == 1 && (
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={12}
                className="overflow-text-length"
                title={Strings["Order #"]}
              >
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["Order #"]} :{" "}
                </span>
                {this.state.resData.orderId}
              </Col>
            )}
           
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={Strings["Card"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Card"]} :{" "}
              </span>
              {rowData.name}
            </Col>
            {/* <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={Strings["Class"]}
            >
              {rowData.cardClass}
            </Col> */}

            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={Strings["Date"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Date"]} :{" "}
              </span>
              {this.state.resData?.orderDate11}
            </Col>

            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length"
              title={Strings["Value"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Value"]} :{" "}
              </span>
              {rowData.value}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="overflow-text-length"
              title={Strings["Code"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Code"]} :{" "}
              </span>
              {rowData.code}
            </Col>
            {localStorage.getItem("SHOW_ORDER_ID_POS") == 1 && (
              <Col
                lg={2}
                md={2}
                sm={2}
                xs={12}
                className="overflow-text-length"
                title={Strings["billingNumber"]}
              >
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["billingNumber"]} :{" "}
                </span>
                {rowData.billNumber?rowData.billNumber:'--'}
              </Col>
            )}
            {this.state.showExportOrder == 1 && (
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={12}
                className="overflow-text-length"
                title={Strings["Invoice Password"]}
              >
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["Invoice Password"]} :{" "}
                </span>
                {this.state.invoicePassword}
              </Col>
            )}
          </Row>
        );
      });
    }
    console.log("orderInfo", orderInfo);
    return orderInfo;
  }

  render() {
    return (
      <div>
        {this.state.showExportOrder == 1 && (
          <Row className="page-filter-view">
            <Col lg={5} md={7}></Col>
            <Col lg={7} md={5} className="text-right Export-textAlignleft">
              <Col lg={12} md={12}>
                <div className="d-flex align-items-center gap-1 flex-row-reverse">
                  <button
                    className="btn page-action-btn ml-1"
                    onClick={(event) => this.exportHandler(event)}
                  >
                    {Strings["Export"]}
                  </button>
                </div>
              </Col>
              <Row className="mr-1 ml-1">
                <Col md={4} lg={6} sm={12} xs={12}></Col>
              </Row>
            </Col>
          </Row>
        )}

        {this.state.loading == 0 && this.state.errorMsg != "" && (
          <div className="inv-cards-view pb-5">
            <div
              className="text-center"
              style={{ marginTop: "15%", marginBottom: "15%" }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">{this.state.errorMsg}</div>
            </div>
          </div>
        )}

        {this.state.loading == 0 && this.state.resData?.cards?.length == 0 && (
          <div className="inv-cards-view pb-5">
            <div
              className="text-center"
              style={{ marginTop: "15%", marginBottom: "15%" }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">
                {Strings["There is no data to display"]}
              </div>
            </div>
          </div>
        )}

        {this.state.loading == 1 && (
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}

        {/* //table--start// */}
        {this.state.loading == 0 && this.state.resData?.cards?.length > 0 && (
          <Row>
            <div className="inv-cards-view width-100">
              <Row className="table-header-row d-none d-md-flex d-lg-flex">
                {localStorage.getItem("SHOW_ORDER_ID_POS") == 1 && (
                  <Col
                    lg={1}
                    md={1}
                    sm={1}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Order #"]}
                  >
                    {Strings["Order #"]}
                  </Col>
                )}
             
                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Card"]}
                >
                  {Strings["Card"]}
                </Col>

                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Date"]}
                >
                  {Strings["Date"]}
                </Col>

                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Value"]}
                >
                  {Strings["Value"]}
                </Col>
                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Code"]}
                >
                  {Strings["Code"]}
                </Col>
                {localStorage.getItem("SHOW_ORDER_ID_POS") == 1 && (
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["billingNumber"]}
                  >
                    {Strings["billingNumber"]}
                  </Col>
                )}
                {this.state.showExportOrder == 1 && (
                  <Col
                    lg={1}
                    md={1}
                    sm={1}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Invoice Password"]}
                  >
                    {Strings["Invoice Password"]}
                  </Col>
                )}
              </Row>
              {this.displayData()}
              {this.state.loading == 0 && this.state.data?.length > 0 && (
                <Col md={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              )}
            </div>
          </Row>
        )}
      </div>
    );
  }
}
export default withRouter(OrderDetailsPOS);

import React, { useState } from "react";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { translations } from "../../../translate.js";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

export default function ChangePasswordComponent() {
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [error, setError] = useState("");

  var form;

  const handleValidSubmit = async () => {
    try {
      setLoading(true);
      let response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "changePassExpiry",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          confirmNewPassword: newPassword2,
          oldPassword,
          newPassword,
        }),
        headers: {
          Accept: "application/json",
        },
      });
      if (response.status == 200) {
        let res = await response.json();
        console.log("FILTERS_RESPONSE", res);
        if (res.logout == 1) {
          localStorage.setItem("USER_ID", "");
          localStorage.setItem("USER_TOKEN", "");
          window.location.href = "/logout";
        } else {
          if (res.error == 1) {
            setError(res.errorMsg);
          } else {
            window.location = "/dashboard";
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-form-view-padding">
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3 className="main-color-1">{Strings["Update Your Password"]}</h3>
            <hr className={"login-hr-view-1"} />
          </div>
          {!loading && (
            <AvForm
              onValidSubmit={handleValidSubmit}
              ref={(c) => (form = c)}
              className="login-form-input main-color-1"
            >
              <AvField
                type="password"
                name="oldPassword"
                label={Strings["Old Password"]}
                placeholder={Strings["Enter password"]}
                value={oldPassword}
                required
                onChange={(e) => setOldPassword(e.target.value)}
                errorMessage={Strings["This field is required"]}
              />
              <AvField
                type="password"
                name="newPassword"
                label={Strings["New Password"]}
                placeholder={Strings["Enter password"]}
                value={newPassword}
                required
                onChange={(e) => setNewPassword(e.target.value)}
                errorMessage={Strings["This field is required"]}
              />
              <AvField
                type="password"
                name="newPassword2"
                label={Strings["Confirm New Password"]}
                placeholder={Strings["Enter password"]}
                value={newPassword2}
                required
                onChange={(e) => setNewPassword2(e.target.value)}
                errorMessage={Strings["This field is required"]}
                validate={{
                  match: {
                    value: "newPassword",
                    errorMessage: Strings["Password Not Match"],
                  },
                  required: {
                    value: true,
                    errorMessage: Strings["This field is required"],
                  },
                }}
              />
              <button
                type="submit"
                className="btn gradient-button-view gradient-button ml-1"
              >
                {Strings["Confirm"]}
              </button>

              <div>
                {error && (
                  <div className="error-text-black">
                    <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                    {error}
                  </div>
                )}
              </div>
            </AvForm>
          )}
          {loading && (
            <div className="card-body">
              <div
                className="spinner-grow text-dark m-2 main-color-1"
                role="status"
              ></div>
              <div
                className="spinner-grow text-dark m-2 main-color-2"
                role="status"
              ></div>
              <div
                className="spinner-grow text-dark m-2 main-color-1"
                role="status"
              ></div>
              <div
                className="spinner-grow text-dark m-2 main-color-2"
                role="status"
              ></div>
              <div
                className="spinner-grow text-dark m-2 main-color-1"
                role="status"
              ></div>
              <div
                className="spinner-grow text-dark m-2 main-color-2"
                role="status"
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import "react-confirm-alert/src/react-confirm-alert.css";
import { translations } from "../../../../translate.js";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class ViewOrderAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      errorMsg: "",
      orderData: this.props.orderRowData,
      showExportOrder:
        this.props.viewPage == undefined ? this.props.showExportOrder : 0,
    };
  }
  displayCardClass = () => {
    var list = [];
    this.state.orderData.cardsClassesInfo.map((rowData, index) => {
      list.push(
        <Row key={index} className="table-body-row">
          <Col
            lg={6}
            md={6}
            sm={6}
            xs={12}
            title={rowData["cardClassName"]}
            className="overflow-text-length main-color-1 font-w-400"
          >
            {rowData["cardClassName"]}
          </Col>
          <Col
            lg={2}
            md={2}
            sm={2}
            xs={12}
            title={rowData["value"]}
            className="overflow-text-length"
          >
            {rowData["value"]}
          </Col>
          <Col
            lg={2}
            md={2}
            sm={2}
            xs={12}
            title={rowData["cardsCount"]}
            className="overflow-text-length"
          >
            {rowData["cardsCount"]}
          </Col>
          <Col
            lg={2}
            md={2}
            sm={2}
            xs={12}
            title={rowData["finalValue"]}
            className="overflow-text-length"
          >
            {rowData["finalValue"]}
          </Col>
        </Row>
      );
    });
    return list;
  };
  displayIdentifierDomainArray = () => {
    let list = [];
    this.state.orderData.identifierDomainArray.map((rowData, index) => {
      console.log(rowData);
      list.push(
        <li>
          {rowData["name"]} : {rowData["refId"]}
        </li>
      );
    });
    return list;
  };
  displayDirectChargeData = () => {
    let list = [];
    this.state.orderData.directCharge_data.map((rowData, index) => {
      list.push(
        <li>
          {rowData["name"]} :{" "}
          {typeof rowData["value"] == "string" ? rowData["value"] : "-"}
        </li>
      );
    });
    return list;
  };
  render() {
    console.log("orderData", this.state.orderData);
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {Strings["View Order"]}
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>
        <div className="sidebar-body-view">
          <div className="view-purchase-main-fields">
            <div className="mb-4 view-purchase-main-fields-row">
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="main-font-1 font-w-500"
              >
                {Strings["Order Number"]}
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                {this.state.orderData.orderId}
              </Col>
            </div>

            {this.state.orderData.distNameFull != undefined &&
              this.state.orderData.distNameFull != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Distributor Name"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.orderData.distNameFull}
                  </Col>
                </div>
              )}
            {this.state.orderData.sourceOrder != undefined &&
              this.state.orderData.sourceOrder != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Order Type"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {localStorage.getItem("LANG") == 1
                      ? this.state.orderData.sourceOrder_ar
                      : this.state.orderData.sourceOrder_en}
                    {" - "}
                    {localStorage.getItem("LANG") == 1
                      ? this.state.orderData["transPaymentType_ar"]
                      : this.state.orderData["transPaymentType_en"]}
                  </Col>
                </div>
              )}
            {this.state.orderData.makerName != undefined &&
              this.state.orderData.makerName != "" &&
              this.state.orderData.makerName != "-" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Order Creator"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.orderData.makerName}
                  </Col>
                </div>
              )}
            {this.state.orderData.sourceOrder == 2 && this.props.orderRowData.printStatus != undefined &&
              this.props.orderRowData.printStatus != "" &&
              this.props.orderRowData.printStatus != "-" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Print Status"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.props.orderRowData.printStatus}
                  </Col>
                </div>
              )}
            {this.state.orderData.makerPhone != undefined &&
              this.state.orderData.makerPhone != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Orders Creator Phone"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.orderData.makerPhone}
                  </Col>
                </div>
              )}
            {this.state.orderData.makerEmail != undefined &&
              this.state.orderData.makerEmail != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Orders Creator Email"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.orderData.makerEmail}
                  </Col>
                </div>
              )}
            {this.state.orderData.accountTypeTitle_ar != undefined &&
              this.state.orderData.accountTypeTitle_ar != "" &&
              this.state.orderData.accountTypeTitle_ar != "-" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Account Type of Creator"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {localStorage.getItem("LANG") == 1
                      ? this.state.orderData.accountTypeTitle_ar
                      : this.state.orderData.accountTypeTitle_en}
                  </Col>
                </div>
              )}
            {this.state.orderData.identifierDist != undefined &&
              this.state.orderData.identifierDist != "" &&
              this.state.orderData.identifierDist != "-" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["API identifier to distributor"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.orderData.identifierDist}
                  </Col>
                </div>
              )}
            {this.state.orderData.status != undefined &&
              this.state.orderData.status != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Status"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {localStorage.getItem("LANG") == 1
                      ? this.state.orderData.statusTitle_ar
                      : this.state.orderData.statusTitle_en}
                  </Col>
                </div>
              )}
            {this.state.orderData.orderDate != undefined &&
              this.state.orderData.orderDate != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Order Date/Time"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.orderData.orderDate +
                      " " +
                      this.state.orderData.orderTime}
                  </Col>
                </div>
              )}
            {this.state.orderData.sourceOrder != undefined && (
              <div className="mb-4 view-purchase-main-fields-row">
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="main-font-1 font-w-500"
                >
                  {Strings["Order Amount"]} :
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="main-font-1">
                  {(this.state.orderData.sourceOrder == 1 ||
                    this.state.orderData.sourceOrder == 3) && (
                    <ul className="mb-2">
                      <li>
                        {Strings["Transaction Amount"]} :{" "}
                        {this.state.orderData.transferAmountFromate}
                      </li>
                      <li>
                        {Strings["Net Transfer"]} :{" "}
                        {this.state.orderData.totalTransferFromate}
                      </li>
                      <li>
                        {Strings["Cards Value"]} :{" "}
                        {this.state.orderData.cardsAmount}
                      </li>
                    </ul>
                  )}
                  {this.state.orderData.sourceOrder == 2 && (
                    <ul className="mb-2">
                      <li>
                        {Strings["Cards Value for Distributor"]} :{" "}
                        {this.state.orderData.distAmountOrderForamate}
                      </li>
                      <li>
                        {Strings["Cards Value for POS"]} :{" "}
                        {this.state.orderData.amountOrderCurr}
                      </li>
                    </ul>
                  )}
                  {(this.state.orderData.sourceOrder == 4 ||
                    this.state.orderData.sourceOrder == 5) && (
                    <ul className="mb-2">
                      <li>
                        {Strings["Cards Value for Distributor"]} :{" "}
                        {this.state.orderData.amountOrderCurr}
                      </li>
                    </ul>
                  )}
                </Col>
              </div>
            )}
            {this.state.orderData.catName != undefined &&
              this.state.orderData.catName != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Card Type"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.orderData.catName}{" "}
                  </Col>
                </div>
              )}
            {this.state.orderData.cardName_ar != undefined &&
              this.state.orderData.cardName_ar != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Card"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {localStorage.getItem("LANG") == 1
                      ? this.state.orderData.cardName_ar
                      : this.state.orderData.cardName_en}{" "}
                  </Col>
                </div>
              )}
            {this.state.orderData.cardsClassesInfo != undefined &&
              this.state.orderData.cardsClassesInfo != "" &&
              this.state.orderData.cardsClassesInfo.length > 0 && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Required card classes"]} :{" "}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Row className="buttom-border-tool-tip mt-2">
                      <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        title={Strings["Denomination"]}
                        className="overflow-text-length"
                      >
                        {Strings["Denomination"]}
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                        title={Strings["Value"]}
                        className="overflow-text-length"
                      >
                        {Strings["Value"]}
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                        title={Strings["Count"]}
                        className="overflow-text-length"
                      >
                        {Strings["Count"]}
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        xs={12}
                        title={Strings["Total"]}
                        className="overflow-text-length"
                      >
                        {Strings["Total"]}
                      </Col>
                    </Row>
                    {this.displayCardClass()}
                  </Col>
                </div>
              )}
            {this.state.showExportOrder == 1 &&
              this.state.orderData.invoicePassword != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Invoice Password"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.orderData.invoicePassword}{" "}
                  </Col>
                </div>
              )}
            {this.state.orderData.paymentType_ar != undefined &&
              this.state.orderData.paymentType_ar != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Payment type"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {localStorage.getItem("LANG") == 1
                      ? this.state.orderData.paymentType_ar
                      : this.state.orderData.paymentType_en}
                  </Col>
                </div>
              )}
            {this.state.orderData?.paymentType == 9 && (
              <>
                {this.state.orderData?.transReferenceId != "" && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["transReferenceId"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.state.orderData?.transReferenceId}{" "}
                    </Col>
                  </div>
                )}
                {this.state.orderData?.transAmount != "" && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["transAmount"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.state.orderData?.transAmount}{" "}
                    </Col>
                  </div>
                )}
                {this.state.orderData?.transEntryDate != "" && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["transEntryDate"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.state.orderData?.transEntryDate}{" "}
                    </Col>
                  </div>
                )}
                {this.state.orderData?.transErrorDesc != "" && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["transErrorDesc"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.state.orderData?.transErrorDesc}{" "}
                    </Col>
                  </div>
                )}
                {this.state.orderData?.transTitle && (
                  <div className="mb-4 view-purchase-main-fields-row">
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="main-font-1 font-w-500"
                    >
                      {Strings["transTitle"]}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {this.state.orderData?.transTitle}{" "}
                    </Col>
                  </div>
                )}
              </>
            )}
            {this.state.orderData.isDirectChargeOrder != undefined &&
              this.state.orderData.isDirectChargeOrder != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Direct Charge"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {localStorage.getItem("LANG") == 1
                      ? this.state.orderData.directCharge_name_ar
                      : this.state.orderData.directCharge_name_en}
                  </Col>
                </div>
              )}
            {this.state.orderData.bankName != undefined &&
              this.state.orderData.bankName != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Bank account"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.orderData.bankName}
                  </Col>
                </div>
              )}
            {this.state.orderData.note != undefined &&
              this.state.orderData.note != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Note"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.orderData.note}
                  </Col>
                </div>
              )}
            {this.state.orderData.couponRats != undefined &&
              this.state.orderData.couponRats != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Discount rate"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.orderData.couponRats}
                  </Col>
                </div>
              )}
            {this.state.orderData.websiteApp != undefined &&
              this.state.orderData.websiteApp != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {this.state.orderData.sourceOrder != undefined &&
                    this.state.orderData.sourceOrder == 5
                      ? Strings["Wholesale Request #"]
                      : Strings["Reference #"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {this.state.orderData.websiteApp}
                  </Col>
                </div>
              )}

            {/* {(this.state.orderData.billNumber != undefined && this.state.orderData.billNumber != '') &&
                            <div className='mb-4 view-purchase-main-fields-row'>
                                <Col lg={12} md={12} sm={12} xs={12} className='main-font-1 font-w-500'>{Strings['Serial Number']}</Col>
                                <Col lg={12} md={12} sm={12} xs={12}>{this.state.orderData.billNumber}</Col>
                            </div>
                        } */}
            {this.state.orderData.identifierDomainArray != undefined &&
              this.state.orderData.identifierDomainArray != "" &&
              this.state.orderData.identifierDomainArray.length > 0 && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["CP Reference #"]} :{" "}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <ul>{this.displayIdentifierDomainArray()}</ul>
                  </Col>
                </div>
              )}
            {this.state.orderData.directCharge_data != undefined &&
              this.state.orderData.directCharge_data != "" &&
              this.state.orderData.directCharge_data.length > 0 && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Direct Charge order information"]} :{" "}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <ul>{this.displayDirectChargeData()}</ul>
                  </Col>
                </div>
              )}
            {this.state.orderData.bankTransfer != undefined &&
              this.state.orderData.bankTransfer != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Bank Transfer Image"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="text-center mt-4">
                      <img
                        src={this.state.orderData.bankTransfer}
                        className="img-fluid inv-category-img"
                      />
                    </div>
                  </Col>
                </div>
              )}
            {this.state.orderData.imageTransfer != undefined &&
              this.state.orderData.imageTransfer != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Transfer Image"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="text-center mt-4">
                      <img
                        src={this.state.orderData.imageTransfer}
                        className="img-fluid inv-category-img"
                      />
                    </div>
                  </Col>
                </div>
              )}
            {this.state.orderData.signatureImg != undefined &&
              this.state.orderData.signatureImg != "" && (
                <div className="mb-4 view-purchase-main-fields-row">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="main-font-1 font-w-500"
                  >
                    {Strings["Signature Image"]}
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="text-center mt-4">
                      <img
                        src={this.state.orderData.signatureImg}
                        className="img-fluid inv-category-img"
                      />
                    </div>
                  </Col>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}
export default ViewOrderAdmin;

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toAbsoluteUrl } from "../../../../_metronic";
import { translations } from "../../../../translate.js";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class CardSuppliers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardClassType: [],
      admin:
        localStorage.getItem("USER_LEVEL") == 1 ||
        localStorage.getItem("INV_MGR") == 1
          ? 1
          : 0,
      selctedSuppliers: {},
      selctedSuppliersIds: [],
      loading: 1,distId:this.props.distId?this.props.distId:''
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    this.getCardClassTypes();
    this.getSuppliersPrices();
  }

  getSuppliersPrices() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getSuppliersPrices",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        cardClassId: this.props.cardsCalssData.id,
        distId:this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            // console.log('========= getSuppliersPrices');
            // console.log(data);

            var selctedSuppliersIds = [];
            data["data"].map((rowData, index) => {
              selctedSuppliersIds[rowData.supplierId] = rowData.priceCurr;
            });
            this.setState({
              selctedSuppliers: data["data"],
              selctedSuppliersIds: selctedSuppliersIds,
              loading: 0,
            });
          }
        }
      });
  }

  getCardClassTypes() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCardClassTypes",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        getType: this.state.admin == 1 ? "all" : 1,
        distId:this.state.distId,

      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              cardClassType: data,
            });
          }
        }
      });
  }
  setUpSelectOptions = (data, type) => {
    if (type == "suppliers") {
      return (
        <option
          value={data.id}
          data-name={data.name}
          key={data.id}
          disabled={
            this.state.selctedSuppliersIds[data.id] != "" &&
            this.state.selctedSuppliersIds[data.id] != undefined &&
            this.state.selctedSuppliersIds[data.id] != null
              ? true
              : false
          }
        >
          {data.name}
        </option>
      );
    }
  };
  setUpSelectSupplier = () => {
    let normailSupplier = [];
    let apiSupplier = [];
    let directChargeSupplier = [];
    let select = [];
    this.state.cardClassType.map((data, indexData) => {
      if (data["type"] == 2) {
        directChargeSupplier.push(
          <option
            value={data.id}
            className={"option-background"}
            data-name={data.name}
            key={data.id}
            disabled={
              this.state.selctedSuppliersIds[data.id] != "" &&
              this.state.selctedSuppliersIds[data.id] != undefined &&
              this.state.selctedSuppliersIds[data.id] != null
                ? true
                : false
            }
          >
            {data.name}
          </option>
        );
      } else if (data["type"] == 1) {
        apiSupplier.push(
          <option
            value={data.id}
            className={"option-background"}
            data-name={data.name}
            key={data.id}
            disabled={
              this.state.selctedSuppliersIds[data.id] != "" &&
              this.state.selctedSuppliersIds[data.id] != undefined &&
              this.state.selctedSuppliersIds[data.id] != null
                ? true
                : false
            }
          >
            {data.name}
          </option>
        );
      } else {
        normailSupplier.push(
          <option
            value={data.id}
            className={"option-background"}
            data-name={data.name}
            key={data.id}
            disabled={
              this.state.selctedSuppliersIds[data.id] != "" &&
              this.state.selctedSuppliersIds[data.id] != undefined &&
              this.state.selctedSuppliersIds[data.id] != null
                ? true
                : false
            }
          >
            {data.name}
          </option>
        );
      }
    });
    if (normailSupplier.length > 0)
      select.push(
        <optgroup
          className={"optgroup-background"}
          label={Strings["Excel Suppliers"]}
        >
          {normailSupplier}
        </optgroup>
      );
    if (apiSupplier.length > 0)
      select.push(
        <optgroup
          className={"optgroup-background"}
          label={Strings["API Suppliers"]}
        >
          {apiSupplier}
        </optgroup>
      );
    if (directChargeSupplier.length > 0)
      select.push(
        <optgroup
          className={"optgroup-background"}
          label={Strings["Direct Charge Suppliers"]}
        >
          {directChargeSupplier}
        </optgroup>
      );
    // console.log("------------------------");
    // console.log(normailSupplier);
    // console.log("------------------------");
    // console.log(apiSupplier);
    // console.log("------------------------");
    // console.log(directChargeSupplier);
    return select;
  };

  addSupplierAction = (event) => {
    event.preventDefault();

    if (
      this.state.suppliers != "" &&
      this.state.suppliers != null &&
      this.state.suppliers != undefined
    ) {
      const newArray = this.state.selctedSuppliersIds;
      var name = this.state.suppliers;
      newArray[name] = this.state.price;

      var selctedSuppliers = this.state.selctedSuppliers;
      var selctedSuppliersArray = [];
      selctedSuppliersArray["supplierId"] = this.state.suppliers;
      selctedSuppliersArray["name"] = this.state.supplierName;
      selctedSuppliersArray["priceCurr"] = this.state.price;

      selctedSuppliers.push(selctedSuppliersArray);

      this.setState(
        {
          selctedSuppliers: selctedSuppliers,
          selctedSuppliersIds: newArray,
          suppliers: "",
          price: "",
          supplierName: "",
        },
        function() {
          this.form && this.form.reset();
        }
      );
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (name == "suppliers") {
      if (this.state.admin == 1) {
        var select = target; // the <select> element
        var selectedIndex = select.selectedIndex; // 0-based index of which element in the <select> is selected
        var selectedElement = select.options[selectedIndex]; // DOM element selected
        var optGroup = selectedElement.parentNode; // <optgroup> element
        var optGroupOptions = optGroup.children; // <option> elements in the <optgroup>
        var positionInOptGroup = Array.prototype.indexOf.call(
          optGroupOptions,
          selectedElement
        ); // the selected <option>'s position in the <optgroup>
        var selectedSupplierName = optGroupOptions[
          positionInOptGroup
        ].getAttribute("data-name");
      } else {
        var index = event.target.selectedIndex;
        var optionElement = event.target.childNodes[index];
        var selectedSupplierName = optionElement.getAttribute("data-name");
      }
      this.setState({ supplierName: selectedSupplierName });
    }
    this.setState({
      [name]: value,
      submitError: "",
    });
  };

  displaySupplierList() {
    var list = "";
    if (this.state.selctedSuppliers.length > 0) {
      list = this.state.selctedSuppliers.map((rowData, index) => {
        return (
          <Row key={index} className="table-body-row">
            <Col
              lg={this.state.admin == 1 ? 3 : 6}
              md={this.state.admin == 1 ? 3 : 6}
              sm={2}
              xs={12}
              className="main-color-1 font-w-400"
            >
              {rowData["name"]}
            </Col>
            {this.state.admin == 1 && (
              <Col lg={3} md={3} sm={2} xs={12}>
                {rowData["type"] == 2
                  ? Strings["Direct Charge"]
                  : rowData["type"] == 1
                  ? Strings["API"]
                  : Strings["Excel type"]}
              </Col>
            )}
            <Col lg={4} md={4} sm={2} xs={12}>
              {rowData["priceCurr"]}
            </Col>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={12}
              className="pointer-cursor text-center d-inline inv-category-box-action-btn"
            >
              <i
                className="flaticon2-cancel-music"
                title={Strings["Delete"]}
                onClick={() => this.deleteSupplier(index, rowData.supplierId)}
              />
            </Col>
          </Row>
        );
      });
    }
    return list;
  }

  deleteSupplier(index, id) {
    var selctedSuppliers = this.state.selctedSuppliers;
    delete selctedSuppliers[index];

    var selctedSuppliersIds = this.state.selctedSuppliersIds;
    selctedSuppliersIds[id] = "";

    this.setState({
      selctedSuppliers: selctedSuppliers,
      selctedSuppliersIds: selctedSuppliersIds,
    });
  }

  handleValidSubmit(event) {
    event.preventDefault();

    let selctedSuppliers = [];
    for (let item in this.state.selctedSuppliers) {
      selctedSuppliers.push({
        supplierId: this.state.selctedSuppliers[item]["supplierId"],
        price: this.state.selctedSuppliers[item]["priceCurr"],
      });
    }

    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "addSuppliersPrices",
        cardClassId: this.props.cardsCalssData.id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        suppliersPrices: JSON.stringify(selctedSuppliers),
        distId:this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.props.handleCloseSideBar();
          }
        }
      });
  }

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {Strings["Add Supplier"]}(
                {localStorage.getItem("LANG") == 1
                  ? this.props.cardsCalssData.name
                  : this.props.cardsCalssData.name_en}
                )
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          {this.state.loading == 1 && (
            <Row>
              <Col md={12} style={{ marginTop: "15%" }} className="text-center">
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}
          {this.state.loading == 0 && (
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
            >
              <Row>
                <Col lg={6} md={6} sm={4} xs={12}>
                  <AvField
                    type="select"
                    name="suppliers"
                    value={this.state.suppliers}
                    onChange={this.handleInputChange}
                    className="p-1 pl-3"
                    errorMessage={Strings["This field is required"]}
                  >
                    <option
                      style={{ display: "none" }}
                      value=""
                      selected="selected"
                    >
                      {Strings["Select supplier"]}
                    </option>
                    {localStorage.getItem("USER_LEVEL") == 1 ||
                    localStorage.getItem("INV_MGR") == 1
                      ? this.setUpSelectSupplier()
                      : this.state.cardClassType.map((data) =>
                          this.setUpSelectOptions(data, "suppliers")
                        )}
                  </AvField>
                </Col>
                <Col lg={4} md={4} sm={4} xs={12}>
                  <AvField
                    name="price"
                    type="number"
                    min={0}
                    placeholder={Strings["Price"]}
                    value={this.state.price}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </Col>
                <Col lg={2} md={2} sm={4} xs={12}>
                  <button
                    className="btn page-action-btn ml-1"
                    onClick={(event) => this.addSupplierAction(event)}
                  >
                    {Strings["Add"]}
                  </button>
                </Col>
              </Row>
              <hr className="class-action-supplier-hr" />
              {this.state.selctedSuppliers.length > 0 && (
                <div className="p-4">
                  <Row className="table-header-row">
                    <Col
                      lg={this.state.admin == 1 ? 3 : 6}
                      md={this.state.admin == 1 ? 3 : 6}
                      sm={2}
                      xs={12}
                    >
                      {Strings["Name"]}
                    </Col>
                    {this.state.admin == 1 && (
                      <Col lg={3} md={3} sm={2} xs={12}>
                        {Strings["Type"]}
                      </Col>
                    )}
                    <Col lg={4} md={4} sm={2} xs={12}>
                      {Strings["Price"]}
                    </Col>
                    <Col lg={2} md={2} sm={2} xs={12} className="text-center">
                      {Strings["Action"]}
                    </Col>
                  </Row>
                  {this.displaySupplierList()}
                </div>
              )}

              <div className="text-right mt-4">
                <button type="submit" className="btn form-save-btn mr-1 ml-1">
                  {" "}
                  {Strings["Save"]}{" "}
                </button>
                <button
                  type="button"
                  className="btn form-cancel-btn m-l-10"
                  onClick={this.props.handleCloseSideBar}
                >
                  {Strings["Cancel"]}
                </button>
              </div>
            </AvForm>
          )}
        </div>
      </div>
    );
  }
}
export default CardSuppliers;

import React, { Component } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import CardForm from "./forms/cardClass";
import FixedPrice from "./forms/fixedPrice";
import CardLangForm from "./forms/cardLangForm";
import { Button, Drawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Switch from "@material-ui/core/Switch";
import { translations } from "../../../translate.js";
import AddCoupons from "./forms/addCoupon";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SortPriority from "./forms/sortPriority";
import SelectBufferAndLinkInv from "./forms/selectBufferAndLinkInv";
import FileUploadForm from "./forms/fileUploadForm";
import ActiveCardOrCardClassToOrder from "./forms/activeCardOrCardClassToOrder";
import ActivateAndDeactivateClass from "./forms/activateAndDeactivateClass";
import AddBalanceMulti from "./forms/addBalanceMulti";
import DiscountAddCard from "./forms/discountAddCard";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class CardClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openleftModal: false,
      openAddAction: false,
      cardsData: [],
      data: [],
      catData: [],
      loading: 1,
      catName: "",
      openHeader: "",
      actionType: "",
      cardsDataFixesPrice: [],
      openCardsDeactivation: false,
      openFixedPriceLeftModal: false,
      openEditCardleftModal: false,
      openCouponsleftModal: false,
    };
  }

  componentDidMount() {
    if (
      localStorage.getItem("USER_LEVEL") == 1 ||
      localStorage.getItem("INV_MGR") == 1
    )
      document.addEventListener("mousedown", this.handleClickOutside);
  }

  displayCardsList(rowData, seach = 0, searchData = []) {
    this.props.displayCardsList(
      rowData,
      this.state.catName,
      this.state.mainCatType,
      this.state.catData,
      seach,
      searchData
    );
  }

  handleChangeCardPosStatus = (event, id) => {
    var name = "cardStatus-" + id;
    this.setState({ [name]: event.target.checked });
    // alert(event.target.checked)

    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "updatePosSpecificCardStatus",
        cardId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        status: event.target.checked == true ? 1 : 0,
        posId: this.props.posId,
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('=========================data');
          // console.log(data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.getCardsList(
              this.state.catId,
              this.state.catName,
              "",
              1,
              this.state.catData
            );
          }
        }
      });
  };
  handleChangeStatus = (event, id) => {
    var name = "hide-" + id;
    this.setState({ [name]: event.target.checked });

    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "updateStatusDistCard",
        cardId: id,
        distId: this.props.distId ? this.props.distId : "",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        status: event.target.checked == true ? 1 : 0,
        cardClassId: 0,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('=========================data');
          // console.log(data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.getCardsList(
              this.state.catId,
              this.state.catName,
              "",
              1,
              this.state.catData
            );
          }
        }
      });
  };
  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
        });
      }
    }
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        openAddAction: false,
      });
    }
  };
  openHeaderMenu = (event, id) => {
    // console.log(id)
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };
  openCardsDeactivation = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        cardsData: data,
        actionType: type,
        openCardsDeactivation: true,
      },
      function() {}
    );
  };
  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };
  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };
  displayData() {
    var list = "";

    if (this.state.data.length > 0) {
      list = this.state.data.map((rowData, index) => {
        // console.log(rowData);
        return (
          <Col lg={4} md={4} sm={6} xs={12} key={index}>
            <div className="inv-class-box">
              <Row>
                <Col
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  onClick={() => this.displayCardsList(rowData)}
                >
                  <img
                    className={
                      rowData.image != "" &&
                      rowData.image != undefined &&
                      rowData.image != null
                        ? "img-fluid inv-class-img pointer-cursor"
                        : "inv-card-empty-image pointer-cursor"
                    }
                    src={
                      rowData.image != "" &&
                      rowData.image != undefined &&
                      rowData.image != null
                        ? rowData.image
                        : toAbsoluteUrl("/media/ezpay/emptyCardImg.png")
                    }
                  />
                </Col>
                {((localStorage.getItem("USER_LEVEL") != 1 &&
                  localStorage.getItem("INV_MGR") != 1) ||
                  this.props.distId) && (
                  <Col lg={8} md={8} sm={8} xs={8}>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      onClick={() => this.displayCardsList(rowData)}
                    >
                      <div
                        className="main-color-1 inv-class-box-title"
                        title={
                          localStorage.getItem("LANG") == 1
                            ? rowData.name
                            : rowData.name_en
                        }
                      >
                        {localStorage.getItem("LANG") == 1
                          ? rowData.name
                          : rowData.name_en}
                      </div>
                    </Col>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="text-right mt-4 pr-0"
                    >
                      <div className="text-right d-inline inv-category-box-action-btn">
                        {localStorage.getItem("PERM_TYPE") == 8 && (
                          <i
                            className="flaticon2-writing mr-3"
                            title={Strings["Edit"]}
                            onClick={(event) =>
                              this.openSidebarModal(
                                event,
                                "edit",
                                rowData,
                                "addCard"
                              )
                            }
                          />
                        )}
                        {localStorage.getItem("PERM_TYPE") == 8 &&
                          this.state.mainCatType == 1 && (
                            <AttachMoneyIcon
                              size={23}
                              className="mr-3"
                              title={Strings["Edit"]}
                              onClick={(event) =>
                                this.openFixedCardPrice(event, rowData)
                              }
                            />
                          )}
                        {localStorage.getItem("PERM_TYPE") == 8 && (
                          <i
                            className="flaticon2-cancel-music"
                            title={Strings["Delete"]}
                            onClick={(event) =>
                              this.deleteCard(event, rowData.cardId)
                            }
                          />
                        )}

                        {(localStorage.getItem("PERM_TYPE") == 2 ||
                          this.props.permType == 2) && (
                          <div>
                            <Switch
                              checked={rowData["hide"] == 0 ? true : false}
                              onChange={(event) =>
                                this.handleChangeStatus(
                                  event,
                                  rowData["cardId"]
                                )
                              }
                              name={"hide-" + rowData["cardId"]}
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              size={"small"}
                            />
                            <i
                              className="flaticon2-writing ml-2"
                              title={Strings["Edit"]}
                              onClick={(event) =>
                                this.openEditCardsSidebarModal(
                                  event,
                                  "edit",
                                  rowData
                                )
                              }
                            />
                            {(localStorage.getItem("PERM_TYPE") == 2 ||
                              this.props.permType == 2) &&
                              (localStorage.getItem("SHOW_COUPONS") == 1 ||
                                this.props.distData?.appearCoupons) && (
                                <i
                                  className="flaticon-price-tag ml-2"
                                  title={Strings["Coupons"]}
                                  onClick={(event) =>
                                    this.openCouponsCardsSidebarModal(
                                      event,
                                      rowData,
                                      "addCopons"
                                    )
                                  }
                                />
                              )}
                          </div>
                        )}

                        {this.props.mainDist == 2 &&
                          localStorage.getItem("ACC_EMP") == 0 &&
                          localStorage.getItem("OPERA_EMP") == 0 && (
                            <div>
                              <Switch
                                checked={
                                  rowData["cardStatus"] == 0 ? false : true
                                }
                                onChange={(event) =>
                                  this.handleChangeCardPosStatus(
                                    event,
                                    rowData["cardId"]
                                  )
                                }
                                name={"cardStatus-" + rowData["cardId"]}
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                size={"small"}
                              />
                            </div>
                          )}
                      </div>
                    </Col>
                  </Col>
                )}
                {(localStorage.getItem("USER_LEVEL") == 1 ||
                  localStorage.getItem("INV_MGR") == 1) &&
                  !this.props.distId && (
                    <Col lg={7} md={7} sm={7} xs={7}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        onClick={() => this.displayCardsList(rowData)}
                      >
                        <div
                          className="main-color-1 inv-class-box-title"
                          title={
                            localStorage.getItem("LANG") == 1
                              ? rowData.name
                              : rowData.name_en
                          }
                        >
                          {localStorage.getItem("LANG") == 1
                            ? rowData.name
                            : rowData.name_en}
                        </div>
                      </Col>
                    </Col>
                  )}
                {(localStorage.getItem("USER_LEVEL") == 1 ||
                  localStorage.getItem("INV_MGR") == 1) &&
                  !this.props.distId && (
                    <Col lg={1} md={1} sm={1} xs={1}>
                      <div
                        className={
                          rowData.cardId +
                          "class" +
                          " row flex-row flex-nowrap  "
                        }
                        style={{ height: "50px" }}
                        key={rowData.cardId}
                      >
                        <div
                          className={
                            " action-account-bal text-center action-icon-view actionDropdownList" +
                            (this.state["activeIndex"] == rowData.cardId &&
                              " hover-scroll-table-row  ")
                          }
                        >
                          <Dropdown
                            isOpen={
                              this.state.openHeader == rowData.cardId
                                ? true
                                : false
                            }
                            className="font-size-13 actionDropdownList"
                          >
                            <DropdownToggle
                              tag="a"
                              className="nav-link pt-0 actionDropdownList inv-menu"
                              caret
                            >
                              <MoreVertIcon
                                className="menu-dots-vertical actionDropdownList"
                                onClick={(event) =>
                                  this.openHeaderMenu(event, rowData.cardId)
                                }
                              />
                            </DropdownToggle>
                            <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList menu-cards">
                              <div
                                className="actionDropdownList"
                                style={{
                                  maxHeight: "250px",
                                  overflowY: "scroll",
                                }}
                              >
                                <DropdownItem
                                  className="actionDropdownList"
                                  onClick={(event) =>
                                    this.openSidebarModal(
                                      event,
                                      "edit",
                                      rowData,
                                      "addCard"
                                    )
                                  }
                                >
                                  {Strings["Edit"]}
                                </DropdownItem>
                                <DropdownItem
                                  className="actionDropdownList"
                                  onClick={(event) =>
                                    this.deleteCard(event, rowData.cardId)
                                  }
                                >
                                  {Strings["Delete"]}
                                </DropdownItem>
                                <DropdownItem
                                  className="actionDropdownList"
                                  onClick={(event) =>
                                    this.openCardsDeactivation(
                                      event,
                                      "activeCardClassToOrder",
                                      rowData
                                    )
                                  }
                                >
                                  {Strings["Deactivation Days"]}
                                </DropdownItem>
                                {rowData.getCardType == 2 &&
                                  this.state.catData != undefined &&
                                  this.state.catData.isDirectCharge !=
                                    undefined &&
                                  this.state.catData.isApi != undefined &&
                                  (this.state.catData.isDirectCharge == 0 ||
                                    this.state.catData.isDirectCharge == "" ||
                                    this.state.catData.isApi == 1) && (
                                    <DropdownItem
                                      className="actionDropdownList"
                                      onClick={(event) =>
                                        this.openCouponsCardsSidebarModal(
                                          event,
                                          rowData,
                                          "selectBufferAndLinkInv",
                                          "buffer"
                                        )
                                      }
                                    >
                                      {Strings["Edit the Classes Buffer"]}
                                    </DropdownItem>
                                  )}
                                {this.state.mainCatType == 1 && (
                                  <DropdownItem
                                    className="actionDropdownList"
                                    onClick={(event) =>
                                      this.openFixedCardPrice(event, rowData)
                                    }
                                  >
                                    {Strings["Edit"] +
                                      " " +
                                      Strings["Buy & Sell Price"]}
                                  </DropdownItem>
                                )}
                                {rowData.getCardType == 2 &&
                                  this.state.catData != undefined &&
                                  this.state.catData.isDirectCharge !=
                                    undefined &&
                                  this.state.catData.isApi != undefined &&
                                  (this.state.catData.isDirectCharge == 0 ||
                                    this.state.catData.isDirectCharge == "" ||
                                    this.state.catData.isApi == 1) && (
                                    <DropdownItem
                                      className="actionDropdownList"
                                      onClick={(event) =>
                                        this.openSidebarModal(
                                          event,
                                          "",
                                          rowData,
                                          "activateAndDeactivate"
                                        )
                                      }
                                    >
                                      {
                                        Strings[
                                          "Activate and deactivate the class"
                                        ]
                                      }
                                    </DropdownItem>
                                  )}
                                {rowData.getCardType == 2 &&
                                  this.state.catData != undefined &&
                                  this.state.catData.isDirectCharge !=
                                    undefined &&
                                  this.state.catData.isApi != undefined &&
                                  (this.state.catData.isDirectCharge == 0 ||
                                    this.state.catData.isDirectCharge == "" ||
                                    this.state.catData.isApi == 1) && (
                                    <DropdownItem
                                      className="actionDropdownList"
                                      onClick={(event) =>
                                        this.openSidebarModal(
                                          event,
                                          "",
                                          rowData,
                                          "sortPriority"
                                        )
                                      }
                                    >
                                      {
                                        Strings[
                                          "Prioritizing Suppliers for Classes"
                                        ]
                                      }
                                    </DropdownItem>
                                  )}
                                {rowData.getCardType == 2 &&
                                  this.state.catData != undefined &&
                                  this.state.catData.isDirectCharge !=
                                    undefined &&
                                  this.state.catData.isApi != undefined &&
                                  (this.state.catData.isDirectCharge == 0 ||
                                    this.state.catData.isDirectCharge == "" ||
                                    this.state.catData.isApi == 1) && (
                                    <DropdownItem
                                      className="actionDropdownList"
                                      onClick={(event) =>
                                        this.openCouponsCardsSidebarModal(
                                          event,
                                          rowData,
                                          "selectBufferAndLinkInv",
                                          "invLink"
                                        )
                                      }
                                    >
                                      {
                                        Strings[
                                          "Edit Inventory Supplier for Classes"
                                        ]
                                      }
                                    </DropdownItem>
                                  )}
                              </div>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                    </Col>
                  )}
              </Row>
            </div>
          </Col>
        );
      });
    }
    return list;
  }

  deleteCard = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to delete this card ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };
  handleClickDelete(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "deleteCards",
        cardId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.getCardsList(
              this.state.catId,
              this.state.catName,
              "",
              "",
              this.state.catData
            );
          }
        }
      });
  }

  openSidebarModal = (event, type, data, actionType = "") => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        cardsData: data,
        status: type,
        actionType: actionType,
        openleftModal: true,
      },
      function() {}
    );
  };

  openEditCardsSidebarModal = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        cardsData: data,
        status: type,
        openEditCardleftModal: true,
      },
      function() {}
    );
  };
  openCouponsCardsSidebarModal = (event, data, type = "", modeVeiw = "") => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        cardsData: data,
        actionType: type,
        modeVeiw: modeVeiw,
        openCouponsleftModal: true,
      },
      function() {}
    );
  };
  openFixedCardPrice = (event, data) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        cardsDataFixesPrice: data,
        openFixedPriceLeftModal: true,
      },
      function() {}
    );
  };

  handleCloseSideBar = () => {
    this.setState({ openleftModal: false });
  };

  handleCloseEditCardSideBar = () => {
    this.setState({ openEditCardleftModal: false });
  };

  handleCloseCouponsSideBar = () => {
    this.setState({ openCouponsleftModal: false });
  };
  handleCloseCardsDeactivation = () => {
    this.setState({ openCardsDeactivation: false, actionType: "" });
  };
  handleCloseFixesPriceSideBar = () => {
    this.setState({ openFixedPriceLeftModal: false, cardsDataFixesPrice: [] });
  };

  handleFormAction = () => {
    this.setState(
      {
        cardsData: [],
        status: "",
        openleftModal: false,
      },
      function() {
        this.getCardsList(
          this.state.catId,
          this.state.catName,
          "",
          "",
          this.state.catData
        );
      }
    );
  };

  handleEditCardFormAction = () => {
    this.setState(
      {
        cardsData: [],
        status: "",
        openEditCardleftModal: false,
      },
      function() {
        this.getCardsList(
          this.state.catId,
          this.state.catName,
          "",
          "",
          this.state.catData
        );
      }
    );
  };

  getCardsList(
    catId,
    catName,
    mainCatType,
    withoutLoading,
    catData,
    search = 0,
    searchData = []
  ) {
    if (withoutLoading != 1 && search == 0) {
      this.setState({ loading: 1 });
    }
    if (search == 1) {
      // console.log("search");
      this.setState(
        {
          data: searchData["card"],
          catName: catName,
          catId: catId,
          catData: catData,
          mainCatType: mainCatType,
        },
        function() {
          if (searchData["cardClass"].length > 0) {
            // if (Object.keys(searchData["cardClass"]).length > 0) {
            this.displayCardsList(searchData["card"][0], 1, searchData);
          }
        }
      );
    } else
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getCards",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          catId: catId,
          inventory: 1,
          mainDist:
            this.props.mainDist != 1 && this.props.mainDist != 2
              ? ""
              : this.props.mainDist,
          posId: this.props.mainDist == 2 ? this.props.posId : "",
          distId: this.props.distId ? this.props.distId : "",
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (withoutLoading != 1) {
            this.setState({ loading: 1 });
          }
          if (typeof data !== "undefined") {
            // console.log('===============================');
            // console.log(data);
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState({
                data: data,
                loading: 0,
                catName: catName,
                catId: catId,
                catData: catData,
                mainCatType: mainCatType,
              });
            }
          }
        });
  }
  openAddActionMenu = () => {
    this.setState({
      openAddAction: !this.state.openAddAction,
    });
  };
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  render() {
    this.setWrapperRef = this.setWrapperRef.bind(this);
    return (
      <div>
        {this.state.loading == 1 && (
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}

        {this.state.loading == 0 && (
          <div>
            <Row>
              {this.props.mainDist == 2 &&
              !this.props.distId &&
              (localStorage.getItem("PERM_TYPE") == 5 ||
                this.props.permType == 5 ||
                localStorage.getItem("ACC_EMP") == 1 ||
                localStorage.getItem("OPERA_EMP") == 1) ? (
                <Col
                  md={6}
                  xs={6}
                  lg={6}
                  sm={12}
                  className="main-color-1 inv-class-view-title"
                >
                  <Paper elevation={0}>
                    <Breadcrumbs
                      separator={
                        localStorage.getItem("LANG") == 1 ? (
                          <NavigateBeforeIcon fontSize="large" />
                        ) : (
                          <NavigateNextIcon fontSize="large" />
                        )
                      }
                      aria-label="Breadcrumb"
                    >
                      <Link className="color-bridcrim" to="/posEmp" href="#">
                        {" "}
                        {this.props.posName}{" "}
                      </Link>
                      <Typography color="textPrimary">
                        {this.state.catName}
                      </Typography>
                    </Breadcrumbs>
                  </Paper>
                  <hr className="inv-hr-view-2" />
                </Col>
              ) : (
                <Col
                  md={6}
                  xs={6}
                  lg={6}
                  sm={12}
                  className="main-color-1 inv-class-view-title"
                >
                  <div>{this.state.catName}</div>
                  <hr className="inv-hr-view-2" />
                </Col>
              )}

              <Col md={6} xs={6} lg={6} sm={12} className="text-right">
                <AvForm ref={(c) => (this.form = c)} className="search-form">
                  <Row>
                    <Col md={7} xs={7} lg={7} sm={12}>
                      {/* <AvField type="text" name="search" placeholder={Strings['Search']} value={this.state.search} onChange={this.handleInputChange} /> */}
                    </Col>
                    <Col md={5} xs={5} lg={5} sm={12}>
                      {(localStorage.getItem("PERM_TYPE") != 2 ||
                        (this.props.permType != 2 &&
                          this.props.permType != "" &&
                          this.props.permType)) &&
                        this.props.mainDist != 1 &&
                        this.props.mainDist != 2 &&
                        (localStorage.getItem("PERM_TYPE") != 3 ||
                          (this.props.permType != 3 &&
                            this.props.permType != "" &&
                            this.props.permType)) &&
                        localStorage.getItem("USER_LEVEL") != 1 &&
                        localStorage.getItem("INV_MGR") != 1 && (
                          <button
                            className="btn gradient-button-view gradient-button gradient-button-font-12 ml-1"
                            onClick={(event) =>
                              this.openSidebarModal(event, "add", "", "addCard")
                            }
                          >
                            {Strings["Add Card"]}
                          </button>
                        )}
                      {(localStorage.getItem("USER_LEVEL") == 1 ||
                        localStorage.getItem("INV_MGR") == 1) &&
                        !this.props.distId && (
                          <>
                            {/* <button className="btn gradient-button-view gradient-button gradient-button-font-12 ml-1" onClick={(event) => this.openSidebarModal(event, 'add', '', "addCard")}>
                                                        {Strings['Action']}
                                                    </button> */}
                            <div
                              className={
                                (this.state.openAddAction == true
                                  ? "show "
                                  : "") + "dropdown-inv "
                              }
                              ref={this.setWrapperRef}
                            >
                              <button
                                className="btn gradient-button-view gradient-button gradient-button-font-12 ml-1"
                                data-toggle="dropdown"
                                aria-expanded="true"
                                onClick={(event) => this.openAddActionMenu()}
                              >
                                {Strings["Actions"]}
                                {this.state.openAddAction == true ? (
                                  <i
                                    className={
                                      localStorage.getItem("LANG") == 1
                                        ? "fas fa-angle-up mr-1"
                                        : "fas fa-angle-up ml-1"
                                    }
                                  ></i>
                                ) : (
                                  <i
                                    className={
                                      localStorage.getItem("LANG") == 1
                                        ? "fas fa-angle-down mr-1"
                                        : "fas fa-angle-down ml-1"
                                    }
                                  ></i>
                                )}
                              </button>

                              <div
                                className={
                                  (this.state.openAddAction == true
                                    ? "show "
                                    : "") + "dropdown-menu-inv"
                                }
                                x-placement="bottom-start"
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                }}
                              >
                                <label className="pl-1 pr-1 pt-1 ">
                                  {Strings.Actions}
                                </label>
                                {localStorage.getItem("USER_LEVEL") == 1 && (
                                  <a
                                    className="dropdown-item-inv"
                                    href="#/"
                                    onClick={(event) =>
                                      this.openCouponsCardsSidebarModal(
                                        event,
                                        [],
                                        "discountAddCard"
                                      )
                                    }
                                  >
                                    {Strings["Discount"]}
                                  </a>
                                )}
                                <a
                                  className="dropdown-item-inv"
                                  href="#/"
                                  onClick={(event) =>
                                    this.openSidebarModal(
                                      event,
                                      "add",
                                      "",
                                      "addCard"
                                    )
                                  }
                                >
                                  {Strings["Add Card"]}
                                </a>
                                <a
                                  className="dropdown-item-inv"
                                  href="#/"
                                  onClick={(event) =>
                                    this.openCouponsCardsSidebarModal(
                                      event,
                                      [],
                                      "addBalanceMulti"
                                    )
                                  }
                                >
                                  {Strings["Add Balance"]}
                                </a>
                                {localStorage.getItem("USER_LEVEL") == 1 && (
                                  <a
                                    className="dropdown-item-inv"
                                    href="#/"
                                    onClick={(event) =>
                                      this.openCouponsCardsSidebarModal(
                                        event,
                                        [],
                                        "fileUploadForm"
                                      )
                                    }
                                  >
                                    {Strings["Files Forms"]}
                                  </a>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                    </Col>
                  </Row>
                </AvForm>
              </Col>
            </Row>

            {this.state.loading == 0 && this.state.data.length <= 0 && (
              <Row className="text-center" style={{ marginTop: "15%" }}>
                <div>
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(
                      "/media/ezpay/EZPAY_LOGO_GRAY" +
                        window.MAIN_IMAGE +
                        ".png"
                    )}
                    className="img-fluid no-data-img"
                  />
                  <div className="no-data-text">
                    {Strings["There is no data to display"]}
                  </div>
                </div>
              </Row>
            )}
            {this.state.loading == 0 && this.state.data.length > 0 && (
              <Row>{this.displayData()}</Row>
            )}
          </div>
        )}

        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openleftModal}
          className="sidebar-modal-view"
        >
          {this.state.actionType == "addCard" && (
            <CardForm
              createQr={this.props?.createQr}
              handleCloseSideBar={this.handleCloseSideBar}
              handleFormAction={this.handleFormAction}
              status={this.state.status}
              cardsData={this.state.cardsData}
              catId={this.state.catId}
              catData={this.state.catData}
              distId={this.props.distId}
              permType={this.props.permType}
              distData={this.props.distData}
            />
          )}
          {this.state.actionType == "activateAndDeactivate" && (
            <ActivateAndDeactivateClass
              handleCloseSideBar={this.handleCloseSideBar}
              handleFormAction={this.handleFormAction}
              cardsData={this.state.cardsData}
              catData={this.state.catData}
              distId={this.props.distId}
            />
          )}
          {this.state.actionType == "sortPriority" && (
            <SortPriority
              handleCloseSideBar={this.handleCloseSideBar}
              cardsCalssData={this.state.cardsData}
              isCard={"1"}
              distId={this.props.distId}
            />
          )}
        </Drawer>

        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openFixedPriceLeftModal}
          className="sidebar-modal-view"
        >
          <FixedPrice
            handleCloseFixesPriceSideBar={this.handleCloseFixesPriceSideBar}
            cardsDataFixesPrice={this.state.cardsDataFixesPrice}
            catId={this.state.catId}
            distId={this.props.distId}
          />
        </Drawer>

        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openEditCardleftModal}
          className="sidebar-modal-view"
        >
          <CardLangForm
            handleCloseSideBar={this.handleCloseEditCardSideBar}
            handleFormAction={this.handleEditCardFormAction}
            status={this.state.status}
            cardsData={this.state.cardsData}
            catId={this.state.catId}
            distId={this.props.distId}
          />
        </Drawer>

        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openCouponsleftModal}
          className={
            this.state.actionType == "discountAddCard" ||
            this.state.actionType == "fileUploadForm"
              ? "sidebar-modal-large-view"
              : "sidebar-modal-x-large-view"
          }
        >
          {this.state.actionType == "addCopons" && (
            <AddCoupons
              handleCloseSideBar={this.handleCloseCouponsSideBar}
              cardType="1"
              cardId={this.state.cardsData["cardId"]}
              cardsData={this.state.cardsData}
              distId={this.props.distId}
            />
          )}
          {this.state.actionType == "selectBufferAndLinkInv" && (
            <SelectBufferAndLinkInv
              handleCloseSideBar={this.handleCloseCouponsSideBar}
              cardId={this.state.cardsData["cardId"]}
              cardsData={this.state.cardsData}
              modeVeiw={this.state.modeVeiw}
              distId={this.props.distId}
            />
          )}
          {this.state.actionType == "addBalanceMulti" && (
            <AddBalanceMulti
              distId={this.props.distId}
              handleCloseSideBar={this.handleCloseCouponsSideBar}
            />
          )}
          {this.state.actionType == "fileUploadForm" && (
            <FileUploadForm
              distId={this.props.distId}
              handleCloseSideBar={this.handleCloseCouponsSideBar}
            />
          )}
          {this.state.actionType == "discountAddCard" && (
            <DiscountAddCard
              handleCloseSideBar={this.handleCloseCouponsSideBar}
              distId={this.props.distId}
            />
          )}
        </Drawer>
        <Drawer
          style={{ position: "absolute", zIndex: 2 }}
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openCardsDeactivation}
          className={"sidebar-modal-large-view"}
        >
          {this.state.actionType == "activeCardClassToOrder" && (
            <ActiveCardOrCardClassToOrder
              distId={this.props.distId}
              handleCloseSideBar={this.handleCloseCardsDeactivation}
              parentCardInfo={this.state.parentCardInfo}
              cardsCalssData={this.state.cardsData}
              isCard={"1"}
            />
          )}
        </Drawer>
      </div>
    );
  }
}
export default CardClass;

import React, { Component } from "react";
import {
  Row,
  Col,
  CustomInput,
  Progress,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Link, withRouter } from "react-router-dom";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button, Drawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Pagination from "react-js-pagination";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ViewOrder from "./forms/viewOrder";
import ViewClasses from "./forms/viewClasses";
import ViewClassesPos from "./forms/viewClassesPos";
import { CircularProgress } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import { translations } from "../../../translate.js";
import SearchIcon from "@material-ui/icons/Search";
import AddProblem from "./forms/addProblem";
import { func } from "prop-types";
import POSviewOrder from "./forms/POSviewOrder.jsx";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
var today = new Date();
var currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);
const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const getMinDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth(); // getMonth returns a zero-indexed month
  const day = today.getDate();

  // Adjust the date to one month ago
  const lastMonthDate = new Date(year, month - 1, day);

  // Ensure proper formatting
  const lastMonthYear = lastMonthDate.getFullYear();
  const lastMonthMonth = String(lastMonthDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const lastMonthDay = String(lastMonthDate.getDate()).padStart(2, "0");

  return `${lastMonthYear}-${lastMonthMonth}-${lastMonthDay}`;
};

class MyOrdersPOS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOrderAction: false,
      orderRowData: [],
      posList: [],
      posCodeError: "",
      selectedPos: [],
      loading: 1,
      posCode: "",
      modal: true,
      statusList: [],
      categoryList: [],
      selectedCategoryList: [],
      cardTypeList: [],
      orderFilterOrderId:
        this.props.match.params.orderId == undefined
          ? ""
          : this.props.match.params.orderId,
      selectedCardTypeList: [],
      selectedStatusList: [],
      data: [],
      dataCount: 0,
      showExportOrder: 0,
      limit: 25,
      activePage: 1,
      emptyEmail: 0,
      loadingSave: 0,
      searchCode: 0,
      mainDist:
        localStorage.getItem("PERM_TYPE") == 5 ||
        localStorage.getItem("POS_MGR_EMP") == 1
          ? 0
          : 1,
      errorMsg: "",
      actionType: "",
      orderFilterDateFrom: currentDate,
      paymentTypeKey: "",
      orderFilterDateTo: currentDate,
      paymentTypes: [],
      filterKey: "",
      filterData: [],
      printFilters: [],
      orderPrint: null,
    };
  }
  handleRefresh = () => {
    // clear all filter data
    this.setState(
      {
        selectedPos: [],
        selectedCategoryList: [],
        selectedCardTypeList: [],
        selectedStatusList: [],
        orderFilterOrderId: "",
        searchCode: 0,
        orderFilterDateFrom: currentDate,
        orderFilterDateTo: currentDate,
        paymentTypeKey: "",
        filterKey: "",
        orderPrint: null,
      },
      function() {
        this.getOrdersList();
      }
    );
  };
  componentDidMount() {
    this.getFilterPaymentsTypes();
    this.getFilterTypes();
    this.getPrintStatusFilters();
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  getPrintStatusFilters = async () => {
    try {
      let response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "printFilter",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
        headers: {
          Accept: "application/json",
        },
      });
      if (response.status == 200) {
        let res = await response.json();
        console.log("FILTERS_RESPONSE", res);
        if (res.logout == 1) {
          localStorage.setItem("USER_ID", "");
          localStorage.setItem("USER_TOKEN", "");
          window.location.href = "/logout";
        } else {
          this.setState({ printFilters: res });
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  getFilterPaymentsTypes = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getFilterPaymentsTypes",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              paymentTypes: data,
            });
          }
        }
      });
  };
  setUpSelectOptions = (data, type) => {
    if (type == "paymentMethod") {
      return (
        <option value={data.id} key={data.id}>
          {data.name}
        </option>
      );
    } else
      return (
        <option value={data.id} key={data.id}>
          {data.title}
        </option>
      );
  };
  getFilterTypes = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getFilterTypes",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              filterData: data,
            });
          }
        }
      });
  };
  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
        });
      }
    }
  };
  getOrderStatus() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getOrderStatus",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              statusList: data["data"],
            });
          }
        }
      });
  }
  getCategoryList() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCategories",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        mainDist: this.state.mainDist,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              categoryList: data,
            });
          }
        }
      });
  }

  getOrdersList(withEmail = 0, seacrhCode = 0) {
    console.log("GOOOOOOOOOOOO!");
    this.setState({ sendEmailResult: 0 });
    var sendByMail = "";
    var email = "";
    if (withEmail == 1) {
      sendByMail = 1;
      email = this.state.email;
      this.setState({ loadingSave: 1 });
    } else {
      this.setState({ loading: 1 });
    }
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getPosOrders",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        page: this.state.activePage,
        limit: this.state.limit,
        dataType: "order",
        newVersion: 1,
        posCode: this.state.posCode,
        paymentTypeKey: this.state.paymentTypeKey,
        filterKey: this.state.filterKey,
        orderPrint: this.state.orderPrint,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ errorMsg: data["errorMsg"] });
          } else {
            if (withEmail == 1) {
              this.setState({ sendEmailResult: 1, loadingSave: 0 });
            } else {
              this.setState({
                data: data["orderData"],
                dataCount: data["orderDataCounter"],
                showExportOrder: data["showExportOrder"],
                loading: 0,
                // currencyTitle: data['currencyTitle']
              });
            }
          }
        }
      });
  }
  exportHandler() {
    window.location =
      window.API_PERM_USER +
      "?type=getPosOrders&dataType=order&newVersion=1&lang=" +
      localStorage.getItem("LANG") +
      "&userId=" +
      localStorage.getItem("USER_ID") +
      "&token=" +
      localStorage.getItem("USER_TOKEN") +
      "&export=1" +
      "&posCode=" +
      this.state.posCode +
      "&page=" +
      this.state.activePage +
      "&limit=" +
      this.state.limit +
      "&paymentTypeKey=" +
      this.state.paymentTypeKey +
      "&filterKey=" +
      this.state.filterKey +
      "&orderPrint=" +
      this.state.orderPrint;
  }

  getCardsList() {
    if (this.state.selectedCategoryList.length > 0) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getCards",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          catId: this.getSelected("category"),
          mainDist: this.state.mainDist,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (typeof data !== "undefined") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState({
                cardTypeList: data,
              });
            }
          }
        });
    }
  }

  //   getPos() {
  //     fetch(window.API_PERM_USER, {
  //       method: "POST",
  //       body: JSON.stringify({
  //         type: "getDistPos",
  //         lang: localStorage.getItem("LANG"),
  //         userId: localStorage.getItem("USER_ID"),
  //         token: localStorage.getItem("USER_TOKEN"),
  //         allPos: 0,
  //         getType: "all",
  //       }),
  //       headers: {
  //         Accept: "application/json",
  //       },
  //     })
  //       .then(function(response) {
  //         return response.json();
  //       })
  //       .then((data) => {
  //         if (data !== "Nothing found") {
  //           if (data["logout"] == 1) {
  //             localStorage.setItem("USER_ID", "");
  //             localStorage.setItem("USER_TOKEN", "");
  //             window.location.href = "/logout";
  //           } else {
  //             var posList = [];
  //             if (data["data"].length > 0) {
  //               data["data"].map((rowData, index) => {
  //                 var posListItem = {};
  //                 posListItem["userId"] = rowData["userId"];
  //                 posListItem["fname"] = rowData["fname"];
  //                 posListItem["lname"] = rowData["lname"];
  //                 posListItem["name"] = rowData["fname"] + " " + rowData["lname"];
  //                 posList.push(posListItem);
  //               });
  //             }

  //             this.setState({
  //               posList: posList,
  //             });
  //           }
  //         }
  //       });
  //   }

  handleChangeSelect = (type, option) => {
    if (type == "pos") {
      this.setState(
        (state) => {
          return {
            selectedPos: option,
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
        }
      );
    } else if (type == "category") {
      this.setState(
        (state) => {
          return {
            selectedCategoryList: option,
            selectedCardTypeList: [],
            cardTypeList: [],
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
          // this.getCardsList();
        }
      );
    } else if (type == "card") {
      this.setState(
        (state) => {
          return {
            selectedCardTypeList: option,
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
        }
      );
    } else if (type == "status") {
      this.setState(
        (state) => {
          return {
            selectedStatusList: option,
            activePage: 1,
          };
        },
        function() {
          // this.getOrdersList();
        }
      );
    }
  };
  getSelected = (type) => {
    if (type == "pos") {
      return this.state.selectedPos.map((data) => data.userId);
    }
    if (type == "category") {
      return this.state.selectedCategoryList.map((data) => data.id);
    }
    if (type == "card") {
      return this.state.selectedCardTypeList.map((data) => data.cardId);
    }
    if (type == "status") {
      return this.state.selectedStatusList.map((data) => data.id);
    }
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (name == "filterKey") {
      this.setState({
        [name]: value,
        paymentTypeKey: "",
        orderPrint:null
      });
    } else if (name == "email") {
      this.setState({
        [name]: value,
        emptyEmail: 0,
      });
    } else if (name == "code") {
      this.setState({
        [name]: value,
        emptyCode: 0,
      });
    } else {
      this.setState(
        {
          [name]: value,
          activePage: 1,
        },
        function() {
          // this.getOrdersList();
        }
      );
    }
  };

  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };
  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };

  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };

  openOrderAction = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (type == "view") {
      this.setState({
        orderRowData: data,
        openOrderAction: true,
        actionType: "viewOrder",
      });
    } else if (type == "export") {
      this.printPdf_CSV(data["orderId"], 1);
    } else if (type == "csv") {
      this.printPdf_CSV(data["orderId"], 2);
    } else if (type == "sms") {
      this.sendSms(data["id"]);
    } else if (type == "cancel") {
      this.cancelOrder(data["id"]);
    } else if (type == "classes") {
      this.setState({
        orderRowData: data,
        openOrderAction: true,
        actionType: "viewClasses",
      });
    } else if (type == "add_problem") {
      this.setState({
        actionType: type,
        openOrderAction: true,
        orderRowData: data,
      });
    }
  };

  printPdf_CSV(id, typeExport) {
    window.open(
      window.API_PERM_USER +
        "/permUser.php?type=" +
        "printPDFOrCSVInvoiceToCustomer" +
        "&lang=" +
        localStorage.getItem("LANG") +
        "&userId=" +
        localStorage.getItem("USER_ID") +
        "&token=" +
        localStorage.getItem("USER_TOKEN") +
        "&orderId=" +
        id +
        "&typeExport=" +
        typeExport,
      "_blank"
    );
  }

  sendSms(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to send sms ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickSendSms(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  }

  handleClickSendSms(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "sendSMSToCustomer",
        orderId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <div className="mb-3">
                      <span className="alert-delete-icon">!</span>
                    </div>
                    <p>{data["errorMsg"]}</p>
                    <button
                      type="button"
                      className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                      onClick={onClose}
                    >
                      {Strings.Cancel}
                    </button>
                  </div>
                );
              },
            });
          }
        }
      });
  }

  cancelOrder(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view alertBoxResopnsive">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to cancel order ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickCancelOrder(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  }

  handleClickCancelOrder(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "cancelOrderById",
        orderId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
            this.getOrdersList();
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <div className="mb-3">
                      <span className="alert-delete-icon">!</span>
                    </div>
                    <p>{data["errorMsg"]}</p>
                    <button
                      type="button"
                      className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
                      onClick={onClose}
                    >
                      {Strings.Cancel}
                    </button>
                  </div>
                );
              },
            });
          }
        }
      });
  }
  handleSendEmail = () => {
    if (
      this.state.email != undefined &&
      this.state.email != null &&
      this.state.email != ""
    ) {
      this.getOrdersList(1);
      this.setState({ emptyEmail: 0 });
    } else {
      this.setState({ emptyEmail: 1 });
    }
  };
  handleSearchByCode = () => {
    if (
      this.state.code != undefined &&
      this.state.code != null &&
      this.state.code != ""
    ) {
      this.setState({ emptyCode: 0, searchCode: 1 }, function() {
        this.getOrdersList(0, 1);
      });
    } else {
      this.setState({ emptyCode: 1 });
    }
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getOrdersList();
    });
  };

  handleCloseSideBar = () => {
    this.setState({ openOrderAction: false, orderRowData: [] });
  };

  recallGetData = () => {
    this.setState(
      {
        openOrderAction: false,
        orderRowData: [],
      },
      function() {
        this.getOrdersList();
      }
    );
  };
  displayData() {
    var orderInfo = "";
    var classStyle = {
      "text-1": "gray-color",
      "text-2": "warning-color",
      "text-3": "succ-color",
      "text-4": "reject-color",
      "text-5": "addCredit-color",
      "text-6": "wthdrawal-color",
      "text-7": "profit-color",
      "text-8": "status-8-color",
      "text-9": "status-9-color",
      "text-11": "pos-correct-mng-color",
    };
    var classColStatus = "";
    var cardName = "";
    var serailNumber = "";
    if (this.state.loading == 0 && this.state.data?.length > 0) {
      orderInfo = this.state.data.map((rowData, index) => {
        if (rowData["status"] !== "") {
          classColStatus = classStyle["text-" + rowData["status"]];
        }
        classColStatus =
          " overflow-text-length  bold-font " + classColStatus + " ";
        rowData["actualAmount"] =
          rowData["actualAmount"] == "" ? 0 : rowData["actualAmount"];
        cardName =
          localStorage.getItem("LANG") == 1
            ? rowData["cardName_ar"]
            : rowData["cardName_en"];
        serailNumber =
          rowData["billNumber"] != undefined && rowData["billNumber"] != ""
            ? rowData["billNumber"]
            : "-";
        return (
          <Row key={index} className="table-body-row">
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length order-2 order-md-1 order-lg-1"
              title={Strings["Type Of Operation"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Type Of Operation"]} :{" "}
              </span>
              {rowData.type == 1 ? Strings["Balance Recharge"] : Strings["buy"]}
              {rowData.type != 1 && ` - ${rowData["transPaymentType"]}`}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length order-3 order-md-2 order-lg-2"
              title={Strings["Order #"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Order #"]} :{" "}
              </span>

              {rowData.type == 1
                ? "--"
                : localStorage.getItem("SHOW_ORDER_ID_POS") != 1
                ? "--"
                : rowData.orderId}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length order-4 order-md-3 order-lg-3"
              title={Strings["Card"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Card"]} :{" "}
              </span>

              {rowData.type == 1 ? "--" : rowData.cardName}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length order-5 order-md-4 order-lg-4"
              title={Strings["Class"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold ">
                {Strings["Class"]} :{" "}
              </span>

              {rowData.type == 1 ? "--" : rowData.cardClass}
            </Col>

            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length order-6 order-md-5 order-lg-5"
              title={Strings["Quantity"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Quantity"]} :{" "}
              </span>

              {rowData.type == 1 ? "--" : rowData.qty}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length order-7 order-md-6 order-lg-6"
              title={Strings["Date"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Date"]} :{" "}
              </span>

              {rowData.type == 1
                ? rowData.entryDate
                : rowData.orderDate + " " + rowData.orderTime}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length order-8 order-md-7 order-lg-7"
              title={Strings["Print Status"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Print Status"]} :{" "}
              </span>

              {rowData.type == 1 ? "--" : rowData.printStatus}
            </Col>
            {this.state.showExportOrder == 1 && (
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={12}
                className="overflow-text-length order-9 order-md-8 order-lg-8"
                title={Strings["Invoice Password"]}
              >
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["Invoice Password"]} :{" "}
                </span>

                {rowData.type != 1 ? rowData.invoicePassword : "--"}
              </Col>
            )}
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length order-10 order-md-9 order-lg-9"
              title={Strings["Value"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Value"]} :{" "}
              </span>

              {rowData.type == 1
                ? rowData.amountFormat
                : localStorage.getItem("USER_PRICE_SHOW") == 1
                ? rowData.value
                : rowData.type != 1 &&
                  localStorage.getItem("USER_PRICE_SHOW") == 1
                ? rowData.value
                : "--"}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length order-11 order-md-10 order-lg-10"
              title={Strings["Balance Before"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Balance Before"]} :{" "}
              </span>

              {rowData.type == 1 ? rowData.beforeSumFormat : "--"}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className="overflow-text-length order-12 order-md-11 order-lg-11"
              title={Strings["Balance After"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Balance After"]} :{" "}
              </span>

              {rowData.type == 1 ? rowData.afterSumFormat : "--"}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={12}
              className={
                "action-icon-view actionDropdownList actionDropdownListPadding-0 order-1 order-md-12 order-lg-12 d-flex flex-row-reverse"
              }
            >
              {rowData.type != 1 ? (
                <Dropdown
                  isOpen={
                    this.state.openHeader == `${index}-${this.state.activePage}`
                      ? true
                      : false
                  }
                  className="font-size-13 actionDropdownList actionDropdownListPadding-0 orderlistActionlist"
                >
                  <DropdownToggle
                    tag="a"
                    className="nav-link pt-0 actionDropdownList  actionDropdownListPadding-0"
                    caret
                  >
                    <MoreVertIcon
                      className="menu-dots-vertical actionDropdownList"
                      onClick={(event) =>
                        this.openHeaderMenu(
                          event,
                          `${index}-${this.state.activePage}`
                        )
                      }
                    />
                  </DropdownToggle>
                  <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList ">
                    <div
                      className="actionDropdownList"
                      style={{ maxHeight: "250px", overflowY: "scroll" }}
                    >
                      {true && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openOrderAction(event, "view", rowData)
                          }
                        >
                          {Strings["View"]}
                        </DropdownItem>
                      )}
                      {this.state.showExportOrder == 1 && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openOrderAction(event, "export", rowData)
                          }
                        >
                          {Strings["Export"]}
                        </DropdownItem>
                      )}
                      {this.state.showExportOrder == 1 && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openOrderAction(event, "csv", rowData)
                          }
                        >
                          CSV
                        </DropdownItem>
                      )}
                      {/* <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openOrderAction(event, "sms", rowData)
                          }
                        >
                          SMS
                        </DropdownItem> */}
                    </div>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                "--"
              )}
            </Col>
          </Row>
        );
      });
    }
    console.log("orderInfo", orderInfo);
    return orderInfo;
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          centered={true}
          className={"requestdataModal"}
        >
          <div
            className="close-modal-btn "
            onClick={() => {
              this.setState({ posCode: "" });
              this.props.history.push({
                pathname: "/dashboard",
              });
            }}
          >
            X
          </div>
          <ModalBody>
            <AvForm>
              <div className="d-flex flex-column justify-content-between h-100">
                <h5 className="text-center">{Strings["Enter Pos Code"]}</h5>
                <div>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <AvField
                        name="posCode"
                        type="password"
                        label={" "}
                        placeholder={Strings["Enter Pos Code"]}
                        value={this.state.posCode}
                        onChange={this.handleInputChange}
                        errorMessage={Strings["This field is required"]}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <button
                  type={"button"}
                  className={
                    "btn gradient-button-view gradient-button gradient-button-font-12 ml-1 f-15 gradient-button-view-mobile "
                  }
                  onClick={(event) => {
                    event.preventDefault();
                    console.log(
                      "this.state.posCode",
                      this.state.posCode,
                      'localStorage.getItem("POS_CODE")',
                      localStorage.getItem("POS_CODE")
                    );
                    if (
                      this.state.posCode == localStorage.getItem("POS_CODE")
                    ) {
                      this.setState({ modal: false, posCodeError: "" });
                      this.getOrdersList();
                    } else {
                      this.setState({ posCodeError: Strings.errPosCode });
                    }
                  }}
                >
                  {Strings["Confirm"]}
                </button>
                {this.state.posCodeError != "" &&
                  this.state.posCodeError != undefined && (
                    <div className="error-text-red font-size-15 text-center mt-3">
                      {this.state.posCodeError}
                    </div>
                  )}
              </div>
            </AvForm>
          </ModalBody>
        </Modal>
        <Row className="page-filter-view">
          <Col lg={5} md={7}>
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
            >
              <Row className="filter-form">
                <Col lg={5} md={5} sm={5} xs={12}>
                  <AvField
                    type="select"
                    // label={Strings["Transactions type"] + " *"}

                    name="filterKey"
                    value={this.state.filterKey}
                    onChange={this.handleInputChange}
                    className="p-1 pl-3"
                    errorMessage={Strings["This field is required"]}
                  >
                    <option
                      style={{ display: "none" }}
                      value=""
                      selected="selected"
                    >
                      {Strings["Select"] + " " + Strings["Transactions type"]}
                    </option>
                    {this.state.filterData.map((data) =>
                      this.setUpSelectOptions(data, "paymentMethod")
                    )}
                  </AvField>
                </Col>
                <Col lg={5} md={5} sm={5} xs={10}>
                  <AvField
                    type="select"
                    name="paymentTypeKey"
                    value={this.state.paymentTypeKey}
                    onChange={this.handleInputChange}
                    className="p-1 pl-3"
                    errorMessage={Strings["This field is required"]}
                    disabled={this.state.filterKey != 2}
                  >
                    <option
                      style={{ display: "none" }}
                      value=""
                      selected="selected"
                    >
                      {Strings["Select"] + " " + Strings["Payment type"]}
                    </option>
                    {this.state.paymentTypes.map((data) =>
                      this.setUpSelectOptions(data, "paymentMethod")
                    )}
                  </AvField>
                </Col>
                <Col md={2} lg={2} sm={2} xs={2} className="mb-2">
                  <Button
                    variant="contained"
                    className="refresh-action-btn"
                    onClick={this.handleRefresh}
                    title={Strings["Refresh"]}
                  >
                    <i className="flaticon-refresh reset-filter-btn"></i>
                  </Button>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <AvField
                    type="select"
                    name="orderPrint"
                    value={this.state.orderPrint}
                    onChange={this.handleInputChange}
                    className="p-1 pl-3"
                    disabled={this.state.filterKey != 2}
                    errorMessage={Strings["This field is required"]}
                  >
                    <option
                      style={{ display: "none" }}
                      value=""
                      selected="selected"
                    >
                      {Strings["Select"] + " " + Strings["Print Status"]}
                    </option>
                    {this.state.printFilters.map((data) =>
                      this.setUpSelectOptions(data, "paymentMethod")
                    )}
                  </AvField>
                </Col>
              </Row>
            </AvForm>
          </Col>
          <Col lg={7} md={5} className="text-right Export-textAlignleft">
            <Col lg={12} md={12}>
              <div className="d-flex align-items-center gap-1 flex-row-reverse">
                {this.state.showExportOrder == 1 && (
                  <button
                    className="btn page-action-btn ml-1"
                    onClick={(event) => this.exportHandler(event)}
                  >
                    {Strings["Export"]}
                  </button>
                )}
                <button
                  variant="contained"
                  className="btn form-save-btn  ml-1 mr-1 "
                  onClick={() => this.getOrdersList()}
                >
                  {Strings["Search"]}{" "}
                  <SearchIcon className="font-size-15  main-color-2" />
                </button>
              </div>
            </Col>
          </Col>
        </Row>

        {this.state.loading == 0 && this.state.errorMsg != "" && (
          <div className="inv-cards-view pb-5">
            <div
              className="text-center"
              style={{ marginTop: "15%", marginBottom: "15%" }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">{this.state.errorMsg}</div>
            </div>
          </div>
        )}

        {this.state.loading == 0 && this.state.data?.length <= 0 && (
          <div className="inv-cards-view pb-5">
            <div
              className="text-center"
              style={{ marginTop: "15%", marginBottom: "15%" }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">
                {Strings["There is no data to display"]}
              </div>
            </div>
          </div>
        )}

        {this.state.loading == 1 && (
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}

        {/* //table--start// */}
        {this.state.loading == 0 && this.state.data?.length > 0 && (
          <Row>
            <div className="inv-cards-view width-100">
              <Row className="table-header-row d-none d-md-flex d-lg-flex">
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Type Of Operation"]}
                >
                  {Strings["Type Of Operation"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Order #"]}
                >
                  {Strings["Order #"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Card"]}
                >
                  {Strings["Card"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Class"]}
                >
                  {Strings["Class"]}
                </Col>

                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Quantity"]}
                >
                  {Strings["Quantity"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Date"]}
                >
                  {Strings["Date"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Print Status"]}
                >
                  {Strings["Print Status"]}
                </Col>
                {this.state.showExportOrder == 1 && (
                  <Col
                    lg={1}
                    md={1}
                    sm={1}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Invoice Password"]}
                  >
                    {Strings["Invoice Password"]}
                  </Col>
                )}
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Value"]}
                >
                  {Strings["Value"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Balance Before"]}
                >
                  {Strings["Balance Before"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Balance After"]}
                >
                  {Strings["Balance After"]}
                </Col>

                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="overflow-text-length text-right"
                  title={Strings["Action"]}
                >
                  {Strings["Action"]}
                </Col>
              </Row>
              {this.displayData()}
              {this.state.loading == 0 && this.state.data?.length > 0 && (
                <Col md={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              )}
            </div>
          </Row>
        )}

        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openOrderAction}
          className={
            this.state.actionType == "viewClasses"
              ? "sidebar-modal-x-large-view"
              : "sidebar-modal-view"
          }
        >
          {this.state.actionType == "viewOrder" && (
            <POSviewOrder
              viewPage={1}
              orderRowData={this.state.orderRowData}
              orderMoreInfo={this.state.orderRowData}
              handleCloseSideBar={this.handleCloseSideBar}
              showExportOrder={this.state.showExportOrder}
              recallGetData={this.recallGetData}
            />
          )}
        </Drawer>
      </div>
    );
  }
}
export default withRouter(MyOrdersPOS);

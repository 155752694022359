import React, { Component } from "react";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { translations } from "../../../translate";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
class OTB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loginError: "",
      viewMode: 1, // 1 for login , 2 for code
      qrCode: "",
      qrCodeError: "",
      loginCount: 0,
      authSecret: "",
      copyText: Strings.copy,
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  messageAlert = (msg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              {/* <span className="alert-delete-icon">!</span> */}
            </div>
            <h5>{Strings["Done Successfully"]}</h5>
            <p>{msg}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.ok}
            </button>
          </div>
        );
      },
    });
  };
  handleValidSubmit(event) {
    if (this.state.viewMode == 1) {
      this.loginHandler();
    } else {
      this.checkAuth();
    }
  }
  handleCopy = () => {
    this.setState({ copyText: Strings.copied });
    navigator.clipboard
      .writeText(this.state.authSecret)
      .then(() => {
        setTimeout(() => {
          this.setState({ copyText: Strings.copy });
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  checkAuth = async () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "checkAuthForPos",
        email: this.state.username,
        authSecret: this.state.authSecret,
        lang: localStorage.getItem("LANG"),
        code: this.state.qrCode,
        loginCount: this.state.loginCount,
        password: this.state.password,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        this.setState({ loading: 1 });
        if (typeof data !== "undefined") {
          // console.log('---------login------------------');
          // console.log(data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            localStorage.setItem("USER_BALANCE", "");
            window.location.href = "/logout";
          } else if (data["error"] == 2) {
            this.setState({
              loginError: data["alertMsg"],
              loginCount: data["loginCount"],
            });
          } else if (data["error"] == 0) {
            this.setState({
              username: "",
              password: "",
              loginError: "",
              viewMode: 1,
              qrCode: "",
              qrCodeError: "",
              loginCount: 0,
              authSecret: "",
              copyText: Strings.copy,
            });
            this.messageAlert(data["errorMsg"]);
          } else {
            this.setState({ qrCodeError: data["errorMsg"] });
          }
        }
      });
  };
  loginHandler() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "checkUserToGenerateTokenAuth",
        email: this.state.username,
        password: this.state.password,
        lang: localStorage.getItem("LANG"),
        loginCount: this.state.loginCount,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        this.setState({ loading: 1 });
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
          } else if (data["error"] == 1) {
            this.setState({ loginError: data["errorMsg"] });
          } else if (data["error"] == 2) {
            this.setState({
              loginError: data["alertMsg"],
              loginCount: data["loginCount"],
            });
          } else {
            this.setState({
              loginError: "",
              viewMode: 2,
              authSecret: data["authSecret"],
              qrCodeUrl: data["qrCodeUrl"],
            });
          }
        }
      });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <div className="login-form-view-padding">
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3 className="main-color-1">{Strings["Get POS code"]}</h3>
              <hr
                className={
                  this.state.viewMode == 1
                    ? "login-hr-view-1"
                    : "login-hr-view-2"
                }
              />
            </div>

            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
              className="login-form-input main-color-1"
            >
              {this.state.viewMode == 1 && (
                <div>
                  <AvField
                    type="email"
                    name="username"
                    label={Strings["Email"]}
                    placeholder={Strings["Enter your email"]}
                    value={this.state.username}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <AvField
                    type="password"
                    name="password"
                    label={Strings["Password"]}
                    placeholder={Strings["Enter password"]}
                    value={this.state.password}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </div>
              )}

              {this.state.viewMode == 2 && (
                <div>
                  {this.state.qrCodeUrl != "" &&
                    this.state.qrCodeUrl != null &&
                    this.state.qrCodeUrl != undefined && (
                      <div className="qr-code-img">
                        <img src={this.state.qrCodeUrl} />
                      </div>
                    )}
                  {this.state.viewMode == 2 && this.state.authSecret != "" && (
                    <div className="d-flex align-items-center justify-content-between">
                      <p>{`${Strings["Google Auth Key"]}: ${this.state.authSecret}`}</p>
                      <p
                        onClick={this.handleCopy}
                        className="a-like-btn"
                        type="button"
                      >
                        {this.state.copyText}
                      </p>
                    </div>
                  )}
                  <AvField
                    type="text"
                    name="qrCode"
                    label={Strings["Qr Code"]}
                    placeholder={Strings["Enter Qr code"]}
                    value={this.state.qrCode}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </div>
              )}

              <button
                type="submit"
                className="btn gradient-button-view gradient-button ml-1"
              >
                {this.state.viewMode == 1
                  ? Strings["Get code"]
                  : Strings["Save code"]}
              </button>

              <div>
                {this.state.loginError && (
                  <div className="error-text-black">
                    <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                    {this.state.loginError}
                  </div>
                )}
                {this.state.qrCodeError && (
                  <div className="error-text-black">
                    <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                    {this.state.qrCodeError}
                  </div>
                )}
              </div>
            </AvForm>
          </div>
        </div>
      </div>
    );
  }
}
export default OTB;

import React, { useContext } from "react";
import PosBillsRequests from "./PosBillsRequests";
import { MyContext } from "../../Context/MyContext";

export default function PosBillsWrap(props) {
  const { getSupervisorBalance } = useContext(MyContext);

  return (
    <PosBillsRequests {...props} getSupervisorBalance={getSupervisorBalance} />
  );
}

import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toAbsoluteUrl } from "../../../../_metronic";
import Pagination from "react-js-pagination";
import { translations } from "../../../../translate.js";
import { isEmpty } from "lodash";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class AddDenomination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      data: [],
      loading: 1,
      activePage: 1,
      limit: 10,
      dataCount: 0,
      id: 0,
      deleteError: "",
      userLevelVeiw: isEmpty(this.props.userLevelVeiw)
        ? "dist"
        : this.props.userLevelVeiw,
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    this.getDenomination();
  }

  getDenomination() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistClasses",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        distId: this.props.distId ? this.props.distId : "",
        token: localStorage.getItem("USER_TOKEN"),
        page: this.state.activePage,
        limit: this.state.limit,
        list:
          this.state.userLevelVeiw == "admin" ||
          localStorage.getItem("USER_LEVEL") == 1
            ? 1
            : 0,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('====================================== lang');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              data: data["data"],
              dataCount: parseInt(data["dataCount"]),
              loading: 0,
            });
          }
        }
      });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      submitError: "",
      deleteError: "",
    });
  };

  displayData() {
    var list = "";
    if (this.state.data.length > 0) {
      list = this.state.data.map((rowData, index) => {
        return (
          <Row key={index} className="table-body-row">
            <Col md={2} className="main-color-1 font-w-400">
              {rowData["id"]}
            </Col>
            <Col md={9}>{rowData["name"]}</Col>
            <Col
              md={1}
              className="text-right d-inline inv-category-box-action-btn"
            >
              <i
                className="flaticon2-writing mr-3"
                title={Strings["Edit"]}
                onClick={(event) => this.editDenomination(event, rowData)}
              />
              <i
                className="flaticon2-cancel-music"
                title={Strings["Delete"]}
                onClick={(event) => this.deleteDenomination(event, rowData.id)}
              />
            </Col>
          </Row>
        );
      });
    }
    return list;
  }

  editDenomination = (event, data) => {
    event.preventDefault();
    this.setState({
      title: data["name"],
      id: data["id"],
      deleteError: "",
    });
  };

  handleCancelAddForm = (event) => {
    event.preventDefault();
    this.form && this.form.reset();
    this.setState(
      {
        title: "",
        id: 0,
        deleteError: "",
      },
      function() {}
    );
  };

  deleteDenomination = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to delete this denomination ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };

  handleClickDelete(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "deleteDistClasses",
        cardClassId: id,
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
            this.getDenomination();
          } else {
            this.setState({ deleteError: data["errorMsg"] }, function() {
              this.displayDeleteError();
            });
          }
        }
      });
  }

  displayDeleteError() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <p>{this.state.deleteError}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
          </div>
        );
      },
    });
  }

  handleValidSubmit(event) {
    event.preventDefault();
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "addDistClasses",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        name: this.state.title,
        cardClassId: this.state.id,
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('===================================== SUBMIT');
          // console.log(data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ submitError: data["errorMsg"] });
          } else {
            this.form && this.form.reset();
            this.setState(
              {
                title: "",
                id: 0,
                deleteError: "",
              },
              function() {
                this.getDenomination();
              }
            );
          }
        }
      });
  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getDenomination();
    });
  };

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col md={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Classes"]}{" "}
              </div>
            </Col>
            <Col md={2} className="text-right">
              <HighlightOffIcon
                onClick={(event) => this.props.handleCloseSideBar(event)}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <Row>
            <Col md={5} className="card-desc-form-view">
              <div>
                <AvForm
                  onValidSubmit={this.handleValidSubmit}
                  ref={(c) => (this.form = c)}
                >
                  <AvField
                    name="title"
                    label={Strings["Title"] + " *"}
                    placeholder={Strings["Enter Title"]}
                    value={this.state.title}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <div className="text-right mt-4">
                    <button
                      type="submit"
                      className="btn form-save-btn mr-1 ml-1"
                    >
                      {" "}
                      {Strings["Save"]}{" "}
                    </button>
                    <button
                      type="button"
                      className="btn form-cancel-btn m-l-10"
                      onClick={(e) => this.handleCancelAddForm(e)}
                    >
                      {Strings["Cancel"]}
                    </button>
                  </div>
                  {this.state.submitError && (
                    <div className="error-text-black">
                      <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                      {this.state.submitError}
                    </div>
                  )}
                </AvForm>
              </div>
            </Col>
            <Col md={7}>
              {this.state.loading == 1 && (
                <Row>
                  <Col
                    md={12}
                    style={{ marginTop: "15%" }}
                    className="text-center"
                  >
                    <div className="card-body">
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow text-dark m-2 main-color-2"
                        role="status"
                      ></div>
                    </div>
                  </Col>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length <= 0 && (
                <Row className="text-center" style={{ marginTop: "15%" }}>
                  <div>
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl(
                        "/media/ezpay/EZPAY_LOGO_GRAY" +
                          window.MAIN_IMAGE +
                          ".png"
                      )}
                      className="img-fluid no-data-img"
                    />
                    <div className="no-data-text">
                      {Strings["There is no data to display"]}
                    </div>
                  </div>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <div className="inv-cards-view">
                  <div>
                    <Row className="table-header-row">
                      <Col md={2}>{Strings["Id"]}</Col>
                      <Col md={9}>{Strings["Title"]}</Col>
                      <Col md={1} className="text-center">
                        {Strings["Action"]}
                      </Col>
                    </Row>
                    {this.displayData()}
                  </div>
                </div>
              )}

              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Col md={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default AddDenomination;

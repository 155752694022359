import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Button, Drawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic";

import AppsIcon from "@material-ui/icons/Apps";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { Link, withRouter } from "react-router-dom";
import { translations } from "../../../translate.js";
import POSAddCardOrder from "./forms/POSAddCardOrder.jsx";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class POSBuyCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 1,
      activeStep: 1,
      categoryList: [],
      categoryd: "",
      cardsList: [],
      cardId: "",
      classList: [],
      inputAmount: "",
      distCurrency: "",
      distHaveBank: "0",
      distBankAccount: [],
      typeOfCard: "",
      taxInfo: [],
      notifiedClasses: [],
      classQty: [],
      taxPrecentAmount: "0",
      passwordView: 1, // 1 : hide , 2 show [ in step 3 ]
      classBuyPrice: [],
      errorInSelectedClasses: 0,
      //
      openOrderAction: false,
      classdistPrice: [],
      classTaxpercent: [],
      propsData: {},
      //
    };
  }
  openOrderAction = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({
      actionType: type,
      openOrderAction: true,
      propsData: data,
    });
  };
  handleCloseSideBar = (res = "", error = 0) => {
    this.setState({
      openOrderAction: false,
      propsData: {},
      actionType: "",
      viewMode: "",
    });
  };

  componentDidMount() {
    this.getCategoryList();
  }

  getCategoryList() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getPosCardCategories",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        this.setState({ loading: 1 });
        if (typeof data !== "undefined") {
          // console.log('===============================');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              categoryList: data,
              loading: 0,
            });
          }
        }
      });
  }

  getCardsList() {
    this.setState({ loading: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getPosCards",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        catId: this.state.categoryd,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          // console.log('===============================');
          // console.log(data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              cardsList: data,
              activeStep: 2,
              loading: 0,
            });
          }
        }
      });
  }

  getCardClassForOrder() {
    this.setState({ loading: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getPosCardClasses",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        cardId: this.state.cardId,
        cardCatId: this.state.categoryd,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "undefined") {
          // console.log('===============================');
          // console.log(data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            console.log("data['cardClass'] :", data["cardClasses"]);
            this.setState({
              classList: data["cardClasses"],
              distCurrency: data["currencyName"],
              distHaveBank: data["distHaveBank"],
              distBankAccount: data["distBankAccount"],
              typeOfCard: data["typeOfCard"],
              taxInfo: data["taxInfo"],
              amountRate: data["buyPrice"],
              activeStep: 3,
              loading: 0,
            });
          }
        }
      });
  }

  displaySteps() {
    return (
      <Row className="add-order-main-steps-view">
        <div className="mr-3 ml-3">
          <div className="add-order-active-step">
            <AppsIcon className="add-order-step-active-icon" />
          </div>
          <div className="add-order-active-step-title">
            {Strings["Categories"]}
          </div>
        </div>
        <div
          className={
            this.state.activeStep >= 2
              ? "add-order-active-arrow-icon d-none d-sm-none d-md-block"
              : "add-order-not-active-arrow-icon d-none d-sm-none d-md-block"
          }
        >
          <MoreHorizIcon />
          <MoreHorizIcon />
        </div>
        <div
          className={
            this.state.activeStep >= 2
              ? "add-order-active-arrow-icon"
              : "add-order-not-active-arrow-icon "
          }
        >
          <DoubleArrowIcon className="add-order-arrow-ar" />
        </div>
        <div
          className={
            this.state.activeStep >= 2
              ? "add-order-active-arrow-icon d-none d-sm-none d-md-block"
              : "add-order-not-active-arrow-icon d-none d-sm-none d-md-block"
          }
        >
          <MoreHorizIcon />
          <MoreHorizIcon />
        </div>

        <div className="mr-3 ml-3">
          <div
            className={
              this.state.activeStep >= 2
                ? "add-order-active-step"
                : "add-order-not-active-step"
            }
          >
            <LibraryBooksIcon
              className={
                this.state.activeStep >= 2
                  ? "add-order-step-active-icon"
                  : "add-order-step-not-active-icon"
              }
            />
          </div>
          <div className="add-order-active-step-title">{Strings["Cards"]}</div>
        </div>
        <div
          className={
            this.state.activeStep >= 3
              ? "add-order-active-arrow-icon d-none d-sm-none d-md-block"
              : "add-order-not-active-arrow-icon d-none d-sm-none d-md-block"
          }
        >
          <MoreHorizIcon />
          <MoreHorizIcon />
        </div>
        <div
          className={
            this.state.activeStep >= 3
              ? "add-order-active-arrow-icon"
              : "add-order-not-active-arrow-icon"
          }
        >
          <DoubleArrowIcon className="add-order-arrow-ar" />
        </div>
        <div
          className={
            this.state.activeStep >= 3
              ? "add-order-active-arrow-icon d-none d-sm-none d-md-block"
              : "add-order-not-active-arrow-icon d-none d-sm-none d-md-block"
          }
        >
          <MoreHorizIcon />
          <MoreHorizIcon />
        </div>

        <div className="mr-3 ml-3">
          <div
            className={
              this.state.activeStep >= 3
                ? "add-order-active-step"
                : "add-order-not-active-step"
            }
          >
            <ListAltIcon
              className={
                this.state.activeStep >= 3
                  ? "add-order-step-active-icon"
                  : "add-order-step-not-active-icon"
              }
            />
          </div>
          <div className="add-order-active-step-title">
            {Strings["Classes"]}
          </div>
        </div>
        <div
          className={
            this.state.activeStep >= 4
              ? "add-order-active-arrow-icon d-none d-sm-none d-md-block"
              : "add-order-not-active-arrow-icon d-none d-sm-none d-md-block"
          }
        >
          <MoreHorizIcon />
          <MoreHorizIcon />
        </div>
        <div
          className={
            this.state.activeStep >= 4
              ? "add-order-active-arrow-icon"
              : "add-order-not-active-arrow-icon"
          }
        >
          <DoubleArrowIcon className="add-order-arrow-ar" />
        </div>
        <div
          className={
            this.state.activeStep >= 4
              ? "add-order-active-arrow-icon d-none d-sm-none d-md-block"
              : "add-order-not-active-arrow-icon d-none d-sm-none d-md-block"
          }
        >
          <MoreHorizIcon />
          <MoreHorizIcon />
        </div>

        <div className="mr-3 ml-3">
          <div
            className={
              this.state.activeStep >= 4
                ? "add-order-active-step margin-Margin-top-Responsive"
                : "add-order-not-active-step margin-Margin-top-Responsive"
            }
          >
            <DoneAllIcon
              className={
                this.state.activeStep >= 4
                  ? "add-order-step-active-icon"
                  : "add-order-step-not-active-icon"
              }
            />
          </div>
          <div className="add-order-active-step-title">{Strings["Done"]}</div>
        </div>
      </Row>
    );
  }

  setNextStep = () => {
    if (this.state.activeStep == 1) {
      // check if select category
      // get cards list
      // change step to 2
      if (
        this.state.categoryd != "" &&
        this.state.categoryd != null &&
        this.state.categoryd != undefined
      ) {
        this.getCardsList();
        // this.setState({ activeStep: 2 })
      }
    } else if (this.state.activeStep == 2) {
      // check if select card
      // get card class list
      // change step to 3
      if (
        this.state.cardId != "" &&
        this.state.cardId != null &&
        this.state.cardId != undefined
      ) {
        this.getCardClassForOrder();
        // this.setState({ activeStep: 3 })
      }
    } else if (this.state.activeStep == 3) {
      // check if add all fields
      // change step to 4
      // this.setState({ activeStep: 4 })
    }
  };

  setBackStep = () => {
    if (this.state.activeStep == 2) {
      this.setState({
        cardId: "",
        cardsList: [],
        activeStep: 1,
      });
    } else if (this.state.activeStep == 3) {
      this.setState({
        classList: [],
        distCurrency: "",
        distHaveBank: "",
        distBankAccount: [],
        typeOfCard: "",
        taxInfo: [],
        amountRate: "",
        inputAmount: "",
        cardsAmounCurr: "",
        paymentAmount: "",
        distCurrency: "",
        amount: "",
        amountUSD: "",
        bankId: "",
        showPass: false,
        passInvoce: "",
        submitError: "",
        errorInSelectedClasses: 0,
        notifiedClasses: [],
        classQty: [],
        classBuyPrice: [],
        activeStep: 2,
        //
        classdistPrice: [],
        classTaxpercent: [],
        //
      });
    }
  };

  selectCategory = (categoryd) => {
    this.setState({ categoryd: categoryd }, function() {
      this.setNextStep();
    });
  };

  selectCard = (cardId) => {
    this.setState({ cardId: cardId }, function() {
      this.setNextStep();
    });
  };

  displayCategory() {
    let categoryView = "";
    if (this.state.categoryList.length > 0) {
      categoryView = this.state.categoryList.map((rowData, index) => {
        return (
          // <Col md={4} lg={2} xl={2} sm={6}  className='text-center p-0' key={index}>
          <div
            className="text-center p-0 buyCard-Category-CardWidth"
            key={index}
          >
            <div
              className={
                (this.state.categoryd == rowData["id"] &&
                  "add-order-cat-selected-view") + " add-order-cat-view"
              }
              onClick={() => this.selectCategory(rowData["id"])}
            >
              <div className="add-order-cat-img-view">
                <img
                  className="img-fluid add-order-cat-img"
                  src={
                    rowData.imageUrl != "" &&
                    rowData.imageUrl != null &&
                    rowData.imageUrl != undefined
                      ? rowData.imageUrl
                      : toAbsoluteUrl(
                          "/media/ezpay/EZPAY_LOGO_GRAY" +
                            window.MAIN_IMAGE +
                            ".png"
                        )
                  }
                />
              </div>
              <div className="mt-3 d-inline-flex main-color-1">
                {this.state.categoryd == rowData["id"] ? (
                  <RadioButtonCheckedIcon className="ml-1 mr-1" />
                ) : (
                  <RadioButtonUncheckedIcon className="ml-1 mr-1" />
                )}
                <div className="add-order-cat-name font-w-500">
                  {rowData.name}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    return categoryView;
  }

  displayCards() {
    let cardsView = "";
    if (this.state.cardsList.length > 0) {
      cardsView = this.state.cardsList.map((rowData, index) => {
        return (
          // <Col md={2} className='text-center p-0' key={index}>
          <div
            className="text-center p-0 buyCard-Category-CardWidth"
            key={index}
          >
            <div
              className={
                (this.state.cardId == rowData["cardId"] &&
                  "add-order-cat-selected-view") + " add-order-cat-view"
              }
              onClick={() => this.selectCard(rowData["cardId"])}
            >
              <div className="add-order-cat-img-view">
                <img
                  className="img-fluid add-order-cat-img"
                  src={
                    rowData.image != "" &&
                    rowData.image != null &&
                    rowData.image != undefined
                      ? rowData.image
                      : toAbsoluteUrl(
                          "/media/ezpay/EZPAY_LOGO_GRAY" +
                            window.MAIN_IMAGE +
                            ".png"
                        )
                  }
                />
              </div>
              <div className="mt-3 d-inline-flex main-color-1">
                {this.state.cardId == rowData["cardId"] ? (
                  <RadioButtonCheckedIcon className="ml-1 mr-1" />
                ) : (
                  <RadioButtonUncheckedIcon className="ml-1 mr-1" />
                )}
                <div className="add-order-cat-name font-w-500">
                  {rowData.name}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    return cardsView;
  }

  handleClassQtyChange = (
    event,
    classId,
    distBuyPriceCurr,
    distPrice,
    percentTax
  ) => {
    //classId==>id
    // distBuyPriceCurr==>value
    //distPrice==>distPriceCurr
    console.log(percentTax);
    const target = event.target;
    const value = target.value;

    var classQty = this.state.classQty;
    classQty[classId] = value;

    var classBuyPrice = this.state.classBuyPrice;
    classBuyPrice[classId] = distBuyPriceCurr;

    var classdistPrice = this.state.classdistPrice;
    classdistPrice[classId] = distPrice;

    var classTaxpercent = this.state.classTaxpercent;
    classTaxpercent[classId] = percentTax;

    this.setState(
      {
        classQty: classQty,
        classBuyPrice: classBuyPrice,
        classdistPrice: classdistPrice,
        classTaxpercent: classTaxpercent,
      },
      function() {
        this.calculateCardsAmount();
      }
    );
  };

  calculateCardsAmount = () => {
    var scope = this;
    var priceClass = 0;
    var total = 0;
    var tax = 0;
    var taxTotal = 0;
    // loop in classQty
    var cardsAmounCurr = 0;
    Object.keys(this.state.classQty).map(function(rowData, index) {
      // cardsAmounCurr = parseFloat(cardsAmounCurr) + (scope.state.classQty[rowData] * parseFloat(scope.state.classBuyPrice[rowData]))
      cardsAmounCurr =
        parseFloat(cardsAmounCurr) +
        scope.state.classQty[rowData] *
          parseFloat(scope.state.classdistPrice[rowData]);

      priceClass = parseFloat(
        scope.state.classBuyPrice[rowData] * scope.state.classQty[rowData]
      );
      if (
        localStorage.getItem("IS_TAXDIST") == 1 &&
        parseFloat(scope.state.classTaxpercent[rowData]) > 0
      ) {
        tax =
          (parseFloat(scope.state.classTaxpercent[rowData]) / 100) *
          parseFloat(priceClass);
        priceClass = parseFloat(priceClass) + parseFloat(tax);
      }

      total = parseFloat(total) + parseFloat(priceClass);
      taxTotal = parseFloat(taxTotal) + parseFloat(tax);
      if (isNaN(total)) {
        total = 0;
      }
      if (isNaN(taxTotal)) {
        taxTotal = 0;
      }
    });

    this.setState({
      cardsAmounCurr: cardsAmounCurr.toFixed(5),
      taxTruncateAmount: taxTotal.toFixed(5),
      inputAmount: total.toFixed(5),
    });
  };

  displayClasses() {
    let classView = "";
    if (this.state.classList.length > 0) {
      classView = this.state.classList.map((rowData, index) => {
        return (
          <Col md={3} className="mb-3" key={rowData.id}>
            <div className="add-order-class-box-form-view">
              <div className="main-color-1 f-15 font-w-500 add-order-class-box-form-title overflow-text-length">
                {rowData["name"]}
              </div>
              {localStorage.getItem("USER_PRICE_SHOW") == 1 && (
                <div>
                  <Row className="mt-3">
                    <Col md={2}>{Strings["Price"]}</Col>
                    <Col md={10}>
                      {/* <div>{rowData['distBuyPriceCurr']} {this.state.distCurrency}</div> */}
                      <div>
                        {rowData["price"]} {rowData["currency"]}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              <div className="mt-5 pt-3">
                <button
                  type={"button"}
                  className={
                    "btn gradient-button-view gradient-button gradient-button-font-12 ml-1 f-15 gradient-button-view-mobile "
                  }
                  onClick={(event) => {
                    this.openOrderAction(event, "buy", rowData);
                  }}
                >
                  {Strings["buy"]}{" "}
                  {localStorage.getItem("LANG") == 1 ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </button>
                {/* <Row className='mt-3'>
                                    <Col md={2} className='mt-2'>
                                        {Strings['Qty']}
                                    </Col>
                                    <Col md={10}>
                                        <AvField
                                            disabled={(rowData['notAvailable'] == 1 && rowData['notified'] == 0)
                                                ? true : false} name={"class-" + rowData['id']} type='number'
                                            min={0} max={100}
                                            placeholder={Strings['Enter Qty']}
                                            value={this.state.classQty[rowData['id']]}
                                            onChange={(event) => this.handleClassQtyChange(event, rowData['id'], rowData['value'], rowData['distPriceCurr'], rowData['percentTax'])}
                                            errorMessage={Strings['Sorry, the required quantity is not available']}

                                        />
                                    </Col>
                                </Row> */}
              </div>
              {/* {localStorage.getItem('IS_TAXDIST') == 1 && rowData['percentTax'] > 0 &&
                                <div>
                                    <Row className='mt-3'>
                                        <Col md={2} className='mt-2'>
                                            {Strings['Tax']}
                                        </Col>
                                        <Col md={10} className='mt-2'>
                                            <div>{rowData['percentTax']}</div>
                                        </Col>
                                    </Row>
                                </div>
                            } */}
              {/* {rowData['notAvailable'] == 1 && rowData['notified'] == 0 &&
                                <div className='text-right add-order-alert-btn-view'>
                                    <Button variant="contained" className='refresh-action-btn filter-send-email-btn mt-3 add-order-alert-btn' onClick={() => this.handleSendAlert(rowData.id)}>
                                        {this.state.notifiedClasses[rowData.id] == 1 ? <CheckCircleOutlineIcon className='reset-filter-btn mr-2 ml-2' /> : <NotificationsNoneIcon className='reset-filter-btn mr-2 ml-2' />} {Strings['Alert']}
                                    </Button>
                                </div>
                            } */}
            </div>
          </Col>
        );
      });
    }
    return classView;
  }

  handleSendAlert = (classId) => {
    // https://dev45.hoja-crm.com/API/permUser.php?type=saveNotifyDist&userId=636&classId=1553&qsyTest=-500
    if (this.state.notifiedClasses[classId] != 1) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "saveNotifyDist",
          classId: classId,
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            // console.log('========================= data');
            // console.log(data);

            // error: 0
            // errorMsg: "Success"
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              var notifiedClasses = this.state.notifiedClasses;
              notifiedClasses[classId] = 1;
              this.setState({
                notifiedClasses: notifiedClasses,
              });
            }
          }
        });
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
    });

    if (name == "showPass") {
      this.setState({
        passInvoce: Math.floor(Math.random() * 1000000000),
      });
    } else if (name == "amount" && this.state.typeOfCard == 1) {
      var amountUSD = parseFloat(value) / parseFloat(this.state.amountRate);
      if (isNaN(amountUSD)) {
        amountUSD = 0;
      }
      this.setState({ amountUSD: amountUSD });
    } else if (name == "inputAmount" && this.state.typeOfCard == 2) {
      this.setState({ paymentAmount: value });
    }
  };

  setUpSelectOptions(data, type) {
    if (type == "distBankAccount") {
      return (
        <option value={data.id} key={data.id}>
          {data.accountName}
        </option>
      );
    }
  }

  changePasswordView = (passwordView) => {
    this.setState({ passwordView: passwordView });
  };

  handleValidSubmit = (event) => {
    event.preventDefault();
    this.setState({ errorInSelectedClasses: 0, submitError: "" });

    var canSave = 1;
    var classIdArray = [];
    var qtyArray = [];
    var classId = "";
    var qty = "";

    if (this.state.typeOfCard == 2) {
      var scope = this;
      Object.keys(this.state.classQty).map(function(rowData, index) {
        if (scope.state.classBuyPrice[rowData] > 0) {
          classIdArray.push(rowData);
          qtyArray.push(scope.state.classQty[rowData]);
        }
      });

      if (classIdArray.length <= 0) {
        canSave = 0;
        this.setState({ errorInSelectedClasses: 1 });
      }

      classId = classIdArray.join(",");
      qty = qtyArray.join(",");
    }

    if (canSave == 1) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "addOrder",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          cardId: this.state.cardId,
          // 'inputAmount': this.state.inputAmount,
          amount: this.state.amount,
          amountUSD: this.state.amountUSD,
          bankId: this.state.bankId,
          showPass: this.state.showPass,
          passInvoce: this.state.passInvoce,
          classId: classId,
          qty: qty,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              if (data["error"] == "0") {
                this.setState({ activeStep: 4 });
              } else {
                this.setState({ submitError: data["error"] });
              }
              // console.log('------------- result -------------');
              // console.log(data);
            }
          }
        });
    }
  };

  render() {
    var disabledBtn = true;
    var disabledClass = "not-allowed-cursor";

    if (this.state.activeStep == 1) {
      // step 1 check if category id is selected
      if (
        this.state.categoryd != "" &&
        this.state.categoryd != null &&
        this.state.categoryd != undefined
      ) {
        disabledBtn = false;
        disabledClass = "";
      }
    } else if (this.state.activeStep == 2) {
      // step 2 check if card id is seleted
      if (
        this.state.cardId != "" &&
        this.state.cardId != null &&
        this.state.cardId != undefined
      ) {
        disabledBtn = false;
        disabledClass = "";
      }
    } else if (this.state.activeStep == 3) {
      // step 3 submit function check validation
      disabledBtn = false;
      disabledClass = "";
    }

    return (
      <div>
        {this.displaySteps()}

        {this.state.loading == 1 && (
          <div className="add-order-step-form-view">
            <Row>
              <Col md={12} style={{ marginTop: "15%" }} className="text-center">
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          </div>
        )}

        <AvForm
          onValidSubmit={this.handleValidSubmit}
          ref={(c) => (this.form = c)}
        >
          {this.state.loading == 0 && (
            <div className="add-order-step-form-view">
              {this.state.activeStep == 1 && (
                <div className="add-order-steps-main-view">
                  <div className="main-color-1 f-16 font-w-500 mb-4 mt-3 pl-4 pr-4">
                    {Strings["Select Cards Category"]}
                    <hr className="inv-hr-view-2" />
                  </div>
                  <Row className="pr-4 pl-4">{this.displayCategory()}</Row>
                </div>
              )}

              {this.state.activeStep == 2 && (
                <div className="add-order-steps-main-view">
                  <div className="main-color-1 f-16 font-w-500 mb-4 mt-3 pl-4 pr-4">
                    {Strings["Select Card"]}
                    <hr className="inv-hr-view-2" />
                  </div>
                  <Row className="pr-4 pl-4">{this.displayCards()}</Row>
                </div>
              )}

              {this.state.activeStep == 3 && (
                <div className="add-order-steps-main-view">
                  <div className="main-color-1 f-16 font-w-500 mb-4 mt-3 pl-4 pr-4">
                    {Strings["Determine the value of the cards"]}
                    <hr className="inv-hr-view-2" />
                  </div>
                  <Row className="pr-4 pl-4">{this.displayClasses()}</Row>
                  <Row className="mt-5 pl-3 pr-3">
                    <Col md={5}>
                      {this.state.typeOfCard == 2 && (
                        <div>
                          <Row>
                            <Col md={6}>
                              {/* <AvField name="inputAmount" type='number' min={0.1} label={Strings['Value']} placeholder={Strings['Enter Value']} value={this.state.inputAmount} onChange={this.handleInputChange} errorMessage={Strings['This field is required']} /> */}
                              <AvField
                                name="inputAmount"
                                label={
                                  localStorage.getItem("IS_TAXDIST") == 1
                                    ? Strings["face Amount"]
                                    : Strings["amount"]
                                }
                                type="number"
                                min={0.1}
                                placeholder={Strings["Enter Value"]}
                                value={this.state.inputAmount}
                                disabled
                              />
                            </Col>

                            <Col md={6}>
                              <AvField
                                className="not-allowed-cursor"
                                name="cardsAmounCurr"
                                type="number"
                                label={Strings["Actual Value"]}
                                value={this.state.cardsAmounCurr}
                                disabled
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <AvField
                                className="not-allowed-cursor"
                                name="distCurrency"
                                label={Strings["Currency"] + " *"}
                                value={this.state.distCurrency}
                                disabled
                              />
                            </Col>
                            {localStorage.getItem("IS_TAXDIST") == 1 && (
                              <Col md={6}>
                                <AvField
                                  className="not-allowed-cursor"
                                  name="taxTruncateAmount"
                                  label={Strings["Deduction Value"]}
                                  value={this.state.taxTruncateAmount}
                                  disabled
                                />
                              </Col>
                            )}
                          </Row>
                        </div>
                      )}

                      {this.state.typeOfCard == 1 && (
                        <div>
                          <Row>
                            <Col md={6}>
                              <AvField
                                name="amount"
                                type="number"
                                min={0.1}
                                label={Strings["Transaction Amount"] + " *"}
                                placeholder={
                                  Strings["Enter Transaction Amount"]
                                }
                                value={this.state.amount}
                                required
                                onChange={this.handleInputChange}
                                errorMessage={Strings["This field is required"]}
                              />
                            </Col>
                            <Col md={6}>
                              <AvField
                                className="not-allowed-cursor"
                                name="distCurrency"
                                label={Strings["Currency"] + " *"}
                                value={this.state.distCurrency}
                                disabled
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <AvField
                                className="not-allowed-cursor"
                                name="amountRate"
                                label={Strings["Conversion Ratio"]}
                                value={this.state.amountRate}
                                disabled
                              />
                            </Col>
                            <Col md={6}>
                              <AvField
                                className="not-allowed-cursor"
                                name="amountUSD"
                                label={Strings["Value"]}
                                value={this.state.amountUSD}
                                disabled
                              />
                            </Col>
                          </Row>
                        </div>
                      )}

                      {this.state.distHaveBank == 1 && (
                        <AvField
                          type="select"
                          required
                          label={Strings["Bank Account"] + " *"}
                          name="bankId"
                          value={this.state.bankId}
                          onChange={this.handleInputChange}
                          errorMessage={Strings["This field is required"]}
                        >
                          <option value="" style={{ display: "none" }}>
                            {" "}
                            {Strings["Select Bank Account"]}
                          </option>
                          {this.state.distBankAccount.map((data) =>
                            this.setUpSelectOptions(data, "distBankAccount")
                          )}
                        </AvField>
                      )}
                    </Col>
                  </Row>
                </div>
              )}

              {this.state.activeStep == 4 && (
                <div className="text-center">
                  <img
                    src={toAbsoluteUrl("/media/ezpay/add-success-order.gif")}
                    height={350}
                  />
                  <div className="main-color-1 mt-3 f-17 font-w-500">
                    {Strings["Order Has Been Successfully Added"]}
                  </div>

                  <Link
                    to={"/dashboard"}
                    className="btn gradient-button-view gradient-button gradient-button-font-12 ml-1 f-15 mr-2 mt-3"
                  >
                    {Strings["Home"]}
                  </Link>
                </div>
              )}

              {this.state.activeStep != 4 && (
                <div className="add-order-next-btn-view">
                  {this.state.activeStep != 4 && this.state.activeStep != 1 && (
                    <button
                      type="button"
                      className={
                        "btn gradient-button-view gradient-button gradient-button-font-12 ml-1 f-15 mr-2 gradient-button-view-mobile"
                      }
                      onClick={() => this.setBackStep()}
                    >
                      {localStorage.getItem("LANG") == 1 ? (
                        <ChevronRightIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}{" "}
                      {Strings["Back"]}
                    </button>
                  )}
                </div>
              )}

              {this.state.errorInSelectedClasses == 1 && (
                <div className="error-text-black text-center mb-3">
                  <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                  {Strings["Please select the required quantity"]}
                </div>
              )}

              {this.state.submitError != "" &&
                this.state.submitError != undefined &&
                this.state.submitError != null && (
                  <div className="error-text-black text-center mb-3">
                    <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                    {this.state.submitError}
                  </div>
                )}
            </div>
          )}
        </AvForm>
        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openOrderAction}
          className={"sidebar-modal-view"}
        >
          {this.state.actionType == "buy" && (
            <POSAddCardOrder
              history={this.props.history}
              handleCloseSideBar={this.handleCloseSideBar}
              cardId={this.state.cardId}
              catId={this.state.categoryd}
              {...this.state.propsData}
            />
          )}
        </Drawer>
      </div>
    );
  }
}
export default withRouter(POSBuyCards);

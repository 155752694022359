import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import { translations } from '../../../../translate.js';
import InfoIcon from '@material-ui/icons/Info';
import { atelierEstuaryDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { toAbsoluteUrl } from "../../../../_metronic";
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { CircularProgress } from "@material-ui/core";

let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;

class AddSupplierApi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitError: '',
            supplierList: [],
            supplierFields: [],
            loadingSupplier: 0,
            startSave: 0,
            status: '',
            counrSupplier: 0,distId:this.props.distId?this.props.distId:'',
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    componentDidMount() {
        this.getDataAndTypeOfChooseApi()
    }

    getDataAndTypeOfChooseApi() {
        this.setState({ loadingSupplier: 1 })
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDataAndTypeOfChooseApi',
                'fields': '1',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                distId:this.state.distId,
                'classId': this.props.cardsCalssData.id // 2581 only eazy=pay | 2586 --> direct charge | 490 normail | 2627 dtone
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            this.setState({ loading: 1 });
            if (typeof data !== 'undefined') {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({
                        supplierList: data["type"],
                        supplierFields: data["fildes"],
                        counrSupplier: data["count"],
                        loadingSupplier: 0
                    })
                }
            }
        });
    }
    handleValidSubmit(event) {
        event.preventDefault();
        var value = []
        var allSuppliers = []
        for (let item in this.state.supplierFields) {
            this.setState({ startSave: 1 })
            if (this.state.supplierFields[item]['show'] == 1 && this.state.supplierFields[item]['active'] == 1) {
                value = []
                for (let subItem in this.state.supplierFields[item]["feildes"]) {
                    value.push({
                        'name': this.state.supplierFields[item]["feildes"][subItem]["name"],
                        'value': this.state.supplierFields[item]["feildes"][subItem]["value"]
                    });
                }
                allSuppliers.push({
                    'supplyerType': this.state.supplierFields[item]["supplierKey"],
                    'value': value
                });
            }
        }

        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'selectSuppliersApi',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'classId': this.props.cardsCalssData.id,
                'supplyerType': JSON.stringify(allSuppliers),
                distId:this.state.distId,
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else if (data['error'] == 1) {
                    this.setState({ submitError: data['errorMsg'], status: '', startSave: 0 })
                } else {
                    this.setState({ status: data['errorMsg'], startSave: 0 })
                    this.props.recallGetData()
                }
            }
        })
    }
    handleSupplier = (event, supplierKey, active) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        var allSuppliers = this.state.supplierFields
        var counrSupplier = this.state.counrSupplier
        if (value == true) {
            allSuppliers[supplierKey]["show"] = 1
            counrSupplier++
        } else {
            allSuppliers[supplierKey]["show"] = 0
            allSuppliers[supplierKey]["feildes"].map((rowKey, index3) => {
                allSuppliers[supplierKey]["feildes"][index3]["value"] = ''
            })
            counrSupplier--
        }
        this.setState({ supplierFields: allSuppliers, counrSupplier: counrSupplier, loadingSupplier: 0 })
    }

    handleChangeInput = (event, supplierKey, nameInput, onlyOne) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? (target.checked == true ? 1 : 0) : target.value;
        var allSuppliers = this.state.supplierFields
        if (onlyOne == 1) {
            Object.keys(allSuppliers).map((suppKey, index) => {
                if (allSuppliers[suppKey]["show"] == 1)
                    allSuppliers[suppKey]["feildes"].filter(items => { return items["name"].includes(nameInput) }).map((rowData, index2) => {
                        rowData["value"] = suppKey == supplierKey ? value : (value != "" && value != false) ? '' : rowData["value"]
                    });
            });
        } else {
            allSuppliers[supplierKey]["feildes"].filter(items => { return items["name"].includes(nameInput) }).map((rowData, index) => {
                rowData["value"] = value
            });
        }
        this.setState({ supplierFields: allSuppliers })
    }

    displaySupplierData() {
        var dataMain = []
        var rowData = []
        var filelds = []
        if (this.state.counrSupplier > 0) {
            Object.keys(this.state.supplierFields).map((tableData, index) => {
                if (this.state.supplierFields[tableData]["show"] == 1) {
                    filelds = []
                    if (this.state.supplierFields[tableData].active == 1) {
                        this.state.supplierFields[tableData]["feildes"].map((rowKey, index3) => {
                            rowData = this.state.supplierFields[tableData]["feildes"][index3];
                            if (rowData['type'] == "checkbox") {
                                filelds.push(
                                    <tag>
                                        < AvField
                                            label={rowData['lebal']}
                                            type="checkbox"
                                            name={rowData.name + "_" + this.state.supplierFields[tableData].supplierKey}
                                            ref="default"
                                            value={rowData['value'] == 1 ? true : false}
                                            checked={rowData['value'] == 1 ? true : false}
                                            onChange={(event) => this.handleChangeInput(event, this.state.supplierFields[tableData].supplierKey, this.state.supplierFields[tableData]["feildes"][index3].name, this.state.supplierFields[tableData]["feildes"][index3].onlyOne)}
                                            tag={CustomInput}
                                            className='custom-form-mb default mb-3 mr-19px pointer-cursor'
                                        />
                                    </tag>
                                )
                            } else if (rowData['type'] == "password") {
                                filelds.push(
                                    <tag>
                                        < AvField name={rowData.name + "_" + this.state.supplierFields[tableData].supplierKey} type={rowData['type']} label={rowData['lebal']} value={rowData['value']} placeholder={Strings['Enter'] + "  " + rowData['lebal']}
                                            onChange={(event) => this.handleChangeInput(event, this.state.supplierFields[tableData].supplierKey, this.state.supplierFields[tableData]["feildes"][index3].name, this.state.supplierFields[tableData]["feildes"][index3].onlyOne)} />
                                    </tag>
                                )
                            } else if (rowData['disable'] != undefined && rowData['disable'] == "1" && rowData['value'] != '' && rowData['value'] != 0) {
                                filelds.push(
                                    <tag>
                                        < AvField name={rowData.name + "_" + this.state.supplierFields[tableData].supplierKey} type={rowData['type']} label={rowData['lebal']} value={rowData['value']} placeholder={rowData['lebal']} disabled="true" />
                                    </tag>
                                )
                            } else if (rowData['veiw_price'] == undefined || (rowData['veiw_price'] != undefined && rowData['veiw_price'] != 1)) {
                                filelds.push(
                                    <tag>
                                        < AvField name={rowData.name + "_" + this.state.supplierFields[tableData].supplierKey} type={rowData['type']} label={rowData['lebal']} value={rowData['value']} placeholder={Strings['Enter'] + "  " + rowData['lebal']} required
                                            onChange={(event) => this.handleChangeInput(event, this.state.supplierFields[tableData].supplierKey, this.state.supplierFields[tableData]["feildes"][index3].name, this.state.supplierFields[tableData]["feildes"][index3].onlyOne)} />
                                    </tag>
                                )
                            }



                        })
                    } else {
                        filelds.push(
                            <div className='mb-3'>
                                {Strings["The service is not currently supported by this supplier."]}
                            </div>
                        )
                    }
                    dataMain.push(
                        <div key={index} className='supplier-api-section-box main-color-1 '>
                            <div className="  font-title-supplier-api ">
                                {this.state.supplierFields[tableData]['suplierName']}
                            </div>
                            <div>
                                {filelds}
                            </div>
                        </div>
                    )
                }
            })

        }
        return dataMain
    }


    displaySupplier() {
        var info = []
        if (Object.keys(this.state.supplierList).length > 0) {
            Object.keys(this.state.supplierList).map((tableData, index) => {
                var rowData = this.state.supplierList[tableData];
                info.push(
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <AvField
                            label={rowData.suplierName}
                            type="checkbox"
                            name={"supplier_" + rowData.supplierKey}
                            ref="default"
                            onClick={(event) => this.handleSupplier(event, rowData.supplierKey, rowData.active)}
                            checked={rowData['check'] == 1 ? true : false}
                            value={rowData['check'] == 1 ? true : false}
                            tag={CustomInput}
                            className='custom-form-mb default mb-3 mr-19px pointer-cursor'
                        />
                    </Col>
                )
            }
            )
            info.push(
                <Col lg={12} md={12} sm={12} xs={12} >
                    <div className='text-right mt-4'>
                        {this.state.startSave == 0 ?
                            <button type="submit" className="btn form-save-btn mr-1 ml-1"  > {Strings['Save']} </button>
                            :
                            <button type="button" className="btn form-save-btn"> <CircularProgress className="SaveCircularProgressColor" /> {Strings['Save']} </button>
                        }

                        <button type="button" className="btn form-cancel-btn m-l-10" onClick={this.props.handleCloseSideBar}>{Strings['Cancel']}</button>
                    </div>

                    {this.state.status &&
                        <div className='error-text-black'><i className={'flaticon2-check-mark success-icon mr-1 mb-3 mt-4'} /> {this.state.status}</div>
                    }
                    {this.state.submitError &&
                        <div className='error-text-black'><i className='flaticon2-exclamation error-icon mr-1 mb-3 mt-4' /> {this.state.submitError}</div>
                    }

                </Col>
            )
        }
        return info
    }

    render() {
        return (
            <div className='view-modal-style'>
                <div className='sidebar-header-view'>
                    <Row>
                        <Col lg={10} md={10} sm={10} xs={10}>
                            <div className='p-0 main-color-1  sidebar-header-title'>{Strings['Selection of API Suppliers']} ({localStorage.getItem("LANG") == 1 ? this.props.cardsCalssData.name : this.props.cardsCalssData.name_en})
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={2} xs={2} className='text-right'>
                            <HighlightOffIcon onClick={this.props.handleCloseSideBar} className='modal-close-icon' />
                        </Col>
                    </Row>
                </div>
                {this.state.loadingSupplier == 1 &&
                    <Row>
                        <Col md={12} style={{ marginTop: "15%" }} className='text-center'>
                            <div className='card-body'>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-1' role='status'></div>
                                <div className='spinner-grow text-dark m-2 main-color-2' role='status'></div>
                            </div>
                        </Col>
                    </Row>
                }

                <div className='sidebar-body-view'>
                    <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                        <Row>
                            <Col lg={3} md={3} sm={12} xs={12} className='add-purchase-order-form-border'>
                                <Row>
                                    {this.displaySupplier()}
                                </Row>
                            </Col>
                            <Col lg={9} md={9} sm={12} xs={12}>
                                {(this.state.loadingSupplier == 0 && this.state.counrSupplier > 0) &&
                                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                                        <Masonry>
                                            {this.displaySupplierData()}
                                        </Masonry>
                                    </ResponsiveMasonry>
                                }

                                {this.state.loadingSupplier == 0 && this.state.counrSupplier <= 0 &&
                                    <div>
                                        <Row className='text-center' style={{ marginTop: "15%" }}>
                                            <div>
                                                <img alt="Logo" src={toAbsoluteUrl("/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png")} className='img-fluid no-data-img' />
                                                <div className='no-data-text'>{Strings["The card class has no supplier API"]}</div>
                                            </div>
                                        </Row>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </div>
        )
    }
}
export default AddSupplierApi;
